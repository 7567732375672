import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import {
  Card,
  message,
  Drawer,
  Form,
  Space,
  Skeleton,
  Row,
  Col,
  UploadedDocuments,
} from '@shipmnts/pixel-hub';
import { DrawerFooter } from '@shipmnts/pixel-hub';
import { GET_OCEAN_TRANSPORT_ORDER } from 'operations/modules/booking/graphql/oceanTransportOrder';
import { convertValuesToDayJs } from '@shipmnts/pixel-hub';
import OceanTransportOrder, {
  OceanTransportOrderValue,
} from 'operations/models/OceanTransportOrder';
import { errorMessageHandlerGraphQL, useSession } from 'common';

import CarrierBooking from 'operations/modules/shipment/components/NewShipmentForm/CarrierBooking';
import BookingConfirmationDetails from 'operations/modules/shipment/components/NewShipmentForm/BookingConfirmationDetails';
import { getRoutingDetails } from 'operations/modules/shipment/components/ShipmentForm/helpers';
import { SHIPMENT, UPDATE_ROUTING_DETAILS } from 'operations/graphql/shipment';
import { getNewOtoPayload } from '../../helpers/allocationHelper';
import { getRoutingValues } from 'operations/modules/shipment/components/NewShipmentForm/getInitialValueHelper';
import Shipment, { ShipmentValue } from 'operations/models/Shipment';
import RoutingDetailsWrapper from 'operations/modules/shipment/components/NewShipmentForm/RoutingDetailsWrapper';
import { getRoutingLegsPayload } from 'operations/modules/shipment/components/ShipmentForm/helpers';
import {
  SHIPPING_LINE_SERVICE_TYPES,
  TRADE_TYPE_EXPORT,
} from 'operations/modules/shipment/constants';
import { BOOKING_TYPE_OCEAN_TRANSPORT_ORDER } from 'operations/modules/booking/constants';
import { STATUS_CANCELLED } from '../../constants';
import { containerSettingsMap } from 'operations/baseConstants';
import { BOOKING_TYPE_SELF } from 'operations/baseConstants';
import BookingTermsAndConditions from 'operations/modules/shipment/components/NewShipmentForm/BookingTermsAndConditions';
const UpdateOtoForm = observer(function ExtendValidityForm(props: {
  oceanTransportOrder: OceanTransportOrderValue;
  oto_id: string;
  onClose: () => void;
  onSuccess?: (sendEmail: boolean, shipment: any) => void;
}): JSX.Element {
  const { oto_id, onClose, onSuccess } = props;
  const [oto, setOto] = useState<OceanTransportOrderValue | undefined>();
  const [shipment, setShipment] = useState<ShipmentValue | undefined>();
  const [sendEmail, setSendEmail] = useState(false);
  const [form] = Form.useForm();

  const [updateRouting, { data: updateRoutingData, error: updateRoutingError, loading }] =
    useMutation(UPDATE_ROUTING_DETAILS);

  const { data: fetchOtoData } = useQuery(GET_OCEAN_TRANSPORT_ORDER, {
    variables: {
      id: oto_id,
    },
    fetchPolicy: 'no-cache',
  });
  const [fetchShipment, { data: shipmentData }] = useLazyQuery(SHIPMENT);

  const sessionData = useSession();

  useEffect(() => {
    if (fetchOtoData?.ocean_transport_order) {
      setOto(OceanTransportOrder.create(fetchOtoData?.ocean_transport_order));
      const shipment_id = fetchOtoData?.ocean_transport_order?.shipment?.id;
      if (shipment_id) {
        fetchShipment({
          variables: {
            id: shipment_id,
          },
        });
      }
    }
  }, [fetchOtoData, fetchShipment]);
  useEffect(() => {
    if (shipmentData?.shipment) {
      setShipment(Shipment.create(shipmentData?.shipment));
    }
  }, [shipmentData]);
  useEffect(() => {
    if (updateRoutingData?.update_routing) {
      message.success('Booking order updated successfully');
      onSuccess && onSuccess(sendEmail, shipment);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateRoutingData, updateRoutingError]);

  const date_fields = [
    'valid_till_date',
    'gate_open_date',
    'si_cutoff_date',
    'vgm_cutoff_date',
    'ams_cutoff_date',
    'icd_cutoff_date',
    'gate_close_date',
    'doc_cutoff_date',
    'booking_date',
  ];
  if (!oto && !shipment) return <Skeleton />;

  const formInitialValue = {
    ...oto,
    booking_party: {
      party_company: oto?.customer_company,
      party_address: oto?.customer_address,
    },
    vendor: {
      party_company:
        oto?.vendor_company || shipment?.getShipmentPartyByName('origin_agent')?.party_company,
      party_address:
        oto?.vendor_address || shipment?.getShipmentPartyByName('origin_agent')?.party_address,
    },
    ...convertValuesToDayJs(oto as any, date_fields),
    ...getRoutingValues(undefined, oto),
    carrier: oto?.global_carrier,
    routing_details: getRoutingDetails(oto as any),
    service_type: SHIPPING_LINE_SERVICE_TYPES[0].key,
  };
  return (
    <Drawer
      title={`Amend Booking`}
      width="60%"
      open={true}
      onClose={onClose}
      footer={
        <DrawerFooter
          saveText="Save"
          loading={loading}
          onClose={onClose}
          onSave={form.submit}
          closeText="Cancel"
          showSendEmail={!!shipment}
          sendEmail={sendEmail}
          setSendEmail={setSendEmail}
          sendEmailText="Send Booking Amendment"
        />
      }
    >
      <Form
        form={form}
        name="edit_booking"
        layout="vertical"
        initialValues={formInitialValue}
        onValuesChange={(changedValues, allValues) => {
          if (changedValues.terms_and_condition) {
            form.setFieldsValue({
              terms_and_condition_description: changedValues.terms_and_condition?.content,
            });
          }
        }}
        onFinish={(values: any) => {
          const otoPayload =
            getNewOtoPayload({ ...values, load_type: shipment?.load_type || 'fcl' }) || {};
          const routing_legs = getRoutingLegsPayload(values, shipment);
          const routing_nodes = Object.values(values.routing_details?.routing_nodes || {}).map(
            (rn: any) => {
              const { location, address, company, terminal, id, _id, tags } = rn;
              return {
                id: id || null,
                _id: _id || null,
                location_id: location?.id || null,
                terminal_id: terminal?.id || null,
                address_id: address?.id || null,
                company_id: company?.id || null,
                tags: tags || null,
              };
            }
          );
          otoPayload['routing_nodes'] = routing_nodes;
          otoPayload['routing_legs'] = routing_legs;
          updateRouting({
            variables: {
              oto_args: { id: oto_id, ...otoPayload },
              only_update_routing: true,
              selected_containers: [],
              old_shipment_id: oto?.shipment?.id,
            },
          });
        }}
      >
        {updateRoutingError && errorMessageHandlerGraphQL(updateRoutingError)}
        <Space size={'middle'} direction="vertical">
          <Card title="Booking Details" className="custom-card">
            <CarrierBooking form={form} disabledFields={['booking_type']} />
          </Card>
          <Card title="Routing Details" className="custom-card">
            <RoutingDetailsWrapper
              form={form}
              loadType={oto?.load_type}
              showShipmentFields={false}
              trade_type={shipment?.trade_type || undefined}
              isReeferContainer={Object.keys(containerSettingsMap.reefer).includes(
                shipment?.shipment_containers?.[0]?.container_type_code || ''
              )}
            />
          </Card>
          {shipment?.trade_type === TRADE_TYPE_EXPORT && (
            <Card title="Booking Cutoff Details" className="custom-card">
              <BookingConfirmationDetails form={form} />
            </Card>
          )}
          {shipment?.booking_type === BOOKING_TYPE_SELF && (
            <Card title="Terms And Conditions" className="custom-card">
              <BookingTermsAndConditions></BookingTermsAndConditions>
            </Card>
          )}
          <Card title="Upload Documents" className="custom-card">
            <Row gutter={16}>
              <Col span={24}>
                <div>
                  <UploadedDocuments
                    sessionData={sessionData as any}
                    parent_id={oto?.id}
                    parent_type={BOOKING_TYPE_OCEAN_TRANSPORT_ORDER}
                    deleteDisabled={oto?.status === STATUS_CANCELLED}
                    docgen_url={process.env.DOCGEN_URL || ''}
                    initialUploadDocumentTags={['Booking confirmation']}
                  />
                </div>
              </Col>
            </Row>
          </Card>
        </Space>
      </Form>
    </Drawer>
  );
});

export default UpdateOtoForm;
