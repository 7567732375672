import { createContext, useContext } from 'react';
import { roundToTwoDecimals } from 'common';
import { hasPermission } from '@shipmnts/pixel-hub';
import {
  types,
  Instance,
  SnapshotIn,
  SnapshotOut,
  detach,
  getEnv,
  getParent,
  getSnapshot,
  IAnyModelType,
  applySnapshot,
} from 'mobx-state-tree';

import {
  ENUM_BUY_SELL_STATUS,
  PERMISSION_BUY_ESTIMATE,
  PERMISSION_SELL_ESTIMATE,
  MARKUP_TYPE_PERCENTAGE,
} from 'sales_hub/utils/constants';

import Company, { CompanyValue } from './Company';
import { getExchangeRate } from 'common/baseHelper';

const ShipmentEstimateInternal = types
  .model({
    uuid: types.string,
    id: types.maybe(types.string),
    item: types.maybe(types.maybeNull(types.string)),
    inquiry_option_id: types.maybe(types.maybeNull(types.string)),
    shipment_id: types.maybe(types.maybeNull(types.string)),
    buy_branch_id: types.maybe(types.maybeNull(types.string)),
    sell_branch_id: types.maybe(types.maybeNull(types.string)),
    supplier_company_id: types.maybe(types.maybeNull(types.string)),
    supplier_company: types.maybe(types.maybeNull(Company)),
    customer_company_id: types.maybe(types.maybeNull(types.string)),
    customer_company: types.maybe(types.maybeNull(Company)),
    uom: types.maybe(types.maybeNull(types.string)),
    quantity: types.maybe(types.maybeNull(types.number)),
    buy_rate: types.maybe(types.maybeNull(types.number)),
    buy_exchange_rate: types.maybe(types.maybeNull(types.number)),
    buy_terms: types.maybe(types.maybeNull(types.string)),
    buy_currency: types.maybe(types.maybeNull(types.string)),
    total_buy_amount: types.maybe(types.maybeNull(types.number)),
    total_buy_billed_amount: types.maybe(types.maybeNull(types.number)),
    sell_rate: types.maybe(types.maybeNull(types.number)),
    sell_exchange_rate: types.maybe(types.maybeNull(types.number)),
    sell_terms: types.maybe(types.maybeNull(types.string)),
    sell_currency: types.maybe(types.maybeNull(types.string)),
    total_sell_amount: types.maybe(types.maybeNull(types.number)),
    total_sell_billed_amount: types.maybe(types.maybeNull(types.number)),
    remarks: types.maybe(types.maybeNull(types.string)),
    buy_status: types.maybe(types.maybeNull(types.string)),
    sell_status: types.maybe(types.maybeNull(types.string)),
    buy_transaction_identifier: types.maybe(types.maybeNull(types.string)),
    buy_transaction_doctype: types.maybe(types.maybeNull(types.string)),
    sell_transaction_identifier: types.maybe(types.maybeNull(types.string)),
    equipment_type: types.maybe(types.maybeNull(types.string)),
    equipment_name: types.maybe(types.maybeNull(types.string)),
    freight_contract_item_id: types.maybe(types.maybeNull(types.string)),
    tag: types.maybe(types.maybeNull(types.string)),
    tax_percentage: types.maybe(types.maybeNull(types.number)),
    taxable: types.maybe(types.maybeNull(types.number)),
    taxability: types.maybe(types.maybeNull(types.string)),
    product_rating: types.maybe(
      types.maybeNull(
        types.model({
          id: types.maybe(types.maybeNull(types.string)),
        })
      )
    ),
    sequence: types.maybe(types.maybeNull(types.number)),
    item_group: types.maybe(types.maybeNull(types.string)),
    item_description: types.maybe(types.maybeNull(types.string)),
    markup_type: types.maybe(types.maybeNull(types.string)),
    markup_value: types.maybe(types.maybeNull(types.number)),
  })
  .volatile((self) => ({
    sell_transaction_doctype: 'Sales Invoice', // adding this for links to work with renderers
    buy_permission: { view: true, edit: true },
    sell_permission: { view: true, edit: true },
    mismatch: false,
  }))
  .views((self) => ({
    get sell_provisional_amount() {
      if (!this.is_sell_view_allowed) return 0;
      return self.total_sell_billed_amount || self.total_sell_amount;
    },
    get buy_provisional_amount() {
      if (!this.is_buy_view_allowed) return 0;
      return self.total_buy_billed_amount || self.total_buy_amount;
    },
    get uom_string() {
      return self.equipment_name
        ? `${self.uom}.${self.equipment_name}.${self.equipment_type}`
        : self.uom;
    },
    get is_sell_edit_allowed() {
      return self.sell_permission.edit && self.sell_status === ENUM_BUY_SELL_STATUS.UNBILLED;
    },
    get is_buy_edit_allowed() {
      if (!self.buy_permission.edit) return false;
      if (self.buy_status !== ENUM_BUY_SELL_STATUS.UNBILLED) return false;
      if (
        self.buy_transaction_identifier &&
        ['Purchase Transaction', 'CSR Transaction'].includes(self.buy_transaction_doctype || '')
      )
        return false;
      return true;
    },
    get is_sell_view_allowed() {
      return self.sell_permission.view;
    },
    get is_buy_view_allowed() {
      return self.buy_permission.view;
    },
    get provisional_margin_in_currency() {
      if (self.sell_currency === self.buy_currency) {
        if (self.sell_exchange_rate === 0 || self.buy_exchange_rate === 0) return 0;
        return (
          (self.total_sell_billed_amount || self.total_sell_amount || 0) /
            (self.sell_exchange_rate || 1) -
          (self.total_buy_billed_amount || self.total_buy_amount || 0) /
            (self.buy_exchange_rate || 1)
        );
      } else
        return (
          (self.total_sell_billed_amount || self.total_sell_amount || 0) -
          (self.total_buy_billed_amount || self.total_buy_amount || 0)
        );
    },
    get estimated_margin_in_currency() {
      if (self.sell_currency === self.buy_currency) {
        return (
          (self.quantity || 0) * (self.sell_rate || 0) - (self.quantity || 0) * (self.buy_rate || 0)
        );
      } else {
        return (self.total_sell_amount || 0) - (self.total_buy_amount || 0);
      }
    },
    get total_sell_amount_in_currency() {
      return (self.sell_rate || 0) * (self.quantity || 0);
    },
    get total_buy_amount_in_currency() {
      return (self.buy_rate || 0) * (self.quantity || 0);
    },
  }))
  .actions((self) => {
    return {
      updatePermissions() {
        const permissions = getEnv(getParent(self, 2)).permissions;
        const disabled = getEnv(getParent(self, 2)).disabled;

        const permissionsConfig = [
          { key: 'buyAllowed', permission: PERMISSION_BUY_ESTIMATE },
          { key: 'sellAllowed', permission: PERMISSION_SELL_ESTIMATE },
        ];

        const permissionStatus = permissionsConfig.reduce((acc, { key, permission }) => {
          acc[key] = hasPermission(permissions, {
            name: permission,
            docType: 'NewAccounting::ShipmentEstimate',
          });
          return acc;
        }, {} as Record<string, boolean>);

        const sellPerm = { view: true, edit: !disabled };
        const buyPerm = { view: true, edit: !disabled };

        if (!permissionStatus.buyAllowed) {
          buyPerm.view = buyPerm.edit = false;
        }
        if (!permissionStatus.sellAllowed) {
          sellPerm.view = sellPerm.edit = false;
        }

        this.setBuyPermission(buyPerm);
        this.setSellPermission(sellPerm);
      },
      setBuyPermission(permission: { view: boolean; edit: boolean }) {
        self.buy_permission = permission;
      },
      setSellPermission(permission: { view: boolean; edit: boolean }) {
        self.sell_permission = permission;
      },
      updateDetailsOnCreate(id: string, buyBranchId: string, supplier: CompanyValue) {
        self.id = id;
        self.uuid = id;
        self.buy_branch_id = buyBranchId;
        self.supplier_company = supplier ? supplier : undefined;
        self.supplier_company_id = supplier ? supplier.id : null;
      },
      updateItem(itemData: {
        item: string;
        item_currency: string;
        uom: string;
        quantity: number;
        tag?: string;
        description?: string;
        item_group?: string;
        tax_percentage?: number;
        taxable?: number;
        taxability?: string;
      }) {
        if (!itemData) return;
        const {
          item,
          uom,
          item_currency,
          quantity,
          tag,
          description,
          item_group,
          tax_percentage,
          taxable,
          taxability,
        } = itemData;
        self.item = item;
        self.sell_currency = item_currency;
        self.tag = tag;
        self.tax_percentage = tax_percentage;
        self.taxable = taxable;
        self.taxability = taxability;
        self.item_description = description;
        self.item_group = item_group;
        this.updatePermissions();
        const [newUom, equipmentName, equipmentType] = uom.split('.');
        this.updateUom(newUom, equipmentName, equipmentType, quantity);
      },
      updateUom(uom: string, equipmentName: string, equipmentType: string, quantity: number) {
        if (this.updateQuantity(quantity)) {
          self.uom = uom;
          self.equipment_name = equipmentName;
          self.equipment_type = equipmentType;
          return true;
        } else return false;
      },
      updateQuantity(quantity: number) {
        if (!self.is_sell_edit_allowed && !self.is_buy_edit_allowed) return false;
        if (
          self.sell_status !== ENUM_BUY_SELL_STATUS.UNBILLED ||
          self.buy_status !== ENUM_BUY_SELL_STATUS.UNBILLED
        )
          return false;
        const newValue = parseFloat(quantity.toString());
        if (Number.isNaN(newValue)) return false;
        self.quantity = newValue;
        this.recalculateSellTotal();
        this.recalculateBuyTotal();
        return true;
      },
      updateSellExchangeRate(exchangeRate: number) {
        if (!self.is_sell_edit_allowed) return false;
        const newValue = parseFloat(exchangeRate.toString());
        if (Number.isNaN(newValue)) return false;
        self.sell_exchange_rate = newValue;
        this.recalculateSellTotal();
        return true;
      },
      updateSellCurrency(currency: string) {
        if (!self.is_sell_edit_allowed) return false;
        const exchangeRate = getParent<ShipmentEstimateStoreValue>(self, 2).getCachedExchangeRate(
          currency,
          'sell'
        );
        self.sell_currency = currency;
        if (exchangeRate) this.updateSellExchangeRate(exchangeRate);
        return true;
      },
      updateSellRate(sellRate: number) {
        if (!self.is_sell_edit_allowed) return false;
        const newValue = parseFloat(sellRate.toString());
        if (Number.isNaN(newValue)) return false;
        self.sell_rate = newValue;
        this.recalculateSellTotal();
        this.recalculateMarkup();
        return true;
      },
      updateSellBranch(branchId: string, mismatch: boolean) {
        self.sell_branch_id = branchId;
        self.mismatch = mismatch;
        getParent<ShipmentEstimateStoreValue>(self, 2).addToBeSynced([self.uuid]);
        if (!mismatch) getParent<ShipmentEstimateStoreValue>(self, 2).checkEstimateError();
      },
      updateCustomer(customer: CompanyValue) {
        self.customer_company = customer;
        self.customer_company_id = customer.id;
        getParent<ShipmentEstimateStoreValue>(self, 2).addToBeSynced([self.uuid]);
      },
      updateBuyExchangeRate(exchangeRate: number) {
        if (!self.is_buy_edit_allowed) return false;
        const newValue = parseFloat(exchangeRate.toString());
        if (Number.isNaN(newValue)) return false;
        self.buy_exchange_rate = newValue;
        if (self.is_sell_edit_allowed) this.updateSellExchangeRate(newValue);
        this.recalculateBuyTotal();
        return true;
      },
      updateBuyCurrency(currency: string) {
        if (!self.is_buy_edit_allowed) return false;
        if (self.is_sell_edit_allowed) self.sell_currency = currency;
        const exchangeRate = getParent<ShipmentEstimateStoreValue>(self, 2).getCachedExchangeRate(
          currency,
          'buy'
        );
        self.buy_currency = currency;
        if (exchangeRate) this.updateBuyExchangeRate(exchangeRate);
        return true;
      },
      updateBuyRate(buyRate: number) {
        if (!self.is_buy_edit_allowed) return false;
        const newValue = parseFloat(buyRate.toString());
        if (Number.isNaN(newValue)) return false;
        self.buy_rate = newValue;
        this.recalculateBuyTotal();
        this.recalculateMarkup();
        return true;
      },
      updateBuyBranch(branchId: string, mismatch: boolean) {
        self.buy_branch_id = branchId;
        self.mismatch = mismatch;
        getParent<ShipmentEstimateStoreValue>(self, 2).addToBeSynced([self.uuid]);
        if (!mismatch) getParent<ShipmentEstimateStoreValue>(self, 2).checkEstimateError();
      },
      updateSupplier(supplier?: CompanyValue) {
        self.supplier_company = supplier ? supplier : undefined;
        self.supplier_company_id = supplier ? supplier.id : null;
        getParent<ShipmentEstimateStoreValue>(self, 2).addToBeSynced([self.uuid]);
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      updateField(fieldName: 'sell_terms' | 'buy_terms' | 'remarks' | 'sequence', value: any) {
        self[fieldName] = value;
        getParent<ShipmentEstimateStoreValue>(self, 2).addToBeSynced([self.uuid]);
      },
      recalculateSellTotal() {
        self.total_sell_amount = roundToTwoDecimals(
          (self.quantity || 1) * (self.sell_exchange_rate || 1) * (self.sell_rate || 0)
        );
        getParent<ShipmentEstimateStoreValue>(self, 2).addToBeSynced([self.uuid]);
      },
      recalculateBuyTotal() {
        self.total_buy_amount = roundToTwoDecimals(
          (self.quantity || 1) * (self.buy_exchange_rate || 1) * (self.buy_rate || 0)
        );
        getParent<ShipmentEstimateStoreValue>(self, 2).addToBeSynced([self.uuid]);
      },
      recalculateMarkup() {
        const sell_rate = self.sell_rate || 0;
        const buy_rate = self.buy_rate || 0;
        if (!self.sell_exchange_rate || !self.buy_exchange_rate) {
          self.markup_value = 0;
          return;
        }
        const sell_rate_in_buy_currency =
          (sell_rate * self.sell_exchange_rate) / self.buy_exchange_rate;

        if (self.markup_type === MARKUP_TYPE_PERCENTAGE && self.sell_rate) {
          if (!self.buy_rate) self.markup_value = 0;
          else
            self.markup_value = roundToTwoDecimals(
              ((sell_rate_in_buy_currency - buy_rate) / buy_rate) * 100
            );
        } else if (self.sell_rate)
          self.markup_value = roundToTwoDecimals(sell_rate_in_buy_currency - buy_rate);
        else self.markup_value = 0;
        getParent<ShipmentEstimateStoreValue>(self, 2).addToBeSynced([self.uuid]);
      },
      updateProductRating(id?: string) {
        if (!self.is_buy_edit_allowed) return false;
        self.product_rating = id ? { id } : undefined;
        getParent<ShipmentEstimateStoreValue>(self, 2).addToBeSynced([self.uuid]);
        return true;
      },
      updateItemGroup(name?: string) {
        return (self.item_group = name);
      },
      delete() {
        getParent<ShipmentEstimateStoreValue>(self, 2).removeChild(self);
        return true;
      },
      updateMarkupType(type: string) {
        // when markup is changed, only sell_rates will get affected
        // however we need to make sure user has view permission for buy rates.
        if (!self.is_sell_edit_allowed || !self.is_buy_view_allowed) return false;
        self.markup_type = type;
        this.recalculateMarkup();
        getParent<ShipmentEstimateStoreValue>(self, 2).addToBeSynced([self.uuid]);
        return true;
      },
      updateMarkupValue(value: number) {
        // when markup is changed, only sell_rates will get affected
        // however we need to make sure user has view permission for buy rates.
        if (!self.is_sell_edit_allowed || !self.is_buy_view_allowed) return false;
        self.markup_value = roundToTwoDecimals(value);
        const buy_rate = self.buy_rate || 0;
        if (!self.buy_exchange_rate || !self.sell_exchange_rate) {
          if (self.markup_type === MARKUP_TYPE_PERCENTAGE)
            self.sell_rate = buy_rate + (buy_rate * value) / 100;
          else self.sell_rate = buy_rate + value;
        } else {
          const sell_rate_in_company_currency =
            (buy_rate +
              (self.markup_type === MARKUP_TYPE_PERCENTAGE ? (buy_rate * value) / 100 : value)) *
            self.buy_exchange_rate;
          self.sell_rate = sell_rate_in_company_currency / self.sell_exchange_rate;
        }
        this.recalculateSellTotal();
        getParent<ShipmentEstimateStoreValue>(self, 2).addToBeSynced([self.uuid]);
        return true;
      },
    };
  });

export type ShipmentEstimateValue = Instance<typeof ShipmentEstimateInternal>;
export type shipmentEstimateIn = SnapshotIn<typeof ShipmentEstimateInternal>;
export type shipmentEstimateOut = SnapshotOut<typeof ShipmentEstimateInternal>;

const ShipmentEstimate = types.snapshotProcessor(ShipmentEstimateInternal, {
  // from snapshot to instance
  preProcessor(sn: shipmentEstimateIn) {
    return {
      ...sn,
      uuid: sn.id || Date.now().toString(36) + Math.random().toString(36).substring(2),
    };
  },
});

export default ShipmentEstimate;

export const ShipmentEstimateStore = types
  .model('ShipmentEstimateStore', {
    estimates: types.array(ShipmentEstimate),
    to_be_synced: types.array(types.string),
    sync_state: types.enumeration(['unsaved', 'saved', 'saving', 'error']),
  })
  .views((self) => ({
    estimateCountForCurrency(InquiryOptionId: string, type: 'buy' | 'sell', currency: string) {
      return self.estimates.filter(
        (est) => est.inquiry_option_id === InquiryOptionId && est[`${type}_currency`] === currency
      ).length;
    },
    estimatesForSalesInvoice(InquiryOptionId: string) {
      return self.estimates.filter(
        (est) => est.is_sell_edit_allowed && est.inquiry_option_id === InquiryOptionId
      );
    },
    estimatesForPurchaseInvoice(InquiryOptionId: string) {
      return self.estimates.filter(
        (est) => est.is_buy_edit_allowed && est.inquiry_option_id === InquiryOptionId
      );
    },
    estimatesForShipment(InquiryOptionId: string) {
      return self.estimates.filter((est) => est.inquiry_option_id === InquiryOptionId);
    },
    get totalProvisionalSellAmount() {
      return self.estimates.reduce((sum, est) => {
        sum += est.sell_provisional_amount || 0;
        return sum;
      }, 0);
    },
    get totalProvisionalBuyAmount() {
      return self.estimates.reduce((sum, est) => {
        sum += est.buy_provisional_amount || 0;
        return sum;
      }, 0);
    },
    get cachedSellExchangeRate() {
      return self.estimates.reduce((cache: { [key: string]: number }, est) => {
        if (est.sell_exchange_rate && !cache[est.sell_currency || ''])
          cache[est.sell_currency || ''] = est.sell_exchange_rate;
        return cache;
      }, {});
    },
    get cachedBuyExchangeRate() {
      return self.estimates.reduce((cache: { [key: string]: number }, est) => {
        if (est.buy_exchange_rate && !cache[est.buy_currency || ''])
          cache[est.buy_currency || ''] = est.buy_exchange_rate;
        return cache;
      }, {});
    },
    getCachedExchangeRate(currency: string, type: string) {
      if (type === 'sell') return this.cachedSellExchangeRate[currency];
      return this.cachedBuyExchangeRate[currency] || 1;
    },
  }))
  .actions((self) => ({
    afterCreate() {
      this.updateEstimatePermissions();
    },
    updateEstimatePermissions() {
      self.estimates.forEach((est) => est.updatePermissions());
    },
    addToBeSynced(ids: string[]) {
      ids.forEach((id) => self.to_be_synced.push(id));
      if (self.sync_state === 'saved') self.sync_state = 'unsaved';
    },
    startSyncing() {
      const toBeSynced = getSnapshot(self.to_be_synced);
      self.to_be_synced.clear();
      self.sync_state = 'saving';
      return toBeSynced;
    },
    updateSyncSuccess() {
      self.sync_state = self.to_be_synced.length > 0 ? 'unsaved' : 'saved';
    },
    updateSyncError(ids: string[]) {
      this.addToBeSynced(ids);
      self.sync_state = 'error';
    },
    checkEstimateError() {
      if (self.estimates.find((est) => est.mismatch)) this.updateSyncError([]);
      else this.updateSyncSuccess();
    },
    replaceEstimates(estimates: ShipmentEstimateValue[]) {
      applySnapshot(self.estimates, estimates);
      this.updateEstimatePermissions();
    },
    clearEstimates() {
      self.estimates.map((est) => est.delete());
    },
    addEstimates(estimates: ShipmentEstimateValue[]) {
      estimates.forEach((est) => self.estimates.push(est));
      this.updateEstimatePermissions();
    },
    removeChild(estimate: Instance<IAnyModelType>) {
      detach(estimate);
    },
    refetchExchangeRates: async function (default_currency: string) {
      const cache: { [key: string]: number } = {};
      for (const est of self.estimates) {
        if (est.buy_currency) {
          if (!cache[est.buy_currency]) {
            cache[est.buy_currency] = await getExchangeRate(est.buy_currency, default_currency);
          }
          est.updateBuyExchangeRate(cache[est.buy_currency]);
        }

        if (est.sell_currency) {
          if (!cache[est.sell_currency]) {
            cache[est.sell_currency] = await getExchangeRate(est.sell_currency, default_currency);
          }
          est.updateSellExchangeRate(cache[est.sell_currency]);
        }
      }
    },
  }));

export type ShipmentEstimateStoreValue = Instance<typeof ShipmentEstimateStore>;
interface ShipmentEstimateStoreContextType {
  store: ShipmentEstimateStoreValue;
}

const ShipmentEstimateStoreContext = createContext<null | ShipmentEstimateStoreContextType>(null);

export const ShipmentEstimateStoreContextProvider = ShipmentEstimateStoreContext.Provider;
export function useShipmentEstimateStore() {
  const contextValue = useContext(ShipmentEstimateStoreContext);
  if (contextValue === null) {
    throw new Error('contextValue cannot be null, please add a context provider');
  }
  return contextValue;
}
