import { FormInstance, LinkedParentType } from '@shipmnts/pixel-hub';
import { ShipmentValue } from 'operations/models/Shipment';
import { RoutingLegValue } from 'operations/models/RoutingLeg';
import { hasPermission } from '@shipmnts/pixel-hub';
import { PermissionValue, SessionDataValue } from 'operations/models/SessionData';
import { PERMISSION_QUOTATION } from './reports/constants';
import { ApolloClient } from '@apollo/client';
import { FieldDisableReason } from 'operations/commonTypeDefs';
import { FETCH_VOYAGE_SCHEDULE_BY_ID_DUPLICATE } from './booking/graphql/voyageSchedule';
import { default_services, ServicesMap } from './shipment/components/ShipmentServiceDetails';
import { BusinessVerticalValue } from 'operations/models/BusinessVertical';

export const fetchShipmentDocumentParents = (shipment: ShipmentValue) => {
  const shipment_id = shipment?.id;
  const ocean_transport_order_id = shipment?.ocean_transport_order_id;
  const fetchDocumentParents: LinkedParentType[] = [];
  if (shipment_id) {
    fetchDocumentParents.push({
      parent_ids: [shipment_id],
      parent_type: 'shipment',
    });
  }
  if (ocean_transport_order_id) {
    fetchDocumentParents.push({
      parent_ids: [ocean_transport_order_id],
      parent_type: 'ocean_transport_order',
    });
  }
  return fetchDocumentParents;
};

export const get_legs_by_routing_type = (value: Array<RoutingLegValue>, routing_type: string) => {
  return value.filter((leg: RoutingLegValue) => leg.routing_type === routing_type);
};

export const getNoPermissionTags = (permissions: PermissionValue[]) => {
  // ToDo: Confirm the doctype of this permission
  let tagsNoPermission: string[] = [];
  if (
    !hasPermission(permissions, { name: PERMISSION_QUOTATION, docType: 'SalesHub::InquiryOption' })
  ) {
    tagsNoPermission = ['Buy Quotation', 'Sell Quotation'];
  }
  return tagsNoPermission;
};

export const getWhyCarrierIsDisabled = (
  shipment: ShipmentValue,
  carrier_field_name = 'carrier'
) => {
  const disabledFilters: Record<string, FieldDisableReason> = {};
  if (shipment?.inquiry_option?.carrier) {
    disabledFilters[carrier_field_name] = {
      disable: true,
      reason: `Carrier fetched from Linked Quote #${shipment?.inquiry_option?.quotation_number} to this order/shipment`,
    };
  }
  if (shipment?.carrier && shipment?.ocean_transport_orders?.length) {
    disabledFilters[carrier_field_name] = {
      disable: true,
      reason: 'Only same carrier booking can be created on this order/shipment',
    };
  }
  return disabledFilters;
};

export const getWhyVesselIsDisabled = (shipment: ShipmentValue, vessel_field_name = 'vessel') => {
  const disabledFilters: Record<string, FieldDisableReason> = {};
  if (shipment?.ocean_vessel && shipment?.ocean_transport_orders?.length) {
    disabledFilters[vessel_field_name] = {
      disable: true,
      reason: 'Only same vessel booking can be created on this order/shipment',
    };
  }
  return disabledFilters;
};

export const getIsSelectedCarrierMatchingWithVoyageSchedule = async (
  client: ApolloClient<object>,
  voyage_schedule_id: string,
  carrier_id: string
) => {
  const { data } = await client.query({
    query: FETCH_VOYAGE_SCHEDULE_BY_ID_DUPLICATE,
    variables: { id: voyage_schedule_id },
  });
  const voyageCarriers = data?.voyage_schedule?.carriers ?? [];
  const isMatch = voyageCarriers.some((carrier: any) => carrier.id === carrier_id);
  return isMatch;
};

export const formatBusinessVerticalService = (business_vertical: any): any => {
  if (!business_vertical?.fields) return [];
  const extractedKeys = Object.keys(business_vertical?.fields)
    .filter((key) => key.startsWith('services.') && business_vertical.fields[key] === true)
    .map((val) => {
      return ServicesMap[val.split('.')[1]]?.name;
    });
  return extractedKeys;
};

export const getServicesFieldObject = (
  business_vertical_services: string[][],
  sessionData: SessionDataValue,
  involved_branch: any
) => {
  const default_company = sessionData?.company_account?.default_company;
  const mergedObject = business_vertical_services.reduce((acc: any, path: string[]) => {
    let temp = acc;
    path.forEach((key: string, index: number) => {
      if (index === path.length - 1) {
        temp[key] = true;
      } else {
        temp[key] = temp[key] || {};
      }
      temp = temp[key];
    });
    return acc;
  }, {});
  const partyObject = business_vertical_services.reduce((acc: any, path: string[]) => {
    const key = `${path.join('_')}_agent`;
    acc[key] = {
      party_company: default_company,
      party_address: involved_branch?.default_address,
    };
    return acc;
  }, {});
  return { services: mergedObject, party: partyObject };
};
export const markDefaultService = (business_vertical_services: string[][]) => {
  const mergedObject = business_vertical_services.reduce((acc: any, path: string[]) => {
    let temp = acc;
    path.forEach((key: string, index: number) => {
      if (index === path.length - 1) {
        temp[key] = false;
      } else {
        temp[key] = temp[key] || {};
      }
      temp = temp[key];
    });
    return acc;
  }, {});
  return { services: mergedObject };
};

export const removeBusinessVerticalService = (business_vertical: any) => {
  if (!business_vertical.fields) return {};
  const extractedObject = Object.keys(business_vertical?.fields)
    .filter((key) => !key.startsWith('services.') && key !== 'services')
    .reduce((acc: any, key: string) => {
      acc[key] = business_vertical?.fields[key];
      return acc;
    }, {});
  return extractedObject;
};

export const setFormValueOfBusinessVertical = (
  business_vertical: BusinessVerticalValue,
  form: FormInstance,
  sessionData: SessionDataValue,
  involved_branch: string
) => {
  form?.setFieldsValue({ ...removeBusinessVerticalService(business_vertical) });
  const default_services_map: string[][] = default_services.map((ser) => ser?.name);
  const business_vertical_services = formatBusinessVerticalService(business_vertical);
  form.setFieldsValue({ ...markDefaultService(default_services_map) });
  form?.setFieldsValue({
    ...getServicesFieldObject(business_vertical_services, sessionData, involved_branch),
  });
};
