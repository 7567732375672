import { CompanyValue, CompanyAccountsData } from 'network/models/Company';

import { pick as _pick, omit as _omit } from 'lodash';

export const getFormInitialValues = (
  company: CompanyValue,
  companyAccountsData?: CompanyAccountsData
) => {
  const payable_accounts = (companyAccountsData?.payable_accounts || []).map((st) =>
    _omit(st, '__typename')
  );
  const formPayload = {
    ..._pick(company, ['id', 'company_type']),
    ..._pick(companyAccountsData, [
      'payable_tax_withholding_category',
      'default_currency',
      'payable_payment_terms',
      'is_msme',
      'credit_overdue_limit',
    ]),
    payable_accounts,
    is_msme: companyAccountsData?.is_msme || 0,
  };
  return formPayload;
};
