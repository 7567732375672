import { ApolloClient, ApolloLink, HttpLink, InMemoryCache } from '@apollo/client';
import { BatchHttpLink } from '@apollo/client/link/batch-http';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { showLogoutModal } from 'common/utils/axiosDefaults';
import { message as Message } from '@shipmnts/pixel-hub';

const possibleTypes = require(`./fragmentTypes_${process.env.REAL_ENV}.json`);

const cache = new InMemoryCache({ possibleTypes });

const baseUrl = process.env.GRAPHQL_API_URL;

const defaultOptions = {
  watchQuery: {
    fetchPolicy: 'cache-and-network',
    errorPolicy: 'all',
  },
  query: {
    fetchPolicy: 'cache-first',
    errorPolicy: 'all',
  },
  mutate: {
    errorPolicy: 'all',
  },
};

let client = null;

async function _create(getToken, logoutUser, graphqlUrl) {
  try {
    let link = graphqlUrl || baseUrl;
    const httpLink = new BatchHttpLink({
      uri: link,
      credentials: 'same-origin',
      batchMax: 5,
      batchInterval: 30,
      batchDebounce: true,
    });
    const activtiesLink = new HttpLink({
      uri: process.env.ACTIVITIES_API_ENPOINT,
      credentials: 'same-origin',
      batchMax: 5,
      batchInterval: 30,
      batchDebounce: true,
    });

    const authMiddleware = setContext(async (req, { headers, operation }) => {
      const token = await getToken();

      return {
        headers: {
          ...headers,
          authorization: token ? `Bearer ${token}` : '',
        },
      };
    });

    const logoutLink = onError(({ graphQLErrors, networkError, operation }) => {
      const context = operation.getContext();
      if (graphQLErrors) {
        graphQLErrors.forEach(({ message, locations, path }) => {
          console.log(
            `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
          );
          if (!context?.skipShowError) Message.error(message);
        });
      }
      if (networkError) console.error(`[Network error]: ${networkError}`);
      if (networkError?.statusCode === 401 && logoutUser) {
        showLogoutModal(networkError, logoutUser);
      }
    });

    const splitLink = ApolloLink.split(
      (operation) => operation.getContext().clientName === 'activities',
      activtiesLink, // if clientName is 'activities'
      httpLink // otherwise use httpLink
    );

    const apolloLink = ApolloLink.from([authMiddleware, logoutLink, splitLink]);

    return new ApolloClient({
      link: apolloLink,
      cache,
      defaultOptions,
      connectToDevTools: process.env.REAL_ENV !== 'production',
    });
  } catch (e) {
    console.error(e);
  }
}

export async function getClient(getToken, logoutUser, graphqlUrl = '') {
  if (!client) client = await _create(getToken, logoutUser, graphqlUrl);
  return client;
}

// export async function updateClient(companyId, userId, token) {
//   client = await _create(companyId, userId, token);
//   return client;
// }

// export function resetApolloClient() {
//   client = null;
// }
