import { CompanyValue, CompanyAccountsData } from 'network/models/Company';
import { pick as _pick } from 'lodash';

export const getFormInitialValues = (
  company: CompanyValue,
  companyAccountsData?: CompanyAccountsData
) => {
  const formPayload = {
    ..._pick(company, ['id', 'receivable_credit_control_type']),
    ..._pick(companyAccountsData, [
      'receivable_credit_limit',
      'receivable_payment_terms',
      'credit_overdue_limit',
    ]),
    status: company.status,
  };
  return formPayload;
};
