import React, { useCallback, useState } from 'react';

import './style.css';
import { useMutation } from '@apollo/client';
import {
  Button,
  Col,
  DownOutlined,
  Dropdown,
  Form,
  message,
  Modal,
  Row,
  Space,
} from '@shipmnts/pixel-hub';
import { isSalesPersonPresentInTeam } from 'common';
import { CustomIcon } from '@shipmnts/pixel-hub';
import { CREATE_INQUIRY } from 'sales_hub/graphql/inquiry';
import { createInquiryParamsFromForm } from './common';
import InquiryForm from './InquiryForm/InquiryForm';
import { LOAD_TYPE_OPTIONS, SERVICES, TypeFreightType } from 'sales_hub/utils/constants';

type Props = {
  onSuccess?: () => void;
  data?: any;
  visible: boolean;
  setVisible: (isVisible: boolean) => void;
};

const CreateInquiryDrawer = (props: Props) => {
  const { onSuccess, setVisible } = props;

  // states
  const [keepModalAlive, setKeepModalAlive] = useState(true);
  const [showSearchRate, setShowSearchRate] = useState(false);

  // Context
  const [form] = Form.useForm();

  // Queries/Mutations
  const [createInquiry, { loading }] = useMutation(CREATE_INQUIRY);

  const onClose = () => {
    setKeepModalAlive(false);
    setShowSearchRate(false);
    if (props.setVisible) {
      form.resetFields();
      props.setVisible(false);
    }
  };

  const setInitialValues = useCallback(() => {
    if (props.data) {
      const { fields } = props.data;
      fields &&
        Object.entries(fields).forEach((entry) => {
          const field = entry[0].split('.');
          form.setFieldValue(field, entry[1]);
          if (entry[0].includes('freight_type') && entry[1]) {
            if (!Object.keys(LOAD_TYPE_OPTIONS).includes(entry[1] as string)) return;
            const freight_type = entry[1] as TypeFreightType;
            form.setFieldValue('load_type', LOAD_TYPE_OPTIONS[freight_type || 'air'][0].value);
          }
        });
    }
  }, [form, props.data]);

  const onFinish = useCallback(
    (values: any, callback?: (inquiry_id: string) => Promise<void>) => {
      const fields = values.business_vertical?.toJSON()?.fields;
      // when creating an inquiry from quick form,
      // assigning services linked to business vertical
      if (!values?.services && !!fields) {
        const keys = Object.keys(fields);
        values.services = SERVICES.filter((service) =>
          keys.includes(`services.${service.value}`)
        ).reduce((acc, service) => {
          return {
            ...acc,
            [service.value]: true,
          };
        }, {});
      }
      const inquiry = createInquiryParamsFromForm(values);
      if (!isSalesPersonPresentInTeam(inquiry.teams)) {
        message.error('Please enter sales person details in team');
        return;
      }
      createInquiry({
        variables: {
          inquiry: inquiry,
        },
      })
        .then((res) => {
          if (res?.data && !res?.errors) {
            message.success({
              content: (
                <>
                  {`Created Inquiry ${res.data?.create_inquiry?.inquiry_number} Successfully, `}
                  <span style={{ color: 'blue', cursor: 'pointer' }}>
                    <u>Click here to view</u>
                  </span>
                </>
              ),
              duration: 5,
              onClick: () =>
                window.open(
                  `/view/rfq/${res.data?.create_inquiry?.inquiry_global_id}?first_load=1`,
                  '_blank'
                ),
            });

            const updateStates = () => {
              form.resetFields();
              if (!keepModalAlive && setVisible) {
                setVisible(false);
              } else {
                setInitialValues();
              }
              if (onSuccess) onSuccess();
              if (showSearchRate) {
                const link = `/view/rfq/${res.data?.create_inquiry?.inquiry_global_id}?first_load=1`;
                setShowSearchRate(false);
                window.open(`${link}&search_rate=1&tab=quotation`, '_blank');
              }
              setKeepModalAlive(false);
            };

            // need to run this in both the scenarios,
            // 1. callback available (detail form)
            // 2. callback not available (quick form)
            callback?.(res.data?.create_inquiry?.id)?.then(updateStates) ?? updateStates();
          }
        })
        .catch(() => {
          // incase of failures, reset states to default values
          setKeepModalAlive(true);
          setShowSearchRate(false);
        });
    },
    [createInquiry, keepModalAlive, setVisible, onSuccess, showSearchRate, form, setInitialValues]
  );

  return (
    <Modal
      centered
      open={props.visible}
      width={'90vw'}
      title={
        <div style={{ display: 'flex' }}>
          <CustomIcon icon="CompassIcon" height={24} width={24} style={{ paddingRight: '5px' }} />
          Log New Inquiry
        </div>
      }
      onCancel={onClose}
      destroyOnClose
      footer={
        <Row gutter={16} style={{ justifyContent: 'end' }}>
          <Col>
            <Dropdown
              menu={{
                items: [
                  {
                    key: 1,
                    label: 'Create Inquiry',
                    onClick: () => {
                      setKeepModalAlive(false);
                      form.submit();
                    },
                  },
                  {
                    key: 2,
                    label: 'Save & Create New',
                    onClick: () => {
                      setKeepModalAlive(true);
                      form.submit();
                    },
                  },
                ],
              }}
            >
              <Button size="small" disabled={loading} loading={loading}>
                <Space>
                  {'Create Inquiry'}
                  <DownOutlined />
                </Space>
              </Button>
            </Dropdown>
          </Col>
          <Col>
            <Button
              type="primary"
              size="small"
              onClick={() => {
                setKeepModalAlive(false);
                setShowSearchRate(true);
                form.submit();
              }}
              loading={loading}
            >
              {'Search Rates'}
            </Button>
          </Col>
        </Row>
      }
    >
      <InquiryForm form={form} data={props?.data} onFinish={onFinish} />
    </Modal>
  );
};

export default CreateInquiryDrawer;
