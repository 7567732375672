import { gql } from '@apollo/client';
import { companyFields } from './company';
import { userContactFields } from './contact';

export const userAccountFields = gql`
  fragment userAccountFields on UserAccount {
    id
    first_name
    last_name
    email
    username
  }
`;

export const taskResourceFields = gql`
  fragment taskResourceFields on TaskResourceObjectType {
    id
    resource_type
    resource_id
    resource {
      ... on CompanyObjectType {
        ...companyFields
      }
      ... on UserContactObjectType {
        ...userContactFields
      }
      ... on ShipmentObject {
        id
        pretty_display
      }
    }
  }
  ${companyFields}
  ${userContactFields}
`;

export const taskFields = gql`
  fragment taskFields on TaskObjectType {
    id
    title
    description
    task_type
    due_date
    parent_task {
      id
    }
    priority
    closed_date
    task_status
    task_resources {
      ...taskResourceFields
    }
    primary_resource_id
    primary_resource_type
    assigned_to {
      ...userAccountFields
    }
    closed_by {
      ...userAccountFields
    }
    class_name
  }
  ${taskResourceFields}
  ${userAccountFields}
`;

export const FETCH_TASKS = gql`
  query fetch_tasks(
    $resource: TaskResourceInputType
    $filters: [WorkspaceFilterInputType]
    $sort: [WorkspaceSortInputType]
  ) {
    fetch_tasks(resource: $resource, filters: $filters, sort: $sort) {
      ...taskFields
    }
  }
  ${taskFields}
`;

export const GET_TASK = gql`
  query task($id: ID!) {
    task(id: $id) {
      ...taskFields
    }
  }
  ${taskFields}
`;
