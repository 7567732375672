import { Button, Typography, PrintPreviewDocType, FilePdfOutlined } from '@shipmnts/pixel-hub';
import React, { useEffect, useState } from 'react';
import BillingFilter from '../BillingFilters';
import { axiosInstance as axios } from 'common/utils/axiosDefaults';
import { useMutation } from '@apollo/client';
import { CREATE_STORAGE_JOB } from '../../graphql/stockLedger';
import {
  generateCreateShipmentPayload,
  STORAGE_REPORT,
  transformFormData,
} from '../BillingFilterHelpers';
import { BillingReportProvider, useBillingReportContext } from '../BillingReportProvider';
import StorageReportTable from './StorageReportTable';
const StorageReport = () => {
  const { form, setReportRows, reportRows } = useBillingReportContext();
  const [dataLoading, setDataLoading] = useState<boolean>(false);
  const [context, setContext] = useState<any>(null);
  const [showPreview, setShowPreview] = useState<boolean>(false);
  const [createStorageJob, { data, loading: jobLoading }] = useMutation(CREATE_STORAGE_JOB);

  useEffect(() => {
    if (data?.create_storage_job) {
      window.open(`/view/shipment/${data?.create_storage_job?.shipment?.id}`, '_blank');
    }
  }, [data]);
  const fetchData = async (values: any) => {
    const payload = transformFormData(values);
    try {
      setDataLoading(true);
      const response = await axios.post(
        `${process.env.REPORT_MANAGER_API_URL}/wms_billing/execute`,
        payload
      );
      const responseData = {
        data: response.data.data,
        total_balance: response.data.total_balance,
        opening_balance: response.data.opening_balance,
        closing_balance: response.data.closing_balance,
      };
      setReportRows(responseData);
      setContext({ ...payload }); //Setting context data for PDF Preview with generated report data
      return responseData;
    } catch (error) {
      console.error(error);
      return { error };
    } finally {
      setDataLoading(false);
    }
  };

  const generateJob = (values: any) => {
    createStorageJob({
      variables: values,
    });
  };

  return (
    <>
      <div
        style={{
          borderBottom: '1px solid #dadee7',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: '10px 24px',
        }}
      >
        <Typography.Title level={4} style={{ margin: 0 }}>
          Warehouse Management / Storage Charges
        </Typography.Title>

        <div>
          <Button
            disabled={dataLoading}
            loading={dataLoading}
            size="small"
            htmlType="submit"
            onClick={form.submit}
            style={{ fontWeight: 'bold', marginRight: '10px' }}
            type="primary"
          >
            Generate
          </Button>
          <Button
            loading={jobLoading}
            size="small"
            onClick={() => generateJob(generateCreateShipmentPayload(context))}
            disabled={
              jobLoading ||
              dataLoading ||
              !context ||
              !reportRows?.closing_balance?.wms_stock_ledgers_storage_amount
            }
          >
            Create Job
          </Button>
          <Button
            disabled={!context}
            size="small"
            style={{ marginLeft: '10px' }}
            icon={<FilePdfOutlined />}
            onClick={() => setShowPreview(true)}
          >
            PDF
          </Button>
          {showPreview && (
            <PrintPreviewDocType
              docType={'Wms::WarehouseBilling'}
              resource_id={'report'}
              context={context}
              onClose={() => setShowPreview(false)}
            />
          )}
        </div>
      </div>

      <BillingFilter onSuccess={fetchData} report_type={STORAGE_REPORT} />
      <StorageReportTable />
    </>
  );
};

const StorageReportLayout = () => {
  return (
    <BillingReportProvider>
      <StorageReport />
    </BillingReportProvider>
  );
};

export default StorageReportLayout;
