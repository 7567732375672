import { message, dayjs, FormInstance } from '@shipmnts/pixel-hub';
import { startCase as _startCase, omit as _omit, pick, omit } from 'lodash';
import {
  CANCELLED,
  STATUS_DRAFT,
  STATUS_SUBMITTED,
  TRANSACTION_TYPE_INCOMING,
  TRANSACTION_TYPE_DELIVERY,
  TRANSACTION_TYPE_OUTGOING,
  TRANSACTION_TYPE_RECEIPT,
  WarehouseTransactionValue,
  TRANSACTION_TYPE_GTO,
} from './models/WarehouseTransaction';
import { WarehouseTransactionProductValue } from './models/WarehouseTransactionProduct';

export const freight_type = [
  { key: 'air_import', value: 'Air Import', default: true },
  { key: 'air_export', value: 'Air Export' },
  { key: 'ocean_import', value: 'Ocean Import' },
  { key: 'ocean_export', value: 'Ocean Export' },
  { key: 'road_import', value: 'Road Import' },
  { key: 'road_export', value: 'Road Export' },
];

export const dimension_unit = [
  { value: 'cms', label: 'cms' },
  { value: 'inches', label: 'inches' },
  { value: 'meter', label: 'meter' },
];

export const weight_unit = [
  { value: 'kgs', label: 'kgs' },
  { value: 'tons', label: 'tons' },
  { value: 'lbs', label: 'lbs' },
];

export const transactionTypeToDocType: Record<string, string> = {
  incoming_shipment: 'Wms::IncomingShipment',
  receipt: 'Wms::Receipt',
  outgoing_shipment: 'Wms::OutgoingShipment',
  delivery: 'Wms::Delivery',
  goods_transfer: 'Wms::WarehouseTransaction', // Goods Transfer has no workspace hence on summary screen back button redirect to All Transaction Workspace.
};

const typeToTitleMapping: Record<string, string> = {
  incoming_shipment: 'Incoming Shipment',
  receipt: 'Receipt Note',
  delivery: 'Delivery',
  outgoing_shipment: 'Outgoing Shipment',
  goods_transfer: 'Goods Transfer',
};

export const convertToKgs = (uom: string, value: number) => {
  if (uom === 'lbs') return value * 0.453;
  else if (uom === 'tons') return value * 1000;
  return value;
};

export const convertToMtr = (uom: string, value: number) => {
  if (uom === 'meter') return value;
  else if (uom === 'inches') return value * 0.0254;
  return value * 0.01;
};

export const TRANSACTION_STATUS_COLOR_HASH = {
  [STATUS_DRAFT]: 'primary_blue',
  [STATUS_SUBMITTED]: 'green',
  [CANCELLED]: 'red',
};

export const TRANSACTION_STATUS_DISPLAY_HASH = {
  [STATUS_DRAFT]: 'Draft',
  [STATUS_SUBMITTED]: 'Submitted',
  [CANCELLED]: 'Cancelled',
};

export const GTO_PURPOSE_OF_TRANS = [
  { key: 'Put-Away', value: 'Put-Away' },
  { key: 'Picking', value: 'Picking' },
];

export const VEHICLE_TYPES = [
  { label: '1 TON PICKUP', value: '1 TON PICKUP' },
  { label: '3 TON PICKUP', value: '3 TON PICKUP' },
  { label: '7 TON PICKUP', value: '7 TON PICKUP' },
  { label: '10 TON PICKUP', value: '10 TON PICKUP' },
  { label: '12 MTR TRAILER', value: '12 MTR TRAILER' },
  { label: '15 MTR TRAILER', value: '15 MTR TRAILER' },
  { label: '20 OPEN TOP', value: '20 OPEN TOP' },
  { label: "20' DC", value: "20' DC" },
  { label: "20' FLAT RACK", value: "20' FLAT RACK" },
  { label: "20' HC", value: "20' HC" },
  { label: "20' REEFER", value: "20' REEFER" },
  { label: "20' STANDARD", value: "20' STANDARD" },
  { label: "20' GP", value: "20' GP" },
  { label: '40 FEET HIGH CUBE', value: '40 FEET HIGH CUBE' },
  { label: '40 FEET REEFER CONTAINER', value: '40 FEET REEFER CONTAINER' },
  { label: '40 FEET STANDARD', value: '40 FEET STANDARD' },
  { label: '40 OPEN TOP', value: '40 OPEN TOP' },
  { label: "40' DC", value: "40' DC" },
  { label: "40' FLAT RACK", value: "40' FLAT RACK" },
  { label: "40' HC", value: "40' HC" },
  { label: '45 FEET HIGH CUBE', value: '45 FEET HIGH CUBE' },
  { label: 'FLAT RACK', value: 'FLAT RACK' },
  { label: 'TRUCK', value: 'TRUCK' },
];
export const calculateTotalQty = (data: any) => (data.qty || 1) + (data.damage_qty || 0) || 1;

const calculatePerPackVolume = (data: any) => {
  const length = convertToMtr(data?.dimension_unit, data?.length || 0);
  const breadth = convertToMtr(data?.dimension_unit, data?.breadth || 0);
  const height = convertToMtr(data?.dimension_unit, data?.height || 0);
  return length * breadth * height;
};
export const calculateGrossVolume = (data: any) => {
  return (data.per_pack_volume || 0) * (data.total_qty || 0);
};
export const calculateGrossWeight = (data: any) => {
  return (data.per_pack_weight || 0) * (data.total_qty || 0);
};
export const calculateVolumetricWeight = (data: any) => {
  return (Math.pow(10, 3) * data.gross_volume) / 6;
};
export const calculateChargeableWeight = (data: any) => {
  return Math.max(convertToKgs(data.weight_unit, data.gross_weight), data.volumetric_weight);
};
export const calculatePackagingDesc = (data: any) => {
  if (!data.packing_type || !data.outer_packing_type) return '';
  else
    return `${(data.outer_qty || 0).toFixed(5)} ${data.outer_packing_type} of ${(
      data.inner_by_outer_qty || 1
    ).toFixed(5)} ${
      typeof data.packing_type === 'object' ? data.packing_type?.packing_type : data.packing_type
    } each`;
};
export const calculateOuterQty = (data: any) => {
  return data.total_qty / (data.inner_by_outer_qty || 1);
};

export const recalculateAndAssign = (params: any, targetField: string) => {
  if (!params || !params.data) return false;
  const data = params.data;
  let newValue = Number(Number(params.newValue || 0).toFixed(5));
  newValue = isNaN(newValue) ? params.newValue : newValue;
  if (['length', 'breadth', 'height', 'dimension_unit'].includes(targetField)) {
    data[targetField] = newValue;
    data.per_pack_volume = calculatePerPackVolume(data);
    data.gross_volume = calculateGrossVolume(data);
    data.volumetric_weight = calculateVolumetricWeight(data);
    data.chargeable_weight = calculateChargeableWeight(data);
  } else if (targetField === 'per_pack_volume') {
    data[targetField] = newValue;
    data.gross_volume = calculateGrossVolume(data);
    data.volumetric_weight = calculateVolumetricWeight(data);
    data.chargeable_weight = calculateChargeableWeight(data);
  } else if (targetField === 'gross_volume') {
    data[targetField] = newValue;
    data.volumetric_weight = calculateVolumetricWeight(data);
    data.chargeable_weight = calculateChargeableWeight(data);
    data.per_pack_volume = newValue / data.total_qty;
  } else if (targetField === 'volumetric_weight') {
    data[targetField] = newValue;
    data.chargeable_weight = calculateChargeableWeight(data);
  } else if (targetField === 'chargeable_weight') {
    data[targetField] = newValue;
  } else if (targetField === 'per_pack_weight') {
    data[targetField] = newValue;
    data.gross_weight = calculateGrossWeight(data);
    data.chargeable_weight = calculateChargeableWeight(data);
  } else if (['gross_weight', 'weight_unit'].includes(targetField)) {
    data[targetField] = newValue;
    data.chargeable_weight = calculateChargeableWeight(data);
  } else if (['qty', 'damage_qty'].includes(targetField)) {
    if (!newValue && targetField === 'qty') return false;
    data[targetField] = newValue;
    data.total_qty = calculateTotalQty(data);
    data.outer_qty = calculateOuterQty(data);
    data.packing_desc = calculatePackagingDesc(data);
    data.gross_volume = calculateGrossVolume(data);
    data.gross_weight = calculateGrossWeight(data);
    data.volumetric_weight = calculateVolumetricWeight(data);
    data.chargeable_weight = calculateChargeableWeight(data);
  } else if (targetField === 'outer_qty') {
    if (!newValue) return false;
    data[targetField] = newValue;
    data.inner_by_outer_qty = data.total_qty / newValue;
    data.packing_desc = calculatePackagingDesc(data);
  } else if (targetField === 'inner_by_outer_qty') {
    if (!newValue) return false;
    data[targetField] = newValue;
    data.outer_qty = data.total_qty / newValue;
    data.packing_desc = calculatePackagingDesc(data);
  } else if (targetField === 'outer_packing_type') {
    data[targetField] = newValue;
    data.packing_desc = calculatePackagingDesc(data);
  } else {
    data[targetField] = newValue;
  }
  if (['dimension_unit', 'weight_unit'].includes(targetField)) {
    params.api.refreshCells({
      columns: ['length', 'breadth', 'height', 'per_pack_weight', 'gross_weight'],
      rowNodes: [params.node],
      force: true,
    });
  }
  return true;
};

export const assignPackagingOptions = (primaryPackaging: any, data: any) => {
  if (!!primaryPackaging && !!data) {
    data.length = primaryPackaging.length || data.length;
    data.breadth = primaryPackaging.breadth || data.breadth;
    data.height = primaryPackaging.height || data.height;
    data.dimension_unit = primaryPackaging.dimension_unit || data.dimension_unit;
    data.weight_unit = primaryPackaging.weight_unit || data.weight_unit;
    data.per_pack_volume = primaryPackaging.gross_volume || data.per_pack_volume;
    data.per_pack_weight = primaryPackaging.gross_weight || data.per_pack_weight;
    data.total_qty = calculateTotalQty(data) || data.total_qty;
    data.gross_volume = calculateGrossVolume(data) || data.gross_volume;
    data.gross_weight = calculateGrossWeight(data) || data.gross_weight;
    data.volumetric_weight = calculateVolumetricWeight(data) || data.volumetric_weight;
    data.chargeable_weight = calculateChargeableWeight(data) || data.chargeable_weight;
    data.packing_type = primaryPackaging || data.packing_type;
    data.outer_packing_type = primaryPackaging.outer_packing_type || data.outer_packing_type;
    data.inner_by_outer_qty = primaryPackaging.inner_by_outer_qty || data.inner_by_outer_qty || 1;
    data.outer_qty = calculateOuterQty(data) || data.outer_qty;
    data.packing_desc = calculatePackagingDesc(data) || data.packing_desc;
  }
};

const getTransactionProductPayload = (products: any[], transaction: any, type: string) => {
  const newValue = products;
  const oldValue = (transaction?.warehouse_transaction_products || []).map((product: any) => {
    return {
      ...omit(product, ['__typename', 'product']),
      product_id: product?.product_id,
    };
  });
  if (oldValue) {
    oldValue?.forEach((old: any) => {
      if (!newValue.find((c: any) => c.id === old.id))
        newValue.push({
          ...old,
          _destroy: true,
        });
    });
  }
  const warehouse_transaction_products = (newValue || []).map((product: any) => {
    return {
      ..._omit(product, [
        'product_property',
        'product_packaging_options',
        'from_location',
        'to_location',
        'damage_location',
        'warehouse_transaction',
        '__typename',
        'linked_to',
        'linked_products',
        'compare_id',
        'balance',
        'outer_qty',
        'total_qty',
        'customer',
        'receipt_number',
        'receipt',
      ]),
      receipt_id: product?.receipt?.id || null,
      product_property: { ..._omit(product?.product_property, ['__typename']) },
      from_location_id:
        type === TRANSACTION_TYPE_INCOMING || type === TRANSACTION_TYPE_RECEIPT
          ? null
          : product?.from_location?.id || null,
      to_location_id:
        type === TRANSACTION_TYPE_OUTGOING || type === TRANSACTION_TYPE_DELIVERY
          ? null
          : product?.to_location?.id || null,
      damage_location_id: type === TRANSACTION_TYPE_RECEIPT ? product?.damage_location?.id : null,
      packing_type: product?.packing_type?.packing_type || product?.packing_type,
      qty: product?.qty || 1,
      damage_qty: product?.damage_qty || 0,
      inner_by_outer_qty: product?.inner_by_outer_qty || 1,
      weight_unit: product?.weight_unit || 'kgs',
      dimension_unit: product?.dimension_unit || 'cms',
      invoice_date:
        product?.invoice_date && typeof product?.invoice_date === 'string'
          ? dayjs(product?.invoice_date).unix()
          : product?.invoice_date,
      prod_date:
        product?.prod_date && typeof product?.prod_date === 'string'
          ? dayjs(product?.prod_date).unix()
          : product?.prod_date,
      exp_date:
        product?.exp_date && typeof product?.exp_date === 'string'
          ? dayjs(product?.exp_date).unix()
          : product?.exp_date,
      receipt_date:
        product?.receipt_date && typeof product?.receipt_date === 'string'
          ? dayjs(product?.receipt_date).unix()
          : product?.receipt_date,
      height: product?.height || 0,
      length: product?.length || 0,
      breadth: product?.breadth || 0,
    };
  });
  return warehouse_transaction_products;
};

const getWarehouseTrnasactionProductInitialvalue = (products: any[]) => {
  return (products || []).map((product) => {
    return {
      ...omit(product, ['product']),
      product_packaging_options: product?.product?.product_packaging_options,
      product_id: product?.product?.id || product?.product_id,
      customer: product?.product?.customer?.registered_name || product?.customer,
    };
  });
};

const getTransactionPartiesPayload = (values: any, transaction_id: any, transaction: any) => {
  return [
    {
      id:
        !!transaction_id && transaction_id !== 'new'
          ? transaction?.shipment_parties?.find((e: any) => e.name === 'customer')?.id
          : null,
      name: 'customer',
      party_address_id: values?.customer?.party_address?.id,
      party_company_id: values?.customer?.party_company?.id,
    },
    {
      id:
        !!transaction_id && transaction_id !== 'new'
          ? transaction?.shipment_parties?.find((e: any) => e.name === 'shipper')?.id
          : null,
      name: 'shipper',
      party_address_id: values?.shipper?.party_address?.id,
      party_company_id: values?.shipper?.party_company?.id,
    },
    {
      id:
        !!transaction_id && transaction_id !== 'new'
          ? transaction?.shipment_parties?.find((e: any) => e.name === 'consignee')?.id
          : null,
      name: 'consignee',
      party_address_id: values?.consignee?.party_address?.id,
      party_company_id: values?.consignee?.party_company?.id,
    },
  ];
};

export const getFormTitle = (
  transaction_id: any,
  transaction: WarehouseTransactionValue | undefined,
  type: string
) => {
  if (!!transaction_id && transaction_id !== 'new')
    return `Edit ${typeToTitleMapping[type]} ${transaction?.transaction_number}`;
  else return `Create New ${typeToTitleMapping[type]}`;
};

export const getInitialValue = (
  transaction_id: any,
  transaction: WarehouseTransactionValue | undefined,
  type: string,
  session: any,
  cross_docking?: boolean
) => {
  const value = {
    ...transaction,
    id: transaction_id === 'new' ? undefined : transaction_id,
    customer: pick(
      transaction?.shipment_parties?.find((e) => e.name === 'customer'),
      ['name', 'party_company', 'party_address']
    ),
    shipper: pick(
      transaction?.shipment_parties?.find((e) => e.name === 'shipper'),
      ['name', 'party_company', 'party_address']
    ),
    consignee: pick(
      transaction?.shipment_parties?.find((e) => e.name === 'consignee'),
      ['name', 'party_company', 'party_address']
    ),
    target_delivery_date: transaction?.target_date
      ? dayjs.unix(transaction?.target_date)
      : type === TRANSACTION_TYPE_OUTGOING
      ? dayjs.unix(Date.now() / 1000)
      : null,
    target_receipt_date: transaction?.transaction_date
      ? dayjs.unix(transaction?.transaction_date)
      : type === TRANSACTION_TYPE_INCOMING
      ? dayjs.unix(Date.now() / 1000)
      : null,
    origin_etd_date: transaction?.origin_etd_date ? dayjs.unix(transaction?.origin_etd_date) : null,
    destination_eta_date: transaction?.destination_eta_date
      ? dayjs.unix(transaction?.destination_eta_date)
      : null,
    transaction_date: transaction?.transaction_date
      ? dayjs.unix(transaction?.transaction_date)
      : dayjs.unix(Date.now() / 1000),
    transaction_by: transaction?.transaction_by || {
      ...session,
      id: session.id || session.user_contact_id,
    },
    custom_clearance_date: transaction?.custom_clearance_date
      ? dayjs.unix(transaction?.custom_clearance_date)
      : null,
    customer_ref_date: transaction?.customer_ref_date
      ? dayjs.unix(transaction?.customer_ref_date)
      : null,
    is_battery: transaction?.product_property?.is_battery,
    is_hazardous: transaction?.product_property?.is_hazardous,
    is_temp_controlled: transaction?.product_property?.is_temp_controlled,
    cross_docking: transaction?.cross_docking || cross_docking || false,
    warehouse_transaction_products: getWarehouseTrnasactionProductInitialvalue(
      transaction?.warehouse_transaction_products || []
    ),
  };
  return value;
};

const REQUIRED_FIELDS_CONFIG: {
  [key: string]: string[];
} = {
  [TRANSACTION_TYPE_INCOMING]: ['product_name', 'product_code', 'qty'],
  [TRANSACTION_TYPE_OUTGOING]: ['product_name', 'product_code', 'qty'],
  [TRANSACTION_TYPE_RECEIPT]: [
    'product_name',
    'product_code',
    'packing_type',
    'qty',
    'to_location_id',
  ],
  [TRANSACTION_TYPE_DELIVERY]: [
    'product_name',
    'product_code',
    'packing_type',
    'qty',
    'from_location_id',
  ],
  [TRANSACTION_TYPE_GTO]: [
    'product_name',
    'product_code',
    'packing_type',
    'qty',
    'to_location_id',
    'from_location_id',
  ],
};

const prettify = (value: string) => {
  if (value === 'to_location_id' || value === 'from_location_id') return 'Location';
  else return _startCase(value);
};

const checkRequiredFieldAndGenerateError = (payload: any, type: string) => {
  const requiredFields = REQUIRED_FIELDS_CONFIG?.[type] || [];
  if (Array.isArray(payload.warehouse_transaction_products)) {
    const missingFieldsMessages: string[] = [];
    const newPayload = (payload.warehouse_transaction_products || []).filter(
      (product: any) => !product._destroy
    );

    newPayload.forEach((product: any) => {
      const missingFields = requiredFields.filter(
        (field) => !(product.hasOwnProperty(field) && product[field])
      );
      if (missingFields.length > 0) {
        const formattedFields = missingFields.map((field) => prettify(field));
        missingFieldsMessages.push(formattedFields.join(', ') + ' is required.');
      }
      if (
        Number.isInteger(product.damage_qty) &&
        product.damage_qty > 0 &&
        !product.damage_location_id
      ) {
        missingFieldsMessages.push('Rejected Location is required for Rejected Qty');
      }
    });
    if (missingFieldsMessages.length > 0) {
      missingFieldsMessages.forEach((errMsg) => message.error(errMsg));
      return true;
    }
  }
  return false;
};

export const validateAndGeneratePayload = (
  values: any,
  transaction_id: any,
  transaction: WarehouseTransactionValue | undefined,
  type: string
) => {
  // Write All your custom validations over here for all four transaction form
  const payload = {
    ..._omit(values, [
      'handling_service',
      'value_added_service',
      'target_receipt_date',
      'customer',
      'customer_ref_date',
      'target_delivery_date',
      'shipper',
      'consignee',
      'branch',
      'origin',
      'destination',
      'carrier',
      'received_transporter',
      'delivered_transporter',
      'transaction_by',
      'all_product_properties',
      'all_location',
      'all_from_location',
      'all_to_location',
      'damage_location',
      'target_warehouse',
      'is_battery',
      'is_hazardous',
      'is_temp_controlled',
    ]),
    id: !!transaction_id && transaction_id !== 'new' ? transaction_id : null,
    type: type,
    shipment_parties: getTransactionPartiesPayload(values, transaction_id, transaction),
    customer_company_id: values?.customer?.party_company?.id,
    customer_address_id: values?.customer?.party_address?.id,
    branch_id: values?.target_warehouse?.branch?.id,
    target_warehouse_id: values?.target_warehouse?.id,
    customer_ref_date: values.customer_ref_date?.unix() || null,
    target_date: values.target_receipt_date?.unix() || values.target_delivery_date?.unix() || null,
    carrier_id: values?.carrier?.id,
    received_transporter_id: values?.received_transporter?.id,
    delivered_transporter_id: values?.delivered_transporter?.id,
    origin_id: values?.origin?.id,
    destination_id: values?.destination?.id,
    vehicle_type: values?.vehicle_type || null,
    freight_type: values?.freight_type || null,
    origin_etd_date: values?.origin_etd_date?.unix() || null,
    destination_eta_date: values?.destination_eta_date?.unix() || null,
    transaction_date: values?.transaction_date?.unix() || null,
    custom_clearance_date: values?.custom_clearance_date?.unix() || null,
    transaction_by_id: values?.transaction_by?.id,
    product_property: {
      ...values?.product_property,
      is_battery: values?.is_battery,
      is_hazardous: values?.is_hazardous,
      is_temp_controlled: values?.is_temp_controlled,
    },
    warehouse_transaction_products: getTransactionProductPayload(
      values?.warehouse_transaction_products,
      transaction,
      type
    ),
    total_gross_wt: values?.warehouse_transaction_products?.reduce(
      (total: any, e: any) => (total += e.gross_weight),
      0
    ),
    total_volume_wt: values?.warehouse_transaction_products?.reduce(
      (total: any, e: any) => (total += e.volumetric_weight),
      0
    ),
    total_gross_volume: values?.warehouse_transaction_products?.reduce(
      (total: any, e: any) => (total += e.gross_volume),
      0
    ),
    total_chargeable_wt: values?.warehouse_transaction_products?.reduce(
      (total: any, e: any) => (total += e.chargeable_weight),
      0
    ),
    total_packages: values?.warehouse_transaction_products?.reduce(
      (total: any, e: any) => (total += e.qty),
      0
    ),
  };

  if ((payload?.warehouse_transaction_products?.length || 0) === 0) {
    message.error('Transaction must atleast have one product added.');
    return false;
  }
  const error = checkRequiredFieldAndGenerateError(payload, type);
  if (error) return false;

  return payload;
};

export const setProductsFromImport = (data: any, form: FormInstance) => {
  const warehouse_transaction_products = createDataImportPayload(data);
  const oldProducts = form.getFieldValue('warehouse_transaction_products') || [];
  const products = [...oldProducts, ...warehouse_transaction_products];
  form.setFieldValue('warehouse_transaction_products', products);
};
export const createDataImportPayload = (data: any) => {
  console.log('data import...', data);
  const prefixex = ['wms_warehouse_transaction_products_'];
  const property_prefix = 'property_';
  const keyMapping: { [x: string]: string } = {
    damage_location_id: 'damage_location',
    from_location_id: 'from_location',
    to_location_id: 'to_location',
    product_id: 'product',
  };

  const warehouse_transaction_products = data.map((e: { [x: string]: any }) => {
    const newObject: { [x: string]: any } = {};
    const product_property: { [x: string]: any } = {};
    Object.entries(e).forEach(([key, value]) => {
      if (Number.isNaN(value)) value = null;
      let transformedKey = key;
      // remove prefixes
      prefixex.forEach((prefix) => {
        if (transformedKey.startsWith(prefix)) {
          transformedKey = transformedKey.slice(prefix.length);
        }
      });
      // Apply manual changing of keys
      if (keyMapping[transformedKey]) transformedKey = keyMapping[transformedKey];

      // create property object and assign
      if (transformedKey.startsWith(property_prefix)) {
        transformedKey = transformedKey.slice(property_prefix.length);
        product_property[transformedKey] = value;
      } else if (transformedKey !== 'custom_fields_json') {
        newObject[transformedKey] = value?.record_details || value;
      }
    });
    newObject.product_property = product_property;

    const { product, ...rest } = newObject;
    const masterProduct: { [x: string]: any } = {};
    masterProduct.product_name = product?.product_name;
    masterProduct.product_code = product?.product_code;
    const primaryPackaging = product?.product_packaging_options?.find(
      (e: any) => e.is_primary_packaging
    );
    masterProduct.product_id = product?.id;
    masterProduct.product_property = product?.product_property;
    if (primaryPackaging) {
      masterProduct.packing_type = primaryPackaging;
      masterProduct.length = primaryPackaging.length;
      masterProduct.breadth = primaryPackaging.breadth;
      masterProduct.height = primaryPackaging.height;
      masterProduct.per_pack_volume = primaryPackaging.gross_volume;
      masterProduct.dimension_unit = primaryPackaging.dimension_unit;
      masterProduct.per_pack_weight = primaryPackaging.gross_weight;
      masterProduct.weight_unit = primaryPackaging.weight_unit;
    }
    const updatedNewObject: { [x: string]: any } = {
      ...masterProduct,
      ...rest,
      product_name: masterProduct.product_name || rest.product_name,
      product_code: masterProduct.product_code || rest.product_code,
      product_id: masterProduct.product_id || rest.product_id,
      product_property: masterProduct.product_property || rest.product_property,
      packing_type: masterProduct.packing_type || rest.packing_type,
      length: rest.length || masterProduct.length,
      breadth: rest.breadth || masterProduct.breadth,
      height: rest.height || masterProduct.height,
      per_pack_volume: rest.per_pack_volume || masterProduct.per_pack_volume,
      dimension_unit: rest.dimension_unit || masterProduct.dimension_unit,
      per_pack_weight: rest.per_pack_weight || masterProduct.per_pack_weight,
      weight_unit: rest.weight_unit || masterProduct.weight_unit,
    };
    return { ...updatedNewObject, ...preComputationProduct(updatedNewObject) };
  });
  return warehouse_transaction_products;
};

export const preComputationProduct = (
  product: WarehouseTransactionProductValue | { [x: string]: any }
) => {
  const dimension_unit = product?.dimension_unit || 'meter';
  const weight_unit = product?.weight_unit || 'kgs';
  const total_qty = calculateTotalQty({ qty: product?.qty, damage_qty: product?.damage_qty });
  // If gross volume is not provided, user entered per pack volume is used prior to L X B X H
  const gross_volume =
    product?.gross_volume ||
    (product?.per_pack_volume || calculatePerPackVolume({ ...product, dimension_unit })) *
      total_qty;
  const per_pack_volume = gross_volume / total_qty;
  const gross_weight =
    product?.gross_weight ||
    calculateGrossWeight({ per_pack_weight: product?.per_pack_weight, total_qty });
  const per_pack_weight = product?.per_pack_weight || (product?.gross_weight || 0) / total_qty || 0;
  const volumetric_weight =
    product?.volumetric_weight || calculateVolumetricWeight({ gross_volume });
  const chargeable_weight =
    product?.chargeable_weight ||
    calculateChargeableWeight({
      weight_unit,
      gross_weight,
      volumetric_weight,
    });
  const outer_qty = calculateOuterQty({
    total_qty,
    inner_by_outer_qty: product?.inner_by_outer_qty,
  });
  const packing_desc = calculatePackagingDesc({
    packing_type: product?.packing_type,
    outer_qty,
    outer_packing_type: product?.outer_packing_type,
    inner_by_outer_qty: product?.inner_by_outer_qty,
  });
  return {
    total_qty,
    volumetric_weight,
    chargeable_weight,
    outer_qty,
    packing_desc,
    per_pack_volume,
    per_pack_weight,
    dimension_unit,
    weight_unit,
    gross_volume,
    gross_weight,
  };
};
