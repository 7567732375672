import { CustomIcon } from '@shipmnts/pixel-hub';
import { WarehouseTransactionValue } from '../models/WarehouseTransaction';
import React from 'react';
import { collapsibleCard } from 'operations/modules/shipment/components/DetailLayout/Common/common';

interface WMSShipmentDetailsProps {
  warehouseTransaction: WarehouseTransactionValue;
}
const WMSShipmentDetails = (props: WMSShipmentDetailsProps) => {
  const { warehouseTransaction } = props;
  const ShipmentDetails: any = [
    {
      'BL/AWB #': warehouseTransaction?.shipment_document_number,
    },
    {
      Carrier: warehouseTransaction?.carrier?.name,
    },
    {
      Origin: warehouseTransaction?.origin?.name,
    },
    {
      Destination: warehouseTransaction?.destination?.name,
    },
    {
      'Customs Ref#': warehouseTransaction?.customs_ref_code,
    },
  ];

  if (warehouseTransaction.cross_docking) {
    ShipmentDetails.push({ 'Cross Docking': 'Yes' });
  }
  return (
    <>
      {collapsibleCard({
        icon: <CustomIcon icon="Route" />,
        header: 'Shipment Detail',
        data: ShipmentDetails,
      })}
    </>
  );
};
export default WMSShipmentDetails;
