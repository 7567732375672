import React, { useContext, useEffect, useState } from 'react';
import { useParams, Link, useLocation } from 'wouter';
import { useMutation, useQuery } from '@apollo/client';
import { SHIPMENT_WITH_HOUSE_SHIPMENTS } from 'operations/graphql/shipment';
import {
  Button,
  Layout,
  Skeleton,
  Result,
  hasPermission,
  NoPermissionFallback,
  AppHelmet,
  CustomSiderContextProvider,
  message,
} from '@shipmnts/pixel-hub';
import Shipment, { ShipmentValue } from 'operations/models/Shipment';
import { useSession } from 'common';
import ShipmentDetailHeader from './ShipmentDetailHeader';
import { PERMISSION_SHIPMENT_VIEW } from 'operations/permissions';
import { RoutingLegValue } from 'operations/models/RoutingLeg';
import { CREDIT_STATUS_ON_AUTO_HOLD } from '../../constants';
import { RecentEventEmailDrawer } from 'operations/components/ChangeEvent/EventEmailDrawer';
import ShipmentDetailContent from './ShipmentDetailContent';
import { gql } from '@apollo/client';
import { TicketForm } from 'service_management';

/**
 * Todo:
 * 1. Manifest table - columns??
 * 2. Action Renderer and actions -> update/ endable auto tracking/ disable auto tracking
 * 3. Manifest model
 * 4. Model sharing
 * 5. DO NOT TRY TO REFACTOR EXHISTING CODE!!!!
 * 5. Create Manifest Button/ Form/ Validations / API integration
 * 6. Update manifest form / Validations / API integration
 * 7. Auto tracking cell
 */

interface ShipmentDetailContextProps {
  shipment: ShipmentValue;
  refetchShipments: () => void;
  routingLegs?: RoutingLegValue[];
}

export type ShipmentTabType =
  | 'documents'
  | 'containers'
  | 'attached_houses'
  | 'accounts'
  | 'customs'
  | 'estimates'
  | 'products'
  | 'manifests'
  | 'bookings'
  | 'inbox'
  | undefined;

const UPDATE_TICKET = gql`
  mutation update_ticket($ticket: UpdateTicketInputType!) {
    update_ticket(ticket: $ticket) {
      id
      title
    }
  }
`;
const ShipmentDetailContext = React.createContext<Partial<ShipmentDetailContextProps>>({});
export const useShipmentDetail = () => useContext(ShipmentDetailContext);

const IsFirstLoadFlag = (props: any): boolean => {
  const { location } = props;
  const { search } = location;
  const search_query = new URLSearchParams(search);
  return !!search_query.get('first_load');
};
const ShipmentDetailLayoutWrapper: React.FC = (props: any) => {
  const params = useParams<{ id: string }>();
  const id = props?.id || params?.id;
  if (!id) return <></>;
  return <ShipmentDetailLayout {...props} id={id}></ShipmentDetailLayout>;
};

export const ShipmentDetailLayout = (props: {
  id: string;
  tab?: ShipmentTabType;
  onClose?: () => void;
  externalLink?: boolean;
}) => {
  const { tab, id, onClose, externalLink } = props;
  const [location, navigate] = useLocation();
  const { search } = window.location;
  const search_query = new URLSearchParams(search);
  const [visible, setVisible] = useState(false);
  const [isFirstLoad, setIsFirstLoad] = useState<boolean>(
    IsFirstLoadFlag({ location: window.location })
  );
  const sessionData = useSession();

  // Context
  // Queries / Mutations
  const { loading, error, data, refetch } = useQuery(SHIPMENT_WITH_HOUSE_SHIPMENTS, {
    variables: { shipmentId: id },
    fetchPolicy: 'network-only',
  });
  const [updateTicket, { data: updateData, loading: loadingUpdate, error: updateError }] =
    useMutation(UPDATE_TICKET);
  useEffect(() => {
    if (isFirstLoad) {
      search_query.delete('first_load');
      navigate(`${location}${search_query.toString()}`, { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (updateError) {
      message.error('Failed to delink ticket');
    } else if (updateData) {
      message.success('Ticket delinked successfully');
      refetch();
      if (onClose) onClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateData, loadingUpdate, updateError]);

  useEffect(() => {
    if (!isFirstLoad && !visible) {
      refetch();
    }
  }, [isFirstLoad, visible, refetch]);

  if (loading) {
    return <Skeleton avatar paragraph={{ rows: 3 }} loading={loading} active></Skeleton>;
  }
  if (
    !hasPermission(sessionData?.permissions, {
      name: PERMISSION_SHIPMENT_VIEW,
      docType: 'Shipment::Shipment',
    })
  ) {
    return <NoPermissionFallback action="view" resource="Shipment" />;
  }
  if (error) {
    let errorCode: 500 | 404 = 500,
      title = 'Something went wrong while fetching the shipment.',
      subTitle = error ? error.message : '',
      button = (
        <Button
          onClick={() => {
            if (refetch) {
              refetch();
            }
          }}
          type="primary"
        >
          Retry
        </Button>
      );
    if (error && error.message.includes('Shipment::Shipment for given id Not found')) {
      errorCode = 404;
      title = `Shipment #${id} does not exist`;
      subTitle = '';
      button = (
        <Button type="primary">
          <Link to="/">Go Home</Link>
        </Button>
      );
    }
    return <Result status={errorCode} title={title} subTitle={subTitle} extra={button} />;
  }

  if (!data?.shipment) return <div>Data not found</div>;

  const routingLegs: RoutingLegValue[] = data?.shipment.routing_legs?.filter(
    (leg: any) => leg.routing_type === 'pickup'
  );

  const shipment = Shipment.create(data.shipment);

  const handleTicketRefDelete = (record: any) => {
    const ticket_id = record?.reference_id;
    const shipment_id = data.shipment.id;
    const doc_ref_id = data.shipment.tickets
      .filter((ticket: any) => ticket.id === ticket_id)[0]
      ?.references?.filter(
        (ref: any) =>
          ref.reference_type === 'Shipment::Shipment' && ref.reference_id === shipment_id
      )[0]?.id;
    const refToDelete = {
      id: doc_ref_id,
      reference_id: shipment_id,
      reference_type: 'Shipment::Shipment',
      _destroy: true,
    };

    const updatePayload = {
      ticket: {
        id: ticket_id,
        references: [refToDelete],
      },
    };
    updateTicket({
      variables: updatePayload,
    });
  };
  return (
    <div style={{ height: '94vh', overflowY: 'scroll' }} className="shipment-detail-layout">
      <ShipmentDetailContext.Provider
        value={{
          shipment: shipment,
          refetchShipments: refetch,
          routingLegs: routingLegs,
        }}
      >
        {isFirstLoad &&
          data?.shipment?.id &&
          data?.shipment?.credit_status === CREDIT_STATUS_ON_AUTO_HOLD && (
            <RecentEventEmailDrawer
              setIsFirstLoad={setIsFirstLoad}
              companies_roles_mapping={shipment.getAllPartiesRolesMapping(sessionData)}
              id={data.shipment.id}
            />
          )}
        <AppHelmet>
          <title>{`#${data?.shipment?.job_number || ''} | Shipment`}</title>
        </AppHelmet>
        <Layout>
          <ShipmentDetailHeader onClose={onClose} externalLink={externalLink} />
          <Layout>
            <CustomSiderContextProvider
              refetchData={refetch}
              title="Tickets"
              order={['ServiceManagement::Ticket::Ticket']}
              records={data.shipment.tickets.map((ticket: any) => ({
                id: ticket.id,
                reference_id: ticket.id,
                reference_type: 'ServiceManagement::Ticket::Ticket',
                data: ticket,
              }))}
              onPopConfirmOk={handleTicketRefDelete}
              defaultOpen={false}
              onAddClick={() => setVisible(true)}
              formComponent={
                <>
                  <TicketForm
                    visible={visible}
                    setVisible={setVisible}
                    dataSource={[
                      {
                        link_type: 'Shipment',
                        linked_record: {
                          // ...data.shipment,
                          // job_number: data?.shipment?.job_number,
                          id: data.shipment.id,
                          pretty_display:
                            data?.shipment?.job_number || data?.shipment?.shipment_booking_number,
                          value: data.shipment.id.toString(),
                          // title: data?.shipment?.job_number,
                          // doc_ref_id: data.shipment.id,
                        },
                      },
                    ]}
                  />
                </>
              }
            >
              <ShipmentDetailContent
                externalLink={externalLink}
                data={data}
                tab={tab}
                shipment={shipment}
                id={id}
              />
            </CustomSiderContextProvider>
          </Layout>
        </Layout>
      </ShipmentDetailContext.Provider>
    </div>
  );
};

export default ShipmentDetailLayoutWrapper;
