import React, { useEffect, useState } from 'react';
import {
  Form,
  FormItemProps,
  Select,
  SingleQuickFilter,
  Checkbox,
  FormInstance,
  ADDRESS_LOCATION_TYPES,
  ADDRESS_TYPE_OPTION,
  LOCATION_CITY_TYPE_OPTION,
  AddressLocationSearch,
} from '@shipmnts/pixel-hub';
import { baseConstants } from 'common';
import { getChargeableWeightCondition } from 'sales_hub/utils/utils';
import {
  FREIGHT_TYPE_AIR,
  FREIGHT_TYPE_OCEAN,
  FREIGHT_TYPE_ROAD,
  INQUIRY_OPTIONS_DISPLAY_HASH,
  SERVICE_TYPE_OPTIONS,
} from 'sales_hub/utils/constants';

import { CarrierType, CarrierValue } from 'sales_hub/models/Carrier';
import {
  RateExplorerMoreFilter,
  RateExplorerQuickFilter,
  RateExplorerResource,
} from 'sales_hub/components/Inquiry/RateExplorer/RateExplorer.type';
import { LocationType } from 'sales_hub/models/Location';
import { GlobalSearch } from '@shipmnts/pixel-hub';
import { convertDateToUnix } from 'sales_hub/components/Inquiry/InquiryLostLayout';
import { compact as _compact } from 'lodash';
import {
  DELIVERY_NODE,
  getDefaultNodeType,
  PICKUP_NODE,
} from 'sales_hub/components/Inquiry/InquiryForm/helper';
import { CONTAINER_TYPES } from 'common/baseConstants';
const {
  CARRIER_TYPE_AIR,
  CARRIER_TYPE_COLOADER,
  CARRIER_TYPE_NVOCC,
  CARRIER_TYPE_OCEAN,
  containerTypesFlatArray,
} = baseConstants;
interface getFormItemProp {
  Komonent: any;
  formItemProp: FormItemProps;
  komponentProp?: any;
  form: FormInstance;
}

const GetFormItem: React.FC<getFormItemProp> = (props) => {
  const { formItemProp, Komonent, komponentProp, form } = props;
  const [show, setShow] = useState<boolean>(!!form.getFieldValue(['filters', formItemProp.name])); // Initialize any state needed

  useEffect(() => {
    setShow(!!form.getFieldValue(['filters', formItemProp.name]));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.getFieldValue(['filters', formItemProp.name])]);

  return (
    <>
      <Checkbox
        checked={show}
        onChange={(e) => {
          const checked = e.target.checked;
          if (!checked) {
            form.setFieldValue(['filters', formItemProp.name], undefined);
            form.submit();
          }
          setShow(e.target.checked);
        }}
      >
        {formItemProp.label || ''}
      </Checkbox>
      {show && (
        <div style={{ margin: '10px 0px' }}>
          <Form.Item
            {...formItemProp}
            name={['filters', formItemProp.name]}
            labelCol={{ span: 0 }}
            rules={[{ required: true }]}
          >
            <Komonent {...komponentProp} />
          </Form.Item>
        </div>
      )}
    </>
  );
};

export function rateExplorerFiltersMapping(
  resource: RateExplorerResource,
  form: FormInstance
): Record<string, React.JSX.Element> {
  const freightType = resource.freight_type;
  const locationType: LocationType[] = ['ICD'];

  if (freightType === FREIGHT_TYPE_AIR) locationType.push('Airport');
  if (freightType === FREIGHT_TYPE_OCEAN) locationType.push('Seaport');

  const carrierSearchProp: {
    carrier_type?: Array<CarrierType>;
  } = {
    carrier_type:
      freightType === FREIGHT_TYPE_OCEAN
        ? [CARRIER_TYPE_OCEAN, CARRIER_TYPE_NVOCC, CARRIER_TYPE_COLOADER]
        : [CARRIER_TYPE_AIR],
  };

  return {
    pickup_location: (
      <GetFormItem
        formItemProp={{ name: 'pickup_location', label: 'Pickup Location' }}
        Komonent={AddressLocationSearch}
        form={form}
        komponentProp={{
          onlyShow:
            freightType === FREIGHT_TYPE_ROAD
              ? [...ADDRESS_LOCATION_TYPES]
              : [ADDRESS_TYPE_OPTION, LOCATION_CITY_TYPE_OPTION],
          companies: _compact([resource?.customer_company, resource?.shipper]),
          defaultType:
            getDefaultNodeType(freightType, resource.service_type, PICKUP_NODE) ||
            ADDRESS_TYPE_OPTION,
        }}
      />
    ),
    delivery_location: (
      <GetFormItem
        formItemProp={{ name: 'delivery_location', label: 'Delivery Location' }}
        Komonent={AddressLocationSearch}
        form={form}
        komponentProp={{
          onlyShow:
            freightType === FREIGHT_TYPE_ROAD
              ? [...ADDRESS_LOCATION_TYPES]
              : [ADDRESS_TYPE_OPTION, LOCATION_CITY_TYPE_OPTION],
          companies: _compact([resource?.customer_company, resource?.consignee]),
          defaultType:
            getDefaultNodeType(freightType, resource.service_type, DELIVERY_NODE) ||
            ADDRESS_TYPE_OPTION,
        }}
      />
    ),

    incoterms: (
      <GetFormItem
        formItemProp={{ name: 'incoterms', label: 'Incoterms' }}
        Komonent={GlobalSearch}
        form={form}
        komponentProp={{
          doc_type: 'Global::Incoterms',
        }}
      />
    ),

    ch_wt_slab: (
      <GetFormItem
        formItemProp={{ name: 'ch_wt_slab', label: 'Ch Wt' }}
        Komonent={Select}
        form={form}
        komponentProp={{
          allowClear: true,
          options: [
            { value: 'less_than_45', label: '-45' },
            { value: 'more_than_45', label: '+45' },
            { value: 'more_than_100', label: '+100' },
            { value: 'more_than_200', label: '+200' },
            { value: 'more_than_300', label: '+300' },
            { value: 'more_than_500', label: '+500' },
            { value: 'more_than_1000', label: '+1000' },
          ],
        }}
      />
    ),

    rate_type: (
      <GetFormItem
        formItemProp={{ name: 'rate_type', label: 'Rate Type' }}
        Komonent={Select}
        form={form}
        komponentProp={{
          allowClear: true,
          mode: 'multiple',
          options: [
            { value: 'quote', label: 'Quote' },
            { value: 'job', label: 'Job' },
          ],
        }}
      />
    ),

    carrier: (
      <GetFormItem
        formItemProp={{ name: 'carrier', label: 'Carrier' }}
        Komonent={GlobalSearch}
        form={form}
        komponentProp={{
          doc_type: 'Global::Carrier',
          searchProps: carrierSearchProp,
          selectMode: 'multiple',
        }}
      />
    ),

    place_of_carrier_delivery: (
      <GetFormItem
        formItemProp={{
          name: 'place_of_carrier_delivery',
          label: 'Place Of Carrier Delivery',
        }}
        Komonent={GlobalSearch}
        form={form}
        komponentProp={{
          searchProps: { type: locationType },
          doc_type: 'Global::Location',
        }}
      />
    ),

    place_of_carrier_receipt: (
      <GetFormItem
        formItemProp={{
          name: 'place_of_carrier_receipt',
          label: 'Place Of Carrier Receipt',
        }}
        Komonent={GlobalSearch}
        form={form}
        komponentProp={{
          searchProps: { type: locationType },
          doc_type: 'Global::Location',
        }}
      />
    ),

    quote_status: (
      <GetFormItem
        formItemProp={{ name: 'quote_status', label: 'Quote Status' }}
        Komonent={Select}
        form={form}
        komponentProp={{
          placeholder: 'Select Quote Status',
          mode: 'multiple',
          options: Object.keys(INQUIRY_OPTIONS_DISPLAY_HASH).map((key) => ({
            label: INQUIRY_OPTIONS_DISPLAY_HASH[key],
            value: key,
          })),
          showSearch: true,
        }}
      />
    ),
  };
}

function getValueFromObject(field_name: string) {
  return (obj: any, resource: RateExplorerResource) => {
    const value: any = {};
    const field = field_name + '_id';
    value[field] = Array.isArray(obj) ? obj.map((r) => r?.id) : obj?.id;
    return value;
  };
}

function getCarrierValue(carries: CarrierValue[], resource: RateExplorerResource) {
  const ids = (carries || []).map((ele) => ele.id);
  return {
    carrier_id: ids.length > 0 ? ids : undefined,
  };
}

function getQuoteValidTo(validTo: [string, string], resource: RateExplorerResource) {
  if (validTo?.[0] && validTo?.[1])
    return {
      created_at: [convertDateToUnix(validTo?.[0]), convertDateToUnix(validTo?.[1])],
    };
  return {};
}

function getCustomerValue(customer: string[], resource: RateExplorerResource) {
  if (customer?.[0]) {
    return {
      customer_company_id: customer[0],
    };
  }
  return {};
}

function getLocationIdAndType(location: any) {
  const id = location?.address?.id || location?.location?.id;
  const type = !!location?.address ? 'Network::Address' : 'Global::Location';
  return { id, type };
}

export const MORE_FILTERS: {
  [key: string]: RateExplorerMoreFilter[];
} = {
  [FREIGHT_TYPE_AIR]: [
    { field: 'incoterms' },
    { field: 'rate_type' },
    { field: 'ch_wt_slab', getValue: getChargeableWeightCondition },
    {
      field: 'place_of_carrier_receipt',
      getValue: getValueFromObject('place_of_carrier_receipt'),
    },
    {
      field: 'place_of_carrier_delivery',
      getValue: getValueFromObject('place_of_carrier_delivery'),
    },
    {
      field: 'pickup_location',
      getValue: (pickup_location: any, resource: RateExplorerResource) => {
        const { id, type } = getLocationIdAndType(pickup_location);
        if (!id || !type) return {};
        return {
          origin_id: id,
          origin_type: type,
        };
      },
    },
    {
      field: 'delivery_location',
      getValue: (delivery_location: any, resource: RateExplorerResource) => {
        const { id, type } = getLocationIdAndType(delivery_location);
        if (!id || !type) return {};
        return {
          destination_id: id,
          destination_type: type,
        };
      },
    },
    { field: 'quote_status' },
  ],
  [FREIGHT_TYPE_OCEAN]: [
    { field: 'incoterms' },
    { field: 'rate_type' },
    {
      field: 'place_of_carrier_receipt',
      getValue: getValueFromObject('place_of_carrier_receipt'),
    },
    {
      field: 'place_of_carrier_delivery',
      getValue: getValueFromObject('place_of_carrier_delivery'),
    },
    {
      field: 'pickup_location',
      getValue: getValueFromObject('pickup_location'),
    },
    {
      field: 'delivery_location',
      getValue: getValueFromObject('delivery_location'),
    },
    { field: 'quote_status' },
  ],
  [FREIGHT_TYPE_ROAD]: [{ field: 'quote_status' }, { field: 'rate_type' }],
  custom: [
    { field: 'rate_type' },
    { field: 'carrier', getValue: getCarrierValue },
    { field: 'incoterms' },
    { field: 'quote_status' },
  ],
};

export function getQuickFilters(resource: RateExplorerResource) {
  const freightType = resource.is_custom ? 'custom' : resource.freight_type;
  const allQuickFilters = getAllQuickFilters(resource);
  const currentQuickFilters = (QUICK_FILTERS?.[freightType || ''] || []).map(
    (filter) => allQuickFilters[filter as keyof typeof allQuickFilters]
  );
  const customerQuickFilter = (
    <Form.Item noStyle name={['quick_filters', 'customer_company']}>
      <SingleQuickFilter
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        field={{
          id: 'customer',
          ...allQuickFilters.customer,
          filter_options: `{"values":["${resource.customer_company?.id}"], "display_hash": {"${resource.customer_company?.id}": "${resource.customer_company?.registered_name}"}}`,
        }}
        operator="in"
      />
    </Form.Item>
  );
  return [
    customerQuickFilter,
    ...currentQuickFilters.map((filter: RateExplorerQuickFilter) => (
      <Form.Item
        noStyle
        name={[
          'quick_filters',
          (filter.form_name || filter.label).toLowerCase().replace(/ /g, '_'),
        ]}
        key={filter.label}
      >
        <SingleQuickFilter
          key={filter.label}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          field={{ id: filter.label, ...filter }}
          operator="in"
        />
      </Form.Item>
    )),
  ];
}

export const getAllQuickFilters = (resource: RateExplorerResource) => {
  const freightType = resource.freight_type;
  const carrierSearchProp: {
    carrier_type?: Array<CarrierType>;
  } = {
    carrier_type:
      freightType === FREIGHT_TYPE_OCEAN
        ? [CARRIER_TYPE_OCEAN, CARRIER_TYPE_NVOCC, CARRIER_TYPE_COLOADER]
        : [CARRIER_TYPE_AIR],
  };

  return {
    customer: {
      label: 'Customer',
      form_name: 'customer_company',
      field_type: 'Dropdown',
      getValue: getCustomerValue,
    },
    carrier: {
      label: 'Carrier',
      field_type: 'MultiSearch',
      filter_options: JSON.stringify({
        doc_type: 'Global::Carrier',
        args: carrierSearchProp,
      }),
      getValue: getCarrierValue,
    },
    billing_party: {
      label: 'Vendor',
      form_name: 'billing_party',
      field_type: 'Search',
      filter_options: '{"doc_type":"Network::Company"}',
      getValue: getValueFromObject('billing_party'),
    },
    container_type: {
      label: 'Container Type',
      form_name: 'container_type_code',
      field_type: 'Dropdown',
      filter_options: `{"options":${JSON.stringify(
        containerTypesFlatArray
      )}, "values":${JSON.stringify(
        Object.keys(CONTAINER_TYPES)
      )}, "display_hash": ${JSON.stringify(CONTAINER_TYPES)}}`,
    },
    date: {
      label: 'Created Date',
      form_name: 'created_at',
      field_type: 'DateRange',
      getValue: getQuoteValidTo,
    },
    services: {
      label: 'Services',
      form_name: 'services',
      field_type: 'MultiSearch',
      filter_options: `{"options":${JSON.stringify(SERVICE_TYPE_OPTIONS)}}`,
    },
  };
};

export const QUICK_FILTERS: {
  [key: string]: string[];
} = {
  [FREIGHT_TYPE_AIR]: ['carrier', 'billing_party', 'date', 'services'],
  [FREIGHT_TYPE_OCEAN]: ['carrier', 'billing_party', 'container_type', 'date', 'services'],
  [FREIGHT_TYPE_ROAD]: ['billing_party', 'date', 'services'],
  custom: ['billing_party', 'date', 'services'],
};

export const SORT_FIELDS: {
  [key: string]: { id: string; field_type: string; label: string }[];
} = {
  [FREIGHT_TYPE_AIR]: [
    { id: 'total_sell', label: 'Total Sell', field_type: 'Float' },
    { id: 'total_buy', label: 'Total Buy', field_type: 'Float' },
    { id: 'freight_sell_unit_rate', label: 'Freight Sell', field_type: 'Float' },
    { id: 'freight_buy_unit_rate', label: 'Freight Buy', field_type: 'Float' },
    { id: 'estimated_time_of_departure', label: 'ETD', field_type: 'Date' },
    { id: 'transit_time', label: 'Transit Time', field_type: 'Float' },
  ],
  [FREIGHT_TYPE_OCEAN]: [
    { id: 'total_sell', label: 'Total Sell', field_type: 'Float' },
    { id: 'total_buy', label: 'Total Buy', field_type: 'Float' },
    { id: 'freight_sell_unit_rate', label: 'Freight Sell', field_type: 'Float' },
    { id: 'freight_buy_unit_rate', label: 'Freight Buy', field_type: 'Float' },
    { id: 'estimated_time_of_departure', label: 'ETD', field_type: 'Date' },
    { id: 'transit_time', label: 'Transit Time', field_type: 'Float' },
  ],
  [FREIGHT_TYPE_ROAD]: [
    { id: 'total_sell', label: 'Total Sell', field_type: 'Float' },
    { id: 'total_buy', label: 'Total Buy', field_type: 'Float' },
    { id: 'estimated_time_of_departure', label: 'ETD', field_type: 'Date' },
    { id: 'transit_time', label: 'Transit Time', field_type: 'Float' },
  ],
  custom: [
    { id: 'total_sell', label: 'Total Sell', field_type: 'Float' },
    { id: 'total_buy', label: 'Total Buy', field_type: 'Float' },
    { id: 'estimated_time_of_departure', label: 'ETD', field_type: 'Date' },
    { id: 'transit_time', label: 'Transit Time', field_type: 'Float' },
  ],
};
