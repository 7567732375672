import React, { useEffect } from 'react';
import { useLocation } from 'wouter';
import { CREATE_HANDLING_JOB } from '../graphql/stockLedger';
import { useMutation } from '@apollo/client';
import { defaultFilters, fieldsRequired } from '../Reports/HandlingReport/HandlingReportLayout';
import { dayjs } from '@shipmnts/pixel-hub';
const CreateWarehouseJobAction = (props: any) => {
  const { transaction, onSuccess, onClose } = props;
  const [createWarehouseShipment, { data, error }] = useMutation(CREATE_HANDLING_JOB);
  const { 1: navigate } = useLocation();

  useEffect(() => {
    if (data?.create_handling_job) {
      window.open(`/view/shipment/${data?.create_handling_job?.shipment?.id}`, '_blank');
      onSuccess && onSuccess();
    } else if (error) onClose && onClose();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, navigate]);
  useEffect(() => {
    createWarehouseShipment({
      variables: {
        customer_company_id: transaction?.customer_company?.id,
        branch_id: transaction?.branch?.id,
        definitions: {
          fields: fieldsRequired.map((e) => {
            return {
              id: e,
            };
          }),
          filters: [
            {
              field_id: 'wms_warehouse_transactions_transaction_number',
              condition: {
                type: 'equals',
                value: transaction?.transaction_number,
              },
            },
            {
              field_id: 'wms_warehouse_transactions_transaction_date',
              condition: {
                type: 'dateRange',
                values: [
                  dayjs.unix(transaction?.transaction_date),
                  dayjs.unix(transaction?.transaction_date),
                ],
              },
            },
          ],
          doc_type_id: 'NewAccounting::ShipmentEstimate',
          default_filters: defaultFilters,
          apply_limit_offset: false,
        },
      },
    });
  }, [transaction, createWarehouseShipment]);

  return <></>;
};

export default CreateWarehouseJobAction;
