import { gql } from '@apollo/client';

export const VALIDATE_EMAIL_CONTACTS = gql`
  query validate_email_contacts($emails: [String!]!, $looped_in_parties: [String!]!) {
    validate_email_contacts(emails: $emails, looped_in_parties: $looped_in_parties)
  }
`;

export const FETCH_EMAIL_TEMPLATES = gql`
  query email_templates(
    $limit: Int
    $offset: Int
    $filters: [FilterInputType!]
    $sort: [SortInputTypeNew!]
  ) {
    email_templates(limit: $limit, offset: $offset, filters: $filters, sort: $sort) {
      total
      data {
        id
        template_name
        action_name
        auto_attachment_tags
        default_parties
        allowed_parties
        resource_type
        has_email
        has_whatsapp
        pivot_fields
      }
    }
  }
`;

export const BULK_SEND_EMAIL = gql`
  mutation bulk_send_email($action_name: String!, $resource_ids: [ID!]!) {
    bulk_send_email(action_name: $action_name, resource_ids: $resource_ids) {
      message
    }
  }
`;
