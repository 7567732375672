import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { FETCH_LOCATIONS, REMOVE_WAREHOUSE_UNIT } from './graphql/warehouseUnit';
import CreateWarehouseUnit from './CreateWarehouseUnit';
import {
  Col,
  Modal,
  Row,
  Space,
  TreeDataNode,
  TreeProps,
  message,
  Button,
  DownOutlined,
  UpOutlined,
  Skeleton,
  Tree,
} from '@shipmnts/pixel-hub';
import { WarehouseUnitValue } from './models/WarehouseUnit';
import { useSession } from 'common';
import {
  PERMISSION_WAREHOUSE_CREATE,
  PERMISSION_WAREHOUSE_DELETE,
  PERMISSION_WAREHOUSE_EDIT,
} from 'operations/permissions';
import { WithPermission } from '@shipmnts/pixel-hub';

const WarehouseUnitTree = () => {
  const session = useSession();
  const { data, loading, refetch } = useQuery(FETCH_LOCATIONS, {
    variables: {
      branch_id: (session?.branch_accounts || []).map((obj: any) => obj.id),
    },
  });
  const [removeUnit, { data: removeUnitData }] = useMutation(REMOVE_WAREHOUSE_UNIT);
  const [treeData, setTreeData] = useState<TreeDataNode[]>([]);
  const [visible, setVisible] = useState<boolean>(false);
  const [parentId, setParentId] = useState<string>();
  const [formType, setFormType] = useState<'new' | 'edit' | 'add'>('new');
  const [expandedKeys, setExpandedKeys] = useState<string[]>([]);
  const [key, setKey] = useState<number>(0);
  const [treeReady, setTreeReady] = useState<boolean>(false);
  const loadIncrement = 30;
  const [loadedCounts, setLoadedCounts] = useState<{ [key: string]: number }>({});
  const toggleKey = (key: number) => {
    setKey(1 - key);
    setTreeReady(true);
  };
  const refetchData = useCallback(() => {
    setTreeReady(false);
    refetch();
  }, [refetch]);
  const idToNodeMapping = useMemo(() => {
    const mapping: { [key: string]: WarehouseUnitValue } = {};
    if (data?.get_locations) {
      (data?.get_locations || []).forEach((e: any) => {
        mapping[e?.id] = e;
      });
    }
    return mapping;
  }, [data]);
  const nodeToChildsUnitsMap = useMemo(() => {
    const mapping: { [key: string]: Array<string> } = {};
    if (data?.get_locations) {
      data.get_locations.forEach((node: any) => {
        const parentId = node?.parent_unit_id;
        const nodeId = node?.id;
        if (parentId) {
          if (!mapping[parentId]) {
            mapping[parentId] = [];
          }
          mapping[parentId].push(nodeId);
        }
      });
    }
    return mapping;
  }, [data]);

  const getTreeNodeWithId = (id: string, expandedNodes: string[]) => {
    const node = idToNodeMapping[id];
    if (!node) return;
    expandedNodes.push(node.id);
    const childUnits: TreeDataNode[] = [];
    (nodeToChildsUnitsMap[node.id] || []).slice(0, loadedCounts[node.id]).forEach((e: any) => {
      const childNode = getTreeNodeWithId(e, expandedNodes);
      if (childNode) childUnits.push(childNode);
    });
    if ((nodeToChildsUnitsMap[node.id] || []).length > loadedCounts[node.id]) {
      childUnits.push({
        title: (
          <span>
            <button
              className="node-action-btn"
              onClick={() => {
                setLoadedCounts((prevCounts) => ({
                  ...prevCounts,
                  [node.id]: prevCounts[node.id] + loadIncrement,
                }));
              }}
            >
              <DownOutlined /> Load More
            </button>
          </span>
        ),
        key: `${node.id}load-more`,
        isLeaf: true,
      });
    }
    return {
      title: (
        <span className="warehouse-name">
          {' '}
          {node.node_name}
          <span id={node.id} className="warehouse-actions">
            {getActionButtons(node.id)}
          </span>
        </span>
      ),
      key: `${node.id}`,
      isLeaf: !(nodeToChildsUnitsMap[node.id] || [])?.length,
      children: childUnits,
    };
  };

  const getExpandedTree = useCallback(() => {
    const initialTree: TreeDataNode[] = [];
    const expandedNodes: string[] = [];
    (data?.get_locations || []).forEach((element: WarehouseUnitValue) => {
      if (element.node_type === 'warehouse') {
        const node = getTreeNodeWithId(element.id, expandedNodes);
        if (node) initialTree.push(node);
      }
    });
    setTreeData(initialTree);
    setExpandedKeys(expandedNodes);
    toggleKey(key);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.get_locations, loadedCounts]);

  useEffect(() => {
    if (Object.keys(loadedCounts).length > 0) getExpandedTree();
  }, [loadedCounts, getExpandedTree]);

  useEffect(() => {
    if (data?.get_locations) {
      const initialCounts: { [key: string]: number } = {};
      (data?.get_locations || []).forEach((node: any) => {
        initialCounts[node.id] = loadIncrement;
      });
      setLoadedCounts(initialCounts);
    }
  }, [data?.get_locations]);

  useEffect(() => {
    if (removeUnitData?.remove_warehouse_unit) {
      message.success('Node Deleted Successfully');
      refetchData();
    }
  }, [removeUnitData, refetchData]);

  const handleDeleteNode = (parentId: string) => {
    removeUnit({
      variables: {
        id: parentId,
      },
    });
  };

  const getActionButtons = (parentId: string) => (
    <Space style={{ marginLeft: '15px' }}>
      <div style={{ display: 'flex' }}>
        <WithPermission
          permission={{
            name: PERMISSION_WAREHOUSE_CREATE,
            docType: 'Wms::WarehouseUnit',
          }}
        >
          <button
            className="node-action-btn"
            onClick={() => {
              setFormType('add');
              setParentId(parentId);
              setVisible(true);
            }}
          >
            Add Child
          </button>
        </WithPermission>
        <WithPermission
          permission={{
            name: PERMISSION_WAREHOUSE_EDIT,
            docType: 'Wms::WarehouseUnit',
          }}
        >
          <button
            className="node-action-btn"
            onClick={() => {
              setFormType('edit');
              setParentId(parentId);
              setVisible(true);
            }}
          >
            Edit
          </button>
        </WithPermission>
        <WithPermission
          permission={{
            name: PERMISSION_WAREHOUSE_DELETE,
            docType: 'Wms::WarehouseUnit',
          }}
        >
          {!(nodeToChildsUnitsMap[parentId] || [])?.length && (
            <button
              className="node-action-btn"
              onClick={() => {
                Modal.confirm({
                  title: `Are you sure you want to delete this node?`,
                  okText: 'Yes',
                  okType: 'danger',
                  cancelText: 'No',
                  onOk: () => handleDeleteNode(parentId),
                });
              }}
              style={{ color: 'red' }}
            >
              Delete
            </button>
          )}
        </WithPermission>
      </div>
    </Space>
  );

  const [selectedNode, setSelectedNode] = useState<any>();
  const onSelect: TreeProps['onSelect'] = (selectedKeys, info) => {
    if (selectedNode) {
      const targetedAction = document.getElementById(selectedNode + '');
      if (targetedAction) targetedAction.style.display = 'none';
    }
    const selectedKey = selectedKeys[0];
    setSelectedNode(selectedKey);
    const targetedAction = document.getElementById(selectedKey + '');
    if (targetedAction) targetedAction.style.display = 'inline';
  };
  const createWarehouse = () => {
    setFormType('new');
    setParentId(undefined);
    setVisible(true);
  };
  return (
    <>
      {visible && (
        <CreateWarehouseUnit
          visible={visible}
          setVisible={setVisible}
          parentId={parentId}
          formType={formType}
          refetch={refetchData}
        />
      )}
      <div className="tree-general-action-button">
        <Row>
          <Col span={6}>
            <Space>
              <Button
                size="small"
                onClick={() => {
                  getExpandedTree();
                }}
              >
                <DownOutlined /> Expand All
              </Button>
              <Button
                size="small"
                onClick={() => {
                  setExpandedKeys([]);
                  toggleKey(key);
                }}
              >
                <UpOutlined /> Collapse All
              </Button>
            </Space>
          </Col>
        </Row>
        <WithPermission
          permission={{
            name: PERMISSION_WAREHOUSE_CREATE,
            docType: 'Wms::WarehouseUnit',
          }}
        >
          <Button type="primary" htmlType="submit" onClick={() => createWarehouse()}>
            Create Warehouse
          </Button>
        </WithPermission>
      </div>

      <div className="main-tree">
        {!treeReady || loading ? (
          <Skeleton />
        ) : (
          <Tree
            key={key}
            onSelect={onSelect}
            treeData={treeData}
            defaultExpandedKeys={expandedKeys}
            showLine={true}
          />
        )}
      </div>
    </>
  );
};

export default WarehouseUnitTree;
