import { ActivityLayout, Layout, THREAD_TYPES } from '@shipmnts/pixel-hub';
import React from 'react';
import { useCompanyView } from '../CompanyView';

const { Content } = Layout;
const InboxTab = () => {
  const { company } = useCompanyView();
  if (!company) return <></>;
  return (
    <Content style={{ padding: 0, height: '750px' }}>
      <ActivityLayout
        reference={{
          reference_type: 'Network::Company',
          reference_id: company.id,
        }}
        thread_type={THREAD_TYPES.CHAT}
        showBreadCrumb={false}
      />
    </Content>
  );
};

export default InboxTab;
