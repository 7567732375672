import { CustomIcon, getDateFromUnix } from '@shipmnts/pixel-hub';
import {
  TRANSACTION_TYPE_INCOMING,
  TRANSACTION_TYPE_DELIVERY,
  TRANSACTION_TYPE_OUTGOING,
  TRANSACTION_TYPE_RECEIPT,
  WarehouseTransactionValue,
} from 'operations/modules/warehouseManagement/models/WarehouseTransaction';
import { collapsibleCard } from 'operations/modules/shipment/components/DetailLayout/Common/common';
import React from 'react';

interface WMSReceiptDetailsProps {
  warehouseTransaction: WarehouseTransactionValue;
}

function WMSReceiptDetails(props: WMSReceiptDetailsProps) {
  const { warehouseTransaction } = props;
  const receiptDetail: any = [];
  if (
    warehouseTransaction?.type === TRANSACTION_TYPE_INCOMING ||
    warehouseTransaction?.type === TRANSACTION_TYPE_RECEIPT
  ) {
    receiptDetail.push({
      'Received From Transporter': warehouseTransaction?.received_transporter?.registered_name,
    });
    receiptDetail.push({
      'Received By': warehouseTransaction.transaction_by
        ? [
            warehouseTransaction.transaction_by.first_name,
            warehouseTransaction.transaction_by.last_name,
          ]
            .filter(Boolean)
            .join(' ')
        : '',
    });
    receiptDetail.push({
      'Received At': warehouseTransaction?.transaction_date
        ? getDateFromUnix(warehouseTransaction?.transaction_date)
        : null,
    });
  }
  if (
    warehouseTransaction?.type === TRANSACTION_TYPE_OUTGOING ||
    warehouseTransaction?.type === TRANSACTION_TYPE_DELIVERY
  ) {
    receiptDetail.push({
      'Delivered to Transporter': warehouseTransaction?.delivered_transporter?.registered_name,
    });
  }
  if (warehouseTransaction?.type === TRANSACTION_TYPE_DELIVERY) {
    receiptDetail.push({
      'Delivered By': warehouseTransaction.transaction_by
        ? [
            warehouseTransaction.transaction_by.first_name,
            warehouseTransaction.transaction_by.last_name,
          ]
            .filter(Boolean)
            .join(' ')
        : '',
    });
    receiptDetail.push({
      'Delivered At': warehouseTransaction?.transaction_date
        ? getDateFromUnix(warehouseTransaction?.transaction_date)
        : null,
    });
  }
  receiptDetail.push({
    'Vehicle #': warehouseTransaction?.vehicle_number,
  });
  receiptDetail.push({
    'Vehicle Type': warehouseTransaction?.vehicle_type,
  });
  receiptDetail.push({
    'Driver Detail': warehouseTransaction?.driver_name,
  });
  receiptDetail.push({
    'Delivery Ref#': warehouseTransaction?.delivery_ref_code,
  });
  return (
    <>
      {collapsibleCard({
        icon: <CustomIcon icon="Info" />,
        header:
          warehouseTransaction?.type === TRANSACTION_TYPE_RECEIPT
            ? 'Receipt Details'
            : warehouseTransaction?.type === TRANSACTION_TYPE_DELIVERY
            ? 'Delivery Details'
            : 'Outgoing Details',
        data: receiptDetail,
      })}
    </>
  );
}

export default WMSReceiptDetails;
