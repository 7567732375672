import React, { useEffect } from 'react';
import { Modal, Form, GlobalSearch, Select, message } from '@shipmnts/pixel-hub';
import { RowNode } from '@ag-grid-community/core';
import {
  ShipmentEstimateValue,
  useShipmentEstimateStore,
} from 'operations/models/ShipmentEstimate';
import { pick as _pick, get as _get } from 'lodash';
import { SAVE_SHIPMENT_ESTIMATES } from 'operations/graphql/shipmentEstimate';
import { useMutation } from '@apollo/client';
import { CHARGE_TERMS } from 'operations/modules/shipment/constants';
import {
  BRANCH_FIELDS,
  COMPANY_FIELDS,
  COMPANY_TYPE,
  DOCTYPE_MAP,
  ESTIMATE_FIELDS,
} from './constants';
import { useShipmentEstimate } from 'operations/modules/shipment/components/DetailLayout/Estimates/ShipmentEstimateLayout';

interface UpdateEstimateFormProps {
  selectedNodes: RowNode[];
  selectedNodeCounts: number;
  onClose: () => void;
  onSuccess: () => void;
  fieldName: string;
  label: string;
  value: Record<string, string>;
  isSpEnabled: boolean;
}

const UpdateCompanyForm = (props: UpdateEstimateFormProps) => {
  const { Option } = Select;
  const { fetchEstimateData } = useShipmentEstimateStore();
  const { branches, configData } = useShipmentEstimate();
  const costCenters = _get(configData, 'cost_centers');
  const { selectedNodes, onClose, onSuccess, fieldName, label, value, isSpEnabled } = props;
  const [saveEstimates, { data, error }] = useMutation(SAVE_SHIPMENT_ESTIMATES);
  const [form] = Form.useForm();
  const link_fieldnames = [...COMPANY_FIELDS, ...BRANCH_FIELDS];
  const accessible_by_branches = selectedNodes[0]?.data?.shipment?.accessible_by_branches.map(
    (branch: { id: string }) => branch.id
  );
  function getPermittedBranches(
    erp_cost_centers: { name: string; has_permission: boolean }[],
    branches: { id: string; name: string; erp_cost_center_id: string }[]
  ): string[] {
    return branches
      .filter(
        (branch) =>
          !!erp_cost_centers.find(
            (cost_center) =>
              cost_center.name === branch.erp_cost_center_id &&
              cost_center.has_permission &&
              accessible_by_branches?.includes(branch.id)
          )
      )
      .map((branch) => branch.name);
  }
  const branch_account_search_values = getPermittedBranches(costCenters, branches);
  let filters: Record<string, string | boolean | Array<string>> = {};
  if (BRANCH_FIELDS.includes(fieldName)) {
    filters = {
      name: branch_account_search_values,
    };
  }
  if (COMPANY_FIELDS.includes(fieldName) && COMPANY_TYPE[fieldName as keyof typeof COMPANY_TYPE]) {
    filters = {
      ...COMPANY_TYPE[fieldName as keyof typeof COMPANY_TYPE],
    };
  }
  const onFinish = async (values: Record<string, Record<string, string>>) => {
    const estimate_rows = selectedNodes.map((node: RowNode) => {
      if (isSpEnabled && values.sell_branch_id) {
        return {
          ...node.data,
          branch: values.sell_branch_id.id,
        };
      }
      return {
        ...node.data,
        [fieldName]:
          typeof values?.[fieldName] === 'object' ? values?.[fieldName]?.id : values?.[fieldName],
      };
    });
    bulkUpdateEstimates(estimate_rows);
  };
  const bulkUpdateEstimates = async (estimates: Partial<ShipmentEstimateValue>[]) => {
    const estimate_fields = isSpEnabled ? ['branch', ...ESTIMATE_FIELDS] : ESTIMATE_FIELDS;
    if (estimates.length > 0) {
      let newEstimates: Partial<ShipmentEstimateValue>[] = [];
      newEstimates = estimates.map((est) => {
        const new_estimate: Partial<ShipmentEstimateValue> = _pick(est, estimate_fields);
        return new_estimate;
      });
      saveEstimates({
        variables: { shipment_estimates: newEstimates },
      });
    }
  };

  useEffect(() => {
    if (data) {
      fetchEstimateData?.();
      message.success('Estimates Updated.');
      onClose?.();
      onSuccess?.();
    }
    if (error) {
      message.error('Failed to update estimates.');
      onClose?.();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, error]);
  const defaultValue =
    BRANCH_FIELDS.includes(fieldName) && typeof value === 'string'
      ? branches.find((branch: { id: string; name: string }) => branch.id === value)
      : value;
  return (
    <Modal
      title="Update Estimates"
      centered={true}
      open={true}
      onCancel={onClose}
      onOk={form.submit}
    >
      <Form form={form} onFinish={onFinish}>
        <Form.Item
          initialValue={defaultValue}
          required
          rules={[{ required: true }]}
          name={fieldName}
          label={label}
        >
          {link_fieldnames.includes(fieldName) ? (
            <GlobalSearch
              doc_type={DOCTYPE_MAP[fieldName]}
              searchProps={filters}
              extraProps={{ include_disabled: false }}
              showCreation={false}
            />
          ) : (
            <Select>
              {CHARGE_TERMS.map((term) => (
                <Option key={term.key} value={term.key}>
                  {term.name}
                </Option>
              ))}
            </Select>
          )}
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default UpdateCompanyForm;
