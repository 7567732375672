import { useQuery } from '@apollo/client';
import { findLast, get, startCase } from 'lodash';
import React, { forwardRef } from 'react';
import { FETCH_EVENT_AND_MILESTONES } from '../../../graphql/queries';
import { ContainerSvg, FactorySvg, TrainSvg } from '../../../Icons';
import { useTrackerContext } from '../../../TrackingContext';
import { EventType } from '../../../types';
import Timeline from '../../timeline/Timeline';
import Spinner from 'src/components/Spinner';

function getStructuredEventFromEventData(item: EventType) {
  const event = {
    ...item,
    mode_of_transit: item.event_data?.mode_of_transit,
    voyage_number: item.event_data?.voyage_number,
    wagon_number: item.event_data?.wagon_number,
    driver_contact: item.event_data?.driver_contact,
  };
  return event;
}

const EventsAndMilestones = forwardRef<
  HTMLDivElement,
  {
    onFormSubmit?: (success?: boolean) => void;
  }
>(({ onFormSubmit }, ref) => {
  // Context
  const { referenceType, id, workflow_type, wrapper } = useTrackerContext();

  // Queries
  const { data, loading, error } = useQuery(FETCH_EVENT_AND_MILESTONES, {
    variables: {
      ids: [id],
      reference_type: referenceType,
      workflow_type: workflow_type || 'main',
    },
  });

  const events: EventType[] = get(data, 'fetch_tracking_events', []);
  const groupedEvents: { [key: string]: EventType[] } = {};

  const checkType = (tbd: any): tbd is EventType => {
    return true;
  };
  events.forEach((o, i) => {
    if (o.location?.name) {
      const arr = groupedEvents[o.location?.name] || [];
      arr.push(o);
      groupedEvents[o.location?.name] = arr;
    } else {
      const last = findLast(events.slice(0, i), (o) => o.location?.name);

      if (last && checkType(last) && last.location && last.location_tag === o.location_tag) {
        const arr = groupedEvents[last.location.name] || [];
        arr.push(o);
        groupedEvents[last.location?.name] = arr;
      } else if (o.location_tag) {
        const arr = groupedEvents[o.location_tag] || [];
        arr.push(o);
        groupedEvents[o.location_tag] = arr;
      } else {
        if (groupedEvents['Events']) groupedEvents['Events'].push(o);
        else {
          groupedEvents['Events'] = [o];
        }
      }
    }
  });
  let lastOccuredEvent: any = findLast(events, (o) => o.actual_date);
  if (lastOccuredEvent && checkType(lastOccuredEvent)) {
    lastOccuredEvent = getStructuredEventFromEventData(lastOccuredEvent);
  }

  // Handle loading/error
  if (loading) {
    return (
      <div className="activity-box" ref={ref}>
        {wrapper !== 'NO_WRAPPER' && (
          <div className="title" style={{ fontWeight: 600 }}>
            {`Events & Milestones`}
          </div>
        )}
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: '10px',
          }}
        >
          <Spinner></Spinner>
        </div>
      </div>
    );
  }
  if (error) {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          marginTop: '10px',
        }}
      >
        Error occured while fetching data
      </div>
    );
  }

  return (
    <div className="activity-box" ref={ref}>
      {wrapper !== 'NO_WRAPPER' && (
        <div className="title" style={{ fontWeight: 600 }}>
          {`Events & Milestones`}
        </div>
      )}
      <div style={{ marginTop: '24px' }}>
        {Object.values(groupedEvents).map((item, index: number) => {
          const name = get(item[0], 'location.name', startCase(Object.keys(groupedEvents)[index]));
          const country_code = get(item[0], 'location.country_code', '');
          const city = get(item[0], 'location.city.name', '');
          const structuredEvents = item.map((event) => getStructuredEventFromEventData(event));
          const lastEventInList = item[item.length - 1];
          let icon = ContainerSvg;
          if (lastEventInList.name?.includes('icd')) {
            icon = TrainSvg;
          } else if (lastEventInList.name?.includes('factory')) {
            icon = FactorySvg;
          }
          return (
            <Timeline
              key={index}
              headerIcon={icon}
              headerText={[name, city, country_code].filter(Boolean).join(', ')}
              events={structuredEvents}
              editable={true}
              onFormSubmit={onFormSubmit}
              lastOccuredEvent={lastOccuredEvent}
            />
          );
        })}
      </div>
    </div>
  );
});

export default EventsAndMilestones;
EventsAndMilestones.displayName = 'Events And Milstones Application';
