import { ActivityLayout, Layout, THREAD_TYPES } from '@shipmnts/pixel-hub';
import React from 'react';
import { useShipmentDetail } from './ShipmentDetailLayout';

const { Content } = Layout;
const InboxTab = () => {
  // States and contexts
  const { shipment } = useShipmentDetail();

  if (!shipment) {
    return <></>;
  }

  return (
    <Content style={{ padding: 0, height: '750px' }}>
      <ActivityLayout
        reference={{
          reference_type: 'Shipment::Shipment',
          reference_id: shipment.id,
          ...shipment,
          uuid: shipment.uuid ?? '',
        }}
        thread_type={THREAD_TYPES.CHAT}
        showBreadCrumb={false}
      />
    </Content>
  );
};

export default InboxTab;
