import ConfirmBookingForm from 'operations/modules/reports/components/OTOReports/ConfirmBookingForm';
import CancelBookingForm from 'operations/modules/reports/components/OTOReports/CancelBookingForm';
import ExtendValidityForm from 'operations/modules/reports/components/OTOReports/ExtendValidityForm';
import OceanTransportOrder, {
  OceanTransportOrderValue,
} from 'operations/models/OceanTransportOrder';
// import { EmailProps } from 'operations/modules/shipment/components/Tracking/ChangeEvent/EventEmailDrawer'

import {
  BOOKING_TYPE_OCEAN_TRANSPORT_ORDER,
  BOOKING_TYPE_SHIPMENT,
} from 'operations/modules/booking/constants';
import { SessionDataValue } from 'operations/models/SessionData';
import UpdateOrSplitOto from 'operations/modules/oto/UpdateOrSplitOto';
import { EmailProps } from 'operations/components/ChangeEvent/EventEmailDrawer';
import DeallocateOceanTranportOrderFromShipment from '../ShipmentActions/DeallocateOceanTranportOrderFromShipment';
import AssignContainerQty from 'operations/modules/booking/components/AssignContainerQty';
import Shipment, { ShipmentValue } from 'operations/models/Shipment';
import UpdateOtoForm from 'operations/modules/reports/components/OTOReports/UpdateOtoForm';
import { ApolloClient } from '@apollo/client';

interface OceanTransportOrderActionType {
  oceanTransportOrder: OceanTransportOrderValue;
  setEmailProps: (emailProps: EmailProps) => void;
  setVisible: (visible: boolean) => void;
  sessionData: SessionDataValue;
  extraProps?: any;
  navigate: any;
  config_data?: any;
  client?: ApolloClient<object>;
  getFetchDocumentFunction: (
    shipment: any,
    client: ApolloClient<object>,
    sessionData: SessionDataValue,
    config_data?: any
  ) => any;
}

export const sendConfirmationActionRenderer = (payload: OceanTransportOrderActionType) => {
  const { oceanTransportOrder } = payload;
  return {
    actionParams: {
      oto: oceanTransportOrder,
    },
    component: ConfirmBookingForm,
  };
};

export const createCOFromOTORenderer = (payload: OceanTransportOrderActionType) => {
  const {
    oceanTransportOrder,
    sessionData,
    setEmailProps,
    setVisible,
    config_data,
    client,
    getFetchDocumentFunction,
  } = payload;
  return {
    actionParams: {
      oto: oceanTransportOrder,
      onSuccess: (shipment_as_br: ShipmentValue, sendEmail: boolean) => {
        const shipment = Shipment.create(shipment_as_br, {
          sessionData,
        });
        if (sendEmail) {
          setEmailProps({
            title: 'Booking Confirmation',
            action_name: 'booking_confirmation',
            resource_ids: [shipment_as_br.id],
            fetchDocuments: client
              ? getFetchDocumentFunction(shipment_as_br, client, sessionData, config_data)
              : undefined,
            fetchDocumentParents: [
              {
                parent_type: BOOKING_TYPE_SHIPMENT,
                parent_ids: [shipment_as_br.id],
              },
              {
                parent_type: BOOKING_TYPE_OCEAN_TRANSPORT_ORDER,
                parent_ids: [oceanTransportOrder.id],
              },
            ],
            companies_roles_mapping: shipment.getAllPartiesRolesMapping(sessionData),
            showMarkDown: true,
          });
          setVisible(true);
        }
      },
    },
    component: AssignContainerQty,
  };
};

export const cancelBookingRenderer = (payload: OceanTransportOrderActionType) => {
  const { oceanTransportOrder } = payload;
  return {
    actionParams: {
      bookingType: BOOKING_TYPE_OCEAN_TRANSPORT_ORDER,
      booking: oceanTransportOrder,
    },
    component: CancelBookingForm,
  };
};

export const extendValidityRenderer = (payload: OceanTransportOrderActionType) => {
  const { oceanTransportOrder } = payload;
  return {
    actionParams: {
      oto: oceanTransportOrder,
    },
    component: ExtendValidityForm,
  };
};
export const updateOtoRenderer = (payload: OceanTransportOrderActionType) => {
  const {
    oceanTransportOrder,
    setEmailProps,
    setVisible,
    client,
    config_data,
    sessionData,
    getFetchDocumentFunction,
  } = payload;
  const default_company = sessionData?.company_account?.default_company;
  const oto = oceanTransportOrder || payload.extraProps?.oto;
  return {
    actionParams: {
      oto: oceanTransportOrder ?? payload.extraProps?.oto,
      oto_id: oceanTransportOrder?.id ?? payload.extraProps?.oto?.id,
      onSuccess: (sendEmail: boolean, shipment: ShipmentValue) => {
        if (sendEmail) {
          setEmailProps({
            title: 'Booking Amendment',
            action_name: 'booking_confirmation',
            resource_ids: [shipment?.id],
            fetchDocumentParents: [
              {
                parent_type: BOOKING_TYPE_SHIPMENT,
                parent_ids: [shipment?.id],
              },
              {
                parent_type: BOOKING_TYPE_OCEAN_TRANSPORT_ORDER,
                parent_ids: [oto?.id],
              },
            ],
            customContent: JSON.stringify({
              is_amendment_confirmation: true,
              oto_id: oceanTransportOrder?.id ?? payload.extraProps?.oto?.id,
            }),
            companies_roles_mapping: oto.getAllCompaniesRolesMapping(default_company),
            showMarkDown: false,
            fetchDocuments: client
              ? getFetchDocumentFunction(shipment, client, sessionData, config_data)
              : undefined,
          });
          setVisible(true);
        }
      },
    },
    component: UpdateOtoForm,
  };
};

export const splitBookingRenderer = (payload: OceanTransportOrderActionType) => {
  const { oceanTransportOrder, setEmailProps, setVisible, sessionData } = payload;
  const default_company = sessionData?.company_account?.default_company;
  const oto = OceanTransportOrder.create(oceanTransportOrder);
  return {
    actionParams: { oceanTransportOrder },
    component: UpdateOrSplitOto,
    onSuccess: (sendEmail: boolean, resource_ids: string[]) => {
      if (sendEmail) {
        setEmailProps({
          title: `Send Routing Update Email`,
          fetchDocumentParents: [
            {
              parent_type: 'tracking_event',
              parent_ids: resource_ids,
            },
          ],
          action_name:
            resource_ids.length === 1
              ? 'tracking_event_notification'
              : 'tracking_event_notification_bulk',
          resource_ids: resource_ids,
          companies_roles_mapping: default_company
            ? oto.getAllCompaniesRolesMapping(default_company)
            : [],
        });
        setVisible(true);
      }
    },
  };
};

export const deallocateOtoRenderer = (payload: OceanTransportOrderActionType) => {
  const { oceanTransportOrder, extraProps } = payload;
  return {
    actionParams: {
      oceanTransportOrder,
      shipment: extraProps.shipment,
    },
    component: DeallocateOceanTranportOrderFromShipment,
  };
};
