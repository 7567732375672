import React, { forwardRef, useEffect, useState } from 'react';
import {
  BY_PACKAGES,
  BY_TOTALS,
  CargoTableProps,
  commodity_type,
  createCargoPayload,
  dimension_unit,
  disableCellStyle,
  getFinalOptions,
  getHeight,
  getTotalWeight,
  recalculateAndAssign,
  RequireHeaderWrapper,
  weight_unit,
} from './helpers';
import { SelectionChangedEvent } from '@ag-grid-community/core';
import {
  BaseTable,
  Button,
  DataImportModal,
  DateEditor,
  DeleteOutlined,
  EnumEditor,
  FloatEditor,
  GlobalSearch,
  PlusOutlined,
  SearchDocTypeEditor,
} from '@shipmnts/pixel-hub';
import { Column } from 'operations/models/Report';
import { compact } from 'lodash';
import { ILargeTextEditorParams } from '@ag-grid-community/core/dist/cjs/es5/rendering/cellEditors/largeTextCellEditor';
import { oceanPackageTypes } from 'operations/baseConstants';
import { QtyUomTypeRenderer } from 'common';
import CargoProperties from 'operations/components/CargoProperties';
import { CargoPropertyValue } from 'operations/models/Cargo';
import {
  FREIGHT_TYPE_AIR,
  FREIGHT_TYPE_OCEAN,
  FREIGHT_TYPE_ROAD,
  LOAD_TYPE_LCL,
} from 'common/baseConstants';

const CargoTableForm = forwardRef(function CargoByPackageTableForm(
  props: CargoTableProps,
  ref
): JSX.Element {
  const {
    value,
    onChange,
    disabled,
    loadType,
    freightType,
    gridRef,
    showAddDeleteButton = true,
    activeTab,
    form,
    setRequiredFieldMap,
  } = props;
  const [showImportModal, setShowImportModal] = useState<boolean>(false);
  const [rowSelected, setRowSelected] = useState(false);

  const onSelectionChanged = (event: SelectionChangedEvent) => {
    const selectedRows = event.api.getSelectedNodes();
    setRowSelected(selectedRows.length > 0);
  };

  const addNewRow = () => {
    const newRow: any = {};
    if (freightType === FREIGHT_TYPE_ROAD) newRow['outer_package_qty'] = 1;
    onChange && onChange([...(value || []), newRow]);
    setTimeout(() => {
      allColumnsDefs[0]?.colId &&
        gridRef?.current?.api?.startEditingCell({
          rowIndex: value?.length || 0,
          colKey: allColumnsDefs[0]?.colId,
        });
    }, 500);
  };

  const deleteRow = () => {
    const selectedRows = gridRef?.current?.api?.getSelectedNodes();
    const selectedIndexes = (selectedRows || []).map((element: any) => element.rowIndex);
    const newData: any = [];
    gridRef?.current?.api?.forEachNode((node: { rowIndex: number | null; data: any }) => {
      if (!selectedIndexes.includes(node.rowIndex)) {
        newData.push(node.data);
      }
    });
    onChange && onChange(newData);
    setRowSelected(false);
  };

  const components = {
    FloatEditor,
    EnumEditor,
    SearchDocTypeEditor,
    DateEditor,
  };

  const allColumnsDefs: Column[] = compact([
    {
      headerName: 'Commodity',
      field: 'commodity',
      colId: 'commodity',
      maxWidth: 150,
      pinned: 'left',
      lockPosition: true,
      suppressMovable: true,
      editable: (o) => !disabled && !o.node.isRowPinned(),
      suppressKeyboardEvent: (params) => {
        return params.event.key === 'Enter' && params.editing;
      },
      cellEditor: 'SearchDocTypeEditor',
      cellEditorParams: {
        CustomComponent: GlobalSearch,
        componentProps: {
          doc_type: 'Global::Commodity',
        },
      },
      valueSetter: (params) => {
        if (!params?.newValue) return false;
        params.data.commodity = params?.newValue;
        params.data.product_name = params?.newValue?.name;
        params.data.cargo_properties = {
          ...(params.data.cargo_properties || {}),
          is_temp_controlled: params?.newValue?.live_reefer_cargo ? true : false,
          is_hazardous: params?.newValue?.dangerous_cargo ? true : false,
        };
        return true;
      },
      valueFormatter: (params) => {
        return params?.value?.name;
      },
    },
    {
      required: true,
      headerName: 'Commodity Description',
      field: 'product_name',
      colId: 'product_name',
      editable: (o) => !disabled && !o.node.isRowPinned(),
      valueSetter: (params) => {
        if (!params.newValue) return false;
        params.data.product_name = params.newValue;
        return true;
      },
      maxWidth: 180,
      cellEditorPopup: true,
      cellEditor: 'agLargeTextCellEditor',
      cellEditorParams: {
        maxLength: 600,
        rows: 15,
        cols: 54,
      } as ILargeTextEditorParams,
    },
    freightType === FREIGHT_TYPE_AIR && {
      headerName: 'Commodity Type',
      field: 'commodity_type',
      colId: 'commodity_type',
      editable: (o) => !disabled && !o.node.isRowPinned(),
      suppressKeyboardEvent: (params) => {
        return params.event.key === 'Enter' && params.editing;
      },
      cellEditor: 'EnumEditor',
      cellEditorParams: {
        options: commodity_type,
      },
      valueSetter: (params: any) => {
        params.data.commodity_type = params.newValue;
        return true;
      },
    },
    {
      required: activeTab === BY_PACKAGES,
      headerName: 'Package Type',
      field: 'outer_package_type',
      colId: 'outer_package_type',
      editable: (o) => !disabled && !o.node.isRowPinned(),
      suppressKeyboardEvent: (params) => {
        return params.event.key === 'Enter' && params.editing;
      },
      cellEditor: 'EnumEditor',
      cellEditorParams: {
        options: getFinalOptions(oceanPackageTypes),
      },
      valueSetter: (params) => {
        params.data.outer_package_type = params.newValue;
        return true;
      },
      valueGetter: (params: any) => {
        if (params.node.isRowPinned()) return '';
        return params.data.outer_package_type || 'Box';
      },
    },
    {
      required: freightType === FREIGHT_TYPE_OCEAN && loadType === LOAD_TYPE_LCL,
      headerName: 'Qty',
      field: 'outer_package_qty',
      colId: 'outer_package_qty',
      editable: (o) => !disabled && !o.node.isRowPinned(),
      cellEditor: 'FloatEditor',
      columnType: 'Float',
      cellEditorParams: {
        min: 0,
        step: 0.001,
      },
      valueSetter: (params: any) => {
        const newValue = Number(Number(params.newValue || 0).toFixed(3));
        if (activeTab === BY_TOTALS) {
          params.data.outer_package_qty = newValue;
          return true;
        } else {
          return recalculateAndAssign(params, 'outer_package_qty');
        }
      },
      valueGetter: (params: any) => {
        return params.data.outer_package_qty || 0;
      },
      width: 150,
    },
    {
      required:
        freightType === FREIGHT_TYPE_AIR ||
        (freightType === FREIGHT_TYPE_OCEAN && loadType === LOAD_TYPE_LCL),
      headerName: 'Weight Unit',
      field: 'weight_unit',
      colId: 'weight_unit',
      columnType: 'String',
      cellEditor: 'EnumEditor',
      cellEditorParams: {
        options: weight_unit,
      },
      suppressKeyboardEvent: (params) => {
        return params.event.key === 'Enter' && params.editing;
      },
      editable: (o) => !disabled && !o.node.isRowPinned(),
      valueGetter: (params: any) => {
        if (params.node.isRowPinned()) return '';
        return params.data.weight_unit || 'kgs';
      },
      valueSetter: (params: any) => {
        return recalculateAndAssign(params, 'weight_unit');
      },
      onCellValueChanged: (params: any) => {
        params.api.refreshCells({
          columns: ['outer_per_packet_wt', 'gross_weight', 'net_weight'],
          rowNodes: [params.node],
          force: true,
        });
      },
    },
    activeTab === BY_PACKAGES && {
      headerName: 'Per Pack Weight',
      field: 'outer_per_packet_wt',
      colId: 'outer_per_packet_wt',
      columnType: 'Float',
      cellEditor: 'FloatEditor',
      editable: (o) => !disabled && !o.node.isRowPinned(),
      valueGetter: (params: any) => {
        return params.data.outer_per_packet_wt || 0;
      },
      valueSetter: (params: any) => {
        return recalculateAndAssign(params, 'outer_per_packet_wt');
      },
      cellRenderer: (params: any) => {
        if (params.node.isRowPinned()) return <></>;
        return (
          <QtyUomTypeRenderer
            uom={params?.data?.weight_unit || 'kgs'}
            qty={params?.data?.outer_per_packet_wt}
          />
        );
      },
    },
    {
      required:
        freightType === FREIGHT_TYPE_AIR ||
        (freightType === FREIGHT_TYPE_OCEAN && loadType === LOAD_TYPE_LCL),
      headerName: 'Gross Weight',
      field: 'gross_weight',
      colId: 'gross_weight',
      columnType: 'Float',
      cellEditor: 'FloatEditor',
      editable: (o) => !disabled && !o.node.isRowPinned(),
      valueGetter: (params: any) => {
        return params.data.gross_weight || 0;
      },
      valueSetter: (params) => {
        return recalculateAndAssign(params, 'gross_weight');
      },
      cellRenderer: (params: any) => {
        if (params.node.isRowPinned()) {
          const val = getTotalWeight(value || []);
          return <QtyUomTypeRenderer uom={'kgs'} qty={val} />;
        }
        return (
          <QtyUomTypeRenderer
            uom={params?.data?.weight_unit || 'kgs'}
            qty={params?.data?.gross_weight}
          />
        );
      },
    },
    {
      headerName: 'Net Weight',
      field: 'net_weight',
      colId: 'net_weight',
      columnType: 'Float',
      cellEditor: 'FloatEditor',
      editable: (o) => !disabled && !o.node.isRowPinned(),
      valueGetter: (params: any) => {
        return params.data.net_weight || 0;
      },
      valueSetter: (params: any) => {
        return recalculateAndAssign(params, 'net_weight');
      },
      cellRenderer: (params: any) => {
        if (params.node.isRowPinned()) return <></>;
        return (
          <QtyUomTypeRenderer
            uom={params?.data?.weight_unit || 'kgs'}
            qty={params?.data?.net_weight}
          />
        );
      },
    },
    activeTab === BY_PACKAGES && {
      required: true,
      suppressKeyboardEvent: (params) => {
        return params.event.key === 'Enter' && params.editing;
      },
      headerName: 'Dimension Unit',
      field: 'dimension_unit',
      colId: 'dimension_unit',
      columnType: 'String',
      cellEditor: 'EnumEditor',
      cellEditorParams: {
        options: dimension_unit,
      },
      valueGetter: (params: any) => {
        if (params.node.isRowPinned()) return '';
        return params.data.dimension_unit || 'cms';
      },
      valueSetter: (params: any) => {
        return recalculateAndAssign(params, 'dimension_unit');
      },
      editable: (o) => !disabled && !o.node.isRowPinned(),
      onCellValueChanged: (params: any) => {
        params.api.refreshCells({
          columns: ['length', 'width', 'height'],
          rowNodes: [params.node],
          force: true,
        });
      },
    },
    activeTab === BY_PACKAGES && {
      required: true,
      headerName: 'Length',
      field: 'length',
      colId: 'length',
      columnType: 'Float',
      cellEditor: 'FloatEditor',
      editable: (o) => !disabled && !o.node.isRowPinned(),
      valueGetter: (params: any) => {
        if (params.node.isRowPinned()) return undefined;
        return params.data.length || 0;
      },
      valueSetter: (params) => {
        return recalculateAndAssign(params, 'length');
      },
      cellRenderer: (params: any) => {
        if (params.node.isRowPinned()) return <></>;
        return (
          <QtyUomTypeRenderer
            uom={params?.data?.dimension_unit || 'cms'}
            qty={params?.data?.length}
          />
        );
      },
    },
    activeTab === BY_PACKAGES && {
      required: true,
      headerName: 'Width',
      field: 'width',
      colId: 'width',
      columnType: 'Float',
      cellEditor: 'FloatEditor',
      editable: (o) => !disabled && !o.node.isRowPinned(),
      valueGetter: (params: any) => {
        if (params.node.isRowPinned()) return undefined;
        return params.data.width || 0;
      },
      valueSetter: (params) => {
        return recalculateAndAssign(params, 'width');
      },
      cellRenderer: (params: any) => {
        if (params.node.isRowPinned()) return <></>;
        return (
          <QtyUomTypeRenderer
            uom={params?.data?.dimension_unit || 'cms'}
            qty={params?.data?.width}
          />
        );
      },
    },
    activeTab === BY_PACKAGES && {
      required: true,
      headerName: 'Height',
      field: 'height',
      colId: 'height',
      columnType: 'Float',
      cellEditor: 'FloatEditor',
      editable: (o) => !disabled && !o.node.isRowPinned(),
      valueGetter: (params: any) => {
        if (params.node.isRowPinned()) return undefined;
        return params.data.height || 0;
      },
      valueSetter: (params) => {
        return recalculateAndAssign(params, 'height');
      },
      cellRenderer: (params: any) => {
        if (params.node.isRowPinned()) return <></>;
        return (
          <QtyUomTypeRenderer
            uom={params?.data?.dimension_unit || 'cms'}
            qty={params?.data?.height}
          />
        );
      },
    },
    {
      required: freightType === FREIGHT_TYPE_OCEAN && loadType === LOAD_TYPE_LCL,
      headerName: 'Gross Vol',
      field: 'gross_volume',
      colId: 'gross_volume',
      columnType: 'Float',
      cellEditor: 'FloatEditor',
      editable: (o) => !disabled && !o.node.isRowPinned(),
      valueGetter: (params: any) => {
        return params.data.gross_volume || 0;
      },
      valueSetter: (params) => {
        return recalculateAndAssign(params, 'gross_volume');
      },
      cellRenderer: (params: any) => {
        return <QtyUomTypeRenderer uom={'CBM'} qty={params?.data?.gross_volume} />;
      },
    },
    (activeTab === BY_PACKAGES || freightType === FREIGHT_TYPE_AIR) && {
      headerName: 'Vol. Weight',
      field: 'volumetric_weight',
      colId: 'volumetric_weight',
      columnType: 'Float',
      cellEditor: 'FloatEditor',
      editable: (o) => !disabled && !o.node.isRowPinned(),
      valueGetter: (params: any) => {
        return params.data.volumetric_weight || 0;
      },
      valueSetter: (params) => {
        return recalculateAndAssign(params, 'volumetric_weight');
      },
      cellRenderer: (params: any) => {
        return <QtyUomTypeRenderer uom={'kgs'} qty={params?.data?.volumetric_weight} />;
      },
    },
    (activeTab === BY_PACKAGES || freightType === FREIGHT_TYPE_AIR) && {
      headerName: 'Ch. Weight',
      field: 'chargeable_weight',
      colId: 'chargeable_weight',
      columnType: 'Float',
      cellEditor: 'FloatEditor',
      editable: false,
      valueGetter: (params: any) => {
        return params.data.chargeable_weight || 0;
      },
      valueSetter: (params) => {
        return recalculateAndAssign(params, 'chargeable_weight');
      },
      cellRenderer: (params: any) => {
        if (params.node.isRowPinned()) {
          const val = Math.max(
            getTotalWeight(value || []),
            getTotalWeight(value || [], 'volumetric_weight')
          );
          return <QtyUomTypeRenderer uom={'kgs'} qty={val} />;
        }
        return <></>;
      },
      cellStyle: disableCellStyle,
    },
    {
      headerName: 'Cargo Properties',
      field: 'cargo_properties',
      colId: 'cargo_properties',
      cellRenderer: (params: any) => {
        if (params.node.isRowPinned()) return <></>;
        return (
          <CargoProperties
            disabled={disabled}
            freightType={freightType}
            formKey={params.rowIndex}
            value={params?.value}
            onChange={(value: CargoPropertyValue) => {
              params?.setValue(value);
            }}
          />
        );
      },
      pinned: 'right',
      editable: false,
      cellStyle: disableCellStyle,
    },
    freightType === FREIGHT_TYPE_ROAD && {
      headerName: 'Serial #',
      field: 'serial_number',
      colId: 'serial_number',
      columnType: 'String',
      editable: (o) => !disabled && !o.node.isRowPinned(),
    },
    freightType === FREIGHT_TYPE_ROAD && {
      headerName: 'Invoice #',
      field: 'invoice_number',
      colId: 'invoice_number',
      columnType: 'String',
      editable: (o) => !disabled && !o.node.isRowPinned(),
    },
    freightType === FREIGHT_TYPE_ROAD && {
      headerName: 'Invoice Date',
      field: 'invoice_date',
      colId: 'invoice_date',
      columnType: 'Date',
      cellEditor: 'DateEditor',
      editable: (o) => !disabled && !o.node.isRowPinned(),
      valueSetter: (params) => {
        if (typeof params.newValue !== 'object') return false;
        params.data.invoice_date = params.newValue?.unix();
        return true;
      },
      valueGetter: (params) => {
        if (params.node?.isRowPinned()) return '';
        return params.data.invoice_date;
      },
      cellEditorPopup: true,
    },
    freightType === FREIGHT_TYPE_ROAD && {
      headerName: 'Batch #',
      field: 'batch_number',
      colId: 'batch_number',
      columnType: 'String',
      editable: (o) => !disabled && !o.node.isRowPinned(),
    },
    freightType === FREIGHT_TYPE_ROAD && {
      headerName: 'Customs Ref#',
      field: 'custom_ref',
      colId: 'custom_ref',
      columnType: 'String',
      editable: (o) => !disabled && !o.node.isRowPinned(),
    },
    freightType === FREIGHT_TYPE_ROAD && {
      headerName: 'E-way #',
      field: 'eway_bill_no',
      colId: 'eway_bill_no',
      columnType: 'String',
      editable: (o) => !disabled && !o.node.isRowPinned(),
    },
    freightType === FREIGHT_TYPE_ROAD && {
      headerName: 'E-way Validity',
      field: 'eway_bill_validity',
      colId: 'eway_bill_validity',
      columnType: 'Date',
      cellEditor: 'DateEditor',
      editable: (o) => !disabled && !o.node.isRowPinned(),
      valueSetter: (params) => {
        if (typeof params.newValue !== 'object') return false;
        params.data.eway_bill_validity = params.newValue?.unix();
        return true;
      },
      valueGetter: (params) => {
        if (params.node?.isRowPinned()) return '';
        return params.data.eway_bill_validity;
      },
      cellEditorPopup: true,
    },
  ]);

  const processedColumnDefs = allColumnsDefs.map((col) => {
    if (col && col.required) {
      return {
        ...col,
        headerComponent: () => <RequireHeaderWrapper text={col?.headerName || ''} />,
      };
    }
    return col;
  });

  useEffect(() => {
    const requiredFieldMap = (processedColumnDefs || [])
      .filter((e) => e?.required)
      .map((e) => ({
        key: e.colId || '',
        value: e.headerName || '',
      }));
    setRequiredFieldMap && setRequiredFieldMap(requiredFieldMap);
    //eslint-disable-next-line
  }, [freightType, loadType, setRequiredFieldMap]);

  return (
    <>
      <BaseTable
        rowData={value || []}
        reportName={`CargoByPackages${activeTab || ''}`}
        height={getHeight(value) || '200px'}
        columns={processedColumnDefs}
        gridRef={gridRef}
        rowSelection={'multiple'}
        checkbox_always_visible={false}
        allMapping={true}
        onGridReady={(grid: any) => grid?.columnApi?.autoSizeAllColumns()}
        onSelectionChanged={onSelectionChanged}
        reportConfig={{
          components: components,
          suppressCellFocus: false,
          suppressLastEmptyLineOnPaste: true,
          rowHeight: 40,
          onCellValueChanged: (params: any) => {
            const data = value || [];
            data[params.rowIndex] = {
              ...params.data,
            };
            onChange && onChange(data);
          },
          defaultColDef: {
            resizable: true,
            editable: true,
          },
          enableRangeSelection: true,
          enableCellChangeFlash: true,
          animateRows: true,
          stopEditingWhenCellsLoseFocus: true,
          tabToNextCell: (params: any) => {
            const col = gridRef?.current?.columnApi?.getColumn(allColumnsDefs[0]?.colId);
            if (params.editing && !params.backwards && !params.nextCellPosition && col) {
              addNewRow();
              return {
                rowIndex: -1,
                column: col,
                rowPinned: null,
              };
            } else return params.nextCellPosition;
          },
        }}
      />
      {showImportModal && (
        <DataImportModal
          open={showImportModal}
          setOpen={setShowImportModal}
          onSuccess={(data: any) => form?.setFieldsValue({ cargos: createCargoPayload(data) })}
          intialValue={{
            selectedDocTypes: ['Shipment::Cargo'],
          }}
        />
      )}
      {showAddDeleteButton && !disabled && (
        <div style={{ display: 'flex', marginTop: '15px' }}>
          <Button
            icon={<PlusOutlined />}
            onClick={() => addNewRow()}
            size="small"
            ghost
            type="primary"
            disabled={disabled}
          >
            Add Cargo
          </Button>
          <Button
            icon={<DeleteOutlined />}
            size="small"
            ghost
            type="primary"
            danger
            style={{ marginLeft: '15px' }}
            disabled={!rowSelected || disabled}
            onClick={() => {
              deleteRow();
            }}
          >
            Delete
          </Button>
          <Button
            icon={<PlusOutlined />}
            onClick={() => setShowImportModal && setShowImportModal(true)}
            size="small"
            ghost
            type="primary"
            disabled={disabled}
            style={{ marginLeft: '15px' }}
          >
            Import Cargo
          </Button>
        </div>
      )}
    </>
  );
});
export default CargoTableForm;
