import React, { useCallback, useEffect, useImperativeHandle, useState } from 'react';
import { observer } from 'mobx-react-lite';
import {
  Checkbox,
  FormTable,
  DateEditor,
  StringEditor,
  FloatEditor,
  EnumEditor,
  Button,
  Typography,
  dayjsGenerateConfig,
  dayjs,
  message,
} from '@shipmnts/pixel-hub';
import {
  ShipmentContainerValue,
  validateContainerNumber,
} from 'operations/models/ShipmentContainer';
import { getContainerSetting } from 'operations/modules/reports/oldCellRenderers';
import { Column } from 'operations/models/Report';
import { compact as _compact } from 'lodash';
import { disableFutureDate } from '@shipmnts/pixel-hub';
import {
  containerTypes,
  containerTypesWithCodeFlatArray,
  oceanPackageTypesFlatArray,
  reeferContainerTypesWithCodeFlatArray,
} from 'operations/baseConstants';
import { SHIPMENT_TYPE_CONSOL, TRADE_TYPE_IMPORT } from 'operations/modules/shipment/constants';
import _map from 'lodash/map';
import { ShipmentValue } from 'operations/models/Shipment';
import { CellClassParams } from '@ag-grid-community/core';
import { ERROR_CELL_STYLE } from 'operations/modules/shipment/helpers/FormHelpers';
import { useGenAIFieldContext } from '../../NewShipmentForm/GenAIFieldContext';
import { cellClassHelper } from '../../NewShipmentForm/FormFiller/Helpers';

const { Text } = Typography;
interface ContainerDetailsFormProps {
  gridRef: any;
  otoContainers?: ShipmentContainerValue[];
  value?: any;
  onChange?: (value: any[]) => void;
  isCreateContainer?: boolean;
  shipment?: ShipmentValue;
  showAddDelete?: boolean;
  isLiveReefer?: boolean;
  requiredFields?: Array<string>;
  tradeType?: string;
  shipmentType?: string;
}

interface ContainerValidationRef {
  runValidation: () => boolean;
}

const renderContainerType = (record: ShipmentContainerValue) => {
  const { container_type_code, container_settings, container_type } = record;
  const containerSettings = getContainerSetting({
    container_settings,
    container_type_code,
  });
  return containerSettings ? `${container_type} (${containerSettings})` : container_type;
};

const extractWt = (val: any) => {
  if (Number.isNaN(val)) {
    return 0.0;
  }
  return val;
};

const ContainerDetailsForm = observer<ContainerDetailsFormProps, ContainerValidationRef>(
  (
    props: ContainerDetailsFormProps,
    ref: React.Ref<ContainerValidationRef> | undefined
  ): JSX.Element => {
    const {
      gridRef,
      value,
      onChange,
      otoContainers,
      isCreateContainer,
      shipment,
      showAddDelete = false,
      requiredFields = [],
      isLiveReefer,
      tradeType,
      shipmentType,
    } = props;

    const [allowCargoDetailsColumns, setAllowCargoDetailsColumns] = useState(false);
    const [errorsPresent, setErrorsPresent] = useState(false);
    const shipment_type = shipmentType || shipment?.shipment_type;

    const { fields } = useGenAIFieldContext();

    const onCellValueChanged = useCallback(() => {
      const containers: any[] = [];
      gridRef?.current?.api?.forEachNode((node: any) => {
        containers.push(node.data);
      });
      onChange && onChange(containers);
    }, [gridRef, onChange]);

    useEffect(() => {
      if (isCreateContainer) setAllowCargoDetailsColumns(false);
      else {
        const containers_data = otoContainers || value;
        const appropriate_cargo_details_len =
          shipment?.shipment_type === SHIPMENT_TYPE_CONSOL ? 2 : 1;
        const container_cargo_details_validation = containers_data.every((container: any) => {
          return container.container_cargo_details?.length === appropriate_cargo_details_len;
        });
        setAllowCargoDetailsColumns(container_cargo_details_validation);
      }
    }, [isCreateContainer, otoContainers, shipment?.shipment_type, value]);

    const getColumns = useCallback(() => {
      const columnDefs: Column[] = _compact([
        {
          headerName: 'Container Type',
          field: 'container_type',
          colId: 'container_type',
          editable: (o) => !o.node.isRowPinned() && !!isCreateContainer,
          // pinned: 'left',
          // lockPinned: true,
          // width: '15%',
          valueGetter: (params) => {
            return renderContainerType(params.data);
          },
          valueSetter: (params) => {
            params.data.container_type = containerTypes[params.newValue];
            params.data.container_type_code = params.newValue;
            return true;
          },
          suppressKeyboardEvent: (params) => {
            return params.event.key === 'Enter' && params.editing;
          },
          cellEditor: 'EnumEditor',
          cellEditorParams: {
            options: isLiveReefer
              ? reeferContainerTypesWithCodeFlatArray
              : containerTypesWithCodeFlatArray,
          },
          cellClass: (params) => cellClassHelper(params, 'shipment_containers', fields),
        },
        {
          headerName: 'Non ISO',
          field: 'is_non_iso_container',
          colId: 'is_non_iso_container',
          cellRenderer: function checkboxRenderer(params: any) {
            if (params.node.isRowPinned()) return <></>;
            return (
              <Checkbox
                onChange={(e) => {
                  params.data.is_non_iso_container = e.target.checked;
                  onCellValueChanged();
                  params.api.refreshCells({
                    force: true,
                    columns: ['container_number', 'is_non_iso_container'],
                    rowNodes: [params.node],
                  });
                }}
                checked={params?.data?.is_non_iso_container}
              ></Checkbox>
            );
          },
          editable: false,
          cellClass: (params) => cellClassHelper(params, 'shipment_containers', fields),
        },
        {
          headerName: 'Container Number',
          field: 'container_number',
          colId: 'container_number',
          valueSetter: (params) => {
            const is_non_iso_container = params.data.is_non_iso_container;
            let _rules = null;
            let newValue = params.newValue;
            newValue = newValue?.toUpperCase();
            newValue = newValue?.replace(/[^A-Z0-9]/g, '');
            params.data.container_number = newValue;
            try {
              _rules = is_non_iso_container ? true : newValue && validateContainerNumber(newValue);
            } catch (e) {
              console.log(e);
              return false;
            }
            return _rules || true;
          },
          tooltipValueGetter: (params) => {
            const is_non_iso_container = params.data.is_non_iso_container;
            let rules = null;
            let errorDetails;
            try {
              rules = is_non_iso_container
                ? true
                : params.value && validateContainerNumber(params.value);
            } catch (e) {
              errorDetails = e;
            }
            if (params.value && !rules) {
              return errorDetails;
            }
            return '';
          },
          cellStyle: (params) => {
            const is_non_iso_container = params.data.is_non_iso_container;
            let rules = null;
            try {
              rules = is_non_iso_container
                ? true
                : params.value && validateContainerNumber(params.value);
            } catch (e) {
              console.log(e);
            }
            if (params.value && !rules) {
              return { background: '#FFCCCB' };
            }
            return null;
          },
          editable: (o) => !o.node.isRowPinned(),
          cellClass: (params) => cellClassHelper(params, 'shipment_containers', fields),
        },
        {
          headerName: 'S/L Seal Number',
          field: 'carrier_seal_number',
          colId: 'carrier_seal_number',
          editable: (o) => !o.node.isRowPinned(),
          cellClass: (params) => cellClassHelper(params, 'shipment_containers', fields),
        },
        {
          headerName: 'VGM Wt (Kgs)',
          field: 'verified_gross_mass',
          colId: 'verified_gross_mass',
          cellEditor: 'FloatEditor',
          columnType: 'Float',
          cellEditorParams: {
            min: 0.00001,
            step: 0.00001,
          },
          valueSetter: (params) => {
            params.data.verified_gross_mass = Number(params.newValue);
            return true;
          },
          valueGetter: (params) => {
            return extractWt(params.data.verified_gross_mass);
          },
          // width: '9%',
          editable: (o) => !o.node.isRowPinned(),
          cellClass: (params) => cellClassHelper(params, 'shipment_containers', fields),
        },
      ]);

      if ((shipment_type && shipment_type !== SHIPMENT_TYPE_CONSOL) || !shipment_type) {
        columnDefs.push(
          {
            headerName: 'Gross Wt (Kgs)',
            field: 'cargo_gross_weight',
            colId: 'cargo_gross_weight',
            cellEditor: 'FloatEditor',
            columnType: 'Float',
            cellEditorParams: {
              min: 0.00001,
              step: 0.00001,
            },
            valueSetter: (params) => {
              params.data.cargo_gross_weight = Number(params.newValue);
              return true;
            },
            valueGetter: (params) => {
              return extractWt(params.data.cargo_gross_weight);
            },
            editable: (o) => !o.node.isRowPinned(),
            cellClass: (params) => cellClassHelper(params, 'shipment_containers', fields),
          },
          {
            headerName: 'Net Wt (Kgs)',
            field: 'cargo_net_weight',
            colId: 'cargo_net_weight',
            cellEditor: 'FloatEditor',
            cellEditorParams: {
              min: 0.00001,
              step: 0.00001,
            },
            columnType: 'Float',
            valueSetter: (params) => {
              params.data.cargo_net_weight = Number(params.newValue);
              return true;
            },
            valueGetter: (params) => {
              return extractWt(params.data.cargo_net_weight);
            },
            editable: (o) => !o.node.isRowPinned(),
            // width: '9%',
            cellClass: (params) => cellClassHelper(params, 'shipment_containers', fields),
          },
          {
            headerName: 'No of Packages',
            colId: 'total_number_of_packages',
            cellEditor: 'FloatEditor',
            columnType: 'Float',
            cellEditorParams: {
              min: 0,
              step: 1,
              precision: 0,
            },
            valueSetter: (params) => {
              if (shipment?.id) {
                const cargo = params?.data?.container_cargo_details.find((c: any) =>
                  shipment?.shipment_type === SHIPMENT_TYPE_CONSOL
                    ? c?.shipment_id !== shipment?.id
                    : c?.shipment_id === shipment?.id
                );
                cargo.total_number_of_packages = parseInt(params.newValue) || 0;
                if (params.data.total_number_of_packages !== parseInt(params.newValue)) {
                  params.data.total_number_of_packages = parseInt(params.newValue) || 0;
                  return true;
                } else {
                  return false;
                }
              } else {
                params.data.total_number_of_packages = Number(params.newValue) || 0;
                return true;
              }
            },
            valueGetter: (params) => {
              return params?.data?.total_number_of_packages;
            },
            editable: (o) => !o.node.isRowPinned(),
            cellClass: (params) => cellClassHelper(params, 'shipment_containers', fields),
          },
          {
            headerName: 'Type of Package',
            colId: 'type_of_package',
            cellEditor: 'EnumEditor',
            cellEditorParams: {
              options: oceanPackageTypesFlatArray,
            },
            valueSetter: (params) => {
              if (shipment?.id) {
                const cargo = params?.data?.container_cargo_details.find((c: any) =>
                  shipment?.shipment_type === SHIPMENT_TYPE_CONSOL
                    ? c?.shipment_id !== shipment?.id
                    : c?.shipment_id === shipment?.id
                );
                const matchedValue = oceanPackageTypesFlatArray.find((item) => {
                  const itemValueLowerCase = item.value.toLowerCase();
                  const newValueUpperCase = params.newValue.toLowerCase();
                  return itemValueLowerCase === newValueUpperCase;
                });
                const newValue = matchedValue?.value || params.newValue;
                cargo.type_of_package = newValue;
                if (params.data.type_of_package !== newValue)
                  params.data.type_of_package = newValue;

                try {
                  const standardValue = oceanPackageTypesFlatArray.some(
                    (item) => item.value === newValue
                  );
                  if (standardValue) {
                    return true;
                  } else throw new Error('Select correct Package Type');
                } catch (e) {
                  console.log(e);
                  return false;
                }
              } else {
                params.data.type_of_package = params.newValue;
                return true;
              }
            },
            cellStyle: (params) => {
              const standardValue = oceanPackageTypesFlatArray.some(
                (item) => item.value === params.value
              );
              if (params.value && !standardValue) {
                return { background: '#FFCCCB' };
              }
              return null;
            },
            valueGetter: (params) => {
              return params?.data?.type_of_package;
            },
            editable: (o) => !o.node.isRowPinned(),
            suppressKeyboardEvent: (params) => {
              return params.event.key === 'Enter' && params.editing;
            },
            cellClass: (params) => cellClassHelper(params, 'shipment_containers', fields),
          }
        );
      }

      columnDefs.push(
        {
          headerName: 'Commercial Invoice #',
          field: 'commercial_invoice_number',
          colId: 'commercial_invoice_number',
          columnType: 'String',
          editable: (o) => !o.node.isRowPinned(),
          width: 180,
          cellClass: (params) => cellClassHelper(params, 'shipment_containers', fields),
        },
        {
          headerName: 'Purchase Order #',
          field: 'purchase_order_number',
          colId: 'purchase_order_number',
          width: 180,
          columnType: 'String',
          editable: (o) => !o.node.isRowPinned(),
          cellClass: (params) => cellClassHelper(params, 'shipment_containers', fields),
        },
        {
          headerName: 'Remarks',
          field: 'remarks',
          colId: 'remarks',
          width: 180,
          columnType: 'String',
          editable: (o) => !o.node.isRowPinned(),
          cellClass: (params) => cellClassHelper(params, 'shipment_containers', fields),
        }
      );

      if (tradeType === TRADE_TYPE_IMPORT || shipment?.trade_type === TRADE_TYPE_IMPORT) {
        const containerTypeColumn = columnDefs.find((col) => col.field === 'container_type');
        const containerNumberColumn = columnDefs.find((col) => col.field === 'container_number');
        const containerIsoColumn = columnDefs.find((col) => col.field === 'is_non_iso_container');
        if (containerTypeColumn && containerNumberColumn && containerIsoColumn) {
          columnDefs.splice(columnDefs.indexOf(containerTypeColumn), 1);
          columnDefs.splice(columnDefs.indexOf(containerNumberColumn), 1);
          columnDefs.splice(columnDefs.indexOf(containerIsoColumn), 1);
          columnDefs.unshift(containerIsoColumn, containerNumberColumn, containerTypeColumn);
        }
      }
      return columnDefs;
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
      allowCargoDetailsColumns,
      isCreateContainer,
      onCellValueChanged,
      shipment?.id,
      shipment?.shipment_type,
      isLiveReefer,
      tradeType,
      shipment?.trade_type,
    ]);

    const columns = getColumns();
    if (!isCreateContainer) {
      columns.unshift({
        headerName: 'Pickup Date',
        field: 'pickup_date',
        colId: 'pickup_date',
        // width: '12%',
        columnType: 'Date',
        cellEditor: 'DateEditor',
        editable: (o) => !o.node.isRowPinned(),
        cellEditorParams: {
          disabledDate: disableFutureDate,
        },
        headerTooltip: 'Supports only DD/MM/YYYY format for copy-paste',
        valueSetter: (params) => {
          if (!params.newValue) return false;
          let parsedDate = dayjs(params.newValue, ['DD/MM/YYYY', 'D/M/YY', 'DD/MM/YY'], true);

          if (!parsedDate.isValid()) {
            parsedDate = dayjs(params.newValue, 'ddd, DD MMM YYYY'); // Handle ctrl + d
          }

          if (parsedDate.isValid()) {
            if (parsedDate.isAfter(dayjs(), 'day')) {
              message.error('Pickup date cannot be in the future');
              return false;
            }
            params.data.pickup_date = parsedDate.unix();
            return true;
          } else {
            return false;
          }
        },
        valueGetter: (params) => {
          if (params.node?.isRowPinned()) return '';

          if (params.data.pickup_date == null) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            //@ts-ignore
            params.data.pickup_date = dayjsGenerateConfig.getNow() / 1000;
          }
          return params.data.pickup_date;
        },
        cellEditorPopup: true,
        cellClass: (params) => cellClassHelper(params, 'shipment_containers', fields),
      });
    }

    const handleAddContainers = () => {
      onChange && onChange([...value, { container_number: '' }]);
    };

    const handleDeleteContainers = () => {
      const selectedRowIds = gridRef?.current?.api?.getSelectedNodes().map((c: any) => c.rowIndex);
      const finalNodeData: any[] = [];
      gridRef?.current?.api?.forEachNode((node: any) => {
        if (!selectedRowIds.includes(node.rowIndex)) finalNodeData.push(node?.data);
      });
      onChange && onChange(finalNodeData);
    };

    const validateContainerDetails = useCallback(() => {
      let foundError = false;
      _map<any>(value, (cr: any) => {
        const values = Object.values(cr);
        if (values.length) {
          _map(requiredFields, (field: any) => {
            if (!cr[field]) {
              setErrorsPresent(true);
              foundError = true;
            }
          });
        }
      });
      if (!foundError) {
        setErrorsPresent(false);
      }
      return foundError;
    }, [requiredFields, value]);

    useImperativeHandle(ref, () => ({
      runValidation() {
        let foundError = false;
        if (!value || value?.length === 0) {
          setErrorsPresent(false);
          foundError = false;
        } else {
          foundError = validateContainerDetails();
        }
        return foundError;
      },
    }));
    return (
      <>
        <FormTable
          reportName="container_form"
          rowData={value || otoContainers}
          columns={columns}
          gridRef={gridRef}
          rowSelection={showAddDelete ? 'multiple' : undefined}
          reportConfig={{
            components: {
              DateEditor,
              StringEditor,
              FloatEditor,
              EnumEditor,
            },
            suppressLastEmptyLineOnPaste: true,
            rowHeight: 40,
            suppressClipboardApi: true,
            onCellValueChanged: onCellValueChanged,
            defaultColDef: {
              editable: true,
              flex: 1,
              minWidth: 100,
              resizable: true,
              cellStyle: (params: CellClassParams<any, any>) => {
                const fieldName = params.colDef?.field || '';
                const value = params.value;
                let cellStyle = null;
                let anyValuePresent = false;
                Object.values(params.data).forEach((value) => {
                  if (value) anyValuePresent = true;
                });
                if (
                  requiredFields.includes(fieldName) &&
                  !value &&
                  errorsPresent &&
                  !params?.node?.isRowPinned() &&
                  anyValuePresent
                )
                  cellStyle = ERROR_CELL_STYLE;
                return cellStyle;
              },
            },
            enableRangeSelection: true,
            stopEditingWhenCellsLoseFocus: true,
          }}
        />
        {errorsPresent && <Text type="danger">Please fill required fields</Text>}

        {showAddDelete && (
          <div style={{ margin: '10px' }}>
            <Button
              size="small"
              style={{
                marginRight: 16,
              }}
              onClick={handleAddContainers}
            >
              Add row
            </Button>
            <Button
              danger
              size="small"
              style={{
                marginRight: 16,
              }}
              disabled={false}
              onClick={handleDeleteContainers}
            >
              Delete row(s)
            </Button>
          </div>
        )}
      </>
    );
  },
  { forwardRef: true }
);

export default ContainerDetailsForm;
