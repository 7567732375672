import React, { useEffect, useMemo, useState } from 'react';
import { DetailsCard } from 'common';
import { useCompanyView } from '../CompanyView';
import { Button, CreateContactPopup, Typography } from '@shipmnts/pixel-hub';
import { SectionEditorModal } from '../SectionEditorModal';
import { useEmailTemplatesContext, useSession, ADMINISTRATION_ACCESS_LEVELS } from 'common';
import CompanyEmailPreferencesForm from 'network/components/CompanyEmailPreferencesForm';

import { pick as _pick, orderBy as _orderBy } from 'lodash';
import { CompanyValue } from 'common/models/Company';
import { EmailTemplate } from 'common/commonTypeDefs';
import { VALIDATE_EMAIL_CONTACTS } from 'src/graphQL/emailTemplates';
import { useLazyQuery } from '@apollo/client';
import { unionBy as _uniqBy } from 'lodash';

const getFormInitialValues = (company: CompanyValue, templates?: EmailTemplate[]) => {
  const company_email_preferences =
    company.company_email_preferences?.map((st) =>
      _pick(st, ['id', 'email_template_id', 'address_id', 'to_emails', 'cc_emails'])
    ) || [];
  templates?.forEach((t) => {
    if (!company_email_preferences.find((pref) => pref.email_template_id === t.id)) {
      company_email_preferences.push({
        id: undefined,
        email_template_id: t.id,
        address_id: undefined,
        to_emails: undefined,
        cc_emails: undefined,
      });
    }
  });
  const formPayload = {
    ..._pick(company, ['id']),
    company_email_preferences,
  };
  return formPayload;
};

export const EmailPreferenceCard = () => {
  const { company, loading } = useCompanyView();
  const { templates } = useEmailTemplatesContext();
  const data = useMemo(() => company?.company_email_preferences, [company]);
  const [editMode, setEditMode] = useState(false);

  const [newContacts, setNewContacts] = useState<any[]>([]);
  const [showAddContactPopup, setShowAddContactPopup] = useState(false);
  const session = useSession();

  const [
    validateEmailContacts,
    { data: validateEmailContactsData, loading: validateEmailLoading },
  ] = useLazyQuery(VALIDATE_EMAIL_CONTACTS);

  const validateEmails = (emails: string[]) => {
    if (!emails || emails.length === 0) {
      return;
    }
    validateEmailContacts({
      variables: {
        emails,
        looped_in_parties: [company?.id],
      },
    });
  };

  const onPreferenceUpdateSubmit = (values: any, form: any) => {
    const emails: string[] = [];
    values?.company_email_preferences?.forEach((pref: any) => {
      emails.push(...(pref?.to_emails ?? []));
      emails.push(...(pref?.cc_emails ?? []));
    });
    form?.submit();
    validateEmails(emails);
  };

  const closeModal = () => {
    setEditMode(false);
    setShowAddContactPopup(false);
  };

  useEffect(() => {
    if (validateEmailLoading) return;
    if (validateEmailContactsData) {
      const newContactsResponse = validateEmailContactsData?.validate_email_contacts?.error_map
        ?.filter((error: any) => error.error_type === 'does_not_exist')
        .map((error: any) => {
          return {
            email: error.email,
          };
        });
      setNewContacts(_uniqBy(newContactsResponse, 'email'));
      if (newContactsResponse?.length) {
        setShowAddContactPopup(true);
      } else {
        closeModal();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validateEmailContactsData, validateEmailLoading]);

  // fixed style
  const headingStyle = { display: 'block', opacity: 0.6, fontSize: '12px', fontWeight: '600' };

  return (
    <>
      <CreateContactPopup
        isOpen={showAddContactPopup}
        setIsOpen={setShowAddContactPopup}
        contacts={newContacts}
        loopedInParties={company && ([company] as any)}
        onSuccess={closeModal}
        onCancel={closeModal}
      />
      <DetailsCard
        header={{ title: 'Email Preferences' }}
        style={{ width: '100%', marginInline: 'auto' }}
        loading={loading}
        Action={
          <Button
            disabled={
              company?.is_disabled ||
              (company?.company_group === 'Self' &&
                !ADMINISTRATION_ACCESS_LEVELS.includes(session?.user_level || ''))
            }
            size="small"
            onClick={() => {
              if (!!!company?.is_disabled) {
                setEditMode(true);
              }
            }}
          >
            {'Edit'}
          </Button>
        }
      >
        <>
          {data && data.length > 0 ? (
            <>
              {data?.map((row, idx) => {
                return (
                  <div
                    key={row?.id}
                    style={{
                      display: 'grid',
                      gridAutoFlow: 'column',
                      gridTemplateColumns: '20% 15% 33% 32%',
                      borderBottom: idx !== data.length - 1 ? '1px solid rgba(0, 0, 0, .1)' : '',
                      padding: '1em',
                      gap: '1em',
                    }}
                  >
                    <section style={{ wordWrap: 'break-word' }}>
                      <span style={headingStyle}>Email Template</span>
                      <span>{row?.email_template?.template_name || '-'}</span>
                    </section>
                    <section style={{ wordWrap: 'break-word', alignSelf: 'start' }}>
                      <span style={headingStyle}>Address</span>
                      <Typography.Text ellipsis>
                        {row.address?.name || row.address?.city_name || row.address?.print_address}
                      </Typography.Text>
                    </section>
                    <section style={{ wordWrap: 'break-word', alignSelf: 'start' }}>
                      <span style={headingStyle}>To Emails</span>
                      <span>{row?.to_emails?.join(', ') || '-'}</span>
                    </section>

                    <section style={{ wordWrap: 'break-word', alignSelf: 'start' }}>
                      <span style={headingStyle}>CC Emails</span>
                      <span>{row?.cc_emails?.join(', ') || '-'}</span>
                    </section>
                  </div>
                );
              })}
            </>
          ) : (
            <div
              style={{ textAlign: 'center', padding: '1em', fontSize: '16px', opacity: '0.4' }}
            >{`No Data Available.`}</div>
          )}
          {editMode && company && (
            <SectionEditorModal
              title="Email Preferences"
              show={editMode}
              setShow={setEditMode}
              initialValues={getFormInitialValues(company, templates)}
              modalWidth={1200}
              onSubmit={onPreferenceUpdateSubmit}
            >
              {({ form }) => {
                return (
                  <CompanyEmailPreferencesForm
                    form={form}
                    addresses={_orderBy(
                      company?.addresses || [],
                      ['is_disabled', 'entity_type'],
                      ['asc', 'asc']
                    )}
                    templates={templates}
                    company={company}
                  />
                );
              }}
            </SectionEditorModal>
          )}
        </>
      </DetailsCard>
    </>
  );
};
