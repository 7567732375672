import { gql } from '@apollo/client';
import { addressFields } from './shipmentEstimate';
import { templateFields } from 'src/graphQL/template';
import { salesPersonFields } from './salesPerson';
import { businessVerticalFields } from './inquiries';

export const companyFields = gql`
  fragment companyFields on CompanyObjectType {
    id
    status
    created_at
    updated_at
    registered_name
    country_of_incorporation
    company_type
    created_by {
      id
      first_name
      last_name
    }
    company_group
    entity_type
    logo
    is_disabled
    remarks
    company_identification_number
    tax_registration_number
    tax_deduction_id
    receivable_credit_control_type
    addresses {
      ...addressFields
    }
    company_email_preferences {
      id
      email_template_id
      address_id
      to_emails
      cc_emails
      address {
        id
        name
        city_name
        print_address
      }
      email_template {
        id
        template_name
        action_name
      }
    }
    global_company_account {
      id
      registered_name
    }
  }
  ${addressFields}
  ${salesPersonFields}
`;

export const globalPortFields = gql`
  fragment globalPortFields on PortObjectType {
    id
    name
    type
    iata_code
    unlocode
    customs_code
    address
    parent_id
    city {
      name
      code
    }
    country {
      name
      code
    }
    country_code
  }
`;

export const OceanVesselFields = gql`
  fragment OceanVesselFields on OceanVesselObjectType {
    flagId: flag_id
    imo
    mmsi
    name
    vesselType: vessel_type
    is_frequent
  }
`;

export const globalCarrierFields = gql`
  fragment globalCarrierFields on CarrierObjectType {
    id
    name
    carrier_type
    iata_carrier_code
    standard_alpha_carrier_code
    iata_awb_prefix
  }
`;

export const inquiryOptionFields = gql`
  fragment inquiryOptionFields on InquiryOptionObject {
    id
    remarks
    amend_from {
      id
      quotation_number
    }
    terms_and_cond {
      ...templateFields
    }
    terms_and_cond_desc
    shipment_estimates {
      id
      item
      inquiry_option_id
      buy_branch_id
      sell_branch_id
      supplier_company_id
      supplier_company {
        ...companyFields
      }
      customer_company_id
      customer_company {
        ...companyFields
      }
      uom
      quantity
      buy_rate
      buy_exchange_rate
      buy_terms
      buy_currency
      total_buy_amount
      total_buy_billed_amount
      sell_rate
      sell_exchange_rate
      sell_terms
      sell_currency
      total_sell_amount
      total_sell_billed_amount
      remarks
      buy_status
      sell_status
      buy_transaction_identifier
      buy_transaction_doctype
      sell_transaction_identifier
      equipment_type
      equipment_name
      tag
    }
    inquiry {
      id
      trade_type
      load_type
      freight_type
      priority
      last_action_status
      inquiry_number
      cargos {
        id
        cargo_properties {
          is_perishable
          is_temp_controlled
          is_hazardous
          is_battery
          un_number
          un_imo_class
          proper_shipping_name
          technical_name
          packaging_group
          net_weight
          net_weight_unit
          flash_point
          flash_point_unit
          ems_number
          emergency_contact_name
          emergency_contact_number
          aircraft_type
          is_radioactive
          temperature_unit
          temperature
          battery_type
        }
        product_name
        net_weight
        outer_package_qty
        gross_volume
        gross_weight
        weight_unit
        volume_unit
        commodity_id
        commodity_type
        volumetric_weight
        chargeable_weight
        invoice_number
        invoice_date
        batch_number
        custom_ref
        serial_number
        eway_bill_no
        eway_bill_validity
        commodity {
          id
          name
          commodity_classification_code
          commodity_coding_system
          dangerous_cargo
          dry_cargo
          live_reefer_cargo
          oog_cargo
        }
        id
        gross_volume
        height
        outer_package_qty
        outer_per_packet_wt
        width
        length
        outer_package_type
        dimension_unit
      }
      container_requests {
        id
        container_settings {
          is_active_reefer
          temperature
          temperature_unit
          ventilation_requested
          air_flow
          air_flow_unit
          humidity_control_requested
          relative_humidity_percent
          vent_setting
          additional_instructions
          genset_requested
          controlled_atmosphere_requested
          oxygen_level_percent
          nitrogen_level_percent
          carbon_dioxide_level_percent
          length
          width
          height
          lbh_unit
        }
        container_type
        container_type_code
        is_shipper_owned
        quantity
        quantity_fulfilled
        quantity_unfulfilled
        quantity_picked_up
        quantity_origin_port_gated_in
        quantity_loaded_on_vessel
        quantity_shutout
        quantity_cancelled
        quantity_offloaded
        weight_per_container
        weight_unit
      }
      customer_company {
        id
        registered_name
        status
        logo
        company_group
        country_of_incorporation
      }
      customer_address {
        id
        name
        print_address
      }
      sales_person {
        ...salesPersonFields
      }
      pricing_person {
        id
        first_name
        last_name
      }
      origin {
        ... on LocationObjectType {
          id
          name
          type
          city {
            name
          }
          state
          country {
            name
          }
          country_code
          unlocode
        }
        ... on AddressObjectType {
          ...addressFields
        }
      }
      destination {
        ... on LocationObjectType {
          id
          name
          type
          city {
            name
          }
          state
          country {
            name
          }
          country_code
          unlocode
        }
        ... on AddressObjectType {
          ...addressFields
        }
      }
      origin_custom_clearance_location {
        id
        type
        name
        unlocode
      }
      destination_custom_clearance_location {
        id
        name
        unlocode
      }
      created_by {
        id
        first_name
        last_name
      }
      incoterms
      description
      target_rate
      target_closing_date
      received_at
      cargo_ready_date
      target_delivery_date
      preferred_carriers {
        id
        carrier_type
        iata_awb_prefix
        iata_carrier_code
        name
        standard_alpha_carrier_code
        meta_information
        short_code
      }
      services
      tags
      next_actions
      business_vertical {
        ...businessVerticalFields
      }
      involved_branch {
        id
        name
      }

      freight_terms
      other_terms
      destination_port_free_days
      origin_carrier_free_days
      destination_carrier_free_days
    }
    port_of_loading {
      id
      type
      name
      unlocode
    }
    port_of_discharge {
      id
      type
      name
      unlocode
    }
    place_of_carrier_receipt {
      id
      type
      name
      unlocode
    }
    place_of_carrier_delivery {
      id
      type
      name
      unlocode
    }
    ocean_vessel {
      ...OceanVesselFields
    }
    carrier {
      ...globalCarrierFields
    }
    booking_vendor {
      ...companyFields
    }
    voyage_number
    vendor_rate_agreement_number
    valid_from
    valid_to
    tariff_rate
    routing
    quote_currency
    origin_carrier_free_days
    destination_carrier_free_days
    destination_port_free_days
    exchange_rate
    carrier_product
    estimated_time_of_departure
    estimated_time_of_arrival
    chargeable_weight
    quotation_number
    status
    transit_days
    total_sell
    total_buy
    freight_sell_unit_rate
    freight_buy_unit_rate
    freight_buy_unit_cur
    freight_sell_unit_cur
  }
  ${companyFields}
  ${salesPersonFields}
  ${globalCarrierFields}
  ${OceanVesselFields}
  ${templateFields}
  ${addressFields}
  ${businessVerticalFields}
`;

export const LINK_INQUIRY_OPTION_TO_RESOURCE = gql`
  mutation link_inquiry_option_to_resource(
    $resource_id: ID!
    $resource_type: String!
    $add_charges: Boolean!
    $inquiry_option_id: ID!
  ) {
    link_inquiry_option_to_resource(
      resource_id: $resource_id
      resource_type: $resource_type
      add_charges: $add_charges
      inquiry_option_id: $inquiry_option_id
    ) {
      message
    }
  }
`;

export const DELINK_INQUIRY_OPTION_FROM_RESOURCE = gql`
  mutation delink_inquiry_option_from_resource(
    $resource_id: ID!
    $resource_type: String!
    $delete_charges: Boolean!
  ) {
    delink_inquiry_option_from_resource(
      resource_id: $resource_id
      resource_type: $resource_type
      delete_charges: $delete_charges
    ) {
      message
    }
  }
`;

export const GET_INQUIRY_OPTION = gql`
  query get_inquiry_option($id: ID!) {
    get_inquiry_option(id: $id) {
      id
      shipment_estimates {
        item
        uom
        quantity
        sell_rate
        total_sell_amount
        buy_rate
        total_buy_amount
        sell_currency
        buy_currency
        sell_exchange_rate
        buy_exchange_rate
        equipment_type
      }
    }
  }
`;
