import { RadioOptionProps } from 'network/commonTypeDefs';
import {
  COMPANY_STATUS_APPROVED,
  COMPANY_STATUS_MODERATE_RISK,
  COMPANY_STATUS_HIGH_RISK,
  COMPANY_STATUS_LEGAL_DISPUTE,
  COMPANY_STATUS_NO_CREDIT,
  COMPANY_STATUS_CONTACT_CREATED,
} from 'network/models/Company';

interface KeyLabelMapping {
  [key: string]: string;
}

export const CARRIER_TYPE_AIR = 'air';
export const TYPE_ALL_COMPANIES = 'all_companies';
export const TYPE_CUSTOMERS = 'customers';
export const TYPE_VENDORS = 'suppliers';
export const TYPE_ADDRESS_BOOK = 'address_book';

export const keyLabelMapping: KeyLabelMapping = {
  [TYPE_ALL_COMPANIES]: 'All Companies',
  [TYPE_CUSTOMERS]: 'Customers',
  [TYPE_VENDORS]: 'Suppliers',
  [TYPE_ADDRESS_BOOK]: 'Other Parties',
  [COMPANY_STATUS_MODERATE_RISK]: 'Moderate Risk',
  [COMPANY_STATUS_HIGH_RISK]: 'High Risk',
  [COMPANY_STATUS_APPROVED]: 'Credit Approved',
  [COMPANY_STATUS_LEGAL_DISPUTE]: 'In Legal Dispute',
  [COMPANY_STATUS_NO_CREDIT]: 'No Credit',
  [COMPANY_STATUS_CONTACT_CREATED]: 'Not Applicable',
};

export const COMPANY_GROUP_SHIPPER_CONSIGNEE = 'Shipper / Consignee';
export const COMPANY_GROUP_SUBAGENT = 'Subagent';
export const COMPANY_GROUP_OVERSEAS_AGENT = 'Overseas Agent';
export const COMPANY_GROUP_SHIPPING_LINE = 'Shipping Line';
export const COMPANY_GROUP_NVOCC_COLOADER = 'NVOCC / Coloader';
export const COMPANY_GROUP_AIRLINE = 'Airline';
export const COMPANY_GROUP_GSA = 'GSA';
export const COMPANY_GROUP_CFS = 'CFS';
export const COMPANY_GROUP_PORT_TERMINAL = 'Port Terminal';
export const COMPANY_GROUP_TRANSPORTER = 'Transporter';
export const COMPANY_GROUP_CUSTOM_BROKER = 'Custom Broker';
export const COMPANY_GROUP_BANK = 'Bank';
export const COMPANY_GROUP_GENERAL_EXPENSE = 'General Expense';
export const COMPANY_GROUP_OTHER_AGENT = 'Other Agent';
export const COMPANY_GROUP_WAREHOUSE = 'Warehouse';
export const COMPANY_GROUP_ICD_INLAND = 'ICD / Inland Ramp';
export const COMPANY_GROUP_INTERBRANCH = 'Interbranch';

export const COMPANY_GROUPS = [
  COMPANY_GROUP_SHIPPER_CONSIGNEE,
  COMPANY_GROUP_SUBAGENT,
  COMPANY_GROUP_OVERSEAS_AGENT,
  COMPANY_GROUP_SHIPPING_LINE,
  COMPANY_GROUP_NVOCC_COLOADER,
  COMPANY_GROUP_AIRLINE,
  COMPANY_GROUP_GSA,
  COMPANY_GROUP_CFS,
  COMPANY_GROUP_PORT_TERMINAL,
  COMPANY_GROUP_TRANSPORTER,
  COMPANY_GROUP_CUSTOM_BROKER,
  COMPANY_GROUP_OTHER_AGENT,
  COMPANY_GROUP_BANK,
  COMPANY_GROUP_GENERAL_EXPENSE,
  COMPANY_GROUP_WAREHOUSE,
  COMPANY_GROUP_ICD_INLAND,
  COMPANY_GROUP_INTERBRANCH,
];

export const COMPANY_TYPE_INDIVIDUAL = 'Individual / Proprietorship';
export const COMPANY_TYPE_COMPANY = 'Company';
export const COMPANY_TYPE_PRIVATE = 'Private Limited';
export const COMPANY_TYPE_PUBLIC = 'Public Limited';
export const COMPANY_TYPE_INC = 'INC';
export const COMPANY_TYPE_LLP = 'LLP';
export const COMPANY_TYPE_PARTNERSHIP = 'Partnership';
export const CREDIT_CONTROL_TYPE_AUTOMATIC = 'automatic';
export const CREDIT_CONTROL_TYPE_MANUAL = 'manual';
export const CREDIT_CONTROL_TYPE_NO_CREDIT = 'no_credit';

export const CREDIT_STATUSES_FOR_AUTOMATIC: RadioOptionProps[] = [
  {
    key: COMPANY_STATUS_APPROVED,
    name: keyLabelMapping[COMPANY_STATUS_APPROVED],
  },
  {
    key: COMPANY_STATUS_MODERATE_RISK,
    name: keyLabelMapping[COMPANY_STATUS_MODERATE_RISK],
  },
  {
    key: COMPANY_STATUS_HIGH_RISK,
    name: keyLabelMapping[COMPANY_STATUS_HIGH_RISK],
  },
];

export const CREDIT_STATUSES_FOR_MANUAL: RadioOptionProps[] = [
  ...CREDIT_STATUSES_FOR_AUTOMATIC,
  {
    key: COMPANY_STATUS_LEGAL_DISPUTE,
    name: keyLabelMapping[COMPANY_STATUS_LEGAL_DISPUTE],
  },
];

export const CREDIT_STATUSES_FOR_NO_CREDIT: RadioOptionProps[] = [
  {
    key: COMPANY_STATUS_NO_CREDIT,
    name: keyLabelMapping[COMPANY_STATUS_NO_CREDIT],
  },
  {
    key: COMPANY_STATUS_LEGAL_DISPUTE,
    name: keyLabelMapping[COMPANY_STATUS_LEGAL_DISPUTE],
  },
];

export const CREDIT_CONTROL_FEATURE = 'enforce_credit_control';

export const DEPARTMENT_SALES = 'Sales';
export const DEPARTMENT_PRICING = 'Pricing';
export const DEPARTMENT_BOOKING_CS = 'Booking / CS';
export const DEPARTMENT_OPERATIONS = 'Operations';
export const DEPARTMENT_DOCUMENTATION = 'Documentation';
export const DEPARTMENT_FINANCE = 'Finance';
export const DEPARTMENT_LOGISTICS = 'Logistics';

export const DEPARTMENTS = [
  DEPARTMENT_SALES,
  DEPARTMENT_PRICING,
  DEPARTMENT_BOOKING_CS,
  DEPARTMENT_OPERATIONS,
  DEPARTMENT_DOCUMENTATION,
  DEPARTMENT_FINANCE,
  DEPARTMENT_LOGISTICS,
];

export const REGULATORY_DETAIL_OPTIONS = [
  { value: 'MTO', label: 'MTO' },
  { value: 'FMC_NO', label: 'FMC NO' },
  { value: 'FMC_ADDRESS', label: 'FMC ADDRESS' },
  { value: 'FMC_LOGO_URL', label: 'FMC LOGO URL' },
  { value: 'IATA', label: 'IATA CODE' },
  { value: 'IATA_ACCOUNT_NO', label: 'IATA ACCOUNT NO' },
  { value: 'IEC', label: 'IEC' },
  { value: 'TAN', label: 'TAN' },
  { value: 'BIN_NO', label: 'BIN NO' },
  {
    value: 'CONTAINER_SELF_SEALING_PERMISSION_NO',
    label: 'CONTAINER SELF SEALING PERMISSION NO',
  },
  { value: 'NVOCC_SCAC_CODE', label: 'NVOCC SCAC CODE' },
  { value: 'WCA_NO', label: 'WCA NO' },
  { value: 'US_CUSTOMS_RECORD_NO', label: 'US CUSTOMS RECORD NO' },
  { value: 'MSME_#', label: 'MSME #' },
];

export const LANDING_DASHBOARD = 'Landing';
export const CUSTOMER_DASHBOARD = 'Customer Relations';
export const SP_FLOW = 'Interbranch sales invoice creation (SP)';
export const PSP_FLOW = 'Purchase invoice creation (PSP)';
export const NONE_FLOW = 'None';
