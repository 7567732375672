import React, { useState, useEffect } from 'react';
import {
  Row,
  Col,
  Select,
  Typography,
  Form,
  Radio,
  Space,
  InputNumber,
  Checkbox,
  GlobalSearch,
  DatePicker,
  AddressCompanySearch,
} from '@shipmnts/pixel-hub';
import { RoutingNodeValue } from 'operations/models/RoutingNode';

import { LocationType } from 'operations/models/Location';
import {
  ROUTING_TYPE_MAIN_CARRIAGE,
  RoutingLegValue,
  ROUTING_TYPE_PICKUP_DELIVERY,
  ROUTING_TYPE_DELIVERY,
  ROUTING_TYPE_ON_CARRIAGE,
  ROUTING_TYPE_PRE_CARRIAGE,
} from 'operations/models/RoutingLeg';
import {
  LOCATION_TYPE_ADDRESS,
  LOCATION_TYPE_CITY,
  LOCATION_TYPE_PORT_ICD,
  LOCATION_TYPE_CFS_YARD,
} from 'operations/baseConstants';
import { LOCATION_TYPES } from 'operations/modules/shipment/components/ShipmentForm/constants';
import { RadioOptionProps } from 'operations/commonTypeDefs';
import { RoutingLegCallback, RoutingNodeCallback, getSeparatedLocationSearchType } from './helpers';
import { ROUTING_NODE_LABEL_DESTINATION, ROUTING_NODE_LABEL_ORIGIN } from 'operations/constants';
import GenAIInputWrapper from 'operations/modules/shipment/components/NewShipmentForm/FormFiller/GenAIInputWrapper';
import {
  useFormResponsive,
  VIEW_TYPE_SM,
} from 'operations/modules/shipment/components/NewShipmentForm/FormResponsiveContext';

const { Option } = Select;
const { Text } = Typography;

const NodeAndLegsInfo = React.memo(function NodeAndLegsInfo(props: {
  node: RoutingNodeValue & { locationType?: string };
  incoming_edge?: RoutingLegValue;
  outgoing_edge?: RoutingLegValue;
  onRoutingLegFieldChange: RoutingLegCallback;
  onRoutingNodeFieldChange: RoutingNodeCallback;
  index: number;
  showTerminal?: boolean;
  disableNode?: boolean;
  disabled?: boolean;
  locationSearchType?: Array<LocationType>;
  errorsPresent?: boolean;
  validateVesselVoyage?: boolean;
  routing_type?: string;
  showWaitingTime?: boolean;
  disableVoyageUpdate?: boolean;
  showDpd?: boolean;
}): JSX.Element {
  const {
    node,
    incoming_edge,
    outgoing_edge,
    onRoutingLegFieldChange,
    onRoutingNodeFieldChange,
    index,
    showTerminal,
    disableNode,
    disabled,
    locationSearchType,
    errorsPresent,
    validateVesselVoyage,
    routing_type,
    showWaitingTime,
    disableVoyageUpdate,
    showDpd,
  } = props;
  const isFirstMainCarriageHop =
    outgoing_edge?.routing_type === ROUTING_TYPE_MAIN_CARRIAGE && index === 0;
  const showEta = Boolean(incoming_edge);
  const showEtd = Boolean(outgoing_edge);
  const nodeLabel =
    routing_type === ROUTING_TYPE_PICKUP_DELIVERY || routing_type === ROUTING_TYPE_DELIVERY
      ? 'Location'
      : routing_type === ROUTING_TYPE_MAIN_CARRIAGE && outgoing_edge && !incoming_edge
      ? 'Port Of Loading'
      : routing_type === ROUTING_TYPE_MAIN_CARRIAGE && !outgoing_edge && incoming_edge
      ? 'Port Of Discharge'
      : routing_type === ROUTING_TYPE_PRE_CARRIAGE && outgoing_edge && !incoming_edge
      ? 'Origin ICD / Port'
      : routing_type === ROUTING_TYPE_PRE_CARRIAGE && !outgoing_edge && incoming_edge
      ? 'Port of Loading'
      : routing_type === ROUTING_TYPE_ON_CARRIAGE && !outgoing_edge && incoming_edge
      ? 'Destination ICD / Port'
      : routing_type === ROUTING_TYPE_ON_CARRIAGE && outgoing_edge && !incoming_edge
      ? 'Port of Discharge'
      : incoming_edge && !outgoing_edge
      ? ROUTING_NODE_LABEL_DESTINATION
      : outgoing_edge && !incoming_edge
      ? ROUTING_NODE_LABEL_ORIGIN
      : 'Transhipment Hop';
  const isEtdRequired = validateVesselVoyage && node?.tags?.includes('port_of_loading');
  // const location = node?.location;
  const [locationType, setLocationType] = useState(LOCATION_TYPE_PORT_ICD);
  const nodeLocationType = node?.location?.type || node?.locationType;
  const company = node?.company;
  const isPickupDeliveryType =
    routing_type === ROUTING_TYPE_PICKUP_DELIVERY || routing_type === ROUTING_TYPE_DELIVERY;

  const handleWaitingTimeChange = (value: string) => {
    onRoutingNodeFieldChange('waiting_time_unit', value, node?.id || node?._id || '');
  };
  const waitingTimeUnit = (value: string, onChange: (value: string) => void) => {
    return (
      <Select defaultValue="days" style={{ width: 78 }} value={value || 'days'} onChange={onChange}>
        <Option value="days">Days</Option>
        <Option value="hrs">Hrs</Option>
      </Select>
    );
  };

  const { viewType } = useFormResponsive();

  const [showDpdCheckbox, setShowDpdCheckbox] = useState(showDpd);

  useEffect(() => {
    if (!!company || ['Address', LOCATION_TYPE_ADDRESS].includes(nodeLocationType)) {
      setLocationType(LOCATION_TYPE_ADDRESS);
    } else if (['Seaport', 'Airport', 'ICD', LOCATION_TYPE_PORT_ICD].includes(nodeLocationType))
      setLocationType(LOCATION_TYPE_PORT_ICD);
    else if ([LOCATION_TYPE_CITY, 'City'].includes(nodeLocationType)) {
      setLocationType(LOCATION_TYPE_CITY);
    } else if (['CFS', LOCATION_TYPE_CFS_YARD].includes(nodeLocationType)) {
      setLocationType(LOCATION_TYPE_CFS_YARD);
    }
  }, [nodeLocationType, company]);

  useEffect(() => {
    setShowDpdCheckbox(showDpd);
  }, [showDpd]);

  const cols = [];

  isPickupDeliveryType &&
    cols.push(
      <>
        <Form.Item
          required
          validateStatus={errorsPresent && !node?.location ? 'error' : 'success'}
          label={'Location Type'}
        >
          <Radio.Group
            value={locationType}
            onChange={(e) => {
              setLocationType(e.target.value);
              onRoutingNodeFieldChange(
                'location_type',
                e.target.value,
                node?.id || node?._id || ''
              );
            }}
          >
            <Space direction="vertical">
              {LOCATION_TYPES.map((option: RadioOptionProps, index: number) => (
                <Radio key={index} value={option.key}>
                  {option.name}
                </Radio>
              ))}
            </Space>
          </Radio.Group>
        </Form.Item>
      </>
    );

  [LOCATION_TYPE_PORT_ICD, LOCATION_TYPE_CITY, LOCATION_TYPE_CFS_YARD].includes(locationType) ||
  !isPickupDeliveryType
    ? cols.push(
        <>
          <Form.Item dependencies={['voyage_number, voyage_schedule_id']}>
            {(form) => {
              const { setFieldsValue } = form;
              return (
                <>
                  <Form.Item
                    required
                    rules={[{ required: true }]}
                    validateStatus={errorsPresent && !node?.location ? 'error' : 'success'}
                    label={nodeLabel}
                  >
                    <GenAIInputWrapper
                      fieldKey={['location', 'name']}
                      value={node?.location}
                      arrayValueConfig={{
                        path_to_array: ['routing_nodes'],
                        findConfig: {
                          key: '_id',
                          value: node?._id,
                        },
                      }}
                    >
                      <GlobalSearch
                        doc_type="Global::Location"
                        value={node?.location}
                        onChange={(value: any) => {
                          const isPOLChanged =
                            nodeLabel === 'Port Of Loading' &&
                            routing_type === ROUTING_TYPE_MAIN_CARRIAGE;
                          if (isPOLChanged) {
                            setFieldsValue({
                              voyage_schedule_id: null,
                              voyage_number: null,
                            });
                            onRoutingLegFieldChange('voyage_number', null, index);
                          }
                          onRoutingNodeFieldChange('location', value, node?.id || node?._id || '');
                        }}
                        disabled={disableNode || disabled}
                        searchProps={{
                          type: !isPickupDeliveryType
                            ? locationSearchType
                            : getSeparatedLocationSearchType(locationType, locationSearchType),
                        }}
                      />
                    </GenAIInputWrapper>
                  </Form.Item>
                  {nodeLabel === ROUTING_NODE_LABEL_DESTINATION && showDpdCheckbox && (
                    <Form.Item noStyle shouldUpdate>
                      {({ getFieldValue }) => {
                        const routing_legs = form.getFieldValue('routing_details')?.routing_legs;
                        const hasOnCarriageType = routing_legs?.some(
                          (leg: any) => leg.routing_type === 'on_carriage'
                        );
                        return (
                          <Form.Item name={'direct_port_delivery'} valuePropName="checked">
                            <Checkbox name="direct_port_delivery" disabled={hasOnCarriageType}>
                              {'Direct Port Delivery'}
                            </Checkbox>
                          </Form.Item>
                        );
                      }}
                    </Form.Item>
                  )}
                  {errorsPresent && !node?.location ? (
                    <Text type="danger">Required</Text>
                  ) : (
                    <span />
                  )}
                </>
              );
            }}
          </Form.Item>
        </>
      )
    : cols.push(
        <>
          <Form.Item
            required
            validateStatus={errorsPresent && !node?.location ? 'error' : 'success'}
            label={'Address'}
          >
            <AddressCompanySearch
              value={{
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                party_company: node?.company || undefined,
                party_address: node?.address,
              }}
              onChange={(value: any) => {
                onRoutingNodeFieldChange('company_address', value, node?.id || node?._id || '');
              }}
              addressSearchProps={{ showAddBranchAction: true }}
              // searchProps={{ type: locationSearchType }}
            />
            {errorsPresent && !node?.address ? <Text type="danger">Required</Text> : <span />}
          </Form.Item>
        </>
      );
  showTerminal &&
    locationType !== 'address' &&
    (!incoming_edge || !outgoing_edge) &&
    cols.push(
      <>
        <Form.Item label="Terminal">
          <GenAIInputWrapper
            fieldKey={['terminal', 'name']}
            value={node?.terminal}
            arrayValueConfig={{
              path_to_array: ['routing_nodes'],
              findConfig: {
                key: '_id',
                value: node?._id,
              },
            }}
          >
            <GlobalSearch
              doc_type="Global::Location"
              value={node?.terminal}
              searchProps={{ type: ['PortTerminal'], country_code: [node?.location?.country_code] }}
              disabled={disableNode || disabled}
              onChange={(value: any) =>
                onRoutingNodeFieldChange('terminal', value, node?.id || node?._id || '')
              }
            />
          </GenAIInputWrapper>
        </Form.Item>
      </>
    );
  showEta &&
    cols.push(
      <>
        <Form.Item label="ETA">
          <GenAIInputWrapper
            fieldKey={['estimated_time_of_arrival']}
            arrayValueConfig={{
              path_to_array: ['routing_legs'],
              findConfig: {
                key: 'routing_type',
                value: routing_type,
              },
            }}
            value={incoming_edge?.estimated_time_of_arrival}
          >
            <DatePicker
              showTime
              value={incoming_edge?.estimated_time_of_arrival}
              onChange={(value: any) =>
                onRoutingLegFieldChange(
                  'estimated_time_of_arrival',
                  value,
                  outgoing_edge ? index - 1 : index
                )
              }
              style={{ width: '100%' }}
              disabled={disabled || disableVoyageUpdate}
            />
          </GenAIInputWrapper>
        </Form.Item>
      </>
    );
  showEtd &&
    cols.push(
      <>
        <Form.Item
          label="ETD"
          required={isEtdRequired}
          validateStatus={
            errorsPresent && isEtdRequired && !outgoing_edge?.estimated_time_of_departure
              ? 'error'
              : 'success'
          }
          shouldUpdate={(prevValues, currentValues) =>
            prevValues.voyage_schedule_id !== currentValues.voyage_schedule_id
          }
        >
          {({ getFieldValue }) => {
            return (
              <React.Fragment>
                <GenAIInputWrapper
                  fieldKey={['estimated_time_of_departure']}
                  arrayValueConfig={{
                    path_to_array: ['routing_legs'],
                    findConfig: {
                      key: 'routing_type',
                      value: routing_type,
                    },
                  }}
                  value={incoming_edge?.estimated_time_of_departure}
                >
                  <DatePicker
                    showTime
                    value={outgoing_edge?.estimated_time_of_departure}
                    onChange={(value: any) =>
                      onRoutingLegFieldChange('estimated_time_of_departure', value, index)
                    }
                    style={{ width: '100%' }}
                    disabled={
                      Boolean(getFieldValue('voyage_schedule_id') && isFirstMainCarriageHop) ||
                      disabled ||
                      disableVoyageUpdate
                    }
                  />
                </GenAIInputWrapper>
                {errorsPresent && isEtdRequired && !outgoing_edge?.estimated_time_of_departure ? (
                  <Text type="danger">Required</Text>
                ) : (
                  <span />
                )}
              </React.Fragment>
            );
          }}
        </Form.Item>
      </>
    );

  showWaitingTime &&
    cols.push(
      <Form.Item label="Waiting Time">
        <InputNumber
          min={0}
          step={1}
          style={{ width: '100%' }}
          addonAfter={waitingTimeUnit(node?.waiting_time_unit || '', handleWaitingTimeChange)}
          value={node?.waiting_time}
          onChange={(value) => {
            onRoutingNodeFieldChange('waiting_time', value, node?.id || node?._id || '');
          }}
        />
      </Form.Item>
    );

  const COL_SPAN = viewType === VIEW_TYPE_SM ? 8 : Math.min(6, 24 / cols.length);

  return (
    <Row gutter={16}>
      {cols.length &&
        cols.map((col, ind) => {
          return (
            <Col key={ind} span={COL_SPAN}>
              {col}
            </Col>
          );
        })}
    </Row>
  );
});

export default NodeAndLegsInfo;
