/* eslint-disable @typescript-eslint/no-empty-interface */
import { types, Instance, SnapshotIn, SnapshotOut } from 'mobx-state-tree';

const BusinessVertical = types.model({
  id: types.identifier,
  name: types.string,
  code: types.string,
  is_disabled: types.boolean,
  fields: types.map(types.frozen()),
});

export interface BusinessVerticalValue extends Instance<typeof BusinessVertical> {}
export interface BusinessVerticalIn extends SnapshotIn<typeof BusinessVertical> {}
export interface BusinessVerticalOut extends SnapshotOut<typeof BusinessVertical> {}

export default BusinessVertical;
