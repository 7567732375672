import React, { useState, useEffect, useCallback } from 'react';
import { useQuery, useMutation, ApolloClient } from '@apollo/client';
import {
  Form,
  Result,
  Button,
  Input,
  message,
  UploadedDocumentType,
  AccountingDocumentType,
  AttachmentType,
  SendNotificationsForm,
  CompanyRoleObject,
  dayjs,
  PrintPreviewDocType,
  SendEmailProvider,
  getAttachmentPayload,
  uploadAccountingDocuments,
  ComposeTab,
} from '@shipmnts/pixel-hub';
import { FormInstance } from '@shipmnts/pixel-hub';
import { SyncOutlined } from '@shipmnts/pixel-hub';
import { startCase as _startCase } from 'lodash';
import DocumentDispatchDetails, { DOCUMENT_DISPATCH_OPTIONS } from './DocumentDispatchDetails';
import { ShipmentPartyValue } from 'operations/models/ShipmentParty';
import { ShipmentDocumentValue } from 'operations/models/ShipmentDocument';
import { ShipmentValue } from 'operations/models/Shipment';
import { SessionDataValue } from 'operations/models/SessionData';
import { CompanyValue } from 'operations/models/Company';
import { SHIPMENT_FOR_PRE_ALERT } from 'operations/graphql/shipment';
import {
  CREATE_SHIPMENT_MESSAGE,
  UPDATE_HOUSE_SHIPMENT_FIELDS,
  UPDATE_SHIPMENT_FIELDS,
} from '../DetailLayout/Containers/graphql';
import {
  SHIPMENT_TYPE_HOUSE,
  MESSAGE_TYPE_OPS_PRE_ALERT,
  MESSAGE_TYPE_DOCUMENT_DISPATCH,
} from '../../constants';
import { useSession, decimalPointFormater, errorMessageHandlerGraphQL } from 'common';
import Spinner from 'src/components/Spinner';
import { CreatedDocument, EmailTemplate } from 'common/commonTypeDefs';
import { PartyValue } from 'operations/modules/reports/components/CreateShipmentFormContent';
const SendDocumentBasicDetails = React.lazy(() => import('./SendDocumentBasicDetails'));
const AccountingDocumentsTable = React.lazy(() => import('./AccountingDocumentsTable'));
const UploadedDocumentsTable = React.lazy(() => import('./UploadedDocumentsTable'));
const GeneratedDocumentsTable = React.lazy(() => import('./GeneratedDocumentsTable'));

const { TextArea } = Input;

export interface CreatedDocumentWithPdf extends CreatedDocument {
  attachment: UploadedDocumentType;
}

interface SendDocumentFormProps {
  shipment_id: string;
  job_number?: string;
  shipment_type?: string;
  docgen_url: string;
  network_url: string;
  onClose: () => void;
  onSuccess?: () => void;
  templates?: EmailTemplate[];
  client: ApolloClient<object>;
  primaryActionName?: string;
  emailTemplateActionName: string;
  emailModalTitle?: string;
  defaultShipmentPartyType: string;
  showDispatchDetails?: boolean;
}

interface DocumentCustomContentType {
  id?: string;
  document_name: string;
  reference_name: string;
  document_type: string;
  document_tag: string;
}

interface SendDocumentFormDataType {
  document_dispatch_date: string;
  mode_of_sharing?: string;
  receiver_company_id?: string;
  remarks?: string;
  document_dispatch_mode?: string;
  courier_company?: CompanyValue;
  courier_tracking_no?: string;
}

const getCompaniesRolesMapping = (
  form: FormInstance,
  sessionData: SessionDataValue,
  shipment: ShipmentValue,
  shipmentParties: ShipmentPartyValue[]
): CompanyRoleObject[] => {
  const default_company = sessionData?.company_account?.default_company;
  const parties = (shipmentParties || []).filter(
    (sp: ShipmentPartyValue) => sp.party_company?.id && sp.party_company?.id !== default_company?.id
  );
  return [
    {
      id: default_company?.id || '',
      registered_name: default_company?.registered_name || '',
      address_id: shipment?.involved_branch?.default_address?.id,
      role: 'Your Company',
    },
  ].concat(
    parties.map((party) => ({
      id: party?.party_company?.id || '',
      registered_name: party?.party_company?.registered_name || '',
      address_id: party?.party_address?.id,
      role: _startCase(party?.name || ''),
    }))
  );
};

const getShipmentDocument = (
  shipment_document_id: string,
  allShipments: ShipmentValue[]
): ShipmentDocumentValue | undefined => {
  let shipment_document: any = null;
  (allShipments || []).forEach((sh: any) => {
    (sh?.shipment_documents || []).forEach((sd: any) => {
      if (sd.id === shipment_document_id) {
        shipment_document = sd;
      }
    });
  });

  return shipment_document;
};

const SendDocumentForm = React.memo(function SendDocumentForm(
  props: SendDocumentFormProps
): JSX.Element {
  const {
    shipment_id,
    job_number,
    docgen_url,
    templates,
    onClose,
    onSuccess,
    client,
    emailTemplateActionName,
    emailModalTitle,
    primaryActionName,
    defaultShipmentPartyType,
    showDispatchDetails,
    shipment_type,
  } = props;
  const sessionData = useSession();
  const {
    loading: fetchingShipment,
    error: fetchShipmentError,
    data: shipment,
    refetch: refetchShipment,
  } = useQuery(SHIPMENT_FOR_PRE_ALERT, {
    variables: { id: shipment_id },
  });
  const [createShipmentMessage, { data, loading, error }] = useMutation(CREATE_SHIPMENT_MESSAGE);
  const [
    updateShipmentDispatchFields,
    { data: updateDispatchResponse, loading: updatingDispatchFields, error: updateDispatchError },
  ] = useMutation(
    shipment_type === SHIPMENT_TYPE_HOUSE ? UPDATE_HOUSE_SHIPMENT_FIELDS : UPDATE_SHIPMENT_FIELDS
  );

  const [selectedPartyName, setSelectedpartyname] = useState();
  const [shareDocumentVisible, setShareDocumentVisible] = useState<boolean>(true);

  useEffect(() => {
    const successCallback = (msg: string) => {
      message.success(msg);
      onClose();
      if (onSuccess) onSuccess();
    };
    const responseField =
      shipment_type === SHIPMENT_TYPE_HOUSE ? 'update_house_shipment' : 'update_shipment';
    const isDispatchSuccess = !updateDispatchError && updateDispatchResponse?.[responseField]?.id;
    const isShareShipmentSuccess = !error && data?.create_shipment_message?.id;
    if (isDispatchSuccess && !shareDocumentVisible) {
      successCallback('Shipment dispatched successfully !');
    } else if (isShareShipmentSuccess && !showDispatchDetails) {
      successCallback('Shipment shared successfully !');
    } else if (isDispatchSuccess && isShareShipmentSuccess) {
      successCallback('Shipment dispatched and shared successfully !');
    }
  }, [
    data,
    error,
    shipment_type,
    updateDispatchResponse,
    shareDocumentVisible,
    updateDispatchError,
    showDispatchDetails,
    onClose,
    onSuccess,
  ]);

  const [fetchingGeneratedDocuments, setFetchingGeneratedDocuments] = useState<boolean>(true);
  const [uploadingAccountingDocuments, setUploadingAccountingDocuments] = useState<boolean>(false);
  const [generatedDocuments, setGeneratedDocuments] = useState<CreatedDocumentWithPdf[]>();
  const [fetchingUploadedDocuments, setFetchingUploadedDocuments] = useState<boolean>(true);
  const [uploadedDocuments, setUploadedDocuments] = useState<UploadedDocumentType[]>();
  const [fetchingAccountingDocuments, setFetchingAccountingDocuments] = useState<boolean>(true);
  const [accountingDocuments, setAccountingDocuments] = useState<AccountingDocumentType[]>([]);

  const [selectedUploadedDocuments, setSelectedUploadedDocuments] = useState<Array<React.Key>>([]);
  const [selectedGeneratedDocuments, setSelectedGeneratedDocuments] = useState<Array<React.Key>>(
    []
  );
  const [selectedAccountingDocuments, setSelectedAccountingDocuments] = useState<Array<React.Key>>(
    []
  );
  const [showPrintWindow, setShowPrintWindow] = useState<boolean>(false);
  const [accountingUploadedDocuments, setAccountingUploadedDocuments] = useState<
    UploadedDocumentType[]
  >([]);

  const [sendEmailDrawerVisible, setSendEmailDrawerVisible] = useState<boolean>(false);

  const [customContent, setCustomContent] = useState<Record<string, any>>();

  const [form] = Form.useForm();

  const onCloseSendEmail = useCallback(() => {
    setSendEmailDrawerVisible(false);
    if (showDispatchDetails) {
      onClose();
    }
  }, [showDispatchDetails, onClose]);
  const onSuccessSendEmail = useCallback(() => {
    onCloseSendEmail();
    onClose();
    if (onSuccess) onSuccess();
  }, [onCloseSendEmail, onClose, onSuccess]);

  const onPartyChanged = useCallback((partyName: any) => {
    setSelectedpartyname(partyName);
  }, []);

  const onShareDocumentVisibleChange = useCallback((val: boolean) => {
    setShareDocumentVisible(val);
  }, []);
  const getCustomContentForEmail = useCallback(
    (values: SendDocumentFormDataType) => {
      const allShipments = [shipment?.shipment, ...(shipment?.shipment?.house_shipments || [])];
      const selectedInvoices = (accountingDocuments || [])
        ?.filter((doc) => (selectedAccountingDocuments || []).includes(doc.name))
        .map((doc) => {
          return {
            ...doc,
            voucher_amount: `${doc.voucher_currency} ${decimalPointFormater(
              String(doc.voucher_total)
            )}`,
          };
        });
      const selectedGeneratedDocumentsContent = (generatedDocuments || [])
        .filter((doc: any) => (selectedGeneratedDocuments || []).includes(doc.id))
        .map((doc) => {
          let new_doc = {
            document_name: doc.document_name,
            document_tag: '',
            document_type: '',
            reference_name: '',
          };
          if (doc.shipment_document_id) {
            const sd = getShipmentDocument(doc.shipment_document_id, allShipments);
            new_doc = {
              ...new_doc,
              document_type: sd?.bl_type || '',
              reference_name: sd?.shipment_number || '',
            };
          }
          return new_doc;
        }) as DocumentCustomContentType[];
      const selectedUploadedDocumentsContent = (uploadedDocuments || [])
        ?.filter((doc: any) => (selectedUploadedDocuments || []).includes(doc.id))
        .map((doc) => {
          return {
            document_name: doc.file_name,
            document_tag: (doc?.tags || []).join(', '),
            document_type: '',
            reference_name: '',
          };
        }) as DocumentCustomContentType[];
      const selectedDocuments = selectedGeneratedDocumentsContent.concat(
        selectedUploadedDocumentsContent
      );

      let sendToParty = shipment?.shipment?.shipment_parties?.find(
        (party: PartyValue) => party?.party_company?.id === values?.receiver_company_id
      );

      if (
        !sendToParty &&
        shipment?.shipment?.customer_company?.id === values?.receiver_company_id
      ) {
        sendToParty = {
          party_company: shipment?.shipment?.customer_company,
          party_address: shipment?.shipment?.customer_address,
        };
      }

      return {
        invoices: selectedInvoices.length ? selectedInvoices : null,
        documents: selectedDocuments.length ? selectedDocuments : null,
        document_dispatch_mode: values?.document_dispatch_mode,
        courier_company_name: values?.courier_company?.registered_name,
        courier_tracking_no: values?.courier_tracking_no,
        remarks: values?.remarks || '',
        dispatch_date: values?.document_dispatch_date || '',
        send_to_company_name: sendToParty?.party_company?.registered_name,
        send_to_company_address: sendToParty?.party_address?.print_address,
      };
    },
    [
      selectedAccountingDocuments,
      accountingDocuments,
      generatedDocuments,
      uploadedDocuments,
      selectedGeneratedDocuments,
      selectedUploadedDocuments,
      shipment,
    ]
  );

  if (fetchShipmentError) {
    return (
      <Result
        status="error"
        title="Something went wrong."
        subTitle={`${
          fetchShipmentError ? 'Cannot fetch shipment' : 'Cannot fetch documents'
        }. Please try again!`}
        extra={[
          <Button
            icon={<SyncOutlined style={{ color: 'rgb(0, 142, 255)' }} />}
            loading={fetchingShipment}
            onClick={() => refetchShipment()}
            size="small"
            key="retry"
          >
            Retry
          </Button>,
        ]}
      />
    );
  }

  if (shipment?.shipment && !fetchingShipment) {
    const shipmentParties = (shipment?.shipment?.shipment_parties || []).concat(
      shipment?.shipment?.customer_company
        ? [
            {
              name: 'customer',
              party_company: shipment?.shipment?.customer_company,
              party_address: {
                id: shipment?.shipment?.customer_address_id,
              },
            },
          ]
        : []
    );
    const receiver_company = shipmentParties.find(
      (sp: ShipmentPartyValue) => sp.name === defaultShipmentPartyType
    )?.party_company;

    return (
      <>
        <Form
          name="pre-alert-form"
          layout="vertical"
          form={form}
          initialValues={{
            receiver_company_id: receiver_company?.id,
            mode_of_sharing: Boolean(receiver_company?.global_company_account?.id)
              ? 'on_platform'
              : 'email',
            document_dispatch_mode: DOCUMENT_DISPATCH_OPTIONS[0].value,
            document_dispatch_date: dayjs(),
          }}
          onFinish={async (values) => {
            if (showDispatchDetails) {
              const {
                document_dispatch_mode,
                document_dispatch_date,
                courier_company,
                courier_tracking_no,
              } = values;
              updateShipmentDispatchFields({
                variables: {
                  id: shipment_id,
                  shipment: {
                    document_dispatch_mode,
                    document_dispatch_date: dayjs(document_dispatch_date).unix(),
                    courier_company_id: courier_company?.id || null,
                    courier_tracking_no: courier_tracking_no || null,
                  },
                },
              });
            }
            if (shareDocumentVisible) {
              let accountingDocsAttachments: AttachmentType[] = [];
              if (selectedAccountingDocuments?.length) {
                setUploadingAccountingDocuments(true);
                const selectedAccountingDocsAttachments = (accountingDocuments || [])?.filter(
                  (doc) => selectedAccountingDocuments.includes(doc.name)
                );
                const accountingUploadedDocuments = await uploadAccountingDocuments(
                  selectedAccountingDocsAttachments,
                  shipment_id,
                  sessionData,
                  docgen_url
                );
                if (accountingUploadedDocuments.documents) {
                  accountingDocsAttachments = accountingUploadedDocuments.documents?.map((doc) =>
                    getAttachmentPayload(doc, shipment_id)
                  );
                  setAccountingUploadedDocuments(accountingUploadedDocuments.documents);
                }
                if (accountingUploadedDocuments.error) {
                  message.error('something went wrong while uploading documents');
                  setUploadingAccountingDocuments(false);
                  return;
                }
                setUploadingAccountingDocuments(false);
              }
              if (values.mode_of_sharing === 'physical') {
                const content = getCustomContentForEmail(values);
                setCustomContent(content);
                setShowPrintWindow(true);
              } else if (values.mode_of_sharing === 'email') {
                const content = getCustomContentForEmail(values);
                setCustomContent(content);
                setSendEmailDrawerVisible(true);
              } else {
                const { receiver_company_id, remarks } = values;
                const uploadedAttachments = (uploadedDocuments || [])
                  ?.filter((doc) => selectedUploadedDocuments.includes(doc.id))
                  .map((doc) => getAttachmentPayload(doc));

                const createdDocsAttachments = (generatedDocuments || [])
                  ?.filter((doc) => selectedGeneratedDocuments.includes(doc.id))
                  ?.map((doc) => getAttachmentPayload(doc.attachment, doc.shipment_id));

                const receiver_company_account_id = (shipmentParties || []).find(
                  (sp: ShipmentPartyValue) => sp?.party_company?.id === receiver_company_id
                )?.party_company?.global_company_account?.id;

                const variables = {
                  receiver_company_account_id,
                  shipment_id,
                  remarks,
                  message_type: showDispatchDetails
                    ? MESSAGE_TYPE_DOCUMENT_DISPATCH
                    : MESSAGE_TYPE_OPS_PRE_ALERT,
                  attachments: [
                    ...uploadedAttachments,
                    ...createdDocsAttachments,
                    ...accountingDocsAttachments,
                  ],
                };
                createShipmentMessage({ variables });
              }
            }
          }}
          onValuesChange={(changedValues, allValues) => {
            if (
              changedValues.hasOwnProperty('receiver_company_id') &&
              allValues?.mode_of_sharing === 'on_platform'
            ) {
              const party = (shipmentParties || []).find(
                (sp: ShipmentPartyValue) =>
                  sp?.party_company?.id === changedValues?.receiver_company_id
              );
              if (party && !Boolean(party?.party_company?.global_company_account?.id)) {
                form.setFieldsValue({ mode_of_sharing: 'email' });
              }
            }
          }}
        >
          {error && errorMessageHandlerGraphQL(error)}
          {updateDispatchError && errorMessageHandlerGraphQL(updateDispatchError)}

          {showDispatchDetails && (
            <DocumentDispatchDetails
              shareDocumentVisible={shareDocumentVisible}
              onShareDocumentVisibleChange={onShareDocumentVisibleChange}
            />
          )}
          {shareDocumentVisible && (
            <>
              <SendDocumentBasicDetails
                onPartyChanged={onPartyChanged}
                shipmentParties={shipmentParties}
              />

              <UploadedDocumentsTable
                fetchingUploadedDocuments={fetchingUploadedDocuments}
                setFetchingUploadedDocuments={setFetchingUploadedDocuments}
                documents={uploadedDocuments}
                selectedUploadedDocuments={selectedUploadedDocuments}
                setSelectedUploadedDocuments={setSelectedUploadedDocuments}
                shipment={shipment?.shipment}
                docgen_url={docgen_url}
                sessionData={sessionData}
                setUploadedDocuments={setUploadedDocuments}
              />

              <div style={{ marginTop: '12px' }}>
                <GeneratedDocumentsTable
                  fetchingGeneratedDocuments={fetchingGeneratedDocuments}
                  setFetchingGeneratedDocuments={setFetchingGeneratedDocuments}
                  documents={generatedDocuments}
                  shipment={shipment?.shipment}
                  selectedGeneratedDocuments={selectedGeneratedDocuments}
                  setSelectedGeneratedDocuments={setSelectedGeneratedDocuments}
                  sessionData={sessionData}
                  setGeneratedDocuments={setGeneratedDocuments}
                  client={client}
                />
              </div>
              <div style={{ marginTop: '12px' }}>
                <AccountingDocumentsTable
                  fetchingAccountingDocuments={fetchingAccountingDocuments}
                  setFetchingAccountingDocuments={setFetchingAccountingDocuments}
                  documents={accountingDocuments}
                  selectedAccountingDocuments={selectedAccountingDocuments}
                  setSelectedAccountingDocuments={setSelectedAccountingDocuments}
                  shipment={shipment?.shipment}
                  docgen_url={docgen_url}
                  sessionData={sessionData}
                  setAccountingDocuments={setAccountingDocuments}
                  selectedPartyName={selectedPartyName}
                  defaultParty={receiver_company}
                />
              </div>
              <Form.Item
                style={{ marginTop: '12px', marginBottom: '44px' }}
                name="remarks"
                label="Remarks"
              >
                <TextArea rows={3} />
              </Form.Item>
            </>
          )}

          <div
            className="drawer-footer"
            style={{
              position: 'absolute',
              bottom: '21px',
              width: '98%',
              borderTop: '1px solid #e8e8e8',
              textAlign: 'right',
              background: '#fff',
              padding: '7px',
              margin: '-22px',
            }}
          >
            <Button
              disabled={
                loading ||
                fetchingGeneratedDocuments ||
                fetchingUploadedDocuments ||
                updatingDispatchFields
              }
              onClick={onClose}
            >
              Cancel
            </Button>
            <Button
              type="primary"
              disabled={
                fetchingGeneratedDocuments ||
                fetchingUploadedDocuments ||
                fetchingAccountingDocuments
              }
              loading={loading || uploadingAccountingDocuments || updatingDispatchFields}
              style={{ marginLeft: '16px' }}
              htmlType={'submit'}
              key="save"
            >
              {primaryActionName || 'Send'}
            </Button>
          </div>
        </Form>
        {sendEmailDrawerVisible && (
          <ComposeTab
            title={
              emailModalTitle || `Send email for JOB # ${job_number ? job_number : shipment_id}`
            }
            autoOpen={sendEmailDrawerVisible && !updatingDispatchFields && !updateDispatchError}
            onClose={onCloseSendEmail}
          >
            <SendEmailProvider
              templates={templates}
              onClose={onCloseSendEmail}
              onSuccess={onSuccessSendEmail}
              resource_ids={[shipment?.shipment?.id]}
              action_name={emailTemplateActionName}
              docTypeId="Shipment::Shipment"
              initialAttachments={[
                ...(
                  uploadedDocuments?.filter((doc) => selectedUploadedDocuments.includes(doc.id)) ||
                  []
                ).map((doc) => {
                  return { ...doc, document_type: 'Supporting Documents' };
                }),
                ...(generatedDocuments || [])
                  ?.filter((doc) => selectedGeneratedDocuments.includes(doc.id))
                  ?.map((doc) => {
                    return {
                      ...doc.attachment,
                      document_type: 'Supporting Documents',
                    };
                  }),
                ...accountingUploadedDocuments?.map((doc) => {
                  return {
                    ...doc,
                    document_type: doc?.tags?.includes('Debit Note Agent')
                      ? 'Sales Invoice'
                      : 'Purchase Invoice',
                  };
                }),
              ]}
              companies_roles_mapping={getCompaniesRolesMapping(
                form,
                sessionData,
                shipment?.shipment,
                shipmentParties
              )}
              sessionData={sessionData}
              customContent={JSON.stringify(customContent)}
            >
              <SendNotificationsForm docgen_url={docgen_url} />
            </SendEmailProvider>
          </ComposeTab>
        )}

        {showPrintWindow && (
          <PrintPreviewDocType
            docType={'Shipment::Shipment'}
            resource_id={shipment_id}
            onClose={() => setShowPrintWindow(false)}
            context={customContent}
            // template_subtype={'document_dispatch'}
          />
        )}
      </>
    );
  }

  return <Spinner style={{ width: '100%' }} tip="Loading ..." />;
});

export default SendDocumentForm;
