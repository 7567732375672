import React, { useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { Modal, message } from '@shipmnts/pixel-hub';
import { WAREHOUSE_TRANSACTION_ACTIONS } from '../graphql/warehouseTransaction';
import { WarehouseTransactionValue } from '../models/WarehouseTransaction';

interface SubmitTransactionModalProp {
  transaction: WarehouseTransactionValue;
  onCancel?: () => void;
  onSuccess?: () => void;
  onClose?: () => void;
}

const SubmitTransactionModal = React.memo(function SubmitTransactionModal(
  props: SubmitTransactionModalProp
) {
  const { transaction, onSuccess, onClose } = props;

  const [submitTransaction, { data, loading, error }] = useMutation(WAREHOUSE_TRANSACTION_ACTIONS);

  useEffect(() => {
    Modal.confirm({
      title: 'Confirm',
      content: `Are you sure you want to Submit Transaction?`,
      okText: 'Ok',
      okType: 'danger',
      onOk: () => {
        submitTransaction({
          variables: {
            id: transaction?.id,
            action: 'submit',
          },
        });
      },
      cancelText: 'Cancel',
      onCancel: () => {
        if (onClose) onClose();
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submitTransaction]);

  useEffect(() => {
    if (loading) {
      return;
    }
    if (error) {
      message.error(error.message);
      onClose && onClose();
      return;
    }
    if (data?.warehouse_transaction_actions) {
      message.success('Transaction Submitted Successfully');
      if (onSuccess) onSuccess();
    }
  }, [loading, error, data, onSuccess, onClose]);

  if (loading) {
    return <div>loading...</div>;
  }

  return <></>;
});

export default SubmitTransactionModal;
