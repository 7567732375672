import React, { useContext } from 'react';

export const VIEW_TYPE_SM = 'sm';
export const VIEW_TYPE_XS = 'xs';
export const VIEW_TYPE_MD = 'md';

export const VIEW_TYPE_SPAN = {
  [VIEW_TYPE_XS]: 24,
  [VIEW_TYPE_SM]: 12,
  [VIEW_TYPE_MD]: 6,
};

export const getViewTypeFromWidth = (width: number) => {
  if (width < 600) {
    return VIEW_TYPE_XS;
  }
  if (width < 960) {
    return VIEW_TYPE_SM;
  }
  return VIEW_TYPE_MD;
};

interface FormResponsiveContextType {
  setViewType?: (viewType: any) => void;
  viewType: 'sm' | 'xs' | 'md' | undefined;
}

export const ResponsiveFieldContext = React.createContext<FormResponsiveContextType>({
  setViewType: () => void 0,
  viewType: undefined,
});

export const useFormResponsive = () => useContext(ResponsiveFieldContext);
