/* eslint-disable camelcase */
/* eslint-disable no-duplicate-imports */
import React, { lazy, useCallback, useEffect, useMemo, useState } from 'react';

import { Button, Col, ExclamationCircleFilled, Flex, Row, Typography } from '@shipmnts/pixel-hub';
import {
  getQueryParamsAndRemove,
  makeColumnForTab,
  useEmailDrawerContext,
  useSession,
} from 'common';
import { BaseTable, getDateFromUnix } from '@shipmnts/pixel-hub';
import { hasPermission } from '@shipmnts/pixel-hub';
import { cloneDeep } from 'lodash';

import {
  InquiryValue,
  INQUIRY_CANCELED_STATUS,
  INQUIRY_WON_STATUS,
} from 'sales_hub/models/inquiry';
import { QuotationValue } from 'sales_hub/models/quotation';
import {
  INQUIRY_OPTIONS_COLOR_HASH,
  INQUIRY_OPTIONS_DISPLAY_HASH,
  PERMISSION_BUY_ESTIMATE,
  PERMISSION_SELL_ESTIMATE,
} from 'sales_hub/utils/constants';

import { Column } from '../../models/Report';
import { ActionRendererDetailReport } from 'operations';
import { gql, useQuery } from '@apollo/client';
import { INQUIRY_LOST_STATUS } from 'operations/models/Inquiry';
const InquiryOptionDrawer = lazy(() => import('./InquiryOptionDrawer'));
const InquiryOptionStatusRenderer = lazy(() => import('./InquiryOptionStatusRenderer'));
const RateExplorer = lazy(() => import('../Inquiry/RateExplorer/RateExplorer'));
const ShareInquiry = lazy(() => import('../Inquiry/ShareInquiry'));

const GET_CUSTOM_FIELDS_FROM_DOC_TYPE = gql`
  query get_custom_fields_from_doc_type($doc_type_id: String!) {
    get_custom_fields_from_doc_type(doc_type_id: $doc_type_id) {
      id
      db_column_name
      filterable
      width
      field_type
      label
      filter_options
      cell_renderer
      cell_editor
      type
    }
  }
`;
interface inquiryOptionTableProps {
  inquiry: InquiryValue;
  rowSelection?: 'multiple' | 'single';
  refetchInquiry?: () => void;
  rowSelectionChange?: (ele: any) => void;
  inquiryOptionIds?: string[];
  removeColumns?: string[];
  disableResource?: boolean;
  showCreateBtnLost?: boolean;
}
export interface InquiryOptionTableType {
  key: React.Key | undefined;
  quotation_number: QuotationValue;
  carrier?: string | null;
  estimated_time_of_arrival?: string | null;
  estimated_time_of_departure?: string | null;
  freight_sell_unit_rate?: number | null;
  freight_buy_unit_rate?: number | null;
  freight_margin?: number | null;
  total_sell?: number | null;
  total_buy?: number | null;
  margin?: number | null;
  vendor_rate_agreement_number?: string | null;
  valid_till_date?: string | null;
  transit_days?: number | null;
}

function InquiryOptionTable(props: inquiryOptionTableProps) {
  const {
    inquiry,
    refetchInquiry,
    rowSelection,
    rowSelectionChange,
    inquiryOptionIds,
    removeColumns = [],
    disableResource = true,
    showCreateBtnLost = true,
  } = props;
  const sessionData = useSession();

  const { freight_type } = inquiry;
  const { setInboundEmailModalProps } = useEmailDrawerContext();
  const [showRateExplorer, setShowRateExplorer] = useState(false);
  const [showInquiryOptionDrawer, setShowInquiryOptionDrawer] = useState(false);
  const [showShareQuoteModal, setShowShareQuoteModal] = useState(false);
  const [gridKey, setGridKey] = useState('');
  const { data } = useQuery(GET_CUSTOM_FIELDS_FROM_DOC_TYPE, {
    variables: { doc_type_id: 'SalesHub::InquiryOption' },
  });
  const [dataSource, setDataSource] = useState<QuotationValue[]>([]);

  const inquiryOptions = useMemo(() => {
    return !!inquiryOptionIds && inquiryOptionIds.length > 0
      ? cloneDeep(
          inquiry?.inquiry_options?.filter((ele: QuotationValue) =>
            inquiryOptionIds.includes(ele.id)
          )
        )
      : cloneDeep(inquiry?.inquiry_options);
  }, [inquiryOptionIds, inquiry]);

  const isWonLostOrCanceled = useMemo(() => {
    return [INQUIRY_WON_STATUS, INQUIRY_LOST_STATUS, INQUIRY_CANCELED_STATUS].includes(
      inquiry.last_action_status || ''
    );
  }, [inquiry.last_action_status]);

  const isLostOrCanceled = useMemo(() => {
    return [INQUIRY_LOST_STATUS, INQUIRY_CANCELED_STATUS].includes(
      inquiry.last_action_status || ''
    );
  }, [inquiry.last_action_status]);

  const getDataSource = useCallback(
    (inquiryOptions: Array<QuotationValue> | undefined | null) => {
      return (inquiryOptions || [])
        .sort((a, b) => {
          if (!a?.quotation_number || !b?.quotation_number) return 0;
          if (a.quotation_number < b.quotation_number) return 1;
          else return -1;
        })
        .map((inquiryOption) => {
          const totalFreightSell = inquiryOption?.freight_sell_unit_rate || 0;
          const totalFreightBuy = inquiryOption?.freight_buy_unit_rate || 0;
          const baseTotalBuy = inquiryOption?.total_buy || 0;
          const baseTotalSell = inquiryOption?.total_sell || 0;
          const totalBuy = baseTotalBuy / (inquiryOption?.exchange_rate || 1);
          const totalSell = baseTotalSell / (inquiryOption?.exchange_rate || 1);
          const customFieldString = inquiryOption?.custom_field_values || '{}';
          const customFieldValues = JSON.parse(customFieldString);

          return {
            ...inquiryOption,
            inquiry: inquiry,
            quote_link: {
              value: inquiryOption.quotation_number,
              extra_fields: { id: inquiryOption.id },
            },
            amend_from: {
              value: inquiryOption?.amend_from?.quotation_number,
              extra_fields: { id: inquiryOption?.amend_from?.id },
            },
            refetchData: refetchInquiry,
            key: inquiryOption?.id,
            currency: sessionData?.company_account?.default_currency,
            carrier: inquiryOption?.carrier?.name,
            estimated_time_of_arrival: inquiryOption?.estimated_time_of_arrival,
            estimated_time_of_departure: inquiryOption?.estimated_time_of_departure,
            freight_sell_unit_rate: totalFreightSell,
            freight_buy_unit_rate: totalFreightBuy,
            total_freight_margin: {
              extra_fields: {
                freight_sell_unit_cur: inquiryOption?.freight_sell_unit_cur,
                freight_buy_unit_cur: inquiryOption?.freight_buy_unit_cur,
                freight_buy_unit_rate: inquiryOption?.freight_buy_unit_rate,
                freight_sell_unit_rate: inquiryOption?.freight_sell_unit_rate,
              },
            },
            total_sell: totalSell,
            total_buy: totalBuy,
            total_margin: totalSell - totalBuy,
            base_total_buy: baseTotalBuy,
            base_total_sell: baseTotalSell,
            base_margin: baseTotalSell - baseTotalBuy,
            vendor_rate_agreement_number: inquiryOption?.vendor_rate_agreement_number,
            valid_till_date: inquiryOption?.valid_to
              ? getDateFromUnix(inquiryOption?.valid_to)
              : undefined,
            transit_days: inquiryOption?.transit_days ? inquiryOption?.transit_days : undefined,
            ...customFieldValues,
          };
        });
    },
    [inquiry, refetchInquiry, sessionData?.company_account?.default_currency]
  );

  useEffect(() => {
    setDataSource(() => getDataSource(inquiryOptions));
    setGridKey(`inquiry-options-${Date.now()}`);
  }, [inquiry, getDataSource, inquiryOptions]);

  const columnDefs: { [key: string]: Column } = useMemo(() => {
    return {
      quotation_number: {
        headerName: 'Quote #',
        field: 'quote_link',
        colId: 'quote_link',
        columnType: 'Link',
        width: 200,
        pinned: 'left',
        cellRendererParams: {
          id_field: 'id',
          doc_type_id: 'SalesHub::InquiryOption',
          onSuccess: refetchInquiry,
          disabledResource: disableResource,
        },
        keyCreator: (params: any) => params?.value?.value,
        filter: 'agSetColumnFilter',
      },
      carrier: {
        headerName: 'Carrier',
        field: 'carrier',
        colId: 'carrier',
        columnType: 'String',
        width: 120,
        cellRenderer: 'string_with_tool_tip',
      },
      estimated_time_of_departure: {
        headerName: 'ETD POL',
        field: 'estimated_time_of_departure',
        colId: 'estimated_time_of_departure',
        columnType: 'DateTime',
        width: 200,
        filter: false,
      },
      estimated_time_of_arrival: {
        headerName: 'ETA POD',
        field: 'estimated_time_of_arrival',
        colId: 'estimated_time_of_arrival',
        columnType: 'DateTime',
        width: 200,
        filter: false,
      },
      freight_sell_unit_rate: {
        headerName: 'Unit Freight Sell',
        field: 'freight_sell_unit_rate',
        colId: 'freight_sell_unit_rate',
        columnType: 'Currency',
        aggFunc: undefined,
        cellRendererParams: {
          currency_column: 'freight_sell_unit_cur',
        },
        width: 120,
      },
      freight_buy_unit_rate: {
        headerName: 'Unit Freight Buy',
        field: 'freight_buy_unit_rate',
        colId: 'freight_buy_unit_rate',
        columnType: 'Currency',
        aggFunc: undefined,
        cellRendererParams: {
          currency_column: 'freight_buy_unit_cur',
        },
        width: 120,
      },
      freight_margin: {
        headerName: 'Total Freight Margin',
        field: 'total_freight_margin',
        colId: 'total_freight_margin',
        cellRenderer: 'MarginRender',
        cellRendererParams: {
          sell_currency_column: 'freight_sell_unit_cur',
          buy_currency_column: 'freight_buy_unit_cur',
          buy_amount_column: 'freight_buy_unit_rate',
          sell_amount_column: 'freight_sell_unit_rate',
        },
        width: 120,
      },
      total_sell: {
        headerName: 'Total Sell(Cur)',
        field: 'total_sell',
        colId: 'total_sell',
        columnType: 'Currency',
        aggFunc: undefined,
        cellRendererParams: {
          currency_column: 'quote_currency',
        },
        width: 120,
      },
      total_buy: {
        headerName: 'Total Buy(Cur)',
        field: 'total_buy',
        colId: 'total_buy',
        columnType: 'Currency',
        aggFunc: undefined,
        cellRendererParams: {
          currency_column: 'quote_currency',
        },
        width: 120,
      },
      margin: {
        headerName: 'Total Margin(Cur)',
        field: 'total_margin',
        colId: 'total_margin',
        columnType: 'Currency',
        aggFunc: undefined,
        cellRendererParams: {
          currency_column: 'quote_currency',
        },
        width: 120,
      },
      base_total_sell: {
        headerName: 'Total Sell',
        hide: true,
        field: 'base_total_sell',
        colId: 'base_total_sell',
        columnType: 'Currency',
        aggFunc: undefined,
        width: 120,
      },
      base_total_buy: {
        headerName: 'Total Buy',
        field: 'base_total_buy',
        hide: true,
        colId: 'base_total_buy',
        columnType: 'Currency',
        aggFunc: undefined,
        width: 120,
      },
      base_margin: {
        headerName: 'Total Margin',
        hide: true,
        field: 'base_margin',
        colId: 'base_margin',
        columnType: 'Currency',
        aggFunc: undefined,
        width: 120,
      },
      vendor_rate_agreement_number: {
        headerName: 'RA #',
        field: 'vendor_rate_agreement_number',
        colId: 'vendor_rate_agreement_number',
        columnType: 'String',
        width: 120,
        cellRenderer: 'string_with_tool_tip',
      },
      valid_till_date: {
        headerName: 'Valid Till Date',
        field: 'valid_till_date',
        colId: 'valid_till_date',
        columnType: 'Date',
        width: 120,
        cellRenderer: 'string_with_tool_tip',
        filter: false,
      },
      transit_days: {
        headerName: 'Transit Time',
        field: 'transit_days',
        colId: 'transit_days',
        columnType: 'String',
        width: 120,
        cellRenderer: 'string_with_tool_tip',
      },
      amend_from: {
        headerName: 'Amended From',
        field: 'amend_from',
        colId: 'amend_from',
        columnType: 'Link',
        width: 120,
        cellRendererParams: {
          id_field: 'id',
          doc_type_id: 'SalesHub::InquiryOption',
        },
        filter: 'agSetColumnFilter',
      },
      status: {
        headerName: 'Status',
        field: 'status',
        colId: 'status',
        width: 120,
        pinned: 'right',
        cellRenderer: 'quoteStatusRender',
        cellRendererParams: {
          filter_options: {
            colors: INQUIRY_OPTIONS_COLOR_HASH,
            display_hash: INQUIRY_OPTIONS_DISPLAY_HASH,
          },
        },
      },
      actions: {
        headerName: 'Actions',
        lockPosition: 'right',
        suppressMovable: true,
        suppressNavigable: true,
        colId: 'actions',
        field: 'actions',
        valueGetter: 'data',
        cellRenderer: 'ActionRendererDetailReport',
        cellRendererParams: {
          doc_type_id: 'SalesHub::InquiryOption',
        },
        width: 100,
        pinned: 'right',
        headerComponent: 'StaticHeaderComponent',
      },
    };
  }, [disableResource, refetchInquiry]);

  const [allColumnsDefs, setAllColumnsDefs] = useState<{ [key: string]: Column }>(columnDefs);

  // if firstload, we open rate search by default
  useEffect(() => {
    const search_query = new URLSearchParams(window.location.search);
    // if user refreshes, we don't want to open rate search again
    if (search_query.get('search_rate')) {
      setShowRateExplorer(true);
      // will remove the search_rate query param in openInquiryOptionDrawer callback called onsuccess and onclose of rate explorer
    }
  }, []);

  useEffect(() => {
    if (data) {
      const resultObject: { [key: string]: Column } = data.get_custom_fields_from_doc_type.reduce(
        (new_field: any, field: any) => {
          new_field[field.db_column_name] = makeColumnForTab(field);
          return new_field;
        },
        {}
      );
      setAllColumnsDefs((prev) => ({ ...prev, ...resultObject }));
    }
  }, [data]);

  const openInquiryOptionDrawer = () => {
    const search_query = new URLSearchParams(window.location.search);
    if (search_query.get('first_load')) {
      getQueryParamsAndRemove('first_load');
      getQueryParamsAndRemove('search_rate');
      setInboundEmailModalProps({
        isOpen: true,
        email: `${inquiry.uuid}@inbound.shipmnts.com`,
        recordName: 'Inquiry',
      });
    }
  };

  const onRateExplorerSubmits = () => {
    refetchInquiry && refetchInquiry();
    openInquiryOptionDrawer();
    console.log('onRateExplorerSubmits');
  };

  const onRateExplorerClose = () => {
    setShowRateExplorer(false);
    openInquiryOptionDrawer();
    console.log('onRateExplorerClose');
  };

  const finalCols = useMemo(
    () =>
      Object.keys(allColumnsDefs).filter((key) => {
        if (
          freight_type === 'road' &&
          [
            'transit_days',
            'estimated_time_of_arrival',
            'estimated_time_of_departure',
            'carrier',
          ].includes(key)
        )
          return false;

        if (
          key === 'freight_buy_unit_rate' &&
          !hasPermission(sessionData?.permissions, {
            name: PERMISSION_BUY_ESTIMATE,
            docType: 'NewAccounting::ShipmentEstimate',
          })
        )
          return false;
        if (
          key === 'freight_sell_unit_rate' &&
          !hasPermission(sessionData?.permissions, {
            name: PERMISSION_SELL_ESTIMATE,
            docType: 'NewAccounting::ShipmentEstimate',
          })
        )
          return false;

        if (
          key === 'total_buy' &&
          !hasPermission(sessionData?.permissions, {
            name: PERMISSION_BUY_ESTIMATE,
            docType: 'NewAccounting::ShipmentEstimate',
          })
        )
          return false;
        if (
          key === 'total_sell' &&
          !hasPermission(sessionData?.permissions, {
            name: PERMISSION_SELL_ESTIMATE,
            docType: 'NewAccounting::ShipmentEstimate',
          })
        )
          return false;
        if (
          key === 'margin' &&
          (!hasPermission(sessionData?.permissions, {
            name: PERMISSION_BUY_ESTIMATE,
            docType: 'NewAccounting::ShipmentEstimate',
          }) ||
            !hasPermission(sessionData?.permissions, {
              name: PERMISSION_SELL_ESTIMATE,
              docType: 'NewAccounting::ShipmentEstimate',
            }))
        )
          return false;
        if (removeColumns.includes(key)) return false;
        return true;
      }),
    [allColumnsDefs, freight_type, removeColumns, sessionData?.permissions]
  );

  return (
    <>
      {showShareQuoteModal && (
        <ShareInquiry
          docTypeId={inquiry.id}
          docType={'SalesHub::Inquiry'}
          doc={inquiry}
          onClose={() => setShowShareQuoteModal(false)}
          selectedQuote={''}
        />
      )}
      <Row gutter={[0, 16]}>
        <Col span={24}>
          <Row justify={'end'}>
            <Col>
              {(inquiry?.inquiry_options?.length || 0) > 0 && (
                <Button size="small" onClick={() => setShowShareQuoteModal(true)}>
                  Share Quotation
                </Button>
              )}
            </Col>
            {(!isWonLostOrCanceled ||
              (isLostOrCanceled &&
                showCreateBtnLost &&
                (inquiry?.inquiry_options?.length || 0) === 0)) && (
              <>
                <Col style={{ marginLeft: '10px' }}>
                  <Button size="small" onClick={() => setShowInquiryOptionDrawer(true)}>
                    Create Blank Quote
                  </Button>
                </Col>
                <Col style={{ marginLeft: '10px' }}>
                  <Button size="small" type="primary" onClick={() => setShowRateExplorer(true)}>
                    Search Rates
                  </Button>
                </Col>
              </>
            )}
          </Row>
        </Col>
        {dataSource.length > 0 && (
          <Col span={24}>
            <div className="customs-tab">
              <BaseTable
                reportName="inquiry_options"
                key={gridKey}
                columns={Object.values(finalCols.map((col) => allColumnsDefs[col]))}
                rowData={dataSource}
                height={'250px'}
                rowSelection={rowSelection}
                onSelectionChanged={rowSelectionChange}
                reportConfig={{
                  rowMultiSelectWithClick: true,
                  defaultColDef: {
                    suppressMovable: true,
                  },
                  components: {
                    ActionRendererDetailReport,
                    quoteStatusRender: InquiryOptionStatusRenderer,
                  },
                }}
                allMapping={true}
              />
            </div>
          </Col>
        )}
      </Row>

      <RateExplorer
        onSuccess={onRateExplorerSubmits}
        onClose={onRateExplorerClose}
        inquiry={inquiry}
        visible={showRateExplorer}
        fallbackForNoRates={
          <Flex
            style={{ padding: '5rem 1rem', textAlign: 'center' }}
            vertical
            gap={`1rem`}
            justify={'center'}
            align={'center'}
          >
            <ExclamationCircleFilled style={{ fontSize: '5rem', opacity: '30%' }} />
            <span>
              <Typography.Title
                level={3}
                style={{ fontWeight: '400', margin: 0 }}
              >{`No Results Found`}</Typography.Title>
              <Typography.Text
                style={{ opacity: '45%' }}
              >{`Create a blank quote with the above details.`}</Typography.Text>
            </span>
            <Button
              type="primary"
              style={{ width: 'fit-content' }}
              onClick={() => setShowInquiryOptionDrawer(true)}
            >
              Create Blank Quote
            </Button>
          </Flex>
        }
      />

      <InquiryOptionDrawer
        isVisible={showInquiryOptionDrawer}
        setIsVisible={setShowInquiryOptionDrawer}
        onSuccess={() => {
          setShowInquiryOptionDrawer(false);
          refetchInquiry && refetchInquiry();
        }}
        inquiry={inquiry}
      />
    </>
  );
}

export default InquiryOptionTable;
