import { convertToDayJs } from '@shipmnts/pixel-hub';
import { get as _get, set as _set } from 'lodash';
import { ArrayValueConfig } from './GenAIInputWrapper';

export const GEN_AI_FILLED_FIELD_CLASSNAME = 'gen-ai-filled-cell';

export const processOutput = (input: any, IGNORE_KEYS: string[] = []) => {
  const result: any = {};

  for (const key in input) {
    if (IGNORE_KEYS.includes(key)) {
      result[key] = input[key];
      continue;
    }
    const value = input[key];

    if (['string', 'number'].includes(typeof value)) {
      result[key] = { showIcon: true };
    } else if (Array.isArray(value)) {
      result[key] = value.map((item) => {
        if (['string', 'number'].includes(typeof value)) {
          return { [item]: { showIcon: true } };
        }
        return processOutput(item, IGNORE_KEYS);
      });
    } else if (typeof value === 'object' && value !== null) {
      result[key] = processOutput(value, IGNORE_KEYS);
    }
  }

  return result;
};

export const transformOutput = (input: any) => {
  const result: any = {};

  for (const key in input) {
    const value = input[key];

    if (['string', 'number'].includes(typeof value)) {
      if (key.toLocaleLowerCase().includes('date') || key.toLocaleLowerCase().includes('time')) {
        result[key] = convertToDayJs(value, 'DD-MM-YYYY HH:mm:ss');
      } else result[key] = value;
    } else if (Array.isArray(value)) {
      if (value.length > 0 && typeof value[0] === 'object') {
        result[key] = value.map((item) => transformOutput(item));
      } else {
        result[key] = value;
      }
    } else if (typeof value === 'object' && value !== null) {
      result[key] = transformOutput(value);
    }
  }

  return result;
};

/**
 * Return weather the field is GenAI field or not
 * @param fields : fields from context
 * @param fieldKey : field key of current cell
 * @param path_to_array : path to array in fields
 * @param index : index of array
 * @param findConfig : if array is not directly accessible, then findConfig is used to find the array
 * @returns : boolean
 */
export const arrayFieldHelper = (
  fields: any,
  fieldKey: any,
  path_to_array: any,
  index: any,
  findConfig?: any
) => {
  const arr = _get(fields, path_to_array);
  if (Array.isArray(arr)) {
    if (index !== undefined && arr.length > index) {
      const field = _get(arr[index], fieldKey);
      return field?.showIcon;
    } else if (findConfig) {
      const field = arr.find((item) => item[findConfig.key] === findConfig.value);
      return _get(field, fieldKey)?.showIcon;
    }
  }
};

export const cellClassHelper = (params: any, field_path_to_array: any, fields: any) => {
  const isGenAIFilled = arrayFieldHelper(
    fields,
    params?.colDef?.field,
    field_path_to_array,
    params.rowIndex
  );
  if (isGenAIFilled) return GEN_AI_FILLED_FIELD_CLASSNAME;
  return '';
};

export const handleFieldChangeHelper = (
  genAIFilledFields: any,
  key: string[] | string,
  arrayValueConfig?: ArrayValueConfig
) => {
  const res = { ...genAIFilledFields };

  if (!arrayValueConfig) {
    _set(res, key, { ..._get(res, key), showIcon: false });
  } else {
    const { path_to_array, index, findConfig } = arrayValueConfig;
    const arr = _get(res, path_to_array);

    if (Array.isArray(arr)) {
      if (index !== undefined && arr.length > index) {
        const field = { ...arr[index] };
        _set(field, key, { ..._get(field, key), showIcon: false });
        arr[index] = field;
        _set(res, path_to_array, arr);
      } else if (findConfig) {
        const field = arr.find((item) => item[findConfig.key] === findConfig.value);
        _set(field, key, { ..._get(field, key), showIcon: false });
        _set(res, path_to_array, arr);
      }
    }
  }

  return res;
};
