import { get } from 'lodash';
import React, { forwardRef } from 'react';
import RenderLdbEvents from './RenderLdbEvents';
import { ApplicationPropsType } from '../../../types';
import { Tag } from '@shipmnts/pixel-hub';

const LdbTracker = forwardRef<HTMLDivElement, ApplicationPropsType>(({ container, app }, ref) => {
  const ldbLastStatus = get(container.tracking_statuses, 'ldb_tracker', null);

  if (!ldbLastStatus) {
    return (
      <>
        <div className="activity-box terminal-tracking" ref={ref}>
          <div className="title" style={{ fontWeight: 600 }}>
            {app?.label}
          </div>
          <div style={{ fontWeight: 500, fontSize: '14px', margin: '10px 0px' }}>
            <Tag type="critical">No Data is available for {container.container_number}.</Tag>
          </div>
        </div>
      </>
    );
  }

  return (
    <div className="activity-box terminal-tracking" ref={ref}>
      <div className="title" style={{ fontWeight: 600 }}>
        {app?.label}
        {ldbLastStatus?.status === 'inactive' && (
          <span style={{ marginLeft: '25px' }}>
            <Tag type={'default'}>Inactive</Tag>
          </span>
        )}
        {ldbLastStatus?.status === 'error' && (
          <span style={{ marginLeft: '25px' }}>
            <Tag type="critical">Error Occured</Tag>
          </span>
        )}
        {ldbLastStatus?.status === 'disabled' && (
          <span style={{ marginLeft: '25px' }}>
            <Tag type="info">Tracking Disabled</Tag>
          </span>
        )}
        {ldbLastStatus?.status === 'not_applicable' && (
          <span style={{ marginLeft: '25px' }}>
            <Tag type={'default'}>Not Applicable</Tag>
          </span>
        )}
      </div>
      <RenderLdbEvents ldbLastStatus={ldbLastStatus} />
    </div>
  );
});

export default LdbTracker;
LdbTracker.displayName = 'LdbTracker';
