/* eslint-disable @typescript-eslint/no-unused-expressions */
import React, { MutableRefObject, useEffect, useState } from 'react';
import { Space, Button, Dropdown, PlusOutlined, message } from '@shipmnts/pixel-hub';
import { useSession } from 'common';
import { GridOptions } from '@ag-grid-community/core';
import { ResourceType, useShipmentEstimateStore } from './ChargeEstimates';
import { addNewEstimateRow } from './helpers';
import type { MenuProps } from '@shipmnts/pixel-hub';
import { CHARGE_AMOUNT_PREPAID } from 'operations/modules/shipment/constants';
import { ShipmentEstimateValue } from 'operations/models/ShipmentEstimate';
import { useMutation } from '@apollo/client';
import { SAVE_SHIPMENT_ESTIMATES } from 'operations/graphql/shipmentEstimate';
import { get as _get } from 'lodash';
import { errorMessageHandlerGraphQLString } from 'common';
import ChargeEstimatesQuotationsModal from './ChargeEstimatesQuotationsModal';

type MenuItem = Required<MenuProps>['items'][number];
const QUOTATION_MODAL = 'quotation';
interface EstimateActionsType {
  resource: any;
  resource_type: ResourceType;
  gridRef: MutableRefObject<GridOptions | undefined>;
}

export default function EstimateActions(props: EstimateActionsType) {
  const { resource, resource_type, gridRef } = props;
  const { store } = useShipmentEstimateStore();
  const [modalState, setModalState] = useState<string | undefined>(undefined);
  const { company_account } = useSession();
  const [saveEstimates, { data, error }] = useMutation(SAVE_SHIPMENT_ESTIMATES);

  useEffect(() => {
    if (data?.save_shipment_estimates_new) {
      const estimates = _get(data, 'save_shipment_estimates_new', []);
      if (estimates.length > 0) {
        store.addEstimates(estimates);
        message.success('Item added successfully');
      }
    }
    if (error) {
      message.error(errorMessageHandlerGraphQLString(error));
    }
  }, [data, error, store]);

  const addItemsToEstimates = async (estimates: Partial<ShipmentEstimateValue>[]) => {
    const default_estimate: Partial<ShipmentEstimateValue> = {
      customer_company_id: resource.customer_company?.id,
      buy_rate: 0,
      buy_exchange_rate: 1,
      buy_terms: CHARGE_AMOUNT_PREPAID,
      buy_currency: company_account.default_currency,
      sell_rate: 0,
      sell_exchange_rate: 1,
      sell_terms: CHARGE_AMOUNT_PREPAID,
      sell_currency: company_account.default_currency,
      warehouse_transaction_id: resource.id,
      sell_branch_id: resource?.branch?.id,
      buy_branch_id: resource?.branch?.id,
    };

    if (estimates.length > 0) {
      const newEstimates: Partial<ShipmentEstimateValue>[] = [];
      estimates.forEach((est) => {
        if (est) newEstimates.push({ ...default_estimate, ...est });
      });
      if (resource.id !== 'new')
        await saveEstimates({
          variables: { shipment_estimates: newEstimates },
        });
    }
  };

  const items: MenuItem[] = [
    {
      key: 'sales_quotation',
      onClick: () => {
        setModalState(QUOTATION_MODAL);
      },
      label: 'Sales Quotation',
    },
  ];

  return (
    <Space>
      <Button
        icon={<PlusOutlined />}
        disabled={resource.status === 'cancelled'}
        onClick={() => addNewEstimateRow(store, resource, resource_type, gridRef, company_account)}
        size="small"
        ghost
        type="primary"
      >
        Add Charge
      </Button>
      <Dropdown menu={{ items }} placement="topLeft">
        <Button size="small" icon={<PlusOutlined />}>
          Get Items From
        </Button>
      </Dropdown>
      {modalState === QUOTATION_MODAL && (
        <ChargeEstimatesQuotationsModal
          resource={resource}
          onClose={() => setModalState(undefined)}
          addItemsToEstimates={addItemsToEstimates}
          resource_type={resource_type}
        />
      )}
    </Space>
  );
}
