import React, { MutableRefObject, useEffect, useState } from 'react';
import { Modal, Radio, Row, Tag, Tooltip } from '@shipmnts/pixel-hub';
import { QuestionCircleFilled, BaseTable } from '@shipmnts/pixel-hub';
import { Column } from 'operations/models/Report';
import { compact as _compact } from 'lodash';
import { groupBy as _groupBy } from 'lodash';
import { ShipmentContainerValue } from 'operations/models/ShipmentContainer';
import { GridOptions } from '@ag-grid-community/core';
import { ShipmentValue } from 'operations/models/Shipment';
import {
  SHIPMENT_ACTION_CREATE,
  SHIPMENT_ACTION_MERGE,
  SHIPMENT_ACTION_MERGE_AND_SPLIT,
  SHIPMENT_ACTION_SPLIT,
} from '../constants';
import { startCase } from 'lodash';

interface ValidateShipmentCreationProps {
  shipment: ShipmentValue | undefined;
  selectedContainers: ShipmentContainerValue[];
  handleSubmit: () => void;
  onClose: () => void;
  gridRef: MutableRefObject<GridOptions | undefined>;
  setSelectedBookingId?: (bookingId: string) => void;
}

const ValidateShipmentCreation = React.memo(function ValidateShipmentCreation(
  props: ValidateShipmentCreationProps
): JSX.Element {
  const { shipment, selectedContainers, handleSubmit, onClose, gridRef, setSelectedBookingId } =
    props;
  const [currentRowData, setCurrentRowData] = useState<any[]>([]);

  const [otoToContainersMap, setOtoToContainerMap] = useState<any>();
  const [selectedContainerOtoToContainersMap, setSelectedContainerOtoToContainerMap] =
    useState<any>();
  const [actionName, setActionName] = useState<string>('');

  useEffect(() => {
    const containerWithGrp = _groupBy(
      selectedContainers.map((c) => ({
        ...c,
        ocean_transport_order_id: c?.ocean_transport_order_ids?.[0],
      })) || [],
      'ocean_transport_order_id'
    );
    setSelectedContainerOtoToContainerMap(containerWithGrp);
  }, [selectedContainers]);

  useEffect(() => {
    if (!selectedContainerOtoToContainersMap || !otoToContainersMap) return;
    const selectedOTOs = Object.keys(selectedContainerOtoToContainersMap);
    if (selectedOTOs?.length === 1) {
      if (
        selectedContainerOtoToContainersMap[selectedOTOs[0]]?.length ===
        otoToContainersMap[selectedOTOs[0]]?.length
      ) {
        setActionName(SHIPMENT_ACTION_CREATE);
        handleSubmit();
      } else setActionName(SHIPMENT_ACTION_SPLIT);
    } else {
      const is_merge = selectedOTOs.every((oto) => {
        return selectedContainerOtoToContainersMap[oto]?.length === otoToContainersMap[oto]?.length;
      });
      if (is_merge) setActionName(SHIPMENT_ACTION_MERGE);
      else setActionName(SHIPMENT_ACTION_MERGE_AND_SPLIT);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [otoToContainersMap, selectedContainerOtoToContainersMap]);

  useEffect(() => {
    const possibleContainers =
      shipment?.shipment_containers?.filter(
        (container) => container.ocean_transport_order_ids?.length
      ) ?? [];
    const containerWithGrp = _groupBy(
      possibleContainers.map((c) => ({
        ...c,
        ocean_transport_order_id: c?.ocean_transport_order_ids?.[0],
      })) || [],
      'ocean_transport_order_id'
    );
    setOtoToContainerMap(containerWithGrp);
  }, [shipment]);

  useEffect(() => {
    const selectedContainersWithInfo = selectedContainers.map((container) => {
      return {
        ...container,
        ocean_transport_order_id: container?.ocean_transport_order_ids?.[0],
        booking_number: container?.booking_number,
        job_number: container?.job_number,
      };
    });
    const containerWithGrp = _groupBy(selectedContainersWithInfo, 'ocean_transport_order_id');
    const rowData: any[] = Object.keys(containerWithGrp).map((key) => {
      return {
        ocean_transport_order_id: key,
        booking_number: containerWithGrp[key][0]?.booking_number,
        order_number: containerWithGrp[key][0]?.job_number,

        container_numbers: containerWithGrp[key].map((container) => container.container_number),
      };
    });
    setCurrentRowData(rowData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedContainers]);

  const columnDefs: Column[] = _compact([
    {
      headerName: 'Booking #',
      field: 'booking_number',
      colId: 'booking_number',
      cellRenderer: function render({ data }: { data: any }) {
        return (
          <span>
            <a
              target="_blank"
              href={`/view/booking_order/${data.ocean_transport_order_id}`}
              rel="noreferrer"
            >
              {data.booking_number}
            </a>
          </span>
        );
      },
    },
    {
      headerName: 'Order #',
      field: 'order_number',
      colId: 'order_number',
      cellRenderer: function render({ data }: { data: any }) {
        return (
          <span>
            <a target="_blank" href={`/view/shipment/${shipment?.id}`} rel="noreferrer">
              {data.order_number}
            </a>
          </span>
        );
      },
    },
    {
      headerName: 'Container s#',
      field: 'container_number',
      colId: 'container_number',
      cellRenderer: function ContainerRenderer(params: any) {
        const container_numbers = params.data?.container_numbers;
        return (
          <div>
            {(container_numbers || []).map((container: any, index: any) => (
              <Tooltip key={index}>
                <Tag style={{ marginBottom: '2px' }}> {container} </Tag>
              </Tooltip>
            ))}
          </div>
        );
      },
    },
  ]);

  if (actionName === SHIPMENT_ACTION_CREATE) return <></>;
  return (
    <Modal
      title={
        <Row>
          <QuestionCircleFilled style={{ color: '#faad14' }} />
          <div style={{ marginLeft: '16px' }}>
            Confirm {startCase(actionName)} of Shipments and Bookings
          </div>
        </Row>
      }
      open
      okText={`Confirm ${startCase(actionName)}`}
      onOk={() => handleSubmit()}
      onCancel={() => onClose()}
      width={630}
      destroyOnClose
    >
      <>
        {[SHIPMENT_ACTION_MERGE_AND_SPLIT, SHIPMENT_ACTION_SPLIT].includes(actionName) && (
          <div>
            <p>
              {actionName === SHIPMENT_ACTION_SPLIT
                ? 'For remaining picked up container(s) as per below details a new split booking will be created'
                : 'Select the Order and Booking details into which all other orders and allocated bookings will be merged'}
            </p>

            <BaseTable
              reportName={'container_details'}
              columns={columnDefs || []}
              rowData={currentRowData}
              onSelectionChanged={() => {
                const selectedNodes = gridRef?.current?.api?.getSelectedNodes() || [];
                if (selectedNodes?.[0]?.data?.ocean_transport_order_id) {
                  setSelectedBookingId &&
                    setSelectedBookingId(selectedNodes?.[0]?.data?.ocean_transport_order_id);
                }
              }}
              height={'20vh'}
              gridRef={gridRef}
              reportConfig={{}}
            />
          </div>
        )}
        <br />
        {[SHIPMENT_ACTION_MERGE_AND_SPLIT, SHIPMENT_ACTION_MERGE].includes(actionName) && (
          <>
            <p>Merge Booking Into</p>
            <Radio.Group
              options={currentRowData.map((c) => ({
                label: c.booking_number,
                value: c.ocean_transport_order_id,
              }))}
              onChange={(e) => {
                if (e.target.value) setSelectedBookingId && setSelectedBookingId(e.target.value);
              }}
            />
          </>
        )}
      </>
    </Modal>
  );
  return <></>;
});

export default ValidateShipmentCreation;
