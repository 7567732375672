import { ShipmentEstimateValue } from 'operations/models/ShipmentEstimate';
import { ResourceType, ShipmentEstimateStoreValue } from './ChargeEstimates';
import { CompanyAccountValue } from 'operations/models/CompanyAccount';
import { MutableRefObject } from 'react';
import { GridOptions } from '@ag-grid-community/core';
import { uuid4 } from '@sentry/utils';
import { getSnapshot } from 'mobx-state-tree';
import { CHARGE_AMOUNT_PREPAID, ENUM_BUY_SELL_STATUS } from 'operations/modules/shipment/constants';
import { uniq as _uniq, pick as _pick } from 'lodash';
import { SAVE_SHIPMENT_ESTIMATES } from 'operations/graphql/shipmentEstimate';
import { ApolloClient } from '@apollo/client';
import { message } from '@shipmnts/pixel-hub';
import { errorMessageHandlerGraphQLString } from 'common';
import { WarehouseTransactionProductValue } from '../models/WarehouseTransactionProduct';
import {
  BASIS_TYPE_CBM,
  BASIS_TYPE_CHARGEABLE_WEIGHT,
  BASIS_TYPE_CONTAINER,
  BASIS_TYPE_GROSS_VOLUME,
  BASIS_TYPE_GROSS_WEIGHT,
} from 'operations/modules/reports/constants';

export const RESOURCETYPE_TO_ID_MAP = (resource_type: ResourceType) => {
  if (resource_type === 'Shipment::Shipment') return 'shipment_id';
  if (resource_type === 'Wms::WarehouseTransaction') return 'warehouse_transaction_id';
  if (resource_type === 'SalesHub::InquiryOption') return 'inquiry_option_id';
  return 'shipment_id';
};
export const getFinalUoms = (
  uoms: { name: string }[],
  resource: any,
  resource_type: ResourceType
) => {
  // Define UOM Based on your requirement basic resource_type
  const newUoms: { value: string; label: string; quantity: number }[] = [];
  uoms.forEach((option) => {
    if (resource_type === 'Wms::WarehouseTransaction' && resource?.warehouse_transaction_products) {
      let totalQty = 1;
      if (BASIS_TYPE_CBM.name === option.name || BASIS_TYPE_GROSS_VOLUME.name === option.name) {
        totalQty = resource?.total_gross_volume || 1;
      } else if (BASIS_TYPE_GROSS_WEIGHT.name === option.name) {
        totalQty = resource?.total_gross_wt || 1;
      } else if (BASIS_TYPE_CHARGEABLE_WEIGHT.name === option.name) {
        totalQty = resource?.total_chargeable_wt || 1;
      } else if (BASIS_TYPE_CONTAINER.name === option.name) {
        totalQty =
          new Set(
            resource?.warehouse_transaction_products?.map(
              (product: WarehouseTransactionProductValue) => product?.container_number
            )
          )?.size || 1;
      }
      newUoms.push({
        value: option.name,
        label: option.name,
        quantity: totalQty,
      });
      return;
    }
    newUoms.push({
      label: option.name,
      value: option.name,
      quantity: resource[option.name] || 1,
    });
  });
  return newUoms;
};

export const addNewEstimateRow = (
  store: ShipmentEstimateStoreValue,
  resource: any,
  resource_type: ResourceType,
  gridRef: MutableRefObject<GridOptions | undefined>,
  company_account: CompanyAccountValue
) => {
  const estimates = store.estimates.filter(
    (estimate) =>
      estimate.charge_type === 'Item' &&
      estimate[RESOURCETYPE_TO_ID_MAP(resource_type)] === resource.id
  );
  let estimate_to_consider;
  if (estimates.length > 0) {
    estimate_to_consider = estimates.reduce((last, est) => {
      return (est?.sequence || 0) > (last?.sequence || 0) ? est : last;
    }, estimates[0]);
  }
  //   Define Initial Value Based on your requirement on basis of resource_type
  const customer_company = estimate_to_consider?.customer_company
    ? getSnapshot(estimate_to_consider?.customer_company)
    : resource.customer_company || null;
  const default_estimate: ShipmentEstimateValue = {
    uuid: uuid4(),
    item: null,
    buy_branch_id: resource?.branch?.id,
    sell_branch_id: resource?.branch?.id,
    customer_company_id: estimate_to_consider?.customer_company_id || resource.customer_company?.id,
    customer_company: customer_company,
    uom: null,
    quantity: 1,
    buy_rate: 0,
    buy_exchange_rate: estimate_to_consider?.buy_exchange_rate || 1,
    buy_terms: CHARGE_AMOUNT_PREPAID,
    buy_currency: estimate_to_consider?.buy_currency || company_account.default_currency,
    sell_rate: 0,
    sell_exchange_rate: estimate_to_consider?.sell_exchange_rate || 1,
    sell_terms: CHARGE_AMOUNT_PREPAID,
    sell_currency: estimate_to_consider?.sell_currency || company_account.default_currency,
    supplier_company_id: estimate_to_consider?.supplier_company_id,
    supplier_company: estimate_to_consider?.supplier_company
      ? getSnapshot(estimate_to_consider.supplier_company)
      : null,
    [RESOURCETYPE_TO_ID_MAP(resource_type)]: resource.id,
    total_buy_amount: 0,
    total_buy_billed_amount: 0,
    total_sell_amount: 0,
    total_sell_billed_amount: 0,
    buy_status: ENUM_BUY_SELL_STATUS.UNBILLED,
    sell_status: ENUM_BUY_SELL_STATUS.UNBILLED,
    charge_type: 'Item',
  } as ShipmentEstimateValue;
  store.addEstimates([default_estimate]);
  setTimeout(() => {
    gridRef?.current?.api?.startEditingCell({ rowIndex: estimates.length, colKey: 'item' });
    gridRef?.current?.api?.setFocusedCell(estimates.length, 'item');
  }, 500);
};

export function callSync(store: ShipmentEstimateStoreValue, client: ApolloClient<object>) {
  const estimate_ids = _uniq(store.startSyncing()).filter((est) => !!est);
  if (estimate_ids.length === 0) return;
  if (store.estimates.find((est) => est.mismatch)) {
    store.updateSyncError(estimate_ids);
    return;
  }

  const estimates_to_be_synced = store.estimates.filter(
    (est) => estimate_ids.includes(est.uuid) && est.item && est.charge_type === 'Item'
  );

  store.addToBeSynced(
    store.estimates
      .filter((est) => estimate_ids.includes(est.uuid))
      .filter((est) => !est.item)
      .map((est) => est.uuid)
  );

  if (estimates_to_be_synced.length === 0) {
    store.updateSyncSuccess();
  }

  const shipment_estimates = estimates_to_be_synced
    .map((est) => getSnapshot(est))
    .map((est) => {
      const new_estimate: Partial<any> = _pick(est, [
        'id',
        'item',
        'buy_branch_id',
        'sell_branch_id',
        'supplier_company_id',
        'customer_company_id',
        'uom',
        'quantity',
        'buy_exchange_rate',
        'buy_rate',
        'buy_currency',
        'buy_terms',
        'sell_exchange_rate',
        'sell_rate',
        'sell_currency',
        'sell_terms',
        'sell_remarks',
        'remarks',
        'equipment_type',
        'equipment_name',
        'shipment_id',
        'warehouse_transaction_id',
        'tag',
        'uuid',
        'sequence',
        'tax_percentage',
        'taxable',
        'taxability',
      ]);
      new_estimate.product_rating_id = est.product_rating?.id || null;
      return new_estimate;
    });
  client
    .mutate({
      mutation: SAVE_SHIPMENT_ESTIMATES,
      variables: {
        shipment_estimates,
      },
    })
    .then((response) => {
      if (response.data?.save_shipment_estimates_new) {
        const new_estimates = estimates_to_be_synced.filter((est) => !est.id);
        new_estimates.forEach((est) => {
          const match = response.data.save_shipment_estimates_new.find(
            (new_est: ShipmentEstimateValue) => new_est.uuid === est.uuid
          );
          if (match) {
            est.updateDetailsOnCreate(match.id, match.buy_branch_id, match.supplier_company);
          }
        });
        store.updateSyncSuccess();
      } else if (response.errors) {
        // message.error(errorMessageHandlerGraphQLString(response.errors));
        store.updateSyncError(estimate_ids);
      }
    })
    .catch((error) => {
      message.error(errorMessageHandlerGraphQLString(error));
      store.updateSyncError(estimate_ids);
    });
}
