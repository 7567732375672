import { gql } from '@apollo/client';
import { branchAccountFields } from 'operations/graphql/shipment';

export const warehouseUnitFields = gql`
  fragment warehouseUnitFields on WarehouseUnitObjectType {
    id
    node_name
    node_code
    node_type
    is_two_step
    storage_capacity
    storage_charge
    parent_unit {
      id
      node_name
      node_code
      node_type
      is_two_step
    }
    branch {
      ...branchAccountFields
    }
    warehouse_type
    type_of_storage
    customs_ref_code
    default_receiving_location {
      id
      node_name
      node_code
      node_type
    }
    default_delivery_location {
      id
      node_name
      node_code
      node_type
    }
    default_damage_location {
      id
      node_name
      node_code
      node_type
    }
  }
  ${branchAccountFields}
`;

export const CREATE_WAREHOUSE_UNIT = gql`
  mutation create_warehouse_unit($warehouse_unit: WarehouseUnitInputType!) {
    create_warehouse_unit(warehouse_unit: $warehouse_unit) {
      id
      node_type
    }
  }
`;

export const UPDATE_WAREHOUSE_UNIT = gql`
  mutation update_warehouse_unit($id: ID!, $warehouse_unit: WarehouseUnitInputType!) {
    update_warehouse_unit(id: $id, warehouse_unit: $warehouse_unit) {
      id
      node_type
    }
  }
`;

export const REMOVE_WAREHOUSE_UNIT = gql`
  mutation remove_warehouse_unit($id: ID!) {
    remove_warehouse_unit(id: $id) {
      message
    }
  }
`;

export const FETCH_LOCATIONS = gql`
  query get_locations($branch_id: [String!]) {
    get_locations(branch_id: $branch_id) {
      id
      node_name
      node_type
      parent_unit_id
    }
  }
`;

export const FETCH_WAREHOUSE_UNIT = gql`
  query get_warehouse_unit($id: ID!) {
    get_warehouse_unit(id: $id) {
      ...warehouseUnitFields
    }
  }
  ${warehouseUnitFields}
`;

export const GET_WAREHOUSE_UNIT = gql`
  query warehouse_unit_search($branch_ids: [String!]!, $query: String, $type: String) {
    warehouse_unit_search(branch_ids: $branch_ids, query: $query, type: $type) {
      ...warehouseUnitFields
    }
  }
  ${warehouseUnitFields}
`;
