import { gql } from '@apollo/client';
import { productFields } from 'operations/modules/warehouseManagement/graphql/product';
import { warehouseUnitFields } from './warehouseUnit';
import { branchAccountFields } from 'operations/graphql/shipment';
import { minimumCompanyFields } from 'network/graphql/company';

export const stockLedgerFields = gql`
  fragment stockLedgerFields on StockLedgerSummaryObjectType {
    id
    branch {
      ...branchAccountFields
    }
    customer_company {
      ...minimumCompanyFields
    }
    product {
      ...productFields
    }
    warehouse_unit {
      ...warehouseUnitFields
    }
    product_code
    product_name
    packing_type
    qty
    balance
    available_qty
    gross_weight
    gross_volume
    invoice_number
    container_number
    serial_number
    customs_ref_code
    batch_number
    lot_number
    pallet_no
    country_of_origin
    shipment_document_number
    customer_ref_code
    exp_date
    prod_date
    per_pack_weight
    per_piece_volume
    receipt {
      id
      transaction_number
    }
    receipt_date
    cross_docking
  }
  ${minimumCompanyFields}
  ${productFields}
  ${warehouseUnitFields}
  ${branchAccountFields}
`;

export const CREATE_STORAGE_JOB = gql`
  mutation create_storage_job($customer_company_id: ID!, $branch_id: ID!, $definitions: JSON!) {
    create_storage_job(
      customer_company_id: $customer_company_id
      branch_id: $branch_id
      definitions: $definitions
    ) {
      shipment {
        id
        job_number
      }
    }
  }
`;
export const CREATE_HANDLING_JOB = gql`
  mutation create_handling_job($customer_company_id: ID!, $branch_id: ID!, $definitions: JSON!) {
    create_handling_job(
      customer_company_id: $customer_company_id
      branch_id: $branch_id
      definitions: $definitions
    ) {
      shipment {
        id
        job_number
      }
    }
  }
`;
