import { SessionDataValue } from 'common/models/SessionData';
import { NEGATIVE_TYPE, PRIMARY_TYPE } from '../constants';
import { Action } from '../models';
import {
  deleteViewSubscriptionActionRender,
  statusUpdateViewSubscriptionActionRender,
  editViewSubscriptionActionRender,
} from './viewSubscriptionActionRenderer';
import { ADMINISTRATION_ACCESS_LEVELS } from 'common';

export const PERMISSION_CREDIT_CONTROL_WRITE = 'Credit Control Write';

export const enableAction = (
  viewSubscription: any,
  session: SessionDataValue,
  checkStatus = ''
) => {
  return viewSubscription?.is_erpnext_auto_email
    ? checkStatus
      ? viewSubscription?.enabled === (checkStatus === 'active' ? 1 : 0)
      : true
    : (session?.id === viewSubscription?.created_by?.id ||
        ADMINISTRATION_ACCESS_LEVELS.includes(session.user_level || '')) &&
        (checkStatus ? viewSubscription?.status === checkStatus : true);
};

export const editViewSubscription = (viewSubscription: any, session: SessionDataValue): Action => {
  return {
    type: PRIMARY_TYPE,
    displayComponent: 'Edit Subscription',
    key: 'edit_subscription',
    isEnable: enableAction(viewSubscription, session),
    performAction: editViewSubscriptionActionRender,
  };
};

export const deleteViewSubscription = (
  viewSubscription: any,
  session: SessionDataValue
): Action => {
  return {
    type: NEGATIVE_TYPE,
    displayComponent: 'Delete Subscription',
    key: 'delete_subscription',
    isEnable: enableAction(viewSubscription, session),
    performAction: deleteViewSubscriptionActionRender,
  };
};

export const disableViewSubscription = (
  viewSubscription: any,
  session: SessionDataValue
): Action => {
  return {
    type: PRIMARY_TYPE,
    displayComponent: 'Disable Subscription',
    key: 'disable_subscription',
    isEnable: enableAction(viewSubscription, session, 'active'),
    performAction: statusUpdateViewSubscriptionActionRender,
  };
};

export const enableViewSubscription = (
  viewSubscription: any,
  session: SessionDataValue
): Action => {
  return {
    type: PRIMARY_TYPE,
    displayComponent: 'Enable Subscription',
    key: 'enable_subscription',
    isEnable: enableAction(viewSubscription, session, 'disabled'),
    performAction: statusUpdateViewSubscriptionActionRender,
  };
};

export const editFinanceViewSubscription = (
  viewSubscription: any,
  session: SessionDataValue
): Action => {
  return {
    type: PRIMARY_TYPE,
    displayComponent: 'Enable Subscription',
    key: 'enable_subscription',
    isEnable: enableAction(viewSubscription, session, 'disabled'),
    onClick: () => {
      window.open('', '_self');
    },
  };
};

export const getViewSubscriptionActions = (viewSubscription: any, session: SessionDataValue) => {
  return [
    editViewSubscription(viewSubscription, session),
    disableViewSubscription(viewSubscription, session),
    enableViewSubscription(viewSubscription, session),
    deleteViewSubscription(viewSubscription, session),
  ];
};

export const getViewSubscriptionActionsWithEdit = (
  viewSubscription: any,
  session: SessionDataValue
) => {
  return [
    editViewSubscription(viewSubscription, session),
    disableViewSubscription(viewSubscription, session),
    enableViewSubscription(viewSubscription, session),
    deleteViewSubscription(viewSubscription, session),
  ];
};
