import {
  AddressCompanySearch,
  Col,
  DatePicker,
  Form,
  FormInstance,
  GlobalSearch,
  Input,
  Row,
} from '@shipmnts/pixel-hub';
import BookingRoutingDetailsNew from 'operations/modules/booking/components/BookingOrderForm/BookingRoutingDetailsNew';
import { useRef } from 'react';
import { BOOKING_TYPE_SHIPPING_LINE } from 'operations/baseConstants';
import React from 'react';
import { FreightType, TRADE_TYPE_EXPORT, TRADE_TYPE_IMPORT } from '../../constants';
import { RoutingDetailsValue } from 'operations/components/RoutingDetails';
import { LOCATION_TYPE_CITY } from 'operations/models/Location';
import { get as _get } from 'lodash';
import { ShipmentValue } from 'operations/models/Shipment';
import GenAIInputWrapper from './FormFiller/GenAIInputWrapper';

export interface RoutingDetailsWrapperProps {
  value?: RoutingDetailsValue;
  onChange?: (value: RoutingDetailsValue) => void;
  disableNodes?: boolean;
  disableVoyageUpdate?: boolean;
  validateVesselVoyage?: boolean;
  globalCarrierId?: string;
  bookingType?: string | null;
  isReeferContainer?: boolean;
  allowVoyageScheduleSearch?: boolean;
  onlyTranshipmentHopEditable?: boolean;
  showPickupSection?: boolean;
  showStuffingSection?: boolean;
  showDeStuffingSection?: boolean;
  showMovementSection?: boolean;
  showPodBufferSection?: boolean;
  showPolBufferSection?: boolean;
  showDeliverySection?: boolean;
  freightType?: FreightType;
  loadType?: string;
  form: FormInstance;
  type?: string;
  shipment?: ShipmentValue;
  masterShipmentId?: string;
  showShipmentFields?: boolean;
  showDpdOnMainCarriage?: boolean;
  trade_type?: string;
}

export default function RoutingDetailsWrapper(props: RoutingDetailsWrapperProps) {
  const {
    freightType,
    form,
    shipment,
    masterShipmentId,
    showShipmentFields = true,
    showDpdOnMainCarriage = false,
    trade_type,
    type,
  } = props;
  const routing_details_ref = useRef<{ runValidation: () => boolean }>();
  const bookingType = Form.useWatch('booking_type', form);
  const loadType = Form.useWatch('load_type', form);
  const tradeType = Form.useWatch('trade_type', form) || trade_type;
  const isNodesDisabled =
    type === 'update' &&
    ((shipment?.ocean_transport_orders || []).length > 1 ||
      !!masterShipmentId ||
      !!shipment?.master_shipment_id);
  const services = Form.useWatch('services', form);

  const isVoyageUpdateDisabled =
    (type === 'update' && (shipment?.ocean_transport_orders || []).length > 1) ||
    !!masterShipmentId ||
    !!shipment?.master_shipment_id;

  return (
    <>
      {showShipmentFields && (
        <>
          {(tradeType === TRADE_TYPE_EXPORT || services?.freight_forwarding) && (
            <Row gutter={24}>
              <Col span={6}>
                <Form.Item label="Pickup Address" name={['party', 'pickup']}>
                  <GenAIInputWrapper fieldKey={['shipment_parties', 'pickup']}>
                    <AddressCompanySearch
                      onChange={(value: any) => {
                        form.setFieldValue(
                          'pickup_address_print',
                          value?.party_address?.print_address || ''
                        );
                      }}
                      addressSearchProps={{
                        showAddBranchAction: true,
                      }}
                    />
                  </GenAIInputWrapper>
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item name={'pickup_address_print'} label="Pickup Address Print">
                  <GenAIInputWrapper fieldKey={['pickup_address_print']}>
                    <Input.TextArea rows={2} />
                  </GenAIInputWrapper>
                </Form.Item>
              </Col>
            </Row>
          )}
          {(tradeType === TRADE_TYPE_IMPORT || services?.freight_forwarding) && (
            <Row gutter={24}>
              <Col span={6}>
                <Form.Item label="Final Place Of Delivery" name="final_place_of_delivery">
                  <GenAIInputWrapper fieldKey={['final_place_of_delivery']}>
                    <GlobalSearch
                      doc_type="Global::Location"
                      searchProps={{ type: [LOCATION_TYPE_CITY] }}
                      onChange={(value) => {
                        const fpod_print_value = `${_get(value, 'city', '')}, ${_get(
                          value,
                          'state',
                          ''
                        )}`;
                        form.setFieldValue('final_place_of_delivery_print', fpod_print_value);
                      }}
                    />
                  </GenAIInputWrapper>
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  name="final_place_of_delivery_print"
                  label="Final Place Of Delivery Print"
                >
                  <GenAIInputWrapper fieldKey={['final_place_of_delivery_print']}>
                    <Input.TextArea rows={2} />
                  </GenAIInputWrapper>
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  name="estimated_time_of_arrival_at_fpod"
                  label="ETA at Final Place of Delivery"
                >
                  <GenAIInputWrapper fieldKey={['estimated_time_of_arrival_at_fpod']}>
                    <DatePicker style={{ width: '100%' }} />
                  </GenAIInputWrapper>
                </Form.Item>
              </Col>
            </Row>
          )}
        </>
      )}
      <Form.Item noStyle name="voyage_schedule_id">
        <div />
      </Form.Item>
      <Form.Item
        name="routing_details"
        noStyle
        rules={[
          {
            validator: (rule, value) => {
              if (!routing_details_ref?.current?.runValidation()) {
                return Promise.resolve();
              }
              return Promise.reject('Error in routing details');
            },
          },
        ]}
      >
        <BookingRoutingDetailsNew
          allowVoyageScheduleSearch={!!bookingType}
          form={props.form}
          ref={routing_details_ref}
          freightType={freightType}
          loadType={loadType}
          bookingType={bookingType}
          disableNodes={isNodesDisabled}
          disableVoyageUpdate={isVoyageUpdateDisabled}
          validateVesselVoyage={bookingType === BOOKING_TYPE_SHIPPING_LINE}
          showDpdOnMainCarriage={showDpdOnMainCarriage}
          showPolBufferSection={tradeType === TRADE_TYPE_EXPORT}
          showStuffingSection={tradeType === TRADE_TYPE_EXPORT}
          showDeStuffingSection={tradeType === TRADE_TYPE_IMPORT}
          showPodBufferSection={tradeType === TRADE_TYPE_IMPORT}
          isReeferContainer={props.isReeferContainer}
        />
      </Form.Item>
    </>
  );
}
