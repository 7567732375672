import React, { useState, useEffect, useRef } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import { GridOptions } from '@ag-grid-community/core';
import {
  Drawer,
  Row,
  Col,
  Form,
  message,
  DrawerFooter,
  Modal,
  GlobalViewLayout,
  Checkbox,
  DatePicker,
} from '@shipmnts/pixel-hub';
import { CompassOutlined } from '@shipmnts/pixel-hub';
import { ShipmentType, TRADE_TYPE_IMPORT } from 'operations/modules/shipment/constants';
import { ShipmentValue } from 'operations/models/Shipment';
import { LOAD_TYPE_FCL, LOAD_TYPE_LCL } from 'operations/baseConstants';
import { SPLIT_SHIPMENT } from 'operations/graphql/shipment';
import { groupBy as _groupBy } from 'lodash';
import { FETCH_CONTAINERS_FOR_EDIT } from '../graphql/shipmentContainer';
import { dayjs } from '@shipmnts/pixel-hub';
import ValidateShipmentCreation from 'operations/modules/shipment/components/ValidateShipmentCreation';

const getGlobalView = (shipment: ShipmentValue | undefined, showMatchingContainers: boolean) => {
  const globalView: any = {
    id: '1',
    name: 'Shipment Containers',
    color: '#eeeeee',
    doc_type_id: 'Shipment::Shipment',
    definition: {
      sort: [],
      fields: [
        {
          id: 'shipment_containers_initial_booking_number',
          label: 'Booking Number',
          width: 160,
          section: 'Basic',
          sortable: true,
          field_type: 'String',
          filterable: true,
          cell_renderer: {},
          filter_options: '{}',
          headerComponent: 'static_header_component',
          groupable: false,
        },
        {
          id: 'shipment_containers_container_number',
          label: 'Container Number',
          width: 160,
          section: 'Basic',
          sortable: true,
          field_type: 'Link',
          filterable: true,
          headerComponent: 'static_header_component',
          cell_renderer: {
            args: { id_field: 'id', doc_type_id: 'Shipment::ShipmentContainer' },
            function: 'link_render',
          },
          dependent_fields: ['id'],
          filter_options: '{}',
          groupable: false,
          pinned: 'left',
        },
        {
          id: 'shipment_containers_container_type',
          label: 'Container Type',
          width: 160,
          section: 'Basic',
          sortable: true,
          field_type: 'Dropdown',
          headerComponent: 'static_header_component',
          filterable: true,
          cell_renderer: {},
          filter_options:
            '{"values":["20 Standard Dry","20 Flexi Dry","20 One Door Open","20 Flat Rack","20 Hard Top","20 Insulated","20 Open Top","20 Platform","20 Flat (collapsible)","20 Reefer","20 Tank","40 Standard Dry","40 Flexi Dry","40 One Door Open","40 Flat Rack","40 Tank","40 Flat (collapsible)","40 Hard Top","40 HC Pallet Wide","40 High Cube","40 High Cube Hard Top","40 High Cube Open Top","40 Insulated","40 Open Top","40 Platform","40 Platform High Cube","40 Reefer High Cube","45 High Cube"]}',
          groupable: false,
        },
        {
          id: 'shipments_port_of_discharge_id',
          label: 'Port Of Discharge',
          width: 160,
          section: 'Basic',
          sortable: false,
          filterable: false,
          groupable: false,
          field_type: 'Search',
          headerComponent: 'static_header_component',
          cell_renderer: { args: {}, function: 'location_render' },
          filter_options: '{ "doc_type": "Global::Location" }',
          hide: true,
        },
        {
          id: 'shipments_port_of_loading_id',
          label: 'Port Of Loading',
          width: 160,
          section: 'Basic',
          sortable: false,
          filterable: false,
          groupable: false,
          field_type: 'Search',
          cell_renderer: { args: {}, function: 'location_render' },
          filter_options: '{ "doc_type": "Global::Location" }',
          hide: true,
        },
        {
          id: 'analytics_shipment_reports_shipper_id',
          label: 'Shipper',
          width: 160,
          section: 'Basic',
          sortable: false,
          filterable: false,
          groupable: false,
          field_type: 'Search',
          cell_renderer: { args: {}, function: 'record_loader_render' },
          filter_options: '{"doc_type":"Network::Company"}',
          hide: true,
        },
        {
          id: 'analytics_shipment_reports_consignee_id',
          label: 'Consignee',
          width: 160,
          section: 'Basic',
          sortable: false,
          filterable: false,
          groupable: false,
          field_type: 'Search',
          cell_renderer: { args: {}, function: 'record_loader_render' },
          filter_options: '{"doc_type":"Network::Company"}',
          hide: true,
        },
        {
          id: 'shipments_carrier_id',
          label: 'Carrier',
          width: 160,
          section: 'Basic',
          sortable: false,
          filterable: false,
          groupable: false,
          field_type: 'Search',
          cell_renderer: { args: {}, function: 'record_loader_render' },
          filter_options: '{"doc_type":"Global::Carrier"}',
          hide: true,
        },
        {
          id: 'shipments_customer_company_id',
          label: 'Customer',
          width: 160,
          section: 'Basic',
          sortable: false,
          filterable: false,
          groupable: false,
          field_type: 'Search',
          cell_renderer: { args: {}, function: 'record_loader_render' },
          filter_options: '{"doc_type":"Network::Company"}',
          hide: true,
        },
      ],
      filters: [
        {
          field_id: 'shipments_status',
          condition: {
            type: 'notIn',
            values: ['shipment_cancelled'],
          },
        },
      ],
    },
    standard_report: true,
    is_disabled: false,
    global: true,
  };
  if (!showMatchingContainers) {
    if (shipment?.job_number) {
      globalView.definition.filters.push({
        field_id: 'shipments_job_number',
        condition: {
          type: 'in',
          values: [shipment?.job_number],
        },
      });
    } else {
      globalView.definition.filters.push({
        field_id: 'shipments_shipment_booking_number',
        condition: {
          type: 'in',
          values: [shipment?.shipment_booking_number],
        },
      });
      globalView.definition.filters.push({
        field_id: 'shipments_job_number',
        condition: {
          type: 'isNull',
          value: null,
        },
      });
    }
  } else {
    globalView.definition.filters.push(
      {
        field_id: 'shipments_port_of_discharge_id',
        condition: {
          type: 'in',
          values: [shipment?.port_of_discharge],
        },
      },
      {
        field_id: 'shipments_port_of_loading_id',
        condition: {
          type: 'in',
          values: [shipment?.port_of_loading],
        },
      },
      {
        field_id: 'shipments_customer_company_id',
        condition: {
          type: 'in',
          values: [shipment?.customer_company],
        },
      },
      {
        field_id: 'shipments_shipment_type',
        condition: {
          type: shipment?.shipment_type ? 'equals' : 'isNull',
          value: shipment?.shipment_type ?? null,
        },
      }
    );
    if (shipment?.carrier) {
      globalView.definition.filters.push({
        field_id: 'shipments_carrier_id',
        condition: {
          type: 'in',
          values: [shipment?.carrier],
        },
      });
    }
  }
  if (shipment?.job_number) {
    globalView.definition.fields.unshift({
      id: 'shipments_job_number',
      label: 'job Number',
      width: 160,
      section: 'Basic',
      sortable: true,
      field_type: 'String',
      filterable: true,
      cell_renderer: {
        args: {
          id_field: 'id',
          doc_type_id: 'Shipment::Shipment',
        },
        function: 'link_render',
      },
      filter_options: '{}',
      groupable: false,
    });
  } else {
    globalView.definition.fields.unshift({
      id: 'shipments_shipment_booking_number',
      label: 'Customer Order Number',
      width: 200,
      section: 'Basic',
      sortable: true,
      field_type: 'String',
      filterable: true,
      cell_renderer: {
        args: {
          id_field: 'id',
          doc_type_id: 'Shipment::Shipment',
        },
        function: 'link_render',
      },
      filter_options: '{}',
      groupable: false,
    });
  }
  globalView.definition.filters.push({
    field_id: 'shipment_containers_container_number',
    condition: {
      type: 'isNotNull',
    },
  });

  if (shipment?.business_vertical) {
    globalView.definition.filters.push({
      field_id: 'shipments_business_vertical_id',
      condition: {
        type: 'in',
        values: [shipment?.business_vertical?.id],
      },
    });
  }
  return globalView;
};

const CreateShipmentFromExportCustomerOrder = React.memo(
  function CreateShipmentFromExportCustomerOrder(props: {
    shipment: ShipmentValue | undefined;
    action: 'split' | 'create';
    shipmentType: ShipmentType;
    onClose: () => void;
    onSuccess: () => void;
  }): JSX.Element {
    const { shipment, onClose, shipmentType, onSuccess, action } = props;
    const [allowCreateShipment, setAllowCreateShipment] = useState(false);
    const [showMatchingContainers, setShowMatchingContainers] = useState(false);
    const [selectedBookingId, setSelectedBookingId] = useState<string | undefined>();
    const [jobDate, setJobDate] = useState<any>(dayjs());
    const [validateShipmentCreation, setValidateShipmentCreation] = useState(false);
    const [globalView, setGlobalView] = useState<any>();
    const [triggerUseEffect, setTriggerUseEffect] = useState<boolean>();
    const [fetchContainers, { data: containers }] = useLazyQuery(FETCH_CONTAINERS_FOR_EDIT);

    const gridRef = useRef<GridOptions>();
    const gridRefForBooking = useRef<GridOptions>();

    const [selectedContainers, setSelectedContainers] = useState<any[]>([]);
    // const [isNextActionDisabled, setIsNextActionDisabled] = useState<boolean>(true);

    const [splitShipment, { data: plannedShipmentData }] = useMutation(SPLIT_SHIPMENT);

    useEffect(() => {
      setGlobalView(getGlobalView(shipment, showMatchingContainers));
    }, [shipment, showMatchingContainers]);

    useEffect(() => {
      if (containers?.fetch_basic_containers_details_by_ids) {
        setSelectedContainers(containers?.fetch_basic_containers_details_by_ids);
        setValidateShipmentCreation(true);
      }
    }, [containers, triggerUseEffect]);

    useEffect(() => {
      if (plannedShipmentData?.split_shipment) {
        message.success('Created shipment successfully!');
        window.open('/form/new_shipment/' + plannedShipmentData?.split_shipment?.id, '_blank');
        onSuccess && onSuccess();
      }
    }, [plannedShipmentData, onSuccess]);

    useEffect(() => {
      if (allowCreateShipment) {
        if ((shipment?.ocean_transport_orders || []).length > 0) {
          const containerWithGrp = _groupBy(
            selectedContainers.map((c) => ({
              ...c,
              ocean_transport_order_id: c?.ocean_transport_order_ids?.[0],
            })) || [],
            'ocean_transport_order_id'
          );
          if (
            Object.keys(containerWithGrp).map((id) => ({
              ocean_transport_order_id: id,
              shipment_container_ids: containerWithGrp[id].map((c) => c.id),
            })).length > 0
          ) {
            splitShipment({
              variables: {
                customer_order_id: shipment?.id,
                job_date: jobDate,
                source_booking_id: selectedBookingId,
                shipment_type: shipmentType,
                requested_containers: Object.keys(containerWithGrp).map((id) => ({
                  ocean_transport_order_id: id,
                  shipment_container_ids: containerWithGrp[id].map((c) => c.id),
                })),
                action,
              },
            });
          }
        }
        // incase required without oto...
        //  else {
        //   createShipmentFromPlanned({
        //     variables: {
        //       customer_order_id: shipment?.id,
        //       job_date: jobDate,
        //       source_booking_id: selectedBookingId,
        //       shipment_type: shipmentType,
        //       shipment_container_ids: selectedContainers.map((sc) => sc.id),
        //     },
        //   });
        // }
      }
    }, [
      allowCreateShipment,
      selectedBookingId,
      splitShipment,
      jobDate,
      selectedContainers,
      shipment,
      shipmentType,
      action,
    ]);

    const redirectToCustomerOrderForm = () => {
      let selectedContainersIds: string[] =
        gridRef?.current?.api
          ?.getSelectedNodes()
          .map((node: any) => node.data.shipment_containers_container_number?.extra_fields?.id) ||
        [];
      if (action !== 'split' && shipment?.trade_type === TRADE_TYPE_IMPORT) {
        selectedContainersIds = (shipment?.shipment_containers || []).map((sc: any) => sc.id);
      }
      fetchContainers({ variables: { ids: selectedContainersIds } });
      setTriggerUseEffect(!triggerUseEffect);
    };

    return (
      <>
        {[LOAD_TYPE_LCL].includes(shipment?.load_type || '') ||
        ([TRADE_TYPE_IMPORT].includes(shipment?.trade_type || '') && action === 'create') ? (
          <Modal
            open={true}
            title="Create Shipment"
            okText="Create Shipment"
            onOk={() => {
              if ([LOAD_TYPE_LCL].includes(shipment?.load_type || '')) {
                splitShipment({
                  variables: {
                    customer_order_id: shipment?.id,
                    job_date: jobDate,
                    shipment_type: shipmentType,
                    action: 'create',
                  },
                });
              } else {
                setAllowCreateShipment(true);
                redirectToCustomerOrderForm();
              }
            }}
            onCancel={onClose}
          >
            <Form.Item label="Job Date">
              <DatePicker value={jobDate} onChange={setJobDate} maxDate={dayjs()} />
            </Form.Item>
          </Modal>
        ) : (
          <Drawer
            placement="right"
            title={
              <div>
                <Row>
                  <Col span={16}>
                    <div
                      style={{
                        color: '#353F52',
                        fontSize: '18px',
                        fontWeight: 500,
                        marginBottom: '10px',
                      }}
                    >
                      <CompassOutlined />
                      <span style={{ marginLeft: '5px' }}>
                        {action === 'split' ? <b>Split Shipment</b> : <b>Create Shipment</b>}
                      </span>
                    </div>
                  </Col>
                </Row>
              </div>
            }
            footer={
              <DrawerFooter
                saveText={action === 'split' ? 'Split Shipment' : 'Create Shipment'}
                closeText="Cancel"
                onClose={() => onClose && onClose()}
                onSave={redirectToCustomerOrderForm}
              />
            }
            onClose={onClose}
            open={true}
            headerStyle={{ padding: '16px 16px 0px 24px' }}
            width="80%"
          >
            <div>
              <Form.Item label="Job Date">
                <DatePicker value={jobDate} onChange={setJobDate} maxDate={dayjs()} />
              </Form.Item>
              {[LOAD_TYPE_FCL].includes(shipment?.load_type || '') && shipment && globalView && (
                <>
                  {action === 'create' && (
                    <Checkbox
                      checked={showMatchingContainers}
                      onChange={(e) => setShowMatchingContainers(e.target.checked)}
                    >
                      Show Matching Customer Orders...
                    </Checkbox>
                  )}
                  <GlobalViewLayout
                    key={JSON.stringify(globalView.definition.filters)}
                    gridRef={gridRef}
                    doc_type_id={'Shipment::Shipment'}
                    globalView={globalView}
                    reportConfig={{
                      rowSelection: 'multiple',
                      defaultColDef: {
                        headerComponentParams: {
                          disableActions: {
                            remove: true,
                            pin: true,
                            rename: true,
                            filter: true,
                            sort: true,
                            group: true,
                          },
                        },
                      },
                    }}
                    defaultFilters={[]}
                    height="70vh"
                    searchPanelVisible={false}
                    showCheckBoxOnHeader={true}
                  />
                </>
              )}
            </div>
            {validateShipmentCreation && (
              <ValidateShipmentCreation
                shipment={shipment}
                selectedContainers={containers?.fetch_basic_containers_details_by_ids}
                onClose={() => {
                  setValidateShipmentCreation(false);
                }}
                handleSubmit={() => setAllowCreateShipment(true)}
                setSelectedBookingId={setSelectedBookingId}
                gridRef={gridRefForBooking}
              />
            )}
          </Drawer>
        )}
      </>
    );
  }
);

export default CreateShipmentFromExportCustomerOrder;
