import React from 'react';
import BasicInfoRender, { InfoDataValue } from '../BasicInfoRenderer';
import { ROUTING_TYPE_MAIN_CARRIAGE } from 'operations/models/RoutingLeg';
import { convertToStringFormat, DEFAULT_DATE_FORMAT } from '@shipmnts/pixel-hub';
import { OceanTransportOrderValue } from 'operations/models/OceanTransportOrder';

const OTOBasicInfo = React.memo(function OTOBasicInfo(props: {
  oto: OceanTransportOrderValue | undefined;
}): JSX.Element {
  const { oto } = props;
  if (!oto) return <></>;
  const mcrl = oto.carriageWiseRoutingLegs[ROUTING_TYPE_MAIN_CARRIAGE];
  const eta = mcrl?.[mcrl.length - 1]?.estimated_time_of_arrival;
  const etd = mcrl?.[0].estimated_time_of_departure;

  const basicInfoData: InfoDataValue[] = [
    { label: 'Booking Number', value: oto.booking_number },
    { label: 'Port of Loading', value: mcrl?.[0].origin?.location?.name },
    { label: 'Vendor', value: oto.vendorName },

    { label: 'ETD', value: (etd && convertToStringFormat(etd, DEFAULT_DATE_FORMAT)) || '-' },
    { label: 'Vessel', value: mcrl?.[0].vessel?.name },
    {
      label: 'Port of Discharge',
      value: mcrl?.[mcrl.length - 1].destination?.location?.name,
    },
    { label: 'Voyage', value: mcrl?.[0].voyage_number },
    { label: 'ETA', value: (eta && convertToStringFormat(eta, DEFAULT_DATE_FORMAT)) || '-' },
    { label: 'Order By', value: oto.salesPersonName },
    { label: 'Created By', value: oto.createdByName },
  ];

  return (
    <>
      <BasicInfoRender
        title="Basic Info"
        noOfColumns={2}
        layout="horizontal"
        showColon={false}
        labelStyle={{ color: 'var(--text-primary-gray-1)', minWidth: '40%' }}
        descriptionItemStyle={{ paddingBottom: '3px' }}
        displayData={basicInfoData}
      />
    </>
  );
});

export default OTOBasicInfo;
