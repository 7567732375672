import React, { useEffect } from 'react';
// import { useEmailDrawerContext } from 'operations/components/ApplicationLayoutContent';
// import { FETCH_CREDIT_EVENTS } from '../../DetailLayout/Overview/ShipmentCreditStatusTimeLine';
import { gql, useQuery } from '@apollo/client';
import { Attachment, LinkedParentType } from '@shipmnts/pixel-hub';
import { useEmailDrawerContext } from 'common';

export interface EmailProps {
  title: string;
  action_name?: string;
  resource_ids: string[];
  fetchDocumentParents?: LinkedParentType[];
  companies_roles_mapping?: any[];
  onClose?: () => void;
  showEmail?: boolean | undefined;
  showMarkDown?: boolean | undefined;
  customContent?: string;
  docTypeId?: string;
  fetchDocuments?: () =>
    | Promise<{
        error: any;
        documents: Array<Attachment>;
      }>
    | undefined;
  defaultEmails?: {
    recipient_emails?: string[];
    cc_emails?: string[];
  };
  isForward?: boolean;
}

export const changeEventEmail = (payload: {
  id: string;
  setVisible: (visible: boolean) => void;
  setEmailProps: (EmailProps: EmailProps) => void;
  title?: string;
  action_name?: string;
  docTypeId?: string;
  showEmail?: boolean | undefined;
  showMarkDown?: boolean | undefined;
  companies_roles_mapping?: any[];
}) => {
  const {
    id,
    setVisible,
    setEmailProps,
    title,
    action_name,
    docTypeId,
    showMarkDown,
    showEmail,
    companies_roles_mapping,
  } = payload;
  if (id) {
    setEmailProps({
      title: title || 'Send Email Notification',
      action_name: action_name,
      resource_ids: [id],
      onClose: () => {
        setVisible(false);
      },
      showMarkDown: showMarkDown,
      showEmail: showEmail,
      docTypeId: docTypeId,
      companies_roles_mapping: companies_roles_mapping || [],
    });
  }

  setVisible(true);
};
export const RecentEventEmailDrawer = React.memo(function RecentEventEmailDrawer(payload: {
  id: string;
  companies_roles_mapping?: any[];
  setIsFirstLoad?: (isFirstLoad: boolean) => void;
}): JSX.Element {
  const { id, setIsFirstLoad, companies_roles_mapping } = payload;
  const { data } = useQuery(FETCH_CREDIT_EVENTS, {
    variables: { id: id },
    fetchPolicy: 'network-only',
  });
  const { setVisible, setEmailProps } = useEmailDrawerContext();
  useEffect(() => {
    if (data) {
      if (setIsFirstLoad) setIsFirstLoad(false);
      const events = data?.shipment?.credit_events;
      if (!events || !(events?.length > 0)) return;
      const sorted_events = [...events].sort((a, b) => b.event_date - a.event_date);
      changeEventEmail({
        id: sorted_events?.[0]?.id,
        setVisible,
        setEmailProps,
        showMarkDown: true,
        showEmail: true,
        action_name: 'shipment_credit_status_changed',
        companies_roles_mapping: companies_roles_mapping,
      });
    }
  }, [companies_roles_mapping, data, id, setEmailProps, setIsFirstLoad, setVisible]);
  return <></>;
});
export const FETCH_CREDIT_EVENTS = gql`
  query shipment($id: ID!) {
    shipment(id: $id) {
      id
      credit_events {
        id
        event_by {
          id
          first_name
          last_name
        }
        event_date
        name
        event_data
        event_type
      }
    }
  }
`;
