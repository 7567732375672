import { useMutation } from '@apollo/client';
import { message } from '@shipmnts/pixel-hub';
import { UPSERT_WMS_PRODUCT } from 'operations/modules/warehouseManagement/graphql/product';
import React, { useEffect } from 'react';

export default function WmsProductActions(props: any) {
  const { wmsProduct, payload, onSuccess } = props;

  //   const [upsertTemplate, { data: upsertData, error: upsertError }] = useMutation(UPSERT_TEMPLATE);
  const [upsertWmsProduct, { data, error }] = useMutation(UPSERT_WMS_PRODUCT);

  useEffect(() => {
    if (data && !error) {
      message.success(
        `Product ${data?.upsert_wms_product?.disabled ? 'disabled' : 'enabled'} successfully.`
      );
      if (onSuccess) {
        onSuccess();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, error]);

  useEffect(() => {
    if (wmsProduct && wmsProduct.id && wmsProduct.id !== 'new') {
      upsertWmsProduct({
        variables: {
          product: {
            id: wmsProduct.id,
            ...payload,
          },
        },
      });
    }
  }, [payload, upsertWmsProduct, wmsProduct]);

  return <></>;
}
