import React from 'react';
import { AddressCompanySearch } from 'common';
import {
  Row,
  Col,
  Input,
  Form,
  Radio,
  Select,
  DatePicker,
  GlobalSearch,
  message,
  FormInstance,
} from '@shipmnts/pixel-hub';
import {
  BOOKING_TYPE_SHIPPING_LINE,
  LOAD_TYPE_LCL,
  BOOKING_TYPE_SELF,
  LOAD_TYPE_FCL,
  BOOKING_TYPE_VENDOR,
} from 'operations/baseConstants';
import { CarrierType } from 'operations/models/Carrier';
import { BOOKING_TYPE_MAP } from 'common/baseConstants';
import {
  FREIGHT_TYPE_OCEAN,
  OCEAN_TRANSPORT_ORDER_RATE_TYPES,
  SHIPPING_LINE_SERVICE_TYPES,
  TRADE_TYPE_EXPORT,
  TRADE_TYPE_IMPORT,
} from '../../constants';
import { RadioOptionProps, SelectOption } from 'operations/commonTypeDefs';
import { InquiryOptionValue } from 'operations/models/InquiryOption';
import BookingOrderDetails from 'operations/modules/booking/components/BookingOrderForm/BookingOrderDetails';
import { useApolloClient } from '@apollo/client';
import { getIsSelectedCarrierMatchingWithVoyageSchedule } from 'operations/modules/helpers';
import { useFormResponsive, VIEW_TYPE_SPAN } from './FormResponsiveContext';
import GenAIInputWrapper from './FormFiller/GenAIInputWrapper';
import BookingOrderNumber from 'operations/modules/booking/components/BookingOrderForm/BookingOrderNumber';

const ROW_GUTTER = 16;

const BookingDetailsShipmentFrom = (props: {
  disabledFields?: Array<string>;
  form: FormInstance;
  inquiryOption?: InquiryOptionValue;
  type?: string;
}) => {
  const { disabledFields, form, type } = props;
  const services = Form.useWatch('services', form);
  const isForwarding = services?.freight_forwarding;

  const client = useApolloClient();
  const { viewType } = useFormResponsive();

  const COL_SPAN = viewType ? VIEW_TYPE_SPAN[viewType] : 6;

  return (
    <>
      <Row gutter={ROW_GUTTER}>
        <Form.Item noStyle dependencies={['freight_type', 'trade_type', 'load_type', 'services']}>
          {({ getFieldValue }) => {
            const freight_type = getFieldValue('freight_type');
            const trade_type = getFieldValue('trade_type');
            const load_type = getFieldValue('load_type');

            if (
              freight_type === FREIGHT_TYPE_OCEAN &&
              trade_type === TRADE_TYPE_EXPORT &&
              isForwarding
            ) {
              return (
                <>
                  <Col span={COL_SPAN}>
                    <Form.Item
                      name="booking_type"
                      label="Booking Type"
                      required={
                        freight_type === FREIGHT_TYPE_OCEAN &&
                        trade_type === TRADE_TYPE_EXPORT &&
                        isForwarding
                      }
                    >
                      <GenAIInputWrapper fieldKey={'booking_type'}>
                        <Radio.Group
                          disabled={
                            load_type !== LOAD_TYPE_FCL ||
                            trade_type === TRADE_TYPE_IMPORT ||
                            type === 'update'
                          }
                          optionType="button"
                        >
                          {Object.entries(BOOKING_TYPE_MAP).map(([key, value], index) => (
                            <Radio
                              key={index}
                              value={key}
                              disabled={
                                load_type !== LOAD_TYPE_FCL ||
                                trade_type === TRADE_TYPE_IMPORT ||
                                type === 'update'
                              }
                            >
                              {value}
                            </Radio>
                          ))}
                        </Radio.Group>
                      </GenAIInputWrapper>
                    </Form.Item>
                  </Col>
                </>
              );
            } else return <></>;
          }}
        </Form.Item>

        <Col span={COL_SPAN}>
          <Form.Item
            noStyle
            dependencies={['load_type', 'booking_type', 'freight_type', 'trade_type']}
          >
            {({ getFieldValue }) => {
              const load_type = getFieldValue('load_type');
              const booking_type = getFieldValue('booking_type');
              const freight_type = getFieldValue('freight_type');
              const trade_type = getFieldValue('trade_type');

              const carrier_types: CarrierType[] = ['ocean', 'nvocc'];
              if (load_type === LOAD_TYPE_LCL) carrier_types.push('coloader');

              return (
                <Form.Item
                  required={
                    booking_type === BOOKING_TYPE_SHIPPING_LINE &&
                    freight_type === FREIGHT_TYPE_OCEAN &&
                    trade_type === TRADE_TYPE_EXPORT &&
                    isForwarding
                  }
                  rules={[
                    {
                      required:
                        booking_type === BOOKING_TYPE_SHIPPING_LINE &&
                        freight_type === FREIGHT_TYPE_OCEAN &&
                        trade_type === TRADE_TYPE_EXPORT &&
                        isForwarding,
                    },
                  ]}
                  name="carrier"
                  label={load_type === LOAD_TYPE_LCL ? 'Carrier/Co-loader' : 'Carrier'}
                >
                  <GenAIInputWrapper fieldKey={['carrier', 'name']}>
                    <GlobalSearch
                      doc_type="Global::Carrier"
                      disabled={disabledFields?.includes('carrier')}
                      searchProps={{ carrier_type: carrier_types }}
                      onChange={async (value) => {
                        const voyage_schedule_id = form.getFieldValue('voyage_schedule_id');
                        if (voyage_schedule_id) {
                          const isMatch = await getIsSelectedCarrierMatchingWithVoyageSchedule(
                            client,
                            voyage_schedule_id,
                            value?.id
                          );
                          if (!isMatch) {
                            form.setFieldValue('voyage_schedule_id', null);
                            message.info(
                              'Carrier changed, please select matching voyage schedule again'
                            );
                          }
                        }
                      }}
                    />
                  </GenAIInputWrapper>
                </Form.Item>
              );
            }}
          </Form.Item>
        </Col>
        <Form.Item
          noStyle
          dependencies={['booking_type', 'freight_type', 'trade_type', 'services']}
        >
          {({ getFieldValue }) => {
            const booking_type = getFieldValue('booking_type');
            const freight_type = getFieldValue('freight_type');
            const trade_type = getFieldValue('trade_type');
            // required for shipping line ocean export forwarding
            const required =
              booking_type === BOOKING_TYPE_SHIPPING_LINE &&
              freight_type === FREIGHT_TYPE_OCEAN &&
              trade_type === TRADE_TYPE_EXPORT &&
              isForwarding;

            if (booking_type === BOOKING_TYPE_SELF) return <></>;

            return (
              <Col span={COL_SPAN}>
                <BookingOrderNumber required={required} label={'Booking Number'} form={form} />
              </Col>
            );
          }}
        </Form.Item>
        <Form.Item
          noStyle
          dependencies={['booking_type', 'freight_type', 'trade_type', 'load_type']}
        >
          {({ getFieldValue }) => {
            const booking_type = getFieldValue('booking_type');
            const freight_type = getFieldValue('freight_type');
            const trade_type = getFieldValue('trade_type');
            // required for shipping line ocean export forwarding
            const required =
              booking_type === BOOKING_TYPE_SHIPPING_LINE &&
              freight_type === FREIGHT_TYPE_OCEAN &&
              trade_type === TRADE_TYPE_EXPORT &&
              isForwarding;
            if (freight_type === FREIGHT_TYPE_OCEAN && isForwarding) {
              return (
                <Col span={COL_SPAN}>
                  <Form.Item
                    rules={[
                      {
                        required: required,
                      },
                    ]}
                    name="booking_date"
                    label="Booking Date"
                  >
                    <GenAIInputWrapper fieldKey={'booking_date'}>
                      <DatePicker style={{ width: '100%' }} />
                    </GenAIInputWrapper>
                  </Form.Item>
                </Col>
              );
            } else return <></>;
          }}
        </Form.Item>
        <Form.Item noStyle dependencies={['freight_type', 'trade_type']}>
          {({ getFieldValue }) => {
            const freight_type = getFieldValue('freight_type');
            const trade_type = getFieldValue('trade_type');

            if (freight_type === FREIGHT_TYPE_OCEAN && isForwarding) {
              return (
                <>
                  <Col span={COL_SPAN}>
                    <Form.Item
                      rules={[
                        {
                          required:
                            freight_type === FREIGHT_TYPE_OCEAN &&
                            trade_type === TRADE_TYPE_EXPORT &&
                            isForwarding,
                        },
                      ]}
                      label="Service Type"
                      name={'service_type'}
                    >
                      <GenAIInputWrapper fieldKey={'service_type'}>
                        <Select placeholder="Select Port to Port, ICD to Port...">
                          {SHIPPING_LINE_SERVICE_TYPES.map(
                            (option: RadioOptionProps, index: number) => (
                              <Select.Option key={index} value={option.key}>
                                {option.name}
                              </Select.Option>
                            )
                          )}
                        </Select>
                      </GenAIInputWrapper>
                    </Form.Item>
                  </Col>
                </>
              );
            } else return <></>;
          }}
        </Form.Item>

        <Form.Item noStyle dependencies={['load_type', 'booking_type']}>
          {({ getFieldValue }) => {
            const loadType = getFieldValue('load_type');
            const bookingType = getFieldValue('booking_type');
            const carrier_types: CarrierType[] = ['ocean', 'nvocc'];
            if (loadType === LOAD_TYPE_LCL) carrier_types.push('coloader');
            if (bookingType === BOOKING_TYPE_VENDOR) {
              return (
                <Col span={COL_SPAN}>
                  <Form.Item name="vendor" label="Booking Forwarder/Vendor">
                    <GenAIInputWrapper fieldKey={'vendor'}>
                      <AddressCompanySearch
                        addressSearchProps={{
                          showAddBranchAction: true,
                        }}
                      />
                    </GenAIInputWrapper>
                  </Form.Item>
                </Col>
              );
            }
            return <></>;
          }}
        </Form.Item>
        <Form.Item
          noStyle
          shouldUpdate={(prevValues, currentValues) =>
            prevValues.confirmed_booking !== currentValues.confirmed_booking ||
            prevValues.routing_details !== currentValues.routing_details ||
            prevValues.booking_type !== currentValues.booking_type ||
            prevValues.trade_type !== currentValues.trade_type ||
            prevValues.load_type !== currentValues.load_type
          }
        >
          {({ getFieldValue }) => {
            const confirmed_booking = getFieldValue('confirmed_booking');
            const bookingType = getFieldValue('booking_type');
            const tradeType = getFieldValue('trade_type');
            const loadType = getFieldValue('load_type');
            return (
              <BookingOrderDetails
                form={form}
                bookingType={bookingType}
                loadType={loadType}
                areCutoffRequired={confirmed_booking && bookingType === BOOKING_TYPE_SHIPPING_LINE}
                tradeType={tradeType}
                isForwarding={isForwarding}
                colSpan={COL_SPAN}
              />
            );
          }}
        </Form.Item>
        <Form.Item noStyle dependencies={['booking_type']}>
          {({ getFieldValue }) => {
            const booking_type = getFieldValue('booking_type');
            if (booking_type !== BOOKING_TYPE_SELF)
              return (
                <>
                  <Col span={24 / 4}>
                    <Form.Item name="rate_type" label="Rate Type">
                      <Select placeholder="Rate Type">
                        {OCEAN_TRANSPORT_ORDER_RATE_TYPES.map(
                          (option: SelectOption, index: number) => (
                            <Select.Option key={index} label={option.label} value={option.value}>
                              {option.label}
                            </Select.Option>
                          )
                        )}
                      </Select>
                    </Form.Item>
                  </Col>
                </>
              );
            else return <></>;
          }}
        </Form.Item>
        <Form.Item noStyle dependencies={['freight_type']}>
          {({ getFieldValue }) => {
            const freight_type = getFieldValue('freight_type');
            if (freight_type === FREIGHT_TYPE_OCEAN && isForwarding)
              return (
                <>
                  <Col span={COL_SPAN}>
                    <Form.Item name="contract_number" label="RA Number">
                      <GenAIInputWrapper fieldKey={'contract_number'}>
                        <Input placeholder="RA number" />
                      </GenAIInputWrapper>
                    </Form.Item>
                  </Col>
                </>
              );
            else return <></>;
          }}
        </Form.Item>
      </Row>
    </>
  );
};

export default BookingDetailsShipmentFrom;
