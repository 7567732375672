import React, { useState, useEffect, useCallback } from 'react';
import {
  Layout,
  Button,
  Card,
  message,
  Skeleton,
  Typography,
  Checkbox,
  Form,
} from '@shipmnts/pixel-hub';
import { PageHeader } from '@shipmnts/pixel-hub';
import { useLocation } from 'wouter';
import { useMutation, useApolloClient } from '@apollo/client';
import { UPSERT_COMPANY, GET_COMPANY } from 'network/graphql/company';
import AddressFormContent, { AddressFormValues } from './AddressFormContent';
import ContactFormContent from './ContactFormContent';
import {
  useSession,
  errorMessageHandlerGraphQL,
  isCustomer,
  isVendor,
  getUpsertTeamPayload,
} from 'common';
import { AppHelmet, hasPermission } from '@shipmnts/pixel-hub';
import { pick as _pick } from 'lodash';
import { CompanyValue, CompanyAccountsData, COMPANY_STATUS_APPROVED } from 'network/models/Company';
import { getERPNextCompanyData } from 'network/erpnextApis';
import { FormInstance } from '@shipmnts/pixel-hub';
import { SessionDataValue } from 'network/models/SessionData';
import { CompanyAccountValue } from 'network/models/CompanyAccount';
import { ContactValue } from '../models/Contact';
import CompanyFormBasicDetails from './CompanyFormBasicDetails';
import SupplierLedgerDetailsForm from './SupplierLedgerDetailsForm';
import CustomerLedgerDetailsForm from './CustomerLedgerDetailsForm';
import CreditControlDetailsForm from './CreditControlDetailsForm';
// import { CompanyEmailPreferencesFormValues } from './CompanyEmailPreferencesForm';

import {
  PERMISSION_CUSTOMER_CREATE_EDIT,
  PERMISSION_SUPPLIER_CREATE_EDIT,
} from 'network/permissions';
import { INVOICE_TYPE_REGULAR, INVOICE_TYPE_EXPORT_IMPORT } from 'network/regional/IN/constants';
import { COMPANY_TYPE_COMPANY, COMPANY_GROUP_OVERSEAS_AGENT } from 'network/constants';
import { deserializeQueryParams } from 'shipmnts_navigation';
import { useErpNextConfig } from 'network';
import { CompanyEmailPreferencesFormValues } from './CompanyEmailPreferencesForm';
import { ERPNextContextType } from 'network/utils/ErpNextConfigDataWrapper';
import { ContactFormValue, upsertContactPayload } from './ContactForm';
import { SalesPersonValue } from 'common/models/SalesPerson';
import { WithPermission, Permission } from '@shipmnts/pixel-hub';
import { getCompanyStage, getCompanyType } from 'common/baseHelper';
import { TeamFormTable } from 'common';
import { TeamValue } from 'common/models/Team';

const { Content, Header } = Layout;
const { Text } = Typography;

interface UpsertCompanyNullableValues {
  receivable_credit_control_type?: string | null;
  receivable_payment_terms?: string | null;
  receivable_tax_withholding_category?: string | null;
  payable_payment_terms?: string | null;
  payable_tax_withholding_category?: string | null;
  default_currency?: string | null;
  global_company_account?: CompanyAccountValue | null;
}
export interface UpsertCompanyFormValues extends UpsertCompanyNullableValues {
  id?: string;
  registered_name?: string;
  company_group?: string;
  country_of_incorporation?: string;
  company_identification_number?: string | null;
  tax_registration_number?: string | null;
  tax_deduction_id?: string | null;
  receivable_accounts?: Array<{ company?: string; account?: string }>;
  payable_accounts?: Array<{ company?: string; account?: string }>;
  company_email_preferences?: Array<CompanyEmailPreferencesFormValues>;
  sales_person?: SalesPersonValue;
  address?: AddressFormValues;
  contact?: ContactFormValue;
  receivable_credit_limit?: number;
  is_msme?: number;
  credit_overdue_limit?: number;
  is_customer?: boolean | null;
  is_vendor?: boolean | null;
  is_lead?: boolean | null;
  status?: string | null;
  sales_partner?: CompanyValue | null;
  company_type?: string | null;
  domain?: string | null;
  pipeline?: string | null;
  pipeline_status?: string | null;
  teams?: Array<TeamValue> | undefined;
}

interface PayloadType extends Partial<UpsertCompanyFormValues> {
  addresses?: Array<AddressFormValues>;
  contacts?: Array<ContactValue>;
  global_company_account_id?: string | null;
  sales_partner_id?: string | null;
  company_stage?: string | null;
  pipeline_id?: string | null;
  current_state_id?: string | null;
  teams?: any; //need to use any type, as id is required in TeamValue, and TeamPayloadType
}
type DestroyInterface<T> = T & {
  _destroy?: boolean;
};

const FIELD_ACCESS_PERSMISSION: Record<string, Permission> = {
  is_customer: { name: PERMISSION_CUSTOMER_CREATE_EDIT, docType: 'Network::Company' },
  is_vendor: { name: PERMISSION_SUPPLIER_CREATE_EDIT, docType: 'Network::Company' },
};

export const getUpsertCompanyPayload = (
  formValues: UpsertCompanyFormValues,
  initialValues?: UpsertCompanyFormValues
) => {
  const {
    address,
    contact,
    global_company_account,
    sales_partner,
    is_customer,
    is_lead,
    is_vendor,
    sales_person,
    credit_overdue_limit,
    pipeline,
    pipeline_status,
    teams,
    ...values
  } = formValues;

  let company_type = initialValues?.company_type;

  // if there is a change in any of these fields
  if (is_customer !== undefined || is_lead !== undefined || is_vendor !== undefined)
    company_type = getCompanyType(is_customer, is_vendor, is_lead, initialValues?.company_type);

  const is_valid_contact =
    !!contact?.first_name && (!!contact?.email || !!contact?.mobile_number?.number);

  if (
    !is_valid_contact &&
    (!!contact?.first_name || !!contact?.email || !!contact?.mobile_number?.number)
  ) {
    message.error('Kindly add email id or mobile number and first name in contact details.');
    throw new Error('Kindly add email id or mobile number in contact details.');
  }

  const payload: PayloadType = {
    ...values,
    pipeline_id: formValues?.pipeline,
    current_state_id: formValues?.pipeline_status,
    global_company_account_id: global_company_account?.id,
    contacts: is_valid_contact ? [upsertContactPayload(contact)] : undefined,
    sales_partner_id: sales_partner?.id || null,
    company_type,
    company_stage: getCompanyStage(initialValues?.company_type, company_type),
  };

  if (initialValues?.credit_overdue_limit || credit_overdue_limit) {
    payload.credit_overdue_limit = credit_overdue_limit ?? initialValues?.credit_overdue_limit;
  }

  if (!formValues.id && address?.print_address) {
    const address_entity_type = address?.is_billing ? 'billing' : 'shipping';
    const city_id = address?.city?.id;
    delete address?.city;
    delete address?.is_billing;
    payload.addresses = [
      {
        ...address,
        entity_type: address_entity_type,
        city_id,
      },
    ];
  } else {
    // If 0 length then too should go in if.., to delete the last row.
    if (
      formValues.hasOwnProperty('company_email_preferences') &&
      initialValues?.company_email_preferences
    ) {
      const company_email_preferences = formValues.company_email_preferences || [];
      if (initialValues?.company_email_preferences) {
        const old_company_email_preferences = initialValues?.company_email_preferences || [];
        old_company_email_preferences.forEach((old_pref: any) => {
          if (
            !company_email_preferences.find(
              (pref: CompanyEmailPreferencesFormValues) => pref.id === old_pref.id
            )
          )
            company_email_preferences.push({
              id: old_pref.id,
              _destroy: true,
            } as DestroyInterface<CompanyEmailPreferencesFormValues>);
        });
      }
      const updated_company_email_preferences: Array<
        DestroyInterface<CompanyEmailPreferencesFormValues>
      > = [];
      // Filter out rows having email ids.
      company_email_preferences.forEach((pref) => {
        if (
          (!pref.to_emails || pref.to_emails?.length === 0) &&
          (!pref.cc_emails || pref.cc_emails?.length === 0)
        ) {
          // Do not push to array if not already saved in backend.
          pref.id &&
            updated_company_email_preferences.push({
              id: pref.id,
              _destroy: true,
            });
        } else {
          updated_company_email_preferences.push({
            ...pref,
            address_id: pref.address_id || null,
          });
        }
      });
      payload.company_email_preferences = updated_company_email_preferences;
    }

    const nullableKeys: Array<keyof UpsertCompanyNullableValues> = [
      'default_currency',
      'receivable_payment_terms',
      'receivable_tax_withholding_category',
      'payable_payment_terms',
      'payable_tax_withholding_category',
    ];
    nullableKeys.forEach((key) => {
      if (key in (initialValues || {}) && !payload[key]) {
        payload[key] = null;
      }
    });
    if (
      'global_company_account' in (initialValues || {}) &&
      !payload['global_company_account_id']
    ) {
      payload['global_company_account_id'] = null;
    }
  }

  // teams are not present on leads, only one sales_person is allowed on leads,
  if (sales_person) {
    payload.teams = [
      {
        id: initialValues?.teams?.filter((team: TeamValue) => team.role === 'sales')?.[0]?.id,
        role: 'sales',
        sales_person_id: sales_person.id,
        reference_type: 'Network::Company',
      },
    ];
  }

  // handles the case when teams are present on the form
  if (formValues.hasOwnProperty('teams')) {
    payload.teams = getUpsertTeamPayload(
      teams,
      'Network::Company',
      initialValues?.id,
      initialValues?.teams
    ).teams;
  }

  return payload;
};

const renderAccountsSection = (
  account_type: 'receivable' | 'payable',
  erpnextContext: ERPNextContextType,
  form: FormInstance,
  showCancel: boolean
) => {
  const {
    fetching: fetchingConfigData,
    erpnextConfigData,
    fetchError: fetchConfingDataError,
    fetchConfigData,
  } = erpnextContext;
  const cust_supp = account_type === 'receivable' ? 'Customer' : 'Supplier';
  const cust_supp_name = account_type === 'receivable' ? 'is_customer' : 'is_vendor';
  return (
    <Card
      title={
        <Form.Item name={cust_supp_name} valuePropName="checked">
          <Checkbox
            onChange={(e) => {
              if (account_type === 'receivable')
                form.setFieldsValue({
                  receivable_credit_control_type: 'Automatic',
                  comopany_status: COMPANY_STATUS_APPROVED,
                  sales_team: [{}], //todo-team : should we remove this?
                });

              if (form.getFieldValue('is_customer'))
                form.setFieldsValue({
                  is_lead: true,
                });
              if (!form.getFieldValue('is_customer') && form.getFieldValue('is_vendor'))
                form.setFieldsValue({
                  is_lead: false,
                });
            }}
            style={{ fontSize: '16px' }}
          >
            {cust_supp} Ledger Details
          </Checkbox>
        </Form.Item>
      }
    >
      <Form.Item noStyle dependencies={[cust_supp_name]}>
        {({ getFieldValue }) => {
          const cust_supp_name_value = getFieldValue(cust_supp_name);
          if (!cust_supp_name_value) return <></>;
          if (fetchingConfigData) return <Skeleton loading />;
          if (fetchConfingDataError)
            return (
              <span>
                <Text type="danger">Unable to fetch Accounts Data. {fetchConfingDataError}</Text>
                <Button size="small" type="link" onClick={fetchConfigData}>
                  Retry
                </Button>
              </span>
            );
          if (!erpnextConfigData) {
            return <Text type="danger">Unable to fetch ERP Data. {fetchConfingDataError}</Text>;
          }

          if (account_type === 'receivable')
            return <CustomerLedgerDetailsForm erpnextConfigData={erpnextConfigData} />;
          return <SupplierLedgerDetailsForm erpnextConfigData={erpnextConfigData} />;
        }}
      </Form.Item>
    </Card>
  );
};

interface InitialCompanyValue extends Partial<CompanyValue> {
  accounts_data?: CompanyAccountsData;
  is_customer?: boolean;
  is_lead?: boolean;
  is_vendor?: boolean;
}

const getFormInitialData = (initialCompany?: InitialCompanyValue, session?: SessionDataValue) => {
  if (!initialCompany) {
    const tenant_country_code = session?.company_account?.default_company?.country_of_incorporation;
    return {
      country_of_incorporation: tenant_country_code,
      is_msme: 0,
      entity_type: COMPANY_TYPE_COMPANY,
      address: {
        preferred_invoice_type: INVOICE_TYPE_REGULAR,
      },
    };
  }
  const { accounts_data } = initialCompany;
  const formPayload = {
    ..._pick(initialCompany, [
      'id',
      'registered_name',
      'company_group',
      'country_of_incorporation',
      'company_identification_number',
      'entity_type',
      'is_customer',
      'is_vendor',
      'is_lead',
      'company_type',
    ]),
    ..._pick(accounts_data, [
      'default_currency',
      'tax_registration_number',
      'tax_deduction_id',
      'receivable_accounts',
      'receivable_credit_limit',
      'is_msme',
      'credit_overdue_limit',
      'receivable_credit_control_type',
      'receivable_payment_terms',
      'receivable_tax_withholding_category',
      'payable_payment_terms',
      'payable_tax_withholding_category',
      'payable_accounts',
      'sales_team', //todo-team : should we remove this - erp me to sales team hi rahega?
      'status',
    ]),
    is_msme: 0,
    status: initialCompany.status,
  };
  return formPayload;
};
interface CompanyFormProps {
  renderedFromCompanySearch?: boolean;
  onSuccess?: (value: CompanyValue) => void;
  initialParams?: InitialCompanyValue;
}
const CompanyForm = React.memo(function CompanyForm(props: CompanyFormProps) {
  const { renderedFromCompanySearch, onSuccess, initialParams } = props;
  const { 1: navigate } = useLocation();
  // const params = useParams<{ id: string }>();
  // const { id: company_id } = params;
  const filterQueryParamsByPermission = (
    queryParams: Record<string, any>,
    session: SessionDataValue
  ) => {
    const fiteredQueryParams: Record<string, any> = {};
    const query_fields = Object.keys(queryParams);
    for (const field of query_fields) {
      const permissions = FIELD_ACCESS_PERSMISSION[field];
      if (!permissions) {
        fiteredQueryParams[field] = queryParams[field];
        continue;
      }
      // FIX ME
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (session.permissions && hasPermission(session.permissions, permissions))
        fiteredQueryParams[field] = queryParams[field];
    }

    return fiteredQueryParams;
  };
  const company_id = 'new';

  const session = useSession();
  const queryParams = deserializeQueryParams(window.location?.search.substring(1));
  const filterParams = filterQueryParamsByPermission(queryParams, session);
  const didMountRef = React.useRef(false);

  const update_intial_compnay = (
    initial_company: InitialCompanyValue | undefined,
    is_lead_prop: boolean | undefined
  ) => {
    if (!initial_company) return;
    if (
      isCustomer(initial_company?.company_type) &&
      isVendor(initial_company?.company_type) &&
      company_id === 'new'
    )
      initial_company.company_group = COMPANY_GROUP_OVERSEAS_AGENT;

    if (is_lead_prop) initial_company.is_lead = true;
    if (initial_company?.is_customer) initial_company.is_lead = true;
  };

  const initial_company: InitialCompanyValue | undefined = window.history?.state?.company
    ? {
        ...window.history?.state?.company,
        accounts_data: window.history?.state?.companyAccountsData,
      }
    : renderedFromCompanySearch
    ? initialParams
    : { ...filterParams };

  update_intial_compnay(initial_company, initialParams?.is_lead);

  const [initialCompany, setInitialCompany] = useState<InitialCompanyValue | undefined>(
    initial_company
  );

  useEffect(() => {
    if (didMountRef.current) {
      setInitialCompany(initial_company);
      form.setFieldsValue(initial_company);
    }
    didMountRef.current = true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const erpnextContext = useErpNextConfig();
  const { fetching: fetchingConfigData, fetchError: fetchConfingDataError } = erpnextContext;

  const [upsertCompany, { data, loading, error }] = useMutation(UPSERT_COMPANY);
  const [fetchingCompany, setFetchingCompany] = useState(false);
  const [fetchCompanyError, setFetchCompanyError] = useState<boolean | string>(false);

  const [form] = Form.useForm();
  const client = useApolloClient();
  const tenant_country_code = session?.company_account?.default_company?.country_of_incorporation;
  const initialValues = getFormInitialData(initialCompany, session);

  const fetchCompany = useCallback(async () => {
    setFetchingCompany(true);
    setFetchCompanyError(false);
    const { data, errors } = await client.query({
      query: GET_COMPANY,
      variables: { id: company_id },
    });
    if (data && data.company) {
      const company = data.company;
      if (isCustomer(company?.company_type) || isVendor(company?.company_type)) {
        const { response, error } = await getERPNextCompanyData(company.registered_name);
        if (response) {
          const accountsData = response.data?.message?.company;
          const initial_company = { ...company, accounts_data: accountsData };
          setInitialCompany(initial_company);
          const formData = getFormInitialData(initial_company);
          form.setFieldsValue(formData);
        } else if (error) {
          if (error instanceof Error) setFetchCompanyError(error.message);
        }
      }
    } else if (errors) {
      setFetchCompanyError('Error while fetching company');
    }
    setFetchingCompany(false);
  }, [company_id, client, form]);

  useEffect(() => {
    if (company_id !== 'new' && !initialCompany) {
      fetchCompany();
    }
  }, [company_id, initialCompany, fetchCompany]);

  const onCompanyCreated = useCallback(() => {
    message.success('Company saved successfully!');
    navigate(`~/view/company/${data.upsert_company?.company?.id}?first_load=1`, {
      replace: true,
      state: {
        company: data.upsert_company?.company,
        company_accounts_data: data.upsert_company?.company_accounts_data,
      },
    });
  }, [data, navigate]);

  useEffect(() => {
    if (error) {
    }
    onSuccess?.(data?.upsert_company?.company);
    if (data?.upsert_company && !renderedFromCompanySearch) {
      onCompanyCreated();
    }
  }, [data, error, navigate, renderedFromCompanySearch, onSuccess, onCompanyCreated]);
  return (
    <Form
      form={form}
      onFinish={(values) => {
        const payload = getUpsertCompanyPayload(values, initialValues);
        if (!payload) return;
        if (payload?.is_msme !== undefined) payload.is_msme = payload.is_msme ? 1 : 0;
        upsertCompany({ variables: { company: payload } });
      }}
      scrollToFirstError
      name="company"
      layout="vertical"
      style={{
        height: '100%',
      }}
      initialValues={initialValues}
      onValuesChange={(changedValues, allValues) => {
        if (
          tenant_country_code === 'IN' &&
          (Object.keys(changedValues).includes('country_of_incorporation') ||
            (Object.keys(changedValues).includes('address') &&
              Object.keys(changedValues.address).includes('is_billing')))
        ) {
          form.setFieldsValue({
            address: {
              preferred_invoice_type:
                allValues.country_of_incorporation !== tenant_country_code
                  ? INVOICE_TYPE_EXPORT_IMPORT
                  : INVOICE_TYPE_REGULAR,
            },
          });
        }
      }}
    >
      <Layout
        style={{ maxWidth: '1200px', margin: 'auto', backgroundColor: 'white' }}
        className="form-layout"
      >
        <AppHelmet>
          <title>
            {company_id === 'new'
              ? 'New Company'
              : `Edit details for ${initialCompany?.registered_name}`}
          </title>
        </AppHelmet>
        <Header style={{ padding: 0, background: 'none', height: 'auto' }}>
          <PageHeader
            style={{ padding: '10px 0px' }}
            onBack={
              renderedFromCompanySearch
                ? undefined
                : () => {
                    window.history.back();
                  }
            }
            title={
              company_id === 'new'
                ? 'New Company'
                : `Edit details for ${initialCompany?.registered_name}`
            }
            extra={[
              <Form.Item noStyle dependencies={['is_customer', 'is_vendor']} key="save">
                {() => {
                  return (
                    <Button
                      loading={loading}
                      disabled={
                        fetchingConfigData ||
                        ((form.getFieldValue('is_customer') || form.getFieldValue('is_vendor')) &&
                          !!fetchConfingDataError) ||
                        !!fetchCompanyError ||
                        fetchingCompany ||
                        initialCompany?.is_disabled
                      }
                      htmlType="submit"
                      type="primary"
                    >
                      Save
                    </Button>
                  );
                }}
              </Form.Item>,
            ]}
          >
            {error && errorMessageHandlerGraphQL(error)}
            {fetchCompanyError && (
              <span>
                <Text type="danger">{fetchCompanyError}</Text>{' '}
                <Button onClick={fetchCompany} type="link">
                  Retry
                </Button>
              </span>
            )}
          </PageHeader>
        </Header>
        <Content>
          {fetchingCompany && (
            <>
              <Card>
                <Skeleton avatar paragraph={{ rows: 3 }} loading={fetchingCompany} active />
              </Card>
              <Card>
                <Skeleton avatar paragraph={{ rows: 3 }} loading={fetchingCompany} active />
              </Card>
              <Card>
                <Skeleton avatar paragraph={{ rows: 3 }} loading={fetchingCompany} active />
              </Card>
            </>
          )}
          {!fetchingCompany && !fetchCompanyError && (
            <>
              <Card title="Basic Details">
                <CompanyFormBasicDetails form={form} company_id={company_id} />
              </Card>
              <>
                <WithPermission
                  permission={{
                    name: PERMISSION_CUSTOMER_CREATE_EDIT,
                    docType: 'Network::Company',
                  }}
                >
                  <>
                    {renderAccountsSection(
                      'receivable',
                      erpnextContext,
                      form,
                      !isCustomer(initialCompany?.company_type)
                    )}
                    <Form.Item noStyle dependencies={['is_customer', 'is_lead']}>
                      {({ getFieldValue }) => {
                        return (
                          erpnextContext?.erpnextConfigData && (
                            <>
                              {getFieldValue('is_customer') && (
                                <Card title="Credit Control Details">
                                  <CreditControlDetailsForm form={form} />
                                </Card>
                              )}
                              {(getFieldValue('is_customer') || getFieldValue('is_lead')) && (
                                <Card title="Team Details">
                                  <Typography.Text type="secondary">
                                    Handling branch specifies the branch of your company for which
                                    following team members are handling this customer. If kept empty
                                    then it means that these team member will be handling this
                                    customers business from all branches.
                                  </Typography.Text>
                                  <TeamFormTable reference_type="Network::Company" form={form} />
                                </Card>
                              )}
                            </>
                          )
                        );
                      }}
                    </Form.Item>
                  </>
                </WithPermission>
                <WithPermission
                  permission={{
                    name: PERMISSION_SUPPLIER_CREATE_EDIT,
                    docType: 'Network::Company',
                  }}
                >
                  {renderAccountsSection(
                    'payable',
                    erpnextContext,
                    form,
                    !isVendor(initialCompany?.company_type)
                  )}
                </WithPermission>
              </>
              {company_id === 'new' && (
                <Card title="Address">
                  <Form.Item
                    noStyle
                    dependencies={[
                      'country_of_incorporation',
                      'is_customer',
                      'is_vendor',
                      'is_lead',
                    ]}
                  >
                    {({ getFieldValue }) => {
                      return (
                        <AddressFormContent
                          fieldName="address"
                          form={form}
                          address_id={'new'}
                          is_lead={getFieldValue('is_lead')}
                          country_code={getFieldValue('country_of_incorporation')}
                          is_customer_or_supplier={
                            !!getFieldValue('is_customer') || !!getFieldValue('is_vendor')
                          }
                        />
                      );
                    }}
                  </Form.Item>
                </Card>
              )}

              {company_id === 'new' && (
                <Card title="Contact">
                  <ContactFormContent is_mandatory={false} form={form} fieldName="contact" />
                </Card>
              )}
            </>
          )}
        </Content>
      </Layout>
    </Form>
  );
});

export default CompanyForm;
