import { FIELD_TYPE_BUY, FIELD_TYPE_SELL } from 'operations/constants';
import {
  FREIGHT_TYPE_AIR,
  FREIGHT_TYPE_OCEAN,
  TRADE_TYPE_EXPORT,
  TRADE_TYPE_IMPORT,
} from 'operations/modules/shipment/constants';

export const DOC_TYPE_SALES_INVOICE = 'Sales Invoice';
export const DOC_TYPE_PURCHASE_INVOICE = 'Purchase Invoice';

export const DOC_TYPE_JOURNAL_ENTRY = 'Journal Entry';

export const DOC_TYPE_INVOICE_MAPPING = {
  [DOC_TYPE_PURCHASE_INVOICE]: FIELD_TYPE_BUY,
  [DOC_TYPE_SALES_INVOICE]: FIELD_TYPE_SELL,
};

export const FREIGHT_CHARGE_AIR_EXPORT = 'Air Export Freight';
export const FREIGHT_CHARGE_AIR_IMPORT = 'Air Import Freight';
export const FREIGHT_CHARGE_OCEAN_EXPORT = 'Ocean Export Freight';
export const FREIGHT_CHARGE_OCEAN_IMPORT = 'Ocean Import Freight';

export const ALL_FREIGHT_ITEMS = [
  FREIGHT_CHARGE_AIR_EXPORT,
  FREIGHT_CHARGE_AIR_IMPORT,
  FREIGHT_CHARGE_OCEAN_EXPORT,
  FREIGHT_CHARGE_OCEAN_IMPORT,
];

export const ITEM_CHARGE_MAPPING = {
  [FREIGHT_TYPE_AIR]: {
    [TRADE_TYPE_EXPORT]: FREIGHT_CHARGE_AIR_EXPORT,
    [TRADE_TYPE_IMPORT]: FREIGHT_CHARGE_AIR_IMPORT,
  },
  [FREIGHT_TYPE_OCEAN]: {
    [TRADE_TYPE_EXPORT]: FREIGHT_CHARGE_OCEAN_EXPORT,
    [TRADE_TYPE_IMPORT]: FREIGHT_CHARGE_OCEAN_IMPORT,
  },
};

export const NATURE_OF_TRANSACTION_JOB = 'Job';
export const NATURE_OF_TRANSACTION_INTERBRANCH = 'Inter Branch';
export const NATURE_OF_TRANSACTION_CONSOLIDATED = 'Consolidated';
