import React, { useEffect, useCallback } from 'react';
import { useApplicationContentContext, useSession } from 'common';
import { useApolloClient } from '@apollo/client';
import { downloadPDF, useDocumentPrintHelper } from '@shipmnts/pixel-hub';

type Props = {
  document_id: string;
  onClose: () => void;
  document_name: string;
};

const DocumentPdfGenerator = (props: Props) => {
  const sessionData = useSession();
  const client = useApolloClient();
  const { document_id, onClose, document_name } = props;
  const { config_data } = useApplicationContentContext();
  const { fetchSourceData } = useDocumentPrintHelper();

  const generatePdf = useCallback(async () => {
    const response = await fetchSourceData({ document_id, sessionData, client, config_data });
    if ('getHTML' in response) {
      const html = response.getHTML();
      if (html)
        await downloadPDF(
          {
            html,
            file_name: document_name,
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          },
          process.env.DOCGEN_URL || ''
        );
    }
    if (onClose) onClose();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client, config_data, document_id, document_name, onClose, sessionData]);

  useEffect(() => {
    generatePdf();
  }, [generatePdf]);

  return <span></span>;
};

export default DocumentPdfGenerator;
