import React from 'react';
import { useContactView } from '../ContactView';
import { ActivityFeed } from '@shipmnts/pixel-hub';

export const ActivityTab = () => {
  const { contact } = useContactView();
  if (!contact) return <></>;
  return (
    <div
      style={{
        width: '100%',
        marginInline: 'auto',
        marginBottom: '2em',
      }}
    >
      <ActivityFeed
        resource={{ ...contact, displayName: contact?.pretty_display }}
        extraClassname={'tab'}
      />
    </div>
  );
};
