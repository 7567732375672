import React from 'react';
import { AddressCompanySearch } from 'common';
import { Row, Col, InputNumber, Form, dayjs, Dayjs, DatePicker } from '@shipmnts/pixel-hub';
import { getCutoffDateDisableFunction } from 'operations/modules/booking/helpers/CutoffDateHelper';
import { LOAD_TYPE_LCL, LOAD_TYPE_FCL } from 'operations/baseConstants';
import { BOOKING_TYPE_SELF } from 'operations/baseConstants';
import GenAIInputWrapper from 'operations/modules/shipment/components/NewShipmentForm/FormFiller/GenAIInputWrapper';
import {
  VIEW_TYPE_SPAN,
  useFormResponsive,
} from 'operations/modules/shipment/components/NewShipmentForm/FormResponsiveContext';
import { RoutingLegValue } from 'operations/models/RoutingLeg';
const ROW_GUTTER = 16;

const cutoffDateDisable = getCutoffDateDisableFunction();

const BookingOrderConfirmation = React.memo(function BookingOrderConfirmation(props: {
  etd: Dayjs | undefined | null;
  areCutoffRequired?: boolean;
  disableCutoff?: { [key: string]: boolean };
  load_type?: string;
  stuffing_location_type?: string;
  disableEmptyPickupLocation?: boolean;
  booking_type?: string | null;
}): JSX.Element {
  const { etd, areCutoffRequired, disableCutoff, load_type, booking_type } = props;
  const estimated_time_of_departure = etd;

  const { viewType } = useFormResponsive();
  const COL_SPAN = viewType ? VIEW_TYPE_SPAN[viewType] : 12;

  return (
    <>
      <Row gutter={ROW_GUTTER}>
        <Col span={12}>
          <Row gutter={ROW_GUTTER}>
            <Col span={12}>
              {load_type !== LOAD_TYPE_LCL && (
                <>
                  {/* <Form.Item
                required={areCutoffRequired}
                rules={[{ required: areCutoffRequired }]}
                name="valid_till_date"
                label="Validity Date"
              >
                <DatePicker
                  disabledDate={(current: Dayjs) =>
                    cutoffDateDisable['valid_till_date'](current, { estimated_time_of_departure })
                  }
                  style={{ width: '100%' }}
                  defaultOpenValue={dayjs()}
                />
              </Form.Item> */}
                  <Form.Item
                    noStyle
                    shouldUpdate={(prevValues, currentValues) =>
                      prevValues.gate_close_date !== currentValues.gate_close_date ||
                      prevValues.si_cutoff_date !== currentValues.si_cutoff_date
                    }
                  >
                    {({ getFieldValue }) => {
                      const gate_close_date = getFieldValue('gate_close_date');
                      return (
                        <Form.Item
                          required={areCutoffRequired}
                          rules={[{ required: areCutoffRequired }]}
                          name="gate_open_date"
                          label="Gate Open Cutoff"
                        >
                          <DatePicker
                            disabledDate={(current: Dayjs) =>
                              cutoffDateDisable['gate_open_date'](
                                current,
                                { estimated_time_of_departure },
                                { gate_close_date }
                              )
                            }
                            showTime
                            style={{ width: '100%' }}
                            disabled={disableCutoff?.gate_open_date}
                            defaultOpenValue={dayjs()}
                          />
                        </Form.Item>
                      );
                    }}
                  </Form.Item>
                </>
              )}
            </Col>
            <Col span={12}>
              <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) =>
                  prevValues.gate_open_date !== currentValues.gate_open_date
                }
              >
                <Form.Item
                  required={areCutoffRequired}
                  rules={[{ required: areCutoffRequired }]}
                  name="si_cutoff_date"
                  label="SI cutoff"
                >
                  <DatePicker
                    disabledDate={(current: Dayjs) =>
                      cutoffDateDisable['si_cutoff_date'](current, { estimated_time_of_departure })
                    }
                    showTime
                    style={{ width: '100%' }}
                    disabled={disableCutoff?.si_cutoff_date}
                    defaultOpenValue={dayjs()}
                  />
                </Form.Item>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={ROW_GUTTER}>
            <Col span={12}>
              <Form.Item name="vgm_cutoff_date" label="VGM Cutoff Date">
                <DatePicker
                  disabledDate={(current: Dayjs) =>
                    cutoffDateDisable['vgm_cutoff_date'](current, { estimated_time_of_departure })
                  }
                  disabled={disableCutoff?.vgm_cutoff_date}
                  showTime={{ defaultOpenValue: dayjs() }}
                  style={{ width: '100%' }}
                  defaultOpenValue={dayjs()}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item noStyle dependencies={['routing_details']}>
                {({ getFieldValue }) => {
                  const routing_legs = getFieldValue('routing_details')?.routing_legs || [];
                  const routing_nodes = getFieldValue('routing_details')?.routing_nodes || [];

                  const pre_carriage_legs = routing_legs.find(
                    (rl: RoutingLegValue) => rl.routing_type === 'pre_carriage'
                  );
                  const main_carriage_legs = routing_legs.find(
                    (rl: RoutingLegValue) => rl.routing_type === 'main_carriage'
                  );

                  const pocr_location_id = pre_carriage_legs?.origin_id;
                  const pol_location_id = main_carriage_legs?.origin_id;

                  const pocr_location = routing_nodes[pocr_location_id];
                  const pol_location = routing_nodes[pol_location_id];

                  const icd_enabled =
                    pocr_location &&
                    pol_location &&
                    pocr_location?.location &&
                    pol_location?.location &&
                    pocr_location?.location.id !== pol_location?.location.id;

                  return (
                    <Form.Item name="icd_cutoff_date" label="ICD Cutoff Date">
                      <DatePicker
                        disabledDate={(current: Dayjs) =>
                          cutoffDateDisable['icd_cutoff_date'](current, {
                            estimated_time_of_departure,
                          })
                        }
                        disabled={!(icd_enabled || disableCutoff?.icd_cutoff_date)}
                        showTime={{ defaultOpenValue: dayjs() }}
                        style={{ width: '100%' }}
                        defaultOpenValue={dayjs()}
                      />
                    </Form.Item>
                  );
                }}
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={ROW_GUTTER}>
            <Col span={12}>
              <Form.Item name="origin_detention_free_days" label="Origin Detention Free Days">
                <InputNumber min={0} style={{ width: '100%' }} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="origin_demurrage_free_days" label="Origin Demurrage Free Days">
                <InputNumber min={0} style={{ width: '100%' }} />
              </Form.Item>
            </Col>
          </Row>
        </Col>

        <Col span={12}>
          {/* {load_type !== LOAD_TYPE_LCL && (
            <Form.Item
              rules={[{ required: false }]}
              label="Stuffing Cutoff Date"
              name={['stuffing_buffer_service_order', 'stuffing_cutoff_date']}
            >
              <DatePicker style={{ width: '100%' }} defaultOpenValue={dayjs()} />
            </Form.Item>
          )} */}

          <Row gutter={ROW_GUTTER}>
            <Col span={12}>
              {load_type !== LOAD_TYPE_LCL && (
                <>
                  {/* <Form.Item
                required={areCutoffRequired}
                rules={[{ required: areCutoffRequired }]}
                name="valid_till_date"
                label="Validity Date"
              >
                <DatePicker
                  disabledDate={(current: Dayjs) =>
                    cutoffDateDisable['valid_till_date'](current, { estimated_time_of_departure })
                  }
                  style={{ width: '100%' }}
                  defaultOpenValue={dayjs()}
                />
              </Form.Item> */}
                  <Form.Item
                    noStyle
                    shouldUpdate={(prevValues, currentValues) =>
                      prevValues.gate_open_date !== currentValues.gate_open_date
                    }
                  >
                    {({ getFieldValue }) => {
                      const gate_open_date = getFieldValue('gate_open_date');
                      return (
                        <Form.Item
                          required={areCutoffRequired}
                          rules={[{ required: areCutoffRequired }]}
                          name="gate_close_date"
                          label="Gate Close Cutoff"
                        >
                          <DatePicker
                            disabledDate={(current: Dayjs) =>
                              cutoffDateDisable['gate_close_date'](
                                current,
                                { estimated_time_of_departure },
                                { gate_open_date }
                              )
                            }
                            disabled={disableCutoff?.gate_close_date}
                            showTime={{ defaultOpenValue: dayjs() }}
                            style={{ width: '100%' }}
                            defaultOpenValue={dayjs()}
                          />
                        </Form.Item>
                      );
                    }}
                  </Form.Item>
                </>
              )}
            </Col>
            <Col span={12}>
              {load_type !== LOAD_TYPE_LCL && (
                <>
                  <Form.Item
                    required={areCutoffRequired}
                    rules={[{ required: areCutoffRequired }]}
                    name="doc_cutoff_date"
                    label="Docs Handover cutoff"
                  >
                    <DatePicker
                      disabledDate={(current: Dayjs) =>
                        cutoffDateDisable['doc_cutoff_date'](current, {
                          estimated_time_of_departure,
                        })
                      }
                      showTime
                      style={{ width: '100%' }}
                      disabled={disableCutoff?.doc_cutoff_date}
                      defaultOpenValue={dayjs()}
                    />
                  </Form.Item>
                </>
              )}
            </Col>
          </Row>

          <Col span={12}>
            <Form.Item name="ams_cutoff_date" label="AMS Cutoff Date">
              <DatePicker
                disabledDate={(current: Dayjs) =>
                  cutoffDateDisable['ams_cutoff_date'](current, { estimated_time_of_departure })
                }
                disabled={disableCutoff?.ams_cutoff_date}
                showTime={{ defaultOpenValue: dayjs() }}
                style={{ width: '100%' }}
                defaultOpenValue={dayjs()}
              />
            </Form.Item>
          </Col>

          <Row gutter={ROW_GUTTER}>
            <Col span={12}>
              <Form.Item
                name="destination_detention_free_days"
                label="Destination Detention Free Days"
              >
                <InputNumber min={0} style={{ width: '100%' }} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="destination_demurrage_free_days"
                label="Destination Demurrage Free Days"
              >
                <InputNumber min={0} style={{ width: '100%' }} />
              </Form.Item>
            </Col>
          </Row>
        </Col>
      </Row>
      {booking_type === BOOKING_TYPE_SELF && load_type === LOAD_TYPE_FCL && (
        <Row gutter={ROW_GUTTER}>
          <Col span={COL_SPAN}>
            <Form.Item name="surveyor" label="Surveyor">
              <GenAIInputWrapper fieldKey={'surveyor'}>
                <AddressCompanySearch
                  companySearchProps={{
                    searchProps: { is_vendor: true },
                  }}
                />
              </GenAIInputWrapper>
            </Form.Item>
          </Col>
        </Row>
      )}
    </>
  );
});

export default BookingOrderConfirmation;
