import React, { useEffect, useMemo, useRef } from 'react';
import { Form } from '@shipmnts/pixel-hub';

import { containerSettingsMap, LOAD_TYPE_FCL } from 'common/baseConstants';
import { useInquiryForm } from './InquiryForm';
import { isLiveReeferCargo } from 'sales_hub/models/Cargo';
import { ContainerDetails } from 'operations';
import { ContainerRequestValue } from 'sales_hub/models/ContainerRequest';
import CargoNewComponent from 'operations/modules/shipment/components/DetailLayout/Cargo/CargoNewComponent/CargoNewComponent';

const ContainerDetailsTable = () => {
  const { form, disable } = useInquiryForm();
  const containerRequestsRef = useRef<{ runValidation: () => boolean }>();

  const cargos = Form.useWatch('cargos', form);
  const isLiveReefer = useMemo(() => (cargos ? isLiveReeferCargo(cargos) : false), [cargos]);

  // is live reefer cargo is selected, clear other container details
  useEffect(() => {
    if (isLiveReefer) {
      const container_requests = form.getFieldValue('containerRequests');
      const new_container_requests = container_requests.map((cr: ContainerRequestValue) => {
        const new_cr = cr;
        if (
          cr.container_type_code &&
          !Object.keys(containerSettingsMap.reefer).includes(cr.container_type_code)
        ) {
          new_cr['container_type_code'] = undefined;
          new_cr['container_type'] = undefined;
          new_cr['container_settings'] = undefined;
        }
        return new_cr;
      });
      form.setFieldValue('containerRequests', new_container_requests);
    }
  }, [isLiveReefer, form]);

  return (
    <div style={{ marginBottom: '1em' }}>
      <Form.Item noStyle shouldUpdate>
        {({ getFieldValue }) => {
          const cargos = getFieldValue('cargos');
          const isLiveReefer = cargos ? isLiveReeferCargo(cargos) : false;

          return (
            <Form.Item
              name="containerRequests"
              noStyle
              rules={[
                {
                  validator: (rule, value) => {
                    if (!containerRequestsRef?.current?.runValidation()) {
                      return Promise.resolve();
                    }
                    return Promise.reject();
                  },
                },
              ]}
            >
              <ContainerDetails
                ref={containerRequestsRef}
                required_fields={['container_type_code', 'quantity']}
                isLiveReefer={isLiveReefer}
                required={false}
                disableAdd={disable}
                disableDelete={disable}
                disabled={disable}
              />
            </Form.Item>
          );
        }}
      </Form.Item>
    </div>
  );
};

const InquiryCargoDetails = () => {
  const { freightType, loadType, form, cargoRef } = useInquiryForm();

  const showContainerDetails = useMemo(() => loadType === LOAD_TYPE_FCL, [loadType]);

  return (
    <>
      <h3 className="inq-form-section-title">{`${
        showContainerDetails ? 'Container & ' : ''
      } Cargo Details`}</h3>
      {showContainerDetails && <ContainerDetailsTable />}
      <CargoNewComponent loadType={loadType} freightType={freightType} ref={cargoRef} form={form} />
    </>
  );
};

export default InquiryCargoDetails;
