import React from 'react';

import { Col, Row, Typography, UploadedDocuments } from '@shipmnts/pixel-hub';
import { useSession } from 'common';
import { WarehouseTransactionValue } from '../models/WarehouseTransaction';
interface DocumentProps {
  warehouseTransaction: WarehouseTransactionValue;
}
const DocumentTab = (props: DocumentProps) => {
  const { warehouseTransaction } = props;
  const session = useSession();
  if (!warehouseTransaction) return <></>;
  return (
    <div style={{ height: '300px' }}>
      <Typography.Title level={4} style={{ color: 'var(--color-primary)' }}>
        Uploaded Documents
      </Typography.Title>
      <Row>
        <Col sm={24} md={12}>
          <UploadedDocuments
            sessionData={session}
            parent_id={warehouseTransaction?.id}
            parent_type={warehouseTransaction?.type}
            docgen_url={`${process.env.DOCGEN_URL}`}
          />
        </Col>
      </Row>
    </div>
  );
};

export default DocumentTab;
