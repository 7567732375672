import React from 'react';
import { ExclamationCircleOutlined, Form, Input, Modal, Row } from '@shipmnts/pixel-hub';
import { toUpper as _toUpper } from 'lodash';

interface ConfirmCancelShipmentModalProps {
  showConfirmationModal?: any;
  setShowConfirmationModal?: any;
  form?: any;
  shipment: any;
}

const ConfirmCancelShipmentModal = React.memo(function ConfirmCancelShipmentModal(
  props: ConfirmCancelShipmentModalProps
): JSX.Element {
  const { setShowConfirmationModal, form, shipment } = props;
  const resource_display_string = shipment?.shipment_type ? 'Shipment' : 'Customer Order';
  const onCancel = () => {
    setShowConfirmationModal(false);
  };
  const onConfirm = () => {
    form.submit();
  };
  return (
    <Modal
      title={
        <Row>
          <ExclamationCircleOutlined
            style={{ color: '#ff4d4f', fontSize: '20px', marginRight: '8px' }}
          />
          <div
            style={{ fontSize: '15px' }}
          >{`Confirm ${resource_display_string} Cancellation`}</div>
        </Row>
      }
      open
      okText={`Confirm`}
      onOk={onConfirm}
      onCancel={onCancel}
      width={500}
      destroyOnClose
    >
      <div>
        <p>{`Are you sure you want to delete ${resource_display_string}?`}</p>
        <p>{`Type 'CANCEL ${_toUpper(resource_display_string)}' to proceed`}</p>
        <Form.Item
          name="confirmation"
          required
          rules={[
            { required: true },
            {
              validator: (_, value) => {
                if (
                  value &&
                  value.trim().toUpperCase() !== `CANCEL ${_toUpper(resource_display_string)}`
                ) {
                  return Promise.reject(
                    `The confirmation value must be "CANCEL ${_toUpper(resource_display_string)}".`
                  );
                }
                return Promise.resolve();
              },
            },
          ]}
        >
          <Input type={'text'} autoComplete="off" />
        </Form.Item>
      </div>
    </Modal>
  );
});

export default ConfirmCancelShipmentModal;
