import React, { useEffect, useRef, useState } from 'react';
import { Button, Card, Space, Tag, Switch } from '@shipmnts/pixel-hub';
import { SyncOutlined } from '@shipmnts/pixel-hub';
import { useApolloClient } from '@apollo/client';
import { get as _get } from 'lodash';
import { useSession } from 'common';
import { observer } from 'mobx-react-lite';
import { getEnv } from 'mobx-state-tree';
import { ShipmentEstimatesTotal } from 'common';
import { CANCELLED, WarehouseTransactionValue } from '../models/WarehouseTransaction';
import ChargeEstimatesLayoutWrapper from '../Estimates/ChargeEstimatesLayout';
import { callSync } from '../Estimates/helpers';
import {
  ShipmentEstimateStore,
  ShipmentEstimateStoreContextProvider,
  ShipmentEstimateStoreValue,
  useShipmentEstimateStore,
} from '../Estimates/ChargeEstimates';
import { FETCH_SHIPMENT_ESTIMATES } from 'operations/graphql/shipmentEstimate';
const SYNC_TIME_IN_SECONDS = 3;
export function useLocalStorage(key: string, initialValue: string | object) {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState(() => {
    try {
      // Get from local storage by key
      const item = window.localStorage.getItem(key);
      // Parse stored json or if none return initialValue
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      // If error also return initialValue
      console.error(error);
      return initialValue;
    }
  });

  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue = (value: string | object) => {
    try {
      // Allow value to be a function so we have same API as useState
      const valueToStore = value instanceof Function ? value(storedValue) : value;
      // Save state
      setStoredValue(valueToStore);
      // Save to local storage
      window.localStorage.setItem(key, JSON.stringify(valueToStore));
    } catch (error) {
      // A more advanced implementation would handle the error case
      console.error(error);
    }
  };

  return [storedValue, setValue];
}

function ShipmentEstimatesTab({
  warehouseTransaction,
}: {
  warehouseTransaction: WarehouseTransactionValue;
}) {
  const [displayMode, setDisplayMode] = useState('view');
  const config = {
    ignored: true,
  };
  const client = useApolloClient();
  const { permissions } = useSession();
  const session = useSession();
  const currentInterval = useRef<any>(null);

  const [loading, setLoading] = useState(true);
  const [initialDataFetched, setInitialDataFetched] = useState(false);
  const [fetchError, setFetchError] = useState(false);

  const store = useRef<ShipmentEstimateStoreValue>(
    ShipmentEstimateStore.create(
      { estimates: [], to_be_synced: [], sync_state: 'saved' },
      {
        session,
        permissions,
        showIgnored: config.ignored,
      }
    )
  );

  const fetchEstimateData = async () => {
    setLoading(true);
    store.current.clearEstimates();
    const { data, errors } = await client.query({
      query: FETCH_SHIPMENT_ESTIMATES,
      variables: {
        resource_ids: [warehouseTransaction.id],
        resource_type: 'Wms::WarehouseTransaction',
      },
      fetchPolicy: 'no-cache',
    });

    if (errors) {
      setFetchError(true);
    } else {
      store.current.replaceEstimates(_get(data, 'fetch_shipment_estimate_list', []));
      //clearing interval if already other sync is going on.
      if (currentInterval.current) {
        clearInterval(currentInterval.current);
      }
      //starting new sync
      currentInterval.current = setInterval(() => {
        if (store.current.sync_state === 'unsaved') callSync(store.current, client);
      }, SYNC_TIME_IN_SECONDS * 1000);
    }
    setLoading(false);
    setInitialDataFetched(true);
  };

  useEffect(() => {
    fetchEstimateData?.();
    getEnv(store.current).showIgnored = config.ignored;
    store.current.updateEstimatePermissions();
    // eslint-disable-next-line
  }, [config.ignored]);

  if (!warehouseTransaction) return null;
  if (fetchError)
    return (
      <Card>
        <p>Something went wrong. Please retry.</p>
      </Card>
    );
  if (!initialDataFetched) return <Card loading />;

  if (!store) return <div>Not Found</div>;

  return (
    <ShipmentEstimateStoreContextProvider
      value={{ store: store.current, fetchEstimateData, loading }}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          height: '100%',
          overflow: 'auto',
          width: '100%',
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
          {warehouseTransaction?.status !== CANCELLED && !warehouseTransaction?.handling_job && (
            <Switch
              checkedChildren="View"
              unCheckedChildren="Edit"
              className="estimates-switch"
              checked={displayMode === 'edit'}
              onClick={(checked) => {
                const displayMode = checked ? 'edit' : 'view';
                setDisplayMode(displayMode);
              }}
            />
          )}
          <SavingBanner />
        </div>

        <div style={{ flexGrow: 1, display: 'flex', justifyContent: 'center' }}>
          <ShipmentEstimatesTotal useStore={useShipmentEstimateStore} />
        </div>
      </div>

      <div style={{ height: 'calc(100% - 80px)', overflow: 'auto' }}>
        <ChargeEstimatesLayoutWrapper
          resource={warehouseTransaction}
          resource_type={'Wms::WarehouseTransaction'}
          displayMode={displayMode}
          showCurrencyTotals={true}
        />
      </div>
    </ShipmentEstimateStoreContextProvider>
  );
}

export default ShipmentEstimatesTab;

const SavingBanner = observer(function SavingBanner() {
  const { store } = useShipmentEstimateStore();
  const client = useApolloClient();
  const [time, setTime] = useState(true);

  useEffect(() => {
    if (store.sync_state === 'saved') {
      const timer = setTimeout(() => {
        setTime(true);
      }, 5000);
      return () => clearTimeout(timer);
    } else {
      setTime(false);
      return;
    }
  }, [store.sync_state]);

  if (store.sync_state === 'error') {
    return (
      <Space>
        <Tag type="critical">Error while saving estimates</Tag>
        <Button size="small" icon={<SyncOutlined />} onClick={() => callSync(store, client)} />
      </Space>
    );
  }

  if (store.sync_state === 'unsaved') {
    return <Tag type="warning-secondary">Unsaved changes</Tag>;
  }

  if (store.sync_state === 'saving') {
    return <Tag type="warning">Saving changes...</Tag>;
  }

  if (store.sync_state === 'saved' && !time) {
    return <Tag type="success">All changes saved</Tag>;
  }

  return <></>;
});
