import { BaseTable, Button } from '@shipmnts/pixel-hub';
import { Column } from 'operations/models/Report';
import React, { useState } from 'react';
import ProductPropertyDrawer from '../ProductPropertyDrawer';
import { WarehouseTransactionValue } from '../models/WarehouseTransaction';
import { omit } from 'lodash';
import {
  DimensionsWithUOM,
  QtyUomTypeRenderer,
} from 'common/report_manager/components/Renderer/cellRenderer';
interface WMSProductsProps {
  warehouseTransactionProducts: WarehouseTransactionValue[];
  type: string;
}

const WMSProducts = (props: WMSProductsProps) => {
  const { warehouseTransactionProducts, type } = props;
  const [showProductPropertyDrawer, setShowProductPropertyDrawer] = useState<boolean>(false);
  const [productPropertyParams, setProductPropertyParams] = useState<any>({});
  const tableData: any[] = [];

  if (warehouseTransactionProducts) {
    warehouseTransactionProducts?.forEach((element: any) => {
      tableData.push({
        ...omit(element, ['linked_to']),
        received_qty: type === 'receipt' ? element.qty + element.damage_qty : 0,
        // id: element?.linked_to?.warehouse_transaction?.id,
        // transaction_number: element?.linked_to?.warehouse_transaction?.transaction_number,
      });
    });
  }

  const openPropertyDrawer = (params: any) => {
    setProductPropertyParams(params);
    setShowProductPropertyDrawer(true);
  };

  const columns: Column[] = [
    // {
    //   headerName: 'Created From',
    //   field: 'transaction_number',
    //   colId: 'transaction_number',
    //   hide: type !== 'receipt' && type !== 'delivery',
    //   cellRenderer: (params: any) => {
    //     if (params.node.isRowPinned()) return <></>;
    //     return (
    //       <RenderLinkByDocType
    //         key={params?.node?.data?.id}
    //         doc_type_id={'Wms::WarehouseTransaction'}
    //         id={params?.node?.data?.id}
    //         value={params?.node?.data?.transaction_number}
    //         extra_fields={{}}
    //         colDef={undefined}
    //       />
    //     );
    //   },
    //   maxWidth: 150,
    //   editable: false,
    // },
    {
      headerName: 'Product Name',
      field: 'product_name',
      colId: 'product_name',
      cellEditor: 'SearchDocTypeEditor',
      editable: false,
      maxWidth: 300,
    },
    {
      headerName: 'Product Code',
      field: 'product_code',
      colId: 'product_code',
      columnType: 'String',
      editable: false,
    },
    ...(!['incoming_shipment', 'receipt'].includes(type)
      ? [
          {
            headerName: type === 'goods_transfer' ? 'From Location' : 'Location',
            field: 'from_location',
            colId: 'from_location',
            editable: false,
            valueFormatter: (params: any) => params.value?.node_name,
            valueGetter: (params: any) => params.data?.from_location?.node_name,
            maxWidth: 300,
            filter: 'agSetColumnFilter',
            keyCreator: (params: any) => params.value?.node_name,
            filterParams: {
              convertValuesToStrings: true,
            },
          },
        ]
      : []),
    ...(!['incoming_shipment', 'outgoing_shipment', 'delivery'].includes(type)
      ? [
          {
            headerName: type === 'goods_transfer' ? 'To Location' : 'Location',
            field: 'to_location',
            colId: 'to_location',
            editable: false,
            valueFormatter: (params: any) => {
              return params.value?.node_name;
            },
            valueGetter: (params: any) => params.data?.to_location?.node_name,
            maxWidth: 300,
            filter: 'agSetColumnFilter',
            keyCreator: (params: any) => params.value?.node_name,
            filterParams: {
              convertValuesToStrings: true,
            },
          },
        ]
      : []),
    {
      headerName: 'Packaging Type',
      field: 'packing_type',
      colId: 'packing_type',
      columnType: 'String',
      editable: false,
    },
    {
      headerName: type === 'receipt' ? 'Accepted Qty' : 'Qty',
      field: 'qty',
      colId: 'qty',
      columnType: 'Float',
      cellEditor: 'FloatEditor',
      cellRendererParams: {
        precision: 5,
      },
      editable: false,
    },
    ...(['incoming_shipment'].includes(type)
      ? [
          {
            headerName: 'Received Qty',
            field: 'issued_qty',
            colId: 'issued_qty',
            editable: false,
            cellEditor: 'FloatEditor',
            cellRendererParams: {
              precision: 5,
            },
          },
        ]
      : []),
    ...(['receipt'].includes(type)
      ? [
          {
            headerName: 'Received Qty',
            field: 'received_qty',
            colId: 'received_qty',
            editable: false,
            cellEditor: 'FloatEditor',
            cellRendererParams: {
              precision: 5,
            },
          },
        ]
      : []),
    {
      headerName: 'Pending Receipt',
      field: 'available_qty',
      colId: 'available_qty',
      columnType: 'Float',
      cellEditor: 'FloatEditor',
      cellRendererParams: {
        precision: 5,
      },
      editable: false,
      hide: type !== 'incoming_shipment',
    },
    {
      headerName: 'Rejected Qty',
      field: 'damage_qty',
      colId: 'damage_qty',
      columnType: 'Float',
      cellEditor: 'FloatEditor',
      cellRendererParams: {
        precision: 5,
      },
      editable: false,
      hide: type !== 'receipt',
    },
    {
      headerName: 'Rejected Location',
      field: 'damage_location',
      colId: 'damage_location',
      editable: false,
      valueFormatter: (params: any) => {
        return params.value?.node_name;
      },
      valueGetter: (params: any) => params.data?.damage_location?.node_name,
      maxWidth: 300,
      filter: 'agSetColumnFilter',
      keyCreator: (params: any) => params.value?.node_name,
      filterParams: {
        convertValuesToStrings: true,
      },
      hide: type !== 'receipt',
    },
    {
      hide: ['goods_transfer', 'outgoing_shipment', 'delivery'].includes(type),
      headerName: 'Dimensions (L X B X H)',
      field: 'dimensions',
      colId: 'dimensions',
      columnType: 'String',
      editable: false,
      cellRenderer: (params: any) => {
        if (params.node.isRowPinned()) return <></>;
        return (
          <DimensionsWithUOM
            length={params?.data?.length}
            breadth={params?.data?.breadth}
            height={params?.data?.height}
            dimension_unit={params?.data?.dimension_unit}
          />
        );
      },
    },
    {
      headerName: 'Gross Vol',
      field: 'gross_volume',
      colId: 'gross_volume',
      columnType: 'Float',
      editable: false,
      cellRenderer: (params: any) => {
        return <QtyUomTypeRenderer uom={'CBM'} qty={params?.data?.gross_volume} precision={5} />;
      },
    },
    {
      headerName: 'Gross Weight',
      field: 'gross_weight',
      colId: 'gross_weight',
      columnType: 'Float',
      editable: false,
      cellRenderer: (params: any) => {
        return (
          <QtyUomTypeRenderer
            uom={params?.data?.weight_unit || 'kgs'}
            qty={params?.data?.gross_weight}
            precision={5}
          />
        );
      },
    },
    {
      headerName: 'Vol. Weight',
      field: 'volumetric_weight',
      colId: 'volumetric_weight',
      columnType: 'Float',
      editable: false,
      cellRenderer: (params: any) => {
        return <QtyUomTypeRenderer uom={'kgs'} qty={params?.data?.volumetric_weight} />;
      },
    },
    {
      headerName: 'Ch. Weight',
      field: 'chargeable_weight',
      colId: 'chargeable_weight',
      columnType: 'Float',
      editable: false,
      cellRenderer: (params: any) => {
        return <QtyUomTypeRenderer uom={'kgs'} qty={params?.data?.chargeable_weight} />;
      },
    },
    {
      headerName: 'Batch #',
      field: 'batch_number',
      colId: 'batch_number',
      columnType: 'String',
      editable: false,
    },
    {
      headerName: 'Serial #',
      field: 'serial_number',
      colId: 'serial_number',
      columnType: 'String',
      editable: false,
    },
    {
      headerName: 'Properties',
      field: 'product_property',
      colId: 'product_property',
      cellRenderer: (params: any) => {
        if (params.node.isRowPinned()) return <></>;
        return <Button onClick={() => openPropertyDrawer(params)}>Show Property</Button>;
      },
      editable: false,
    },
    {
      headerName: 'Invoice #',
      field: 'invoice_number',
      colId: 'String',
      columnType: 'String',
      editable: false,
    },
    {
      hide: type === 'delivery' || type === 'goods_transfer',
      headerName: 'Invoice Date',
      field: 'invoice_date',
      colId: 'invoice_date',
      columnType: 'Date',
      cellEditor: 'DateEditor',
      editable: false,
    },
    {
      headerName: type === 'delivery' ? 'In Container #' : 'Container #',
      field: 'container_number',
      colId: 'container_number',
      columnType: 'String',
      editable: false,
    },
    {
      hide: type !== 'delivery',
      headerName: 'Out Container #',
      field: 'out_container_number',
      colId: 'out_container_number',
      columnType: 'String',
      editable: false,
    },
    {
      hide: type === 'goods_transfer',
      headerName: 'Container/Vehicle Type',
      field: 'container_type',
      colId: 'container_type',
      columnType: 'String',
      editable: false,
    },
    {
      headerName: 'Customs Ref #',
      field: 'customs_ref_code',
      colId: 'customs_ref_code',
      columnType: 'String',
      editable: false,
    },
    {
      hide: !['delivery', 'receipt'].includes(type),
      headerName: 'Seal #',
      field: 'seal_number',
      colId: 'seal_number',
      columnType: 'String',
      editable: false,
    },
    {
      headerName: 'Lot #',
      field: 'lot_number',
      colId: 'lot_number',
      columnType: 'String',
      editable: false,
    },
    {
      headerName: 'Country of Origin',
      field: 'country_of_origin',
      colId: 'country_of_origin',
      columnType: 'String',
      editable: false,
    },
    {
      headerName: 'Inner Qty per Outer Package',
      field: 'inner_by_outer_qty',
      colId: 'inner_by_outer_qty',
      columnType: 'Float',
      cellEditor: 'FloatEditor',
      cellRendererParams: {
        precision: 5,
      },
      editable: false,
      hide: type !== 'receipt',
    },
    {
      headerName: 'Prod. Date',
      field: 'prod_date',
      colId: 'prod_date',
      columnType: 'Date',
      cellEditor: 'DateEditor',
      editable: false,
    },
    {
      headerName: 'Exp. Date',
      field: 'exp_date',
      colId: 'exp_date',
      columnType: 'Date',
      cellEditor: 'DateEditor',
      editable: false,
    },
    {
      hide: type === 'goods_transfer',
      headerName: 'Remarks',
      field: 'remarks',
      colId: 'String',
      columnType: 'String',
      editable: false,
    },
  ];
  return (
    <>
      <ProductPropertyDrawer
        key={showProductPropertyDrawer}
        setShowProductPropertyDrawer={setShowProductPropertyDrawer}
        showProductPropertyDrawer={showProductPropertyDrawer}
        params={productPropertyParams}
        editable={false}
      />

      <BaseTable
        rowData={tableData || []}
        reportName={type || 'products'}
        height={250}
        columns={columns}
        reportConfig={{
          suppressCellFocus: false,
          suppressLastEmptyLineOnPaste: true,
          rowHeight: 40,
          stopEditingWhenCellsLoseFocus: true,
          defaultColDef: {
            resizable: true,
          },
          animateRows: true,
        }}
      />
    </>
  );
};

export default WMSProducts;
