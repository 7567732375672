import React, { useCallback, useEffect, useRef, useState } from 'react';

import { Icon, MailOutlined, CloseOutlined } from '@shipmnts/pixel-hub';
import { useLazyQuery } from '@apollo/client';
import { Button, Col, Row, Tooltip, dayjs, localizedFormat } from '@shipmnts/pixel-hub';
import { useEmailDrawerContext } from 'common';
// import { GET_COMPANY } from 'network/graphql/company'

import { eventToIconsMaping } from '../../constants';
import { TrackerSvg } from '../../Icons';
import { useTrackerContext } from '../../TrackingContext';
import { EventStateType, EventType } from '../../types';
import EventsForm from '../applications/EventsAndMilestones/EventsForm';
import { getEventInputTypes } from '../applications/EventsAndMilestones/helpers';
import { toUpper as _toUpper } from 'lodash';
import { gql } from '@apollo/client';
import ClearEvent from './ClearEvent';

import { salesPersonFields } from 'operations/graphql/salesPerson';

type SingleEventProps = {
  isAccordian?: boolean;
  event: EventType;
  editable?: boolean;
  onFormSubmit?: (success?: boolean) => void;
  lastOccuredEvent: EventType | undefined | null;
};

export const addressFields = gql`
  fragment addressFields on AddressObjectType {
    id
    name
    entity_type
    city {
      id
      name
    }
    city_name
    company_id
    address_line_1
    address_line_2
    postal_code
    print_address
    state_tax_code
    state_name
    state_code
    country_name
    country_code
    attn
    accounts_address_link
    is_disabled
    company {
      id
      registered_name
      company_type
      status
      country_of_incorporation
    }
  }
`;

export const companyFields = gql`
  fragment companyFields on CompanyObjectType {
    id
    status
    created_at
    updated_at
    registered_name
    country_of_incorporation
    company_type
    created_by {
      id
      first_name
      last_name
    }
    company_group
    entity_type
    logo
    is_disabled
    remarks
    company_identification_number
    tax_registration_number
    tax_deduction_id
    receivable_credit_control_type
    addresses {
      ...addressFields
    }
    company_email_preferences {
      id
      email_template_id
      address_id
      to_emails
      cc_emails
      address {
        id
        name
        city_name
        print_address
      }
      email_template {
        id
        template_name
        action_name
      }
    }
    global_company_account {
      id
      registered_name
    }
  }
  ${addressFields}
  ${salesPersonFields}
`;

export const GET_COMPANY = gql`
  query company($id: ID!) {
    company(id: $id) {
      ...companyFields
    }
  }
  ${companyFields}
`;

const SingleEvent = (props: SingleEventProps): JSX.Element => {
  const { isAccordian = true, event, editable, onFormSubmit, lastOccuredEvent } = props;
  dayjs.extend(localizedFormat); // to use local format

  const [getCompany, { data }] = useLazyQuery(GET_COMPANY);

  // Context
  const { editEventId, session, refData, wrapper, referenceType } = useTrackerContext();
  const { setVisible, setEmailProps } = useEmailDrawerContext();
  // Refs
  const eventRef = useRef<HTMLDivElement>(null);

  // States
  const [isCollapse, setisCollapse] = useState<boolean>(
    event?.id === undefined || event?.id == null ? true : event.id !== editEventId
  );
  const [editMode, setEditMode] = useState<boolean>(false);

  // Functions/Handlers
  const handleOnArrowClick = useCallback(() => {
    setisCollapse(!isCollapse);
    setEditMode(false);
  }, [isCollapse]);
  const handleOnEditClick = () => {
    setEditMode((prev) => !prev);
  };
  const closeForm = (success?: boolean) => {
    if (onFormSubmit && success) onFormSubmit(success);
    setEditMode(false);
  };
  const onEmailNotificationButton = () => {
    if (event.id) {
      setEmailProps({
        resource_ids: [event.id],
        action_name: 'tracking_event_notification',
        fetchDocumentParents: [
          {
            parent_type: 'tracking_event',
            parent_ids: [event.id],
          },
        ],
        docTypeId: 'Shipment::TrackingEvent',
        title: '',
        companies_roles_mapping: (
          refData?.company_role_list?.map((item: any) => ({
            ...item,
            id: item.company_id,
            registered_name: item.name,
          })) || []
        ).filter((i: any) => i.company_id),
        showMarkDown: true,
      });
      setVisible(true);
    }
  };

  // Constants
  let state: EventStateType = 'inactive';
  if (!lastOccuredEvent) {
    state = 'future';
  } else if (
    event.name === lastOccuredEvent.name &&
    ((!!event.current_location && event.current_location === lastOccuredEvent.current_location) ||
      (!!event?.location?.name && event?.location?.name === lastOccuredEvent?.location?.name))
  ) {
    state = 'active';
  } else if (
    (typeof event.sequence_number != undefined &&
      typeof lastOccuredEvent.sequence_number != undefined &&
      event?.sequence_number > lastOccuredEvent.sequence_number) ||
    !event.actual_date
  ) {
    state = 'future';
  }

  const actual_date = event.actual_date
    ? dayjs.unix(parseInt(event.actual_date)).format('llll')
    : '-';
  const estimated_date = event.estimated_date
    ? dayjs.unix(parseInt(event.estimated_date)).format('llll')
    : '-';

  const { fields } = getEventInputTypes(event, refData, session);
  const extra = [
    'actual_date',
    'estimated_date',
    'attachments',
    'location',
    'custom_clearance_location',
    'igm_location',
    'icd_igm_location',
    'movement_location',
    'transporter_company_id',
  ];
  const filteredFields = fields.filter((i) => !extra.includes(i.name));
  const transporter = fields.find((i) => i.name === 'transporter_company_id');
  // Effects
  useEffect(() => {
    if (editEventId === event.id) {
      eventRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, [editEventId, event.id]);
  useEffect(() => {
    if (transporter?.initialValue) getCompany({ variables: { id: transporter.initialValue } });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const eventName = event?.name;

  return (
    <>
      <div className={`single-event ${state}`} ref={eventRef}>
        <div className={`icon ${state}`}>
          {
            <Icon
              component={
                event.name
                  ? // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    eventToIconsMaping[event.name] || TrackerSvg
                  : TrackerSvg
              }
            />
          }
        </div>
        <div className={`event-name ${isCollapse ? '' : 'open'} ${state}`}>
          {isAccordian && !isCollapse
            ? eventName
            : (() => {
                if (event.actual_date) {
                  return `${eventName} - ${actual_date}`;
                } else if (event.estimated_date) {
                  return `${eventName} - ${estimated_date}`;
                } else {
                  return eventName;
                }
              })()}
        </div>
        {isAccordian && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              marginLeft: 'auto',
            }}
          >
            <Button
              icon={
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  transform={!isCollapse ? 'rotate(90)' : undefined}
                  className={`arrow ${!isCollapse ? 'tranform' : ''}`}
                >
                  <path
                    d="M6.44238 12.4425L9.87738 9L6.44238 5.5575L7.49988 4.5L11.9999 9L7.49988 13.5L6.44238 12.4425Z"
                    fill="var(--text-primary-gray-1)"
                  />
                </svg>
              }
              type={'text'}
              className="arrow"
              onClick={handleOnArrowClick}
            ></Button>
          </div>
        )}
      </div>
      {!isCollapse && (
        <div
          className={`single-event ${state} event-name open`}
          style={{
            paddingTop: '0px',
            paddingLeft: '38px',
            alignItems: 'flex-start',
          }}
        >
          {!editMode ? (
            <div className="detail-view">
              <div>{event?.location?.name}</div>
              <div style={{ marginTop: '6px' }}>Estimated Date: {estimated_date}</div>
              <div style={{ marginTop: '6px' }}>
                <span> Actual Date: {actual_date}</span>
                {event.event_type !== 'system' &&
                  editable &&
                  event.actual_date &&
                  state === 'active' &&
                  !editMode &&
                  wrapper !== 'NO_WRAPPER' && (
                    <ClearEvent event={event} onSuccess={closeForm}>
                      {({ loading, callClear }) => {
                        return (
                          <Tooltip title={'Undo this event and clear data'}>
                            <Button
                              loading={loading}
                              onClick={callClear}
                              danger
                              type="link"
                              icon={<CloseOutlined />}
                            ></Button>
                          </Tooltip>
                        );
                      }}
                    </ClearEvent>
                  )}
              </div>

              <Row gutter={[1, 2]} style={{ marginTop: '14px' }}>
                {filteredFields &&
                  filteredFields.map((item, index) => (
                    <Col span={12} key={index}>
                      <div className="field-name">{item.label}</div>
                      <div className="field-value">{_toUpper(item.initialValue || '-')}</div>
                    </Col>
                  ))}
                {data?.company && (
                  <Col span={12}>
                    <div className="field-name">Transporter Name</div>
                    <div className="field-value">{data?.company?.registered_name || '-'}</div>
                  </Col>
                )}
              </Row>

              {event.event_type !== 'system' && isAccordian && event.actual_date && (
                <Row style={{ marginTop: '14px' }}>
                  <Col span={24}>
                    <Button icon={<MailOutlined />} onClick={onEmailNotificationButton}>
                      Send Email Notification
                    </Button>
                  </Col>
                </Row>
              )}
              {event.updated_at && (
                <div style={{ marginTop: '14px' }}>
                  Last updated by{' '}
                  <span style={{ color: 'var(--color-primary)' }}>{event.last_updated_by}</span>,{' '}
                  {dayjs(parseInt(event.updated_at) * 1000).format('ddd, MMM D, h:mm A')}
                </div>
              )}
            </div>
          ) : (
            <div style={{ paddingBottom: '14px' }}>
              <EventsForm
                event={event}
                afterSubmit={closeForm}
                onDiscard={closeForm}
                session={session}
                refData={refData}
                referenceType={referenceType}
              />
            </div>
          )}
          {event.event_type !== 'system' && editable && !editMode && wrapper !== 'NO_WRAPPER' && (
            <Button type={'text'} onClick={handleOnEditClick} className="edit-btn">
              Edit
            </Button>
          )}
        </div>
      )}
    </>
  );
};

export default SingleEvent;
