import {
  applySnapshot,
  detach,
  getSnapshot,
  IAnyModelType,
  Instance,
  types,
} from 'mobx-state-tree';
import ShipmentEstimate, { ShipmentEstimateValue } from 'operations/models/ShipmentEstimate';
import { createContext, useContext } from 'react';
import { RESOURCETYPE_TO_ID_MAP } from './helpers';

export type ResourceType =
  | 'Wms::WarehouseTransaction'
  | 'Shipment::Shipment'
  | 'SalesHub::InquiryOption';

export const ShipmentEstimateStore = types
  .model('ShipmentEstimateStore', {
    estimates: types.array(ShipmentEstimate),
    to_be_synced: types.array(types.string),
    sync_state: types.enumeration(['unsaved', 'saved', 'saving', 'error']),
  })
  .views((self) => ({
    estimateCountForCurrency(
      resource_type: ResourceType,
      resource_id: string,
      type: 'buy' | 'sell',
      currency: string
    ) {
      return self.estimates.filter(
        (est) =>
          est[RESOURCETYPE_TO_ID_MAP(resource_type)] === resource_id &&
          est[`${type}_currency`] === currency
      ).length;
    },
    estimatesForSalesInvoice(resource_type: ResourceType, resource_id: string) {
      return self.estimates.filter(
        (est) =>
          est.is_sell_edit_allowed &&
          est.charge_type === 'Item' &&
          est[RESOURCETYPE_TO_ID_MAP(resource_type)] === resource_id
      );
    },
    estimatesForPurchaseInvoice(resource_type: ResourceType, resource_id: string) {
      return self.estimates.filter(
        (est) =>
          est.is_buy_edit_allowed &&
          est.charge_type === 'Item' &&
          est[RESOURCETYPE_TO_ID_MAP(resource_type)] === resource_id
      );
    },
    estimatesForShipment(resource_type: ResourceType, resource_id: string) {
      return self.estimates.filter(
        (est) => est[RESOURCETYPE_TO_ID_MAP(resource_type)] === resource_id
      );
    },
    get totalProvisionalSellAmount() {
      return self.estimates.reduce((sum, est) => {
        sum += est.sell_provisional_amount || 0;
        return sum;
      }, 0);
    },
    get totalProvisionalBuyAmount() {
      return self.estimates.reduce((sum, est) => {
        sum += est.buy_provisional_amount || 0;
        return sum;
      }, 0);
    },
    get cachedSellExchangeRate() {
      return self.estimates.reduce((cache: { [key: string]: number }, est) => {
        if (est.sell_exchange_rate && !cache[est.sell_currency || ''])
          cache[est.sell_currency || ''] = est.sell_exchange_rate;
        return cache;
      }, {});
    },
    get cachedBuyExchangeRate() {
      return self.estimates.reduce((cache: { [key: string]: number }, est) => {
        if (est.buy_exchange_rate && !cache[est.buy_currency || ''])
          cache[est.buy_currency || ''] = est.buy_exchange_rate;
        return cache;
      }, {});
    },
    getCachedExchangeRate(currency: string, type: string) {
      if (type === 'sell') return this.cachedSellExchangeRate[currency];
      return this.cachedBuyExchangeRate[currency] || 1;
    },
  }))
  .actions((self) => ({
    updateEstimatePermissions() {
      self.estimates.forEach((est) => est.updatePermissions());
    },
    addToBeSynced(ids: string[]) {
      ids.forEach((id) => self.to_be_synced.push(id));
      if (self.sync_state === 'saved') self.sync_state = 'unsaved';
    },
    startSyncing() {
      const toBeSynced = getSnapshot(self.to_be_synced);
      self.to_be_synced.clear();
      self.sync_state = 'saving';
      return toBeSynced;
    },
    updateSyncSuccess() {
      self.sync_state = self.to_be_synced.length > 0 ? 'unsaved' : 'saved';
    },
    updateSyncError(ids: string[]) {
      this.addToBeSynced(ids);
      self.sync_state = 'error';
    },
    checkEstimateError() {
      if (self.estimates.find((est) => est.mismatch)) this.updateSyncError([]);
      else this.updateSyncSuccess();
    },
    replaceEstimates(estimates: ShipmentEstimateValue[]) {
      applySnapshot(self.estimates, estimates);
      this.updateEstimatePermissions();
    },
    clearEstimates() {
      self.estimates.map((est) => est.delete());
    },
    addEstimates(estimates: ShipmentEstimateValue[]) {
      estimates.forEach((est) => self.estimates.push(est));
      this.updateEstimatePermissions();
    },
    addJVEstimates(estimates: ShipmentEstimateValue[]) {
      estimates.forEach((est) => {
        self.estimates.push(est);
        self.estimates[self.estimates.length - 1].setChargeType('Account');
      });
    },
    removeChild(estimate: Instance<IAnyModelType>) {
      detach(estimate);
    },
  }));

export type ShipmentEstimateStoreValue = Instance<typeof ShipmentEstimateStore>;
interface ShipmentEstimateStoreContextType {
  store: ShipmentEstimateStoreValue;
  fetchEstimateData?: () => Promise<void>;
  loading?: boolean;
}

const ShipmentEstimateStoreContext = createContext<null | ShipmentEstimateStoreContextType>(null);

export const ShipmentEstimateStoreContextProvider = ShipmentEstimateStoreContext.Provider;
export function useShipmentEstimateStore() {
  const contextValue = useContext(ShipmentEstimateStoreContext);
  if (contextValue === null) {
    throw new Error('contextValue cannot be null, please add a context provider');
  }
  return contextValue;
}
