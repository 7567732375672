import React, { useEffect } from 'react';
import { Button, Input, message, Modal, Form, dayjs } from '@shipmnts/pixel-hub';
import { UPDATE_SHIPMENT_DOCUMENT_STATUS } from 'operations/graphql/shipmentDocument';
import { useMutation } from '@apollo/client';
import { DOCUMENT_STATUS_EXECUTED, DOCUMENT_STATUS_SURRENDERED_AT_ORIGIN } from '../../constants';
import { ShipmentDocumentValue } from 'operations/models/ShipmentDocument';
import { ShipmentValue } from 'operations/models/Shipment';
import { BL_TYPE_ORIGINAL } from 'operations/modules/shipment/constants';

interface UnreleaseDocumentProps {
  documentType: string;
  shipmentDocument: ShipmentDocumentValue;
  shipment: ShipmentValue;
  visible?: boolean;
  onClose?: () => void;
  onSuccess?: () => void;
}
export default function UnreleaseSwitchBl(props: UnreleaseDocumentProps) {
  const { shipmentDocument, visible, onClose, onSuccess } = props;
  const [form] = Form.useForm();

  const prevStatusMap: { [key: string]: string } = {
    house:
      shipmentDocument?.bl_type === BL_TYPE_ORIGINAL
        ? DOCUMENT_STATUS_SURRENDERED_AT_ORIGIN
        : DOCUMENT_STATUS_EXECUTED,
    master:
      shipmentDocument?.bl_type === BL_TYPE_ORIGINAL
        ? DOCUMENT_STATUS_SURRENDERED_AT_ORIGIN
        : DOCUMENT_STATUS_EXECUTED,
  };

  const [updateDocumentStatus, { loading: updateDocumentStatusLoading, data, error }] = useMutation(
    UPDATE_SHIPMENT_DOCUMENT_STATUS
  );

  useEffect(() => {
    if (updateDocumentStatusLoading) {
      return;
    }
    if (error) {
      if (onClose) onClose();
      message.error(error.message);
    }
    if (data?.update_shipment_document_status) {
      message.success('Updated successfully');
      if (onSuccess) onSuccess();
      if (onClose) onClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateDocumentStatusLoading, data, error, onSuccess]);

  return (
    <Modal
      open={visible}
      title="Unrelease Switch BL"
      footer={[
        <Button
          key="cancel"
          disabled={updateDocumentStatusLoading}
          onClick={() => {
            if (onClose) onClose();
          }}
        >
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          loading={updateDocumentStatusLoading}
          onClick={form.submit}
        >
          Unrelease
        </Button>,
      ]}
      onCancel={() => {
        if (onClose) onClose();
      }}
    >
      <Form
        form={form}
        onFinish={(values: any) => {
          const { remarks } = values;
          const nextstatus = prevStatusMap[shipmentDocument.document_type || ''];
          const document_status_event_date = dayjs().unix();
          updateDocumentStatus({
            variables: {
              id: shipmentDocument?.id,
              document_status: nextstatus,
              document_status_event_date: document_status_event_date,
              ...(remarks
                ? { document_status_event_data: JSON.stringify({ remarks: remarks }) }
                : {}),
            },
          });
        }}
        layout="vertical"
      >
        <Form.Item name="remarks" label="Remarks For Reopen" rules={[{ required: true }]} required>
          <Input.TextArea style={{ width: '100%' }} rows={4} />
        </Form.Item>
      </Form>
    </Modal>
  );
}
