import React, { useState } from 'react';
import { ShipmentValue } from 'operations/models/Shipment';
import { Tag, Button, Dropdown, Space, Tooltip } from '@shipmnts/pixel-hub';
import { startCase as _startCase } from 'lodash';
import { PlusOutlined } from '@shipmnts/pixel-hub';
import { DOC_TYPE_PURCHASE_INVOICE, DOC_TYPE_SALES_INVOICE } from '../Accounts/constants';
import CreateInvoiceModal from './CreateInvoiceModal';
import EstimatesQuantityMisMatchBanner from './EstimatesQuantityMisMatchBanner';
import { useShipmentEstimateStore } from 'operations/models/ShipmentEstimate';
import { observer } from 'mobx-react-lite';
import type { MenuProps } from '@shipmnts/pixel-hub';
import { baseHelper } from 'common';
import {
  ACCOUNTING_STATUS_BILLING_CLOSED,
  ACCOUNTING_STATUS_CLOSED,
  FREIGHT_TYPE_ROAD,
  SHIPMENT_TYPE_WAREHOUSE,
  SHIPMENT_TYPE_GENERAL,
} from 'operations/modules/shipment/constants';
import InquiryOptionLinkTag from 'operations/components/InquiryOptionLinkTag';
import { useShipmentDetail } from '../ShipmentDetailLayout';
import {
  SHIPMENT_STATUS_BACK_TO_TOWN,
  SHIPMENT_STATUS_CANCELLED,
} from 'operations/modules/reports/constants';
import DeLinkInquiryOptionToResource from 'operations/components/DeLinkInquiryOptionToResource';
const { renderShorthandServices, shorthandServiceMap } = baseHelper;
type MenuItem = Required<MenuProps>['items'][number];

export const GetEstimatesTableCardViewTitle = (
  shipment: ShipmentValue,
  refreshGrid?: () => void
) => {
  const shipment_type = shipment.isMasterShipment() ? 'Master' : 'House';
  let shipment_code = shipment.isAirShipment() ? 'AWB' : 'BL';

  if (shipment_type === 'Master') {
    shipment_code = 'M' + shipment_code;
  } else {
    shipment_code = 'H' + shipment_code;
  }
  if (shipment.freight_type === FREIGHT_TYPE_ROAD) {
    shipment_code = 'CN';
  }
  const shipment_document = shipment.getShipmentDocument(undefined);
  const shipment_number = shipment.isMasterShipment()
    ? shipment.getMasterShipmentNumber()
    : shipment_document?.shipment_number;
  const terms: any = shipment_document?.charge_terms;
  let new_terms: any = [];
  if (terms) {
    if (shipment.isBackToBackShipment()) {
      const shipment_document_code = shipment.isAirShipment() ? 'AWB' : 'BL';
      const shipment_document_house_code = 'H' + shipment_document_code;
      const shipment_document_master_code = 'M' + shipment_document_code;
      const house_document = shipment.getShipmentDocument('house');
      const house_terms: any = house_document.charge_terms;
      const master_terms = Object.keys(terms)
        .filter((term) => !!terms[term])
        .map((term) => {
          const term_string = shipment_document_master_code.concat(
            ' ',
            _startCase(term.replace('_charges_terms', '').replace('_terms', ''))
          );
          return [term_string, terms[term]];
        });
      if (house_terms) {
        const new_house_terms = Object.keys(house_terms)
          .filter((term) => !!house_terms[term])
          .map((term) => {
            const term_string = shipment_document_house_code.concat(
              ' ',
              _startCase(term.replace('_charges_terms', '').replace('_terms', ''))
            );
            return [term_string, house_terms[term]];
          });
        new_terms = [...master_terms, ...new_house_terms];
      }
    } else {
      new_terms = Object.keys(terms)
        .filter((term) => !!terms[term])
        .map((term) => {
          const term_string = _startCase(term.replace('_charges_terms', '').replace('_terms', ''));
          return [term_string, terms[term]];
        });
    }
  }
  const services_offered = shipment.getServices();

  const { refetchShipments } = useShipmentDetail();
  const { fetchEstimateData } = useShipmentEstimateStore();
  if (shipment.id === 'new') return <></>;
  return (
    <div style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
      <span style={{ fontWeight: '500', fontSize: '16px' }}>
        {shipment_code} {shipment_number || shipment.job_number}
      </span>
      {shipment?.shipment_type !== SHIPMENT_TYPE_WAREHOUSE &&
        shipment?.shipment_type !== SHIPMENT_TYPE_GENERAL && (
          <EstimatesQuantityMisMatchBanner shipment={shipment} refreshGrid={refreshGrid} />
        )}
      <span style={{ overflow: 'hidden', textOverflow: 'ellipsis', marginLeft: '15px' }}>
        {shipment?.shipment_type !== SHIPMENT_TYPE_WAREHOUSE &&
          (shipment.is_external_quotation_number ? (
            <DeLinkInquiryOptionToResource
              onSucess={() => {
                if (refetchShipments) refetchShipments();
                if (fetchEstimateData) fetchEstimateData();
              }}
              resourceId={shipment.id}
              disabled={
                [SHIPMENT_STATUS_CANCELLED, SHIPMENT_STATUS_BACK_TO_TOWN].includes(
                  shipment.status || ''
                ) ||
                [ACCOUNTING_STATUS_BILLING_CLOSED, ACCOUNTING_STATUS_CLOSED].includes(
                  shipment.accounting_status || ''
                )
              }
              resourceType="Shipment::Shipment"
              is_external_quotation_number={shipment.is_external_quotation_number}
              quotation_number={shipment.quotation_number}
            />
          ) : (
            <InquiryOptionLinkTag
              disabled={
                [SHIPMENT_STATUS_CANCELLED, SHIPMENT_STATUS_BACK_TO_TOWN].includes(
                  shipment.status || ''
                ) ||
                [ACCOUNTING_STATUS_BILLING_CLOSED, ACCOUNTING_STATUS_CLOSED].includes(
                  shipment.accounting_status || ''
                )
              }
              searchProps={{
                customer_company_id: shipment.customer_company?.id,
                involved_branch_id: shipment.involved_branch?.id,
                freight_type: shipment?.freight_type || '',
                trade_type: shipment?.trade_type || '',
              }}
              resourceType="Shipment::Shipment"
              resourceId={shipment.id}
              inquiryOption={shipment.inquiry_option}
              onSucess={() => {
                if (refetchShipments) refetchShipments();
                if (fetchEstimateData) fetchEstimateData();
              }}
            />
          ))}

        {services_offered.map((service) => (
          <Tooltip
            trigger={['hover']}
            key={service}
            title={shorthandServiceMap.includes(_startCase(service)) ? _startCase(service) : null}
          >
            <Tag color="#FFF1DC" key={service} style={{ color: '#CC933E' }}>
              {renderShorthandServices(_startCase(service))}
            </Tag>
          </Tooltip>
        ))}
        {shipment?.shipment_type !== SHIPMENT_TYPE_WAREHOUSE &&
          new_terms.map((term: any) => {
            return (
              <Tag key={term[0]}>
                {term[0]}: {_startCase(term[1])}
              </Tag>
            );
          })}
      </span>
    </div>
  );
};

interface RenderExtraHeaderType {
  shipment: ShipmentValue;
  areEstimatesEmpty?: boolean;
}

export const RenderExtraHeader = observer((props: RenderExtraHeaderType) => {
  const { shipment, areEstimatesEmpty } = props;
  const { store, loading, fetchEstimateData } = useShipmentEstimateStore();

  const [invoiceModal, setInvoiceModal] = useState<string>();
  let disabled = false;
  let disabledReason = '';

  const menuItems: MenuItem[] = [];
  if (shipment.isAccountingOpen() && !disabled) {
    menuItems.push({
      label: DOC_TYPE_SALES_INVOICE,
      key: DOC_TYPE_SALES_INVOICE,
    });
  }

  if (!shipment.isAccountingClosed() && !disabled) {
    menuItems.push({
      label: DOC_TYPE_PURCHASE_INVOICE,
      key: DOC_TYPE_PURCHASE_INVOICE,
    });
  }

  if (menuItems.length === 0) {
    disabled = true;
    disabledReason = 'Shipment has been marked as closed';
  }

  if (areEstimatesEmpty) {
    disabled = true;
    disabledReason = 'Please add some estimates to create invoices';
  }

  if (store.sync_state !== 'saved') {
    disabled = true;
    disabledReason = 'There are unsaved changes in estimates';
  }

  const openModal = (invoice_type: string) => {
    fetchEstimateData?.();
    setInvoiceModal(invoice_type);
  };

  const getItems = () => {
    return menuItems;
  };
  if (shipment.id === 'new') return <></>;

  return (
    <Space>
      <Dropdown
        disabled={disabled}
        menu={{ items: getItems(), onClick: (props) => openModal(props.key) }}
      >
        <Tooltip title={disabled ? disabledReason : ''}>
          <Button
            loading={loading}
            icon={<PlusOutlined />}
            size="small"
            type="primary"
            disabled={disabled}
          >
            Create Invoice
          </Button>
        </Tooltip>
      </Dropdown>
      {!loading && invoiceModal && (
        <CreateInvoiceModal
          shipment={shipment}
          invoice_type={invoiceModal}
          onClose={() => {
            setInvoiceModal(undefined);
            fetchEstimateData && fetchEstimateData();
          }}
        />
      )}
    </Space>
  );
});
