import React, { useEffect } from 'react';
import { Modal, message } from '@shipmnts/pixel-hub';
import { DELETE_SHIPMENT_ESTIMATES } from 'operations/graphql/shipmentEstimate';
import { useMutation } from '@apollo/client';
import { useShipmentEstimateStore } from 'operations/models/ShipmentEstimate';

const DeleteEstimatesAction = (props: any) => {
  const [deleteShipmentEstimates, { data, error }] = useMutation(DELETE_SHIPMENT_ESTIMATES);
  const { fetchEstimateData } = useShipmentEstimateStore();
  const { selectedNodes, selectedNodeCounts, onSuccess } = props;
  const deleteEstimates = async () => {
    const shipment_estimates = selectedNodes.map((node: any) => node.data.id);
    deleteShipmentEstimates({
      variables: { shipment_estimates },
    });
  };
  useEffect(() => {
    Modal.confirm({
      title: 'Are you sure?',
      content: `This action will delete ${selectedNodeCounts} estimates.`,
      onOk() {
        deleteEstimates();
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (data) {
      fetchEstimateData?.();
      onSuccess?.();
      message.success('Estimates deleted.');
    }
    if (error) {
      message.error('Failed to delete estimates.');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, error]);
  return <></>;
};

export default DeleteEstimatesAction;
