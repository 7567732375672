import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Drawer, message, DrawerFooter } from '@shipmnts/pixel-hub';
import CustomsOrContainerListTable from './CustomsOrContainerListTable';
import { GridOptions } from '@ag-grid-community/core';
import { Column } from 'operations/models/Report';
import { UPSERT_COMMERCIAL_INVOICE } from '../graphql/productOrder';
import { useMutation } from '@apollo/client';
import { CommercialInvoiceValue } from 'operations/models/CommercialInvoice';
import { omit as _omit } from 'lodash';
import { ShipmentContainerValue } from 'operations/models/ShipmentContainer';
import { ContainerCargoDetailsValue } from 'operations/models/ContainerCargoDetails';

interface LinkContainerDrawerProps {
  commercialInvoice: CommercialInvoiceValue;
  onSuccess?: () => void;
  onClose: () => void;
}

export default function LinkContainerDrawer(props: LinkContainerDrawerProps): JSX.Element {
  const { commercialInvoice, onSuccess, onClose } = props;
  const [disabled, setDisabled] = useState(true);
  let purchaseNumber = '';
  const rowData = useMemo(
    () =>
      (commercialInvoice?.shipment?.shipment_containers || []).map((sc: ShipmentContainerValue) => {
        let grossWeight = 0,
          netWeight = 0;
        sc?.container_cargo_details?.forEach((ccd: ContainerCargoDetailsValue) => {
          grossWeight += ccd?.gross_weight || 0;
          netWeight += ccd?.net_weight || 0;
        });
        return {
          ..._omit(sc, ['container_cargo_details', 'last_action_status']),
          cargo_gross_weight: grossWeight,
          cargo_net_weight: netWeight,
        };
      }),
    [commercialInvoice]
  );

  if (commercialInvoice?.product_order_items) {
    purchaseNumber =
      commercialInvoice?.product_order_items[0]?.linked_to?.product_order?.purchase_order_number;
  }
  const gridRef = useRef<GridOptions>();
  const [linkContainersToInvoice, { data, loading, error }] =
    useMutation(UPSERT_COMMERCIAL_INVOICE);
  useEffect(() => {
    if (data && data?.upsert_commercial_invoice?.id) {
      message.success(`Successfully Updated Commercial Invoice !`);
      if (onSuccess) onSuccess();
    }
    if (error) {
      message.error(error.message);
    }
  }, [data, error, onSuccess]);

  const containerData = useMemo(() => {
    return commercialInvoice?.containers_commercial_invoices?.map((cci: any) => {
      return cci.shipment_container;
    });
  }, [commercialInvoice]);

  const onSaveDrawer = () => {
    const selectedContianers: any = gridRef?.current?.api?.getSelectedNodes()?.map((node) => {
      return { id: node.data.id, commercial_invoice_number: node.data.commercial_invoice_number };
    });

    const selectedContianersIds: any = selectedContianers.map((node: any) => {
      return node.id;
    });

    const detstroyContainer: any = [];
    const newContainer: any = [];

    const alreadyLinkedContaintersIds = (containerData || [])?.map((sc: any) => sc.id);

    (commercialInvoice?.containers_commercial_invoices || []).forEach((alc: any) => {
      if (!selectedContianersIds?.includes(alc.shipment_container.id)) {
        const newCommercialNumber = alc.shipment_container.commercial_invoice_number.replace(
          `${commercialInvoice.invoice_number}, `,
          ''
        );

        detstroyContainer?.push({
          id: alc.id,
          shipment_container: {
            id: alc.shipment_container.id,
            commercial_invoice_number: newCommercialNumber,
          },
          _destroy: true,
        });
      }
    });

    selectedContianers.forEach((alc: any) => {
      if (!alreadyLinkedContaintersIds?.includes(alc.id)) {
        const number = alc?.commercial_invoice_number || '';
        newContainer?.push({
          shipment_container_id: alc.id,
          shipment_container: {
            id: alc.id,
            purchase_order_number: purchaseNumber,
            commercial_invoice_number: number.concat(`${commercialInvoice.invoice_number}, `),
          },
        });
      }
    });

    const finalContainer = detstroyContainer.concat(newContainer);
    const payload: any = [];
    gridRef?.current?.api?.forEachNode((node: any) => {
      payload.push({
        ..._omit(node.data, [
          '__typename',
          'last_action_status',
          'status',
          'booking_number',
          'job_number',
          'ocean_transport_order_ids',
        ]),
        container_settings: node.data.container_settings
          ? _omit(node.data.container_settings, ['__typename'])
          : null,
      });
    });
    linkContainersToInvoice({
      variables: {
        shipment_containers: payload,
        commercial_invoice: {
          id: commercialInvoice.id,
          shipment_id: commercialInvoice?.shipment?.id
            ? parseInt(commercialInvoice.shipment.id)
            : null,
          containers_commercial_invoices: finalContainer.length > 0 ? finalContainer : [],
        },
      },
    });
  };
  /* note: below effect causing the issue, so commented it out 
    re-rendering child component and row selection get erased
  */
  // useEffect(() => {
  //   if (!disabled) {
  //     const payload: any = [];
  //     gridRef?.current?.api?.forEachNode((node: any) => {
  //       payload.push({
  //         ..._omit(node.data, ['__typename', 'last_action_status', 'status']),
  //       });
  //     });
  //     setRowData(payload);
  //   }
  // }, [disabled]);

  const columns: Column[] = [
    {
      headerName: 'Container #',
      field: 'container_number',
      colId: 'container_number',
      columnType: 'String',
      pinned: 'left',
      editable: false,
    },
    {
      headerName: 'Container Type',
      field: 'container_type',
      colId: 'container_type',
      columnType: 'String',
      editable: false,
    },
    {
      headerName: 'S/L Seal Number',
      field: 'carrier_seal_number',
      colId: 'carrier_seal_number',
      columnType: 'String',
      editable: true,
    },
    {
      headerName: 'Shipper Seal #',
      field: 'shipper_seal_number',
      colId: 'shipper_seal_number',
      columnType: 'String',
      editable: true,
    },
    {
      headerName: 'Net Weight',
      field: 'cargo_net_weight',
      colId: 'cargo_net_weight',
      columnType: 'Float',
    },
    {
      headerName: 'Gross Weight',
      field: 'cargo_gross_weight',
      colId: 'cargo_gross_weight',
      columnType: 'Float',
    },
    {
      headerName: 'Commercial Invoice #',
      field: 'commercial_invoice_number',
      colId: 'commercial_invoice_number',
      columnType: 'String',
      editable: (o) => !o.node.isRowPinned(),
      width: 180,
    },
    {
      headerName: 'Purchase Order #',
      field: 'purchase_order_number',
      colId: 'purchase_order_number',
      width: 180,
      columnType: 'String',
      editable: (o) => !o.node.isRowPinned(),
    },
    {
      headerName: 'Remarks',
      field: 'remarks',
      colId: 'remarks',
      width: 180,
      columnType: 'String',
      editable: (o) => !o.node.isRowPinned(),
    },
  ];

  return (
    <>
      <Drawer
        open={true}
        onClose={onClose}
        title={`Link Containers on Invoice ${commercialInvoice.invoice_number}`}
        width={850}
        footer={
          <DrawerFooter
            disabled={disabled}
            saveText="Update Containers"
            onSave={onSaveDrawer}
            onClose={onClose}
            loading={loading}
          />
        }
      >
        <CustomsOrContainerListTable
          data={rowData}
          isRowSelectable="multiple"
          gridRef={gridRef}
          alreadyLinked={containerData || []}
          columns={columns}
          setDisabled={setDisabled}
        />
      </Drawer>
    </>
  );
}
