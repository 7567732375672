/* eslint-disable @typescript-eslint/no-empty-interface */
import { types, Instance, SnapshotIn, SnapshotOut, IAnyModelType } from 'mobx-state-tree';
import Location from 'operations/models/Location';
import Address from 'operations/models/Address';
import { DayJsDate as DayJsDateObj, convertValuesToDayJs, Dayjs } from '@shipmnts/pixel-hub';
import Company from './Company';

export const ROUTING_NODE_TYPE_EMPTY_PICKUP = 'empty_pickup';
export const ROUTING_NODE_TYPE_PICKUP = 'origin_factory';
export const ROUTING_NODE_TYPE_PCR = 'place_of_carrier_receipt';
export const ROUTING_NODE_TYPE_ORIGIN_CFS = 'origin_cfs';
export const ROUTING_NODE_TYPE_STUFFING_LOCATION = 'stuffing_location';
export const ROUTING_NODE_TYPE_POL = 'port_of_loading';
export const ROUTING_NODE_TYPE_TRANSHIPMENT = 'transhipment';
export const ROUTING_NODE_TYPE_HOP = 'hop';
export const ROUTING_NODE_TYPE_POD = 'port_of_discharge';
export const ROUTING_NODE_TYPE_DESTINATION_CFS = 'destination_cfs';
export const ROUTING_NODE_TYPE_PCD = 'place_of_carrier_delivery';
export const ROUTING_NODE_TYPE_DELIVERY = 'delivery';
export const ROUTING_NODE_TYPE_EMPTY_RETURN = 'empty_return';

export declare type RoutingNodeTag =
  | 'empty_pickup'
  | 'origin_factory'
  | 'place_of_carrier_receipt'
  | 'origin_cfs'
  | 'stuffing_location'
  | 'port_of_loading'
  | 'transhipment'
  | 'hop'
  | 'port_of_discharge'
  | 'destination_cfs'
  | 'place_of_carrier_delivery'
  | 'delivery'
  | 'empty_return'
  | 'destuffing_location';

export const cutoffDateFields = [
  'reefer_gate_open_date',
  'reefer_gate_close_date',
  'gate_open_date',
  'gate_close_date',
  'si_cutoff_date',
  'vgm_cutoff_date',
  'ams_cutoff_date',
  'icd_cutoff_date',
  'doc_cutoff_date',
  'form13_cutoff_date',
  'load_list_cutoff_date',
];

const DayJsDate = types.custom<Dayjs, Dayjs>(DayJsDateObj);

const RoutingNode = types
  .model({
    id: types.maybe(types.identifier),
    _id: types.maybe(types.maybeNull(types.string)),
    location: types.maybe(types.maybeNull(types.late((): IAnyModelType => Location))),
    terminal: types.maybe(types.maybeNull(types.late((): IAnyModelType => Location))),
    address: types.maybe(types.maybeNull(types.late((): IAnyModelType => Address))),
    company: types.maybe(types.maybeNull(Company)),
    tags: types.maybe(types.maybeNull(types.array(types.string))),
    reefer_gate_open_date: types.maybe(types.maybeNull(DayJsDate)),
    reefer_gate_close_date: types.maybe(types.maybeNull(DayJsDate)),
    gate_open_date: types.maybe(types.maybeNull(DayJsDate)),
    gate_close_date: types.maybe(types.maybeNull(DayJsDate)),
    si_cutoff_date: types.maybe(types.maybeNull(DayJsDate)),
    doc_cutoff_date: types.maybe(types.maybeNull(DayJsDate)),
    form13_cutoff_date: types.maybe(types.maybeNull(DayJsDate)),
    load_list_cutoff_date: types.maybe(types.maybeNull(DayJsDate)),
    vgm_cutoff_date: types.maybe(types.maybeNull(DayJsDate)),
    icd_cutoff_date: types.maybe(types.maybeNull(DayJsDate)),
    ams_cutoff_date: types.maybe(types.maybeNull(DayJsDate)),
    waiting_time: types.maybe(types.maybeNull(types.number)),
    waiting_time_unit: types.maybe(types.maybeNull(types.string)),
  })
  .preProcessSnapshot((snapshot) => {
    if (snapshot) {
      return {
        ...snapshot,
        ...convertValuesToDayJs(snapshot, cutoffDateFields),
      };
    }
    return snapshot;
  });

export interface RoutingNodeValue extends Instance<typeof RoutingNode> {}
export interface RoutingNodeIn extends SnapshotIn<typeof RoutingNode> {}
export interface RoutingNodeOut extends SnapshotOut<typeof RoutingNode> {}

export default RoutingNode;
