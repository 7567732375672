import React, { ReactNode, useState } from 'react';
import {
  collapsibleCard,
  getPortName,
  getStuffingAddress,
  descriptionsProps,
} from '../../Common/common';
import { useShipmentDetail } from '../../ShipmentDetailLayout';
import { get as _get } from 'lodash';
import {
  Typography,
  Descriptions,
  Timeline,
  DescriptionsProps,
  getDateFromUnix,
  getDateTimeFromUnix,
} from '@shipmnts/pixel-hub';
import { CustomIcon } from '@shipmnts/pixel-hub';
import { DownOutlined, RightOutlined, VersionTimeline } from '@shipmnts/pixel-hub';
import { FREIGHT_TYPE_ROAD } from 'operations/modules/shipment/constants';
import { getPanelHeader } from '../../Common/common';
const { Text, Paragraph } = Typography;

const rouingDescriptionProps: DescriptionsProps = {
  column: 1,
  colon: false,
  size: 'default',
  layout: 'vertical',
  labelStyle: { color: '#46546D', width: '50%', lineHeight: '17px', fontSize: '12px' },
  className: 'ant-descriptions-header-custom',
  contentStyle: { lineHeight: '17px', width: '50%', fontSize: '12px' },
};

const ShowRoutingDetailViaNodesAndLegs = () => {
  const { shipment } = useShipmentDetail();
  let { routingLegs } = useShipmentDetail();
  if (shipment?.isRoadHouseShipment()) {
    routingLegs = (shipment?.master_shipment?.routing_legs || []).filter(
      (leg: any) => leg.routing_type === 'pickup'
    );
  }

  if ((routingLegs || []).length === 0 || !routingLegs) return <></>;
  const routingNodes: any[] = [
    {
      ...routingLegs[0].origin,
      estimated_time_of_departure: routingLegs[0].estimated_time_of_departure,
    },
  ];

  for (let rl = 0; rl < routingLegs.length; rl++) {
    if (rl === routingLegs.length - 1) {
      routingNodes.push({
        ...routingLegs[rl].destination,
        estimated_time_of_arrival: routingLegs[rl].estimated_time_of_arrival,
      });
    } else {
      routingNodes.push({
        ...routingLegs[rl].destination,
        estimated_time_of_arrival: routingLegs[rl].estimated_time_of_arrival,
        estimated_time_of_departure: routingLegs[rl + 1].estimated_time_of_departure,
      });
    }
  }

  return (
    <Descriptions
      {...rouingDescriptionProps}
      style={{ flexDirection: 'column' }}
      title={getPanelHeader(<CustomIcon icon="Route" />, 'Routing Details')}
    >
      <div>
        <Timeline>
          {routingNodes.map((rn, id) => (
            <Timeline.Item key={id}>
              <Descriptions
                column={1}
                colon={false}
                size={'default'}
                layout="horizontal"
                className="ant-descriptions-header-custom"
                contentStyle={{
                  lineHeight: '17px',
                  fontSize: '12px',
                  alignItems: 'center',
                  textAlign: 'center',
                  width: '30%',
                }}
                labelStyle={{
                  color: '#46546D',
                  width: '30%',
                  lineHeight: '17px',
                  fontSize: '12px',
                }}
              >
                {rn.location && (
                  <>
                    <Descriptions.Item
                      key={id}
                      style={{
                        paddingBottom: '0px',
                        lineHeight: '17px',
                      }}
                      label={'Location'}
                    >
                      <Paragraph ellipsis={{ tooltip: true }} style={{ marginBottom: '0' }}>
                        {getPortName(rn.location, FREIGHT_TYPE_ROAD)}
                      </Paragraph>
                    </Descriptions.Item>

                    <Descriptions.Item
                      key={id}
                      style={{
                        paddingBottom: '3px',
                        lineHeight: '17px',
                      }}
                      label={'Type'}
                    >
                      <Paragraph ellipsis={{ tooltip: true }} style={{ marginBottom: '0' }}>
                        {_get(rn.location, 'type', '-')}
                      </Paragraph>
                    </Descriptions.Item>
                  </>
                )}
                {rn.address && (
                  <Descriptions.Item
                    key={id}
                    style={{
                      paddingBottom: '3px',
                      lineHeight: '17px',
                    }}
                    label={'Address'}
                  >
                    <Paragraph ellipsis={{ tooltip: true }} style={{ marginBottom: '0' }}>
                      {[
                        rn.company?.registered_name,
                        rn.address?.name || rn.address?.city_name,
                        rn.address?.state_code,
                      ]
                        .filter(Boolean)
                        .join(' - ')}
                    </Paragraph>
                  </Descriptions.Item>
                )}
                {rn.terminal && (
                  <Descriptions.Item
                    key={id}
                    style={{
                      paddingBottom: '3px',
                      lineHeight: '17px',
                    }}
                    label={'Terminal'}
                  >
                    <Paragraph ellipsis={{ tooltip: true }} style={{ marginBottom: '0' }}>
                      {rn.terminal?.name}
                    </Paragraph>
                  </Descriptions.Item>
                )}
                {rn.estimated_time_of_arrival && (
                  <Descriptions.Item
                    key={id}
                    style={{
                      paddingBottom: '3px',
                      lineHeight: '17px',
                    }}
                    label={'ETA'}
                  >
                    <Paragraph ellipsis={{ tooltip: true }} style={{ marginBottom: '0' }}>
                      {getDateFromUnix(rn.estimated_time_of_arrival)}
                    </Paragraph>
                  </Descriptions.Item>
                )}
                {rn.estimated_time_of_departure && (
                  <Descriptions.Item
                    key={id}
                    style={{
                      paddingBottom: '3px',
                      lineHeight: '17px',
                    }}
                    label={'ETD'}
                  >
                    <Paragraph ellipsis={{ tooltip: true }} style={{ marginBottom: '0' }}>
                      {getDateFromUnix(rn.estimated_time_of_departure)}
                    </Paragraph>
                  </Descriptions.Item>
                )}
              </Descriptions>
            </Timeline.Item>
          ))}
        </Timeline>
      </div>
    </Descriptions>
  );
};
function RoutingDetails() {
  const { shipment } = useShipmentDetail();
  const routingLegs = shipment?.routing_legs;
  const [transhipmentShow, setTranshipmentShow] = useState<boolean>(false);
  const [collapseEtaTimeline, setCollapseEtaTimeline] = useState(true);
  const [collapseEtdTimeline, setCollapseEtdTimeline] = useState(true);

  if (!shipment) {
    return <></>;
  }
  const freightType = _get(shipment, 'freight_type');
  const etaStatusColor: { [key: string]: string } = {
    delayed: 'red',
    before_time: 'blue',
    on_time: 'var(--bg-success-green-2)',
    '': 'black',
  };

  if (freightType === FREIGHT_TYPE_ROAD) {
    return <ShowRoutingDetailViaNodesAndLegs />;
  }
  const getRoutingDetails = () => {
    if (routingLegs) {
      const main_carriage_routing_legs = routingLegs
        .filter((leg: any) => leg.routing_type === 'main_carriage')
        .sort((a, b) => a.sequence_number - b.sequence_number);
      const first_vessel_leg = main_carriage_routing_legs[0];
      const last_vessel_leg = main_carriage_routing_legs.slice(-1)[0];
      const origin_terminal =
        _get(first_vessel_leg, 'origin.terminal.name') || shipment?.port_of_loading_terminal?.name;
      const destination_terminal = _get(last_vessel_leg, 'destination.terminal.name');

      const routingDetails: { [key: string]: string | ReactNode }[] = [];

      if (
        shipment.place_of_carrier_receipt &&
        shipment.place_of_carrier_receipt?.id !== shipment.port_of_loading?.id
      ) {
        routingDetails.push({
          POCR: getPortName(shipment.place_of_carrier_receipt, freightType),
          // ATD: shipment.atd_pcr_date ? getDateTimeFromUnix(shipment.atd_pcr_date) : '-',
        });
      }
      if (shipment.port_of_loading) {
        const obj: { [key: string]: string | ReactNode } = {
          POL: (
            <Text
              ellipsis={{
                tooltip: `${origin_terminal || ''} ${getPortName(
                  shipment.port_of_loading,
                  freightType
                )}`,
              }}
              style={{ marginBottom: '0' }}
            >
              <b>{`${origin_terminal || ''} ${getPortName(
                shipment.port_of_loading,
                freightType
              )}`}</b>
            </Text>
          ),

          // Terminal: origin_terminal || '-',
        };
        // if (shipment.atd_pol_date) {
        //   // obj['ATD'] = getDateTimeFromUnix(shipment.atd_pol_date);
        // } else
        if (shipment.estimated_time_of_departure) {
          obj['ETD'] = shipment.estimated_time_of_departure ? (
            <div style={{ display: 'flex' }}>
              <div>{`${getDateFromUnix(shipment.estimated_time_of_departure)}`}</div>
              <span style={{ marginLeft: '2px', cursor: 'pointer' }}>
                <CustomIcon
                  icon="TrackerIcon"
                  onClick={() => setCollapseEtdTimeline(!collapseEtdTimeline)}
                />
              </span>
              {!collapseEtdTimeline && (
                <VersionTimeline
                  id={shipment.id}
                  reference_type="Shipment::Shipment"
                  onClose={() => setCollapseEtdTimeline(!collapseEtdTimeline)}
                  title="Shipment Estimated Time Of Departure Changes Timeline"
                  fieldName="estimated_time_of_departure"
                />
              )}
            </div>
          ) : (
            '-'
          );
        }
        if (shipment.isOceanShipment()) {
          obj['Vessel - Voyage'] = [shipment.vessel_name, shipment.voyage_number]
            .filter((x) => !!x && x !== '')
            .join(' / ');
        }
        if (shipment.isAirShipment()) obj['Flight #'] = shipment.voyage_number || '-';
        routingDetails.push(obj);
      }

      if (shipment.port_of_discharge) {
        routingDetails.push({
          POD: (
            <Text ellipsis={{ tooltip: true }} style={{ marginBottom: '0' }}>
              <b>
                {`${shipment.port_of_discharge_terminal?.name || destination_terminal || ''} 
              ${getPortName(shipment.port_of_discharge, freightType)}`}
              </b>
            </Text>
          ),
          // Terminal: shipment.port_of_discharge_terminal?.name || destination_terminal || '-',
          ETA: shipment.estimated_time_of_arrival ? (
            <div style={{ display: 'flex' }}>
              <div
                style={{
                  color: etaStatusColor[shipment.eta_status || ''],
                }}
              >{`${getDateFromUnix(shipment.estimated_time_of_arrival)}`}</div>
              <span style={{ marginLeft: '2px', cursor: 'pointer' }}>
                <CustomIcon
                  icon="TrackerIcon"
                  onClick={() => setCollapseEtaTimeline(!collapseEtaTimeline)}
                />
              </span>
              {!collapseEtaTimeline && (
                <VersionTimeline
                  id={shipment.id}
                  reference_type="Shipment::Shipment"
                  onClose={() => setCollapseEtaTimeline(!collapseEtaTimeline)}
                  title="Shipment Estimated Time Of Arrival Changes Timeline"
                  fieldName="estimated_time_of_arrival"
                />
              )}
            </div>
          ) : (
            '-'
          ),
          ATA: shipment.actual_time_of_arrival
            ? getDateTimeFromUnix(shipment.actual_time_of_arrival)
            : '-',
        });
      }
      if (
        shipment.place_of_carrier_delivery &&
        shipment.place_of_carrier_delivery?.id !== shipment.port_of_discharge?.id
      ) {
        routingDetails.push({
          POCD: getPortName(shipment.place_of_carrier_delivery, freightType),
          ATA: shipment.actual_time_of_arrival_pocd
            ? getDateFromUnix(shipment.actual_time_of_arrival_pocd)
            : '-',
        });
      }
      if (shipment.destuffing_location) {
        routingDetails.push({
          'Destuffing Location': getStuffingAddress(shipment.destuffing_location, freightType),
        });
      }
      if (shipment.final_place_of_delivery) {
        routingDetails.push({
          FPOD: shipment.final_place_of_delivery_print || '-',
          ETA: shipment.estimated_time_of_arrival_at_fpod
            ? getDateFromUnix(shipment.estimated_time_of_arrival_at_fpod)
            : '-',
        });
      }
      return routingDetails;
    }
    return [];
  };
  const routingDetails: { [key: string]: string | ReactNode }[] = getRoutingDetails();

  const transhipmentDetails: any = {};
  if (shipment.tsp_1) {
    transhipmentDetails['TSP 1'] = getPortName(shipment.tsp_1, freightType);
  }
  if (shipment.tsp_2) {
    transhipmentDetails['TSP 2'] = getPortName(shipment.tsp_2, freightType);
  }
  if (shipment.current_vessel) {
    transhipmentDetails['Current Vessel'] = shipment.current_vessel.name;
  }
  if (shipment.current_voyage_number) {
    transhipmentDetails['Current Voyage Number'] = shipment.current_voyage_number;
  }
  if (shipment.eta_tsp1) {
    transhipmentDetails['Transhipment-1 ETA'] = getDateFromUnix(shipment.eta_tsp1);
  }
  if (shipment.etd_tsp1) {
    transhipmentDetails['Transhipment-1 ETD'] = getDateFromUnix(shipment.etd_tsp1);
  }
  if (shipment.eta_tsp2) {
    transhipmentDetails['Transhipment-2 ETA'] = getDateFromUnix(shipment.eta_tsp2);
  }
  if (shipment.etd_tsp2) {
    transhipmentDetails['Transhipment-2 ETD'] = getDateFromUnix(shipment.etd_tsp2);
  }

  const showTsp1 =
    transhipmentDetails.hasOwnProperty('TSP 1') ||
    transhipmentDetails.hasOwnProperty('Current Vessel') ||
    transhipmentDetails.hasOwnProperty('Current Voyage Number') ||
    transhipmentDetails.hasOwnProperty('Transhipment-1 ETA') ||
    transhipmentDetails.hasOwnProperty('Transhipment-1 ETD');

  const showTsp2 =
    transhipmentDetails.hasOwnProperty('TSP 2') ||
    transhipmentDetails.hasOwnProperty('Transhipment-2 ETA') ||
    transhipmentDetails.hasOwnProperty('Transhipment-2 ETD');

  return (
    <div>
      {/* Routing Detials */}
      {collapsibleCard({
        icon: <CustomIcon icon="Route" />,
        header: 'Routing Details',
        data: routingDetails,
      })}

      {Object.keys(transhipmentDetails).length > 0 && (
        <Descriptions {...descriptionsProps}>
          <Descriptions.Item
            key={1}
            style={{
              paddingBottom: '3px',
              lineHeight: '17px',
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
            }}
            label={'Transhipment Details'}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
              }}
            >
              <div style={{ display: 'flex', fontSize: '12px', width: '100%' }}>
                <Paragraph style={{ marginBottom: '0', overflow: 'hidden' }}>
                  <Paragraph ellipsis={{ tooltip: true }} style={{ marginBottom: '0' }}>
                    Hop Details
                  </Paragraph>
                </Paragraph>
                {transhipmentShow ? (
                  <DownOutlined
                    size={12}
                    style={{ fontSize: '12px', marginTop: '4px', marginLeft: 'auto' }}
                    onClick={() => setTranshipmentShow(!transhipmentShow)}
                  />
                ) : (
                  <RightOutlined
                    size={12}
                    style={{ fontSize: '12px', marginTop: '4px', marginLeft: 'auto' }}
                    onClick={() => setTranshipmentShow(!transhipmentShow)}
                  />
                )}
              </div>
              {transhipmentShow && (
                <Timeline>
                  {showTsp1 && (
                    <Timeline.Item
                      style={{ fontSize: '10px', lineHeight: '10px', paddingTop: '15px' }}
                      color="gray"
                    >
                      <p> TSP 1: {transhipmentDetails['TSP 1'] || ' - '} </p>
                      <p>Current Vessel: {transhipmentDetails['Current Vessel'] || ' - '} </p>
                      <p>
                        Current Voyage #: {transhipmentDetails['Current Voyage Number'] || ' - '}
                      </p>
                      <p>ETA: {transhipmentDetails['Transhipment-1 ETA'] || ' - '} </p>
                      <p>ETD: {transhipmentDetails['Transhipment-1 ETD'] || ' - '} </p>
                    </Timeline.Item>
                  )}
                  {showTsp2 && (
                    <Timeline.Item
                      style={{ fontSize: '10px', lineHeight: '10px', paddingTop: '10px' }}
                      color="gray"
                    >
                      <p>TSP 2: {transhipmentDetails['TSP 2'] || ' - '} </p>
                      <p>ETA: {transhipmentDetails['Transhipment-2 ETA'] || ' - '} </p>
                      <p>ETD: {transhipmentDetails['Transhipment-2 ETD'] || ' - '} </p>
                    </Timeline.Item>
                  )}
                </Timeline>
              )}
            </div>
          </Descriptions.Item>
        </Descriptions>
      )}
    </div>
  );
}

export default RoutingDetails;
