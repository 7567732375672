import { CompanyValue, CompanyAccountsData } from 'network/models/Company';

import { pick as _pick, omit as _omit } from 'lodash';

export const getFormInitialValues = (
  company: CompanyValue,
  companyAccountsData?: CompanyAccountsData
) => {
  const receivable_accounts = (companyAccountsData?.receivable_accounts || []).map((st) =>
    _omit(st, '__typename')
  );
  const formPayload = {
    ..._pick(company, ['id', 'company_type']),
    ..._pick(companyAccountsData, [
      'receivable_tax_withholding_category',
      'default_currency',
      'is_msme',
    ]),
    is_msme: companyAccountsData?.is_msme || 0,
    receivable_accounts,
  };
  return formPayload;
};
