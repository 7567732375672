import React, { useContext } from 'react';
import { ArrayValueConfig } from './FormFiller/GenAIInputWrapper';

interface GenAIFieldContextType {
  setFields: (fields: any) => void;
  fields: Record<any, EntryType> | null;
  handleFieldChange: (key: string[] | string, arrayValueConfig?: ArrayValueConfig) => void;
}

export interface EntryType {
  showIcon: boolean;
}

export const GenAIFieldContext = React.createContext<GenAIFieldContextType>({
  setFields: () => void 0,
  fields: null,
  handleFieldChange: (key: string[] | string) => void 0,
});

export const useGenAIFieldContext = () => useContext(GenAIFieldContext);
