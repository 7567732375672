import React, { useEffect } from 'react';
import {
  Button,
  Skeleton,
  LinkedParentType,
  SendNotificationsForm,
  UploadedDocumentType,
  Attachment,
  SendEmailProvider,
  ComposeTab,
  useComposeTab,
  MailOutlined,
  CustomIcon,
  CommentOutlined,
} from '@shipmnts/pixel-hub';
import { ApolloError } from '@apollo/client';
import { errorMessageHandlerGraphQL } from 'common/utils/errorMessageHandler';
import { useSession } from 'common/utils/SessionContext';
import { useEmailDrawerContext } from 'common/ApplicationLayoutContent';

interface SendEmailWithDocumentPdfProps {
  resource_ids: string[];
  action_name?: string;
  templates?: any[];
  fetchDocumentParents?: LinkedParentType[];
  companies_roles_mapping?: any[];
  initialAttachments?: UploadedDocumentType[];
  showEmail?: boolean;
  showMarkdown?: boolean;
  customContent?: string;
  title?: string;
  visible?: boolean;
  onClose?: () => void;
  onSuccess?: () => void;
  fetchingEmailTemplates: boolean;
  fetchEmailTemplatesError: ApolloError | undefined;
  fetchTemplates: () => void;
  docTypeId?: string;
  fetchDocuments?: () =>
    | Promise<{
        error: any;
        documents: Array<Attachment>;
      }>
    | undefined;
  defaultEmails?: {
    recipient_emails?: string[];
    cc_emails?: string[];
  };
  threadId?: string;
  isForward?: boolean;
}
export default function SendEmailWithDocumentPdf(props: SendEmailWithDocumentPdfProps) {
  const {
    resource_ids,
    action_name,
    fetchDocumentParents,
    companies_roles_mapping,
    templates,
    initialAttachments,
    showEmail,
    showMarkdown,
    customContent,
    onClose,
    onSuccess,
    fetchingEmailTemplates,
    fetchEmailTemplatesError,
    fetchTemplates,
    docTypeId,
    fetchDocuments,
    defaultEmails,
    threadId,
    visible,
    title,
    isForward,
  } = props;
  const user = useSession();
  const { setVisible, setEmailProps, emailProps } = useEmailDrawerContext();
  const {
    visible: tabVisible,
    setVisible: setTabVisible,
    tab,
    handleMinimize,
    handleClose,
  } = useComposeTab();
  // if (!resource_ids) return null;
  const closeDrawer = () => {
    setEmailProps({
      title: '',
      action_name: '',
      resource_ids: [],
      fetchDocumentParents: [],
      companies_roles_mapping: [],
      onClose: () => {
        console.log('onClose');
      },
      showEmail: undefined,
      showMarkDown: undefined,
      defaultEmails: {
        recipient_emails: [],
        cc_emails: [],
      },
    });
    handleClose && handleClose();
    if (setVisible) setVisible(false);
    if (onClose) onClose();
  };

  useEffect(() => {
    if (visible && !tabVisible) {
      setTabVisible(true);
    }
    if (!visible && !tabVisible) {
      closeDrawer();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  useEffect(() => {
    if (emailProps?.isTabMinimized) {
      handleMinimize(emailProps?.isTabMinimized);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emailProps?.isTabMinimized]);

  if (fetchEmailTemplatesError) {
    return (
      <span>
        {errorMessageHandlerGraphQL(fetchEmailTemplatesError)}
        <Button size="small" type="link" onClick={() => fetchTemplates()}>
          Retry
        </Button>
      </span>
    );
  }

  return (
    <>
      {fetchingEmailTemplates && <Skeleton />}
      {!fetchingEmailTemplates && (visible || tab?.status === 'draft') && (
        <SendEmailProvider
          sessionData={user}
          onClose={() => {
            closeDrawer();
          }}
          fetchDocumentParents={fetchDocumentParents}
          action_name={action_name}
          companies_roles_mapping={companies_roles_mapping}
          resource_ids={resource_ids}
          showEmail={showEmail}
          showMarkdown={showMarkdown}
          templates={templates}
          initialAttachments={initialAttachments}
          customContent={customContent}
          onSuccess={onSuccess}
          docTypeId={docTypeId}
          fetchDocuments={fetchDocuments}
          defaultEmails={defaultEmails}
          threadId={threadId}
          isForward={isForward}
        >
          <ComposeTab
            onClose={closeDrawer}
            title={title}
            autoOpen={visible}
            headerIcon={
              tab?.data?.replied_to_activity?.id ? (
                <CustomIcon icon="CornerLeft" />
              ) : isForward ? (
                <CommentOutlined />
              ) : (
                <MailOutlined />
              )
            }
          >
            <SendNotificationsForm docgen_url={process.env.DOCGEN_URL || 'http://localhost:9001'} />
          </ComposeTab>
        </SendEmailProvider>
      )}
    </>
  );
}
