export const DOCTYPE_MAP: Record<string, string> = {
  customer_company_id: 'Network::Company',
  supplier_company_id: 'Network::Company',
  sell_branch_id: 'Network::BranchAccount',
  buy_branch_id: 'Network::BranchAccount',
  branch: 'Network::BranchAccount',
};

export const COMPANY_FIELDS = ['customer_company_id', 'supplier_company_id'];
export const BRANCH_FIELDS = ['sell_branch_id', 'buy_branch_id', 'branch'];
export const COMPANY_TYPE = {
  customer_company_id: {
    is_customer: true,
  },
  supplier_company_id: {
    is_vendor: true,
  },
};
export const ESTIMATE_FIELDS = [
  'id',
  'item',
  'buy_branch_id',
  'sell_branch_id',
  'supplier_company_id',
  'customer_company_id',
  'uom',
  'quantity',
  'buy_exchange_rate',
  'buy_rate',
  'buy_currency',
  'buy_terms',
  'sell_exchange_rate',
  'sell_rate',
  'sell_currency',
  'sell_terms',
  'sell_remarks',
  'remarks',
  'equipment_type',
  'equipment_name',
  'shipment_id',
  'tag',
  'uuid',
  'sequence',
  'tax_percentage',
  'taxable',
  'taxability',
];

export const SEARCH_OPTIONS_MAP: Record<string, Record<string, string>> = {
  'Network::Company': {
    id: 'same_as_estimate',
    label: 'Same as Estimate',
    registered_name: 'Same as Estimate',
  },
  'Network::BranchAccount': {
    id: 'same_as_estimate',
    label: 'Same as Estimate',
    name: 'Same as Estimate',
  },
};
