import React, { useEffect, useState, useRef } from 'react';
import {
  Card,
  Row,
  Col,
  Input,
  message,
  Select,
  Form,
  DatePicker,
  dayjsGenerateConfig,
} from '@shipmnts/pixel-hub';
import { ContainerReportData } from 'operations/modules/reports/reportModels';
import { useApolloClient, useMutation } from '@apollo/client';
import { UPDATE_OTO_FOR_ROLLOVER_SHUTOUT } from 'operations/modules/booking/graphql/oceanTransportOrder';
import { ROLLOVER_BY_REASONS } from 'operations/modules/reports/constants';
import { OceanTransportOrderValue } from 'operations/models/OceanTransportOrder';
import { errorMessageHandlerGraphQL } from 'common';
import { GlobalSearch } from '@shipmnts/pixel-hub';

import {
  getCarriageWiseRoutingLegs,
  hasPrecarriageLeg,
  ROUTING_TYPE_MAIN_CARRIAGE,
  RoutingLegValue,
} from 'operations/models/RoutingLeg';
import { RoutingNodeValue } from 'operations/models/RoutingNode';
import BookingRoutingDetails from 'operations/modules/booking/components/BookingOrderForm/BookingRoutingDetails';
import { LOAD_TYPE_FCL, BOOKING_TYPE_SHIPPING_LINE, LOAD_TYPE_LCL } from 'operations/baseConstants';
import BookingOrderConfirmation from 'operations/modules/booking/components/BookingOrderForm/BookingOrderConfirmation';
import { getDisbaledCutoffDateMapping } from 'operations/modules/booking/helpers/CutoffDateHelper';
import { CarrierType } from 'operations/models/Carrier';
import { ROW_GUTTER } from 'operations/modules/shipment/constants';
import { DrawerFooter } from '@shipmnts/pixel-hub';
import { startCase as _startCase, omit as _omit, debounce as _debounce } from 'lodash';
import { cast, getSnapshot } from 'mobx-state-tree';
import { RoutingNodesHashValue } from 'operations/components/RoutingDetails/RoutingDetails';
import { fetchSplitUpdateOTOWarnings } from 'operations/modules/reports/helpers/containerActionsHelper';
import { EVENT_ROUTING_UPDATED } from '../actionHelper/constants';
import BookingOrderNewDesign from '../booking/components/BookingOrderForm/BookingOrderNewDesign';

type DestroyInterface<T> = T & {
  _destroy?: boolean;
};

const convertDatesTounix = (payload: { [key: string]: any }, fields: Array<string>) => {
  const result = fields.reduce((response: { [key: string]: number }, field: string) => {
    if (payload[field]) {
      response[field] = payload[field].unix();
    }
    return response;
  }, {});
  return result;
};

interface ContainerRolloverShutoutActionProps {
  containers: ContainerReportData[];
  oceanTransportOrder: OceanTransportOrderValue;
  onClose: () => void;
  onSuccess?: (sendEmail: boolean, rs: string[]) => void;
  actionName: string;
}

const UpdateOtoForRollover = React.memo(function ContainerRolloverShutoutAction(
  props: ContainerRolloverShutoutActionProps
): JSX.Element {
  const { containers, actionName, onClose, onSuccess, oceanTransportOrder } = props;
  const [sendEmail, setSendEmail] = useState<boolean>(true);
  const [form] = Form.useForm();
  const client = useApolloClient();
  const [fetchingWarnings, setFetchingWarnings] = useState(false);

  const [
    updateOtoForRollOver,
    {
      data: updateOtoForRollOverData,
      error: updateOtoForRollOverError,
      loading: updateOtoForRollOverLoading,
    },
  ] = useMutation(UPDATE_OTO_FOR_ROLLOVER_SHUTOUT);

  useEffect(() => {
    if (updateOtoForRollOverError) {
      message.error(updateOtoForRollOverError.message);
      return;
    }
    if (updateOtoForRollOverData && updateOtoForRollOverData?.update_oto_for_rollover_shutout) {
      message.success('Booking updated successfully');
      if (onSuccess) {
        const containers =
          updateOtoForRollOverData?.update_oto_for_rollover_shutout?.shipment_containers;
        const events = containers
          .map((c: any) => {
            return c.tracking_events
              .sort(
                (a: { sequence_number: number }, b: { sequence_number: number }) =>
                  b.sequence_number - a.sequence_number
              )
              .find((event: any) => event.name === EVENT_ROUTING_UPDATED);
          })
          .filter(Boolean);
        onSuccess(
          sendEmail,
          events.map((e: any) => e.id)
        );
      }
      if (onClose) onClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateOtoForRollOverData, sendEmail]);

  const routing_details_ref = useRef<{ runValidation: () => boolean }>();

  if (!oceanTransportOrder) return <></>;

  const booking_type = oceanTransportOrder.booking_type;
  const load_type = oceanTransportOrder.load_type;
  const carrier_types: CarrierType[] = ['ocean', 'nvocc'];
  if (load_type === LOAD_TYPE_LCL) carrier_types.push('coloader');

  const routing_nodes: RoutingNodesHashValue = {};
  let routing_legs = oceanTransportOrder?.routing_legs
    ? getSnapshot<RoutingLegValue[]>(cast(oceanTransportOrder.routing_legs)).slice()
    : [];

  routing_legs = routing_legs.map((rl) => {
    if (rl?.origin?.id) routing_nodes[rl.origin.id] = rl.origin;
    if (rl?.destination?.id) routing_nodes[rl.destination.id] = rl.destination;
    return {
      ...rl,
      origin: undefined,
      destination: undefined,
      origin_id: rl?.origin?.id,
      destination_id: rl?.destination?.id,
    };
  });

  const omitFields = ['wagon_number'];

  routing_legs = routing_legs.map((rl) =>
    _omit<RoutingLegValue>(rl, omitFields)
  ) as RoutingLegValue[];

  const onFinish = async (values: any) => {
    const old_routing_legs = oceanTransportOrder.routing_legs || [];
    const old_routing_nodes = old_routing_legs.reduce((acc, rl) => {
      rl.origin && acc.push(rl.origin);
      rl.destination && acc.push(rl.destination);
      return acc;
    }, [] as Array<RoutingNodeValue>);
    const new_routing_legs: Array<RoutingLegValue> = [
      ...(values.routing_details?.routing_legs || []),
    ];
    const new_routing_nodes: Array<RoutingNodeValue> = Object.values({
      ...(values.routing_details?.routing_nodes || {}),
    });

    old_routing_legs.forEach((rl) => {
      if (!new_routing_legs.find((l: RoutingLegValue) => l.id === rl.id)) {
        new_routing_legs.push({ ...rl, _destroy: true } as DestroyInterface<RoutingLegValue>);
      }
    });
    old_routing_nodes.forEach((rn: RoutingNodeValue) => {
      if (!new_routing_nodes.find((n: RoutingNodeValue) => n.id === rn.id)) {
        new_routing_nodes.push({ ...rn, _destroy: true } as DestroyInterface<RoutingNodeValue>);
      }
    });

    const oto = {
      ...convertDatesTounix(values, [
        'booking_date',
        'valid_till_date',
        'gate_open_date',
        'si_cutoff_date',
        'vgm_cutoff_date',
        'icd_cutoff_date',
        'ams_cutoff_date',
        'gate_close_date',
        'doc_cutoff_date',
        'stuffing_cutoff_date',
      ]),
      routing_legs: new_routing_legs.map((rl) => {
        const { vessel, origin, destination, global_carrier, ...restRL } = rl;
        return {
          ...restRL,
          global_carrier_id: global_carrier?.id,
          ...convertDatesTounix(rl, ['estimated_time_of_departure', 'estimated_time_of_arrival']),
          vessel_id: vessel?.imo,
        };
      }),
      routing_nodes: new_routing_nodes.map((rn: any) => {
        const { location, company, address, terminal, ...restRN } = rn;
        return { ...restRN, location_id: location?.id, terminal_id: terminal?.id };
      }),
      empty_pickup_location_id: values.empty_pickup_location?.id,
      empty_return_location_id: values.empty_return_location?.id,
      voyage_schedule_id: values.voyage_schedule_id,
      booking_number: values.booking_number,
      id: oceanTransportOrder.id,
      surveyor_company_id: values?.surveyor?.party_company?.id,
      surveyor_address_id: values?.surveyor?.party_address?.id,
    };

    const routing_updated_reason = values.routing_updated_reason.split('----');
    const routing_updated_at = values.routing_updated_at.unix();

    await fetchSplitUpdateOTOWarnings(
      client,
      {
        ocean_transport_order: _omit(oto, ['shipment_containers']),
        action: 'update_booking',
      },
      setFetchingWarnings,
      () => {
        updateOtoForRollOver({
          variables: {
            ocean_transport_order: oto,
            routing_update: {
              routing_updated_at: routing_updated_at,
              routing_updated_by: routing_updated_reason[0],
              routing_updated_reason: routing_updated_reason[1],
              shipment_container_ids: containers.map((c) => c.id),
            },
          },
        });
      }
    );
  };

  return (
    <Form
      name={actionName}
      form={form}
      layout="vertical"
      //onFinish gets triggered twice hence debounced the function call to prevent duplication.
      onFinish={_debounce(onFinish)}
      initialValues={{
        confirmed_booking: true,
        routing_details: { routing_legs, routing_nodes },
        routing_updated_at: dayjsGenerateConfig.getNow(),
        carrier: oceanTransportOrder.global_carrier,
        valid_till_date: dayjsGenerateConfig.getNow().add(3, 'day'),
        empty_pickup_location: oceanTransportOrder.empty_pickup_location,
        empty_return_location: oceanTransportOrder.empty_return_location,
        booking_number: oceanTransportOrder.booking_number,
      }}
    >
      {updateOtoForRollOverError && errorMessageHandlerGraphQL(updateOtoForRollOverError)}
      <Form.Item noStyle name="voyage_schedule_id">
        <span />
      </Form.Item>
      <Card>
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item
              name="routing_updated_reason"
              label="Select reason for changing the route"
              rules={[{ required: true }]}
              required
            >
              <Select showSearch allowClear={false}>
                {Object.keys(ROLLOVER_BY_REASONS).map((rollover_by: string) => (
                  <Select.OptGroup key={rollover_by} label={_startCase(rollover_by)}>
                    {ROLLOVER_BY_REASONS[rollover_by].map((option, index) => (
                      <Select.Option
                        key={`${rollover_by}_${index}`}
                        value={`${rollover_by}----${option}`}
                      >
                        {_startCase(option)}
                      </Select.Option>
                    ))}
                  </Select.OptGroup>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item required name="routing_updated_at" label="Rollover At">
              <DatePicker style={{ width: '100%' }} />
            </Form.Item>
          </Col>
        </Row>
      </Card>
      <Card title="Basic Details" className="custom-card margin-top-md ">
        <Row gutter={ROW_GUTTER}>
          <Col span={12}>
            <Form.Item
              required={booking_type === BOOKING_TYPE_SHIPPING_LINE || load_type === LOAD_TYPE_LCL}
              rules={[
                {
                  required:
                    booking_type === BOOKING_TYPE_SHIPPING_LINE || load_type === LOAD_TYPE_LCL,
                },
              ]}
              name="carrier"
              label={load_type === LOAD_TYPE_LCL ? 'Carrier/Co-loader' : 'Carrier'}
            >
              <GlobalSearch
                doc_type="Global::Carrier"
                disabled
                searchProps={{ carrier_type: carrier_types }}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              required
              rules={[
                {
                  required: true,
                },
              ]}
              name="booking_number"
              label="Booking number"
            >
              <Input placeholder="Booking number" />
            </Form.Item>
          </Col>
        </Row>
      </Card>
      <Card title="Routing Details" className="custom-card margin-top-md">
        <Form.Item
          noStyle
          shouldUpdate={(prevValues, currentValues) => prevValues.carrier !== currentValues.carrier}
        >
          {({ getFieldValue }) => {
            return (
              <Form.Item
                name="routing_details"
                rules={[
                  {
                    validator: (rule, value) => {
                      if (!routing_details_ref?.current?.runValidation()) {
                        return Promise.resolve();
                      }
                      return Promise.reject();
                    },
                  },
                ]}
                noStyle
              >
                <BookingRoutingDetails
                  ref={routing_details_ref}
                  validateVesselVoyage={load_type === LOAD_TYPE_FCL}
                  globalCarrierId={getFieldValue('carrier')?.id}
                  bookingType={booking_type}
                  isReeferContainer={
                    oceanTransportOrder.container_requests?.[0]?.is_reefer_container
                  }
                />
              </Form.Item>
            );
          }}
        </Form.Item>
      </Card>
      <Card title="Booking Confirmation Details" className="custom-card margin-top-md ">
        <Form.Item
          noStyle
          shouldUpdate={(prevValues, currentValues) =>
            prevValues.confirmed_booking !== currentValues.confirmed_booking ||
            prevValues.routing_details !== currentValues.routing_details
          }
        >
          {({ getFieldValue }) => {
            const confirmed_booking = getFieldValue('confirmed_booking');
            return (
              <BookingOrderNewDesign
                loadType={load_type}
                bookingType={booking_type}
                areCutoffRequired={
                  confirmed_booking &&
                  oceanTransportOrder.booking_type === BOOKING_TYPE_SHIPPING_LINE
                }
                disableEmptyPickupLocation
              />
            );
          }}
        </Form.Item>
      </Card>
      <Card
        title="Booking Cutoff Details"
        className="custom-card margin-top-md"
        style={{ marginBottom: '44px' }}
      >
        <Form.Item
          noStyle
          shouldUpdate={(prevValues, currentValues) =>
            prevValues.confirmed_booking !== currentValues.confirmed_booking ||
            prevValues.routing_details !== currentValues.routing_details
          }
        >
          {({ getFieldValue }) => {
            const confirmed_booking = getFieldValue('confirmed_booking');
            const routing_legs = getFieldValue('routing_details')?.routing_legs;
            const carriageWiseRoutingLegs = routing_legs
              ? getCarriageWiseRoutingLegs(routing_legs)
              : undefined;
            const etd =
              carriageWiseRoutingLegs?.[ROUTING_TYPE_MAIN_CARRIAGE]?.[0]
                ?.estimated_time_of_departure;

            const voyage_schedule_id = getFieldValue('voyage_schedule_id');
            const hasPrecarriage = hasPrecarriageLeg(routing_legs);

            return (
              <BookingOrderConfirmation
                etd={etd}
                areCutoffRequired={
                  confirmed_booking &&
                  oceanTransportOrder.booking_type === BOOKING_TYPE_SHIPPING_LINE
                }
                disableCutoff={getDisbaledCutoffDateMapping(
                  Boolean(voyage_schedule_id),
                  hasPrecarriage
                )}
                load_type={oceanTransportOrder.load_type}
                booking_type={oceanTransportOrder.booking_type}
                disableEmptyPickupLocation
              />
            );
          }}
        </Form.Item>
      </Card>
      <div
        style={{
          position: 'absolute',
          bottom: '24px',
          width: '98%',
          borderTop: '1px solid #e8e8e8',
          textAlign: 'right',
          background: '#fff',
          padding: '7px',
          margin: '-22px',
        }}
      >
        <DrawerFooter
          saveText="Update booking"
          loading={updateOtoForRollOverLoading || fetchingWarnings}
          onClose={onClose}
          onSave={form.submit}
          showSendEmail
          sendEmail={sendEmail}
          setSendEmail={setSendEmail}
          sendEmailText={`Send Routing Update Confirmation`}
        />
      </div>
    </Form>
  );
});

export default UpdateOtoForRollover;
