import React, { useState } from 'react';
import { Card, Button, Descriptions, Tooltip, Tag } from '@shipmnts/pixel-hub';
import { OceanTransportOrderValue } from 'operations/models/OceanTransportOrder';
import { startCase as _startCase } from 'lodash';
import BookingOrderOverviewCard from './BookingOrderOverviewCard';
import { statusTagColor } from 'operations/modules/reports/constants';
import { observer } from 'mobx-react-lite';
import { TagColorType } from '@shipmnts/pixel-hub/dist/components/tag/types';

export interface BookingOrderOverviewProps {
  oceanTransportOrder: OceanTransportOrderValue;
  disabled?: boolean;
  disableReason?: string;
  onUpdate?: (oceanTransportOrder: OceanTransportOrderValue) => void;
}

const BookingOrderOverview = observer(function BookingOrderOverview(
  props: BookingOrderOverviewProps
): JSX.Element {
  const { oceanTransportOrder, disabled, disableReason, onUpdate } = props;

  const [otoOverviewEdit, setOtoOverviewEdit] = useState<boolean>(false);

  if (otoOverviewEdit) {
    return (
      <BookingOrderOverviewCard
        oceanTransportOrder={oceanTransportOrder}
        onUpdate={onUpdate}
        setOtoOverviewEdit={setOtoOverviewEdit}
      />
    );
  }

  const statusTagProps = oceanTransportOrder?.status
    ? { type: statusTagColor[oceanTransportOrder?.status] as TagColorType }
    : {};

  return (
    <Card
      id="overview"
      title="Overview"
      extra={
        <Tooltip title={disableReason}>
          <Button
            disabled={disabled}
            onClick={() => {
              setOtoOverviewEdit(true);
            }}
          >
            Edit
          </Button>
        </Tooltip>
      }
    >
      <Descriptions column={{ xs: 2, sm: 4, md: 4 }} size="small" colon={false} layout="vertical">
        <Descriptions.Item key="sales_person" label="Order By">
          {oceanTransportOrder.salesPersonName}
        </Descriptions.Item>
        <Descriptions.Item key="created_by" label="Created By">
          {oceanTransportOrder.createdByName}
        </Descriptions.Item>
        <Descriptions.Item key="booked_by" label="Booked By Branch">
          {oceanTransportOrder.bookedByBranch?.name}
        </Descriptions.Item>
        <Descriptions.Item key="status" label="Status">
          <Tag {...statusTagProps}>{_startCase(oceanTransportOrder?.status)}</Tag>
        </Descriptions.Item>
        {oceanTransportOrder?.rate_type && (
          <Descriptions.Item key="rate_type" label="Rate Type">
            {_startCase(oceanTransportOrder?.rate_type)}
          </Descriptions.Item>
        )}
        {oceanTransportOrder?.contract_number && (
          <Descriptions.Item key="rate_type" label="RA Number">
            {oceanTransportOrder?.contract_number}
          </Descriptions.Item>
        )}
      </Descriptions>
    </Card>
  );
});

export default BookingOrderOverview;
