import { types, Instance, SnapshotIn, SnapshotOut, IAnyModelType } from 'mobx-state-tree';

import Carrier from './Carrier';
import Company from './Company';
import ContainerRequest from './ContainerRequest';
import Location from './Location';
import quotation from './quotation';
import SalesPerson from './SalesPerson';
import UserAccount from './UserAccount';
import UserContact from './UserContact';
import { baseConstants } from 'common';
import { SHIPMENT_STATUS_CANCELLED } from 'operations';
import Team from 'common/models/Team';
import { CONTAINER_TYPES } from 'common/baseConstants';
import ShipmentParty from 'operations/models/ShipmentParty';
import Address from 'common/models/Address';
import ProductOrderItem from 'operations/models/ProductOrderItem';
export const INQUIRY_PENDING_STATUS = 'Pending';
export const INQUIRY_WON_STATUS = 'Won';
export const INQUIRY_LOST_STATUS = 'Lost';
export const INQUIRY_CANCELED_STATUS = 'Inquiry Canceled';
export const INQUIRY_NEGATIVE_STATUS = [INQUIRY_CANCELED_STATUS, INQUIRY_LOST_STATUS];

const { LOAD_TYPE_FCL, WEIGHT_UNIT_MTS, LOAD_TYPE_LCL } = baseConstants;

const ShipmentContainer = types.model({
  id: types.identifier,
  container_type_code: types.maybe(types.maybeNull(types.string)),
});
const formatQty = (input: any) => {
  const number = parseFloat(input);
  if (number % 1 === 0) {
    return number.toFixed(0);
  } else {
    return number.toFixed(3);
  }
};

const AddressLocationUnion = types.union(
  { dispatcher: (snapshot) => (!!snapshot?.type ? Location : Address) },
  Location,
  Address
);

const Inquiry = types
  .model({
    id: types.identifier,
    inquiry_global_id: types.maybe(types.string),
    involved_branch_id: types.maybe(types.maybeNull(types.string)),
    container_requests: types.array(ContainerRequest),
    customer_company: types.maybe(types.maybeNull(Company)),
    freight_type: types.maybe(types.maybeNull(types.string)),
    load_type: types.maybe(types.maybeNull(types.string)),
    trade_type: types.maybe(types.maybeNull(types.string)),
    priority: types.maybe(types.maybeNull(types.string)),
    last_action_status: types.maybe(types.maybeNull(types.string)),
    inquiry_number: types.maybe(types.maybeNull(types.string)),
    status: types.maybe(types.maybeNull(types.string)),
    incoterms: types.maybe(types.maybeNull(types.string)),
    description: types.maybe(types.maybeNull(types.string)),
    target_rate: types.maybe(types.maybeNull(types.string)),
    target_closing_date: types.maybe(types.maybeNull(types.number)),
    received_at: types.maybe(types.maybeNull(types.number)),
    cargo_ready_date: types.maybe(types.maybeNull(types.number)),
    target_delivery_date: types.maybe(types.maybeNull(types.number)),
    customer_address: types.maybe(
      types.maybeNull(
        types.model({
          id: types.identifier,
          name: types.maybe(types.maybeNull(types.string)),
          print_address: types.maybe(types.maybeNull(types.string)),
        })
      )
    ),
    sales_person: types.maybe(types.maybeNull(SalesPerson)),
    pricing_person: types.maybe(types.maybeNull(UserContact)),
    origin: types.maybe(types.maybeNull(AddressLocationUnion)),
    destination: types.maybe(types.maybeNull(AddressLocationUnion)),
    origin_custom_clearance_location: types.maybe(types.maybeNull(Location)),
    destination_custom_clearance_location: types.maybe(types.maybeNull(Location)),
    preferred_carriers: types.maybe(types.maybeNull(types.array(Carrier))),
    tags: types.maybe(types.maybeNull(types.array(types.string))),
    next_actions: types.maybe(types.maybeNull(types.array(types.string))),
    collaborators: types.maybe(
      types.maybeNull(types.array(types.late((): IAnyModelType => UserContact)))
    ),
    cargos: types.array(ProductOrderItem),
    business_vertical: types.maybe(
      types.maybeNull(
        types.model({
          id: types.identifier,
          name: types.string,
        })
      )
    ),
    involved_branch: types.maybe(
      types.maybeNull(
        types.model({
          id: types.identifier,
          name: types.string,
        })
      )
    ),
    services: types.maybe(types.maybeNull(types.array(types.string))),
    inquiry_options: types.maybe(
      types.maybeNull(types.array(types.late((): IAnyModelType => quotation)))
    ),
    freight_terms: types.maybe(types.maybeNull(types.string)),
    other_terms: types.maybe(types.maybeNull(types.string)),
    destination_port_free_days: types.maybe(types.maybeNull(types.integer)),
    origin_carrier_free_days: types.maybe(types.maybeNull(types.integer)),
    destination_carrier_free_days: types.maybe(types.maybeNull(types.integer)),
    created_by: types.maybe(types.maybeNull(UserAccount)),
    business_received_through: types.maybe(types.maybeNull(types.string)),
    additional_loss_remarks: types.maybe(types.maybeNull(types.string)),
    place_of_carrier_receipt: types.maybe(types.maybeNull(Location)),
    place_of_carrier_delivery: types.maybe(types.maybeNull(Location)),
    billing_party: types.maybe(types.maybeNull(Company)),
    billing_party_address: types.maybe(
      types.maybeNull(
        types.model({
          id: types.identifier,
          name: types.maybe(types.maybeNull(types.string)),
          print_address: types.maybe(types.maybeNull(types.string)),
        })
      )
    ),
    shipments: types.maybe(
      types.maybeNull(
        types.array(
          types.model({
            id: types.maybe(types.maybeNull(types.identifier)),
            job_number: types.maybe(types.maybeNull(types.string)),
            shipment_booking_number: types.maybe(types.maybeNull(types.string)),
            gross_weight: types.maybe(types.maybeNull(types.number)),
            gross_volume: types.maybe(types.maybeNull(types.number)),
            status: types.maybe(types.maybeNull(types.string)),
            shipment_containers: types.maybe(types.array(ShipmentContainer)),
          })
        )
      )
    ),
    created_at: types.maybe(types.maybeNull(types.number)),
    color_code: types.maybe(types.maybeNull(types.string)),
    transit_days: types.maybe(types.maybeNull(types.string)),
    teams: types.maybe(types.maybeNull(types.array(Team))),
    port_of_loading: types.maybe(types.maybeNull(Location)),
    port_of_discharge: types.maybe(types.maybeNull(Location)),
    container_stuffing_location: types.maybe(types.maybeNull(AddressLocationUnion)),
    container_destuffing_location: types.maybe(types.maybeNull(AddressLocationUnion)),
    shipment_parties: types.maybe(types.maybeNull(types.array(ShipmentParty))),
    service_type: types.maybe(types.maybeNull(types.string)),
    movement_mode: types.maybe(types.maybeNull(types.string)),
    routing_remarks: types.maybe(types.maybeNull(types.string)),
    uuid: types.maybe(types.maybeNull(types.string)),
    vehicle_category: types.maybe(types.maybeNull(types.string)),
  })
  .views((self) => ({
    get gross_weight() {
      return self.cargos.reduce((sum, cargo) => {
        sum += cargo.gross_weight || 0;
        return sum;
      }, 0);
    },
    get cargo_weight_unit() {
      if (self.load_type === LOAD_TYPE_LCL) return self.cargos?.[0]?.volume_unit || 'cbm';
      return self.cargos?.[0]?.weight_unit || 'kgs';
    },
    get gross_volume() {
      return self.cargos.reduce((sum, cargo) => {
        sum += cargo.gross_volume || 0;
        return sum;
      }, 0);
    },
    get total_ordered() {
      if (self.load_type === LOAD_TYPE_LCL) {
        return this.gross_volume;
      }
      return this.gross_weight;
    },
    get outer_package_qty() {
      return self.cargos.reduce((sum, cargo) => {
        sum += cargo.outer_package_qty || 0;
        return sum;
      }, 0);
    },
    get total_allocated() {
      const total =
        self.shipments?.reduce((sum, shipment) => {
          if (shipment.status !== SHIPMENT_STATUS_CANCELLED) {
            if (self.load_type === LOAD_TYPE_LCL) {
              sum += shipment.gross_volume || 0;
            } else {
              sum += shipment.gross_weight || 0;
            }
          }
          return sum;
        }, 0) || 0;
      return total;
    },
    get allocated_fcl_container_map(): { [key: string]: number } {
      const containersByCode: { [key: string]: number } = {};
      self.shipments?.forEach((sp) => {
        if (sp.status !== SHIPMENT_STATUS_CANCELLED) {
          sp.shipment_containers?.forEach((sc) => {
            if (sc.container_type_code) {
              containersByCode[sc.container_type_code] =
                (containersByCode[sc.container_type_code] || 0) + 1;
            }
          });
        }
      });
      return containersByCode;
    },
    get containerSummary() {
      if (!self.container_requests) return '';
      if (self.load_type === LOAD_TYPE_FCL) {
        const containersByCode: { [key: string]: number } = {};
        self.container_requests.forEach((cr) => {
          if (cr.container_type)
            containersByCode[cr.container_type] =
              (containersByCode[cr.container_type] || 0) + (cr.quantity || 0);
        });
        return Object.keys(containersByCode)
          .sort()
          .map((containerType) => {
            return `${containersByCode[containerType]} X ${containerType}`;
          })
          .join(', ');
      }
      return `${formatQty(this.total_ordered)} ${this.cargo_weight_unit}`;
    },
    get allocatedQty() {
      if (self.load_type === LOAD_TYPE_FCL) {
        if (!self.container_requests) return '';
        const total: { [key: string]: number } = {};
        self.container_requests.forEach((cr) => {
          if (cr.container_type_code)
            total[cr.container_type_code] =
              (total[cr.container_type_code] || 0) + (cr.quantity || 0);
        });

        const allocated = this.allocated_fcl_container_map;
        const remains: { [key: string]: number } = {};
        Object.keys(total).forEach((key) => {
          remains[key] = allocated[key] || 0;
        });

        return Object.entries(remains)
          .sort()
          .map(([key, value]) => `${value} X ${CONTAINER_TYPES[key]}`)
          .join(', ');
      }
      let allocated = this.total_allocated;
      allocated =
        this.cargo_weight_unit === WEIGHT_UNIT_MTS && allocated !== 0
          ? allocated / 1000
          : allocated;
      return `${formatQty(allocated)} ${this.cargo_weight_unit}`;
    },
    get pendingQty() {
      if (self.load_type === LOAD_TYPE_FCL) {
        const allocated = this.allocated_fcl_container_map;

        const total: { [key: string]: number } = {};
        self.container_requests.forEach((cr) => {
          if (cr.container_type_code)
            total[cr.container_type_code] =
              (total[cr.container_type_code] || 0) + (cr.quantity || 0);
        });

        const remains: { [key: string]: number } = {};
        Object.keys(total).forEach((key) => {
          const pendingQty = total[key] - (allocated[key] || 0);
          if (pendingQty !== 0) remains[key] = Math.max(pendingQty, 0);
        });

        return Object.entries(remains)
          .sort()
          .map(([key, value]) => `${value} X ${CONTAINER_TYPES[key]}`)
          .join(', ');
      }
      let allocated = this.total_allocated;
      allocated =
        this.cargo_weight_unit === WEIGHT_UNIT_MTS && allocated !== 0
          ? allocated / 1000
          : allocated;
      return `${formatQty(Math.max(this.total_ordered - allocated, 0))} ${this.cargo_weight_unit}`;
    },
    getAllPartiesRolesMapping: function () {
      if (!self.customer_company) return [];
      return [
        {
          id: self.customer_company.id,
          registered_name: self.customer_company.registered_name,
          address_id: self.customer_address?.id,
          role: 'Customer',
        },
      ];
    },
  }));

export type InquiryValue = Instance<typeof Inquiry>;
export type InquiryIn = SnapshotIn<typeof Inquiry>;
export type InquiryOut = SnapshotOut<typeof Inquiry>;

export default Inquiry;
