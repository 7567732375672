import { omit } from 'lodash';
import {
  TRANSACTION_TYPE_DELIVERY,
  TRANSACTION_TYPE_OUTGOING,
  TRANSACTION_TYPE_RECEIPT,
} from './models/WarehouseTransaction';
import React from 'react';
import { useLocation } from 'wouter';
const RedirectToTransactionForm = (props: any) => {
  const { transaction, nextTransaction } = props;
  const { 1: navigate } = useLocation();
  const products: any = [];
  transaction?.warehouse_transaction_products?.forEach((tp: any) => {
    if (tp?.available_qty > 0)
      products.push({
        ...omit(tp, [
          'issued_qty',
          'available_qty',
          'id',
          'warehouse_transaction_type',
          '__typename',
          'warehouse_transaction',
        ]),
        compare_id: tp?.id,
        qty: tp?.available_qty,
        from_location:
          transaction?.type === TRANSACTION_TYPE_RECEIPT &&
          (nextTransaction === TRANSACTION_TYPE_DELIVERY ||
            nextTransaction === TRANSACTION_TYPE_OUTGOING)
            ? tp?.to_location
            : tp?.from_location,
        linked_to_id:
          transaction?.type === TRANSACTION_TYPE_RECEIPT &&
          (nextTransaction === TRANSACTION_TYPE_DELIVERY ||
            nextTransaction === TRANSACTION_TYPE_OUTGOING)
            ? null
            : tp?.id,
      });
  });
  const value = {
    ...transaction,
    warehouse_transaction_products: products,
  };
  navigate(`~/form/${nextTransaction}/new`, {
    state: {
      transaction: JSON.stringify(value),
    },
  });

  return <></>;
};

export default RedirectToTransactionForm;
