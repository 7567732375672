import React, { useCallback, useEffect, useState } from 'react';
import { DateConfigType, EventType, TrackingReferenceType } from '../../../types';
import {
  Input,
  Checkbox,
  Button,
  Col,
  Row,
  message,
  Form,
  Radio,
  Descriptions,
  getDateTimeFromUnix,
  RenderDate,
  RenderDateTime,
  RenderStartCase,
  SingleFormItem,
  disableFutureDate,
  dayjs,
  DatePicker,
} from '@shipmnts/pixel-hub';
import { useApolloClient, useMutation } from '@apollo/client';
import {
  getContainerReferenceFieldGraphQL,
  getShipmentReferenceFieldGraphQL,
  UPDATE_TRACKING_EVENT,
} from '../../../graphql/queries';
import { FieldType, getEventInputTypes } from './helpers';

import { SessionDataValue } from 'common/models/SessionData';
import { get } from 'lodash';
import { useEmailDrawerContext, renderDateWithAlert } from 'common';

// import { ContainerTypeRenderer } from 'common/report_manager/components/Renderer/cellRenderer';

type Props = {
  event: EventType;
  afterSubmit: (success?: boolean) => void;
  onDiscard: () => void;
  session?: SessionDataValue;
  type?: 'bulk' | 'single';
  referenceType?: TrackingReferenceType;
  event_ids?: number[];
  refData?: any;
};

const config = {
  rules: [{ type: 'object' as const, required: true, message: 'Please select time!' }],
};

const convertDateToUnix = (dateStr?: string) => {
  if (!dateStr) return null;
  return new Date(dateStr).getTime() / 1000;
};

const EventsForm = ({
  event,
  onDiscard,
  afterSubmit,
  session,
  type = 'single',
  event_ids,
  refData,
  referenceType,
}: Props): JSX.Element => {
  // States
  const initialValueMap = new Map();
  const eventData = event.event_data;
  Object.keys(eventData || {}).forEach((key) => {
    if (event?.event_data) {
      initialValueMap.set(key, get(event?.event_data, key));
    }
  });
  const [dependentFields, setdependentFields] = useState(initialValueMap);
  const [location, setLocation] = useState(event.location);
  const [otherLocations, setOtherLocations] = useState(new Map());
  const [referenceFields, setRefereneFields] = useState([]);
  const [mode, setMode] = useState('actual_date');

  const { setVisible, setEmailProps } = useEmailDrawerContext();
  const client = useApolloClient();
  const [form] = Form.useForm();
  const handleOnChange = (name: string, value: any) => {
    const newMap = new Map(dependentFields);
    newMap.set(name, value);
    setdependentFields(newMap);
  };

  const eventFields = getEventInputTypes(
    event,
    refData,
    session,
    form,
    handleOnChange,
    referenceFields
  );
  const actualDateConfig: DateConfigType = eventFields.actualDateConfig;
  const requiredReferenceFields = eventFields.referenceFields;
  const fields = eventFields.fields;
  const date_fields = fields.filter((field) => field.type === 'date').map((field) => field.name);
  const eventDate = event.actual_date ? dayjs(parseInt(event.actual_date) * 1000) : null;
  const estimatedDate = event.estimated_date ? dayjs(parseInt(event.estimated_date) * 1000) : null;

  const getReferenceFields = useCallback(async () => {
    let refereceFieldquery: any = null;
    if (requiredReferenceFields.length > 0) {
      if (event.reference_type === 'Shipment::ShipmentContainer') {
        refereceFieldquery = getContainerReferenceFieldGraphQL(
          requiredReferenceFields.map((item) => item.name)
        );
      }
      if (event.reference_type === 'Shipment::Shipment') {
        refereceFieldquery = getShipmentReferenceFieldGraphQL(
          requiredReferenceFields.map((item) => item.name)
        );
      }
    }
    if (refereceFieldquery) {
      const { data } = await client.query({
        query: refereceFieldquery,
        variables: { id: event.id },
      });
      if (data && data.fetch_reference_field_containers) {
        setRefereneFields(data.fetch_reference_field_containers);
      }
      if (data && data.fetch_reference_field_shipments) {
        setRefereneFields(data.fetch_reference_field_shipments);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client, event]);

  // if referenceType is not Shipmnet::ShipmentContainer, create new api for that reference type
  const [updateEvent, { data, loading, error }] = useMutation(UPDATE_TRACKING_EVENT);

  useEffect(() => {
    getReferenceFields();
  }, [getReferenceFields]);
  // Handlers
  const onFinish = async (values: any) => {
    const update_event_ids = event_ids ? event_ids : event?.id ? [parseInt(event?.id)] : [];
    if (update_event_ids.length <= 0) return;

    const variables: {
      ids?: number[];
      input?: any;
    } = { ids: update_event_ids, input: {} };
    const sendNotification = values.sendNotification;

    if (values.actual_date) {
      variables.input.actual_date = convertDateToUnix(values.actual_date);
    }
    if (values.estimated_date) {
      variables.input.estimated_date = convertDateToUnix(values.estimated_date);
    }

    if (location?.id !== event.location?.id) {
      variables.input.location_id = `${location?.id}`;
    }
    date_fields.forEach((key) => {
      const timestamp = convertDateToUnix(values[key]);
      if (timestamp) {
        values[key] = getDateTimeFromUnix(timestamp);
      }
    });

    delete values.actual_date;
    delete values.estimated_date;
    delete values.attachments;
    delete values.location;
    // delete values.vehicle_detention;
    // delete values.late_gate_in_detention; //we do not store flag in backend only store the detention amount
    delete values.sendNotification;
    // delete values.port_demurrage;

    if (variables.input.actual_date)
      variables.input.event_data = {
        // ...event.event_data,
        ...values,
      };

    if (values.transporter_company_id) {
      variables.input.event_data.transporter_company_id = values.transporter_company_id?.id;
    }
    if (values.late_gate_in_amount === 0) {
      delete variables.input.event_data.late_gate_in_amount;
    }

    ['igm_location', 'icd_igm_location', 'movement_location'].forEach((key) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (
        variables.input.event_data &&
        variables.input.event_data[key] &&
        typeof variables.input.event_data[key] === 'object'
      ) {
        variables.input.event_data[key + '_id'] = values[key]?.id;
        delete variables.input.event_data[key];
        delete variables.input.location;
      }
    });

    otherLocations.forEach((value, key, map) => {
      variables.input.event_data = { ...event.event_data, [key]: value.id };
    });
    updateEvent({
      variables: variables,
    }).then((res) => {
      if (sendNotification) {
        setEmailProps({
          resource_ids: (event_ids || [event.id]).map((i) => `${i}`),
          action_name:
            update_event_ids.length === 1
              ? 'tracking_event_notification'
              : 'tracking_event_notification_bulk',
          title: '',
          docTypeId: 'Shipment::TrackingEvent',
          companies_roles_mapping: (
            refData?.company_role_list?.map((item: any) => ({
              ...item,
              id: item.company_id,
              registered_name: item.name,
            })) || []
          ).filter((i: any) => i.company_id),
          showMarkDown: true,
        });
        setVisible(true);
      }
      afterSubmit(true);
    });
  };

  const handleLocationChange = (val: any) => {
    if (val) setLocation(val?.party_address);
  };

  useEffect(() => {
    if (error && !error.message) message.error('Error occured');
  }, [error, data]);

  const renderMap = (item: FieldType) => {
    const renderItem = get(referenceFields, item.name);
    if (item.type === 'date') {
      if (renderItem) {
        return <RenderDate val={renderItem} />;
      }
    } else if (item.type === 'datetime') {
      if (item.cellRenderer === 'cutoffRenderer' && renderItem) {
        return renderDateWithAlert(renderItem);
      }
      return <RenderDateTime val={renderItem} />;
    }
    if (item.cellRenderer === 'StartCaseRenderer') {
      return <RenderStartCase val={renderItem} />;
    }
    return <>{renderItem || '-'}</>;
  };

  return (
    <div className="detail-view add-edit-event-form">
      <Descriptions column={2} labelStyle={{ color: '#46546D' }}>
        {event?.location?.name && (
          <Descriptions.Item label="Location">{event?.location?.name}</Descriptions.Item>
        )}
        {requiredReferenceFields.map((item: FieldType) => {
          return (
            <Descriptions.Item key={item.id} label={item.label}>
              {renderMap(item)}
            </Descriptions.Item>
          );
        })}
      </Descriptions>

      <Form layout="vertical" form={form} requiredMark={false} onFinish={onFinish}>
        <Form.Item label="Event State">
          <Radio.Group onChange={(e) => setMode(e.target.value)} value={mode}>
            <Radio value="actual_date">Actual Date</Radio>
            <Radio value="estimated_date">Estimated Date</Radio>
          </Radio.Group>
        </Form.Item>
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          {mode === 'actual_date' && (
            <Col span={12}>
              <Form.Item
                className="custom-form-item"
                name="actual_date"
                label="Event Date & Time"
                initialValue={
                  eventDate ||
                  (!!actualDateConfig?.initialValue
                    ? dayjs(actualDateConfig?.initialValue * 1000)
                    : null)
                }
                {...config}
              >
                <DatePicker
                  onChange={() => {
                    actualDateConfig.onChange();
                  }}
                  disabledDate={disableFutureDate}
                  showTime
                  format="DD-MM-YYYY HH-mm"
                />
              </Form.Item>
            </Col>
          )}
          {mode === 'estimated_date' && (
            <Col span={12}>
              <Form.Item
                className="custom-form-item"
                name="estimated_date"
                label="Event Date & Time"
                initialValue={estimatedDate}
              >
                <DatePicker showTime format="DD-MM-YYYY HH-mm" />
              </Form.Item>
            </Col>
          )}

          {mode === 'actual_date' &&
            fields.map((item) => {
              const dependents = fields.find(
                (o) => o.dependency && o.dependency.name === item.name
              );
              const OnChange = (name: string, value: any) => {
                if (
                  dependents &&
                  item.name === 'location' &&
                  (item.type === 'address' || item.type === 'location')
                ) {
                  handleOnChange(name, value);
                  handleLocationChange(value);
                } else if (dependents) {
                  handleOnChange(name, value);
                } else if (
                  (item.name === 'location' && item.type === 'address') ||
                  item.type === 'location'
                ) {
                  handleLocationChange(value);
                } else if (item.type === 'address' || item.type === 'location') {
                  const newMap = new Map(otherLocations);
                  newMap.set(name, value);
                  setOtherLocations(newMap);
                }
              };
              if (item.dependency) {
                const val = dependentFields.get(item.dependency.name);
                const toShow =
                  (val && Array.isArray(item.dependency.value)
                    ? item.dependency.value.includes(val)
                    : val === item.dependency.value) || item.defaultDisplay;
                if (toShow) {
                  return (
                    <Col span={item.colSpan ? item.colSpan : 24} key={item.name}>
                      <SingleFormItem
                        item={item}
                        referenceType={referenceType}
                        referenceId={event.reference_id}
                        onChange={OnChange}
                        id={event.id}
                        type={type}
                        session={session}
                      />
                    </Col>
                  );
                } else {
                  return <></>;
                }
              }
              return (
                <Col span={item.colSpan ? item.colSpan : 24} key={item.name}>
                  <SingleFormItem
                    item={item}
                    referenceType={event.reference_type}
                    referenceId={event.reference_id}
                    onChange={OnChange}
                    id={event.id}
                    type={type}
                    session={session}
                  />
                </Col>
              );
            })}
        </Row>
        {mode === 'actual_date' && (
          <Form.Item
            name="remarks"
            className="custom-form-item"
            label="Remarks"
            initialValue={event.event_data?.remarks}
          >
            <Input type={'text'} />
          </Form.Item>
        )}
        {mode === 'actual_date' && (
          <Form.Item name="sendNotification" className="custom-form-item" valuePropName="checked">
            <Checkbox>Send Notification</Checkbox>
          </Form.Item>
        )}
        <div className="form-action">
          <Button
            style={{
              marginRight: '10px',
            }}
            onClick={onDiscard}
            size="small"
          >
            Close
          </Button>
          <Button
            htmlType="submit"
            type="primary"
            loading={loading}
            disabled={loading}
            size="small"
          >
            Submit
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default EventsForm;
