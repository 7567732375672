import React, { useState, useCallback } from 'react';
import { CheckboxChangeEvent, Dayjs } from '@shipmnts/pixel-hub';
import { Row, Col, Checkbox, Collapse, Typography, Form } from '@shipmnts/pixel-hub';
import { MinusCircleTwoTone, DatePicker } from '@shipmnts/pixel-hub';
import { RoutingLegValue } from 'operations/models/RoutingLeg';
import { RoutingNodeValue } from 'operations/models/RoutingNode';
import { getCutoffDateDisableFunction } from 'operations/modules/booking/helpers/CutoffDateHelper';
import { GlobalSearch } from '@shipmnts/pixel-hub';

const ROW_GUTTER = 16;
const datePickerStyle = { width: '100%' };
const { Text } = Typography;

declare type RoutingLegCallback = (
  key: string,
  value: RoutingLegValue[keyof RoutingLegValue],
  route_order: number
) => void;
declare type RoutingNodeCallback = (
  key: string,
  value: RoutingNodeValue[keyof RoutingNodeValue],
  id: string
) => void;

const cutoffDateDisable = getCutoffDateDisableFunction();

const VoyageSchedulePortCallRow = React.memo(function VoyageSchedulePortCallRow(props: {
  routingLeg: RoutingLegValue;
  index: number;
  onRoutingLegFieldChange: RoutingLegCallback;
  onRoutingNodeFieldChange: RoutingNodeCallback;
  node: RoutingNodeValue;
  removeHop: (route_order: number) => void;
  resetCutOff: (rn_id: string) => void;
  errorsPresent?: boolean;
  addCutoffChecked?: boolean;
  previous_routing_leg?: RoutingLegValue;
  next_routing_leg?: RoutingLegValue;
  onChangeNodeCutOffRequired?: (nodeId: string, required: boolean) => void;
  hideAddRemovePortCall?: boolean;
}): JSX.Element {
  const {
    index,
    node,
    onRoutingLegFieldChange,
    onRoutingNodeFieldChange,
    routingLeg,
    removeHop,
    resetCutOff,
    errorsPresent,
    addCutoffChecked,
    onChangeNodeCutOffRequired,
    hideAddRemovePortCall,
  } = props;
  const [addCutoff, setAddCutoff] = useState(!!addCutoffChecked);
  const addCutoffDisabled = !!addCutoffChecked && !node?._id;
  const isATDPresent = routingLeg?.actual_time_of_departure != null;

  const onChangeAddCutoff = useCallback(
    (e: CheckboxChangeEvent) => {
      if (addCutoffDisabled) return;
      const nodeId = node.id || node._id || '';
      const val = e.target.checked;
      if (!val) {
        resetCutOff(nodeId);
      }
      setAddCutoff(val);
      if (onChangeNodeCutOffRequired) onChangeNodeCutOffRequired(nodeId, val);
    },
    [node, resetCutOff, addCutoffDisabled, onChangeNodeCutOffRequired]
  );
  const onChangeCollapse = useCallback(() => {
    if (addCutoffDisabled) return;
    const nodeId = node.id || node._id || '';
    if (addCutoff) {
      resetCutOff(nodeId);
    }
    setAddCutoff(!addCutoff);
    if (onChangeNodeCutOffRequired) onChangeNodeCutOffRequired(nodeId, !addCutoff);
  }, [node, addCutoff, resetCutOff, addCutoffDisabled, onChangeNodeCutOffRequired]);

  return (
    <React.Fragment>
      <Row gutter={ROW_GUTTER}>
        <Col span={6}>
          <Form.Item
            required
            rules={[{ required: true }]}
            validateStatus={errorsPresent && !node?.location ? 'error' : 'success'}
            label="Port Name"
          >
            <GlobalSearch
              doc_type="Global::Location"
              value={node?.location}
              onChange={(value: any) =>
                onRoutingNodeFieldChange('location', value, node.id || node._id || '')
              }
              searchProps={{ type: ['Seaport'] }}
              disabled={Boolean(node?.id && !node?._id)}
            />
            {errorsPresent && !node?.location ? <Text type="danger">Required</Text> : <span />}
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label="Terminal Name">
            <GlobalSearch
              doc_type="Global::Location"
              value={node?.terminal}
              onChange={(value: any) =>
                onRoutingNodeFieldChange('terminal', value, node.id || node._id || '')
              }
              searchProps={{ type: ['PortTerminal'], parent_id: node?.location?.id }}
              disabled={isATDPresent}
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            required={index === 0}
            rules={[{ required: index === 0 }]}
            validateStatus={
              index === 0 && errorsPresent && !routingLeg?.estimated_time_of_departure
                ? 'error'
                : 'success'
            }
            label="ETD From Port"
          >
            <DatePicker
              value={routingLeg?.estimated_time_of_departure}
              onChange={(value: any) =>
                onRoutingLegFieldChange('estimated_time_of_departure', value, index)
              }
              style={datePickerStyle}
              disabled={isATDPresent}
              disabledDate={(current: Dayjs) => {
                return Boolean(
                  routingLeg?.estimated_time_of_arrival &&
                    current.isBefore(routingLeg?.estimated_time_of_arrival)
                );
              }}
            />
            {index === 0 && errorsPresent && !routingLeg?.estimated_time_of_departure ? (
              <Text type="danger">Required</Text>
            ) : (
              <span />
            )}
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label="ATD From Port">
            <DatePicker
              value={routingLeg?.actual_time_of_departure}
              onChange={(value: any) =>
                onRoutingLegFieldChange('actual_time_of_departure', value, index)
              }
              style={datePickerStyle}
              disabledDate={(current: Dayjs) => {
                return Boolean(current.isAfter(Date.now(), 'day'));
              }}
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label="ETA At Port">
            <DatePicker
              value={routingLeg?.estimated_time_of_arrival}
              onChange={(value: any) =>
                onRoutingLegFieldChange('estimated_time_of_arrival', value, index)
              }
              style={datePickerStyle}
              disabledDate={(current: Dayjs) => {
                return Boolean(
                  routingLeg?.estimated_time_of_departure &&
                    current.isAfter(routingLeg?.estimated_time_of_departure)
                );
              }}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={ROW_GUTTER} justify="space-between">
        <Col span={23}>
          <Collapse activeKey={addCutoff ? ['1'] : []} onChange={onChangeCollapse}>
            <Collapse.Panel
              showArrow={false}
              collapsible="disabled"
              forceRender
              header={
                <Checkbox
                  checked={addCutoff}
                  onChange={onChangeAddCutoff}
                  disabled={addCutoffDisabled || isATDPresent}
                >
                  Add Cutoff Details
                </Checkbox>
              }
              key="1"
            >
              <Row gutter={ROW_GUTTER}>
                <Col span={12}>
                  <Form.Item label="General Gate Open">
                    <DatePicker
                      disabledDate={(current: Dayjs) =>
                        cutoffDateDisable['gate_open_date'](current, routingLeg, node)
                      }
                      showTime
                      value={node?.gate_open_date}
                      onChange={(value: any) =>
                        onRoutingNodeFieldChange('gate_open_date', value, node.id || node._id || '')
                      }
                      style={datePickerStyle}
                      disabled={isATDPresent}
                    />
                  </Form.Item>
                  <Form.Item label="Reefer Gate Open">
                    <DatePicker
                      disabledDate={(current: Dayjs) =>
                        cutoffDateDisable['reefer_gate_open_date'](current, routingLeg, node)
                      }
                      showTime
                      value={node?.reefer_gate_open_date}
                      onChange={(value: any) =>
                        onRoutingNodeFieldChange(
                          'reefer_gate_open_date',
                          value,
                          node.id || node._id || ''
                        )
                      }
                      style={datePickerStyle}
                      disabled={isATDPresent}
                    />
                  </Form.Item>
                  <Form.Item label="Load List Cutoff">
                    <DatePicker
                      disabledDate={(current: Dayjs) =>
                        cutoffDateDisable['load_list_cutoff_date'](current, routingLeg)
                      }
                      showTime
                      value={node?.load_list_cutoff_date}
                      onChange={(value: any) =>
                        onRoutingNodeFieldChange(
                          'load_list_cutoff_date',
                          value,
                          node.id || node._id || ''
                        )
                      }
                      style={datePickerStyle}
                      disabled={isATDPresent}
                    />
                  </Form.Item>
                  <Form.Item label="Form 13 Cutoff">
                    <DatePicker
                      disabledDate={(current: Dayjs) =>
                        cutoffDateDisable['form13_cutoff_date'](current, routingLeg)
                      }
                      showTime
                      value={node?.form13_cutoff_date}
                      onChange={(value: any) =>
                        onRoutingNodeFieldChange(
                          'form13_cutoff_date',
                          value,
                          node.id || node._id || ''
                        )
                      }
                      style={datePickerStyle}
                      disabled={isATDPresent}
                    />
                  </Form.Item>
                  <Form.Item label="VGM Cutoff Date">
                    <DatePicker
                      showTime
                      value={node?.vgm_cutoff_date}
                      disabledDate={(current: Dayjs) =>
                        cutoffDateDisable['vgm_cutoff_date'](current, routingLeg)
                      }
                      onChange={(value: any) =>
                        onRoutingNodeFieldChange(
                          'vgm_cutoff_date',
                          value,
                          node.id || node._id || ''
                        )
                      }
                      style={datePickerStyle}
                      disabled={isATDPresent}
                    />
                    {errorsPresent && addCutoff && !node?.doc_cutoff_date ? (
                      <Text type="danger">Required</Text>
                    ) : (
                      <span />
                    )}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="General Gate Close">
                    <DatePicker
                      disabledDate={(current: Dayjs) =>
                        cutoffDateDisable['gate_close_date'](current, routingLeg, node)
                      }
                      showTime
                      value={node?.gate_close_date}
                      onChange={(value: any) =>
                        onRoutingNodeFieldChange(
                          'gate_close_date',
                          value,
                          node.id || node._id || ''
                        )
                      }
                      style={datePickerStyle}
                      disabled={isATDPresent}
                    />
                  </Form.Item>

                  <Form.Item label="Reefer Gate Close">
                    <DatePicker
                      disabledDate={(current: Dayjs) =>
                        cutoffDateDisable['reefer_gate_close_date'](current, routingLeg, node)
                      }
                      showTime
                      value={node?.reefer_gate_close_date}
                      onChange={(value: any) =>
                        onRoutingNodeFieldChange(
                          'reefer_gate_close_date',
                          value,
                          node.id || node._id || ''
                        )
                      }
                      style={datePickerStyle}
                      disabled={isATDPresent}
                    />
                  </Form.Item>
                  <Form.Item
                    required={addCutoff}
                    rules={[{ required: addCutoff }]}
                    validateStatus={
                      errorsPresent && addCutoff && !node?.si_cutoff_date ? 'error' : 'success'
                    }
                    label="SI Cutoff"
                  >
                    <DatePicker
                      disabledDate={(current: Dayjs) =>
                        cutoffDateDisable['si_cutoff_date'](current, routingLeg)
                      }
                      showTime
                      value={node?.si_cutoff_date}
                      onChange={(value: any) =>
                        onRoutingNodeFieldChange('si_cutoff_date', value, node.id || node._id || '')
                      }
                      style={datePickerStyle}
                      disabled={isATDPresent}
                    />
                    {errorsPresent && addCutoff && !node?.si_cutoff_date ? (
                      <Text type="danger">Required</Text>
                    ) : (
                      <span />
                    )}
                  </Form.Item>
                  <Form.Item
                    required={addCutoff}
                    rules={[{ required: addCutoff }]}
                    validateStatus={
                      errorsPresent && addCutoff && !node?.doc_cutoff_date ? 'error' : 'success'
                    }
                    label="Docs Handover"
                  >
                    <DatePicker
                      disabledDate={(current: Dayjs) =>
                        cutoffDateDisable['doc_cutoff_date'](current, routingLeg)
                      }
                      showTime
                      value={node?.doc_cutoff_date}
                      onChange={(value: any) =>
                        onRoutingNodeFieldChange(
                          'doc_cutoff_date',
                          value,
                          node.id || node._id || ''
                        )
                      }
                      style={datePickerStyle}
                      disabled={isATDPresent}
                    />
                    {errorsPresent && addCutoff && !node?.doc_cutoff_date ? (
                      <Text type="danger">Required</Text>
                    ) : (
                      <span />
                    )}
                  </Form.Item>
                </Col>
              </Row>
            </Collapse.Panel>
          </Collapse>
        </Col>
        {!hideAddRemovePortCall && (
          <Col span={1}>
            <div style={{ cursor: 'pointer', textAlign: 'center' }}>
              <MinusCircleTwoTone
                twoToneColor="#eb2f96"
                style={{ fontSize: '18px' }}
                onClick={() => removeHop(index)}
              />
            </div>
          </Col>
        )}
      </Row>
    </React.Fragment>
  );
});

export default VoyageSchedulePortCallRow;
