import { Form, Tabs, TabsProps } from '@shipmnts/pixel-hub';
import { useRef } from 'react';
import ContainerQuantity from '../ShipmentForm/ContainerQuantity';
import { TRADE_TYPE_EXPORT } from '../../constants';
import ContainerDetailsForm from '../DetailLayout/Containers/ContainerForm';
import React from 'react';
import { isLiveReeferCargo } from 'operations/models/Cargo';

const ContainerTabs = (props: any) => {
  const { shipment, disabled, disabledAdd, disableDelete, bookingType, tradeType, shipmentType } =
    props;
  const gridRef = useRef<{ runValidation: () => boolean }>();
  const items: TabsProps['items'] = [
    {
      key: '1',
      label: (
        <>
          By Container Qty
          <div style={{ fontSize: '12px' }}>Without Container Details</div>
        </>
      ),
      children: (
        <ContainerQuantity
          disabled={disabled}
          disableAdd={disabledAdd}
          disableDelete={disableDelete}
        />
      ),
    },
    {
      key: '2',
      label: (
        <>
          By Containers
          <div style={{ fontSize: '12px' }}>With Container Details</div>
        </>
      ),
      disabled: disabled || (!bookingType && tradeType === TRADE_TYPE_EXPORT),
      children: (
        <>
          <Form.Item noStyle shouldUpdate dependencies={['cargos']}>
            {({ getFieldValue }) => {
              const cargos = getFieldValue('cargos');
              const isLiveReefer = cargos ? isLiveReeferCargo(cargos) : false;
              return (
                <Form.Item
                  name="shipment_containers"
                  noStyle
                  shouldUpdate={(prevValues: any, currentValues: any) =>
                    prevValues.cargos !== currentValues.cargos
                  }
                >
                  <ContainerDetailsForm
                    gridRef={gridRef}
                    shipment={shipment}
                    isCreateContainer={true}
                    showAddDelete={true}
                    isLiveReefer={isLiveReefer}
                    tradeType={tradeType}
                    shipmentType={shipmentType}
                  />
                </Form.Item>
              );
            }}
          </Form.Item>
        </>
      ),
    },
  ];
  return (
    <Tabs
      destroyInactiveTabPane
      defaultActiveKey={tradeType === TRADE_TYPE_EXPORT ? '1' : '2'}
      items={items}
    />
  );
};

export default ContainerTabs;
