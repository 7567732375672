import React, { useContext, useState } from 'react';
import { Form, FormInstance } from '@shipmnts/pixel-hub';
import { useSession } from 'common';
import { SessionDataValue } from 'common/models/SessionData';

interface ReportRows {
  data?: any[];
  opening_balance?: any;
  closing_balance?: any;
  total_balance?: any;
}

interface BillingReportContextType {
  reportRows: ReportRows;
  setReportRows: (rows: ReportRows) => void;
  form: FormInstance;
  fetchData?: (values: any) => void;
  session?: SessionDataValue;
}

interface BillingReportProviderType {
  children: React.ReactNode;
  fetchData?: (values: any) => void;
}

const WmsBillingReportContext = React.createContext<BillingReportContextType | null>(null);
export const useBillingReportContext = (): BillingReportContextType => {
  const context = useContext(WmsBillingReportContext);
  if (!context)
    throw new Error(
      'WmsBillingReportContext must be called from within the WmsBillingReportContextProvider'
    );
  return context;
};

export const BillingReportProvider = (props: BillingReportProviderType) => {
  const { children, fetchData } = props;
  const [reportRows, setReportRows] = useState<ReportRows>({});
  const [form] = Form.useForm();
  const session = useSession();
  return (
    <WmsBillingReportContext.Provider
      value={{ reportRows, setReportRows, form, fetchData, session }}
    >
      {children}
    </WmsBillingReportContext.Provider>
  );
};
