import React, { useState } from 'react';
import { Button, Space } from '@shipmnts/pixel-hub';
import { ICellRendererParams } from '@ag-grid-community/core';
import { ForkOutlined } from '@ant-design/icons';
import SplitChargeModal from './SplitChargeModal';
import { useShipmentDetail } from '../ShipmentDetailLayout';
import { useShipmentEstimateStore } from 'operations/models/ShipmentEstimate';
import { FREIGHT_CHARGE_TAG, DUE_CARRIER_TAG } from 'operations/constants';
import {
  ACCOUNTING_STATUS_CLOSED,
  ACCOUNTING_STATUS_BILLING_CLOSED,
  FREIGHT_TYPE_AIR,
} from 'operations/modules/shipment/constants';

interface SplitActionType extends ICellRendererParams {
  buy_permission?: (params: { data: any }) => boolean;
  sell_permission?: (params: { data: any }) => boolean;
}

export default function SplitAction(props: SplitActionType) {
  const { data, buy_permission, sell_permission } = props;
  const { shipment } = useShipmentDetail();
  const buyPermissionValue = buy_permission ? buy_permission({ data }) : false;
  const sellPermissionValue = sell_permission ? sell_permission({ data }) : false;
  const { fetchEstimateData } = useShipmentEstimateStore();
  const [invoiceModal, setInvoiceModal] = useState<boolean>(false);

  const noPermissions = !buyPermissionValue && !sellPermissionValue;
  const notUnbilled = data.buy_status !== 'unbilled' && data.sell_status !== 'unbilled';
  const isSpecialTag =
    [FREIGHT_CHARGE_TAG, DUE_CARRIER_TAG].includes(data?.tag || '') &&
    shipment?.freight_type === FREIGHT_TYPE_AIR;
  const specialTagCondition =
    isSpecialTag && (data.sell_status !== 'unbilled' || !sellPermissionValue);
  const accountingStatusClosed = shipment?.accounting_status === ACCOUNTING_STATUS_CLOSED;
  const billingClosedExpenseBooked =
    shipment?.accounting_status === ACCOUNTING_STATUS_BILLING_CLOSED &&
    data.buy_status !== 'unbilled';

  const isDisabled =
    noPermissions ||
    notUnbilled ||
    specialTagCondition ||
    accountingStatusClosed ||
    billingClosedExpenseBooked;

  if (props.node.isRowPinned()) return <span></span>;

  return (
    <Space>
      <Button
        blue
        disabled={isDisabled}
        icon={<ForkOutlined />}
        loading={false}
        onClick={() => {
          setInvoiceModal(true);
        }}
      />
      {shipment && invoiceModal && (
        <SplitChargeModal
          shipment={shipment}
          estimate_data={data}
          buyPermitted={buyPermissionValue}
          sellPermitted={sellPermissionValue}
          onClose={() => {
            setInvoiceModal(false);
            fetchEstimateData?.();
          }}
        />
      )}
    </Space>
  );
}
