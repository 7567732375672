import React, { useContext } from 'react';

import './style.css';
import { useMutation } from '@apollo/client';
import {
  Button,
  Drawer,
  Form,
  message,
  Space,
  CustomIcon,
  ViewTableContext,
} from '@shipmnts/pixel-hub';

import { CREATE_INQUIRY } from 'sales_hub/graphql/inquiry';
import { InquiryValue } from 'sales_hub/models/inquiry';

import { createInquiryParamsFromForm } from './common';
import InquiryForm from './InquiryForm/InquiryForm';
import { isSalesPersonPresentInTeam } from 'common';

type Props = {
  onSuccess?: () => void;
  inquiry?: InquiryValue;
  isVisible: boolean;
  setIsVisible: (isVisible: boolean) => void;
};

const DuplicateInquiryDrawer = (props: Props) => {
  const { inquiry: _inquiry } = props;
  const inquiry: InquiryValue = JSON.parse(JSON.stringify(_inquiry));
  // Context
  const [form] = Form.useForm();
  const { refetchReport } = useContext<any>(ViewTableContext);

  // Queries/Mutations
  const [createInquiry, { loading }] = useMutation(CREATE_INQUIRY);

  // Effects

  // Constants and Methods

  const onFinish = (values: any, callback?: (inquiry_id: string) => Promise<void>) => {
    const inquiry = createInquiryParamsFromForm(values);
    if (!isSalesPersonPresentInTeam(inquiry.teams)) {
      message.error('Please enter sales person details in team');
      return;
    }
    createInquiry({
      variables: {
        inquiry: inquiry,
      },
    }).then((res) => {
      if (res?.data && !res?.errors) {
        message.success({
          content: (
            <>
              {`Created Inquiry ${res.data?.create_inquiry?.inquiry_number} Successfully, `}
              <span style={{ color: 'blue', cursor: 'pointer' }}>
                <u>Click here to view</u>
              </span>
            </>
          ),
          duration: 5,
          onClick: () =>
            window.open(
              `/view/rfq/${res.data?.create_inquiry?.inquiry_global_id}?first_load=1`,
              '_blank'
            ),
        });

        const updateStates = () => {
          form.resetFields();
          if (refetchReport) refetchReport();
          props.setIsVisible(false);
          if (props.onSuccess) props.onSuccess();
        };

        callback?.(res.data?.create_inquiry?.id)?.then(updateStates) ?? updateStates();
      }
    });
  };

  const onClose = () => {
    if (props.onSuccess) props.onSuccess();
    form.resetFields();
  };

  inquiry?.cargos?.map((i: any) => {
    delete i.id;
    return i;
  });
  inquiry?.container_requests?.map((i: any) => {
    delete i.id;
    return i;
  });
  inquiry?.teams?.map((i: any) => {
    delete i.id;
    return i;
  });

  // Deleting last_action_status from, we don't want to copy that from exhishting inquiry
  delete inquiry?.last_action_status;
  return (
    <Drawer
      title={
        <div style={{ display: 'flex' }}>
          <CustomIcon icon="CompassIcon" height={24} width={24} style={{ paddingRight: '5px' }} />
          Duplicate Inquiry #{props.inquiry?.inquiry_number}
        </div>
      }
      width={'80%'}
      onClose={onClose}
      open={props.isVisible}
      styles={{ body: { paddingBottom: 80 } }}
      footer={
        <Space style={{ width: '100%', justifyContent: 'flex-end' }}>
          <Button onClick={onClose} disabled={loading}>
            Cancel
          </Button>
          <Button
            type="primary"
            htmlType="submit"
            onClick={form.submit}
            loading={loading}
            disabled={loading}
          >
            Create Inquiry
          </Button>
        </Space>
      }
      destroyOnClose={true}
    >
      <InquiryForm
        form={form}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        inquiry={{ ...inquiry, id: '', received_at: new Date().getTime() / 1000 }}
        onFinish={onFinish}
        noTabs
      />
    </Drawer>
  );
};

export default DuplicateInquiryDrawer;
