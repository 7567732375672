import { gql } from '@apollo/client';

export const GET_ID_FROM_NAME = gql`
  query get_id_from_name($module_name: String!, $name: String!) {
    get_id_from_name(module_name: $module_name, name: $name) {
      id
    }
  }
`;

const fields = gql`
  fragment fields on FieldObjectType {
    id
    field_type
    filter_options
    label
    description
    width
    cell_renderer
    cell_editor
    sortable
    filterable
    groupable
    section
    doc_type_id
    editable
    use_record_loader
    record_loader_config
    validations
    type
  }
`;

export const templateFields = gql`
  fragment templateFields on TemplateObjectType {
    id
    resource_type
    content
    template_type
    name
    is_standard
    is_disabled
    is_default
    print_option {
      field_order
      fields {
        field_name
        field_type
        label
        description
        default
      }
    }
    created_by {
      id
    }
    col_map
    default_map
    import_config
  }
`;

export const GET_FIELDS = gql`
  query field($id: ID!) {
    field(id: $id) {
      ...fields
    }
  }
  ${fields}
`;
export const EDIT_FIELD = gql`
  mutation editFields($changes: [EditFieldsInputType!]!) {
    edit_fields(changes: $changes) {
      id
      message
    }
  }
`;

export const GET_CUSTOM_FIELDS_FROM_DOC_TYPE = gql`
  query get_custom_fields_from_doc_type($doc_type_id: String!, $section: String) {
    get_custom_fields_from_doc_type(doc_type_id: $doc_type_id, section: $section) {
      id
      db_column_name
      filterable
      width
      field_type
      label
      filter_options
      cell_renderer
      cell_editor
      type
      section
      doc_type_id
    }
  }
`;

export const DELETE_DOC = gql`
  mutation deletedoc($doc: DeleteDocInputType!) {
    delete_doc(doc: $doc) {
      message
    }
  }
`;

export const importableDoctypeField = gql`
  fragment importableDoctypeField on DocTypeObjectType {
    id
    description
    label
    data_import_templates {
      ...templateFields
    }
    fields: importable_fields {
      ...fields
    }
    doc_type_associations: importable_doc_type_associations {
      optional
      to_doc_type {
        id
        label
        fields: importable_fields {
          ...fields
        }
      }
    }
  }
  ${fields}
  ${templateFields}
`;

export const GET_DATA_IMPORT = gql`
  query get_data_import($id: ID!) {
    get_data_import(id: $id) {
      id
      file
      name
      status
      error_rows
      record_created
      doctype_id
      doctype {
        ...importableDoctypeField
      }
      created_at
      template {
        ...templateFields
      }
      created_by {
        id
        username
        first_name
        last_name
      }
    }
  }
  ${templateFields}
  ${importableDoctypeField}
`;
