import { CompanyValue } from 'network/models/Company';

import { COMPANY_TYPE_MAP } from 'common/baseConstants';

import { getPrimarySalesPersonFromTeams } from 'common/helpers/helper';
import { pick } from 'lodash';

export const getFormInitialValues = (company?: CompanyValue) => {
  if (!company) return;
  const sales_person = getPrimarySalesPersonFromTeams(company?.teams || []);

  let formPayload = {
    ...pick(company, [
      'id',
      'registered_name',
      'company_group',
      'country_of_incorporation',
      'company_identification_number',
      'tax_deduction_id',
      'tax_registration_number',
      'entity_type',
      'global_company_account',
      'sales_partner',
      'company_type',
      'domain',
    ]),
    sales_person,
  };

  formPayload = {
    ...formPayload,
    ...(company.company_type ? COMPANY_TYPE_MAP[company.company_type] : {}),
  };

  if (formPayload.sales_partner) {
    formPayload.sales_partner = pick(formPayload.sales_partner, ['id', 'registered_name']);
  }
  return formPayload;
};
