import { RowNode } from '@ag-grid-community/core';
import getDoctypeEnableDisableHelperAction from '../doctypeEnableDisableHelper';
import { Action } from 'operations/modules/actionHelper/models';

const getTemplateBulkActions = (selectedNodes: RowNode[]) => {
  if (selectedNodes.length === 0) return [];
  const isNotStandard = selectedNodes.every((node) => !node?.data?.is_standard);
  const actions: Action[] = [];
  const disableAction = getDoctypeEnableDisableHelperAction('Network::Template', selectedNodes);
  if (disableAction && isNotStandard) {
    actions.push(disableAction);
  }

  return actions;
};

export default getTemplateBulkActions;
