import { useLazyQuery } from '@apollo/client';
import { Button, Spin, Tag, dayjs } from '@shipmnts/pixel-hub';
import React, { useEffect, useState } from 'react';
import { FETCH_DOCKFLOW_EVENTS } from '../../../graphql/queries';
import {
  ContainerType,
  DockFlowEventType,
  DockFlowSingleEventType,
  TrackingStatusType,
} from '../../../types';
import { get as _get } from 'lodash';
import { AnchorSvg } from '../../../Icons';
import Timeline from '../../timeline/Timeline';

interface RenderDocFlowEventsProps {
  dockFlowLastStatus: {
    id?: string;
    last_event: DockFlowSingleEventType;
    status?: TrackingStatusType;
  };
  container: ContainerType;
}

type MoreEvents = {
  moreEvents: DockFlowEventType[];
};
type StructuredEventType = DockFlowEventType & MoreEvents;

const RenderSingleEvent = ({
  event,
  lastOccuredEvent,
}: {
  event: StructuredEventType;
  lastOccuredEvent: DockFlowSingleEventType | null;
}) => {
  const [showMoreEvents, setShowMoreEvents] = useState(false);

  return (
    <div style={{ marginTop: '24px' }} key={event.location}>
      <Timeline
        headerText={event.location}
        headerIcon={AnchorSvg}
        isAccordian={false}
        events={event.events}
        lastOccuredEvent={lastOccuredEvent}
      />
      {showMoreEvents &&
        event.moreEvents.map((item) => {
          return (
            <Timeline
              headerText={item.location}
              headerIcon={AnchorSvg}
              isAccordian={false}
              events={item.events}
              key={item.location}
              lastOccuredEvent={lastOccuredEvent}
            />
          );
        })}
      {event.moreEvents.length > 0 && (
        <div className="staion-crossed-count" onClick={() => setShowMoreEvents(!showMoreEvents)}>
          {!showMoreEvents ? `+ ${event.moreEvents.length}` : `-`}
        </div>
      )}
    </div>
  );
};

export default function RenderDocFlowEvents(props: RenderDocFlowEventsProps) {
  const { dockFlowLastStatus, container } = props;
  const [terminalTrackingExpand, setTerminalTrackingExpand] = useState<boolean>(false);
  // const { updateLastOccuredEvent } = useTrackerContext();
  // const [lastOccuredEvent, setLastOccuredEvent] = useState<DockFlowSingleEventType | null>(null);
  let lastOccuredEvent = dockFlowLastStatus?.last_event;
  const [getDockflowEvents, { data: dockflowEventsData, loading }] = useLazyQuery(
    FETCH_DOCKFLOW_EVENTS,
    {
      variables: {
        tracking_request_id: dockFlowLastStatus?.id,
      },
    }
  );

  useEffect(() => {
    getDockflowEvents();
  }, [getDockflowEvents]);
  const handleExpandClick = () => {
    setTerminalTrackingExpand((prev) => !prev);
  };

  if (loading) {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Spin />
      </div>
    );
  }

  const { last_event } = dockFlowLastStatus;
  const structuredEvents: StructuredEventType[] = [];
  const dockflowEvents: DockFlowEventType[] = _get(dockflowEventsData, 'fetch_dockflow_events', []);

  if (dockflowEvents?.length === 0) {
    return (
      <div
        style={{
          fontWeight: 500,
          fontSize: '14px',
          margin: '10px 0px',
          overflowWrap: 'break-word',
        }}
      >
        <Tag type="caution">
          Data for container {container.container_number} is being gathered. This can take to
          maximum 3 hours
        </Tag>
      </div>
    );
  }
  dockFlowLastStatus.status !== 'inactive' &&
    Array.isArray(dockflowEvents) &&
    dockflowEvents.forEach((event, index) => {
      event.events.forEach((i: DockFlowSingleEventType) => {
        if (i.actual_date) {
          if (lastOccuredEvent) {
            const currentEventDate = dayjs(i.actual_date);
            const currentDate = dayjs(new Date());
            const prevEventDate = dayjs(lastOccuredEvent.actual_date);
            if (
              !currentEventDate.isBefore(prevEventDate) &&
              !currentDate.isBefore(currentEventDate)
            ) {
              lastOccuredEvent = i;
            }
          } else lastOccuredEvent = i;
        }
      });
      const prevEvent = dockflowEvents[index - 1]?.events[0];
      if (
        event?.events[0]?.name === 'departure from port of loading' &&
        prevEvent?.name === 'departure from port of loading'
      ) {
        const eventDate = event.events[0]?.actual_date || event.events[0]?.estimated_time;
        const previousEventDate = prevEvent?.actual_date || prevEvent?.estimated_time;
        const currentDate = dayjs(eventDate);
        const prevEventDate = dayjs(previousEventDate);
        if (!currentDate.isSame(prevEventDate)) {
          structuredEvents[structuredEvents.length - 1].moreEvents.push(event);
        } else {
          structuredEvents.push({ ...event, moreEvents: [] });
        }
      } else {
        structuredEvents.push({ ...event, moreEvents: [] });
      }
    });

  if (!terminalTrackingExpand) {
    return (
      <>
        <div style={{ marginTop: '24px' }}>
          {lastOccuredEvent && lastOccuredEvent?.current_location && (
            <Timeline
              headerIcon={AnchorSvg}
              headerText={`${lastOccuredEvent?.current_location}`}
              isAccordian={false}
              events={[lastOccuredEvent]}
              lastOccuredEvent={lastOccuredEvent}
            />
          )}
        </div>
        {((last_event?.current_location && dockFlowLastStatus.status === 'active') ||
          dockFlowLastStatus.status === 'inactive') && (
          <Button className="expand-all-btn" ghost onClick={handleExpandClick}>
            Expand All
          </Button>
        )}
      </>
    );
  }
  return (
    <>
      {structuredEvents.map((item, index: number) => (
        <RenderSingleEvent event={item} key={index} lastOccuredEvent={lastOccuredEvent} />
      ))}

      <Button className="expand-all-btn" ghost onClick={handleExpandClick}>
        Collapse
      </Button>
    </>
  );
}
