import React from 'react';

import { SessionDataValue } from 'operations/models/SessionData';
import { NEGATIVE_TYPE, PRIMARY_TYPE, SECONDARY_TYPE, TERTIARY_TYPE } from '../constants';
import { Action } from '../models';
import {
  ApartmentOutlined,
  CloseCircleOutlined,
  EditOutlined,
  MailOutlined,
  MessageOutlined,
  ShareAltOutlined,
  TeamOutlined,
} from '@shipmnts/pixel-hub';
import { startCase } from 'lodash';
import {
  performDuplicateInquiryAction,
  performInquiryAction,
  performInquiryCancelAction,
  performInquiryEditAction,
  performInquiryLostAction,
  performInquiryWinAction,
  performInquiryOptionCreation,
  performCreateResource,
  performInquiryOptionSharePDF,
  addCollaboratorsRenderer,
  updateTeamRenderer,
  performInquiryAmendAction,
} from './inquiryActionRenderer';
import { CustomIcon } from '@shipmnts/pixel-hub';
import { hasPermission } from '@shipmnts/pixel-hub';

import { PERMISSOIN_ADMIN } from 'operations/modules/reports/constants';
import { CopyOutlined, TagsOutlined, FilePdfOutlined } from '@shipmnts/pixel-hub';

const EVENT_CANCELED = 'Inquiry Canceled';
const EVENT_WON = 'Won';
const EVENT_LOST = 'Lost';
const INQ_SYSTEM_EVENTS_ARR = [EVENT_CANCELED, EVENT_WON, EVENT_LOST];

export const notifyOnEmail = (payload: any): Action => {
  const { setEmailProps, inquiry, setVisible } = payload;
  return {
    type: TERTIARY_TYPE,
    key: 'notify_on_email',
    displayComponent: 'Notify On Email',
    icon: <MailOutlined />,
    isEnable: true,
    onClick: () => {
      setEmailProps({
        docTypeId: 'SalesHub::Inquiry',
        resource_ids: [inquiry.id],
        fetchDocumentParents: [
          {
            parent_type: 'inquiry',
            parent_ids: [inquiry.id],
          },
        ],
      });
      setVisible(true);
    },
  };
};

export const notifyOnChat = (payload: any): Action => {
  const { setEmailProps, inquiry, setVisible } = payload;
  return {
    type: TERTIARY_TYPE,
    key: 'notify_on_chat',
    displayComponent: 'Notify On Chat',
    icon: <MessageOutlined />,
    isEnable: true,
    onClick: () => {
      setEmailProps({
        docTypeId: 'SalesHub::Inquiry',
        resource_ids: [inquiry.id],
        fetchDocumentParents: [
          {
            parent_type: 'inquiry',
            parent_ids: [inquiry.id],
          },
        ],
        showMarkDown: true,
        showEmail: false,
        isForward: true,
      });
      setVisible(true);
    },
  };
};

export const getInquiryActions = (
  inquiry: any,
  setEmailProps: (emailProps: any) => void,
  setVisible: (visible: boolean) => void,
  sessionData: SessionDataValue
) => {
  const { next_actions, last_action_status } = inquiry; // next action will be array of event names
  const actions: Action[] = [];
  const { permissions } = sessionData;

  const childComponents = (next_actions || []).map((action: string) => ({
    key: action,
    type: PRIMARY_TYPE,
    displayComponent: startCase(action),
    isEnable: true,
    performAction: performInquiryAction,
    description: action,
    extraProps: { event_name: action },
  }));
  //  Returns Next Possible actions
  childComponents &&
    childComponents.length > 0 &&
    actions.push({
      key: 'Workflow',
      type: PRIMARY_TYPE,
      icon: <ApartmentOutlined />,
      displayComponent: 'Workflow',
      isEnable: true,
      childComponents: childComponents,
    });
  actions.push({
    key: 'edit_inquiry',
    type: SECONDARY_TYPE,
    displayComponent: 'Edit Inquiry',
    icon: <CustomIcon height="15" icon="Edit" width="15" />,
    isEnable: ![EVENT_CANCELED, EVENT_WON].includes(last_action_status),
    performAction: performInquiryEditAction,
    description: 'edit_inquiry',
  });
  actions.push({
    key: 'amend_inquiry',
    type: SECONDARY_TYPE,
    isAdmin: true,
    displayComponent: 'Amend Inquiry',
    icon: <EditOutlined />,
    isEnable:
      hasPermission(permissions, {
        name: PERMISSOIN_ADMIN,
        docType: 'SalesHub::Inquiry',
      }) && [EVENT_WON].includes(last_action_status),
    performAction: performInquiryAmendAction,
    description: 'amend_inquiry',
  });
  actions.push({
    key: 'duplicate_inquiry',
    type: SECONDARY_TYPE,
    displayComponent: 'Duplicate Inquiry',
    icon: <CopyOutlined />,
    isEnable: true,
    performAction: performDuplicateInquiryAction,
    description: 'duplicate_inquiry',
  });
  actions.push({
    key: 'inquiry_won',
    type: SECONDARY_TYPE,
    isAdmin: [EVENT_LOST, EVENT_CANCELED].includes(last_action_status),
    displayComponent: 'Mark Inquiry As Won',
    icon: <CustomIcon height="15" icon="FileLockIcon" width="15" />,
    isEnable:
      !INQ_SYSTEM_EVENTS_ARR.includes(last_action_status) ||
      ([EVENT_LOST, EVENT_CANCELED].includes(last_action_status) &&
        // FIX ME
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        hasPermission(permissions, {
          name: PERMISSOIN_ADMIN,
          docType: 'SalesHub::Inquiry',
        })),
    performAction: performInquiryWinAction,
    description: 'win_inquiry',
  });

  actions.push({
    key: 'create_inquire_option',
    type: SECONDARY_TYPE,
    displayComponent: 'Create Quotation',
    icon: <TagsOutlined />,
    isEnable: !INQ_SYSTEM_EVENTS_ARR.includes(last_action_status),
    performAction: performInquiryOptionCreation,
    description: 'Create Quotation',
  });
  actions.push({
    key: 'Share Quote',
    type: SECONDARY_TYPE,
    displayComponent: 'Share Quotation',
    icon: <FilePdfOutlined />,
    isEnable: (inquiry?.inquiry_options?.length || 0) > 0,
    performAction: performInquiryOptionSharePDF,
    description: 'Share Quotation PDF To customer',
  });
  actions.push(notifyOnChat({ setEmailProps, setVisible, inquiry }));
  actions.push(notifyOnEmail({ setEmailProps, setVisible, inquiry }));
  actions.push({
    key: 'lost_inquiry',
    type: NEGATIVE_TYPE,
    isAdmin: last_action_status === EVENT_WON,
    displayComponent: 'Mark Inquiry As Lost',
    icon: <CloseCircleOutlined />,
    isEnable:
      !INQ_SYSTEM_EVENTS_ARR.includes(last_action_status) ||
      (last_action_status === EVENT_WON &&
        // FIX ME
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        hasPermission(permissions, {
          name: PERMISSOIN_ADMIN,
          docType: 'SalesHub::Inquiry',
        })),
    performAction: performInquiryLostAction,
    // description: 'delete_action',
    // extraProps: { event_name: action },
  });
  actions.push({
    key: 'cancel_inquiry',
    type: NEGATIVE_TYPE,
    isAdmin: [EVENT_LOST, EVENT_WON].includes(last_action_status),
    displayComponent: 'Cancel Inquiry',
    icon: <CustomIcon height={15} width={15} icon="FileTimesIcon" />,
    isEnable:
      !INQ_SYSTEM_EVENTS_ARR.includes(last_action_status) ||
      ([EVENT_LOST, EVENT_WON].includes(last_action_status) &&
        // FIX ME
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        hasPermission(permissions, {
          name: PERMISSOIN_ADMIN,
          docType: 'SalesHub::Inquiry',
        })),
    performAction: performInquiryCancelAction,
  });

  actions.push({
    key: 'create_shipment',
    type: PRIMARY_TYPE,
    displayComponent: 'Create Shipment',
    isEnable: inquiry?.last_action_status === 'Won' && inquiry?.freight_type !== 'road',
    childComponents: [
      {
        key: 'direct',
        type: PRIMARY_TYPE,
        displayComponent: 'Direct',
        isEnable: true,
        extraProps: { shipment_type: 'direct', resource_type: 'shipment' },
        performAction: performCreateResource,
      },
      {
        key: 'back_to_back',
        type: PRIMARY_TYPE,
        displayComponent: 'Back To Back',
        isEnable: true,
        extraProps: { shipment_type: 'back_to_back', resource_type: 'shipment' },
        performAction: performCreateResource,
      },
    ],
  });

  actions.push({
    key: 'create_shipment_land',
    type: PRIMARY_TYPE,
    displayComponent: 'Create Shipment',
    isEnable: inquiry?.last_action_status === 'Won' && inquiry?.freight_type === 'road',
    extraProps: { resource_type: 'shipment', shipment_type: 'back_to_back' },
    performAction: performCreateResource,
  });

  actions.push({
    key: 'create_order',
    type: PRIMARY_TYPE,
    displayComponent: 'Create Order',
    isEnable:
      inquiry?.last_action_status === 'Won' &&
      (inquiry?.freight_type === 'air' || inquiry?.freight_type === 'ocean'),
    extraProps: { resource_type: 'order' },
    performAction: performCreateResource,
  });

  actions.push({
    type: PRIMARY_TYPE,
    key: 'add_collaborators',
    icon: <ShareAltOutlined />,
    isEnable: true,
    displayComponent: 'Add Collaborators',
    performAction: addCollaboratorsRenderer,
  });

  actions.push({
    type: PRIMARY_TYPE,
    key: 'update_team',
    icon: <TeamOutlined />,
    isEnable: !INQ_SYSTEM_EVENTS_ARR.includes(inquiry?.last_action_status),
    displayComponent: 'Update Team Details',
    performAction: updateTeamRenderer,
  });

  return actions;
};

export const getInquiryOptionCreateActions = (inquiry: any) => {
  const { last_action_status } = inquiry;

  return [
    {
      key: 'create_inquire_option',
      type: PRIMARY_TYPE,
      displayComponent: 'Create Quotation',
      icon: <TagsOutlined />,
      isEnable: !INQ_SYSTEM_EVENTS_ARR.includes(last_action_status),
      performAction: performInquiryOptionCreation,
      description: 'Create Quotation',
    },
  ];
};
