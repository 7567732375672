import { Tag } from '@shipmnts/pixel-hub';
import React from 'react';
import { VOYAGE_SCHEDULE_MATCH, VOYAGE_SCHEDULE_PARTIAL } from '../../constants';
import { TagColorType } from '@shipmnts/pixel-hub/dist/components/tag/types';

const voyageScheduleTypeColor: Record<string, TagColorType> = {
  [VOYAGE_SCHEDULE_PARTIAL]: 'warning-secondary',
  [VOYAGE_SCHEDULE_MATCH]: 'success-secondary',
};

const VoyageScheduleTypeRenderer = (props: any) => {
  const voyageScheduleType =
    // eslint-disable-next-line eqeqeq
    props?.selectedLocationId == props?.data?.routing_nodes_location_id?.id
      ? VOYAGE_SCHEDULE_MATCH
      : VOYAGE_SCHEDULE_PARTIAL;

  return <Tag type={voyageScheduleTypeColor[voyageScheduleType]}>{voyageScheduleType}</Tag>;
};

export default VoyageScheduleTypeRenderer;
