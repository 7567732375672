import React from 'react';
import { GridOptions } from '@ag-grid-community/core';
import { DocumentUpload, Form, FormInstance, hasPermission, message } from '@shipmnts/pixel-hub';
import { useRef } from 'react';
// import BookingRoutingDetails from 'operations/modules/booking/components/BookingOrderForm/BookingRoutingDetails';
import {
  DOCUMENT_TYPE_HOUSE,
  FREIGHT_TYPE_AIR,
  // DUTY_FREE,
  // HOME_BILL,
  SHIPMENT_TYPE_BACK_TO_BACK,
  SHIPMENT_TYPE_CONSOL,
  SHIPMENT_TYPE_HOUSE,
  TRADE_TYPE_EXPORT,
  TRADE_TYPE_IMPORT,
  BUYER_CONSOLIDATION,
  SELLER_CONSOLIDATION,
  SELF_CONSOLIDATION,
  SHIPMENT_TYPE_DIRECT,
  FREIGHT_TYPE_OCEAN,
  DOCUMENT_TYPE_MASTER,
} from '../../constants';
import { BOOKING_TYPE_SELF, containerSettingsMap, LOAD_TYPE_FCL } from 'operations/baseConstants';
import ExportClearanceDetails from '../ExportClearanceDetails';
import BasicDetails from './BasicDetails';
import PartyDetails from './PartyDetailNew';
import { ShipmentContainerValue } from 'operations/models/ShipmentContainer';
// import BookingConfirmationDetails from './BookingConfirmationDetails';
import BookingTermsAndConditions from './BookingTermsAndConditions';
import ShipmentDetails from './ShipmentDetails';
import BillOfEntryDetails from './BillOfEntryDetails';
import ShippingBillDetails from './ShippingBillDetails';
import ShipmentDocumentDetailsNew from '../ShipmentDocumentDetailsNew';
// import BookingRoutingDetails from 'operations/modules/booking/components/BookingOrderForm/BookingRoutingDetails';
import {
  CREATE_HOUSE_SHIPMENT,
  CREATE_SHIPMENT,
  UPDATE_SHIPMENT_NEW,
} from '../ShipmentForm/graphql';
import { CREATE_PLANNED_SHIPMENT } from 'operations/graphql/shipment';

import ShipmentServiceDetails, { ShipmentServiceRenderType } from '../ShipmentServiceDetails';
// import InvoiceDetails from '../InvoiceDetails';
import BookingConfirmationDetails from './BookingConfirmationDetails';
import { startCase } from 'lodash';
import RoutingDetailsWrapper, { RoutingDetailsWrapperProps } from './RoutingDetailsWrapper';
import ShipmentEstimateFormWrapper from '../DetailLayout/Estimates/ShipmentEstimateFormWrapper';
import { DOCUMENT_PERMISSION_VIEW } from 'operations/constants';
import InquiryOptionSummary from 'operations/components/InquiryOptionSummary';
import { OceanLastFreeDays } from 'operations/modules/orderManagement/CreateShipmentIMPEX/OceanLastFreeDays';
import { omit as _omit } from 'lodash';
import { CargoDetailsClearance } from './CargoDetailsClearance';
import CustomsClearanceShipmentRouting from './CustomsClearanceShipmentRouting';
import BookingDetailsShipmentFrom from './BookingDetailsShipmentForm';
import SwitchBLPartyDetails from '../SwitchBLPartyDetails';
import { Remarks } from './Remarks';
import { ShipmentDeliveryOrder } from '../ShipmentDeliveryOrder';
import ContainerTabs from './ContainerTabs';
import CargoNewComponent from '../DetailLayout/Cargo/CargoNewComponent/CargoNewComponent';
// import { InquiryOptionValue } from 'operations/models/InquiryOption';
// import { pick as _pick, omit as _omit } from 'lodash';
// import { CompanyValue } from 'operations/models/Company';
// import {
//   LOCATION_TAG_POD,
//   LOCATION_TAG_POL,
//   RATE_CLASS_QUANTITY,
// } from 'operations/modules/reports/constants';
// import { FREIGHT_TYPE_AIR } from 'operations/utils/constants';
// import { convertToDayJs } from 'operations/models/DayJs';
// import { dayjs } from '@shipmnts/pixel-hub';
// import { convertNumber } from 'sales_hub/utils/dimensionHelper';
// import { InquiryValue } from 'operations/models/Inquiry';

export const origin_clearance_services: ShipmentServiceRenderType[] = [
  {
    name: ['origin', 'clearance'],
  },
  {
    name: ['origin', 'transport'],
  },
  {
    name: ['origin', 'palletization'],
  },
  {
    name: ['origin', 'fumigation'],
  },
  {
    name: ['cargo_insurance'],
  },
  {
    name: ['empty_container_insurance'],
  },
];

export const destination_clearance_services: ShipmentServiceRenderType[] = [
  {
    name: ['destination', 'clearance'],
  },
  {
    name: ['destination', 'transport'],
  },
  {
    name: ['cargo_insurance'],
  },
  {
    name: ['empty_container_insurance'],
  },
];

export const freight_forwarding_services: ShipmentServiceRenderType[] = [
  {
    name: ['freight_forwarding'],
  },
  {
    name: ['origin', 'clearance'],
  },
  {
    name: ['origin', 'transport'],
  },
  {
    name: ['origin', 'palletization'],
  },
  {
    name: ['origin', 'fumigation'],
  },
  {
    name: ['destination', 'clearance'],
  },
  {
    name: ['destination', 'transport'],
  },
  {
    name: ['cargo_insurance'],
  },
  {
    name: ['empty_container_insurance'],
  },
];

const PartyDetailsWrapper = (props: any) => {
  const gridRef = useRef<GridOptions>();
  const { shipmentType } = props;
  const mandatoryFields = [];
  const tradeType = Form.useWatch('trade_type');
  const services = Form.useWatch('services');
  const originClearance = services?.origin?.clearance;
  const destinationClearance = services?.destination?.clearance;
  const freightForwarding = services?.freight_forwarding;
  const isClearanceByServices = !freightForwarding
    ? originClearance || destinationClearance
    : false;

  if (shipmentType && shipmentType !== SHIPMENT_TYPE_CONSOL) {
    if (isClearanceByServices && tradeType === TRADE_TYPE_IMPORT) {
      mandatoryFields.push('consignee');
    } else if (isClearanceByServices && tradeType === TRADE_TYPE_EXPORT) {
      mandatoryFields.push('shipper');
    } else if (tradeType === TRADE_TYPE_EXPORT) {
      mandatoryFields.push('shipper');
    } else if (tradeType === TRADE_TYPE_IMPORT) {
      mandatoryFields.push('consignee');
    }
  }

  return (
    <Form.Item name={'shipment_parties'}>
      <PartyDetails
        {...props}
        isClearance={isClearanceByServices}
        editable={true}
        mandatoryFields={mandatoryFields}
        gridRef={gridRef}
      />
    </Form.Item>
  );
};

const BillOfEntryWrapper = (props: any) => {
  const gridRef = useRef<GridOptions>();
  return (
    <Form.Item name={'shipment_custom_details'}>
      <BillOfEntryDetails {...props} editable={true} gridRef={gridRef} />
    </Form.Item>
  );
};

const ShippingBillWrapper = (props: any) => {
  const gridRef = useRef<GridOptions>();
  return (
    <Form.Item name={'shipment_custom_details'}>
      <ShippingBillDetails {...props} editable={true} gridRef={gridRef} />
    </Form.Item>
  );
};

const RoutingDetailsOwnWrapper = (props: RoutingDetailsWrapperProps) => {
  const { form } = props;
  const shipment_containers = Form.useWatch('shipment_containers', form);
  const shipment_container_quantity = Form.useWatch('shipment_container_quantity', form);

  const isReeferContainer = (container: ShipmentContainerValue) =>
    Object.keys(containerSettingsMap.reefer).includes(container?.container_type_code || '');

  return (
    <RoutingDetailsWrapper
      {...props}
      isReeferContainer={
        isReeferContainer(shipment_container_quantity?.[0]) ||
        isReeferContainer(shipment_containers?.[0])
      }
    />
  );
};

const RoutingDetailsWrapperClearance = (props: any) => {
  const { freightType, form, fromCustomerOrder, tradeType, shipment } = props;
  const routing_details_ref = useRef<{ runValidation: () => boolean }>();

  return (
    <Form.Item name="routing_details" noStyle>
      <CustomsClearanceShipmentRouting
        allowVoyageScheduleSearch={false}
        ref={routing_details_ref}
        freightType={freightType}
        disableNodes={fromCustomerOrder}
        onlyTranshipmentHopEditable={fromCustomerOrder}
        form={form}
        tradeType={tradeType}
        shipment={shipment}
      />
    </Form.Item>
  );
};

export const getShipmentComponents = (props: any) => {
  const {
    freightType,
    loadType,
    shipment,
    form,
    tradeType,
    shipmentType,
    tennantCountry,
    bookingType,
    type,
    fromOto,
    isClearance,
    cargoRef,
    masterShipmentId,
    erpConfigData,
    inquiryOption,
    inquiryOptionState,
    sessionData,
    uploadDocRef,
    setIsBookingAvailable,
    fromCustomerOrder,
    masterShipment,
    isFreightForwarding,
    isDup,
  } = props;
  let services = freight_forwarding_services;
  const isCleranceByServices = isCustomCleranceShipment(shipment?.services || {}, tradeType);
  if (isClearance && tradeType === TRADE_TYPE_EXPORT) {
    services = origin_clearance_services;
  } else if (isClearance && tradeType === TRADE_TYPE_IMPORT) {
    services = destination_clearance_services;
  } else if (isCleranceByServices && tradeType === TRADE_TYPE_EXPORT) {
    services = [
      {
        name: ['freight_forwarding'],
      },
      ...origin_clearance_services,
    ];
  } else if (isCleranceByServices && tradeType === TRADE_TYPE_IMPORT) {
    services = [
      {
        name: ['freight_forwarding'],
      },
      ...destination_clearance_services,
    ];
  }
  return [
    {
      title: 'Basic Details',
      component: BasicDetails,
      props: {
        form,
        shipment,
        shipmentType,
        isClearance,
        isFreightForwarding,
        type,
        masterShipment,
        isDup,
        trade_type: tradeType,
        freight_type: freightType,
        inquiryOption,
      },
      isVisible: true,
    },
    {
      title: 'Bill of Entry Details',
      component: BillOfEntryWrapper,
      props: { form, shipment, tradeType },
      isVisible: isClearance && tennantCountry === 'IN' && tradeType === 'import',
    },
    {
      title: 'Shipping Bill Details',
      component: ShippingBillWrapper,
      props: { form, shipment, tradeType },
      isVisible: isClearance && tennantCountry === 'IN' && tradeType === 'export',
    },
    {
      title: 'Party Details',
      component: PartyDetailsWrapper,
      isVisible: true,
      props: {
        form,
        shipmentType,
        tradeType,
        isClearance,
      },
    },
    {
      title: 'Shipment Details',
      component: ShipmentDetails,
      props: { form, shipment, freightType, loadType, tradeType, shipmentType },
      isVisible: isClearance,
    },
    {
      title: 'Cargo Details',
      component: CargoNewComponent,
      props: {
        form,
        ref: cargoRef,
        load_type: loadType,
        showNetWeight: true,
        freightType: freightType,
        showWeightDetails: !(freightType === FREIGHT_TYPE_OCEAN && loadType === LOAD_TYPE_FCL),
      },
      isVisible: !isClearance || (isClearance && freightType !== FREIGHT_TYPE_AIR),
    },
    {
      title: 'Cargo Details',
      component: CargoDetailsClearance,
      props: { shipment, form, isUpdate: type === 'update', freightType },
      isVisible: isClearance && freightType === FREIGHT_TYPE_AIR,
    },
    {
      title: 'Container Details',
      component: ContainerTabs,
      props: {
        disabled: !!fromOto,
        disabledAdd: !!fromOto,
        disableDelete: !!fromOto,
        bookingType,
        tradeType,
        shipmentType,
      },
      isVisible:
        loadType === 'fcl' &&
        type !== 'update' &&
        !masterShipmentId &&
        (shipment?.master_shipment_id ? shipmentType !== SHIPMENT_TYPE_HOUSE : true),
    },
    {
      title: `${type === 'update' ? '' : 'Have'} Booking Details`,
      component: BookingDetailsShipmentFrom,
      props: {
        form,
        inquiryOption: inquiryOptionState,
        setIsAccordionOpen: setIsBookingAvailable,
        type,
      },
      isAccordian: !shipmentType && type !== 'update',
      isVisible:
        (shipment?.master_shipment_id ? shipmentType !== SHIPMENT_TYPE_HOUSE : true) &&
        !shipment?.master_shipment_id &&
        !masterShipmentId &&
        !fromOto &&
        !isClearance &&
        (type === 'update' ? (shipment?.ocean_transport_orders || []).length === 1 : true),
    },
    {
      title: 'Routing Details',
      component: RoutingDetailsOwnWrapper,
      props: {
        form,
        shipment,
        freightType,
        tennantCountry,
        loadType,
        tradeType,
        type,
        masterShipmentId,
        showDpdOnMainCarriage: true,
      },
      isVisible: !isClearance || (isClearance && freightType !== FREIGHT_TYPE_AIR),
    },
    {
      title: 'Routing Details',
      component: RoutingDetailsWrapperClearance,
      props: {
        form,
        shipment,
        freightType,
        fromCustomerOrder,
        loadType,
        tradeType,
      },
      isVisible: isClearance && freightType === FREIGHT_TYPE_AIR,
    },
    {
      title: 'Shipment Cutoff',
      component: BookingConfirmationDetails,
      props: {
        form,
      },
      isAccordian: false,
      isVisible: !masterShipmentId && !!bookingType && !fromOto && tradeType !== TRADE_TYPE_IMPORT,
    },
    {
      title: 'Booking Terms & Conditions',
      component: BookingTermsAndConditions,
      props: {
        form,
      },
      isAccordian: !shipmentType,
      isVisible:
        !masterShipmentId &&
        !fromOto &&
        type !== 'update' &&
        bookingType === BOOKING_TYPE_SELF &&
        !isClearance,
    },
    {
      title: 'HBL Details',
      component: ShipmentDocumentDetailsNew,
      props: {
        form,
        freightType,
        tradeType,
        shipmentType,
        documentType: DOCUMENT_TYPE_HOUSE,
        disableDocumentStatus: type === 'update',
      },
      isVisible:
        (shipmentType && shipmentType === SHIPMENT_TYPE_BACK_TO_BACK && !isClearance) ||
        (shipmentType === SHIPMENT_TYPE_HOUSE && !isClearance),
    },
    {
      title: 'MBL Details',
      component: ShipmentDocumentDetailsNew,
      props: {
        form,
        freightType,
        tradeType,
        shipmentType,
        documentType: DOCUMENT_TYPE_MASTER,
        disableDocumentStatus: type === 'update',
      },
      isVisible: shipmentType && shipmentType !== SHIPMENT_TYPE_HOUSE && !isClearance,
    },
    {
      title: 'Issue Switch BL',
      component: SwitchBLPartyDetails,
      props: { form, shipment },
      isAccordian: true,
      isAccordianOpen: shipment?.will_issue_switch_bl && !isDup,
      isVisible:
        shipmentType &&
        (shipmentType === SHIPMENT_TYPE_DIRECT
          ? bookingType === BOOKING_TYPE_SELF
          : shipmentType !== SHIPMENT_TYPE_CONSOL) &&
        !isClearance,
    },
    {
      title: 'Service Details',
      component: ShipmentServiceDetails,
      props: {
        form: form,
        services: services,
        freightType: freightType,
        shipmentType: shipmentType,
        isClearance: isClearance,
        hideIsfFiledBy: false,
      },
      isVisible: true,
    },

    {
      title: 'Quotation Summary',
      component: InquiryOptionSummary,
      props: { id: inquiryOption?.id },
      isVisible:
        type !== 'update' &&
        !!inquiryOption &&
        hasPermission(sessionData?.permissions, {
          name: DOCUMENT_PERMISSION_VIEW,
          docType: 'SalesHub::Inquiry',
        }),
    },
    {
      title: !!inquiryOption ? 'Additional Estimates' : 'Buy/Sell Rate Estimates',
      component: ShipmentEstimateFormWrapper,
      props: {
        shipment: {
          id: 'new',
          freight_type: freightType,
          trade_type: tradeType,
          isMasterShipment: () => shipmentType !== SHIPMENT_TYPE_HOUSE,
          isAccountingClosed: () => 0,
          isAirShipment: () => freightType === FREIGHT_TYPE_AIR,
          isExportShipment: () => tradeType === TRADE_TYPE_EXPORT,
          isStockAllocated: () => 0,
          accessible_by_branches: [],
          accounting_status: 'pending',
          getShipmentDocument: () => undefined,
          getShipmentDocumentType: () => 'master',
          getMasterShipmentNumber: () => '',
          getServices: () => [],
          isAccountingOpen: () => 1,
          inquiry_option: inquiryOption,
        },
        displayMode: 'edit',
        form: form,
        erpConfigData: erpConfigData,
      },
      isVisible: type !== 'update',
    },
    {
      title: 'Customs Declaration & Invoice Details',
      component: ExportClearanceDetails,
      props: {
        shipment: shipment,
        form,
        showExportCustomDetails: tradeType === TRADE_TYPE_EXPORT,
        showImportCustomDetails: tradeType === TRADE_TYPE_IMPORT,
        skipServiceCheck: true,
        type,
      },
      isVisible: shipmentType && shipmentType !== SHIPMENT_TYPE_CONSOL && !isClearance,
    },
    {
      title: 'Customs Declaration Details',
      component: ExportClearanceDetails,
      props: {
        shipment: shipment,
        form,
        showInvoiceDetails: false,
        showExportCustomDetails: tradeType === TRADE_TYPE_EXPORT,
        showImportCustomDetails: tradeType === TRADE_TYPE_IMPORT,
        type,
      },
      isVisible: isClearance && tennantCountry !== 'IN',
    },
    {
      title: 'Invoice Details',
      component: ExportClearanceDetails,
      props: {
        shipment: shipment,
        form,
        showInvoiceDetails: true,
        showExportCustomDetails: false,
        showImportCustomDetails: false,
      },
      isVisible: isClearance,
    },
    {
      title: 'Delivery Order',
      component: ShipmentDeliveryOrder,
      isVisible: tradeType === TRADE_TYPE_IMPORT,
    },
    {
      title: 'Cutoffs and Free Days',
      component: OceanLastFreeDays,
      props: {
        form,
      },
      isAccordian: false,
      isVisible: tradeType === TRADE_TYPE_IMPORT,
    },
    {
      title: 'Remarks',
      component: Remarks,
      props: {},
      isVisible: true,
    },
    {
      title: 'Upload Documents',
      component: DocumentUpload,
      props: {
        ref: uploadDocRef,
        data: { id: type === 'update' ? shipment?.id : null },
        parentType: 'shipment',
      },
      isVisible: type !== 'update',
    },
  ];
};

export const getContainerRequestFromShipmentContainers = (
  shipment_containers: ShipmentContainerValue[]
) => {
  const containers = (shipment_containers || []).filter((c: any) => {
    return (c.ocean_transport_order_ids || []).length === 0;
  });

  const groupedContainers = (containers || []).reduce(
    (groups: Record<string, ShipmentContainerValue[]>, container) => {
      const container_settings = _omit(container.container_settings, '__typename') || {};
      const sorted_container_settings_keys = Object.keys(container_settings).sort();
      const sorted_container_settings = sorted_container_settings_keys.reduce(
        (acc: any, key: any) => {
          acc[key] = container_settings[key as keyof typeof container_settings];
          return acc;
        },
        {}
      );
      const key = `${container.container_type_code}_${JSON.stringify(sorted_container_settings)}`;
      if (!groups[key]) {
        groups[key] = [];
      }
      groups[key].push(container);
      return groups;
    },
    {}
  );
  const container_requests: any[] = Object.values(groupedContainers || {}).map((group) => ({
    id: group[0].id,
    container_type: group[0].container_type || '',
    container_type_code: group[0].container_type_code || '',
    is_shipper_owned: false,
    quantity: group.length,
    quantity_fulfilled: 0,
    quantity_unfulfilled: group.length,
    quantity_origin_port_gated_in: 0,
    weight_unit: 'kgs',
    container_settings: _omit(group[0].container_settings, '__typename') || {},
  }));

  return container_requests;
};

export const TYPE_UPDATE = 'update';
export const TYPE_CREATE = 'create';

export const getShipmentApiHelper = (type: string, shipment_type: string | null | undefined) => {
  if (type === TYPE_UPDATE) {
    return UPDATE_SHIPMENT_NEW;
  } else {
    if (shipment_type === SHIPMENT_TYPE_HOUSE) {
      return CREATE_HOUSE_SHIPMENT;
    }
    if (!shipment_type) {
      return CREATE_PLANNED_SHIPMENT;
    }
    return CREATE_SHIPMENT;
  }
};

export const redirectToShipment = (
  type: string,
  displayName: string | null | undefined,
  data: any,
  navigate: any
) => {
  const shipmentId =
    data?.update_shipment_new?.id ||
    data?.create_house_shipment?.id ||
    data?.create_planned_shipment?.id ||
    data?.create_shipment?.shipment?.id;
  if (!shipmentId) return;
  message.success(`${startCase(type)} ${startCase(displayName || '')} successfully`);
  if (!data?.update_shipment_new)
    navigate(`~/view/shipment/${shipmentId}/documents?first_load=1`, '_self');
  else navigate(`~/view/shipment/${shipmentId}`, '_self');
};

export const isCustomCleranceShipment = (services: any, trade_type: string) => {
  const originClearance = services?.origin?.clearance;
  const destinationClearance = services?.destination?.clearance;
  const freightForwarding = services?.freight_forwarding;
  if (!freightForwarding && trade_type === TRADE_TYPE_EXPORT && originClearance) return true;
  else if (!freightForwarding && trade_type === TRADE_TYPE_IMPORT && destinationClearance)
    return true;
  else return false;
};

export const getIsCustomClearanceByServices = (services: any) => {
  const originClearance = services?.origin?.clearance;
  const destinationClearance = services?.destination?.clearance;
  const freightForwarding = services?.freight_forwarding;
  return !freightForwarding ? originClearance || destinationClearance : false;
};

export const handleShipperConsigneeOnMBLPrefilling = (consol_type: string, form: FormInstance) => {
  if (consol_type === BUYER_CONSOLIDATION.key) {
    form.setFieldValue(
      [DOCUMENT_TYPE_MASTER, 'shipment_document', 'shipper_on_document'],
      'origin_agent'
    );
  } else if (consol_type === SELF_CONSOLIDATION.key) {
    form.setFieldValue(
      [DOCUMENT_TYPE_MASTER, 'shipment_document', 'shipper_on_document'],
      'origin_agent'
    );
    form.setFieldValue(
      [DOCUMENT_TYPE_MASTER, 'shipment_document', 'consignee_on_document'],
      'destination_agent'
    );
  } else if (consol_type === SELLER_CONSOLIDATION.key) {
    form.setFieldValue(
      [DOCUMENT_TYPE_MASTER, 'shipment_document', 'consignee_on_document'],
      'destination_agent'
    );
  }
};
