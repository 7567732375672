import React from 'react';
import { ROW_GUTTER } from '../../constants';
import { Col, DatePicker, Form, Input, Row } from '@shipmnts/pixel-hub';

export default function ConsignmentNoteDetails() {
  return (
    <Row gutter={ROW_GUTTER}>
      <Col span={8}>
        <Form.Item label="Consignment Note No." name={['consignment_note', 'shipment_number']}>
          <Input placeholder="Enter Consignment Note No." />
        </Form.Item>
      </Col>
      <Col span={8}>
        <Form.Item label="Consignment Note Date" name={['consignment_note', 'shipment_date']}>
          <DatePicker style={{ width: '100%' }} />
        </Form.Item>
      </Col>
    </Row>
  );
}
