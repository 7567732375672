import { PerformAction } from '../actionHelper/models';
import { SessionDataValue } from 'operations/models/SessionData';
import RedirectToTransactionForm from 'operations/modules/warehouseManagement/RedirectToTransactionForm';
import CancelWMSTransactionDrawer from './Components/CancelWMSTransactionDrawer';
import PrintTransaction from './PrintTransaction';
import CreateWarehouseUnit from './CreateWarehouseUnit';
import DeleteWarehouseUnit from './DeleteWarehouseUnit';
import { ProductValue } from './models/Product';
import WmsProductActions from './WmsProductActions';
import { CreateProductFormDrawer } from './Product/CreateProductForm';
import SubmitTransactionModal from './Components/SubmitTransactionModal';
import ReopenTransactionModal from './Components/ReopenTransactionModal';
import { FetchStockSummaryDrawerWrapper } from './Components/FetchStockSummaryDrawer';
import CreateWarehouseJobAction from './Components/CreateWarehouseJobAction';

interface WMSTransactionActionType {
  transaction: any;
  setVisible: (visible: boolean) => void;
  sessionData: SessionDataValue;
  extraProps?: any;
  navigate: any;
  nextTransaction?: any;
}

interface WMSWarehouseUnitActionType {
  warehouseUnit: any;
  setVisible: (visible: boolean) => void;
  sessionData: SessionDataValue;
  extraProps?: any;
  navigate: any;
  nextTransaction?: any;
}

interface WmsProductActionType {
  wmsProduct: ProductValue;
  setVisible: (visible: boolean) => void;
  sessionData: SessionDataValue;
  extraProps?: any;
  navigate: any;
  nextTransaction?: any;
}

export const performCreateReceiptAction = (payload: WMSTransactionActionType): PerformAction => {
  const { transaction, sessionData, extraProps } = payload;

  return {
    actionParams: {
      id: transaction.id,
      referenceType: 'Wms::WarehouseTransaction',
      session: sessionData,
      transaction: transaction,
      nextTransaction: extraProps?.nextTransaction,
      onSuccess: (sendEmail: boolean) => {
        // TODO
      },
      onClose: (props: any) => {
        // TODO
      },
      isVisible: true,
      setIsVisible: () => {
        //Todo
      },
    },
    component: RedirectToTransactionForm,
  };
};

export const performCreateGoodsTransferAction = (
  payload: WMSTransactionActionType
): PerformAction => {
  const { transaction, sessionData, extraProps } = payload;

  return {
    actionParams: {
      id: transaction.id,
      referenceType: 'Wms::WarehouseTransaction',
      session: sessionData,
      transaction: transaction,
      nextTransaction: extraProps?.nextTransaction,
      purposeOfTransfer: extraProps?.purposeOfTransfer,
      onSuccess: (sendEmail: boolean) => {
        // TODO
      },
      onClose: (props: any) => {
        // TODO
      },
      isVisible: true,
      setIsVisible: () => {
        //Todo
      },
    },
    component: FetchStockSummaryDrawerWrapper,
  };
};

export const cancelTransactionRenderer = (payload: WMSTransactionActionType): PerformAction => {
  const { transaction } = payload;

  return {
    actionParams: {
      transaction,
    },
    component: CancelWMSTransactionDrawer,
  };
};

export const submitTransactionRenderer = (payload: WMSTransactionActionType): PerformAction => {
  const { transaction } = payload;
  return {
    actionParams: {
      transaction: transaction,
    },
    component: SubmitTransactionModal,
  };
};

export const reopenTransactionRenderer = (payload: WMSTransactionActionType): PerformAction => {
  const { transaction } = payload;
  return {
    actionParams: {
      transaction: transaction,
    },
    component: ReopenTransactionModal,
  };
};

export const performPrintTransaction = (payload: WMSTransactionActionType): PerformAction => {
  const { transaction, extraProps } = payload;
  return {
    actionParams: {
      docTypeId: transaction?.id,
      docType: extraProps?.docType,
      doc: transaction || extraProps?.transaction,
    },
    component: PrintTransaction,
  };
};

export const performAddChild = (payload: WMSWarehouseUnitActionType): PerformAction => {
  const { extraProps } = payload;
  return {
    actionParams: {
      visible: true,
      parentId: extraProps?.warehouseUnit?.id,
      formType: 'add',
    },
    component: CreateWarehouseUnit,
  };
};

export const performEditWarehouseUnit = (payload: WMSWarehouseUnitActionType): PerformAction => {
  const { extraProps } = payload;
  return {
    actionParams: {
      visible: true,
      parentId: extraProps?.warehouseUnit?.id,
      formType: 'edit',
      onSuccess: () => {
        // TODO: on success
      },
    },
    component: CreateWarehouseUnit,
  };
};

export const performDeleteWarehouseUnit = (payload: WMSWarehouseUnitActionType): PerformAction => {
  const { extraProps, setVisible } = payload;
  return {
    actionParams: {
      parent: extraProps?.warehouseUnit,
      onClose: () => {
        //
      },
      isVisible: true,
      setVisible: setVisible,
      refetchWarehouseUnit: extraProps?.warehouseUnit?.refetchData,
      onSuccess: (sendEmail: boolean) => {
        //
      },
    },
    component: DeleteWarehouseUnit,
  };
};

export const performEditWmsProduct = (payload: WmsProductActionType): PerformAction => {
  const { wmsProduct } = payload;

  return {
    actionParams: {
      product: wmsProduct,
      id: wmsProduct?.id,
      isVisible: true,
    },
    component: CreateProductFormDrawer,
  };
};

export const enableDisableWmsProductRenderer = (payload: WmsProductActionType): PerformAction => {
  const { wmsProduct } = payload;

  return {
    actionParams: {
      wmsProduct: wmsProduct,
      payload: {
        disabled: !wmsProduct?.disabled,
      },
      onSuccess: () => {
        // TODO: on success
      },
    },
    component: WmsProductActions,
  };
};

export const enableWmsProductRenderer = (payload: WmsProductActionType): PerformAction => {
  const { wmsProduct } = payload;

  return {
    actionParams: {
      wmsProduct: wmsProduct,
      payload: {
        disabled: false,
      },
      onSuccess: () => {
        // TODO: on success
      },
    },
    component: WmsProductActions,
  };
};
export const performCreateWarehouseShipment = (
  payload: WMSTransactionActionType
): PerformAction => {
  const { transaction } = payload;

  return {
    actionParams: {
      transaction: transaction,
      onSuccess: () => {
        // TODO: on success
      },
    },
    component: CreateWarehouseJobAction,
  };
};
