import React from 'react';
import { Action } from 'operations/modules/actionHelper/models';
import { RowNode } from '@ag-grid-community/core';
import { PRIMARY_TYPE } from 'operations/modules/actionHelper/constants';
import { CopyOutlined, DeleteOutlined, EditOutlined } from '@shipmnts/pixel-hub';
import UpdateCompanyForm from './UpdateCompanyForm';
import DuplicateEstimatesActionForm from './DuplicateEstimatesActionForm';
import DeleteEstimatesAction from './DeleteEstimatesAction';
import { BRANCH_FIELDS } from './constants';

const estimate_fields: Record<string, Record<string, string>> = {
  sell_status: {
    customer_company_id: 'Customer',
    sell_terms: 'Sell Terms',
  },
  buy_status: {
    supplier_company_id: 'Supplier',
    buy_terms: 'Buy Terms',
  },
};

const permission_field: Record<string, string> = {
  sell_status: 'sell_permission',
  buy_status: 'buy_permission',
};

const transaction_field: Record<string, string> = {
  sell_status: 'sell_transaction_identifier',
  buy_status: 'buy_transaction_identifier',
};

const value_fields_map: Record<string, string> = {
  customer_company_id: 'customer_company',
  supplier_company_id: 'supplier_company',
};

export const editEstimateFieldRenderer = (
  selectedNodes: RowNode[],
  onSuccess: () => void,
  selectedNodeCounts: number,
  extraProps: any,
  onCloseModal: () => void
) => {
  return {
    actionParams: {
      selectedNodes: selectedNodes,
      onSuccess: onSuccess,
      selectedNodeCounts: selectedNodeCounts,
      onCloseModal: onCloseModal,
      fieldName: extraProps.field_name,
      label: extraProps.label,
      value: extraProps.value,
      isSpEnabled: extraProps.isSpEnabled,
    },
    component: UpdateCompanyForm,
  };
};

export const duplicateEstimatesRenderer = (
  selectedNodes: RowNode[],
  onSuccess: () => void,
  selectedNodeCounts: number,
  extraProps: any,
  onCloseModal: () => void
) => {
  return {
    actionParams: {
      selectedNodes: selectedNodes,
      onSuccess: onSuccess,
      selectedNodeCounts: selectedNodeCounts,
      onCloseModal: onCloseModal,
      isSpEnabled: extraProps.isSpEnabled,
    },
    component: DuplicateEstimatesActionForm,
  };
};

export const deleteEstimatesRenderer = (
  selectedNodes: RowNode[],
  onSuccess: () => void,
  selectedNodeCounts: number,
  onCloseModal: () => void
) => {
  return {
    actionParams: {
      selectedNodes: selectedNodes,
      onSuccess: onSuccess,
      selectedNodeCounts: selectedNodeCounts,
      onCloseModal: onCloseModal,
    },
    component: DeleteEstimatesAction,
  };
};

const editFieldAction = (
  field_name: string,
  label: string,
  value: any,
  isSpEnabled: boolean
): Action => {
  return {
    key: field_name,
    type: PRIMARY_TYPE,
    displayComponent: label,
    icon: <EditOutlined />,
    description: label,
    performAction: editEstimateFieldRenderer,
    isEnable: true,
    extraProps: {
      field_name: field_name,
      label: label,
      value: value,
      render_inside: true,
      isSpEnabled,
    },
  };
};

const editEstimateFieldsAction = (selectedNodes: RowNode[], isSpEnabled: boolean): Action => {
  const referenceNodeData = selectedNodes[0].data;
  const allNodesHaveSameValue = (key: string) =>
    selectedNodes.every((node) => node.data[key] === referenceNodeData[key]);
  const allNodesAreValid = (
    status_key: string,
    permission_key: string,
    transaction_key: string,
    key: string
  ) => {
    if (
      status_key === 'sell_status' &&
      referenceNodeData?.shipment?.accounting_status === 'billing_closed'
    ) {
      return false;
    }
    return selectedNodes.every((node) => {
      const isAnyTransactionPresent =
        node.data['sell_transaction_identifier'] || node.data['buy_transaction_identifier'];
      const areBothUnbilled =
        node.data['sell_status'] === 'unbilled' && node.data['buy_status'] === 'unbilled';
      return isSpEnabled && BRANCH_FIELDS.includes(key)
        ? areBothUnbilled && !isAnyTransactionPresent && node.data?.[permission_key]?.edit
        : node.data[status_key] === 'unbilled' &&
            node.data?.[permission_key]?.edit &&
            !node.data[transaction_key];
    });
  };

  if (isSpEnabled) {
    estimate_fields['sell_status']['sell_branch_id'] = 'Branch';
  } else {
    estimate_fields['sell_status']['sell_branch_id'] = 'Sell Branch';
    estimate_fields['buy_status']['buy_branch_id'] = 'Buy Branch';
  }

  const childComponents: any = [];
  Object.keys(estimate_fields).forEach((status) => {
    Object.keys(estimate_fields[status]).forEach((key) => {
      if (
        allNodesAreValid(status, permission_field[status], transaction_field[status], key) &&
        (['customer_company_id', 'supplier_company_id'].includes(key) || allNodesHaveSameValue(key))
      ) {
        childComponents.push(
          editFieldAction(
            key,
            estimate_fields[status][key],
            referenceNodeData[value_fields_map[key] ? value_fields_map[key] : key],
            isSpEnabled
          )
        );
      }
    });
  });
  return {
    key: 'edit_fields',
    type: PRIMARY_TYPE,
    displayComponent: 'Edit Fields',
    description: 'Edit Fields',
    childComponents: childComponents,
    isEnable: true,
    icon: <EditOutlined />,
  };
};

const duplicateEstimatesAction = (isSpEnabled: boolean): Action => {
  return {
    key: 'duplicate_estimates',
    type: PRIMARY_TYPE,
    displayComponent: 'Duplicate',
    description: 'Duplicate Estimates',
    isEnable: true,
    performAction: duplicateEstimatesRenderer,
    extraProps: { isSpEnabled },
    icon: <CopyOutlined />,
  };
};
const deleteEstimatesAction = (): Action => {
  return {
    key: 'delete_estimates',
    type: PRIMARY_TYPE,
    displayComponent: 'Delete',
    description: 'Delete Estimates',
    isEnable: true,
    performAction: deleteEstimatesRenderer,
    icon: <DeleteOutlined />,
  };
};

export const getEstimateBulkActions = (selectedNodes: RowNode[], isSpEnabled: boolean) => {
  if (selectedNodes.length === 0) return [];
  const actions: Action[] = [];
  let isEditAllowed = true;
  let isUnbilled = true;
  const accountingStatusValid = selectedNodes[0]?.data?.shipment?.accounting_status === 'pending';
  selectedNodes.forEach((node) => {
    isEditAllowed =
      isEditAllowed && node.data?.buy_permission.edit && node.data?.sell_permission.edit;
    isUnbilled =
      isUnbilled &&
      node.data.sell_status === 'unbilled' &&
      node.data.buy_status === 'unbilled' &&
      !node.data.sell_transaction_identifier &&
      !node.data.buy_transaction_identifier;
  });
  actions.push(editEstimateFieldsAction(selectedNodes, isSpEnabled));
  if (isEditAllowed && accountingStatusValid) {
    actions.push(duplicateEstimatesAction(isSpEnabled));
    if (isUnbilled) actions.push(deleteEstimatesAction());
  }
  return actions;
};
