import React, { useEffect } from 'react';
import { Modal, Form, GlobalSearch, message } from '@shipmnts/pixel-hub';
import { pick as _pick, get as _get } from 'lodash';
import { SAVE_SHIPMENT_ESTIMATES } from 'operations/graphql/shipmentEstimate';
import { useMutation } from '@apollo/client';
import {
  ShipmentEstimateValue,
  useShipmentEstimateStore,
} from 'operations/models/ShipmentEstimate';
import {
  BRANCH_FIELDS,
  COMPANY_FIELDS,
  COMPANY_TYPE,
  DOCTYPE_MAP,
  ESTIMATE_FIELDS,
  SEARCH_OPTIONS_MAP,
} from './constants';
import { RowNode } from '@ag-grid-community/core';
import { useShipmentEstimate } from 'operations/modules/shipment/components/DetailLayout/Estimates/ShipmentEstimateLayout';
interface DuplicateEstimatesFormProps {
  selectedNodes: RowNode[];
  onClose: () => void;
  onSuccess: () => void;
  isSpEnabled: boolean;
}

const DuplicateEstimatesActionForm = (props: DuplicateEstimatesFormProps) => {
  const { fetchEstimateData } = useShipmentEstimateStore();
  const { branches, configData } = useShipmentEstimate();
  const costCenters = _get(configData, 'cost_centers');
  const [saveEstimates, { data, error }] = useMutation(SAVE_SHIPMENT_ESTIMATES);
  const { selectedNodes, onClose, onSuccess, isSpEnabled } = props;
  const [form] = Form.useForm();
  const label_map: Record<string, string> = {
    customer_company_id: 'Customer',
    supplier_company_id: 'Supplier',
  };
  if (isSpEnabled) {
    label_map['branch'] = 'Branch';
  } else {
    label_map['sell_branch_id'] = 'Sell Branch';
    label_map['buy_branch_id'] = 'Buy Branch';
  }

  function getPermittedBranches(
    erp_cost_centers: { name: string; has_permission: boolean }[],
    branches: { id: string; name: string; erp_cost_center_id: string }[]
  ): string[] {
    return branches
      .filter(
        (branch) =>
          !!erp_cost_centers.find(
            (cost_center) =>
              cost_center.name === branch.erp_cost_center_id &&
              cost_center.has_permission &&
              accessible_by_branches.includes(branch.id)
          )
      )
      .map((branch) => branch.name);
  }

  const onFinish = async (values: Record<string, Record<string, string>>) => {
    const estimate_fields = isSpEnabled ? ['branch', ...ESTIMATE_FIELDS] : ESTIMATE_FIELDS;
    const new_estimates = selectedNodes.map((node: RowNode) => {
      const updated_node = {
        ...node.data,
        id: null,
        uuid: null,
        sequence: null,
      };
      Object.keys(values).forEach((key) => {
        if (values[key]) {
          if (values[key]?.id !== 'same_as_estimate') updated_node[key] = values[key]?.id;
        }
      });
      const new_estimate: Partial<ShipmentEstimateValue> = _pick(updated_node, estimate_fields);
      return new_estimate;
    });
    saveEstimates({
      variables: { shipment_estimates: new_estimates },
    });
  };

  useEffect(() => {
    if (data) {
      fetchEstimateData?.();
      message.success('Duplicate estimates created.');
      onClose?.();
      onSuccess?.();
    }
    if (error) {
      message.error('Failed to duplicate estimates.');
      onClose?.();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, error]);
  const accessible_by_branches =
    selectedNodes[0]?.data?.shipment?.accessible_by_branches.map(
      (branch: { id: string }) => branch.id
    ) || [];
  const branch_account_search_values = getPermittedBranches(costCenters, branches);
  return (
    <Modal
      title="Duplicate Estimates"
      centered={true}
      open={true}
      onCancel={onClose}
      onOk={form.submit}
    >
      <Form form={form} onFinish={onFinish}>
        {Object.keys(label_map).map((key, index) => {
          let filters: Record<string, string | boolean | Array<string>> = {};
          if (BRANCH_FIELDS.includes(key)) {
            filters = {
              name: branch_account_search_values,
            };
          }
          if (COMPANY_FIELDS.includes(key) && COMPANY_TYPE[key as keyof typeof COMPANY_TYPE]) {
            filters = {
              ...COMPANY_TYPE[key as keyof typeof COMPANY_TYPE],
            };
          }
          return (
            <Form.Item
              initialValue={{ ...SEARCH_OPTIONS_MAP?.[DOCTYPE_MAP[key]] }}
              key={index}
              name={key}
              label={label_map[key]}
              required
              rules={[{ required: true }]}
              normalize={(value) =>
                value === undefined ? { ...SEARCH_OPTIONS_MAP?.[DOCTYPE_MAP[key]] } : value
              }
            >
              <GlobalSearch
                doc_type={DOCTYPE_MAP[key]}
                searchProps={filters}
                extraProps={{
                  include_disabled: false,
                  custom_option: SEARCH_OPTIONS_MAP?.[DOCTYPE_MAP[key]],
                }}
                showCreation={false}
              />
            </Form.Item>
          );
        })}
      </Form>
    </Modal>
  );
};

export default DuplicateEstimatesActionForm;
