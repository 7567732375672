import { gql } from '@apollo/client';
import { addressFields } from './address';
import { userContactFields } from './contact';
import { salesPersonFields } from 'operations/graphql/salesPerson';

export const minimumCompanyFields = gql`
  fragment minimumCompanyFields on CompanyObjectType {
    id
    status
    registered_name
    country_of_incorporation
    company_group
    entity_type
    logo
    is_disabled
    company_type
    company_stage
    remarks
    company_identification_number
  }
`;

export const regulatoryDetailFields = gql`
  fragment regulatoryDetailFields on RegulatoryDetailsObjectType {
    id
    key
    value
    valid_till
    attachment {
      document_identifier
      document_url
      content_type
      file_size
      file_name
    }
  }
`;

export const companyFields = gql`
  fragment companyFields on CompanyObjectType {
    id
    uuid
    status
    created_at
    updated_at
    registered_name
    country_of_incorporation
    created_by {
      id
      first_name
      last_name
    }
    company_group
    entity_type
    logo
    is_disabled
    company_type
    company_stage
    remarks
    company_identification_number
    tax_registration_number
    tax_deduction_id
    receivable_credit_control_type
    addresses {
      ...addressFields
    }
    domain
    company_email_preferences {
      id
      email_template_id
      address_id
      to_emails
      cc_emails
      address {
        id
        name
        city_name
        print_address
      }
      email_template {
        id
        template_name
        action_name
      }
    }
    global_company_account {
      id
      registered_name
    }
    teams {
      id
      role
      branch_accounts {
        id
        name
      }
      user_contact {
        id
        first_name
        last_name
        name
      }
      sales_person {
        ...salesPersonFields
      }
      reference_id
      reference_type
    }
    contacts {
      ...userContactFields
    }
    sales_partner {
      id
      registered_name
    }
    regulatory_details_data {
      ...regulatoryDetailFields
    }
    connection_request {
      status
    }
    pipeline {
      id
      name
    }
    current_state {
      id
      name
    }
    custom_field_values
    color_code
  }
  ${addressFields}
  ${salesPersonFields}
  ${userContactFields}
  ${regulatoryDetailFields}
`;

const exemptionFields = gql`
  fragment exemptionFields on ErpnextExemptionObjectType {
    erpnext_name
    certificate_number
    exemption_limit
    exemption_rate
    valid_from
    valid_to
  }
`;

export const UPSERT_COMPANY = gql`
  mutation upsert_company($company: CompanyInputType!) {
    upsert_company(company: $company) {
      company {
        ...companyFields
      }
      company_accounts_data {
        name
        cin_no
        default_currency
        is_customer
        is_supplier
        is_lead
        operations_company_id
        receivable_accounts {
          erpnext_name
          account
          company
        }
        receivable_credit_limit
        is_msme
        credit_overdue_limit
        receivable_credit_control_type
        receivable_payment_terms
        receivable_tax_withholding_category
        payable_accounts {
          erpnext_name
          account
          company
        }
        payable_payment_terms
        payable_tax_withholding_category
        addresses {
          name
          operations_address_id
          preferred_invoice_type
          gstin
        }
        payable_exemptions {
          ...exemptionFields
        }
        receivable_exemptions {
          ...exemptionFields
        }
      }
    }
  }
  ${salesPersonFields}, ${companyFields}, ${exemptionFields}
`;

export const UPSERT_TEAM = gql`
  mutation upsert_team($reference_type: String!, $reference_id: ID!, $teams: [TeamInputType!]!) {
    upsert_team(reference_type: $reference_type, reference_id: $reference_id, teams: $teams) {
      id
    }
  }
`;

export const GET_REFERENCE_TEAM = gql`
  query get_reference_team(
    $reference_type: String!
    $reference_id: String!
    $branch_account_id: String
    $roles: [String!]
  ) {
    get_reference_team(
      reference_type: $reference_type
      reference_id: $reference_id
      branch_account_id: $branch_account_id
      roles: $roles
    ) {
      branch_accounts {
        id
        name
      }
      role
      user_contact {
        id
        first_name
        last_name
        name
      }
      sales_person {
        ...salesPersonFields
      }
      reference_id
      reference_type
    }
  }
  ${salesPersonFields}
`;

export const DELETE_TEAM = gql`
  mutation delete_team($id: ID!) {
    delete_team(id: $id) {
      message
    }
  }
`;

export const COMPANY_REPORT = gql`
  query company_report(
    $limit: Int
    $offset: Int
    $filters: [FilterInputType]
    $sort: [SortInputTypeNew]
  ) {
    company_report(limit: $limit, offset: $offset, filters: $filters, sort: $sort) {
      data {
        id
        registered_name
        status
        country_of_incorporation
        created_by
        company_group
        is_disabled
        company_type
        company_stage
        created_at
      }
      total
    }
  }
`;

export const GET_COMPANY = gql`
  query company($id: ID!) {
    company(id: $id) {
      ...companyFields
      connection_request {
        id
        status
        from_company_account {
          id
        }
        to_company_account {
          id
        }
        requested_by_user {
          id
        }
      }
    }
  }
  ${companyFields}
`;

export const RENAME_COMPANY = gql`
  mutation rename_company($company_id: ID!, $new_name: String!, $merge_existing: Boolean!) {
    rename_company(company_id: $company_id, new_name: $new_name, merge_existing: $merge_existing) {
      ...companyFields
    }
  }
  ${companyFields}
`;

export const VALIDATE_CUSTOM_RULE = gql`
  mutation validate_custom_rule($actor_id: ID!, $trigger: String!, $actor_type: String!) {
    validate_custom_rule(actor_id: $actor_id, trigger: $trigger, actor_type: $actor_type) {
      rule {
        id
        title
        description
        trigger
        actor_type
        action_method
        conditions
      }
      message
    }
  }
`;

export const INVITE_CUSTOMER = gql`
  mutation invite_customer($user_contact_id: ID!, $company_id: ID!) {
    invite_customer(user_contact_id: $user_contact_id, company_id: $company_id) {
      id
      status
    }
  }
`;

export const SUMMARIZE_COMPANY = gql`
  query summarize_company($company_id: ID!, $days: Int) {
    summarize_company(company_id: $company_id, days: $days) {
      inquiry_summary {
        total
        in_past_n_days
        inquiries {
          id
          inquiry_global_id
          inquiry_number
          customer_company {
            registered_name
          }
          created_at
          created_by {
            name
          }
          trade_type
          load_type
          freight_type
          last_action_status
          sales_person {
            name
          }
          pricing_person {
            pretty_display
          }
        }
      }
      inquiry_options_summary {
        total
        in_past_n_days
      }
      activity_summary {
        total
        in_past_n_days
        activities {
          created_at
          type
        }
      }
    }
  }
`;
