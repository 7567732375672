import { FormInstance, GlobalSearch } from '@shipmnts/pixel-hub';
import { BusinessVerticalValue } from 'common/models/BusinessVertical';
import { useSession } from 'common';
import { getSnapshot } from 'mobx-state-tree';
import { setFormValueOfBusinessVertical } from 'operations/modules/helpers';
import React, { useEffect } from 'react';
import { getAllPossibleBusinessVertical } from '@shipmnts/pixel-hub';

export default function BusinessVerticalSearchForm(props: {
  form?: FormInstance;
  filters?: any;
  formType?: string;
  disabled?: boolean;
  value?: any;
  onChange?: (val: any) => void;
}) {
  const { form, formType, filters, value, disabled, onChange } = props;
  const sessionData = useSession();

  useEffect(() => {
    const getBv = async () => {
      const businessVertical = form?.getFieldValue('business_vertical');
      if (!businessVertical && formType !== 'update') {
        const businessVerticals = await getAllPossibleBusinessVertical(sessionData, value, filters);
        if (businessVerticals?.length === 1) {
          form?.setFieldValue('business_vertical', businessVerticals?.[0]);
        }
      }
    };
    getBv();
  }, [filters, form, sessionData, value, formType]);

  return (
    <GlobalSearch
      onChange={(val: any) => {
        const business_vertical: BusinessVerticalValue = getSnapshot(val);
        onChange && onChange(val);
        if (!business_vertical || !form) return;
        if (formType) {
          if (formType === 'update') return;
        }
        const involved_branch = form?.getFieldValue('involved_branch');
        setFormValueOfBusinessVertical(business_vertical, form, sessionData, involved_branch);
      }}
      disabled={disabled}
      value={value}
      searchProps={filters}
      doc_type="Network::BusinessVertical"
    />
  );
}
