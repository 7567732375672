import { startCase } from 'lodash';

export const FREIGHT_TYPE_AIR = 'air';
export const FREIGHT_TYPE_OCEAN = 'ocean';
export const FREIGHT_TYPE_ROAD = 'road';
export const FREIGHT_TYPE_RAIL = 'rail';

export const LOAD_TYPE_LOOSE = 'loose';
export const LOAD_TYPE_ULD = 'ULD';
export const LOAD_TYPE_FCL = 'FCL';
export const LOAD_TYPE_LCL = 'LCL';
export const LOAD_TYPE_BREAKBULK = 'Breakbulk';
export const LOAD_TYPE_FTL = 'FTL';

export const LOAD_TYPE_OPTIONS = {
  [FREIGHT_TYPE_AIR]: [
    {
      label: 'Loose',
      value: 'loose',
    },
    {
      label: 'ULD',
      value: 'uld',
    },
  ],
  [FREIGHT_TYPE_OCEAN]: [
    {
      label: 'FCL',
      value: 'fcl',
    },
    {
      label: 'LCL',
      value: 'lcl',
    },
  ],
  [FREIGHT_TYPE_ROAD]: [
    {
      label: 'FCL',
      value: 'fcl',
    },
    {
      label: 'FTL - Breakbulk',
      value: 'breakbulk',
    },
    {
      label: 'FTL - Bulk',
      value: 'bulk',
    },
    {
      label: 'FTL - Liquid',
      value: 'liquid',
    },
    {
      label: 'LTL',
      value: 'ltl',
    },
  ],
  [FREIGHT_TYPE_RAIL]: [],
};

export const LOAD_TYPE_TOOLTIP_TEXT: { [key: string]: string } = {
  [FREIGHT_TYPE_OCEAN]:
    'Type of load such as Full Container Load (FCL), Less than container load (LCL), or Break Bulk',
  [FREIGHT_TYPE_AIR]: 'Type of load such as Loose Cargo or Cargo loaded on Unit Load Device',
};

export const SERVICE_TYPE_FREIGHT = 'freight_forwarding';
export const SERVICE_TYPE_ORIGIN_CLEARANCE = 'origin_custom_clearance';
export const SERVICE_TYPE_DESTINATION_CLEARANCE = 'destination_custom_clearance';
export const SERVICE_TYPE_ORIGIN_TRANSPORT = 'origin_transport';
export const SERVICE_TYPE_DESTINATION_TRANSPORT = 'destination_transport';
export const SERVICE_PALLETIZATION = 'palletization';
export const SERVICE_FUMIGATION = 'fumigation';
export const SERVICE_CARGO_INSURANCE = 'cargo_insurance';
export const SERVICE_EMPTY_CONTAINER_INSURANCE = 'empty_container_insurance';
export const SERVICE_EMPTY_WAREHOUSE = 'warehouse';
export const SERVICE_EMPTY_LIVE_TRACKING = 'live_tracking';

export const SERVICE_TYPE_OPTIONS = [
  {
    value: SERVICE_TYPE_FREIGHT,
    label: startCase(SERVICE_TYPE_FREIGHT),
  },
  {
    value: SERVICE_TYPE_ORIGIN_CLEARANCE,
    label: startCase(SERVICE_TYPE_ORIGIN_CLEARANCE),
  },
  {
    value: SERVICE_TYPE_DESTINATION_CLEARANCE,
    label: startCase(SERVICE_TYPE_DESTINATION_CLEARANCE),
  },
  {
    value: SERVICE_TYPE_ORIGIN_TRANSPORT,
    label: startCase(SERVICE_TYPE_ORIGIN_TRANSPORT),
  },
  {
    value: SERVICE_TYPE_DESTINATION_TRANSPORT,
    label: startCase(SERVICE_TYPE_DESTINATION_TRANSPORT),
  },
  {
    value: SERVICE_PALLETIZATION,
    label: startCase(SERVICE_PALLETIZATION),
  },
  {
    value: SERVICE_FUMIGATION,
    label: startCase(SERVICE_FUMIGATION),
  },
  {
    value: SERVICE_CARGO_INSURANCE,
    label: startCase(SERVICE_CARGO_INSURANCE),
  },
  {
    value: SERVICE_EMPTY_CONTAINER_INSURANCE,
    label: startCase(SERVICE_EMPTY_CONTAINER_INSURANCE),
  },
  {
    value: SERVICE_EMPTY_WAREHOUSE,
    label: startCase(SERVICE_EMPTY_WAREHOUSE),
  },
  {
    value: SERVICE_EMPTY_LIVE_TRACKING,
    label: startCase(SERVICE_EMPTY_LIVE_TRACKING),
  },
];

// ! copied from tracker
export type FieldType = {
  id?: string;
  name: string;
  label: string;
  type?: string;
  cellRenderer?: string;
  initialValue?: any;
  rules?: any;
  dependency?: {
    name: string;
    value?: any;
  };
  options?: {
    name: string;
    value: string;
  }[];
  colSpan?: number;
  defaultDisplay?: boolean; // default show dependent field
  searchProps?: any;
  fieldType?: string;
  onChange?: (val?: any, rf?: any) => void;
  addonBefore?: React.ReactNode;
  addonAfter?: React.ReactNode;
};

export type TypeFreightType = 'ocean' | 'air' | 'road' | 'rail';

export const PERMISSION_ESTIMATES_TOTAL = 'margin';
export const PERMISSION_SALES_MODULE = 'list';
export const DOCUMENT_PERMISSION_VIEW = 'view';
export const PERMISSION_BUY_ESTIMATE = 'buy';
export const PERMISSION_SELL_ESTIMATE = 'sell';

export const ENUM_BUY_SELL_STATUS = {
  UNBILLED: 'unbilled',
  BILLED: 'billed',
  DRAFT: 'draft',
  CANCELLED: 'cancelled',
  IGNORED: 'ignored',
};
export type ENUM_BUY_SELL_STATUS_TYPE = 'unbilled' | 'billed' | 'draft' | 'ignored';

export const DUE_CARRIER_TAG = 'due_carrier';

export const CHARGE_AMOUNT_PREPAID = 'prepaid';
export const CHARGE_AMOUNT_COLLECT = 'collect';
export const CHARGE_TERMS = [
  { key: CHARGE_AMOUNT_PREPAID, name: 'PP-prepaid' },
  { key: CHARGE_AMOUNT_COLLECT, name: 'CC-collect' },
];

export const MARKUP_TYPE_PERCENTAGE = 'percentage';
export const MARKUP_TYPE_VALUE = 'value';
export const MARKUP_TYPE = [
  { key: MARKUP_TYPE_VALUE, name: 'By Value' },
  { key: MARKUP_TYPE_PERCENTAGE, name: 'By %' },
];

export const BASIS_TYPE_CHARGEABLE_WEIGHT = {
  name: 'Chargeable Weight',
  key: 'chargeable_weight',
};

export const BASIS_TYPE_CHARGEABLE_VOLUME = {
  name: 'Chargeable Volume',
  key: 'chargeable_volume',
};

export const BASIS_TYPE_GROSS_WEIGHT = {
  name: 'Gross Weight',
  key: 'gross_weight',
};

export const BASIS_TYPE_SHIPMENT = {
  name: 'Shipment',
  key: 'shipment',
};

export const BASIS_TYPE_GROSS_VOLUME = {
  name: 'Gross Volume',
  key: 'gross_volume',
};

export const BASIS_TYPE_PACKAGES = {
  name: 'Package',
  key: 'total_number_of_packages',
};

export const BASIS_TYPE_ULD = {
  name: 'Uld',
  key: 'uld',
};

export const BASIS_TYPE_CONTAINER = {
  name: 'Container',
  key: 'container',
};

export const INQUIRY_OPTION_WON_STATUS = 'won';
export const INQUIRY_OPTION_LOST_STATUS = 'lost';
export const INQUIRY_OPTION_DRAFT_STATUS = 'draft';
export const INQUIRY_OPTION_CANCELLED_STATUS = 'cancelled';
export const INQUIRY_OPTION_SEND_TO_CUSTOMER_STATUS = 'sent_to_customer';
export const INQUIRY_OPTION_HOLD_STATUS = 'hold';
export const INQUIRY_OPTION_APPROVED_STATUS = 'approved';

export const TRIGGER_AFTER_QUOTE_WON = 'after_quote_won';
export const ACTOR_TYPE_QUOTE = 'Quote';

export const INQUIRY_OPTIONS_DISPLAY_HASH: { [key: string]: string } = {
  [INQUIRY_OPTION_WON_STATUS]: 'Won',
  [INQUIRY_OPTION_DRAFT_STATUS]: 'Draft',
  [INQUIRY_OPTION_LOST_STATUS]: 'Lost',
  [INQUIRY_OPTION_CANCELLED_STATUS]: 'Cancelled',
  [INQUIRY_OPTION_SEND_TO_CUSTOMER_STATUS]: 'Sent To Customer',
  [INQUIRY_OPTION_HOLD_STATUS]: 'Hold',
  [INQUIRY_OPTION_APPROVED_STATUS]: 'Approved',
};

export const INQUIRY_OPTIONS_COLOR_HASH = {
  [INQUIRY_OPTION_WON_STATUS]: 'green',
  [INQUIRY_OPTION_DRAFT_STATUS]: 'default',
  [INQUIRY_OPTION_LOST_STATUS]: 'red',
  [INQUIRY_OPTION_CANCELLED_STATUS]: 'red',
  [INQUIRY_OPTION_SEND_TO_CUSTOMER_STATUS]: 'blue',
  [INQUIRY_OPTION_APPROVED_STATUS]: 'green',
};

export const PRINT_TEMPLATE_TYPE = 'print';
export const TERMS_AND_COND_TEMPLATE_TYPE = 'terms_and_condition';
export const DOC_INS_TEMPLATE_TYPE = 'documentation_instructions';
export const LETTER_HEAD_TEMPLATE_TYPE = 'letter_head';

export const CREATE_BLANK_ACTION = 'blank_action';
export const CREATE_COPY_ACTION = 'create_copy_action';
export const RATE_SEARCH_ACTION = 'rate_search_action';

export const RENDER_INQUIRY_FOR_WON = 'mark_won';
export const RENDER_INQUIRY_FOR_LOST = 'mark_lost';

export const SALES_PERSON_DISABLED_FEATURE = 'sales_person_disabled';

export const CONDITION_COMPUTE_MARGIN = 'compute_margin_percentage';
export const CONDITION_COMPUTE_FREIGHT_MARGIN = 'compute_freight_margin_percentage';

export const TRADE_TYPE_EXPORT = 'export';
export const TRADE_TYPE_IMPORT = 'import';
export const TRADE_TYPE_DOMESTIC = 'domestic';
export const TRADE_TYPE_CROSS_TRADE = 'cross_trade';

export const PORT_TO_PORT_SERVICE = 'port_to_port';
export const PORT_TO_DOOR_SERVICE = 'port_to_door';
export const DOOR_TO_PORT_SERVICE = 'door_to_port';
export const DOOR_TO_DOOR_SERVICE = 'door_to_door';
export const PORT_TO_ICD_SERVICE = 'port_to_icd';
export const ICD_TO_PORT_SERVICE = 'icd_to_port';
export const ICD_TO_ICD_SERVICE = 'icd_to_icd';
export const COMMON_SERVICES = [
  PORT_TO_PORT_SERVICE,
  PORT_TO_DOOR_SERVICE,
  DOOR_TO_PORT_SERVICE,
  DOOR_TO_DOOR_SERVICE,
];

export const OCEAN_SERVICES = [ICD_TO_PORT_SERVICE, PORT_TO_ICD_SERVICE, ICD_TO_ICD_SERVICE];

export const CONTAINER_PICKUP_AND_DROP_MOVEMENT_MODE = 'container_pickup_and_drop';
export const CARGO_PICKUP_AND_DROP_MOVEMENT_MODE = 'cargo_pickup_and_drop';
export const CONTAINER_STUFFING_MOVEMENT_MODE = 'container_stuffing';
export const CONTAINER_DESTUFFING_MOVEMENT_MODE = 'container_destuffing';
export const AIRPORT_PICKUP_MOVEMENT_MODE = 'airport_pickup';
export const AIRPORT_DROP_MOVEMENT_MODE = 'airport_drop';
export const MOVEMENT_MODES = [
  CONTAINER_PICKUP_AND_DROP_MOVEMENT_MODE,
  CARGO_PICKUP_AND_DROP_MOVEMENT_MODE,
  CONTAINER_STUFFING_MOVEMENT_MODE,
  CONTAINER_DESTUFFING_MOVEMENT_MODE,
  AIRPORT_PICKUP_MOVEMENT_MODE,
  AIRPORT_DROP_MOVEMENT_MODE,
];

export const serviceLabelMap = {
  ...COMMON_SERVICES.reduce(
    (acc, service) => ({ ...acc, [service]: startCase(service.replace('_', ' ')) }),
    {}
  ),
  ...MOVEMENT_MODES.reduce(
    (acc, movement) => ({ ...acc, [movement]: startCase(movement.replace('_', ' ')) }),
    {}
  ),
  [ICD_TO_PORT_SERVICE]: 'ICD / Ramp To Port',
  [PORT_TO_ICD_SERVICE]: 'Port To ICD / Ramp',
  [ICD_TO_ICD_SERVICE]: 'ICD / Ramp To ICD / Ramp',
};

export const freightTypeIconProps: {
  [key in TypeFreightType]?: { height?: number; width?: number; icon: any };
} = {
  [FREIGHT_TYPE_AIR]: {
    height: 12,
    width: 12,
    icon: 'FlightTakeoff',
  },
  [FREIGHT_TYPE_OCEAN]: {
    icon: 'ShipIconNew',
  },
  [FREIGHT_TYPE_ROAD]: {
    icon: 'TruckIcon',
  },
};

export const PRIORITY_HIGHEST = 'highest';
export const PRIORITY_HIGH = 'high';
export const PRIORITY_MEDIUM = 'medium';
export const PRIORITY_LOW = 'low';
export const PRIORITY_LOWEST = 'lowest';
export const ALL_PRIORITIES = [
  PRIORITY_HIGHEST,
  PRIORITY_HIGH,
  PRIORITY_MEDIUM,
  PRIORITY_LOW,
  PRIORITY_LOWEST,
];

export const BUSINESS_THROUGH_DIRECT_OPTION = 'direct';
export const BUSINESS_RECEIVED_THROUGH_OPTION = [
  {
    value: 'direct',
  },
  {
    value: 'subagent',
  },
  {
    value: 'agent_nomination',
    label: 'Overseas Nomination',
  },
];

export const INQUIRY_ADDITIONAL_PARTIES = ['shipper', 'consignee'];

export const FORWARDING_SERVICES = 'freight_forwarding';
export const ORIGIN_CUSTOM_SERVICES = 'origin_custom_clearance';
export const DESTINATION_CUSTOM_SERVICES = 'destination_custom_clearance';
export const SERVICES = [
  { label: 'Forwarding', value: 'freight_forwarding' },
  { label: 'Origin Custom Clearance', value: 'origin_custom_clearance' },
  { label: 'Origin Transport', value: 'origin_transport', disabled: false },
  { label: 'Destination Custom Clearance', value: 'destination_custom_clearance' },
  { label: 'Destination Transport', value: 'destination_transport', disabled: false },
  { label: 'Live Tracking', value: 'live_tracking' },
  { label: 'Cargo Insurance', value: 'cargo_insurance', disabled: false },
  { label: 'Empty Container Insurance', value: 'empty_container_insurance', disabled: false },
  { label: 'Palletization', value: 'palletization', disabled: false },
  { label: 'Fumigation', value: 'fumigation', disabled: false },
  { label: 'Warehousing / CFS', value: 'warehouse', disabled: false },
];

export const RATES_RESOURCE_TYPE_MAP: { [key: string]: string } = {
  'SalesHub::InquiryOption': 'Quote',
  'Shipment::Shipment': 'Job',
  'RateManagement::Contract': 'Contract',
};

export const VEHICLE_CATEGORY = [
  { name: 'car_carrier_trailer', value: 'Car Carrier Trailer' },
  { name: 'car_carrier_truck', value: 'Car Carrier Truck' },
  { name: 'chassis_on_trailer', value: 'Chassis On Trailer (COT)' },
  { name: 'crane', value: 'Crane' },
  { name: 'excavator', value: 'Excavator' },
  { name: 'flat_bed_trailer', value: 'Flat Bed Trailer' },
  { name: 'forklift', value: 'Forklift' },
  { name: 'heavy_commercial_vehicle', value: 'Heavy Commercial Vehicle (HCV)' },
  { name: 'intermediate_commercial_vehicle', value: 'Intermediate Commercial Vehicle (ICV)' },
  { name: 'light_commercial_vehicle', value: 'Light Commercial Vehicle (LCV)' },
  { name: 'loader', value: 'Loader' },
  { name: 'low_bed_trailer', value: 'Low Bed Trailer' },
  { name: 'medium_commercial_vehicle', value: 'Medium Commercial Vehicle (MCV)' },
  { name: 'semi_low_bed_trailer', value: 'Semi Low Bed Trailer' },
  { name: 'small_commercial_vehicle', value: 'Small Commercial Vehicle (SCV)' },
  { name: 'tractor', value: 'Tractor' },
  { name: 'chiller_1_ton', value: '1 Ton Chiller' },
  { name: 'freezer_1_ton', value: '1 Ton Freezer' },
  { name: 'freezer_1_and_half_ton', value: '1.5 Ton Freezer' },
  { name: 'chiller_with_tail_gate_3_ton', value: '3 Ton Chiller With Tail Gate' },
  { name: 'open_truck_3_ton', value: '3 Ton Open Truck' },
  { name: 'chiller_with_tail_gate_4_ton', value: '4 Ton Chiller With Tail Gate' },
  { name: 'dry_box_with_tail_gate_4_ton', value: '4 Ton Dry Box With Tail Gate' },
  { name: 'freezer_with_tail_gate_4_ton', value: '4 Ton Freezer With Tail Gate' },
  { name: 'dry_box_with_tail_gate_5_ton', value: '5 Ton Dry Box With Tail Gate' },
  { name: 'open_pickup_7_ton', value: '7 Ton Open Pickup' },
  { name: 'car', value: 'Car' },
  { name: 'hilux', value: 'Hilux' },
];
