import React, { useEffect, useState } from 'react';
import { RowNode } from '@ag-grid-community/core';
import { Action } from 'operations/modules/actionHelper/models';
import { PRIMARY_TYPE } from 'operations/modules/actionHelper/constants';

import ChangeContainerTypeForm from './ChangeContainerTypeForm';
import {
  GetIconForAction,
  bulkEventActionsRenderer,
  getNextPossibleAction,
} from '../ShipmentContainerBulkActionsHelper';
import { CustomIcon } from '@shipmnts/pixel-hub';
import { startCase } from 'lodash';
import AssignLoadWrapper from 'operations/modules/shipment/components/ShipmentForm/AssignLoadWrapper';

const BulkContainerActionsHandler = React.memo(function BulkContainerActionsHandler(props: {
  selectedNodes: RowNode[];
  selectedNodeCounts: number;
  onClose: () => void;
  onSuccess: () => void;
  action_name: string | undefined;
  doctype_id: string;
}): JSX.Element {
  const { selectedNodes, onSuccess, selectedNodeCounts, onClose } = props;
  const [selectedDocIds, setSelectedDocIds] = useState<string[]>([]);

  useEffect(() => {
    if (selectedNodeCounts > 0) {
      const ids = selectedNodes.map((i) => i.data.id.toString());
      setSelectedDocIds(ids);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedNodes, selectedNodeCounts]);

  return <ChangeContainerTypeForm id={selectedDocIds} onSuccess={onSuccess} onClose={onClose} />;
});

const getContainerAction = (action_name: string, renderer: any): Action => {
  return {
    key: action_name,
    type: PRIMARY_TYPE,
    displayComponent: action_name,
    icon: <GetIconForAction eventName={action_name} />,
    description: action_name,
    performAction: renderer,
    isEnable: true,
    doc_type_id: 'Shipment::ShipmentContainer',
    extraProps: {
      action_name: action_name,
      doctype_id: 'Shipment::ShipmentContainer',
    },
  };
};

export const bulkContainerActionsRenderer = (
  selectedNodes: RowNode[],
  onSuccess: () => void,
  selectedNodeCounts: number,
  extraProps: any,
  onCloseModal: () => void
) => {
  return {
    actionParams: {
      selectedNodes: selectedNodes,
      onSuccess: onSuccess,
      selectedNodeCounts: selectedNodeCounts,
      onCloseModal: onCloseModal,
      action_name: extraProps.action_name,
      doctype_id: extraProps.doctype_id,
    },
    component: BulkContainerActionsHandler,
  };
};

export const bulkAssignLoadActionRenderer = (
  selectedNodes: RowNode[],
  onSuccess: () => void,
  onCloseModal: () => void
) => {
  return {
    actionParams: {
      selectedNodes: selectedNodes,
      onSuccess: onSuccess,
      onCloseModal: onCloseModal,
      selectedIds: selectedNodes.map((node) => node.data?.id?.toString()),
      isFcl: true,
    },
    component: AssignLoadWrapper,
  };
};

const bulkAssignLoadAction = (action_name: string): Action => {
  return {
    key: action_name,
    type: PRIMARY_TYPE,
    displayComponent: startCase(action_name),
    icon: <CustomIcon icon="Package" />,
    description: startCase(action_name),
    performAction: bulkAssignLoadActionRenderer,
    isEnable: true,
    doc_type_id: 'Shipment::ShipmentContainer',
  };
};

export const getContainerBulkActions = (selectedNodes: RowNode[]) => {
  if (selectedNodes.length === 0) return [];

  const nextActions: string[] = getNextPossibleAction(selectedNodes);
  const actions: Action[] = [];
  nextActions.forEach((action_name: string) => {
    if (action_name === 'Assign Load') actions.push(bulkAssignLoadAction('Assign Load'));
    else actions.push(getContainerAction(action_name, bulkEventActionsRenderer));
  });
  actions.push(getContainerAction('Change Container Type', bulkContainerActionsRenderer));
  return actions;
};
