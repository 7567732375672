import React, { useState, useEffect, useCallback } from 'react';
import { Modal, message as Message, Dayjs, getJsonFromError } from '@shipmnts/pixel-hub';
import { pick as _pick } from 'lodash';
import { MessageValue, AttachmentValue } from 'operations/models/Message';
import { useSession, errorMessageHandlerGraphQLString } from 'common';
// import { errorMessageHandlerGraphQL } from 'common';

import InboxMessageActionDrawer, {
  actionTypeForInboxMessage,
  DISCARD_MESSAGE_ACTION,
  CREATE_SHIPMENT_ACTION,
  ASSIGN_TO_ACTION,
} from './InboxMessageActionDrawer';
import { useMutation } from '@apollo/client';
import {
  VALIDATE_CREATE_SHIPMENT_FROM_MESSAGE,
  CREATE_SHIPMENT_FROM_MESSAGE,
  ASSIGNED_USER_TO_MESSAGE,
  TENANT_MAPPING_ADDRESS_TYPE,
  TENANT_MAPPING_CHARGE_TYPE,
} from 'operations/modules/message/graphql/message';
import {
  ResourceDataValue,
  TenantMappingMessageValue,
  CompanyMappingMessageValue,
  ChargeMappingMessageValue,
} from 'operations/modules/message/messageResourceDataModel';
import { CreateShipmentFromDataValue } from 'operations/modules/message/helpers/messageHelper';
import { ShipmentDocumentFormValue } from 'operations/modules/message/components//MessageShipmentDocumentDetails';
import { ShipmentPartyValue } from 'operations/models/ShipmentParty';
import { CompanyValue } from 'operations/models/Company';
import { constants } from 'network';
import { ApolloError } from '@apollo/client';
import { RecentEventEmailDrawer } from 'operations/components/ChangeEvent/EventEmailDrawer';
import { SalesPersonValue } from 'common/models/SalesPerson';
import Spinner from 'src/components/Spinner';
import { getCargoPayloadWithExistingCargo } from 'operations/modules/shipment/components/ShipmentForm/helpers';

export interface ExistingShipmentType {
  id?: string;
  job_number?: string;
}

export interface TenantMappingsPayloadValue {
  sender_reference_id: string;
  receiver_reference_id: string;
  resource_type: string;
}

export interface ShipmentPartiesPayloadvalue {
  name?: string;
  party_company_id?: string | null;
  party_address_id?: string | null;
}

export interface ShipmentFormDataPayloadValue {
  id?: string;
  job_date?: Dayjs;
  business_type?: string;
  consol_type?: string;
  involved_branch_id?: string;
  customer_company_id?: string;
  customer_address_id?: string;
  sales_agent?: SalesPersonValue;
  sales_agent_id?: number;
  shipment_documents?: ShipmentDocumentFormValue[];
  shipment_parties?: ShipmentPartiesPayloadvalue[];
}

export interface CreateShipmentPayloadValue {
  message_id: string;
  shipments: ShipmentFormDataPayloadValue[];
  tenant_mappings: TenantMappingsPayloadValue[];
  finance_party: string | null;
  selected_attachments: { document_identifier: string; tags?: string[] }[];
}

const renderValidateShipmentLoader = () => {
  return (
    <Modal title="Validating Shipment Data" centered open footer={null}>
      <Spinner size="large" tip="Loading..." style={{ width: '100%' }} />
    </Modal>
  );
};

const InboxMessageActions = React.memo(function InboxMessageActions(props: {
  messageRow: MessageValue;
  actionName: actionTypeForInboxMessage;
  onClose: () => void;
  onSuccess: () => void;
}): JSX.Element {
  const { messageRow, actionName, onClose: onCloseDrawer, onSuccess } = props;
  const [activeAction, setActiveAction] = useState<actionTypeForInboxMessage | undefined>(
    actionName
  );
  const [shipments, setShipments] = useState<ResourceDataValue[]>([]);
  const [showEmail, setShowEmail] = useState<boolean>(false);
  const [existingShipment, setExistingShipment] = useState<ExistingShipmentType | null>(null);
  const [{ shipmentsFormValue, companyMappingFormValues, activeShipmentIndex }, setShipmentsValue] =
    useState<{
      shipmentsFormValue: CreateShipmentFromDataValue[];
      companyMappingFormValues: { [key: string]: ShipmentPartyValue };
      activeShipmentIndex: number;
    }>({ shipmentsFormValue: [], companyMappingFormValues: {}, activeShipmentIndex: 0 });
  const [companyMapping, setCompanyMapping] = useState<CompanyMappingMessageValue[]>([]);
  const [tenantMapping, setTenantMapping] = useState<ChargeMappingMessageValue[]>([]);
  const [attachments, setAttachments] = useState<AttachmentValue[]>([]);
  const [senderCompany, setSenderCompany] = useState<CompanyValue | null>();
  const [showActionDrawer, setShowActionDrawer] = useState(false);
  const [valideCreateShipmentFromMessage, valideCreateShipmentFromMessageResponse] = useMutation(
    VALIDATE_CREATE_SHIPMENT_FROM_MESSAGE
  );
  const [createShipmentMutation, createShipmentMutationResponse] = useMutation(
    CREATE_SHIPMENT_FROM_MESSAGE
  );
  const [updateAssignedTo, { data: assignToUserData, error: assignToUserError }] =
    useMutation(ASSIGNED_USER_TO_MESSAGE);
  const sessionData = useSession();

  const onClose = useCallback(() => {
    setActiveAction(undefined);
    if (!assignToUserError && assignToUserData?.update_message) {
      onSuccess();
    }
    onCloseDrawer();
  }, [onSuccess, assignToUserData, assignToUserError, setActiveAction, onCloseDrawer]);

  const updateAsssignedToMessage = useCallback(() => {
    if (!messageRow.assigned_to) {
      updateAssignedTo({
        variables: { id: messageRow.id, message: { assigned_to_id: sessionData.id } },
      });
    }
  }, [messageRow, updateAssignedTo, sessionData]);

  useEffect(() => {
    if (!showActionDrawer && activeAction === CREATE_SHIPMENT_ACTION) {
      updateAsssignedToMessage();
      valideCreateShipmentFromMessage({
        variables: {
          message_id: messageRow.id,
          types: [TENANT_MAPPING_ADDRESS_TYPE, TENANT_MAPPING_CHARGE_TYPE],
        },
      });
    } else if (!showActionDrawer && activeAction === DISCARD_MESSAGE_ACTION) {
      updateAsssignedToMessage();
      setShowActionDrawer(true);
    } else if (!showActionDrawer && activeAction === ASSIGN_TO_ACTION) {
      setShowActionDrawer(true);
    }
  }, [
    showActionDrawer,
    activeAction,
    messageRow,
    updateAsssignedToMessage,
    valideCreateShipmentFromMessage,
  ]);

  useEffect(() => {
    if (activeAction === CREATE_SHIPMENT_ACTION && !showActionDrawer && !existingShipment) {
      if (valideCreateShipmentFromMessageResponse?.error) {
        Message.error(
          errorMessageHandlerGraphQLString(valideCreateShipmentFromMessageResponse?.error)
        );
      } else if (
        valideCreateShipmentFromMessageResponse?.data?.validate_create_shipment_from_message
      ) {
        const result =
          valideCreateShipmentFromMessageResponse?.data?.validate_create_shipment_from_message;
        if ((result?.existing_shipments || []).length > 0) {
          setExistingShipment(result.existing_shipments[0]);
        } else if (result.errors) {
          Message.error(result.errors.join(', '));
        } else if (result.message) {
          const { house_shipments = [], ...shipment } = result.message
            .resource_data as ResourceDataValue;
          setCompanyMapping(
            result.tenant_mappings
              .filter(
                (ele: TenantMappingMessageValue) =>
                  ele.resource_type === TENANT_MAPPING_ADDRESS_TYPE
              )
              .map((ele: TenantMappingMessageValue) => {
                return {
                  source_address_id: ele.sender_reference_id,
                  destination_address: ele.destination_address,
                };
              })
          );
          setTenantMapping(
            result.tenant_mappings
              .filter(
                (ele: TenantMappingMessageValue) => ele.resource_type === TENANT_MAPPING_CHARGE_TYPE
              )
              .map((ele: TenantMappingMessageValue) => {
                return {
                  source_item: ele.sender_reference_id,
                  destination_item: ele.receiver_reference_id,
                };
              })
          );
          setAttachments(result.message?.attachments as AttachmentValue[]);
          setSenderCompany(result.sender_local_company);
          const all_shipments = [shipment].concat(house_shipments || []) as ResourceDataValue[];
          setShipments(all_shipments);
          setShowActionDrawer(true);
        }
      }
    }
  }, [
    valideCreateShipmentFromMessageResponse,
    activeAction,
    existingShipment,
    showActionDrawer,
    setActiveAction,
  ]);

  useEffect(() => {
    if (activeAction === CREATE_SHIPMENT_ACTION) {
      const graphQLErrors =
        createShipmentMutationResponse?.error instanceof ApolloError
          ? createShipmentMutationResponse?.error.graphQLErrors
          : createShipmentMutationResponse?.error;
      if (
        createShipmentMutationResponse?.error &&
        graphQLErrors &&
        !getJsonFromError(graphQLErrors)
      ) {
        const err_msg = errorMessageHandlerGraphQLString(
          createShipmentMutationResponse.error,
          'Error in create shipment!'
        );
        Message.error(err_msg);
      } else if (createShipmentMutationResponse?.data?.create_shipment_from_message) {
        Message.success('Shipment created successfully!');
        if (!showEmail) {
          onClose();
          onSuccess();
        }
      }
    }
  }, [createShipmentMutationResponse, onClose, onSuccess, activeAction, showEmail]);

  const getShipmentFormDataPayloadValue = useCallback(
    (val: CreateShipmentFromDataValue): ShipmentFormDataPayloadValue => {
      let shipment_parties: ShipmentPartiesPayloadvalue[] = [];
      if (val.party) {
        shipment_parties = Object.keys(val.party || {})
          .filter((party_type) => val.party && val.party[party_type])
          .map((party_type) => {
            const party_value: ShipmentPartiesPayloadvalue = {};
            if (val.party && val.party[party_type]) {
              party_value.name = party_type;
              party_value.party_address_id = val.party[party_type]?.party_address?.id;
              party_value.party_company_id = val.party[party_type]?.party_company?.id;
            }
            return party_value;
          });
      }
      const shipment_documents = val?.shipment_documents?.map((sd: ShipmentDocumentFormValue) => {
        return { ...sd, document_status_event_date: sd?.document_status_event_date?.unix() };
      });
      const payload = {
        ..._pick(val, [
          'id',
          'job_date',
          'trade_type',
          'business_type',
          'consol_type',
          'involved_branch_id',
          'services',
          'lfd_at_carrier',
          'lfd_at_empty_return',
          'lfd_at_pocd',
          'lfd_at_pod',
          'destination_carrier_free_days',
          'destination_port_free_days',
          'origin_carrier_free_days',
        ]),
        sales_agent_id: val?.sales_agent?.id,
        shipment_parties,
        customer_company_id: val.customer?.party_company?.id,
        customer_address_id: val.customer?.party_address?.id,
        business_vertical_id: val?.business_vertical?.id,
        shipment_documents,
        cargos: getCargoPayloadWithExistingCargo(val, undefined)?.map((cargo: any) => {
          return {
            ...cargo,
            gross_weight: parseFloat(cargo?.gross_weight),
            gross_volume: parseFloat(cargo?.gross_volume),
            net_weight: parseFloat(cargo?.net_weight),
            received_weight: parseFloat(cargo?.received_weight),
          };
        }),
      };

      return payload as ShipmentFormDataPayloadValue;
    },
    []
  );

  const getCreateShipmentPayloadValue = useCallback(
    (newShipmentsFormValue: CreateShipmentFromDataValue[]) => {
      const shipments_payload = newShipmentsFormValue.reduce(
        (acc: CreateShipmentPayloadValue, s: CreateShipmentFromDataValue) => {
          const {
            companies_mappings,
            charge_mapping,
            selected_attachment_ids,
            uploaded_documents,
            finance_party,
            ...shipmentFormDataValue
          } = s;
          acc.shipments.push(getShipmentFormDataPayloadValue(shipmentFormDataValue));
          if (companies_mappings) {
            const shipment_companies_mappings = Object.keys(companies_mappings || {}).reduce(
              (acc: TenantMappingsPayloadValue[], source_address_id: string) => {
                const destination_address_id: string | undefined =
                  companies_mappings[source_address_id]?.party_address?.id;
                if (destination_address_id)
                  acc.push({
                    sender_reference_id: source_address_id,
                    receiver_reference_id: destination_address_id,
                    resource_type: TENANT_MAPPING_ADDRESS_TYPE,
                  });
                return acc;
              },
              [] as TenantMappingsPayloadValue[]
            );

            acc.tenant_mappings = acc.tenant_mappings.concat(shipment_companies_mappings);
          }

          if (charge_mapping) {
            Object.keys(charge_mapping || {}).forEach((source_charge: string) => {
              const destination_charge = charge_mapping[source_charge];
              if (destination_charge)
                acc.tenant_mappings.push({
                  sender_reference_id: source_charge,
                  receiver_reference_id:
                    typeof destination_charge === 'string'
                      ? destination_charge
                      : destination_charge?.item_code,
                  resource_type: TENANT_MAPPING_CHARGE_TYPE,
                });
            });
          }

          if (selected_attachment_ids) {
            acc.selected_attachments = (acc.selected_attachments || []).concat(
              selected_attachment_ids.map((attachment_id) => ({
                document_identifier: attachment_id,
                tags: uploaded_documents && uploaded_documents[attachment_id]?.tags,
              }))
            );
          }
          if (finance_party) acc.finance_party = finance_party;
          return acc;
        },
        {
          message_id: messageRow.id,
          shipments: [],
          tenant_mappings: [],
          selected_attachments: [],
          finance_party: null,
        } as CreateShipmentPayloadValue
      );
      return shipments_payload;
    },
    [messageRow, getShipmentFormDataPayloadValue]
  );

  const onCreateShipmentFormSubmit = useCallback(
    (values: CreateShipmentFromDataValue, throw_error_on_credit_fail = true) => {
      const newShipmentsFormValue = [
        ...shipmentsFormValue.slice(0, activeShipmentIndex),
        values,
        ...shipmentsFormValue.slice(activeShipmentIndex + 1),
      ];
      if (activeShipmentIndex === shipments.length - 1) {
        const variables: any = getCreateShipmentPayloadValue(newShipmentsFormValue);
        if (sessionData.isFeatureEnabled(constants.CREDIT_CONTROL_FEATURE)) {
          variables['throw_error_on_credit_fail'] = throw_error_on_credit_fail;
          if (!throw_error_on_credit_fail) setShowEmail(true);
        }

        if ((attachments || []).length > 0 && (variables.selected_attachments || []).length === 0) {
          Message.error('Please select atleast one uploaded document attachment');
        } else {
          createShipmentMutation({ variables });
        }
      } else {
        setShipmentsValue({
          shipmentsFormValue: newShipmentsFormValue,
          companyMappingFormValues: {
            ...companyMappingFormValues,
            ...values.companies_mappings,
          } as { [key: string]: ShipmentPartyValue },
          activeShipmentIndex: activeShipmentIndex + 1,
        });
      }
    },
    [
      shipmentsFormValue,
      activeShipmentIndex,
      shipments.length,
      getCreateShipmentPayloadValue,
      attachments,
      sessionData,
      createShipmentMutation,
      companyMappingFormValues,
    ]
  );

  const renderDuplicateShipmentModal = useCallback(() => {
    Modal.confirm({
      title: 'We found a duplicate shipment',
      content: (
        <>
          <div>We found a shipment matching with MBL and HBL #.</div>
          <div>
            Please click on the job{' '}
            <b>
              <a
                href={`${process.env.OPERATIONS_URL}/view/shipment/${existingShipment?.id}/documents`}
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: 'inherit' }}
              >
                {existingShipment?.job_number}
              </a>
            </b>{' '}
            to open it in new tab
          </div>
        </>
      ),
      okText: 'Go To Shipment',
      cancelText: 'Close',
      onCancel: () => {
        setActiveAction(undefined);
      },
      onOk: () => {
        const url = `${process.env.OPERATIONS_URL}/view/shipment/${existingShipment?.id}/documents`;
        window.open(url, '_blank')?.focus();
      },
    });
  }, [existingShipment, setActiveAction]);
  const default_company = sessionData?.company_account?.default_company;

  return (
    <>
      {valideCreateShipmentFromMessageResponse?.loading && renderValidateShipmentLoader()}
      {Boolean(existingShipment) && renderDuplicateShipmentModal()}
      {showEmail && createShipmentMutationResponse?.data?.create_shipment_from_message?.id && (
        <RecentEventEmailDrawer
          setIsFirstLoad={setShowEmail}
          id={createShipmentMutationResponse?.data?.create_shipment_from_message?.id}
          companies_roles_mapping={[
            {
              role: 'Your Company',
              id: default_company?.id,
              registered_name: default_company?.registered_name,
              address_id:
                createShipmentMutationResponse?.data?.create_shipment_from_message?.involved_branch
                  ?.default_address?.id,
            },
          ]}
        />
      )}
      {Boolean(showActionDrawer) && (
        <InboxMessageActionDrawer
          message={messageRow}
          companyMapping={companyMapping}
          tenantMapping={tenantMapping}
          attachments={attachments}
          shipment={shipments.length ? shipments[activeShipmentIndex] : undefined}
          shipmentInitialValue={
            shipmentsFormValue.length && shipmentsFormValue[activeShipmentIndex]
              ? shipmentsFormValue[activeShipmentIndex]
              : undefined
          }
          masterShipmentInitialValue={shipmentsFormValue.length ? shipmentsFormValue[0] : undefined}
          companyMappingFormValues={companyMappingFormValues}
          senderCompany={senderCompany}
          activeShipmentIndex={activeShipmentIndex}
          lastShipmentIndex={(shipments || []).length - 1}
          onCreateShipmentFormSubmit={onCreateShipmentFormSubmit}
          activeAction={activeAction}
          setActiveAction={setActiveAction}
          onClose={onClose}
          onBack={() => {
            setShipmentsValue({
              shipmentsFormValue,
              companyMappingFormValues,
              activeShipmentIndex: activeShipmentIndex - 1,
            });
          }}
          onSuccess={onSuccess}
          loading={createShipmentMutationResponse.loading}
          error={createShipmentMutationResponse?.error}
        />
      )}
    </>
  );
});

export default InboxMessageActions;
