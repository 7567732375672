export const PRIMARY_TYPE = 'primary';
export const SECONDARY_TYPE = 'secondary';
export const TERTIARY_TYPE = 'tertiary';
export const NEGATIVE_TYPE = 'negative';
export const DOCUMENT_MBL = 'MBL';
export const DOCUMENT_HBL = 'HBL';
export const DOCUMENT_MAWB = 'MAWB';
export const DOCUMENT_HAWB = 'HAWB';
export const EVENT_EMPTY_PICKED_UP = 'Empty Picked Up';
export const EVENT_ROUTING_UPDATED = 'Routing Updated';
export const DOCUMENT_RELEASE_HBL = 'Release HBL';

export const getActionClassName = (type: string, isAdmin: boolean) => {
  const className = [];
  if (type === NEGATIVE_TYPE) className.push('color-red');
  if (isAdmin) className.push('admin-class');
  return className.join(' ');
};
