import { Checkbox, Col, Form, FormInstance, Row } from '@shipmnts/pixel-hub';
import { AddressCompanySearch } from 'common';
import React, { useEffect } from 'react';
import { ROW_GUTTER } from 'operations/modules/shipment/constants';
import { PartyValue } from 'operations/modules/reports/components/CreateShipmentFormContent';
import { ShipmentValue } from '@shipmnts/pixel-hub/dist/components/common/models/Shipment';

interface SwitchBlPartyProps {
  shipment?: ShipmentValue;
  form?: FormInstance;
}
export default function SwitchBLPartyDetails({ form, shipment }: SwitchBlPartyProps) {
  const getUpdatedPartyValue = (party?: PartyValue | null, value?: PartyValue | null) => {
    return {
      ...(party || null),
      party_company: value?.party_company,
      party_address: value?.party_address,
    };
  };
  const isSameParty = (party1?: PartyValue | null, party2?: PartyValue | null) => {
    if (party1 && party2) return party1?.party_company?.id === party2?.party_company?.id;
    else return false;
  };

  useEffect(() => {
    form?.setFieldValue('will_issue_switch_bl', true);
  }, [form]);
  return (
    <div>
      <Form.Item name="will_issue_switch_bl" label="Will Issue Switch BL" noStyle>
        <span />
      </Form.Item>
      <Row gutter={ROW_GUTTER}>
        <Col span={6} style={{ paddingBottom: '10px' }}>
          <Form.Item
            noStyle
            dependencies={[['party', 'consignee'], 'switch_bl_shipper_same_as_consignee']}
          >
            {({ getFieldValue }) => {
              const consignee = getFieldValue(['party', 'consignee']);
              const switchBlShipperSameAsConsignee = getFieldValue(
                'switch_bl_shipper_same_as_consignee'
              );
              const switch_bl_shipper = getFieldValue(['party', 'switch_bl_shipper']);
              if (switchBlShipperSameAsConsignee) {
                if (consignee && !shipment?.id) {
                  form?.setFieldValue(
                    ['party', 'switch_bl_shipper'],
                    getUpdatedPartyValue(switch_bl_shipper, consignee)
                  );
                }
              } else {
                form?.setFieldValue(
                  ['party', 'switch_bl_shipper'],
                  getUpdatedPartyValue(switch_bl_shipper, switch_bl_shipper)
                );
              }

              return (
                <Form.Item
                  style={{ marginBottom: '10px' }}
                  name={['party', 'switch_bl_shipper']}
                  label={'Switch BL Shipper'}
                >
                  <AddressCompanySearch
                    onChange={(value) => {
                      const consignee = form?.getFieldValue(['party', 'consignee']);
                      const updated_switch_blshipper = form?.getFieldValue([
                        'party',
                        'switch_bl_shipper',
                      ]);
                      if (isSameParty(updated_switch_blshipper, consignee))
                        form?.setFieldValue('switch_bl_shipper_same_as_consignee', true);
                      else {
                        form?.setFieldValue('switch_bl_shipper_same_as_consignee', false);
                      }
                    }}
                    addressSearchProps={{
                      showAddBranchAction: true,
                    }}
                  />
                </Form.Item>
              );
            }}
          </Form.Item>
          <Form.Item name={'switch_bl_shipper_same_as_consignee'} noStyle valuePropName="checked">
            <Checkbox
              onChange={(e) => {
                const switch_bl_shipper = form?.getFieldValue(['party', 'switch_bl_shipper']);
                const consignee = form?.getFieldValue(['party', 'consignee']);
                if (!e.target.checked) {
                  if (isSameParty(switch_bl_shipper, consignee))
                    form?.setFieldValue(
                      ['party', 'switch_bl_shipper'],
                      getUpdatedPartyValue(switch_bl_shipper, null)
                    );
                } else {
                  form?.setFieldValue(
                    ['party', 'switch_bl_shipper'],
                    getUpdatedPartyValue(switch_bl_shipper, consignee)
                  );
                }
                form?.setFieldValue('switch_bl_shipper_same_as_consignee', e.target.checked);
              }}
            >
              Same As Consignee
            </Checkbox>
          </Form.Item>
        </Col>
        <Col span={6} style={{ paddingBottom: '10px' }}>
          <Form.Item
            name={['party', 'switch_bl_consignee']}
            label={'Switch BL Consignee'}
            style={{ marginBottom: '10px' }}
          >
            <AddressCompanySearch
              addressSearchProps={{
                showAddBranchAction: true,
              }}
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item name={['party', 'switch_bl_notify_party_1']} label="Switch BL Notify Party">
            <AddressCompanySearch
              addressSearchProps={{
                showAddBranchAction: true,
              }}
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            name={['party', 'switch_bl_delivery_agent']}
            label="Switch BL Destination Agent"
          >
            <AddressCompanySearch
              addressSearchProps={{
                showAddBranchAction: true,
              }}
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item name={['party', 'switch_bl_issuing_agent']} label="Switch BL Issuing Agent">
            <AddressCompanySearch
              addressSearchProps={{
                showAddBranchAction: true,
              }}
            />
          </Form.Item>
        </Col>
      </Row>
    </div>
  );
}
