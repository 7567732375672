import React, { CSSProperties, ReactElement } from 'react';
import { useGenAIFieldContext } from '../GenAIFieldContext';
import { Flex } from '@shipmnts/pixel-hub';
import { get as _get } from 'lodash';

interface GenAIInputWrapperProps {
  children: React.ReactNode;
  fieldKey: string | string[];
  onChange?: (e: any) => void;
  value?: any;
  arrayValueConfig?: ArrayValueConfig;
}

export interface ArrayValueConfig {
  path_to_array: string | string[];
  index?: number;
  findConfig?: {
    key: string;
    value: any;
  };
}

const GenAIInputWrapper = (props: GenAIInputWrapperProps) => {
  const { children, fieldKey, value, onChange, arrayValueConfig } = props;
  const { fields, handleFieldChange } = useGenAIFieldContext();

  let isGenAIField = false;
  if (!arrayValueConfig) {
    isGenAIField = _get(fields, fieldKey)?.showIcon;
  } else {
    const { path_to_array, index, findConfig } = arrayValueConfig;
    const arr = _get(fields, path_to_array);
    if (Array.isArray(arr)) {
      if (index !== undefined && arr.length > index) {
        const field = _get(arr[index], fieldKey);
        isGenAIField = field?.showIcon;
      } else if (findConfig) {
        const field = arr.find((item) => item[findConfig.key] === findConfig.value);
        isGenAIField = _get(field, fieldKey)?.showIcon;
      }
    }
  }

  const child = React.Children.only(children) as ReactElement;

  const childOnChange = child.props.onChange;

  const handleChange = (e: any) => {
    onChange && onChange(e);
    if (childOnChange) {
      childOnChange(e);
    }
    handleFieldChange(fieldKey, arrayValueConfig);
  };

  const style: CSSProperties = {
    width: '100%',
  };

  if (isGenAIField) {
    style['backgroundColor'] = '#FFF2E2';
    style['borderRadius'] = '5px';
    style['padding'] = '3px';
  }

  return (
    <Flex gap={2} align={'flex-start'} style={{ width: '100%' }}>
      {/* {isGenAIField ? <GenAIIcon /> : null} */}
      <span style={style}>
        {React.cloneElement(child, {
          value: value,
          onChange: handleChange,
          style: { ...child.props.style, background: 'transparent' },
        })}
      </span>
    </Flex>
  );
};

export default GenAIInputWrapper;
