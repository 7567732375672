import React, { useEffect, useRef } from 'react';
import MessageShipmentBasicDetails from './MessageShipmentBasicDetails';
import { Card, Row, Col, Form, getJsonFromError } from '@shipmnts/pixel-hub';
import { FormInstance } from '@shipmnts/pixel-hub';
import {
  ResourceDataValue,
  CompanyMappingMessageValue,
  ChargeMappingMessageValue,
  ShipmentEstimatesFieldValue,
} from 'operations/modules/message/messageResourceDataModel';
import {
  FreightType,
  ROW_GUTTER,
  SHIPMENT_TYPE_CONSOL,
} from 'operations/modules/shipment/constants';
import ShipmentMessageOverview from './ShipmentMessageOverview';
import MessageShipmentPartyMapping from './MessageShipmentPartyMapping';
import MessageShipmentChargeMapping from './MessageShipmentChargeMapping';
import MessageShipmentDocumentDetails from './MessageShipmentDocumentDetails';
import ShipmentServiceDetails, {
  ShipmentServiceRenderType,
  default_services,
  freightForwardingService,
} from 'operations/modules/shipment/components/ShipmentServiceDetails';
import CreateShipmentFooter from './CreateShipmentFooter';
import { CreateShipmentType } from 'operations/modules/reports/components/ContainerReports/ContainerActions/ContainerActionDrawer';
import { useSession } from 'common';
import { SessionDataValue } from 'operations/models/SessionData';
import { getShipmentIntialValue, CreateShipmentFromDataValue } from '../helpers/messageHelper';
import { ShipmentPartyValue } from 'operations/models/ShipmentParty';
import { AttachmentValue, MessageValue } from 'operations/models/Message';
import { getSalesPersonForCustomer } from 'operations/helpers/fetchSalesPerson';
import MessageShipmentUploadedDocumentList from './MessageShipmentUploadedDocumentList';
import { CompanyValue } from 'operations/models/Company';
import { ShipmentPartyFieldValue } from 'operations/modules/message/messageResourceDataModel';
import { ApolloClient, ApolloError, useApolloClient } from '@apollo/client';
import MessageShipmentFreeDaysAndLfd from './MessageShipmentFreeDaysAndLfd';
import { SHIPMENT_TYPE_HOUSE } from 'operations/modules/shipment/constants';

export const setSalesPerson = async (
  form: FormInstance,
  changedValues: CreateShipmentFromDataValue,
  allValues: CreateShipmentFromDataValue,
  sessionData: SessionDataValue,
  masterShipmentInitialValue?: CreateShipmentFromDataValue,
  client?: ApolloClient<object>
) => {
  if ((changedValues?.customer || changedValues?.involved_branch_id) && allValues?.customer?.id) {
    const branch_id =
      allValues?.involved_branch_id || masterShipmentInitialValue?.involved_branch_id;
    if (!branch_id) return;
    const { response } = await getSalesPersonForCustomer(
      allValues?.customer?.id,
      branch_id,
      sessionData,
      client
    );
    if (response) {
      form.setFieldsValue({
        sales_agent: response,
      });
    }
  }
};

const CreateShipmentFromMessage = React.memo(function CreateShipmentFromMessage(props: {
  shipment: ResourceDataValue;
  onFormSubmit: (values: CreateShipmentFromDataValue, throw_error_on_credit_fail?: boolean) => void;
  shipmentInitialValue?: CreateShipmentFromDataValue;
  masterShipmentInitialValue?: CreateShipmentFromDataValue;
  companyMappingFormValues?: { [key: string]: ShipmentPartyValue };
  onNext?: (values: CreateShipmentFromDataValue) => void;
  onClose?: () => void;
  onBack?: () => void;
  onDiscard?: () => void;
  loading?: boolean;
  companyMapping?: CompanyMappingMessageValue[];
  tenantMapping?: ChargeMappingMessageValue[];
  senderCompany?: CompanyValue | null;
  activeShipmentIndex: number;
  lastShipmentIndex: number;
  attachments?: AttachmentValue[];
  message?: MessageValue;
  error?: ApolloError | undefined;
  showCreditPopupWithAction?: (payload: any) => void;
}) {
  const {
    shipment,
    onClose,
    onFormSubmit,
    loading,
    onBack,
    onDiscard,
    shipmentInitialValue,
    masterShipmentInitialValue,
    companyMappingFormValues,
    companyMapping,
    senderCompany,
    activeShipmentIndex,
    lastShipmentIndex,
    attachments,
    tenantMapping,
    message,
    error,
    showCreditPopupWithAction,
  } = props;

  const client = useApolloClient();
  const [form] = Form.useForm();
  useEffect(() => {
    if (error) {
      const graphQLErrors = error instanceof ApolloError ? error.graphQLErrors : error;
      const data: any = getJsonFromError(graphQLErrors);
      if (data && showCreditPopupWithAction) {
        const values: any = form.getFieldsValue();
        showCreditPopupWithAction({
          data: data,
          onSucess: () => {
            onFormSubmit(values, false);
          },
        });
      }
    }
  }, [error, form, onFormSubmit, showCreditPopupWithAction]);
  const uniqueParties = new Set<string>();
  const getUniqueShipmentParties = (
    shipmentParties: ShipmentPartyFieldValue[]
  ): ShipmentPartyFieldValue[] => {
    return (shipmentParties || []).filter((p: ShipmentPartyFieldValue) => {
      const address_id = p?.party_address_id?.value;
      const cond = address_id && !uniqueParties.has(address_id);
      if (cond && address_id) {
        uniqueParties.add(address_id);
        return true;
      }
      return false;
    });
  };
  const get_finance_pary_search_prop = (
    shipment_estimates: [ShipmentEstimatesFieldValue] | [] | undefined
  ) => {
    let is_customer: any = undefined;
    let is_vendor: any = undefined;
    (shipment_estimates || []).forEach((estimate: ShipmentEstimatesFieldValue) => {
      if (estimate?.buy_rate?.value) is_vendor = true;
      if (estimate?.sell_rate?.value) is_customer = true;
    });
    return { is_customer, is_vendor };
  };

  const { is_customer, is_vendor } = get_finance_pary_search_prop(shipment?.shipment_estimates);
  const uniqueShipmentParties = getUniqueShipmentParties(shipment.shipment_parties);
  const sessionData = useSession();
  const shipment_documents_ref = useRef<{ runValidation: () => boolean }>();
  const freightType = shipment.freight_type.value;
  const shipmentType = shipment.shipment_type.value;
  const tradeType = shipment.trade_type.value;
  if (!shipmentType || !freightType) return <span />;
  const shipmentServicesList: ShipmentServiceRenderType[] =
    shipmentType === SHIPMENT_TYPE_CONSOL ? freightForwardingService : default_services;
  return (
    <Form
      form={form}
      name="create_master_shipment"
      layout="vertical"
      initialValues={getShipmentIntialValue(
        shipment,
        sessionData,
        senderCompany,
        shipmentInitialValue,
        masterShipmentInitialValue,
        companyMapping,
        tenantMapping,
        companyMappingFormValues,
        attachments
      )}
      onFinish={(values) => {
        values['finance_party'] = values?.party_type?.id;
        const foundError = Boolean(shipment_documents_ref?.current?.runValidation());
        if (!foundError) {
          onFormSubmit(values);
        }
      }}
      onValuesChange={(changedValues, allValues) => {
        setSalesPerson(
          form,
          changedValues,
          allValues,
          sessionData,
          masterShipmentInitialValue,
          client
        );
      }}
    >
      <Row gutter={ROW_GUTTER}>
        <Col span={16}>
          <Form.Item noStyle name="id">
            <span />
          </Form.Item>
          <Form.Item noStyle name="cargos">
            <span />
          </Form.Item>
          <Card title="Basic Details" className="custom-card">
            <MessageShipmentBasicDetails
              shipmentType={shipmentType}
              shipment={shipment}
              consolType={masterShipmentInitialValue?.consol_type}
              form={form}
            />
          </Card>
          {shipment.shipment_parties && shipment.shipment_parties.length && (
            <Card title="Party Mapping" className="custom-card" style={{ marginTop: '15px' }}>
              <MessageShipmentPartyMapping shipmentParties={uniqueShipmentParties} />
            </Card>
          )}
          {shipment.shipment_estimates && shipment.shipment_estimates.length > 0 && (
            <Card title="Charge Mapping" className="custom-card" style={{ marginTop: '15px' }}>
              <MessageShipmentChargeMapping
                freightType={freightType}
                tradeType={tradeType}
                shipmentEstimates={shipment.shipment_estimates}
                activeShipmentIndex={activeShipmentIndex}
                is_customer={is_customer}
                is_vendor={is_vendor}
              />
            </Card>
          )}
          {shipment.shipment_documents && shipment.shipment_documents.length && (
            <Card
              title="Shipment Document Details"
              className="custom-card"
              style={{ marginTop: '15px' }}
            >
              <MessageShipmentDocumentDetails
                shipmentDocuments={shipment.shipment_documents}
                freightType={freightType}
                ref={shipment_documents_ref}
                form={form}
              />
            </Card>
          )}
          {attachments && shipment.id?.value && (
            <Card
              title="Uploaded Documents Details"
              className="custom-card"
              style={{ marginTop: '15px' }}
            >
              <Form.Item noStyle name="selected_attachment_ids">
                <MessageShipmentUploadedDocumentList
                  attachments={attachments}
                  shipmentId={shipment.id?.value}
                  shipmentType={shipmentType}
                />
              </Form.Item>
            </Card>
          )}
          <Card
            title="Shipment Service Details"
            className="custom-card"
            style={{ marginTop: '15px' }}
          >
            <ShipmentServiceDetails
              shipmentType={shipmentType as CreateShipmentType}
              services={shipmentServicesList}
              freightType={freightType as FreightType}
              hideIsfFiledBy={true}
            />
          </Card>
          {shipmentType !== SHIPMENT_TYPE_HOUSE && (
            <Card
              title="Cutoffs and Free Days"
              className="custom-card"
              style={{ marginTop: '15px' }}
            >
              <MessageShipmentFreeDaysAndLfd
                shipmentType={shipmentType}
                shipment={shipment}
              ></MessageShipmentFreeDaysAndLfd>
            </Card>
          )}
        </Col>
        <Col span={8}>
          <ShipmentMessageOverview shipment={shipment} message={message} />
        </Col>
        <CreateShipmentFooter
          showDiscardAction
          loading={loading}
          activeShipmentIndex={activeShipmentIndex}
          lastShipmentIndex={lastShipmentIndex}
          onDiscard={onDiscard}
          onClose={onClose}
          onBack={onBack}
        />
      </Row>
    </Form>
  );
});

export default CreateShipmentFromMessage;
