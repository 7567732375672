import React, {
  MutableRefObject,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { GridOptions, RowNode } from '@ag-grid-community/core';
import { Action, PerformAction } from 'operations/modules/actionHelper/models';
import {
  CheckCircleFilled,
  CloseOutlined,
  CustomIcon,
  ViewTableContext,
} from '@shipmnts/pixel-hub';
import { getShipmentBulkActions } from 'operations/modules/bulkActionHelper/ShipmentBulkActions/shipmentBulkActionHelper';
import { getContainerBulkActions } from 'operations/modules/bulkActionHelper/ContainerBulkActions/containerBulkActionHelper';
import { getShipmentCustomDetailBulkActions } from 'operations/modules/bulkActionHelper/ShipmentCustomDetailActions/shipmentCustomDetailActionHelper';
import { DOC_TYPE_METHODS } from 'common/baseConstants';
import {
  NEGATIVE_TYPE,
  PRIMARY_TYPE,
  SECONDARY_TYPE,
  TERTIARY_TYPE,
} from 'operations/modules/actionHelper/constants';
import { groupBy, uniq } from 'lodash';
import { Button, Divider, Drawer, Dropdown } from '@shipmnts/pixel-hub';
import { getCargoBulkActions } from 'operations/modules/actionHelper/CargoActions/cargoActionHelper';
import { useLazyQuery } from '@apollo/client';
import { FETCH_CONTAINERS_FOR_EDIT } from 'operations/modules/booking/graphql/shipmentContainer';
import _isEqual from 'lodash/isEqual';
import { FETCH_SHIPMENT_CUSTOM_DETAILS_BY_IDS } from 'operations/graphql/shipmentCustomDetail';

import { getEstimateBulkActions } from 'operations/modules/bulkActionHelper/EstimateBulkActions/estimateBulkActionHelper';
import { useErpNextConfig } from 'network';
import getUserContactBulkActions from 'operations/modules/bulkActionHelper/UserContactBulkActions/UserContactBulkActionsHelper';
import getTemplateBulkActions from 'operations/modules/bulkActionHelper/TemplateBulkActions/TemplateBulkActionsHelper';
import getSalesPersonBulkActions from 'operations/modules/bulkActionHelper/SalesPersonBulkActions/SalesPersonBulkActionsHelper';
import getCompanyBulkActions from 'operations/modules/bulkActionHelper/CompanyBulkAction/CompanyBulkActionHelper';
import getTicketBulkActions from 'operations/modules/bulkActionHelper/TicketBulkActions/TicketBulkActionsHelper';
import getInquiryBulkAction from 'operations/modules/bulkActionHelper/InquiryBulkAction/InquiryBulkActionHelper';
import { useSession } from 'common';
interface ExtendedRefOptions extends GridOptions {
  isAction?: boolean;
}

interface BulkActionDrawerProps {
  isBulkActionVisible: boolean;
  setIsBulkActionVisible: (isVisible: boolean) => void;
  gridRef: MutableRefObject<ExtendedRefOptions | undefined>;
  doc_type_id: string[];
  primary_doc_type_id: string;
  selectedNodeCounts: number;
  refetch?: () => void;
  selectedNodes: {
    [docType: string]: RowNode[];
  };
}
// helper functions

const getDocTypesFromData = (selectedNodes: any[], doc_type_id: string): string[] => {
  const selectedNodeData = selectedNodes[0]?.data;
  if (!selectedNodeData) return [];
  const decodeBase64 = (base64String: string) => {
    if (!base64String) return '';
    try {
      const decoded = atob(base64String);
      return decoded;
    } catch (error) {
      console.error('Error decoding base64:', error);
      return null;
    }
  };
  const docTypes: string[] = [];

  if (selectedNodeData) {
    Object.entries(selectedNodeData).forEach(([key, value]) => {
      if (typeof key === 'string') {
        const decodedValue = decodeBase64(key);
        if (
          decodedValue &&
          DOC_TYPE_METHODS[decodedValue] &&
          DOC_TYPE_METHODS[decodedValue].has_bulk_actions
        ) {
          docTypes.push(decodedValue);
        }
      }
    });
  }
  docTypes.push(doc_type_id);
  return docTypes;
};

// function to prepare actions in case of multiple doc_types in a view
const prepareAction = (doc_type_id: string, selectedNodes: RowNode[]) => {
  const actionMap: { [key: string]: Record<string, any> } = {
    'Shipment::Shipment': {
      key: 'Shipment Actions',
      type: PRIMARY_TYPE,
      displayComponent: 'Shipment Actions',
      icon: <CustomIcon icon={'ShipIconNew'} width={15} height={15} />,
      description: 'Shipment Actions',
      childComponents: getShipmentBulkActions(selectedNodes),
      doc_type_id: 'Shipment::Shipment',
    },
    'Shipment::ShipmentContainer': {
      key: 'Shipment Container Actions',
      type: PRIMARY_TYPE,
      displayComponent: 'Shipment Container Actions',
      icon: <CustomIcon icon={'ContainerIcon'} width={15} height={15} />,
      description: 'Container Actions',
      childComponents: getContainerBulkActions(selectedNodes),
      doc_type_id: 'Shipment::ShipmentContainer',
    },
    'Shipment::ShipmentContainerDuplicate': {
      key: 'Shipment Container Actions',
      type: PRIMARY_TYPE,
      displayComponent: 'Shipment Container Actions',
      icon: <CustomIcon icon={'ContainerIcon'} width={15} height={15} />,
      description: 'Container Actions',
      childComponents: getContainerBulkActions(selectedNodes),
      doc_type_id: 'Shipment::ShipmentContainer',
    },
    'Shipment::ShipmentCustomDetail': {
      key: 'Shipment Custom Detail Actions',
      type: PRIMARY_TYPE,
      displayComponent: 'Shipment Custom Detail Action',
      icon: <CustomIcon icon={'ContainerIcon'} width={15} height={15} />,
      description: 'Shipment Custom Detail Actions',
      childComponents: getShipmentCustomDetailBulkActions(selectedNodes),
      doc_type_id: 'Shipment::ShipmentCustomDetail',
    },
    'Shipment::Cargo': {
      key: 'Shipment Cargo Actions',
      type: PRIMARY_TYPE,
      displayComponent: 'Shipment Cargo Action',
      icon: <CustomIcon icon={'ContainerIcon'} width={15} height={15} />,
      description: 'Shipment Cargo Actions',
      childComponents: getCargoBulkActions(selectedNodes),
      doc_type_id: 'Shipment::Cargo',
    },
  };
  if (actionMap[doc_type_id]) {
    const actionData = actionMap[doc_type_id];

    if (actionData.key) {
      return {
        key: actionData.key,
        type: actionData.type,
        displayComponent: actionData.displayComponent,
        icon: actionData.icon,
        description: actionData.description,
        isEnable: true,
        childComponents: actionData.childComponents,
        doc_type_id: actionData.doc_type_id,
      };
    } else return {};
  } else return {};
};

const BulkAction = React.memo(function BulkAction(prop: BulkActionDrawerProps) {
  const {
    isBulkActionVisible,
    setIsBulkActionVisible,
    gridRef,
    doc_type_id,
    selectedNodeCounts,
    refetch,
    selectedNodes,
    primary_doc_type_id,
  } = prop;
  const [actionParams, setActionParams] = useState<any>({});
  const [currentId, setCurrentId] = useState<string>('');
  const [menuItems, setMenuItem] = useState<Action[]>([]);
  const [TaskComponent, setTaskComponent] = useState<React.JSXElementConstructor<any>>();
  const [ChildTaskComponent, setChildTaskComponent] = useState<React.JSXElementConstructor<any>>();
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { refreshReportRows } = useContext(ViewTableContext);
  const erpnextContext = useErpNextConfig();
  const { erpnextConfigData } = erpnextContext;
  const isSpEnabled =
    erpnextConfigData?.create_interbranch_invoices_automatically ===
    'Interbranch sales invoice creation (SP)';
  const session = useSession();
  const { permissions } = session;
  const onCloseBulkActionDrawer = () => {
    if (gridRef?.current) {
      gridRef.current.isAction = false;
    }
    setIsBulkActionVisible(false);
    gridRef?.current?.api?.deselectAll();
    setTaskComponent(undefined);
    setChildTaskComponent(undefined);
    setCurrentId('');
    setMenuItem([]);
  };
  const deselectRows = useCallback(() => {
    gridRef?.current?.api?.deselectAll();
  }, [gridRef]);
  const onSuccess = () => {
    if (gridRef.current) {
      gridRef.current.isAction = true;
    }
    const ids = uniq(selectedNodes[primary_doc_type_id].map((node: any) => node.data.id));
    deselectRows();
    setTaskComponent(undefined);
    setChildTaskComponent(undefined);
    setCurrentId('');
    setActionParams({});
    setIsBulkActionVisible(false);
    if (refreshReportRows) refreshReportRows(ids);
    if (refetch) refetch();
  };
  const onClose = () => {
    if (gridRef?.current) {
      gridRef.current.isAction = false;
    }
    setTaskComponent(undefined);
    setChildTaskComponent(undefined);
    setCurrentId('');
    setActionParams({});
  };
  useEffect(() => {
    if (selectedNodeCounts > 0) {
      if (doc_type_id.length === 1) {
        if (doc_type_id[0] === 'Shipment::Shipment') {
          setMenuItem(getShipmentBulkActions(selectedNodes[doc_type_id[0]]));
        } else if (doc_type_id[0] === 'Shipment::ShipmentContainer') {
          setMenuItem(getContainerBulkActions(selectedNodes[doc_type_id[0]]));
        } else if (doc_type_id[0] === 'Shipment::ShipmentCustomDetail') {
          setMenuItem(getShipmentCustomDetailBulkActions(selectedNodes[doc_type_id[0]]));
        } else if (doc_type_id[0] === 'Shipment::Cargo') {
          setMenuItem(getCargoBulkActions(selectedNodes[doc_type_id[0]]));
        } else if (doc_type_id[0] === 'Network::UserContact') {
          setMenuItem(getUserContactBulkActions(selectedNodes[doc_type_id[0]]));
        } else if (doc_type_id[0] === 'Network::Template') {
          setMenuItem(getTemplateBulkActions(selectedNodes[doc_type_id[0]]));
        } else if (doc_type_id[0] === 'Network::SalesPerson') {
          setMenuItem(getSalesPersonBulkActions(selectedNodes[doc_type_id[0]], permissions));
        } else if (doc_type_id[0] === 'Network::Company') {
          setMenuItem(getCompanyBulkActions(selectedNodes[doc_type_id[0]]));
        } else if (doc_type_id[0] === 'ServiceManagement::Ticket::Ticket') {
          setMenuItem(getTicketBulkActions(selectedNodes[doc_type_id[0]]));
        } else if (doc_type_id[0] === 'SalesHub::Inquiry') {
          setMenuItem(getInquiryBulkAction(selectedNodes[doc_type_id[0]], session));
        } else if (doc_type_id[0] === 'NewAccounting::ShipmentEstimate') {
          setMenuItem(getEstimateBulkActions(selectedNodes[doc_type_id[0]], isSpEnabled));
        }
      } else {
        const menuItems: any[] = [];
        doc_type_id.forEach((dt_id: string) => {
          let currDocType = dt_id;
          if (dt_id === 'Shipment::ShipmentContainerDuplicate')
            currDocType = 'Shipment::ShipmentContainer';
          menuItems.push(prepareAction(dt_id, selectedNodes[currDocType]));
        });
        setMenuItem(menuItems);
      }
    } else {
      onCloseBulkActionDrawer();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedNodeCounts]);
  const handleActions = async (action: Action) => {
    let result: PerformAction | undefined;
    if (
      action &&
      DOC_TYPE_METHODS[action?.doc_type_id || ''].has_bulk_actions &&
      selectedNodes[action?.doc_type_id || ''].length > 0 &&
      action.performAction
    ) {
      result = await action.performAction(
        selectedNodes[action?.doc_type_id || ''],
        onSuccess,
        selectedNodeCounts,
        action.extraProps,
        onClose
      );
    }
    const render_inside = action.extraProps?.render_inside || false;
    if (result && render_inside) {
      setActionParams(result?.actionParams);
      setChildTaskComponent(() => result?.component);
    } else if (result) {
      setActionParams(result?.actionParams);
      setTaskComponent(() => result?.component);
    }
  };
  const getRenderAction = useCallback(() => {
    const renderActions: Action[][] = [];
    const actionsWithType = groupBy(menuItems, 'type');
    const actionTypesArr = [PRIMARY_TYPE, SECONDARY_TYPE, TERTIARY_TYPE, NEGATIVE_TYPE];
    actionTypesArr.forEach((type: string) => {
      if (actionsWithType && actionsWithType[type]) {
        const currentAction = actionsWithType[type].filter((action) => action.isEnable);
        if (currentAction.length > 0) {
          renderActions.unshift(currentAction);
        }
      }
    });
    return renderActions;
  }, [menuItems]);
  const renderActionByMenuItems = () => {
    if (selectedNodes[primary_doc_type_id]?.length > 0) {
      return (
        <div style={{ display: 'flex', justifyContent: 'space-evenly', alignItems: 'center' }}>
          {(getRenderAction() || []).map((actions: Action[], actionsId: number) => {
            return actions.map((item, index) => {
              let description = item.displayComponent;
              if (typeof item.displayComponent === 'function') {
                const ComponentWrapper = item.displayComponent;
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                description = <ComponentWrapper key={item.key} {...item.componentProps} />;
              }
              if (!item.isEnable) {
                return null;
              }
              if (item.childComponents && item.childComponents.length === 0) return null;
              if (item.childComponents && item.childComponents.length > 0) {
                const childComponentsLength = item.childComponents.filter((c) => c.isEnable).length;
                return childComponentsLength > 0 ? (
                  <React.Fragment key={item.key}>
                    <Dropdown
                      placement="bottom"
                      menu={{
                        style: { minWidth: '200px' },
                        className: 'actions-menu',
                        items: item.childComponents.map((child) =>
                          child.isEnable
                            ? {
                                key: child.key,
                                onClick: () => {
                                  handleActions(child);
                                  setCurrentId(item.key);
                                  if (child.onClick) {
                                    child.onClick();
                                  }
                                },
                                label: (
                                  <div className={item.type === NEGATIVE_TYPE ? 'color-red' : ''}>
                                    <span className="action-icon">{child.icon}</span>
                                    {child.displayComponent}
                                  </div>
                                ),
                              }
                            : null
                        ),
                      }}
                      trigger={['click']}
                    >
                      <Button
                        className={item.type === NEGATIVE_TYPE ? 'color-red' : ''}
                        onClick={() => {
                          handleActions(item);
                          setCurrentId(item.key);
                          if (item.onClick) {
                            item.onClick();
                          }
                        }}
                        key={item.key}
                        type="link"
                        style={{
                          color: '#FFFFFF',
                          display: 'flex',
                          justifyContent: 'space-evenly',
                          alignItems: 'center',
                          flexDirection: 'column',
                        }}
                      >
                        <span className="action-icon">{item.icon}</span>
                        {description}
                      </Button>
                    </Dropdown>
                    {currentId === item.key && ChildTaskComponent && (
                      <ChildTaskComponent
                        {...actionParams}
                        visible={true}
                        onClose={onClose}
                        onSuccess={onSuccess}
                      />
                    )}
                  </React.Fragment>
                ) : (
                  <></>
                );
              }
              return (
                <React.Fragment key={item.key}>
                  <Button
                    className={item.type === NEGATIVE_TYPE ? 'color-red' : ''}
                    onClick={() => {
                      handleActions(item);
                      setCurrentId(item.key);
                      if (item.onClick) {
                        item.onClick();
                      }
                    }}
                    key={item.key}
                    type="link"
                    style={{
                      color: '#FFFFFF',
                      display: 'flex',
                      justifyContent: 'space-evenly',
                      alignItems: 'center',
                      flexDirection: 'column',
                    }}
                  >
                    <span className="action-icon">{item.icon}</span>
                    {description}
                  </Button>
                  {index === actions.length - 1 &&
                  actionsId !== (getRenderAction() || []).length - 1 ? (
                    <Divider type="vertical" style={{ color: '#FFFFFF' }} />
                  ) : (
                    <></>
                  )}
                </React.Fragment>
              );
            });
          })}
        </div>
      );
    } else {
      return <></>;
    }
  };
  if (doc_type_id.length === 0) {
    return <></>;
  }
  return (
    <>
      <Drawer
        placement="top"
        onClose={onCloseBulkActionDrawer}
        open={isBulkActionVisible}
        height="4rem"
        mask={false}
        maskClosable={false}
        styles={{ body: { background: 'var(--bg-primary-black-1)', padding: '0px 12px' } }}
        closable={false}
        title={null}
      >
        <div
          style={{
            justifyContent: 'space-between',
            alignItems: 'center',
            display: 'flex',
            height: '100%',
          }}
        >
          <div style={{ color: '#FFFFFF' }}>
            <CheckCircleFilled />
            <span style={{ marginLeft: '3px' }}>{selectedNodeCounts} Selected</span>
          </div>
          <div style={{ textAlign: 'center', display: 'inline-block' }}>
            {(selectedNodeCounts && renderActionByMenuItems()) || <></>}
          </div>
          <div>
            <Button
              style={{ background: '#FFFFFF', color: 'var(--color-primary)' }}
              type="default"
              key="dismiss"
              shape="circle"
              icon={<CloseOutlined />}
              size="small"
              onClick={() => onCloseBulkActionDrawer()}
            />
          </div>
        </div>
      </Drawer>
      {TaskComponent && (
        <TaskComponent {...actionParams} visible={true} onClose={onClose} onSuccess={onSuccess} />
      )}
    </>
  );
});
const BulkActionDrawer = (props: any) => {
  const { gridRef, doc_type_id, selectedNodeCounts } = props;

  // Memoizing selected nodes to prevent unnecessary re-renders.
  const selectedNodes = (gridRef?.current?.api?.getSelectedNodes() || []).filter(
    (node: any) => !node.group
  );

  const allDocTypeIds = useMemo(
    () => getDocTypesFromData(selectedNodes, doc_type_id),
    [selectedNodes, doc_type_id]
  );

  const [allSelectedNodesData, setAllSelectedNodesData] = useState<{ [key: string]: RowNode[] }>();
  const previousSelectedNodesRef = useRef(selectedNodes);

  const [fetchContainers] = useLazyQuery(FETCH_CONTAINERS_FOR_EDIT, {
    fetchPolicy: 'no-cache',
  });

  const [fetchCustomDetails] = useLazyQuery(FETCH_SHIPMENT_CUSTOM_DETAILS_BY_IDS, {
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    if (_isEqual(selectedNodes, previousSelectedNodesRef.current)) return;

    const fetchExtraData = async () => {
      const containerIds: any[] = [];
      const customDetailIds: any[] = [];

      selectedNodes.forEach((node: any) => {
        if (node?.data) {
          Object.entries(node.data).forEach(([key, value]) => {
            try {
              const decodedKey = atob(key);
              if (
                ['Shipment::ShipmentContainerDuplicate', 'Shipment::ShipmentContainer'].includes(
                  decodedKey
                ) &&
                value
              ) {
                containerIds.push(value);
              }
              if (['Shipment::ShipmentCustomDetail'].includes(decodedKey) && value) {
                customDetailIds.push(value);
              }
            } catch (error) {
              console.error('Error decoding key:', error);
            }
          });
        }
      });

      const newData: { [key: string]: RowNode[] } = {};
      newData[doc_type_id] = selectedNodes;

      if (containerIds.length > 0) {
        try {
          const result = await fetchContainers({ variables: { ids: containerIds } });
          const containerData = selectedNodes.map((selectedNode: any, index: number) => {
            const container = result?.data?.fetch_basic_containers_details_by_ids[index];
            if (container) {
              return { ...selectedNode, data: { ...container } };
            }
            return selectedNode;
          });

          newData['Shipment::ShipmentContainer'] = containerData;
        } catch (error) {
          console.error('Error fetching containers:', error);
        }
      }
      if (customDetailIds.length > 0) {
        try {
          const result = await fetchCustomDetails({ variables: { ids: customDetailIds } });
          const customData = selectedNodes.map((selectedNode: any, index: number) => {
            const customDetail = result?.data?.fetch_shipment_custom_details_by_ids[index];
            if (customDetail) {
              return { ...selectedNode, data: { ...customDetail } };
            }
            return selectedNode;
          });

          newData['Shipment::ShipmentCustomDetail'] = customData;
        } catch (error) {
          console.error('Error fetching containers:', error);
        }
      }

      if (!_isEqual(newData, allSelectedNodesData)) {
        setAllSelectedNodesData(newData);
      }
    };

    if (selectedNodes.length > 0 && !_isEqual(selectedNodes, previousSelectedNodesRef.current)) {
      fetchExtraData();
      previousSelectedNodesRef.current = selectedNodes;
    }
  }, [selectedNodes, doc_type_id, fetchContainers, allSelectedNodesData, fetchCustomDetails]);

  if (selectedNodeCounts === 0 || !allSelectedNodesData) return null; // Avoid unnecessary rendering

  return (
    <BulkAction
      {...props}
      selectedNodes={allSelectedNodesData}
      doc_type_id={allDocTypeIds}
      primary_doc_type_id={doc_type_id}
    />
  );
};
export default BulkActionDrawer;
