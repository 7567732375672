import React, { useState, useEffect, useCallback, forwardRef, useImperativeHandle } from 'react';
import {
  Timeline,
  Row,
  Col,
  Input,
  Button,
  Select,
  Typography,
  Modal,
  Form,
  Radio,
  Space,
  Checkbox,
  GlobalSearch,
} from '@shipmnts/pixel-hub';
import {
  DatePicker,
  AddressCompanySearch,
  VesselSearch,
  PlusOutlined,
  DeleteOutlined,
} from '@shipmnts/pixel-hub';
import { uniqueId as _uniqueId } from 'lodash';
import { RoutingNodeValue, RoutingNodeTag } from 'operations/models/RoutingNode';
import VoyageSchedule, {
  VoyageScheduleInitialValue,
  VoyageScheduleValue,
} from 'operations/models/VoyageSchedule';
import { LocationType, LocationValue } from 'operations/models/Location';
import _pick from 'lodash/pick';
import { omit as _omit } from 'lodash';
import {
  RoutingLegRoutingType,
  ROUTING_TYPE_MAIN_CARRIAGE,
  RoutingLegValue,
  MODE_OF_TRANSIT_RAIL,
  ROUTING_TYPE_PICKUP_DELIVERY,
  ROUTING_TYPE_DELIVERY,
  ROUTING_TYPE_ON_CARRIAGE,
  ROUTING_TYPE_PRE_CARRIAGE,
} from 'operations/models/RoutingLeg';
import _mapValues from 'lodash/mapValues';
import VoyageNumberInput from 'operations/modules/booking/components/BookingSchedules/VoyageNumberInput';
import {
  LOCATION_TYPE_ADDRESS,
  LOCATION_TYPE_PORT_CFS_ICD,
  BOOKING_TYPE_SELF,
} from 'operations/baseConstants';
import { VesselValue } from 'operations/models/Vessel';
import { convertToDayJs } from '@shipmnts/pixel-hub';
import { LOCATION_TYPES } from 'operations/modules/shipment/components/ShipmentForm/constants';
import { RadioOptionProps } from 'operations/commonTypeDefs';
import { FreightType } from 'operations/modules/shipment/constants';
import { ROUTING_NODE_LABEL_DESTINATION, ROUTING_NODE_LABEL_ORIGIN } from 'operations/constants';
import {
  useFormResponsive,
  VIEW_TYPE_SM,
} from 'operations/modules/shipment/components/NewShipmentForm/FormResponsiveContext';
import GenAIInputWrapper from 'operations/modules/shipment/components/NewShipmentForm/FormFiller/GenAIInputWrapper';

const { Text } = Typography;
const { Option } = Select;

// ToDo import this from common/model/Routing node
export interface RoutingNodesHashValue {
  [id: string]: RoutingNodeValue;
}

export interface RoutingDetailsValue {
  routing_legs: Array<RoutingLegValue>;
  routing_nodes: RoutingNodesHashValue;
}

export interface RoutingDetailsProps {
  value?: RoutingDetailsValue;
  onChange?: (value: RoutingDetailsValue) => void;
  locationSearchType?: Array<LocationType>;
  showTerminal?: boolean;
  disableNodes?: number[];
  disableVoyageUpdate?: boolean;
  disableRoutingLegs?: number[];
  disableAddRemoveTranshipmentHop?: boolean;
  routing_type?: RoutingLegRoutingType;
  startSequence: number;
  endSequence: number;
  validateVesselVoyage?: boolean;
  globalCarrierId?: string;
  isReeferContainer?: boolean;
  bookingType?: string | null;
  allowVoyageScheduleSearch?: boolean;
  freightType?: FreightType;
  showDpd?: boolean;
}

export const getNewRoutingNode = (params?: Partial<RoutingNodeValue>): RoutingNodeValue => {
  return { _id: _uniqueId('rn_'), ...(params || {}) } as RoutingNodeValue;
};

declare type RoutingLegCallback = (
  key: string,
  value: RoutingLegValue[keyof RoutingLegValue],
  route_order: number
) => void;
declare type RoutingNodeCallback = (
  key: string,
  value: RoutingNodeValue[keyof RoutingNodeValue],
  id: string
) => void;

declare type RoutingNodeKeys = keyof RoutingNodeValue;
const GreyIcon = () => (
  <svg width="16" height="16" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="12" height="12" fill="#CCCCCC" rx="4" />
  </svg>
);
const NodeAndLegsInfo = React.memo(function NodeAndLegsInfo(props: {
  node: RoutingNodeValue;
  incoming_edge?: RoutingLegValue;
  outgoing_edge?: RoutingLegValue;
  onRoutingLegFieldChange: RoutingLegCallback;
  onRoutingNodeFieldChange: RoutingNodeCallback;
  index: number;
  showTerminal?: boolean;
  disableNode?: boolean;
  disabled?: boolean;
  locationSearchType?: Array<LocationType>;
  errorsPresent?: boolean;
  validateVesselVoyage?: boolean;
  routing_type?: string;
  disableVoyageUpdate?: boolean;
  showDpd?: boolean;
}): JSX.Element {
  const {
    node,
    incoming_edge,
    outgoing_edge,
    onRoutingLegFieldChange,
    onRoutingNodeFieldChange,
    index,
    showTerminal,
    disableNode,
    disabled,
    locationSearchType,
    errorsPresent,
    validateVesselVoyage,
    routing_type,
    disableVoyageUpdate,
    showDpd,
  } = props;
  const isFirstMainCarriageHop =
    outgoing_edge?.routing_type === ROUTING_TYPE_MAIN_CARRIAGE && index === 0;
  const showEta = Boolean(incoming_edge);
  const showEtd = Boolean(outgoing_edge);

  const { viewType } = useFormResponsive();

  const nodeLabel =
    routing_type === ROUTING_TYPE_PICKUP_DELIVERY || routing_type === ROUTING_TYPE_DELIVERY
      ? 'Location'
      : routing_type === ROUTING_TYPE_MAIN_CARRIAGE && outgoing_edge && !incoming_edge
      ? 'Port Of Loading'
      : routing_type === ROUTING_TYPE_MAIN_CARRIAGE && !outgoing_edge && incoming_edge
      ? 'Port Of Discharge'
      : routing_type === ROUTING_TYPE_PRE_CARRIAGE && outgoing_edge && !incoming_edge
      ? 'Origin ICD / Port'
      : routing_type === ROUTING_TYPE_PRE_CARRIAGE && !outgoing_edge && incoming_edge
      ? 'Port of Loading'
      : routing_type === ROUTING_TYPE_ON_CARRIAGE && !outgoing_edge && incoming_edge
      ? 'Destination ICD / Port'
      : routing_type === ROUTING_TYPE_ON_CARRIAGE && outgoing_edge && !incoming_edge
      ? 'Port of Discharge'
      : incoming_edge && !outgoing_edge
      ? ROUTING_NODE_LABEL_DESTINATION
      : outgoing_edge && !incoming_edge
      ? ROUTING_NODE_LABEL_ORIGIN
      : 'Transhipment Hop';
  const isEtdRequired = validateVesselVoyage && node?.tags?.includes('port_of_loading');
  // const location = node?.location;
  const [locationType, setLocationType] = useState(LOCATION_TYPE_PORT_CFS_ICD);
  const company = node?.company;
  useEffect(() => {
    if (!!company) {
      setLocationType(LOCATION_TYPE_ADDRESS);
    } else {
      setLocationType(LOCATION_TYPE_PORT_CFS_ICD);
    }
  }, [company]);
  useEffect(() => {
    setShowDpdCheckbox(showDpd);
  }, [showDpd]);
  const [showDpdCheckbox, setShowDpdCheckbox] = useState(showDpd);
  const cols = [];
  (routing_type === ROUTING_TYPE_PICKUP_DELIVERY || routing_type === ROUTING_TYPE_DELIVERY) &&
    cols.push(
      <>
        <Form.Item
          required
          validateStatus={errorsPresent && !node?.location ? 'error' : 'success'}
          label={'Location Type'}
        >
          <Radio.Group
            value={locationType}
            onChange={(e) => {
              setLocationType(e.target.value);
              onRoutingNodeFieldChange(
                'location_type',
                e.target.value,
                node?.id || node?._id || ''
              );
            }}
          >
            <Space direction="vertical">
              {LOCATION_TYPES.map((option: RadioOptionProps, index: number) => (
                <Radio key={index} value={option.key}>
                  {option.name}
                </Radio>
              ))}
            </Space>
          </Radio.Group>
        </Form.Item>
      </>
    );
  locationType === LOCATION_TYPE_PORT_CFS_ICD
    ? cols.push(
        <>
          <Form.Item dependencies={['voyage_number, voyage_schedule_id']}>
            {(form) => {
              const { setFieldsValue } = form;
              return (
                <>
                  <Form.Item
                    required
                    rules={[{ required: true }]}
                    validateStatus={errorsPresent && !node?.location ? 'error' : 'success'}
                    label={nodeLabel}
                  >
                    <GenAIInputWrapper
                      fieldKey={['location', 'name']}
                      value={node?.location}
                      arrayValueConfig={{
                        path_to_array: ['routing_nodes'],
                        findConfig: {
                          key: '_id',
                          value: node?._id,
                        },
                      }}
                    >
                      <GlobalSearch
                        doc_type="Global::Location"
                        value={node?.location}
                        onChange={(value: any) => {
                          const isPOLChanged =
                            nodeLabel === 'Port Of Loading' &&
                            routing_type === ROUTING_TYPE_MAIN_CARRIAGE;
                          if (isPOLChanged) {
                            setFieldsValue({
                              voyage_schedule_id: null,
                              voyage_number: null,
                            });
                            onRoutingLegFieldChange('voyage_number', null, index);
                          }
                          onRoutingNodeFieldChange('location', value, node?.id || node?._id || '');
                        }}
                        disabled={disableNode || disabled}
                        searchProps={{ type: locationSearchType }}
                      />
                    </GenAIInputWrapper>
                  </Form.Item>
                  {nodeLabel === ROUTING_NODE_LABEL_DESTINATION && showDpdCheckbox && (
                    <Form.Item name={'direct_port_delivery'} valuePropName="checked">
                      <Checkbox name="direct_port_delivery" disabled={false}>
                        {'Direct Port Delivery'}
                      </Checkbox>
                    </Form.Item>
                  )}
                  {errorsPresent && !node?.location ? (
                    <Text type="danger">Required</Text>
                  ) : (
                    <span />
                  )}
                </>
              );
            }}
          </Form.Item>
        </>
      )
    : cols.push(
        <>
          <Form.Item
            required
            validateStatus={errorsPresent && !node?.location ? 'error' : 'success'}
            label={'Address'}
          >
            <AddressCompanySearch
              value={{
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                party_company: node?.company || undefined,
                party_address: node?.address,
              }}
              onChange={(value: any) => {
                onRoutingNodeFieldChange('company_address', value, node?.id || node?._id || '');
              }}
              // searchProps={{ type: locationSearchType }}
            />
            {errorsPresent && !node?.address ? <Text type="danger">Required</Text> : <span />}
          </Form.Item>
        </>
      );
  showTerminal &&
    locationType !== 'address' &&
    (!incoming_edge || !outgoing_edge) &&
    cols.push(
      <>
        <Form.Item label="Terminal">
          <GenAIInputWrapper
            fieldKey={['terminal', 'name']}
            value={node?.terminal}
            arrayValueConfig={{
              path_to_array: ['routing_nodes'],
              findConfig: {
                key: '_id',
                value: node?._id,
              },
            }}
          >
            <GlobalSearch
              doc_type="Global::Location"
              value={node?.terminal}
              searchProps={{ type: ['PortTerminal'] }}
              disabled={disableNode || disabled}
              onChange={(value: any) =>
                onRoutingNodeFieldChange('terminal', value, node?.id || node?._id || '')
              }
            />
          </GenAIInputWrapper>
        </Form.Item>
      </>
    );
  showEta &&
    cols.push(
      <>
        <Form.Item label="ETA">
          <GenAIInputWrapper
            fieldKey={['estimated_time_of_arrival']}
            arrayValueConfig={{
              path_to_array: ['routing_legs'],
              findConfig: {
                key: 'routing_type',
                value: routing_type,
              },
            }}
            value={incoming_edge?.estimated_time_of_arrival}
          >
            <DatePicker
              value={incoming_edge?.estimated_time_of_arrival}
              onChange={(value: any) =>
                onRoutingLegFieldChange(
                  'estimated_time_of_arrival',
                  value,
                  outgoing_edge ? index - 1 : index
                )
              }
              style={{ width: '100%' }}
              disabled={disabled || disableVoyageUpdate}
            />
          </GenAIInputWrapper>
        </Form.Item>
      </>
    );
  showEtd &&
    cols.push(
      <>
        <Form.Item
          label="ETD"
          required={isEtdRequired}
          validateStatus={
            errorsPresent && isEtdRequired && !outgoing_edge?.estimated_time_of_departure
              ? 'error'
              : 'success'
          }
          shouldUpdate={(prevValues, currentValues) =>
            prevValues.voyage_schedule_id !== currentValues.voyage_schedule_id
          }
        >
          {({ getFieldValue }) => {
            return (
              <React.Fragment>
                <GenAIInputWrapper
                  fieldKey={['estimated_time_of_departure']}
                  arrayValueConfig={{
                    path_to_array: ['routing_legs'],
                    findConfig: {
                      key: 'routing_type',
                      value: routing_type,
                    },
                  }}
                  value={outgoing_edge?.estimated_time_of_departure}
                >
                  <DatePicker
                    value={outgoing_edge?.estimated_time_of_departure}
                    onChange={(value: any) =>
                      onRoutingLegFieldChange('estimated_time_of_departure', value, index)
                    }
                    style={{ width: '100%' }}
                    disabled={
                      Boolean(getFieldValue('voyage_schedule_id') && isFirstMainCarriageHop) ||
                      disabled ||
                      disableVoyageUpdate
                    }
                  />
                </GenAIInputWrapper>
                {errorsPresent && isEtdRequired && !outgoing_edge?.estimated_time_of_departure ? (
                  <Text type="danger">Required</Text>
                ) : (
                  <span />
                )}
              </React.Fragment>
            );
          }}
        </Form.Item>
      </>
    );

  const COL_SPAN = viewType === VIEW_TYPE_SM ? 8 : Math.min(6, 24 / cols.length);

  return (
    <Row gutter={16}>
      {cols.length &&
        cols.map((col, ind) => {
          return (
            <Col key={ind} span={COL_SPAN}>
              {col}
            </Col>
          );
        })}
    </Row>
  );
});

const RoutingLegRender = React.memo(function RoutingLegRender(props: {
  routing_leg: RoutingLegValue;
  routing_nodes: RoutingNodesHashValue;
  previous_routing_leg?: RoutingLegValue;
  index: number;
  onRoutingLegFieldChange: RoutingLegCallback;
  onRoutingNodeFieldChange: RoutingNodeCallback;
  total_legs: number;
  addHop: () => void;
  removeHop: (route_order: number) => void;
  showTerminal?: boolean;
  freightType?: FreightType;
  locationSearchType?: Array<LocationType>;
  errorsPresent?: boolean;
  disableNodes?: number[];
  disableRoutingLegs?: number[];
  disableAddRemoveTranshipmentHop?: boolean;
  validateVesselVoyage?: boolean;
  globalCarrierId?: string;
  isReeferContainer?: boolean;
  bookingType?: string | null;
  allowVoyageScheduleSearch?: boolean;
  routing_type?: string;
  disableVoyageUpdate?: boolean;
  showDpd?: boolean;
}): JSX.Element {
  const {
    routing_leg,
    previous_routing_leg,
    routing_nodes,
    index,
    routing_type,
    onRoutingLegFieldChange,
    onRoutingNodeFieldChange,
    total_legs,
    addHop,
    removeHop,
    showTerminal,
    locationSearchType,
    errorsPresent,
    disableNodes,
    disableRoutingLegs,
    disableAddRemoveTranshipmentHop,
    validateVesselVoyage,
    globalCarrierId,
    isReeferContainer,
    bookingType,
    disableVoyageUpdate,
    showDpd = false,
  } = props;
  const isFirstMainCarriageHop =
    routing_leg.routing_type === ROUTING_TYPE_MAIN_CARRIAGE && index === 0;
  const showVoyageScheduleSearch = isFirstMainCarriageHop;
  const [deleteHighlight, setDeleteHighlight] = useState(false);
  const [showEditVoyageSchedule, setShowEditVoyageSchedule] = useState(false);

  const VoyageNumberLableMapping: { [key: string]: string } = {
    air: 'Flight number',
    road: 'Truck number',
    rail: 'Train number',
  };
  const voyageNumberLabel =
    (routing_leg?.mode_of_transit && VoyageNumberLableMapping[routing_leg?.mode_of_transit]) ||
    'Voyage number';

  const showVoyageCutoffMissingWarning = useCallback(() => {
    Modal.warning({
      title: 'Voyage Schedule Cutoff Date Missing!',
      content:
        'you need to edit voyage schedule details for selected voyage number to prefill cutoff dates',
    });
  }, []);
  const disabled = disableRoutingLegs?.includes(index);

  return (
    <>
      <Timeline.Item
        dot={<GreyIcon />}
        key={index + '_origin'}
        style={{ padding: 0 }}
        className={deleteHighlight ? 'item-hover-bg' : ''}
      >
        <NodeAndLegsInfo
          node={routing_nodes[routing_leg.origin_id || '']}
          incoming_edge={previous_routing_leg}
          outgoing_edge={routing_leg}
          onRoutingLegFieldChange={onRoutingLegFieldChange}
          onRoutingNodeFieldChange={onRoutingNodeFieldChange}
          index={index}
          routing_type={routing_type}
          disableNode={disableNodes?.includes(index)}
          disabled={disabled}
          showTerminal={showTerminal}
          locationSearchType={locationSearchType}
          errorsPresent={errorsPresent}
          validateVesselVoyage={validateVesselVoyage}
          disableVoyageUpdate={disableVoyageUpdate}
        />
        {(routing_type === ROUTING_TYPE_PICKUP_DELIVERY ||
          routing_type === ROUTING_TYPE_DELIVERY) && (
          <div style={{ marginLeft: '15px', marginBottom: '15px' }}>
            {index === total_legs - 1 && (
              <Button
                disabled={disableAddRemoveTranshipmentHop}
                icon={<PlusOutlined />}
                onClick={addHop}
                size="small"
                style={{ marginRight: '8px' }}
              >
                Add Transhipment Hop
              </Button>
            )}
            {index !== 0 && (
              <Button
                onMouseEnter={() => setDeleteHighlight(true)}
                onMouseLeave={() => setDeleteHighlight(false)}
                type={deleteHighlight ? 'primary' : 'default'}
                onClick={() => removeHop(index)}
                danger={deleteHighlight}
                size="small"
                icon={<DeleteOutlined />}
                disabled={disableAddRemoveTranshipmentHop}
              >
                Delete Transhipment Hop
              </Button>
            )}
          </div>
        )}
      </Timeline.Item>
      {routing_type !== ROUTING_TYPE_PICKUP_DELIVERY && routing_type !== ROUTING_TYPE_DELIVERY && (
        <Timeline.Item
          dot={<GreyIcon />}
          className={deleteHighlight ? 'item-hover-bg' : ''}
          key={index + '_edge'}
        >
          <Row style={{ marginLeft: '5px' }} gutter={16}>
            {[ROUTING_TYPE_ON_CARRIAGE, ROUTING_TYPE_PRE_CARRIAGE].includes(
              routing_leg?.routing_type || ''
            ) && (
              <Col span={6}>
                <Form.Item label="Mode of Transit">
                  <Select
                    value={routing_leg?.mode_of_transit || ''}
                    onChange={(val) => onRoutingLegFieldChange('mode_of_transit', val, index)}
                    placeholder="Transit through"
                    disabled={disabled || disableVoyageUpdate}
                  >
                    <Option key="road" value="road">
                      Road
                    </Option>
                    <Option key="rail" value="rail">
                      Rail
                    </Option>
                    <Option key="river" value="river">
                      River
                    </Option>
                    <Option key="air" value="air">
                      Air
                    </Option>
                    <Option key="sea" value="sea">
                      Sea
                    </Option>
                  </Select>
                </Form.Item>
              </Col>
            )}
            {['sea', 'river', 'air'].includes(routing_leg?.mode_of_transit || '') && (
              <Col span={6}>
                {routing_leg?.mode_of_transit !== 'air' ? (
                  <Form.Item
                    label={'Vessel'}
                    required={
                      index === 0 && validateVesselVoyage && bookingType !== BOOKING_TYPE_SELF
                    }
                    validateStatus={
                      index === 0 && errorsPresent && validateVesselVoyage && !routing_leg.vessel
                        ? 'error'
                        : 'success'
                    }
                  >
                    <GenAIInputWrapper
                      fieldKey={['vessel', 'name']}
                      arrayValueConfig={{
                        path_to_array: ['routing_legs'],
                        findConfig: {
                          key: 'routing_type',
                          value: routing_type,
                        },
                      }}
                      value={routing_leg.vessel}
                    >
                      <VesselSearch
                        value={routing_leg.vessel}
                        onChange={(value: any) => onRoutingLegFieldChange('vessel', value, index)}
                        disabled={disabled || disableVoyageUpdate}
                      />
                    </GenAIInputWrapper>
                    {index === 0 && errorsPresent && validateVesselVoyage && !routing_leg.vessel ? (
                      <Text type="danger">Required</Text>
                    ) : (
                      <span />
                    )}
                  </Form.Item>
                ) : (
                  <Form.Item label={'Carrier'}>
                    <GlobalSearch
                      doc_type="Global::Carrier"
                      value={routing_leg?.global_carrier || undefined}
                      searchProps={{ carrier_type: ['air'] }}
                      onChange={(val) => onRoutingLegFieldChange('global_carrier', val, index)}
                      selectMode={undefined}
                    />
                    {index === 0 &&
                    errorsPresent &&
                    validateVesselVoyage &&
                    !routing_leg.global_carrier ? (
                      <Text type="danger">Required</Text>
                    ) : (
                      <span />
                    )}
                  </Form.Item>
                )}
              </Col>
            )}
            <Col span={showVoyageScheduleSearch ? 12 : 6}>
              <Form.Item
                shouldUpdate={(prevValues, currentValues) =>
                  prevValues.voyage_schedule_id !== currentValues.voyage_schedule_id ||
                  prevValues.carrier?.id !== currentValues.carrier?.id
                }
              >
                {({ getFieldValue, setFieldsValue }) => {
                  const fieldErrorValidation =
                    index === 0 &&
                    errorsPresent &&
                    validateVesselVoyage &&
                    !routing_leg.voyage_number &&
                    bookingType !== BOOKING_TYPE_SELF ? (
                      <Text type="danger">Required</Text>
                    ) : (
                      <span />
                    );
                  const voyage_schedule_id = getFieldValue('voyage_schedule_id');
                  const carrier = getFieldValue('carrier');
                  const vessel = routing_leg?.vessel || undefined;
                  const node = routing_nodes[routing_leg.origin_id || ''];
                  const origin_id = node?.id || node?._id;
                  const routing_legs = [
                    {
                      sequence_number: 1,
                      mode_of_transit: routing_leg?.mode_of_transit,
                      estimated_time_of_departure: routing_leg?.estimated_time_of_departure,
                      origin: { ..._omit(node, ['id']), _id: origin_id },
                      origin_id,
                    },
                  ] as Array<RoutingLegValue>;
                  const initialVoyageScheduleFormData: VoyageScheduleInitialValue = {
                    carriers: carrier ? [carrier] : [],
                    vessel,
                    routing_legs,
                  };

                  const onVoyageScheduleChange = (
                    _voyageSchedule: VoyageScheduleValue | undefined | null,
                    isPartialMatch?: boolean
                  ) => {
                    _voyageSchedule = _voyageSchedule
                      ? VoyageSchedule?.create(_voyageSchedule)
                      : null;
                    onRoutingLegFieldChange('voyage_number', _voyageSchedule?.voyage_number, index);
                    onRoutingLegFieldChange('vessel', _voyageSchedule?.vessel, index);
                    const location_id = node?.location?.id?.toString();
                    if (!isPartialMatch)
                      onRoutingLegFieldChange(
                        'estimated_time_of_departure',
                        _voyageSchedule?.getETDByOriginLocation(location_id),
                        index
                      );
                    const routingNode =
                      _voyageSchedule?.getRoutingNodeByOriginLocation(location_id) ||
                      ({} as RoutingNodeValue);
                    if (!isPartialMatch)
                      onRoutingNodeFieldChange(
                        'terminal',
                        routingNode?.terminal,
                        node.id || node._id || ''
                      );
                    const fields: RoutingNodeKeys[] = [
                      'gate_open_date',
                      'gate_close_date',
                      'si_cutoff_date',
                      'doc_cutoff_date',
                      'vgm_cutoff_date',
                    ];
                    const formValues = _pick(routingNode, fields);

                    if (isReeferContainer) {
                      formValues['gate_open_date'] = routingNode['reefer_gate_open_date'];
                      formValues['gate_close_date'] = routingNode['reefer_gate_close_date'];
                    }
                    if (
                      _voyageSchedule &&
                      !isPartialMatch &&
                      !(formValues.gate_open_date && formValues.gate_close_date)
                    ) {
                      showVoyageCutoffMissingWarning();
                    }
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    //@ts-ignore
                    formValues['voyage_schedule_id'] = _voyageSchedule?.id;
                    setFieldsValue(formValues);
                  };

                  const onVoyageNumberChange = (voyage_number: string) => {
                    if (!voyage_number) setFieldsValue({ voyage_schedule_id: null });
                    onRoutingLegFieldChange('voyage_number', voyage_number, index);
                  };

                  return (
                    <Form.Item
                      label={
                        showVoyageScheduleSearch ? (
                          <span onClick={(e) => e.preventDefault()}>
                            {voyageNumberLabel}
                            <Button
                              type="link"
                              size="small"
                              disabled={!voyage_schedule_id}
                              onClick={() => setShowEditVoyageSchedule(true)}
                              style={{ float: 'right' }}
                            >
                              Edit
                            </Button>
                          </span>
                        ) : (
                          voyageNumberLabel
                        )
                      }
                      required={
                        index === 0 && validateVesselVoyage && bookingType !== BOOKING_TYPE_SELF
                      }
                      validateStatus={
                        index === 0 &&
                        errorsPresent &&
                        validateVesselVoyage &&
                        !routing_leg.voyage_number &&
                        bookingType !== BOOKING_TYPE_SELF
                          ? 'error'
                          : 'success'
                      }
                    >
                      {showVoyageScheduleSearch ? (
                        <GenAIInputWrapper
                          fieldKey={['voyage_number']}
                          arrayValueConfig={{
                            path_to_array: ['routing_legs'],
                            findConfig: {
                              key: 'routing_type',
                              value: routing_type,
                            },
                          }}
                          value={routing_leg.voyage_number || undefined}
                        >
                          <VoyageNumberInput
                            value={routing_leg.voyage_number || undefined}
                            showEditVoyageSchedule={showEditVoyageSchedule}
                            voyageScheduleId={getFieldValue('voyage_schedule_id')}
                            onEditCloseDrawer={() => setShowEditVoyageSchedule(false)}
                            initialVoyageScheduleFormData={initialVoyageScheduleFormData}
                            disabled={disabled || disableVoyageUpdate}
                            onChange={onVoyageNumberChange}
                            onVoyageChange={(
                              _voyageSchedule: VoyageScheduleValue | undefined | null,
                              isPartialMatch?: boolean
                            ) => {
                              onVoyageScheduleChange(_voyageSchedule, isPartialMatch);
                            }}
                            searchProps={{
                              vessel: vessel,
                              carrier: carrier || {
                                id: globalCarrierId,
                              },
                              location: node?.location,
                            }}
                          />
                        </GenAIInputWrapper>
                      ) : (
                        <Input
                          value={routing_leg.voyage_number || ''}
                          placeholder={voyageNumberLabel}
                          onChange={(e) =>
                            onRoutingLegFieldChange('voyage_number', e.target.value, index)
                          }
                          disabled={disabled || disableVoyageUpdate}
                        />
                      )}
                      {fieldErrorValidation}
                    </Form.Item>
                  );
                }}
              </Form.Item>
            </Col>
            {index !== 0 && (
              <Col style={{ display: 'flex', alignItems: 'center' }} span={4}>
                <Button
                  onMouseEnter={() => setDeleteHighlight(true)}
                  onMouseLeave={() => setDeleteHighlight(false)}
                  type={deleteHighlight ? 'primary' : 'default'}
                  onClick={() => removeHop(index)}
                  danger={deleteHighlight}
                  size="small"
                  icon={<DeleteOutlined />}
                  disabled={disableAddRemoveTranshipmentHop}
                >
                  Delete Transhipment Hop
                </Button>
              </Col>
            )}
          </Row>
          {index === total_legs - 1 && (
            <div style={{ marginLeft: '15px', marginBottom: '8px' }}>
              <Button
                disabled={disableAddRemoveTranshipmentHop}
                icon={<PlusOutlined />}
                onClick={addHop}
                size="small"
              >
                Add Transhipment Hop
              </Button>
            </div>
          )}
        </Timeline.Item>
      )}
      {index === total_legs - 1 && (
        <Timeline.Item
          dot={<GreyIcon />}
          key={index + '_destination'}
          // className="ant-timeline-item-last"
          style={{ padding: 0 }}
        >
          <NodeAndLegsInfo
            node={routing_nodes[routing_leg.destination_id || '']}
            incoming_edge={routing_leg}
            onRoutingLegFieldChange={onRoutingLegFieldChange}
            onRoutingNodeFieldChange={onRoutingNodeFieldChange}
            index={index}
            showTerminal={showTerminal}
            disableNode={disableNodes?.includes(total_legs)}
            disabled={disableRoutingLegs?.includes(total_legs)}
            locationSearchType={locationSearchType}
            errorsPresent={errorsPresent}
            routing_type={routing_type}
            disableVoyageUpdate={disableVoyageUpdate}
            showDpd={showDpd}
          />
        </Timeline.Item>
      )}
    </>
  );
});

export const createRoutingValues = (params: {
  place_of_carrier_receipt?: LocationValue | null;
  port_of_loading?: LocationValue | null;
  port_of_discharge?: LocationValue | null;
  place_of_carrier_delivery?: LocationValue | null;
  main_carriage_vessel?: VesselValue | null;
  main_carriage_voyage_number?: string | null;
  estimated_time_of_departure?: number | null;
  estimated_time_of_arrival?: number | null;
}) => {
  const {
    place_of_carrier_receipt,
    port_of_loading,
    port_of_discharge,
    place_of_carrier_delivery,
    main_carriage_vessel,
    main_carriage_voyage_number,
    estimated_time_of_departure,
    estimated_time_of_arrival,
  } = params;
  const pol_node = getNewRoutingNode({
    tags: ['port_of_loading'] as Array<RoutingNodeTag>,
    location: port_of_loading,
  } as RoutingNodeValue);
  const pod_node = getNewRoutingNode({
    tags: ['port_of_discharge'] as Array<RoutingNodeTag>,
    location: port_of_discharge,
  } as RoutingNodeValue);
  const nodes = { [pol_node._id || '']: pol_node, [pod_node._id || '']: pod_node };
  let pre_carriage_leg: RoutingLegValue[] = [];
  let on_carriage_leg: RoutingLegValue[] = [];
  if (place_of_carrier_receipt) {
    const pcr_node = getNewRoutingNode({
      tags: ['place_of_carrier_receipt'] as Array<RoutingNodeTag>,
      location: place_of_carrier_receipt,
    } as RoutingNodeValue);
    pre_carriage_leg = [
      {
        routing_type: 'pre_carriage',
        origin_id: pcr_node._id,
        destination_id: pol_node._id,
        mode_of_transit: MODE_OF_TRANSIT_RAIL,
        sequence_number: 1.1,
      } as RoutingLegValue,
    ];
    nodes[pcr_node._id || ''] = pcr_node;
  }
  if (place_of_carrier_delivery) {
    const pcd_node = getNewRoutingNode({
      tags: ['place_of_carrier_delivery'] as Array<RoutingNodeTag>,
      location: place_of_carrier_delivery,
    } as RoutingNodeValue);
    on_carriage_leg = [
      {
        routing_type: 'on_carriage',
        origin_id: pod_node._id,
        destination_id: pcd_node._id,
        mode_of_transit: MODE_OF_TRANSIT_RAIL,
        sequence_number: 1.1,
      } as RoutingLegValue,
    ];
    nodes[pcd_node._id || ''] = pcd_node;
  }
  return {
    routing_legs: [
      ...pre_carriage_leg,
      {
        routing_type: 'main_carriage',
        origin_id: pol_node._id,
        destination_id: pod_node._id,
        mode_of_transit: 'sea',
        sequence_number: 2.1,
        vessel: main_carriage_vessel,
        voyage_number: main_carriage_voyage_number,
        estimated_time_of_departure: convertToDayJs(estimated_time_of_departure),
        estimated_time_of_arrival: convertToDayJs(estimated_time_of_arrival),
      } as RoutingLegValue,
      ...on_carriage_leg,
    ],
    routing_nodes: nodes,
  };
};

export const getInitialNodes = (
  routing_type?: RoutingLegRoutingType
): { origin: RoutingNodeValue; destination: RoutingNodeValue } => {
  let origin_node_tags: Array<RoutingNodeTag> = [],
    destination_node_tags: Array<RoutingNodeTag> = [];
  if (routing_type === 'main_carriage') {
    origin_node_tags = ['port_of_loading', 'place_of_carrier_receipt'];
    destination_node_tags = ['port_of_discharge', 'place_of_carrier_delivery'];
  } else if (routing_type === 'pre_carriage' || routing_type === 'pickup') {
    origin_node_tags = ['place_of_carrier_receipt'];
    destination_node_tags = ['port_of_loading'];
  } else if (routing_type === 'on_carriage' || routing_type === 'delivery') {
    origin_node_tags = ['port_of_discharge'];
    destination_node_tags = ['place_of_carrier_delivery'];
  } else {
    origin_node_tags = [];
    destination_node_tags = [];
  }
  const origin = getNewRoutingNode({ tags: origin_node_tags } as RoutingNodeValue);
  const destination = getNewRoutingNode({ tags: destination_node_tags } as RoutingNodeValue);
  return { origin, destination };
};

const RoutingDetails = forwardRef(function RoutingDetails(props: RoutingDetailsProps, ref) {
  const {
    value,
    onChange,
    showTerminal,
    routing_type,
    locationSearchType,
    startSequence,
    endSequence,
    disableNodes,
    disableRoutingLegs,
    disableAddRemoveTranshipmentHop,
    validateVesselVoyage,
    globalCarrierId,
    isReeferContainer,
    bookingType,
    allowVoyageScheduleSearch = true,
    disableVoyageUpdate,
    showDpd = false,
  } = props;
  const [errorsPresent, setErrorsPresent] = useState(false);
  const validateRoutingNode = useCallback(() => {
    let foundError = false;
    _mapValues(value?.routing_nodes, (rn) => {
      if (!rn.location && !rn.address) {
        if (rn.tags?.length && rn.tags?.length > 0) {
          setErrorsPresent(true);
          foundError = true;
        }
      }
    });
    if (validateVesselVoyage) {
      _mapValues(value?.routing_legs, (rl: RoutingLegValue, index: string) => {
        if (
          (index === '0' && rl.hasOwnProperty('vessel') && !rl?.vessel) ||
          (index === '0' && rl.hasOwnProperty('vessel') && !rl?.voyage_number) ||
          (index === '0' &&
            rl.hasOwnProperty('estimated_time_of_departure') &&
            !rl?.estimated_time_of_departure)
        ) {
          setErrorsPresent(true);
          foundError = true;
        }
      });
    }
    if (!foundError) {
      setErrorsPresent(false);
    }
    return foundError;
  }, [validateVesselVoyage, value]);

  useImperativeHandle(ref, () => ({
    runValidation() {
      let foundError = false;
      _mapValues(value?.routing_nodes, (rn) => {
        if (!rn.location && !rn.address) {
          if (rn.tags?.length && rn.tags?.length > 0) {
            setErrorsPresent(true);
            foundError = true;
          }
        }
      });
      if (validateVesselVoyage && bookingType !== BOOKING_TYPE_SELF) {
        _mapValues(value?.routing_legs, (rl: RoutingLegValue, index: string) => {
          if (
            (index === '0' && !rl?.vessel) ||
            (index === '0' && !rl?.voyage_number) ||
            (index === '0' && !rl?.estimated_time_of_departure)
          ) {
            setErrorsPresent(true);
            foundError = true;
          }
        });
      }
      return foundError;
    },
    addHop,
  }));

  useEffect(() => {
    validateRoutingNode();
  }, [validateRoutingNode]);

  const initialNodes = getInitialNodes(routing_type);
  const routing_legs: Array<RoutingLegValue> = value?.routing_legs || [
      {
        routing_type,
        origin_id: initialNodes.origin._id,
        destination_id: initialNodes.destination._id,
        mode_of_transit: routing_type === 'main_carriage' ? 'sea' : undefined,
        sequence_number: startSequence + 0.1,
      } as RoutingLegValue,
    ],
    routing_nodes: RoutingNodesHashValue = value?.routing_nodes || {
      [initialNodes.origin._id || '']: initialNodes.origin,
      [initialNodes.destination._id || '']: initialNodes.destination,
    };

  const onRoutingLegFieldChange: RoutingLegCallback = (key, value, route_order) => {
    const new_routing_legs = routing_legs;
    new_routing_legs[route_order] = {
      ...new_routing_legs[route_order],
      [key]: value ? value : null,
    };
    if (onChange) onChange({ routing_legs: [...new_routing_legs], routing_nodes });
  };

  const onRoutingNodeFieldChange: RoutingNodeCallback = (key, value, id) => {
    const new_routing_nodes = routing_nodes;

    if (key === 'location_type') {
      new_routing_nodes[id] = {
        ...new_routing_nodes[id],
        location: null,
        terminal: null,
        address: null,
        company: undefined,
      };
    } else if (key === 'location') {
      new_routing_nodes[id] = {
        ...new_routing_nodes[id],
        location: value ? value : null,
        terminal: null,
      };
    } else if (key === 'company_address') {
      new_routing_nodes[id] = {
        ...new_routing_nodes[id],
        address: value ? value.party_address : null,
        company: value ? value.party_company : null,
      };
    } else {
      new_routing_nodes[id] = { ...new_routing_nodes[id], [key]: value ? value : null };
    }
    if (onChange) onChange({ routing_legs, routing_nodes: { ...new_routing_nodes } });
  };

  const addHop = () => {
    const last_routing_leg = routing_legs.splice(-1)[0];
    const new_routing_legs = routing_legs;
    let hop_tags: Array<RoutingNodeTag> = [];
    if (routing_type === 'main_carriage') hop_tags = ['transhipment'];
    else hop_tags = ['hop'];
    const new_node = getNewRoutingNode({ tags: hop_tags } as RoutingNodeValue);
    new_routing_legs.push({
      ...last_routing_leg,
      destination_id: new_node._id,
      estimated_time_of_arrival: undefined,
      actual_time_of_arrival: undefined,
      routing_type,
    });
    new_routing_legs.push({
      sequence_number: (last_routing_leg.sequence_number + endSequence) / 2,
      origin_id: new_node._id,
      destination_id: last_routing_leg.destination_id,
      estimated_time_of_arrival: last_routing_leg.estimated_time_of_arrival,
      actual_time_of_arrival: last_routing_leg.actual_time_of_arrival,
      routing_type,
      mode_of_transit: last_routing_leg?.mode_of_transit,
    } as RoutingLegValue);
    if (onChange)
      onChange({
        routing_legs: [...new_routing_legs],
        routing_nodes: { ...routing_nodes, [new_node._id || '']: new_node },
      });
  };

  const removeHop = (route_order: number) => {
    const routing_leg = routing_legs.splice(route_order, 1)[0];
    routing_legs[route_order - 1] = {
      ...routing_legs[route_order - 1],
      estimated_time_of_arrival: routing_leg.estimated_time_of_arrival,
      actual_time_of_arrival: routing_leg.actual_time_of_arrival,
      destination_id: routing_leg.destination_id,
    };
    if (routing_leg.origin_id) delete routing_nodes[routing_leg.origin_id];
    if (onChange)
      onChange({
        routing_legs: [...routing_legs],
        routing_nodes: { ...routing_nodes },
      });
  };

  return (
    <Timeline pending={false}>
      {routing_legs.map((routing_leg, index) => {
        const previous_routing_leg = routing_legs[index - 1];

        return (
          <Timeline.Item key={index} dot={<></>}>
            <RoutingLegRender
              key={index}
              routing_leg={routing_leg}
              previous_routing_leg={previous_routing_leg}
              routing_nodes={routing_nodes}
              index={index}
              total_legs={routing_legs.length}
              addHop={addHop}
              removeHop={removeHop}
              routing_type={routing_type}
              onRoutingLegFieldChange={onRoutingLegFieldChange}
              onRoutingNodeFieldChange={onRoutingNodeFieldChange}
              showTerminal={showTerminal}
              locationSearchType={locationSearchType}
              errorsPresent={errorsPresent}
              disableNodes={disableNodes}
              disableRoutingLegs={disableRoutingLegs}
              disableAddRemoveTranshipmentHop={disableAddRemoveTranshipmentHop}
              validateVesselVoyage={
                validateVesselVoyage && routing_type === ROUTING_TYPE_MAIN_CARRIAGE
              }
              globalCarrierId={globalCarrierId}
              isReeferContainer={isReeferContainer}
              bookingType={bookingType}
              allowVoyageScheduleSearch={allowVoyageScheduleSearch}
              disableVoyageUpdate={disableVoyageUpdate}
              showDpd={showDpd}
            />
          </Timeline.Item>
        );
      })}
    </Timeline>
  );
});

const CompRoutingDetails = React.memo(RoutingDetails);

export default CompRoutingDetails;
