import React, { useRef, useState, useEffect } from 'react';
import { GridOptions } from '@ag-grid-community/core';
import {
  Form,
  message,
  Modal,
  Select,
  Col,
  Row,
  BaseTable,
  Button,
  Radio,
} from '@shipmnts/pixel-hub';
import { useSession } from 'common';
import { Column } from 'operations/models/Report';
import { observer } from 'mobx-react-lite';
import { cloneDeep, isNil } from 'lodash';
import {
  ShipmentEstimateValue,
  useShipmentEstimateStore,
} from 'operations/models/ShipmentEstimate';
import { useMutation, useQuery } from '@apollo/client';
import { ShipmentValue } from 'operations/models/Shipment';
import { FREIGHT_CHARGE_TAG, DUE_CARRIER_TAG } from 'operations/constants';
import {
  ACCOUNTING_STATUS_BILLING_CLOSED,
  FREIGHT_TYPE_AIR,
  FREIGHT_TYPE_OCEAN,
  ENUM_BUY_SELL_STATUS,
} from 'operations/modules/shipment/constants';
import {
  SPLIT_SHIPMENT_ESTIMATE,
  FETCH_HOUSE_JOB_LIST_SPLITTED_AMOUNT,
} from 'operations/graphql/shipmentEstimate';
import { LOAD_TYPE_LCL } from 'operations/baseConstants';

interface SplitChargeModalType {
  buyPermitted: boolean;
  sellPermitted: boolean;
  onClose: () => void;
  shipment: ShipmentValue;
  estimate_data: ShipmentEstimateValue;
}

const SplitChargeModal = observer(function SplitChargeModal(props: SplitChargeModalType) {
  const { buyPermitted, sellPermitted, onClose, shipment, estimate_data } = props;

  const gridRef = useRef<GridOptions>();
  const { company_account } = useSession();

  const freight_type_basis: Record<string, string[]> = {
    air: ['Shipment', 'Manual', 'Chargeable Weight', 'Chargeable Volume'],
    road: ['Shipment', 'Manual', 'Gross Weight', 'Gross Volume', 'Container'],
    ocean: ['Shipment', 'Manual', 'Gross Volume', 'Chargeable Volume', "TEU's", 'Container'],
  };

  const full_basis_map: Record<string, string> = {
    Container: 'by_no_of_containers',
    'Chargeable Weight': 'by_chargeable_wt',
    'Gross Volume': 'by_gross_volume',
    'Chargeable Volume': 'by_chargeable_volume',
    'Gross Weight': 'by_gross_wt',
    Shipment: 'by_shipment',
    Manual: 'by_manual',
    "TEU's": 'by_teu',
  };

  function getBasisMap(freight_type: string): Record<string, string> {
    const allowed_basis = freight_type_basis[freight_type] || [];
    return Object.fromEntries(
      allowed_basis.map((option) => [option, full_basis_map[option] || option])
    );
  }

  let basis_map = getBasisMap(shipment?.freight_type || FREIGHT_TYPE_OCEAN);
  if (shipment?.freight_type === FREIGHT_TYPE_OCEAN) {
    let isLclPresent = false;
    shipment?.house_shipments?.forEach((element) => {
      if (element.load_type === LOAD_TYPE_LCL) isLclPresent = true;
    });
    const exclusions = isLclPresent
      ? ["TEU's", 'Container']
      : ['Gross Volume', 'Chargeable Volume'];

    basis_map = Object.fromEntries(
      Object.entries(basis_map).filter(([key]) => !exclusions.includes(key))
    );
  }

  const { store } = useShipmentEstimateStore();
  const estimates = store.estimatesForSplitModal(shipment.id);
  const [currentEstimateIndex, setCurrentEstimateIndex] = useState(
    estimates.findIndex((est) => est.id === estimate_data.id)
  );
  const [currentEstimate, setCurrentEstimate] = useState(estimate_data);

  const [splitMethod, setSplitMethod] = useState(
    basis_map[currentEstimate?.uom ?? 'Shipment'] || 'by_shipment'
  );

  const [splitType, setSplitType] = useState(
    currentEstimate?.buy_status === ENUM_BUY_SELL_STATUS.SPLITTED ? 'INCOME' : 'EXPENSE'
  );

  const isSupplierRequired =
    splitType === 'EXPENSE' ? isNil(currentEstimate?.supplier_company_id) : false;

  const errorShownRef = useRef(false);

  if (isSupplierRequired && !errorShownRef.current) {
    message.error('Supplier required on estimate');
    errorShownRef.current = true;
  }
  const [isButtonDisabled, setIsButtonDisabled] = useState(isSupplierRequired);

  useEffect(() => {
    setIsButtonDisabled(isSupplierRequired);
    if (!isSupplierRequired) {
      errorShownRef.current = false;
    }
  }, [isSupplierRequired, errorShownRef]);

  const { data, loading } = useQuery(FETCH_HOUSE_JOB_LIST_SPLITTED_AMOUNT, {
    variables: {
      estimate_id: currentEstimate.id,
      master_shipment_id: shipment?.id,
      split_type: splitType,
      split_method: splitMethod,
    },
  });
  const [splitEstimate] = useMutation(SPLIT_SHIPMENT_ESTIMATE);

  useEffect(() => {
    setSplitType(
      currentEstimate?.buy_status === ENUM_BUY_SELL_STATUS.SPLITTED ||
        ([FREIGHT_CHARGE_TAG, DUE_CARRIER_TAG].includes(currentEstimate?.tag || '') &&
          shipment?.freight_type === FREIGHT_TYPE_AIR) ||
        (!buyPermitted && shipment?.accounting_status !== ACCOUNTING_STATUS_BILLING_CLOSED)
        ? 'INCOME'
        : 'EXPENSE'
    );
  }, [currentEstimate, buyPermitted, shipment?.freight_type, shipment?.accounting_status]);

  if (!shipment) return <></>;

  const columnDefs: Column[] = [
    {
      headerName: 'Item',
      field: 'item',
      pinned: 'left',
      minWidth: 170,
      columnType: 'String',
    },
    {
      headerName: 'Job Number',
      field: 'job_number',
      width: 200,
      minWidth: 100,
      columnType: 'String',
      valueGetter: (params) => {
        const house = params.data?.house;
        if (!house) return null;
        return house.job_number;
      },
    },
    {
      headerName: 'Gross Volume',
      field: 'gross_volume',
      minWidth: 100,
      columnType: 'Float',
      hide: true,
      valueGetter: (params) => {
        const house = params.data?.house;
        if (!house) return null;
        return house.gross_volume;
      },
    },
    {
      headerName: 'Chargeable Wt.',
      field: 'chargeable_weight',
      minWidth: 100,
      columnType: 'Float',
      hide: true,
      valueGetter: (params) => {
        const house = params.data?.house;
        if (!house) return null;
        return house.chargeable_weight;
      },
    },
    {
      headerName: 'House Containers',
      field: 'house_wise_containers',
      minWidth: 110,
      columnType: 'String',
      valueGetter: (params: {
        data?: { house?: { shipment_containers?: { container_number: string }[] } };
      }) => {
        const house = params.data?.house;
        if (!house || !Array.isArray(house.shipment_containers)) return '';
        const containerNumbers = house.shipment_containers.map(
          (container: { container_number: string }) => container.container_number
        );

        return `${containerNumbers.join(', ')}`;
      },
      hide: !(shipment?.freight_type === FREIGHT_TYPE_OCEAN),
    },
    {
      headerName: 'Chargeable Vol.',
      field: 'chargeable_volume',
      minWidth: 100,
      columnType: 'Float',
      hide: true,
      valueGetter: (params) => {
        const house = params.data?.house;
        if (!house) return null;
        const grossVolume = house.gross_volume || 0;
        const grossWeight = (house.gross_weight || 0) / 1000;
        return Math.max(grossVolume, grossWeight);
      },
    },
    {
      headerName: 'Basis',
      field: 'basis',
      columnType: 'String',
      minWidth: 100,
    },
    {
      headerName: 'Qty',
      field: 'quantity',
      columnType: 'Float',
      minWidth: 100,
      editable: true,
      cellEditor: 'FloatEditor',
      valueSetter: (params: any) => {
        if (splitMethod === 'by_manual') {
          params.data.quantity = Number(params.newValue);
        } else {
          params.data.quantity = Number(params.data.quantity);
        }
        return true;
      },
      cellStyle: () => {
        return {
          cursor: 'pointer',
        };
      },
    },
    {
      headerName: 'Rate',
      field: 'rate',
      columnType: 'Float',
      minWidth: 150,
      editable: true,
      cellEditor: 'FloatEditor',
      valueSetter: (params: any) => {
        if (splitMethod === 'by_manual') {
          params.data.rate = Number(params.newValue);
        } else {
          params.data.rate = Number(params.data.rate);
        }
        return true;
      },
      cellRendererSelector: () => {
        return {
          component: 'CurrencyTypeRenderer',
          params: {
            precision: 2,
            currency:
              splitType === 'EXPENSE'
                ? currentEstimate?.buy_currency
                : currentEstimate?.sell_currency,
            showCurrency: true,
          },
        };
      },
      cellStyle: () => {
        return {
          cursor: 'pointer',
        };
      },
    },
    {
      headerName: 'Amount',
      field: 'amount',
      columnType: 'Float',
      minWidth: 150,
      lockVisible: true,
      valueGetter: (params) => {
        if (splitMethod === 'by_manual' && !params.node?.rowPinned) {
          return Number(
            params.data.rate *
              params.data.quantity *
              ((splitType === 'EXPENSE'
                ? estimate_data?.buy_exchange_rate
                : estimate_data?.sell_exchange_rate) || 1)
          );
        }
        return Number(params.data.amount);
      },
      cellRenderer: 'CurrencyTypeRenderer',
      cellRendererSelector: () => {
        return {
          component: 'CurrencyTypeRenderer',
          params: {
            precision: 2,
            currency: company_account?.default_currency,
            showCurrency: true,
          },
        };
      },
    },
  ];

  const onOk = async () => {
    setIsButtonDisabled(true);
    const selectedData = gridRef.current?.api?.getRenderedNodes() ?? [];
    const cleanSelectedData = selectedData.map(({ data }) => {
      if (!data) return {};
      const { __typename, house_wise_containers, house, ...rest } = data;
      return {
        ...rest,
        shipment_id: house?.id,
        customer_company_id: house?.customer_company_id,
      };
    });
    try {
      const { data } = await splitEstimate({
        variables: {
          split_estimate: {
            id: currentEstimate?.id,
            split_type: splitType,
            split_method: splitMethod,
            splitted_data: cleanSelectedData,
          },
        },
      });
      if (data) {
        message.success(data.split_shipment_estimate.messages[0]);
        const nextIndex = currentEstimateIndex + 1;
        if (nextIndex < estimates.length) {
          setCurrentEstimate(estimates[nextIndex]);
          setCurrentEstimateIndex(nextIndex);
          setSplitType(
            currentEstimate?.buy_status === ENUM_BUY_SELL_STATUS.SPLITTED ||
              (!buyPermitted && shipment?.accounting_status !== ACCOUNTING_STATUS_BILLING_CLOSED)
              ? 'INCOME'
              : 'EXPENSE'
          );
        } else {
          message.info('All estimates processed');
          onClose();
        }
        setIsButtonDisabled(false);
      }
    } catch (error) {
      message.error('Failed to create estimate');
      console.error('Mutation Error:', error);
    }
  };

  const moveToNextCharge = async () => {
    const nextIndex = currentEstimateIndex + 1;
    if (nextIndex < estimates.length) {
      setCurrentEstimate(estimates[nextIndex]);
      setCurrentEstimateIndex(nextIndex);
      setSplitType(
        currentEstimate?.buy_status === ENUM_BUY_SELL_STATUS.SPLITTED ||
          (!buyPermitted && shipment?.accounting_status !== ACCOUNTING_STATUS_BILLING_CLOSED)
          ? 'INCOME'
          : 'EXPENSE'
      );
    } else {
      message.info('All estimates viewed');
      onClose();
    }
  };

  let report_name;
  if (shipment?.freight_type === FREIGHT_TYPE_OCEAN) report_name = 'split_charge_for_ocean';
  else report_name = 'split_charge_for_others';

  const createText = `Create Estimates and Go to Next Item`;
  const nextChargeText = `Skip and Next`;

  return (
    <Modal
      title={`Split Charges on Houses`}
      open
      width="85vw"
      onOk={onClose}
      onCancel={onClose}
      footer={[
        <Button key="close" onClick={onClose}>
          {'Close'}
        </Button>,
        <Button key="NextCharge" loading={loading} onClick={moveToNextCharge}>
          {nextChargeText}
        </Button>,
        <Button
          key="Create"
          disabled={isButtonDisabled}
          loading={loading}
          onClick={onOk}
          type="primary"
        >
          {createText}
        </Button>,
      ]}
    >
      <Row gutter={[16, 16]} align="middle">
        <Col span={7}>
          <Form.Item label="Charge Name">{currentEstimate?.item}</Form.Item>
        </Col>
        <Col span={5}>
          <Form.Item label="Basis">
            {currentEstimate?.equipment_name
              ? currentEstimate?.equipment_name
              : currentEstimate?.uom}
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item label="Qty">{currentEstimate?.quantity}</Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item label="Rate">
            {new Intl.NumberFormat('en-US', {
              style: 'currency',
              currency: 'EXPENSE'
                ? currentEstimate?.buy_currency ?? company_account?.default_currency
                : currentEstimate?.sell_currency ?? company_account?.default_currency,
              minimumFractionDigits: 2,
            }).format(
              splitType === 'EXPENSE'
                ? currentEstimate?.buy_rate || 0
                : currentEstimate?.sell_rate || 0
            )}
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item label="Amount">
            {new Intl.NumberFormat('en-US', {
              style: 'currency',
              currency: company_account?.default_currency,
              minimumFractionDigits: 2,
            }).format(
              splitType === 'EXPENSE'
                ? currentEstimate?.total_buy_amount || 0
                : currentEstimate?.total_sell_amount || 0
            )}
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={[16, 16]} align="middle">
        <Col span={7}>
          <Form.Item label="Split Type">
            <Radio.Group value={splitType} onChange={(e) => setSplitType(e.target.value)}>
              <Radio
                value="INCOME"
                disabled={
                  currentEstimate?.sell_status !== 'unbilled' ||
                  !sellPermitted ||
                  shipment?.accounting_status === ACCOUNTING_STATUS_BILLING_CLOSED
                }
              >
                Income
              </Radio>
              <Radio
                value="EXPENSE"
                disabled={
                  currentEstimate?.buy_status !== 'unbilled' ||
                  !buyPermitted ||
                  ([FREIGHT_CHARGE_TAG, DUE_CARRIER_TAG].includes(currentEstimate?.tag || '') &&
                    shipment?.freight_type === FREIGHT_TYPE_AIR)
                }
              >
                Expense
              </Radio>
            </Radio.Group>
          </Form.Item>
        </Col>
        <Col span={7}>
          <Form.Item label="Split Method">
            <Select
              value={splitMethod}
              placeholder="Select Split Method"
              onChange={(value) => setSplitMethod(value)}
            >
              {Object.entries(basis_map).map(([label, value]) => (
                <Select.Option key={value} value={value}>
                  {label === 'Shipment' ? 'Equal Split' : `By ${label}`}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>

      <BaseTable
        reportName={report_name}
        height={'40vh'}
        gridRef={gridRef}
        columns={columnDefs}
        rowData={cloneDeep(data?.fetch_house_job_list_splitted_amount || [])}
        reportConfig={{
          enableCellChangeFlash: true,
          animateRows: true,
          singleClickEdit: true,
          stopEditingWhenCellsLoseFocus: true,
          onCellFocused: (event: any) => {
            if (!event?.column?.colId) return;
            Array.from(document.querySelectorAll('.col-bg-cell-focus')).forEach((el) =>
              el.classList.remove('col-bg-cell-focus')
            );
            Array.from(
              document.querySelectorAll(
                `.ag-header-cell.ag-focus-managed[col-id="${event?.column?.colId}"]`
              )
            ).forEach((el) => el.classList.add('col-bg-cell-focus'));

            Array.from(
              document.querySelectorAll(
                `.ag-cell-focus, .ag-theme-material .ant-select-focused .ant-select-selector, .ant-select-selector:focus, .ant-select-selector:active,.ant-select-open.ant-select-selector, .ant-select-selector-search .ant-select-selector-search:focus, .ag-has-focus .ag-cell-inline-editing input`
              )
            ).forEach((el) => el.classList.add('col-bg-cell-focus'));
          },
          tabToNextCell: (params: any) => {
            return params.nextCellPosition;
          },
        }}
      />
    </Modal>
  );
});

export default SplitChargeModal;
