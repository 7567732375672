import React, { useState, useEffect } from 'react';
import {
  Button,
  Card,
  message,
  Form,
  WithPermission,
  ConditionalPermissions,
  Space,
  Drawer,
} from '@shipmnts/pixel-hub';
import { isCustomer, isVendor, useSession, ADMINISTRATION_ACCESS_LEVELS } from 'common';
import ContactFormContent from './ContactFormContent';
import { CompanyValue } from 'network/models/Company';
import { UPSERT_CONTACT } from 'network/graphql/contact';
import { useMutation } from '@apollo/client';
import DisableContact from './DisableContact';
import {
  PERMISSION_CUSTOMER_CREATE_EDIT,
  PERMISSION_SUPPLIER_CREATE_EDIT,
} from 'network/permissions';
import { PERMISSION_DISABLE } from 'common/baseConstants';
import { ContactValue } from '../models/Contact';
export interface ContactFormValue extends Omit<ContactValue, 'mobile_number'> {
  mobile_number: {
    number: string;
    dialCode: string;
  };
}

let permission: ConditionalPermissions | undefined = undefined;
export const getContactPayload = (data?: any) => {
  if (!data) return undefined;
  return {
    ...data,
    mobile_number: {
      number: data?.mobile_number,
      dialCode: data?.dial_code,
    },
  };
};

export const upsertContactPayload = (value: ContactFormValue) => {
  return {
    id: value.id,
    title: value?.title || null,
    email: value?.email || null,
    first_name: value?.first_name || null,
    last_name: value?.last_name || null,
    department: value?.department || null,
    mobile_number: value?.mobile_number?.number || null,
    dial_code: value?.mobile_number?.number ? value?.mobile_number?.dialCode || null : null,
  } as ContactValue;
};

type Props = {
  visible: boolean;
  setVisible?: (visible: boolean) => void;
  onSuccess?: (value?: any) => void;
  onClose?: () => void;
  contact?: any;
  company?: CompanyValue;
};

export const isValidContactField = (values: any, user_level: string) => {
  if (!values?.first_name) {
    message.error('Kindly add first name in contact details.');
    return false;
  }

  if (!values?.mobile_number?.number && !values?.email) {
    message.error('Kindly add email id or mobile number in contact details.');
    return false;
  }

  if (values?.mobile_number?.number && !values?.mobile_number?.number?.match(/^\d+$/)) {
    message.error('Phone number should be Number');
    return false;
  }
  if (values?.mobile_number?.number && !values?.mobile_number?.dialCode) {
    message.error('Please select country code.');
    return false;
  }
  if (!checkValidations(values?.company, user_level)) {
    message.error('Permission denied for creating user contacts for self company.');
    return false;
  }
  return true;
};

export const checkValidations = (company: CompanyValue, user_level: string) => {
  let allCreate = true;
  const isUserAdmin = ADMINISTRATION_ACCESS_LEVELS.includes(user_level);
  const isSelfCompany = company?.company_group === 'Self';
  if (isSelfCompany && !isUserAdmin) {
    allCreate = false;
  }
  return allCreate;
};

const ContactForm = (props: Props) => {
  const { visible, setVisible, contact, company, onSuccess, onClose } = props;
  const session = useSession();
  const [createAnother, setCreateAnother] = useState(false);

  const [
    upsertContact,
    { data: upsertContactData, error: upsertContactError, loading: upsertContactLoading },
  ] = useMutation(UPSERT_CONTACT);

  const initial_company: CompanyValue | undefined = company;
  const [initialContact, setInitialContact] = useState<any>(getContactPayload(contact));
  if (isCustomer(initial_company?.company_type) || isVendor(initial_company?.company_type)) {
    permission = {
      OR: [
        { name: PERMISSION_CUSTOMER_CREATE_EDIT, docType: 'Network::Company' },
        { name: PERMISSION_SUPPLIER_CREATE_EDIT, docType: 'Network::Company' },
      ],
    };
  }
  const disablePermission = { name: PERMISSION_DISABLE, docType: 'Network::UserContact' };
  if (permission)
    permission = {
      AND: [permission, disablePermission],
    };
  else permission = disablePermission;
  const contact_id = contact?.id || 'new';
  const [form] = Form.useForm();

  useEffect(() => {
    if (upsertContactData?.upsert_contact && !upsertContactError) {
      if (contact_id === 'new') message.success('Contact Created sucessfully.');
      else message.success('Contact updated sucessfully.');
      onSuccess && onSuccess();
      form.resetFields();
      if (!createAnother) setVisible && setVisible(false);
    }
    if (upsertContactError)
      message.error({
        content: upsertContactError?.graphQLErrors.map((e) => e.message),
        key: 'upsert_contact',
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [upsertContactData, upsertContactError]);

  const permissionWrap = (content: React.ReactElement, key: string) => {
    let wrappedContent = content;
    wrappedContent = (
      <WithPermission key={key} permission={permission}>
        {wrappedContent}
      </WithPermission>
    );
    return wrappedContent;
  };

  const company_name = initial_company?.registered_name;
  const title =
    contact_id === 'new'
      ? `New Contact ${company_name ? `for ${company_name}` : ''}`
      : `Edit Contact ${company_name ? `for ${company_name}` : ''}`;

  return (
    <Drawer
      title={title}
      placement="right"
      closable={false}
      onClose={() => {
        setVisible && setVisible(false);
        onClose && onClose();
        form.resetFields();
      }}
      bodyStyle={{
        padding: '8px',
        backgroundColor: '#fafafa',
      }}
      open={visible}
      width={720}
      footer={
        <Space style={{ width: '100%', justifyContent: 'flex-end' }}>
          <Button
            onClick={() => {
              form.resetFields();
              setVisible && setVisible(false);
              onClose && onClose();
            }}
            key="cancel"
          >
            Cancel
          </Button>
          {contact_id !== 'new' &&
            permissionWrap(
              <DisableContact
                contact_id={initialContact?.id}
                flag={!initialContact?.is_disabled}
                key="disable"
                onSuccess={setInitialContact}
                disabled={upsertContactLoading || initialContact?.is_internal || false}
              >
                {initialContact?.is_disabled ? 'Enable' : 'Disable'}
              </DisableContact>,
              'disable'
            )}
          {contact_id === 'new' && (
            <Button
              loading={upsertContactLoading}
              disabled={
                upsertContactLoading ||
                !!initialContact?.is_internal ||
                !!initialContact?.is_disabled
              }
              onClick={() => {
                setCreateAnother(true);
                form.submit();
              }}
              key="save&another"
            >
              Save & Add Another
            </Button>
          )}
          <Button
            loading={upsertContactLoading}
            disabled={
              upsertContactLoading || !!initialContact?.is_internal || !!initialContact?.is_disabled
            }
            onClick={() => {
              setCreateAnother(false);
              form.submit();
            }}
            type="primary"
            key="save"
          >
            Save
          </Button>
        </Space>
      }
    >
      <Form
        form={form}
        onFinish={(values: ContactFormValue) => {
          if (contact_id !== 'new') values['id'] = contact_id;
          if (!isValidContactField(values, session?.user_level || '')) return;

          const payload: any = { contact: upsertContactPayload(values) };
          if (initial_company) payload['company_id'] = initial_company?.id;
          else payload['company_id'] = form.getFieldsValue()?.company?.id;
          upsertContact({ variables: payload });
        }}
        name="contact"
        layout="vertical"
        style={{
          height: '100%',
        }}
        initialValues={initialContact}
      >
        <Card>
          <ContactFormContent
            initialCompany={initial_company}
            form={form}
            contact={initialContact}
            showCompany={contact_id === 'new'}
          />
        </Card>
      </Form>
    </Drawer>
  );
};

export default ContactForm;
