import React from 'react';
import { ColDef } from '@ag-grid-community/core';
import { omit as _omit } from 'lodash';
import { getFieldEditable } from '@shipmnts/pixel-hub';
import { FIELD_TYPE_DROPDOWN, FIELD_TYPE_MULTISEARCH } from 'common/constants/constant';

export const DefaultColumnTypeRenderers: { [key: string]: ColDef } = {
  Date: {
    cellRenderer: 'date_type_renderer',
  },
  DateTime: {
    cellRenderer: 'date_time_type_renderer',
  },
  Dropdown: {
    cellRenderer: 'enum_type_renderer',
  },
  Number: {
    cellRenderer: 'number_type_renderer',
  },
  Float: {
    cellRenderer: 'number_type_renderer',
  },
  Boolean: {
    cellRenderer: 'boolean_type_renderer',
  },
  Attachment: {
    cellRenderer: 'attachment_type_renderer',
  },
  MultiAttachment: {
    cellRenderer: 'attachment_type_renderer',
  },
  String: {},
  Link: {},
  Search: {},
  MultiSearch: {},
  Integer: {},
};

export const getWithCustomFieldValues = (items: any) => {
  return items.map((item: any) => {
    const customFieldString = item?.custom_field_values || '{}';
    const customFieldValues = JSON.parse(customFieldString);
    return { ...item, ...customFieldValues };
  });
};

export const makeColumnForTab = (field: any, editProps?: any) => {
  const cellRenderer = JSON.parse(field.cell_renderer || '{}');
  let column: any = {
    headerName: field.label,
    field: field.db_column_name,
    colId: field.db_column_name,
    columnType: field.field_type,
    width: field.width,
    filter: field.filterable,
    cellRenderer: (props: any) => {
      return <>{props.value}</>;
    },
    hide: true,
  };
  if (!!editProps) {
    column = {
      ...column,
      ..._omit(
        getFieldEditable(
          { ...field, cell_editor: JSON.parse(field.cell_editor || '{}') },
          editProps
        ),
        'cellStyle'
      ),
    };
  }
  if (cellRenderer?.function) {
    column.cellRenderer = cellRenderer?.function;
    column.cellRendererParams = cellRenderer?.args;
  } else if (DefaultColumnTypeRenderers[field.field_type]) {
    column.cellRenderer = DefaultColumnTypeRenderers[field.field_type].cellRenderer;
  }
  if (field.field_type === FIELD_TYPE_DROPDOWN || field.field_type === FIELD_TYPE_MULTISEARCH) {
    column.cellRendererParams = {
      filter_options: field?.filter_options,
    };
  }
  return column;
};
