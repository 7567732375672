import { gql } from '@apollo/client';
export const templateFields = gql`
  fragment templateFields on TemplateObjectType {
    id
    resource_type
    content
    template_type
    name
    is_standard
    is_disabled
    is_default
    print_option {
      field_order
      fields {
        field_name
        field_type
        label
        description
        default
      }
    }
    created_by {
      id
    }
    template_subtype
  }
`;

export const GET_TEMPLATE = gql`
  query template($id: ID!) {
    template(id: $id) {
      ...templateFields
    }
  }
  ${templateFields}
`;

export const UPSERT_TEMPLATE = gql`
  mutation upsert_template($template: TemplateInputType!) {
    upsert_template(template: $template) {
      ...templateFields
    }
  }
  ${templateFields}
`;
