import { Col, Drawer, Form, FormInstance, message, Row, Steps, dayjs } from '@shipmnts/pixel-hub';
import { ShipmentValue } from 'operations/models/Shipment';
import React, { MutableRefObject, useCallback, useEffect, useRef, useState } from 'react';
import { useSession } from 'common';
import { CompassOutlined, DrawerFooter } from '@shipmnts/pixel-hub';
import ContainerGrid from 'operations/modules/booking/components/MergeSplitBooking/ContainerGridRender';
import { ShipmentContainerValue } from 'operations/models/ShipmentContainer';
import { GridOptions } from '@ag-grid-community/core';
import { omit, filter as _filter } from 'lodash';
import {
  ASSET_TYPE_OWN,
  BUSINESS_TYPE_DIRECT,
  CARGO_TYPE_BULK,
  CARGO_TYPE_CONTAINER,
  FREIGHT_TYPE_AIR,
  FREIGHT_TYPE_OCEAN,
  FREIGHT_TYPE_ROAD,
  MOVEMENT_MODE_CARGO_PICKUP_AND_DROP,
  SHIPMENT_TYPE_BACK_TO_BACK,
  TRADE_TYPE_DOMESTIC,
  TRADE_TYPE_EXPORT,
  TRADE_TYPE_IMPORT,
} from '../../constants';
import {
  LOAD_TYPE_FCL,
  LOAD_TYPE_FTL_BREAK_BULK,
  VOLUME_UNIT_CBM,
  WEIGHT_UNIT_KGS,
} from 'operations/baseConstants';
import { RoutingNodesHashValue } from 'operations/components/RoutingDetails/RoutingDetails';
import { MODE_OF_TRANSIT_ROAD, RoutingLegValue } from 'operations/models/RoutingLeg';
import {
  getConsignmentNotePayload,
  getGenericPayloadValue,
  getRoadMovementMode,
  getServices,
} from './helpers';
import { useApolloClient, useMutation } from '@apollo/client';
import { CREATE_SHIPMENT } from './graphql';
import { useLocation } from 'wouter';
import BasicDetails from './BasicDetails';
import VehicleDetails from './VehicleDetails';
import LinkShipmentDetails from './LinkShipmentDetails';
import RoadRoutingDetails from './RoadRoutingDetails/RoadRoutingDetails';
import ShipmentServiceDetails from '../ShipmentServiceDetails';
import ExportClearanceDetails from '../ExportClearanceDetails';
import FormLayout from './FormLayout';
import { getSalesPersonForCustomer } from 'operations/helpers/fetchSalesPerson';
import { ProductOrderItemValue } from 'operations/models/ProductOrderItem';
import { convertToDayJs } from '@shipmnts/pixel-hub';
import CargoNewComponent from '../DetailLayout/Cargo/CargoNewComponent/CargoNewComponent';
import ConsignmentNoteDetails from './ConsignmentNoteDetails';
import { Remarks } from '../NewShipmentForm/Remarks';

// const { Step } = Steps;
interface LinkRoadShipmentDrawerProps {
  shipment?: ShipmentValue;
  onClose?: () => void;
}
const getComponents = (props: {
  shipment: ShipmentValue | undefined | null;
  form: FormInstance;
  cargoRef: MutableRefObject<
    { runValidation: () => boolean; getPayload: () => ProductOrderItemValue[] } | undefined
  >;
}) => {
  const { shipment, form, cargoRef } = props;
  const tradeType = form?.getFieldValue('trade_type');
  const services = getServices(tradeType || '');
  return [
    {
      title: 'Basic Details',
      component: BasicDetails,
      props: { form, shipment },
      isVisible: true,
    },
    {
      title: 'Vehicle & Driver Details',
      component: VehicleDetails,
      props: { shipment, form },
      isVisible: true,
    },
    {
      title: 'Link Shipment Details',
      component: LinkShipmentDetails,
      props: { shipment, form, disableShipmentSearch: true },
      isVisible: true,
    },
    {
      title: 'Routing Details',
      component: RoadRoutingDetails,
      props: { value: shipment, form },
      isVisible: true,
    },
    {
      title: 'Cargo Details',
      component: CargoNewComponent,
      props: { form, ref: cargoRef },
      isVisible: true,
    },
    {
      title: 'Consignment Note Details',
      component: ConsignmentNoteDetails,
      isVisible: true,
    },
    {
      title: 'Services Provided',
      component: ShipmentServiceDetails,
      props: { shipmentType: shipment?.shipment_type, services },
      isVisible: true,
    },
    {
      title: 'Custom Clearance',
      component: ExportClearanceDetails,
      props: { shipment: shipment, form },
      isVisible: tradeType !== TRADE_TYPE_DOMESTIC,
    },
    {
      title: 'Remarks',
      component: Remarks,
      props: {},
      isVisible: true,
    },
  ];
};

const getRoutingLegs = (shipment: ShipmentValue | undefined) => {
  if (!shipment) return [];
  const pol = shipment.port_of_loading;
  const pod = shipment.port_of_discharge;
  const podTerminal = shipment.port_of_discharge_terminal;
  const polTerminal = shipment.port_of_loading_terminal;
  const pocd = shipment.place_of_carrier_delivery;
  const emptyYard = shipment?.ocean_transport_order?.empty_pickup_location;
  const pocdTerminal = shipment.place_of_carrier_delivery_terminal;
  const stuffingLocationCompany = shipment.stuffing_location?.company;
  const stuffingLocationAddress = shipment.stuffing_location?.company_address;
  const stuffingLocationPort = shipment.stuffing_location?.port;
  const destuffingLocationCompany = shipment.destuffing_location?.company;
  const destuffingLocationAddress = shipment.destuffing_location?.company_address;
  const destuffingLocationPort = shipment.destuffing_location?.port;
  const isOcean = shipment.freight_type === FREIGHT_TYPE_OCEAN;
  const isAir = shipment.freight_type === FREIGHT_TYPE_AIR;
  const isExport = shipment.trade_type === TRADE_TYPE_EXPORT;
  const isImport = shipment.trade_type === TRADE_TYPE_IMPORT;

  let routingLegs: any[] = [];

  if (isOcean && isExport) {
    routingLegs = [
      {
        origin: {
          _id: '1',
          location: emptyYard,
          tags: [],
        },
        destination: {
          _id: '2',
          address: stuffingLocationAddress,
          company: stuffingLocationCompany,
          location: stuffingLocationPort,
          tags: [],
        },
        sequence_number: 1,
        _id: '1',
        routing_type: 'pickup',
        mode_of_transit: MODE_OF_TRANSIT_ROAD,
      },
      {
        origin: {
          _id: '2',
          address: stuffingLocationAddress,
          company: stuffingLocationCompany,
          location: stuffingLocationPort,
          tags: [],
        },
        destination: {
          _id: '3',
          location: pol,
          terminal: polTerminal,
          tags: [],
        },
        sequence_number: 2,
        _id: '2',
        routing_type: 'pickup',
        mode_of_transit: MODE_OF_TRANSIT_ROAD,
      },
    ];
  } else if (isOcean && isImport) {
    routingLegs = [
      {
        origin: {
          _id: '1',
          location: pod?.id === pocd?.id ? pod : pocd,
          terminal: pod?.id === pocd?.id ? podTerminal : pocdTerminal,
          tags: [],
        },
        destination: {
          _id: '2',
          address: destuffingLocationAddress,
          company: destuffingLocationCompany,
          location: destuffingLocationPort,
          tags: [],
        },
        sequence_number: 1,
        _id: '1',
        routing_type: 'pickup',
        mode_of_transit: MODE_OF_TRANSIT_ROAD,
      },
    ];
  } else if (isAir && isExport) {
    routingLegs = [
      {
        origin: {
          _id: '1',
          location: null,
          tags: [],
        },
        destination: {
          _id: '2',
          location: pol,
          terminal: polTerminal,
          tags: [],
        },
        sequence_number: 1,
        _id: '1',
        routing_type: 'pickup',
        mode_of_transit: MODE_OF_TRANSIT_ROAD,
      },
    ];
  } else {
    routingLegs = [
      {
        origin: {
          _id: '1',
          location: pod,
          terminal: podTerminal,
          tags: [],
        },
        destination: {
          _id: '2',
          location: null,
          tags: [],
        },
        sequence_number: 1,
        _id: '1',
        routing_type: 'pickup',
        mode_of_transit: MODE_OF_TRANSIT_ROAD,
      },
    ];
  }
  return routingLegs;
};
export default function LinkRoadShipmentDrawer(props: LinkRoadShipmentDrawerProps) {
  const { shipment, onClose } = props;
  const [visible, setVisible] = useState(true);
  const [components, setComponents] = useState<any[]>();
  const [form] = Form.useForm();
  const cargoRef = useRef<{
    runValidation: () => boolean;
    getPayload: () => ProductOrderItemValue[];
  }>();
  const movementMode = Form.useWatch('movement_type', form);
  const loadType = Form.useWatch('load_type', form);
  const { 1: navigate } = useLocation();
  const [isNextActionDisabled, setIsNextActionDisabled] = useState<boolean>(true);
  const [selectedContainers, setSelectedContainers] = useState<ShipmentContainerValue[]>([]);
  const tableContainers: any[] = shipment?.shipment_containers || [];
  const [idWiseContainersData, setIdWiseContainersData] = useState<{
    [key: string]: ShipmentContainerValue;
  }>({});
  const sessionData = useSession();
  const client = useApolloClient();

  const [createShipment, { data, loading, error }] = useMutation(CREATE_SHIPMENT);

  const gridRef = useRef<GridOptions>();

  const [current, setCurrent] = React.useState(0);

  const onChangeStep = (next: number) => {
    if (current === 1 && !isNextActionDisabled) {
      setCurrent(next);
    } else if (current === 0) {
      setCurrent(next);
      setIsNextActionDisabled(true);
    }
  };

  const routingLegs = getRoutingLegs(shipment);
  const routing_nodes: RoutingNodesHashValue = {};
  const routing_legs = (routingLegs || []).map((rl: RoutingLegValue) => {
    if (rl?.origin?._id) routing_nodes[rl.origin._id] = rl.origin;
    if (rl?.destination?._id) routing_nodes[rl.destination._id] = rl.destination;
    return {
      ...omit(rl, 'global_carrier'),
      global_carrier_id: rl?.global_carrier?.id,
      origin: undefined,
      destination: undefined,
      origin_id: rl?.origin?._id,
      destination_id: rl?.destination?._id,
    };
  });

  const containerColumnNames = [
    'container_number',
    'container_type',
    'container_settings',
    'order_number',
    'seal_number',
    'total_gross_weight',
  ];

  const onSelectionContainerChanged = useCallback(
    () => {
      const rowCount = gridRef?.current?.api?.getSelectedNodes().length || 0;
      setIsNextActionDisabled(rowCount === 0);
      const selectedContainersIds =
        gridRef?.current?.api?.getSelectedNodes().map((node) => node.data.id) || [];
      setSelectedContainers((selectedContainersIds || []).map((id) => idWiseContainersData[id]));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  let movement_mode = MOVEMENT_MODE_CARGO_PICKUP_AND_DROP;
  if (shipment && shipment.freight_type && shipment.trade_type) {
    movement_mode = getRoadMovementMode({
      freightType: shipment?.freight_type,
      tradeType: shipment?.trade_type,
    });
  }

  useEffect(() => {
    setComponents(getComponents({ shipment: shipment, form, cargoRef: cargoRef }));
  }, [shipment, form, movementMode, loadType]);
  useEffect(() => {
    const preIdWiseContainersData = idWiseContainersData;
    (tableContainers || []).forEach((container: ShipmentContainerValue) => {
      preIdWiseContainersData[container.id] = container;
    });
    setIdWiseContainersData(preIdWiseContainersData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableContainers]);
  useEffect(() => {
    if (loading) return;
    if (error) {
      message.error(error.message);
    }
    if (data?.create_shipment) {
      message.success('Shipment created successfully');
      navigate(`~/view/shipment/${data?.create_shipment?.shipment?.id}/documents?first_load=1`);
    }
  }, [data, loading, error, navigate]);
  const items = [
    {
      title: 'Step 1',
      description: 'Select Containers or Cargos',
    },
    {
      title: 'Step 2',
      description: 'Create Shipment',
    },
  ];
  return (
    <Drawer
      open={visible}
      onClose={() => {
        onClose && onClose();
        setVisible(false);
      }}
      title={
        <div>
          <Row>
            <Col span={16}>
              <div
                style={{
                  color: '#353F52',
                  fontSize: '18px',
                  fontWeight: 500,
                  marginBottom: '10px',
                }}
              >
                <CompassOutlined />
                <span style={{ marginLeft: '5px' }}>
                  <b>Create Road Shipment</b>
                </span>
              </div>
            </Col>
          </Row>
          {tableContainers.length > 0 && (
            <Row>
              <Col span={16}>
                <Steps
                  items={items}
                  current={current}
                  size="small"
                  type="navigation"
                  onChange={onChangeStep}
                />
              </Col>
            </Row>
          )}
        </div>
      }
      width={'88%'}
      footer={
        (tableContainers?.length === 0 || (current === 1 && selectedContainers)) && shipment ? (
          <DrawerFooter
            onSave={() => {
              form.submit();
            }}
            onClose={() => setVisible(false)}
            saveText="Create Shipment"
          />
        ) : (
          <DrawerFooter
            saveText="Confirm & Go to Next Step"
            closeText="Cancel"
            disabled={isNextActionDisabled}
            onSave={() => onChangeStep(1)}
            onClose={() => {
              setVisible(false);
              onClose && onClose();
            }}
          />
        )
      }
    >
      <div className="steps-content">
        {current === 0 && (
          <>
            <div>
              {tableContainers?.length > 0 && (
                <ContainerGrid
                  tableHeading="Select container(s) to create shipment"
                  containers={
                    tableContainers
                      .filter((sc) => !!sc.container_number)
                      .map((sc) => ({ ...sc, order_number: shipment?.job_number })) || []
                  }
                  columnNames={containerColumnNames}
                  gridRef={gridRef}
                  onSelectionChanged={onSelectionContainerChanged}
                  renderFormatedData
                />
              )}
            </div>
          </>
        )}
        {(tableContainers?.length === 0 || (current === 1 && selectedContainers)) && shipment && (
          <>
            <Form
              initialValues={{
                shipment_type: SHIPMENT_TYPE_BACK_TO_BACK,
                freight_type: FREIGHT_TYPE_ROAD,
                trade_type: TRADE_TYPE_DOMESTIC,
                linked_shipment_id: shipment?.id,
                vehicle: {
                  ownership_type: ASSET_TYPE_OWN,
                },
                purchase_order_number: shipment?.purchase_order_number,
                is_external_quotation_number: shipment?.is_external_quotation_number,
                inquiry_option: shipment?.inquiry_option,
                quotation_number: shipment?.quotation_number,
                ocean_vessel: shipment?.ocean_vessel,
                carrier: shipment?.carrier,
                lfd_at_empty_return: convertToDayJs(shipment?.lfd_at_empty_return),
                estimated_time_of_arrival: convertToDayJs(shipment?.estimated_time_of_arrival),
                estimated_time_of_departure: convertToDayJs(shipment?.estimated_time_of_departure),
                voyage_number: shipment?.voyage_number,
                ocean_transport_order: {
                  valid_till_date: convertToDayJs(
                    shipment?.getOtoBookingFromShipment()?.valid_till_date
                  ),
                  booking_number: shipment?.getOtoBookingFromShipment()?.booking_number,
                  gate_close_date: convertToDayJs(
                    shipment?.getOtoBookingFromShipment()?.gate_close_date
                  ),
                },
                lfd_at_pod: convertToDayJs(shipment?.lfd_at_pod),
                port_of_loading: shipment?.port_of_loading,
                port_of_discharge: shipment?.port_of_discharge,
                cargo_type:
                  shipment?.load_type === LOAD_TYPE_FCL ? CARGO_TYPE_CONTAINER : CARGO_TYPE_BULK,
                cargos: [
                  {
                    outer_package_type: 'Box',
                    weight_unit: WEIGHT_UNIT_KGS,
                    volume_unit: VOLUME_UNIT_CBM,
                    outer_package_qty: 1,
                  },
                ],
                load_type:
                  shipment?.load_type === LOAD_TYPE_FCL ? LOAD_TYPE_FCL : LOAD_TYPE_FTL_BREAK_BULK,
                sales_agent: shipment?.sales_agent,
                movement_mode,
                routing_details: {
                  routing_legs: routing_legs,
                  routing_nodes: routing_nodes,
                },
                involved_branch_id: shipment?.involved_branch?.id,
                customer: shipment?.customer_company
                  ? {
                      party_company: shipment?.customer_company,
                      party_address: shipment?.customer_address,
                    }
                  : undefined,
                job_date: dayjs(),
                party: {
                  shipper: omit(shipment?.getShipmentPartyByName('shipper'), 'id'),
                  consignee: omit(shipment?.getShipmentPartyByName('consignee'), 'id'),
                },
                business_type: shipment?.business_type || BUSINESS_TYPE_DIRECT,
                shipment_containers: (selectedContainers || []).map((c) => omit(c, 'id')),
                shipping_bill_details: _filter(shipment?.shipment_custom_details, {
                  trade_type: 'export',
                }).map((sb: any) => ({
                  document_number: sb.custom_document_number,
                  document_date: convertToDayJs(sb.custom_document_date),
                  country: sb.country || sb.custom_clearance_location?.country_code,
                  custom_clearance_location: sb.custom_clearance_location,
                  trade_type: sb.trade_type,
                })),
                import_custom_details: _filter(shipment?.shipment_custom_details, {
                  trade_type: 'import',
                }).map((sb: any) => ({
                  document_number: sb.custom_document_number,
                  document_date: convertToDayJs(sb.custom_document_date),
                  country: sb.country || sb.custom_clearance_location?.country_code,
                  custom_clearance_location: sb.custom_clearance_location,
                  trade_type: sb.trade_type,
                })),
                shipment_invoices: (shipment?.shipment_invoices || []).map((si) => ({
                  document_number: si.invoice_number,
                  document_date: convertToDayJs(si.invoice_date),
                })),
              }}
              onFinish={(values) => {
                const error = Boolean(cargoRef?.current?.runValidation());
                if (error) return;
                values.shipment_containers = selectedContainers.map((c) => omit(c, 'id'));
                const shipmentFormData = {
                  ...values,
                  shipment_type: SHIPMENT_TYPE_BACK_TO_BACK,
                  cargos: cargoRef?.current?.getPayload(),
                  cargo_properties: undefined,
                };
                const payload = getGenericPayloadValue(shipmentFormData);
                createShipment({
                  variables: {
                    shipment: payload,
                    ...getConsignmentNotePayload(shipmentFormData),
                  },
                });
              }}
              form={form}
              layout="vertical"
              style={{
                height: '100%',
              }}
              requiredMark={true}
              onValuesChange={async (changedValues) => {
                const formValues: any = {};
                if (changedValues.hasOwnProperty('customer')) {
                  const branch_id = form.getFieldValue('involved_branch_id');
                  const customer = changedValues.customer;
                  if (branch_id && customer) {
                    const { response } = await getSalesPersonForCustomer(
                      customer?.party_company?.id,
                      branch_id,
                      sessionData,
                      client
                    );
                    if (response) {
                      formValues['sales_agent'] = response;
                    }
                  }
                }
                if (Object.keys(formValues).length > 0) form.setFieldsValue(formValues);
              }}
            >
              <FormLayout
                form={form}
                hideHeaderFooter={true}
                hideComponents={{
                  container_details: true,
                }}
                components={components}
              />
            </Form>
          </>
        )}
      </div>
    </Drawer>
  );
}
