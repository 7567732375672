import React, { useEffect, useRef, useState } from 'react';
import { createRoot } from 'react-dom/client';

import { useLocation } from 'wouter';

import { UniversalSearch } from '@shipmnts/pixel-hub';
import { useSession } from 'common/utils/SessionContext';
import { Navbar, NAVBAR_HEIGHT, NAVBAR_SEARCH_DIV_ID } from 'shipmnts_navigation';
import { axiosInstance as axios } from 'common/utils/axiosDefaults';
import { useApolloClient } from '@apollo/client';
import { clearAndReload } from 'common/utils/applicationSettings';
import { setSentryScope } from 'common/utils/thirdPartyIntegrations';
import { ADMINISTRATION_ACCESS_LEVELS } from 'common';
import CopilotChatDrawer from './Copilot/CopilotChatDrawer';

// const { Header } = Layout;
// const header_height = 50;

function ApplicationHeader(props) {
  const { setNavJson } = props;
  const session = useSession();
  const apolloClient = useApolloClient();
  const { 1: navigate } = useLocation();
  const ctrlKeyDown = useRef(false);

  const [isCopilotOpen, setIsCopilotOpen] = useState(false);

  useEffect(() => {
    setSentryScope(session);
    const user = {
      id: session?.id,
      firstName: session?.first_name,
      lastName: session?.last_name,
      email: session?.email,
      company_account: session?.company_account,
    };
    new Navbar({
      rootNodeId: 'header',
      user: user,
      appName: 'operations',
      authClientId: process.env.WORKOS_CLIENT_ID || '',
      devMode: process.env.WORKOS_DEVMODE === 'true' || false,
      reload: clearAndReload,
      links: {
        profile_and_preference: `${process.env.SHIPMNTS_MANAGE_URL}`,
        user_management: ADMINISTRATION_ACCESS_LEVELS.includes(session?.user_level)
          ? process.env.SHIPMNTS_MANAGE_URL
          : null,
      },
      axiosInstance: axios,
      changeRoute: (route, routeOptions, queryParams) => {
        navigate(`${route}?${queryParams}`);
      },
      getHubspotToken: async () => {
        try {
          const response = await axios.get(
            `${process.env.NETWORK_API_URL}/user_accounts/get_hubspot_token`
          );
          return response.data.token;
        } catch (e) {
          console.error(e);
        }
        return;
      },
      callback: (navjson) => {
        setNavJson(navjson);
      },
      configVars: {
        shipmntsWebUrl: process.env.SHIPMNTS_WEB_URL,
        operationsUrl: process.env.OPERATIONS_URL,
        firebaseApplicationConfig: JSON.parse(process.env.FIREBASE_APPLICATION_CONFIG || '{}'),
        alexUrl: process.env.ALEX_URL,
        workspace_product_fruit_code: process.env.WORKSPACE_PRODUCT_FRUIT_CODE,
        firebaseVapidKey:
          process.env.NODE_ENV !== 'development' ? process.env.FIREBASE_VAPID_KEY : '',
      },
      iconTogglersConfig: {
        activity: {
          route: '/inbox',
        },
        copilot: {
          onClick: () => setIsCopilotOpen((prev) => !prev),
        },
      },
      appendSearchBox: () => {
        const container = document.getElementById(NAVBAR_SEARCH_DIV_ID);

        const root = createRoot(container);
        root.render(
          <UniversalSearch ENV={{ ALEX_URL: process.env.ALEX_URL }} client={apolloClient} />
        );
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [session]);

  useEffect(() => {
    const ctrlKeyDownHandler = (e) => {
      if (e.key === 'Control') {
        e.preventDefault();
        ctrlKeyDown.current = true;
      }
    };

    const ctrlKeyUpHandler = (e) => {
      if (e.key === 'Control') {
        e.preventDefault();
        ctrlKeyDown.current = false;
      }
    };

    window.addEventListener('keydown', ctrlKeyDownHandler);
    window.addEventListener('keyup', ctrlKeyUpHandler);

    return () => {
      window.removeEventListener('keydown', ctrlKeyDownHandler);
      window.removeEventListener('keyup', ctrlKeyUpHandler);
    };
  }, []);

  return (
    <>
      <CopilotChatDrawer isOpen={isCopilotOpen} setIsOpen={setIsCopilotOpen} />
      <div style={{ marginBottom: NAVBAR_HEIGHT }} id="header" />
    </>
  );
}

export default ApplicationHeader;
