import React, { useCallback } from 'react';
import { BaseTable, SearchDocTypeEditor } from '@shipmnts/pixel-hub';
import { compact as _compact } from 'lodash';
import { Column } from 'operations/models/Report';
import { useBillingReportContext as useStockSummaryReportContext } from '../BillingReportProvider';
import { STOCK_SUMMARY_REPORT } from '../BillingFilterHelpers';

const StockSummaryReportTable = () => {
  const { reportRows } = useStockSummaryReportContext();

  const getColumns = useCallback(() => {
    const columnDefs: Column[] = _compact([
      {
        headerName: 'Product Code',
        field: 'wms_stock_summary_product_code',
        colId: 'wms_stock_summary_product_code',
        columnType: 'String',
      },
      {
        headerName: 'Product Name',
        field: 'wms_stock_summary_product_name',
        colId: 'wms_stock_summary_product_name',
        columnType: 'String',
      },
      {
        headerName: 'UoM',
        field: 'wms_stock_summary_packing_type',
        colId: 'wms_stock_summary_packing_type',
        columnType: 'String',
      },
      {
        headerName: 'Lot #',
        field: 'wms_stock_summary_lot_number',
        colId: 'wms_stock_summary_lot_number',
        columnType: 'String',
      },
      {
        headerName: 'Qty',
        field: 'wms_stock_summary_qty',
        colId: 'wms_stock_summary_qty',
        columnType: 'Float',
        editable: false,
      },
      {
        headerName: 'Volume',
        field: 'wms_stock_summary_gross_volume',
        colId: 'wms_stock_summary_gross_volume',
        columnType: 'Float',
      },
    ]);
    return columnDefs;
    // eslint-disable-next-line
  }, []);
  const allColumnsDefs = getColumns() || [];
  const components = {
    SearchDocTypeEditor,
  };
  return (
    <div style={{ margin: '10px', padding: '0 10px' }}>
      <BaseTable
        reportName={STOCK_SUMMARY_REPORT}
        rowData={reportRows?.data || []}
        height={'70vh'}
        columns={allColumnsDefs}
        reportConfig={{
          components: components,
        }}
      />
    </div>
  );
};

export default StockSummaryReportTable;
