import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { gql, useLazyQuery } from '@apollo/client';
import { Input, Modal, Spin, Form } from '@shipmnts/pixel-hub';
import { errorMessageHandlerGraphQL } from 'common';
import GenAIInputWrapper from 'operations/modules/shipment/components/NewShipmentForm/FormFiller/GenAIInputWrapper';

const SEARCH_OTO_BY_BOOKING_NUMBER = gql`
  query search_oto_by_booking_number($booking_number: String!) {
    search_oto_by_booking_number(booking_number: $booking_number) {
      id
      booking_number
    }
  }
`;

interface BookingOrderNumberProps {
  label: string;
  form?: any;
  disabled?: boolean;
  required?: boolean;
}

const BookingOrderNumber = React.memo(function BookingOrderNumber(
  props: BookingOrderNumberProps
): JSX.Element {
  const { label, form, disabled = false, required = true } = props;
  const [isDuplicateModalVisible, setDuplicateModalVisible] = useState<boolean>(false);
  const [searchOto, { loading, data, error }] = useLazyQuery(SEARCH_OTO_BY_BOOKING_NUMBER);

  useEffect(() => {
    if (data && data.search_oto_by_booking_number?.length > 0) {
      setDuplicateModalVisible(true);
    }
  }, [data]);

  const checkOtoPresence = useCallback(
    (booking_number?: string) => {
      if (!booking_number) return;

      const variables = {
        booking_number,
      };
      searchOto({ variables });
    },
    [searchOto]
  );

  const retryCheckOtoPresence = useCallback(() => {
    const field_name = 'booking_number';
    checkOtoPresence(form?.getFieldValue(field_name));
  }, [checkOtoPresence, form]);

  const retryHelp = useMemo(
    () =>
      error
        ? {
            help: errorMessageHandlerGraphQL(error, retryCheckOtoPresence),
          }
        : {},
    [error, retryCheckOtoPresence]
  );

  const openOto = useCallback(
    (oto_id: string) => {
      form?.setFieldValue('booking_number', null);
      window.open(`${process.env.OPERATIONS_URL}/view/booking_order/${oto_id}`);
    },
    [form]
  );

  return (
    <>
      <Spin spinning={loading}>
        <Form.Item
          name="booking_number"
          label={label}
          required={required}
          rules={[
            {
              required,
            },
          ]}
          {...retryHelp}
        >
          <GenAIInputWrapper fieldKey={'booking_number'}>
            <Input onBlur={(e) => checkOtoPresence(e.target.value.trim())} disabled={disabled} />
          </GenAIInputWrapper>
        </Form.Item>
      </Spin>
      <Modal
        title="Duplicate Booking(s) Found"
        open={isDuplicateModalVisible}
        maskClosable={true}
        closable={true}
        footer={null}
        onCancel={() => setDuplicateModalVisible(false)}
      >
        <p>Go to duplicate Booking(s)</p>
        <ul>
          {(data?.search_oto_by_booking_number || []).map(
            (oto: { id: string; booking_number: string }, index: string) => {
              return (
                <li key={index}>
                  <span onClick={() => openOto(oto.id)}>
                    <u style={{ cursor: 'pointer' }}>{oto?.booking_number}</u>
                  </span>
                </li>
              );
            }
          )}
        </ul>
      </Modal>
    </>
  );
});

export default BookingOrderNumber;
