import React, { useEffect } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { UPDATE_SHIPMENT_CUSTOM_DETAIL } from 'operations/graphql/shipmentCustomDetail';
import Shipment, { ShipmentValue } from 'operations/models/Shipment';
import { ShipmentPartyValue } from 'operations/models/ShipmentParty';
import { ShipmentCustomDetailValue } from 'operations/models/ShipmentCustomDetail';
import {
  message,
  Row,
  Col,
  Drawer,
  Input,
  InputNumber,
  Checkbox,
  Select,
  Form,
  Modal,
  DatePicker,
} from '@shipmnts/pixel-hub';
import {
  TRADE_TYPE_EXPORT,
  TRADE_TYPE_IMPORT,
  BILL_OF_ENTRY_TYPE,
  SHIPPING_BILL_TYPE,
  SEZ_BILL,
  INBOND_BILL,
  EXBOND_BILL,
  REWARD_SCHEMES,
} from 'operations/modules/shipment/constants';
import { pick as _pick, get as _get } from 'lodash';
import { convertToDayJs, convertDatesTounix } from '@shipmnts/pixel-hub';
import { DrawerFooter } from '@shipmnts/pixel-hub';
import { useApplicationContentContext } from 'common';
import { useSession } from 'common';
import LinkedShipmentInvoice from './LinkedShipmentInvoice';
import { FETCH_PRODUCT_ORDER_ITEMS_FROM_SHIPMENT } from 'operations/modules/orderManagement/graphql/productOrder';
import { GlobalSearch } from '@shipmnts/pixel-hub';

const FILING_TYPE = [
  {
    key: 'Normal',
    value: 'Normal',
  },
  {
    name: 'Advance',
    value: 'Advance',
  },
  {
    name: 'Prior',
    value: 'Prior',
  },
];

const date_fields = [
  'custom_document_date',
  'due_date_to_pay_duty',
  'filing_valid_till',
  'bank_realisation_cutoff_date',
  'transhipment_permission_date',
  'rewarehousing_certificate_date',
  'warehouse_be_date',
  'import_duty_free_date',
];

const getCustomDetailPayload = (
  values: ShipmentCustomDetailValue,
  shipment: ShipmentValue,
  id: string,
  shipment_invoices: any
): any => {
  const date_fields_unix = convertDatesTounix(values, date_fields);

  // Add the date field to following list if you are saving date as a string in backend
  const date_fields_str = [
    'custom_document_date',
    'transhipment_permission_date',
    'rewarehousing_certificate_date',
    'warehouse_be_date',
    'import_duty_free_date',
  ];
  const variables: {
    shipment_custom_detail: ShipmentCustomDetailValue;
    id?: string;
  } = { shipment_custom_detail: { ...values }, id: id };

  date_fields.forEach((field) => {
    if (!date_fields_str.includes(field))
      (variables.shipment_custom_detail as Record<string, any>)[field] = date_fields_unix[field];
  });

  variables.shipment_custom_detail.custom_clearance_location_id =
    values.custom_clearance_location?.id || null;

  variables.shipment_custom_detail.transhipment_permission_no =
    values?.transhipment_permission_no || null;
  variables.shipment_custom_detail.transhipment_permission_date =
    values?.transhipment_permission_date || null;
  variables.shipment_custom_detail.rewarehousing_certificate_no =
    values?.rewarehousing_certificate_no || null;
  variables.shipment_custom_detail.rewarehousing_certificate_date =
    values?.rewarehousing_certificate_date || null;
  variables.shipment_custom_detail.warehouse_be_no = values?.warehouse_be_no || null;
  variables.shipment_custom_detail.current_queue = values?.current_queue || null;
  variables.shipment_custom_detail.warehouse_be_date = values?.warehouse_be_date || null;
  variables.shipment_custom_detail.import_duty_free_no = values?.import_duty_free_no || null;
  variables.shipment_custom_detail.import_duty_free_date = values?.import_duty_free_date || null;
  delete variables.shipment_custom_detail.custom_clearance_location;

  variables.shipment_custom_detail.warehouse_location_id = values.warehouse_location?.id || null;
  variables.shipment_custom_detail.shipment_invoices = shipment_invoices || null;

  delete variables.shipment_custom_detail.warehouse_location;

  return variables;
};

export default function EditShipmentCustomDetail(props: {
  shipment: ShipmentValue;
  shipmentCustomDetail: ShipmentCustomDetailValue;
  onSuccess?: () => void;
  onClose?: () => void;
}) {
  const { shipmentCustomDetail, onSuccess, onClose } = props;
  const shipment = Shipment.create(props.shipment);
  const { data: spoiData } = useQuery(FETCH_PRODUCT_ORDER_ITEMS_FROM_SHIPMENT, {
    variables: { id: shipment.id },
  });
  let isInvoiceLinkedWithOtherCustoms = false;
  spoiData?.shipment?.commercial_invoices?.forEach((ci: any) => {
    if (!!ci.shipment_custom_detail) {
      if (ci.shipment_custom_detail.id !== shipmentCustomDetail.id)
        isInvoiceLinkedWithOtherCustoms = true;
    }
  });

  const [updateShipmentCustomDetail, { loading, data, error }] = useMutation(
    UPDATE_SHIPMENT_CUSTOM_DETAIL,
    {
      fetchPolicy: 'no-cache',
    }
  );
  const tradeType = shipmentCustomDetail.trade_type || shipment.trade_type;
  useEffect(() => {
    if (error) {
      message.error(`Error Occured while updating custom detail. ${error}`);
      return;
    }
    if (data?.update_shipment_custom_detail) {
      message.success('Custom Detail updated successfully!');
      if (onSuccess) onSuccess();
    }
  }, [data, error, onSuccess]);

  const getClearanceLocation = (trade_type: string) => {
    if (trade_type === TRADE_TYPE_EXPORT) {
      return shipment.place_of_carrier_receipt || shipment.port_of_loading;
    } else if (trade_type === TRADE_TYPE_IMPORT) {
      return shipment.place_of_carrier_delivery || shipment.port_of_discharge;
    }
    return null;
  };
  const getFormInitialData = () => {
    const initialCustomDetail = shipmentCustomDetail;

    const customCountry =
      initialCustomDetail.country ||
      (initialCustomDetail.trade_type &&
        getClearanceLocation(initialCustomDetail.trade_type)?.country_code);

    let customCountryLocation = initialCustomDetail.custom_clearance_location;
    if (
      !customCountryLocation &&
      initialCustomDetail.trade_type &&
      initialCustomDetail.country ===
        getClearanceLocation(initialCustomDetail.trade_type)?.country_code
    )
      customCountryLocation = getClearanceLocation(initialCustomDetail.trade_type);

    if (!initialCustomDetail) return {};
    const formPayload: any = {
      ..._pick(initialCustomDetail, [
        'custom_document_number',
        'trade_type',
        'country',
        'filing_type',
        'custom_clearance_location',
        'cargo_value',
        'insurance_value',
        'freight_value',
        'cargo_gross_weight',
        'cargo_net_weight',
        'cargo_volume',
        'currency',
        'exchange_rate',
        'duty_payment_by',
        'duty_amount',
        'assessable_value',
        'duty_benefits',
        'reward_amount',
        'drawback_bank_account',
        'forex_bank_account',
        'cif_value',
        'fob_value',
        'invoice_value',
        'bill_of_entry_type',
        'shipping_bill_type',
        'transhipment_permission_no',
        'transhipment_permission_date',
        'rewarehousing_certificate_no',
        'rewarehousing_certificate_date',
        'warehouse_location',
        'warehouse_be_no',
        'current_queue',
        'warehouse_be_date',
        'import_duty_free_no',
        'import_duty_free_date',
        'stamp_duty',
        'shipment_invoices',
      ]),
      custom_clearance_location: customCountryLocation,
      currency: initialCustomDetail.currency || sessionData?.company_account?.default_currency,
      country: customCountry,
      reward_scheme: initialCustomDetail?.reward_scheme || [],
    };

    date_fields.forEach((fieldname: string) => {
      if (_get(initialCustomDetail, fieldname))
        formPayload[fieldname] = convertToDayJs(_get(initialCustomDetail, fieldname));
    });
    return formPayload;
  };
  const onFinish = async (values: any) => {
    const shipment_invoice = values.shipment_invoices || [];
    const new_shipment_invoice = shipment_invoice.map((el: any) =>
      _pick(el, ['id', 'shipment_custom_detail_id'])
    );

    const new_shipment_invoices_ids = new_shipment_invoice.map((ccd: any) => ccd.id);
    shipmentCustomDetail?.shipment_invoices?.forEach((ccd) => {
      if (!new_shipment_invoices_ids.includes(ccd.id)) {
        new_shipment_invoice.push({ id: ccd.id, shipment_custom_detail_id: null });
      }
    });

    const variables = getCustomDetailPayload(
      values,
      shipment,
      shipmentCustomDetail.id,
      new_shipment_invoice
    );

    if (isInvoiceLinkedWithOtherCustoms && new_shipment_invoice.length > 0) {
      Modal.confirm({
        title: 'Confirm',
        content: `This invoice might be linked to any other customs document of this shipment, which will get de-linked on linking the invoice here. Are you sure you want to link this invoice to this document?`,
        okText: 'Ok',
        okType: 'danger',
        onOk: () => {
          updateShipmentCustomDetail({
            variables: { ...variables },
          });
        },
        cancelText: 'Cancel',
        onCancel: () => {
          if (onClose) onClose();
        },
      });
    } else {
      updateShipmentCustomDetail({
        variables: { ...variables },
      });
    }
  };
  const document_type = tradeType === TRADE_TYPE_EXPORT ? 'Export Customs' : 'Import Customs';

  const shipment_parties: string[] = [];

  (shipment.shipment_parties || []).forEach((party: ShipmentPartyValue) => {
    if (party?.party_company && !shipment_parties.includes(party.party_company.registered_name))
      shipment_parties.push(party.party_company.registered_name);
  });

  const [form] = Form.useForm();
  const { config_data } = useApplicationContentContext();
  const sessionData = useSession();
  const { currencies, list_of_countries } = config_data;
  const initialValues = getFormInitialData();
  return (
    <div style={{ alignItems: 'center', minWidth: '300px' }}>
      <Drawer
        title={<div>Edit {document_type} Detail</div>}
        placement="right"
        open={true}
        onClose={onClose}
        styles={{ header: { padding: '16px' } }}
        size="large"
        footer={
          <DrawerFooter
            saveText="Update"
            onClose={() => {
              if (onClose) onClose();
            }}
            loading={loading}
            disabled={loading}
            onSave={form.submit}
          ></DrawerFooter>
        }
      >
        <Form
          layout="vertical"
          form={form}
          requiredMark={false}
          onFinish={onFinish}
          initialValues={initialValues}
        >
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col span={12}>
              <Form.Item
                className="custom-form-item"
                name="custom_document_number"
                label={document_type + ' Number'}
              >
                <Input type={'text'} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                className="custom-form-item"
                name="custom_document_date"
                label={document_type + ' Date'}
              >
                <DatePicker style={{ width: '100%' }} />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col span={8}>
              <Form.Item dependencies={['custom_clearance_location', 'bill_of_entry_type']}>
                {({ getFieldValue, setFieldsValue }) => {
                  return (
                    <Form.Item
                      className="custom-form-item"
                      name="country"
                      label="Custom Clearance Country"
                      dependencies={['custom_clearance_location']}
                    >
                      <Select
                        onChange={(val) => {
                          if (getFieldValue('custom_clearance_location')?.country_code !== val) {
                            setFieldsValue({ custom_clearance_location: undefined });
                          }
                        }}
                        showSearch={true}
                        filterOption={(input, option) => {
                          return (
                            `${option?.children}`.toLowerCase().indexOf(input?.toLowerCase()) >= 0
                          );
                        }}
                      >
                        {list_of_countries.map((country: { code: string; name: string }) => (
                          <Select.Option key={country.code} value={country.code}>
                            {country.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  );
                }}
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item dependencies={['country']}>
                {({ getFieldValue }) => {
                  return (
                    <Form.Item
                      className="custom-form-item"
                      name="custom_clearance_location"
                      label="Custom Clearance Location"
                      dependencies={['country']}
                    >
                      <GlobalSearch
                        doc_type="Global::Location"
                        searchProps={{
                          is_customs_location: true,
                          country_code: [getFieldValue('country')],
                        }}
                      />
                    </Form.Item>
                  );
                }}
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item className="custom-form-item" name="current_queue" label={'Current Queue'}>
                <Input />
              </Form.Item>
            </Col>
          </Row>
          {tradeType === TRADE_TYPE_IMPORT && (
            <>
              <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                <Col span={12}>
                  <Form.Item noStyle={true} dependencies={['country']}>
                    {({ getFieldValue }) => {
                      const countryCode = getFieldValue('country');
                      if (countryCode === 'IN') {
                        return (
                          <Form.Item
                            className="custom-form-item"
                            name="bill_of_entry_type"
                            label="Bill Of Entry Type"
                          >
                            <Select>
                              {BILL_OF_ENTRY_TYPE.map((option, index) => (
                                <Select.Option key={(option || '') + index} value={option}>
                                  {option}
                                </Select.Option>
                              ))}
                            </Select>
                          </Form.Item>
                        );
                      }
                      return <></>;
                    }}
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item noStyle={true} dependencies={['country', 'bill_of_entry_type']}>
                    {({ getFieldValue }) => {
                      const bill_of_entry_type = getFieldValue('bill_of_entry_type');
                      if (
                        bill_of_entry_type === INBOND_BILL ||
                        bill_of_entry_type === EXBOND_BILL
                      ) {
                        return (
                          <Form.Item
                            className="custom-form-item"
                            name="warehouse_location"
                            label="Warehouse Location"
                            dependencies={['country']}
                          >
                            <GlobalSearch
                              doc_type="Global::Location"
                              searchProps={{
                                is_customs_location: true,
                                country_code: [getFieldValue('country')],
                              }}
                            />
                          </Form.Item>
                        );
                      }
                      return <></>;
                    }}
                  </Form.Item>
                </Col>
              </Row>

              <Form.Item noStyle={true} dependencies={['bill_of_entry_type']}>
                {({ getFieldValue }) => {
                  const bill_of_entry_type = getFieldValue('bill_of_entry_type');
                  if (bill_of_entry_type === SEZ_BILL) {
                    return (
                      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                        <Col span={12}>
                          <Form.Item
                            className="custom-form-item"
                            name="transhipment_permission_no"
                            label={'Transhipment Permission #'}
                          >
                            <Input />
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item
                            className="custom-form-item"
                            name="transhipment_permission_date"
                            label={'Transhipment Permission Date'}
                          >
                            <DatePicker style={{ width: '100%' }} />
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item
                            className="custom-form-item"
                            name="rewarehousing_certificate_no"
                            label={'RWC #'}
                          >
                            <Input />
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item
                            className="custom-form-item"
                            name="rewarehousing_certificate_date"
                            label={'RWC Date'}
                          >
                            <DatePicker style={{ width: '100%' }} />
                          </Form.Item>
                        </Col>
                      </Row>
                    );
                  } else if (bill_of_entry_type === EXBOND_BILL) {
                    return (
                      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                        <Col span={12}>
                          <Form.Item
                            className="custom-form-item"
                            name="warehouse_be_no"
                            label={'Warehouse BE #'}
                          >
                            <Input />
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item
                            className="custom-form-item"
                            name="warehouse_be_date"
                            label={'Warehouse BE Date'}
                          >
                            <DatePicker style={{ width: '100%' }} />
                          </Form.Item>
                        </Col>
                      </Row>
                    );
                  }
                  return <></>;
                }}
              </Form.Item>
            </>
          )}
          {tradeType === TRADE_TYPE_EXPORT && (
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              <Col span={12}>
                <Form.Item noStyle={true} dependencies={['country']}>
                  {({ getFieldValue }) => {
                    const countryCode = getFieldValue('country');
                    if (countryCode === 'IN') {
                      return (
                        <Form.Item
                          className="custom-form-item"
                          name="shipping_bill_type"
                          label="Shipping Bill Type"
                        >
                          <Select>
                            {SHIPPING_BILL_TYPE.map((option, index) => (
                              <Select.Option key={(option || '') + index} value={option}>
                                {option}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                      );
                    }
                    return <></>;
                  }}
                </Form.Item>
              </Col>
            </Row>
          )}
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col span={8}>
              <Form.Item className="custom-form-item" name="currency" label="Currency">
                <Select>
                  {currencies.map((currency: { key: string; value: string }) => (
                    <Select.Option key={currency.key} value={currency.key}>
                      {currency.value}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item className="custom-form-item" name="exchange_rate" label="Exchange Rate">
                <InputNumber min={0} style={{ width: '100%' }} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item className="custom-form-item" name="filing_type" label="Filing Type">
                <Select>
                  {FILING_TYPE.map((option, index) => (
                    <Select.Option key={(option.key || '') + index} value={option.value}>
                      {option.value}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col span={8}>
              <Form.Item className="custom-form-item" name="cargo_value" label="Cargo Value">
                <InputNumber min={0} style={{ width: '100%' }} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item className="custom-form-item" name="freight_value" label="Freight Value">
                <InputNumber min={0} style={{ width: '100%' }} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                className="custom-form-item"
                name="insurance_value"
                label="Insurance Value"
              >
                <InputNumber min={0} style={{ width: '100%' }} />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col span={8}>
              <Form.Item
                className="custom-form-item"
                name="cargo_gross_weight"
                label="Cargo Gross Weight"
              >
                <InputNumber min={0} style={{ width: '100%' }} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                className="custom-form-item"
                name="cargo_net_weight"
                label="Cargo Net Weight"
              >
                <InputNumber min={0} style={{ width: '100%' }} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item className="custom-form-item" name="cargo_volume" label="Cargo Volume">
                <InputNumber min={0} style={{ width: '100%' }} />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col span={8}>
              <Form.Item className="custom-form-item" name="invoice_value" label="Invoice Value">
                <InputNumber min={0} style={{ width: '100%' }} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item className="custom-form-item" name="fob_value" label="FOB Value">
                <InputNumber min={0} style={{ width: '100%' }} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item className="custom-form-item" name="cif_value" label="CIF Value">
                <InputNumber min={0} style={{ width: '100%' }} />
              </Form.Item>
            </Col>
          </Row>

          {tradeType === TRADE_TYPE_IMPORT && (
            <>
              <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                <Col span={8}>
                  <Form.Item
                    className="custom-form-item"
                    name="duty_payment_by"
                    label="Duty Payment By"
                  >
                    <Select>
                      {shipment_parties.map((party: string, index: number) => (
                        <Select.Option key={index} value={party}>
                          {party}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item className="custom-form-item" name="duty_amount" label="Duty Amount">
                    <InputNumber min={0} style={{ width: '100%' }} />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    className="custom-form-item"
                    name="due_date_to_pay_duty"
                    label="Due Date to Pay Duty"
                  >
                    <DatePicker style={{ width: '100%' }} showTime={true} />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                <Col span={8}>
                  <Form.Item
                    className="custom-form-item"
                    name="duty_free"
                    valuePropName="checked"
                    initialValue={shipmentCustomDetail?.duty_free ? true : false}
                  >
                    <Checkbox checked={shipmentCustomDetail?.duty_free ? true : false}>
                      Duty Free
                    </Checkbox>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    className="custom-form-item"
                    name="assessable_value"
                    label="Assessable Value"
                  >
                    <InputNumber min={0} style={{ width: '100%' }} />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item className="custom-form-item" name="stamp_duty" label="Stamp Duty">
                    <InputNumber min={0} style={{ width: '100%' }} />
                  </Form.Item>
                </Col>
              </Row>

              <Form.Item dependencies={['duty_free', 'country']}>
                {({ getFieldValue }) => {
                  const isChecked = getFieldValue('duty_free');
                  const countryCode = getFieldValue('country');
                  if (isChecked && countryCode === 'IN') {
                    return (
                      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                        <Col span={12}>
                          <Form.Item
                            className="custom-form-item"
                            name="import_duty_free_no"
                            label={'IDF#'}
                          >
                            <Input />
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item
                            className="custom-form-item"
                            name="import_duty_free_date"
                            label={'IDF Date'}
                          >
                            <DatePicker style={{ width: '100%' }} />
                          </Form.Item>
                        </Col>
                      </Row>
                    );
                  }
                  return <></>;
                }}
              </Form.Item>
            </>
          )}

          {tradeType === TRADE_TYPE_EXPORT && (
            <>
              <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                <Col span={12}>
                  <Form.Item
                    className="custom-form-item"
                    name="filing_valid_till"
                    label="Filing Valid Till"
                  >
                    <DatePicker style={{ width: '100%' }} />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    className="custom-form-item"
                    name="bank_realisation_cutoff_date"
                    label="Bank Realisation Cutoff"
                  >
                    <DatePicker style={{ width: '100%' }} />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                <Col span={24}>
                  <Form.Item
                    className="custom-form-item"
                    name="reward_scheme"
                    label="Reward Scheme"
                  >
                    <Select mode="tags">
                      {REWARD_SCHEMES.map((option) => (
                        <Select.Option key={option.name} value={option.value}>
                          {option.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                <Col span={12}>
                  <Form.Item
                    className="custom-form-item"
                    name="duty_benefits"
                    label="Duty Benefits"
                  >
                    <InputNumber min={0} style={{ width: '100%' }} />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    className="custom-form-item"
                    name="reward_amount"
                    label="Reward Amount"
                  >
                    <InputNumber min={0} style={{ width: '100%' }} />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} style={{ marginBottom: '20px' }}>
                <Col span={12}>
                  <Form.Item
                    className="custom-form-item"
                    name="drawback_bank_account"
                    label="Drawback Bank Account"
                  >
                    <Input type={'text'} />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    className="custom-form-item"
                    name="forex_bank_account"
                    label="Forex Bank Account"
                  >
                    <Input type={'text'} />
                  </Form.Item>
                </Col>
              </Row>
            </>
          )}

          {shipment?.trade_type === TRADE_TYPE_EXPORT &&
            (sessionData.company_account.primary_business === 'manufacturer' ||
              sessionData.company_account.primary_business === 'trader') && (
              <>
                <h2 style={{ paddingTop: '20px' }}>Link Commercial Invoices</h2>
                <Form.Item name={['shipment_invoices']} style={{ paddingBottom: '50px' }}>
                  <LinkedShipmentInvoice
                    form={form}
                    shipmentCustomDetail={shipmentCustomDetail}
                    spoiData={spoiData}
                  />
                </Form.Item>
              </>
            )}
        </Form>
      </Drawer>
    </div>
  );
}
