import { ActivityLayout, Layout, THREAD_TYPES } from '@shipmnts/pixel-hub';
import React from 'react';

const { Content } = Layout;
const InboxTab = ({ id }: { id: string }) => {
  if (!id) {
    return <></>;
  }

  return (
    <Content style={{ padding: 0, height: '750px' }}>
      <ActivityLayout
        reference={{
          reference_type: 'SalesHub::InquiryGlobal',
          reference_id: id,
        }}
        thread_type={THREAD_TYPES.CHAT}
        showBreadCrumb={false}
      />
    </Content>
  );
};

export default InboxTab;
