import { GridOptions, ColumnApi } from '@ag-grid-community/core';
import React, { useState, useRef, useEffect, useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { useApolloClient } from '@apollo/client';
import { map as _map, pick as _pick, isArray as _isArray } from 'lodash';
import { Column, Variables, IndexableFieldType } from 'operations/models/Report';
import { VesselValue } from 'operations/models/Vessel';
import { ContainerRequestValue } from 'operations/models/ContainerRequest';
import { VesselSearch, exportExcelStyles } from 'common';
import { fields } from 'operations/modules/reports/components/OTOReports/OTOReportFields';
import { ALLOCATE_OTO_REPORT } from 'operations/modules/reports/graphql/otoReport';
import {
  renderContainerQuantityAndType,
  renderCommodity,
  renderContainerSettings,
} from 'operations/modules/reports/oldCellRenderers';
import { OTOReportData } from 'operations/modules/reports/components/OTOReports/OTOReports';
import OTOBookingNumber from 'operations/modules/reports/components/OTOReports/OTOBookingNumber';
import AllocateContainerInputNumber from 'operations/modules/shipment/components/NewShipmentForm/AllocateContainerInputNumber';
import { CarrierValue } from 'operations/models/Carrier';
import { ShipmentContainerValue } from 'operations/models/ShipmentContainer';
import {
  BaseTable,
  Flex,
  GlobalSearch,
  InfoCircleOutlined,
  Tooltip,
  Col,
  Row,
} from '@shipmnts/pixel-hub';
import { FieldDisableReason } from 'operations/commonTypeDefs';

export type PartialVesselValue = Omit<VesselValue, 'is_frequent'>;

const reportFields = fields as IndexableFieldType<Column>;

const renderOTOData = (data: Array<OTOReportData> | undefined) => {
  const response = (data || []).map((row: OTOReportData) => ({
    ...row,
    requested_quantity_and_type: renderContainerQuantityAndType(
      row.container_requests || [],
      'quantity'
    ),
    allocation_pending_quantity_and_type: renderContainerQuantityAndType(
      row.container_requests || [],
      'quantity_unfulfilled'
    ),
    status_text: `${row.status} ${row.status_time_duration_in_words || ''}`,
    created_by: `${row.created_by?.first_name || ''} ${row.created_by?.last_name || ''}`,
    voyage_number_text: `${row?.global_carrier?.name || ''} | ${
      row?.routing_legs?.[0]?.vessel?.name || ''
    } | ${row?.routing_legs?.[0]?.voyage_number || ''}`,
    ordered_by: row?.sales_person?.name || '',
    commodity: renderCommodity(row.cargos || []),
    container_settings: renderContainerSettings(row.container_requests || []),
  }));
  return response;
};

const components = {
  allocateContainerInputNumber: AllocateContainerInputNumber,
  OTOBookingNumber,
};

const BookingReport = observer(function AllocateOTOReport(props: {
  filters: {
    port_of_loading_id: string | undefined;
    port_of_discharge_id: string | undefined;
    carrier: CarrierValue | CarrierValue[] | undefined | null;
    shipmentContainerQty: ShipmentContainerValue[] | undefined;
    ocean_vessel?: VesselValue | undefined | null;
  };
  disabledFilters?: Record<string, FieldDisableReason>;
  rowSelection?: 'single' | 'multiple' | undefined;
}): JSX.Element {
  const { filters, rowSelection } = props;
  const {
    port_of_loading_id,
    port_of_discharge_id,
    carrier = [],
    shipmentContainerQty,
    ocean_vessel,
  } = filters;
  const { disabledFilters = {} } = props;
  const client = useApolloClient();
  const [otoData, setOTOData] = useState<Array<OTOReportData>>([]);
  const gridRef = useRef<GridOptions>();
  const columnApi = useRef<ColumnApi>();
  const [selectedCarrier, setSelectedCarrier] = useState<Array<CarrierValue>>(
    _isArray(carrier) ? carrier : [carrier as CarrierValue]
  );
  const [selectedVessel, setSelectedVessel] = useState(
    ocean_vessel ? (ocean_vessel as VesselValue) : undefined
  );
  const shipment_container_quantity = shipmentContainerQty;
  let columnDefs = [
    reportFields.voyage_number,
    {
      headerName: 'Booking #',
      field: 'booking_number',
      colId: 'booking_number',
      width: 120,
      cellRenderer: 'OTOBookingNumber',
      cellRendererParams: {
        redirectToBase: `/view/booking_order`,
        idField: 'id',
        doNotShowChangeRequests: true,
      },
    },
    reportFields.requested_quantity_and_type,
    reportFields.allocation_pending_quantity_and_type,
  ];

  let count = 0;

  const br_container_requests = shipment_container_quantity
    ? shipment_container_quantity.map((container: any) => ({
        id: container.id,
        _id: container._id,
        container_type: container.container_type,
        container_type_code: container.container_type_code,
        container_settings: container.container_settings,
        is_shipper_owned: container.isShipperOwned,
        quantity: container?.quantity,
        quantity_fulfilled: 0,
        quantity_unfulfilled: container?.quantity,
      }))
    : [];
  _map(br_container_requests, (cr: ContainerRequestValue) => {
    const containerSetting = renderContainerSettings([cr]);
    const headerName =
      containerSetting && containerSetting !== '-'
        ? `(${cr.quantity_unfulfilled}) Allocate ${cr.container_type} \n[${renderContainerSettings([
            cr,
          ])}]`
        : `(${cr.quantity_unfulfilled}) Allocate ${cr.container_type}`;
    if (cr.container_type && cr.quantity) {
      columnDefs = columnDefs.concat({
        headerName,
        field: `allocate_container_${count++}`,
        cellRenderer: 'allocateContainerInputNumber',
        width: 200,
        cellEditorParams: { container_request: cr },
      });
    }
  });

  columnDefs = columnDefs.concat(
    fields.commodity,
    fields.container_settings,
    {
      headerName: 'Ordered By',
      field: 'ordered_by',
      width: 100,
    },
    {
      headerName: 'Status',
      field: 'status_text',
      width: 100,
    },
    { headerName: 'Created By', field: 'created_by', width: 150 }
  );

  const Coldefs = columnDefs.map((c) => {
    const { filterKey, ...restCol } = c;
    return restCol;
  });

  const fetchData = useCallback(
    async (variables: Variables) => {
      const { data, errors } = await client.query({
        query: ALLOCATE_OTO_REPORT,
        variables,
        fetchPolicy: 'network-only',
      });
      if (errors) console.error(errors);
      return {
        data: data?.allocate_oto_report,
        error: errors ? true : false,
      };
    },
    [client]
  );

  const updateDataSource = useCallback(async () => {
    const variables: any = {
      allocate_oto_report: {
        origin_location_id: port_of_loading_id,
        destination_location_id: port_of_discharge_id,
        carrier_ids: selectedCarrier.map((carrier: any) => carrier.id),
        vessel_id: selectedVessel?.imo,
        voyage_number: undefined,
        shipment_containers: (shipmentContainerQty || []).map((sc: any) =>
          _pick(sc, ['id', 'container_type', 'container_type_code', 'container_settings'])
        ),
      },
    };
    if (!port_of_loading_id || !port_of_discharge_id || !shipmentContainerQty) {
      return;
    }
    const { data, error } = await fetchData(variables);
    gridRef?.current?.api?.hideOverlay();
    if (error) {
      gridRef?.current?.api?.showNoRowsOverlay();
    }
    setOTOData(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedVessel, selectedCarrier, filters]);

  useEffect(() => {
    updateDataSource();
  }, [updateDataSource, selectedCarrier, selectedVessel, filters]);

  return (
    <>
      <Row gutter={16}>
        <Col span={5} style={{ margin: '16px 16px 16px 0' }} className="new-filter-select-style">
          <Flex gap={2}>
            <GlobalSearch
              doc_type="Global::Carrier"
              searchProps={{ carrier_type: ['ocean', 'nvocc', 'coloader'] }}
              value={selectedCarrier}
              selectMode="multiple"
              onChange={(val: any) => setSelectedCarrier(val)}
              disabled={disabledFilters['carrier']?.disable}
            />
            {disabledFilters['carrier']?.reason && (
              <Tooltip title={disabledFilters['carrier']?.reason}>
                <InfoCircleOutlined />
              </Tooltip>
            )}
          </Flex>
        </Col>
        <Col
          span={5}
          style={{ width: '250px', margin: '16px 0' }}
          className="new-filter-select-style"
        >
          <Flex gap={2}>
            <VesselSearch
              value={selectedVessel}
              onChange={(val: any) => setSelectedVessel(val)}
              disabled={disabledFilters['vessel']?.disable}
            />
            {disabledFilters['vessel']?.reason && (
              <Tooltip title={disabledFilters['vessel']?.reason}>
                <InfoCircleOutlined />
              </Tooltip>
            )}
          </Flex>
        </Col>
      </Row>
      <div
        id="myGrid"
        style={{
          height: 360,
          width: '100%',
        }}
        className="ag-theme-material ag-grid-editable-cell ag-grid-header-wrap"
      >
        <BaseTable
          reportName="allocate_oto_report"
          columns={Coldefs}
          rowSelection={rowSelection}
          reportConfig={{
            rowHeight: 36,
            overlayNoRowsTemplate: 'No Matching Booking Orders Found',
            enableCellChangeFlash: true,
            groupDisplayType: 'groupRows',
            enableCellTextSelection: true,
            maxConcurrentDatasourceRequests: 1,
            excelStyles: exportExcelStyles,
            groupDefaultExpanded: -1,
            defaultColDef: {
              resizable: true,
            },
            components,
          }}
          gridRef={gridRef}
          rowData={renderOTOData(otoData) || []}
          onGridReady={async (grid: any) => {
            gridRef.current = grid;
            columnApi.current = grid.columnApi;
          }}
        />
      </div>
    </>
  );
});

export default BookingReport;
