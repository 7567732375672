/**
 * Conponent to be rendered in Container Tab in shipment view, booking order view
 * uses AgGrid to render containers
 */

import React, { useCallback, useEffect, useRef, useState } from 'react';

import { compact as _compact, startCase, groupBy as _groupBy } from 'lodash';
import { Card, Drawer, Form, FormInstance, Layout, Modal, message } from '@shipmnts/pixel-hub';
import { DrawerFooter, FormTable } from '@shipmnts/pixel-hub';
import { OceanTransportOrderValue } from 'operations/models/OceanTransportOrder';
import { ShipmentValue } from 'operations/models/Shipment';
// import { DECREASE_QUANTITY } from 'operations/graphql/shipment';
import { useMutation } from '@apollo/client';
import { CHANGE_BOOKING_ALLOCATION } from 'operations/graphql/shipment';
import ContainerQuantity from './ShipmentForm/ContainerQuantity';
import { useSession } from 'common';
import { RESTRICT_CHANGE_ALLOCATION } from 'operations/baseConstants';
import { Column } from 'operations/models/Report';
import { SessionDataValue } from 'operations/models/SessionData';

export function ChangeAllocationOfBooking(props: {
  shipment: ShipmentValue;
  oceanTransportOrders?: OceanTransportOrderValue[];
  onClose: () => void;
  onSuccess: () => void;
  disabled?: boolean;
}) {
  const { shipment, onClose, onSuccess } = props;
  const [form] = Form.useForm();
  const gridRef = useRef<any>();
  const [changeBookingAllocation, { data, error }] = useMutation(CHANGE_BOOKING_ALLOCATION);

  const [rowData, setRowData] = useState<
    {
      booking_number: any;
      booking_type: any;
      global_carrier: any;
      container_type: any;
      allocated: any;
      valid_till_date: any;
      quantity: any;
      container_request_id: any;
      oto_id: any;
    }[]
  >([]);

  useEffect(() => {
    const extractedData: {
      booking_number: any;
      booking_type: any;
      global_carrier: any;
      container_type: any;
      quantity: any;
      valid_till_date: any;
      container_request_id: any;
      oto_id: any;
    }[] = [];
    shipment?.ocean_transport_orders?.forEach((item) => {
      const {
        booking_number,
        container_requests,
        booking_type,
        global_carrier,
        valid_till_date,
        id,
      } = item;
      container_requests.forEach((request: any) => {
        extractedData.push({
          container_request_id: request.id,
          oto_id: id,
          booking_number,
          booking_type,
          global_carrier,
          valid_till_date,
          container_type: request.container_type,
          quantity: request.quantity,
        });
      });
    });

    // Step 2: Group the extracted data by BookingNumber and code_type
    const groupedData = extractedData.reduce((acc: any, curr: any) => {
      const key = `${curr.booking_number}-${curr.container_type}`;
      acc[key] = curr;
      return acc;
    }, {});
    // Step 3: Calculate the sum of quantities for each group
    const summedData = Object.keys(groupedData).map((key) => ({
      oto_id: groupedData[key].oto_id,
      container_request_id: groupedData[key].container_request_id,
      booking_number: groupedData[key].booking_number,
      booking_type: groupedData[key].booking_type,
      global_carrier: groupedData[key].global_carrier,
      container_type: groupedData[key].container_type,
      valid_till_date: groupedData[key].valid_till_date,
      allocated: groupedData[key].container_type,
      quantity: groupedData[key].quantity,
      actual_quantity: groupedData[key].quantity,
    }));
    setRowData(summedData);
  }, [shipment]);

  useEffect(() => {
    if (error) {
      message.error(error.message);
    } else if (data?.change_booking_allocation?.message) {
      message.success('Changed Booking Allocation Sucessfully');
      onClose && onClose();
      onSuccess && onSuccess();
    }
  }, [data, error, onClose, onSuccess]);

  // columns
  const columnDefs: any[] = _compact([
    {
      headerName: 'Booking Number',
      field: 'booking_number',
      colId: 'booking_number',
      cellRenderer: function render({ data }: { data: any }) {
        return (
          <span>
            <a target="_blank" href={`/view/booking_order/${data.oto_id}`} rel="noreferrer">
              {data.booking_number}
            </a>
          </span>
        );
      },
    },
    {
      headerName: 'Booking Type',
      field: 'booking_type',
      colId: 'booking_type',
      cellRenderer: function render({ data }: { data: any }) {
        return <span>{startCase(data.booking_type)}</span>;
      },
      width: 150,
    },
    {
      headerName: 'Carrier',
      field: 'global_carrier',
      colId: 'global_carrier',
      cellRenderer: function render({ data }: { data: OceanTransportOrderValue }) {
        return data?.global_carrier?.name;
      },
    },
    {
      headerName: 'Valid Till',
      field: 'valid_till_date',
      colId: 'valid_till_date',
    },
    {
      headerName: 'Container Type',
      field: 'allocated',
      colId: 'allocated',
      pinned: 'right',
    },
    {
      headerName: 'quantity',
      field: 'quantity',
      colId: 'quantity',
      cellRenderer: 'NumberTypeRenderer',
      editable: true,
      pinned: 'right',
      valueSetter: (params: any) => {
        if (params.newValue > 0) {
          params.data.quantity = params.newValue;
        } else {
          return false;
        }
        return true;
      },
    },
    {
      headerName: 'Booked Quantity',
      field: 'actual_quantity',
      colId: 'actual_quantity',
      editable: true,
      hide: true,
      pinned: 'right',
    },
  ]);

  const closeDrawer = useCallback(() => {
    form.resetFields();
    onClose();
  }, [form, onClose]);
  const sessionData = useSession();
  const containerMap: any = _groupBy(shipment?.shipment_containers, 'container_type_code');
  const shipmentContainerQuantity: any[] = [];
  Object.keys(containerMap).forEach((key) => {
    shipmentContainerQuantity.push({
      container_type_code: containerMap[key][0].container_type_code,
      container_type: containerMap[key][0].container_type,
      quantity: containerMap[key].length,
      container_settings: containerMap[key][0].container_settings,
      disable_container_type: true,
      _id: containerMap[key][0].id,
    });
  });
  const showDrawer = sessionData?.isFeatureEnabled(RESTRICT_CHANGE_ALLOCATION);

  if (showDrawer) {
    return (
      <Layout style={{ height: '100%' }}>
        <Modal
          title={`Edit Booking Allocation`}
          width={'60%'}
          open={true}
          maskClosable={true}
          closable={true}
          onCancel={onClose}
          footer={
            <DrawerFooter
              onSave={form.submit}
              saveText="Submit"
              closeText="Cancel"
              onClose={closeDrawer}
            />
          }
        >
          <AllocationForm
            form={form}
            shipmentContainerQuantity={shipmentContainerQuantity}
            sessionData={sessionData}
            gridRef={gridRef}
            columnDefs={columnDefs}
            rowData={rowData}
            shipment={shipment}
            changeBookingAllocation={changeBookingAllocation}
          />
        </Modal>
      </Layout>
    );
  }

  return (
    <Layout style={{ height: '100%' }}>
      <Drawer
        title={`Edit Booking Allocation`}
        width={'60%'}
        open={true}
        onClose={onClose}
        footer={
          <DrawerFooter
            onSave={form.submit}
            saveText="Submit"
            closeText="Cancel"
            onClose={closeDrawer}
          />
        }
      >
        <AllocationForm
          form={form}
          shipmentContainerQuantity={shipmentContainerQuantity}
          sessionData={sessionData}
          gridRef={gridRef}
          columnDefs={columnDefs}
          rowData={rowData}
          shipment={shipment}
          changeBookingAllocation={changeBookingAllocation}
        />
      </Drawer>
    </Layout>
  );
}

const AllocationForm = ({
  form,
  shipmentContainerQuantity,
  sessionData,
  gridRef,
  columnDefs,
  rowData,
  shipment,
  changeBookingAllocation,
}: {
  form: FormInstance;
  shipmentContainerQuantity: any[];
  sessionData: SessionDataValue;
  gridRef: any;
  columnDefs: Column[];
  rowData: any[];
  shipment: ShipmentValue;
  changeBookingAllocation: any;
}) => {
  return (
    <Form
      form={form}
      layout="vertical"
      initialValues={{
        shipment_container_quantity: shipmentContainerQuantity,
      }}
      onFinish={(value: any) => {
        const requested_crs: {
          count: any;
          container_request_id: any;
          oto_id: any;
        }[] = [];

        const newContainerMap = new Map(
          value.shipment_container_quantity.map((item: any) => [item._id, item])
        );

        shipmentContainerQuantity.forEach((oldItem) => {
          if (!newContainerMap.has(oldItem._id)) {
            value.shipment_container_quantity.push({
              ...oldItem,
              quantity: 0,
            });
          }
        });

        if (!sessionData?.isFeatureEnabled(RESTRICT_CHANGE_ALLOCATION)) {
          gridRef.current.api.forEachNode((node: any) => {
            const rowData = node.data;
            const { oto_id, container_request_id, actual_quantity, quantity } = rowData;
            if (actual_quantity) {
              const count = actual_quantity;
              requested_crs.push({
                oto_id,
                container_request_id,
                count: parseInt(quantity) - count,
              });
            }
          });
        }
        changeBookingAllocation({
          variables: {
            requested_crs: requested_crs,
            container_qty: value.shipment_container_quantity.map((cr: any) => ({
              container_type_code: cr.container_type_code,
              container_type: cr.container_type,
              quantity: cr.quantity,
              container_settings: cr.container_settings || {},
            })),
            shipment_id: shipment?.id,
          },
        });
      }}
    >
      <Card title="Ordered Container Qty">
        <ContainerQuantity disableDelete={false} allowZeroQty={true} />
      </Card>
      <br />
      {!sessionData?.isFeatureEnabled(RESTRICT_CHANGE_ALLOCATION) && (
        <Card title="Changed Booked Qty">
          <FormTable
            reportName={'container_details'}
            columns={columnDefs}
            rowData={rowData || []}
            gridRef={gridRef}
            reportConfig={{
              groupDefaultExpanded: 0,
              rowHeight: 40,
              stopEditingWhenCellsLoseFocus: true,
              enableCellChangeFlash: true,
              groupDisplayType: 'groupRows',
            }}
          />
        </Card>
      )}
    </Form>
  );
};

export default ChangeAllocationOfBooking;
