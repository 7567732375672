import { TagColorType } from '@shipmnts/pixel-hub/dist/components/tag/types';
import { CompanyStatus, KeyLabelMapping } from './types';

// constants
const AVATAR_COLORS = [
  { color: '#AF0ACA', backgroundColor: '#AF0ACA4D', borderColor: '#AF0ACA' },
  { color: '#CC933E', backgroundColor: '#FFF1DC', borderColor: '#CC933E' },
];

export const PRIORITY_COLOR_MAP = {
  LOW: '#1677FF',
  MEDIUM: '#FAAD14',
  HIGH: '#FF4D4F',
};

export const NEGATIVE_STATUS = ['CANCEL', 'LOST', 'CHURNED'];

export const COMPANY_STATUS_APPROVED = 'approved';
export const COMPANY_STATUS_MODERATE_RISK = 'moderate_risk';
export const COMPANY_STATUS_HIGH_RISK = 'high_risk';
export const COMPANY_STATUS_LEGAL_DISPUTE = 'legal_dispute';
export const COMPANY_STATUS_NO_CREDIT = 'no_credit';

export const TASK_COMPLETED = 'Completed';
export const TASK_PENDING = 'Pending';

export const CREDIT_STATUS = [
  COMPANY_STATUS_APPROVED,
  COMPANY_STATUS_MODERATE_RISK,
  COMPANY_STATUS_HIGH_RISK,
  COMPANY_STATUS_LEGAL_DISPUTE,
  COMPANY_STATUS_NO_CREDIT,
];

// company status filter array
export const COMPANY_STATUS_ARRAY: CompanyStatus[] = [
  COMPANY_STATUS_APPROVED,
  COMPANY_STATUS_MODERATE_RISK,
  COMPANY_STATUS_HIGH_RISK,
  COMPANY_STATUS_NO_CREDIT,
  COMPANY_STATUS_LEGAL_DISPUTE,
];

export const COMPANY_STATUSES = [
  COMPANY_STATUS_APPROVED,
  COMPANY_STATUS_MODERATE_RISK,
  COMPANY_STATUS_HIGH_RISK,
  COMPANY_STATUS_LEGAL_DISPUTE,
  COMPANY_STATUS_NO_CREDIT,
];

export const COMPANY_STATUS_DISPLAY_NAMES: { [id: string]: string } = {
  [COMPANY_STATUS_APPROVED]: 'Approved',
  [COMPANY_STATUS_MODERATE_RISK]: 'Moderate Risk',
  [COMPANY_STATUS_HIGH_RISK]: 'High Risk',
  [COMPANY_STATUS_LEGAL_DISPUTE]: 'Legal Dispute',
  [COMPANY_STATUS_NO_CREDIT]: 'No Credit',
};

export const COMPANY_STATUS_TAG_COLORS: { [id: string]: TagColorType } = {
  [COMPANY_STATUS_APPROVED]: 'success',
  [COMPANY_STATUS_MODERATE_RISK]: 'warning',
  [COMPANY_STATUS_HIGH_RISK]: 'critical',
  [COMPANY_STATUS_NO_CREDIT]: 'warning',
  [COMPANY_STATUS_LEGAL_DISPUTE]: 'critical',
};

export const keyLabelMapping: KeyLabelMapping = {
  [COMPANY_STATUS_MODERATE_RISK]: 'Moderate Risk',
  [COMPANY_STATUS_HIGH_RISK]: 'High Risk',
  [COMPANY_STATUS_APPROVED]: 'Credit Approved',
  [COMPANY_STATUS_LEGAL_DISPUTE]: 'In Legal Dispute',
  [COMPANY_STATUS_NO_CREDIT]: 'No Credit',
};

export const getInitials = (name?: string | null) => {
  if (!name) return '';
  const words = name.trim().split('');
  let initials = '';

  for (let i = 0; i < Math.min(words.length, 2); i++) {
    initials += words[i][0].toUpperCase();
  }

  return initials;
};

export const getColorsForAvatar = (id?: number | string) => {
  if (!id) return {};
  return AVATAR_COLORS[Number(id) % AVATAR_COLORS.length];
};

export const convertDateToUnix = (dateStr?: string) => {
  if (!dateStr) return null;
  return new Date(dateStr).getTime() / 1000;
};

export function formatUnixDate(timestamp?: number | string, time = false) {
  if (!timestamp) return;
  // Convert Unix timestamp to milliseconds
  const date = typeof timestamp === 'string' ? new Date(timestamp) : new Date(timestamp * 1000);
  const currentDate = new Date();

  // Format the date
  let formattedDate = date.getDate() + ' ' + date.toLocaleDateString('en-US', { month: 'short' });

  // Add year if it's not the current year
  if (date.getFullYear() !== currentDate.getFullYear()) {
    formattedDate += ', ' + date.getFullYear();
  }

  if (time) {
    let hours = date.getHours();
    const amPm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12 || 12; // Convert midnight (0) to 12
    formattedDate += ` ${hours}:${String(date.getMinutes()).padStart(2, '0')} ${amPm}`;
  }

  return formattedDate;
}
