import React, { useEffect, useMemo } from 'react';
import { Button, message, Modal } from '@shipmnts/pixel-hub';
import { DeleteOutlined } from '@shipmnts/pixel-hub';
import { useMutation } from '@apollo/client';
import { DELETE_SHIPMENT_ESTIMATE } from 'operations/graphql/shipmentEstimate';
import { ICellRendererParams } from '@ag-grid-community/core';
import { errorMessageHandlerGraphQLString } from 'common';
import { useShipmentEstimateStore } from './ChargeEstimates';

interface DeleteActionType extends ICellRendererParams {
  disabled?: (params: { data: any }) => boolean;
}

export default function DeleteAction(props: DeleteActionType) {
  const { disabled, data } = props;
  const [deleteEstimate, { data: response, error, loading }] =
    useMutation(DELETE_SHIPMENT_ESTIMATE);
  const { store } = useShipmentEstimateStore();

  const isDisabled = useMemo(() => {
    if (!!disabled) {
      return disabled({ data });
    } else return false;
  }, [disabled, data]);

  useEffect(() => {
    if (response?.delete_shipment_estimate) {
      message.success('Deleted Estimate Successfully');
      data.delete();
    }
    if (error) {
      message.error(errorMessageHandlerGraphQLString(error));
    }
  }, [response, error, data, store]);

  if (props.node.isRowPinned()) return <span></span>;

  return (
    <Button
      danger
      disabled={isDisabled}
      icon={<DeleteOutlined />}
      loading={loading}
      onClick={() => {
        Modal.confirm({
          title: 'Confirm',
          content: 'Are you sure you want to delete the charge?',
          okText: 'Ok',
          okType: 'danger',
          onOk: () => {
            data.id ? deleteEstimate({ variables: { id: data.id } }) : data.delete();
          },
          cancelText: 'Cancel',
        });
      }}
    />
  );
}
