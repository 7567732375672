import React, { Suspense } from 'react';
import { FallbackSkeleton, Modal } from '@shipmnts/pixel-hub';
const AwbStockForm = React.lazy(() => import('./AwbStockForm'));
interface AwbStockModalProps {
  visible: boolean;
  setVisible: (visible: boolean) => void;
  onSuccess: () => void;
}
export default function AwbStockModal(props: AwbStockModalProps) {
  const { visible, setVisible, onSuccess } = props;

  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <>
      <Modal
        width={800}
        title="Add AWB Stock Received From Airline"
        open={visible}
        onCancel={handleCancel}
        footer={null}
        maskClosable={false}
      >
        <Suspense fallback={<FallbackSkeleton />}>
          <AwbStockForm onSuccess={onSuccess} handleCancel={handleCancel} />
        </Suspense>
      </Modal>
    </>
  );
}
