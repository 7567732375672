import { types, Instance, SnapshotIn, SnapshotOut, IAnyModelType } from 'mobx-state-tree';

import Carrier from './Carrier';
import Company from './Company';
import ContainerRequest from './ContainerRequest';
import Location from './Location';
import InquiryOption from './InquiryOption';
import SalesPerson from 'common/models/SalesPerson';
import UserContact from 'common/models/UserContact';
import Team from 'common/models/Team';
import ShipmentParty from './ShipmentParty';
import Address from './Address';
import ProductOrderItem from './ProductOrderItem';

export const INQUIRY_WON_STATUS = 'Won';
export const INQUIRY_LOST_STATUS = 'Lost';
export const INQUIRY_CANCELED_STATUS = 'Inquiry Canceled';

const AddressLocationUnion = types.union(
  { dispatcher: (snapshot) => (!!snapshot?.type ? Location : Address) },
  Location,
  Address
);

const Inquiry = types
  .model({
    id: types.identifier,
    inquiry_global_id: types.maybe(types.string),
    involved_branch_id: types.maybe(types.maybeNull(types.string)),
    container_requests: types.array(ContainerRequest),
    customer_company: types.maybe(types.maybeNull(Company)),
    freight_type: types.maybe(types.maybeNull(types.string)),
    load_type: types.maybe(types.maybeNull(types.string)),
    trade_type: types.maybe(types.maybeNull(types.string)),
    priority: types.maybe(types.maybeNull(types.string)),
    last_action_status: types.maybe(types.maybeNull(types.string)),
    inquiry_number: types.maybe(types.maybeNull(types.string)),
    status: types.maybe(types.maybeNull(types.string)),
    incoterms: types.maybe(types.maybeNull(types.string)),
    description: types.maybe(types.maybeNull(types.string)),
    target_rate: types.maybe(types.maybeNull(types.string)),
    target_closing_date: types.maybe(types.maybeNull(types.number)),
    received_at: types.maybe(types.maybeNull(types.number)),
    cargo_ready_date: types.maybe(types.maybeNull(types.number)),
    target_delivery_date: types.maybe(types.maybeNull(types.number)),
    customer_address: types.maybe(types.maybeNull(Address)),
    sales_person: types.maybe(types.maybeNull(SalesPerson)),
    pricing_person: types.maybe(types.maybeNull(UserContact)),
    origin: types.maybe(types.maybeNull(AddressLocationUnion)),
    destination: types.maybe(types.maybeNull(AddressLocationUnion)),
    origin_custom_clearance_location: types.maybe(types.maybeNull(Location)),
    destination_custom_clearance_location: types.maybe(types.maybeNull(Location)),
    preferred_carriers: types.maybe(types.maybeNull(types.array(Carrier))),
    tags: types.maybe(types.maybeNull(types.array(types.string))),
    next_actions: types.maybe(types.maybeNull(types.array(types.string))),
    cargos: types.array(ProductOrderItem),
    business_vertical: types.maybe(
      types.maybeNull(
        types.model({
          id: types.identifier,
          name: types.string,
        })
      )
    ),
    involved_branch: types.maybe(
      types.maybeNull(
        types.model({
          id: types.identifier,
          name: types.string,
        })
      )
    ),
    services: types.maybe(types.maybeNull(types.array(types.string))),
    inquiry_options: types.maybe(
      types.maybeNull(types.array(types.late((): IAnyModelType => InquiryOption)))
    ),
    business_received_through: types.maybe(types.maybeNull(types.string)),
    fulfilled_container_map: types.maybe(types.maybeNull(types.string)),
    additional_loss_remarks: types.maybe(types.maybeNull(types.string)),
    place_of_carrier_receipt: types.maybe(types.maybeNull(Location)),
    place_of_carrier_delivery: types.maybe(types.maybeNull(Location)),
    destination_port_free_days: types.maybe(types.maybeNull(types.integer)),
    origin_carrier_free_days: types.maybe(types.maybeNull(types.integer)),
    destination_carrier_free_days: types.maybe(types.maybeNull(types.integer)),
    billing_party: types.maybe(types.maybeNull(Company)),
    billing_party_address: types.maybe(
      types.maybeNull(
        types.model({
          id: types.identifier,
          name: types.maybe(types.maybeNull(types.string)),
          print_address: types.maybe(types.maybeNull(types.string)),
        })
      )
    ),
    shipments: types.maybe(
      types.maybeNull(
        types.array(
          types.model({
            id: types.maybe(types.maybeNull(types.identifier)),
            job_number: types.maybe(types.maybeNull(types.string)),
            shipment_booking_number: types.maybe(types.maybeNull(types.string)),
          })
        )
      )
    ),
    teams: types.maybe(types.maybeNull(types.array(Team))),
    port_of_loading: types.maybe(types.maybeNull(Location)),
    port_of_discharge: types.maybe(types.maybeNull(Location)),
    container_stuffing_location: types.maybe(types.maybeNull(AddressLocationUnion)),
    container_destuffing_location: types.maybe(types.maybeNull(AddressLocationUnion)),
    shipment_parties: types.maybe(types.maybeNull(types.array(ShipmentParty))),
    service_type: types.maybe(types.maybeNull(types.string)),
    movement_mode: types.maybe(types.maybeNull(types.string)),
    routing_remarks: types.maybe(types.maybeNull(types.string)),
    freight_terms: types.maybe(types.maybeNull(types.string)),
    other_terms: types.maybe(types.maybeNull(types.string)),
    vehicle_category: types.maybe(types.maybeNull(types.string)),
  })
  .views((self) => ({
    get gross_weight() {
      return self.cargos.reduce((sum, cargo) => {
        sum += cargo.gross_weight || 0;
        return sum;
      }, 0);
    },
    get gross_volume() {
      return self.cargos.reduce((sum, cargo) => {
        sum += cargo.gross_volume || 0;
        return sum;
      }, 0);
    },
    get outer_package_qty() {
      return self.cargos.reduce((sum, cargo) => {
        sum += cargo.outer_package_qty || 0;
        return sum;
      }, 0);
    },
    getAllPartiesRolesMapping: function () {
      if (!self.customer_company) return [];
      return [
        {
          id: self.customer_company.id,
          registered_name: self.customer_company.registered_name,
          address_id: self.customer_address?.id,
          role: 'Customer',
        },
      ];
    },
  }));

export type InquiryValue = Instance<typeof Inquiry>;
export type InquiryIn = SnapshotIn<typeof Inquiry>;
export type InquiryOut = SnapshotOut<typeof Inquiry>;

export default Inquiry;
