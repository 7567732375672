import React from 'react';
import { SessionDataValue } from 'operations/models/SessionData';
import { NEGATIVE_TYPE, PRIMARY_TYPE, SECONDARY_TYPE } from '../constants';
import {
  performEditInquiryOption,
  performDeleteInquiryOption,
  performDuplicateInquiryOption,
  performAmendInquiryOption,
  performApproveInquiryOption,
} from './inquiryOptionActionRenderer';

import { Action } from '../models';
import { performInquiryOptionSharePDF } from './inquiryOptionActionRenderer';
import {
  FilePdfOutlined,
  EditOutlined,
  DeleteOutlined,
  CopyOutlined,
  CheckOutlined,
} from '@shipmnts/pixel-hub';
import { hasPermission } from '@shipmnts/pixel-hub';
import { PERMISSION_APPROVER, PERMISSOIN_ADMIN } from 'operations/modules/reports/constants';
import { INQUIRY_OPTION_HOLD_STATUS } from 'sales_hub/utils/constants';

export const getInquiryOptionActions = (
  inquiryOption: any,
  setEmailProps: (emailProps: any) => void,
  setVisible: (visible: boolean) => void,
  sessionData: SessionDataValue
) => {
  const actions: Action[] = [];
  const { permissions } = sessionData;

  actions.push({
    key: 'edit',
    type: PRIMARY_TYPE,
    displayComponent: 'Edit',
    icon: <EditOutlined />,
    performAction: performEditInquiryOption,
    isEnable: inquiryOption?.status === 'draft',
  });

  actions.push({
    key: 'duplicate',
    type: SECONDARY_TYPE,
    icon: <CopyOutlined />,
    displayComponent: 'Duplicate',
    performAction: performDuplicateInquiryOption,
    isEnable: inquiryOption?.status === 'draft',
  });

  actions.push({
    key: 'amend',
    type: SECONDARY_TYPE,
    isAdmin: true,
    icon: <CopyOutlined />,
    displayComponent: 'Amend',
    performAction: performAmendInquiryOption,
    isEnable:
      (inquiryOption?.status === 'won' || inquiryOption?.status === 'lost') &&
      // FIX ME
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      hasPermission(permissions, { name: PERMISSOIN_ADMIN, docType: 'SalesHub::Inquiry' }),
  });

  actions.push({
    key: 'delete',
    type: NEGATIVE_TYPE,
    icon: <DeleteOutlined />,
    displayComponent: 'Delete',
    performAction: performDeleteInquiryOption,
    isEnable: inquiryOption?.status === 'draft',
  });

  actions.push({
    key: 'Share Quote',
    type: SECONDARY_TYPE,
    displayComponent: 'Share Quotation',
    isEnable:
      (inquiryOption?.shipment_estimates?.length || 0) > 0 &&
      inquiryOption?.status !== INQUIRY_OPTION_HOLD_STATUS,
    icon: <FilePdfOutlined />,
    performAction: performInquiryOptionSharePDF,
    description: 'Share Quotation PDF To customer',
  });

  actions.push({
    key: 'Approve Quote',
    type: SECONDARY_TYPE,
    displayComponent: 'Approve Quotation',
    isEnable:
      inquiryOption?.status === INQUIRY_OPTION_HOLD_STATUS &&
      hasPermission(permissions, { name: PERMISSION_APPROVER, docType: 'SalesHub::InquiryOption' }),
    icon: <CheckOutlined />,
    performAction: performApproveInquiryOption,
    description: 'Approve Quotation to make it won',
  });

  return actions;
};
