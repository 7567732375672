import { useQuery } from '@apollo/client';
import { get } from 'lodash';
import React, { useState } from 'react';
import {
  FETCH_CONTAINER,
  FETCH_SHIPMENT,
  FETCH_SHIPMENT_CUSTOM_DETAIL,
  FETCH_EVENT_AND_MILESTONES,
  GET_INQUIRY_FROM_GLOBAL_ID,
} from '../../../graphql/queries';
import { EventType, TrackingReferenceType, WorkflowType } from '../../../types';
import EventsForm from './EventsForm';
import { getEventInputTypes } from './helpers';
import BulkUpdateEvents from './BulkUpdateEvents';
import { TrackerContextProvider } from '../../../TrackingContext';
import { SessionDataValue } from 'common/models/SessionData';

type Props = {
  id: string | string[];
  referenceType: TrackingReferenceType;
  eventName: string;
  type: 'ADD' | 'UPDATE';
  onClose?: () => void;
  onSuccess?: () => void;
  session?: SessionDataValue;
  workflow_type?: WorkflowType;
};

const RenderEventsForm = ({
  id,
  referenceType,
  eventName,
  type,
  onClose,
  onSuccess,
  session,
  workflow_type,
}: Props): JSX.Element => {
  // Queries and Mutation
  const { data, loading, error } = useQuery(FETCH_EVENT_AND_MILESTONES, {
    variables: {
      ids: Array.isArray(id) ? id.map((i) => parseInt(i)) : [parseInt(id)],
      reference_type: referenceType,
      workflow_type: workflow_type || 'main',
    },
  });

  let FETCH_BASIC_DETAILS = FETCH_CONTAINER;
  if (referenceType === 'Shipment::ShipmentContainer') {
    FETCH_BASIC_DETAILS = FETCH_CONTAINER;
  } else if (referenceType === 'Shipment::Shipment') {
    FETCH_BASIC_DETAILS = FETCH_SHIPMENT;
  } else if (referenceType === 'Shipment::ShipmentCustomDetail') {
    FETCH_BASIC_DETAILS = FETCH_SHIPMENT_CUSTOM_DETAIL;
  } else if (referenceType === 'SalesHub::InquiryGlobal') {
    FETCH_BASIC_DETAILS = GET_INQUIRY_FROM_GLOBAL_ID;
  }

  // Queries
  const { data: referenceData } = useQuery(FETCH_BASIC_DETAILS, {
    variables: { id: Array.isArray(id) ? parseInt(id[0]) : id },
  });
  let refData = null;
  if (referenceType === 'Shipment::ShipmentContainer') {
    refData = referenceData?.fetch_basic_container_details_by_id;
  } else if (referenceType === 'Shipment::Shipment') {
    refData = referenceData?.shipment;
  } else if (referenceType === 'Shipment::ShipmentCustomDetail') {
    refData = referenceData?.shipment_custom_detail;
  } else if (referenceType === 'SalesHub::InquiryGlobal') {
    refData = referenceData?.get_inquiry_from_global_id;
  }

  // States
  const [showModal, setshowModal] = useState(true);

  // Handlers
  const closeModal = (sucess?: boolean) => {
    setshowModal(false);
    if (onClose) onClose();
    if (onSuccess && sucess) onSuccess();
  };

  if (error) {
    return <div>Error occured in loading events</div>;
  }
  if (!data && !loading) {
    return <div>data not found</div>;
  }

  const events: EventType[] = get(data, 'fetch_tracking_events', []);

  // If bulk
  if (Array.isArray(id)) {
    const eventsToBeEdited = events.filter((o) => o.name === eventName);
    return (
      <TrackerContextProvider
        events={eventsToBeEdited}
        // id={ids}
        referenceType={referenceType}
        session={session}
        id={''}
        onClose={closeModal}
        headerText={eventName}
        isOpen={showModal}
        refData={refData}
      >
        <BulkUpdateEvents
          ids={id}
          referenceType={referenceType}
          eventName={eventName}
          type={type}
          onSuccess={closeModal}
          onClose={closeModal}
          session={session}
          events={eventsToBeEdited}
          refData={refData}
        />
      </TrackerContextProvider>
    );
  } else {
    const eventToBeEdited = events.find((o) => o.name === eventName);
    if (eventToBeEdited) {
      const { fields } = getEventInputTypes(eventToBeEdited, refData, session);
      return fields.length > 0 ? (
        <TrackerContextProvider
          isOpen={showModal}
          headerText={eventName}
          onClose={closeModal}
          session={session}
          referenceType={referenceType}
          id={id}
          wrapper="DRAWER"
        >
          <div style={{ padding: '0px 25px' }}>
            <EventsForm
              event={eventToBeEdited}
              afterSubmit={closeModal}
              onDiscard={closeModal}
              session={session}
              refData={refData}
              referenceType={referenceType}
            />
          </div>
        </TrackerContextProvider>
      ) : (
        <div className="event-add-edit-layout">
          <TrackerContextProvider
            isOpen={showModal}
            headerText={eventName}
            onClose={closeModal}
            session={session}
            referenceType={referenceType}
            id={id}
            wrapper="POPUP"
          >
            {eventToBeEdited ? (
              <div
                style={{
                  width: '400px',
                }}
              >
                <EventsForm
                  event={eventToBeEdited}
                  afterSubmit={closeModal}
                  onDiscard={closeModal}
                  session={session}
                  refData={refData}
                  referenceType={referenceType}
                />
              </div>
            ) : (
              <></>
            )}
          </TrackerContextProvider>
        </div>
      );
    }
  }

  return <div className="event-add-edit-layout"></div>;
};

export default RenderEventsForm;
