import React, { useState } from 'react';
import { ICellRendererParams } from '@ag-grid-community/core';
import { observer } from 'mobx-react-lite';
import {
  Space,
  Tooltip,
  LinkRender,
  Tag,
  LinkOutlined,
  ExclamationCircleOutlined,
  Modal,
} from '@shipmnts/pixel-hub';
import { WarningOutlined } from '@shipmnts/pixel-hub';
import { ContractValue } from 'src/packages/rate_management';
import { CONTRACT_DOCTYPE_MAP } from 'src/packages/rate_management/constants';

interface ContractNumberRendererParams extends ICellRendererParams {
  contract: ContractValue;
  freightType: string;
  type?: string;
  isEdit?: boolean;
}

export const ContractNumberRenderer = observer(function ContractNumberRenderer(
  props: ContractNumberRendererParams
): JSX.Element {
  const [contract, setContract] = useState<ContractValue | null>(props?.contract);
  const freightType: string = props?.freightType;
  const type = props?.type;
  const isEdit = props?.isEdit;

  const showAmendWarning = contract?.amended_to?.contract_number ? true : false;
  const showExpireWarning = contract?.isExpired();
  if (!contract || !contract?.id || !contract?.contract_number || !freightType) {
    return <></>;
  }

  return (
    <div style={{ width: '100%', display: 'flex' }}>
      <div style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
        {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          //@ts-ignore
          <LinkRender
            value={{
              record_details: {
                contract_number: contract?.contract_number,
                extra_fields: {
                  id: contract?.id,
                  rate_type: contract?.rate_type,
                  type: CONTRACT_DOCTYPE_MAP[contract?.type as keyof typeof CONTRACT_DOCTYPE_MAP],
                },
              },
            }}
            data={contract}
            value_field={'contract_number'}
            id_field={'id'}
            doc_type_id={'RateManagement::Contract'}
          />
        }
      </div>
      <Space size={4} style={{ float: 'right', marginLeft: 'auto' }}>
        {!isEdit && showAmendWarning && (
          <Tooltip title={`Contract has been Amended to '${contract.amended_to?.contract_number}'`}>
            <WarningOutlined style={{ color: 'red' }} />
          </Tooltip>
        )}
        {showExpireWarning && !showAmendWarning && !isEdit && (
          <Tooltip title={`Contract has Expired`}>
            <WarningOutlined style={{ color: 'red' }} />
          </Tooltip>
        )}
        {isEdit && (
          <Tooltip trigger={['hover']} title={'Delink Contract'}>
            <Tag
              closable={true}
              onClose={(e) => {
                e.preventDefault();
                Modal.confirm({
                  icon: <ExclamationCircleOutlined />,
                  title: 'Are you sure?',
                  content: 'This will Delink the Contract from the Item',
                  onOk() {
                    if (type === 'sell_contract_item') {
                      props.data.delinkSellContractItem();
                      setContract(null);
                    } else if (type === 'buy_contract_item') {
                      props.data.delinkBuyContractItem();
                      setContract(null);
                    }
                  },
                });
              }}
            >
              <LinkOutlined style={{ color: 'var(--bg-success-green-2)' }} />
            </Tag>
          </Tooltip>
        )}
      </Space>
    </div>
  );
});
