import React, { lazy, Suspense, useContext, useEffect, useRef, useState } from 'react';
import '../style.css';

import { useQuery } from '@apollo/client';
import {
  Divider,
  Layout,
  Tabs,
  AppHelmet,
  TrackerContextProvider,
  FallbackSkeleton,
} from '@shipmnts/pixel-hub';

import { get } from 'lodash';
import { useParams } from 'wouter';

import { GET_INQUIRY_FROM_GLOBAL_ID } from 'sales_hub/graphql/inquiry';
import Inquiry, {
  INQUIRY_CANCELED_STATUS,
  INQUIRY_WON_STATUS,
  InquiryValue,
  INQUIRY_LOST_STATUS,
} from 'sales_hub/models/inquiry';
import Spinner from 'src/components/Spinner';

import InboxTab from './InboxTab';
import { getQueryParamsAndRemove, useEmailDrawerContext } from 'common';
const EditInquiryDrawer = lazy(() => import('../EditInquiryDrawer'));
const DocumentTab = lazy(() => import('./DocumentTab'));
const InquiryDetailHeader = lazy(() => import('./InquiryDetailHeader'));
const InquirySummary = lazy(() => import('./InquirySummary'));
const InquiryOptionTable = lazy(
  () => import('sales_hub/components/InquiryOption/InquiryOptionTable')
);

type Props = {
  id?: string; // in case of quick view we need id in props to fetch data
  externalLink?: boolean;
  onClose?: () => void;
};

interface InquiryDetailContextProps {
  inquiry_global_id?: string;
  inquiry: InquiryValue;
  refetchInquiry: () => void;
}
const InquiryDetailContext = React.createContext<Partial<InquiryDetailContextProps>>({});
export const useInquiryDetail = () => useContext(InquiryDetailContext);

const InquiryDetailLayout = (props: Props) => {
  const params = useParams<{ id: string }>();
  const search_query = new URLSearchParams(window.location.search);
  const id = props.id || params.id;

  // States
  const [defaultActiveKey, setDefaultActiveKey] = useState<string>('inbox');
  const [showFormDrawer, setShowFormDrawer] = useState<boolean>(false);

  // Refs
  const summaryRef = useRef<null | HTMLDivElement>(null);

  //hooks
  const { setInboundEmailModalProps } = useEmailDrawerContext();

  // Queries and Mutations
  const { data, error, loading, refetch } = useQuery(GET_INQUIRY_FROM_GLOBAL_ID, {
    variables: { id: id },
    fetchPolicy: 'no-cache',
  });

  // Effects
  // Scroll to tab content on tab change
  useEffect(() => {
    const height = summaryRef?.current?.scrollHeight;
    if (height) window.scrollBy(0, height);
    if (search_query.get('tab')) {
      setDefaultActiveKey(search_query.get('tab') || '');
      getQueryParamsAndRemove('tab');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const inquiry = get(data, 'get_inquiry_from_global_id');
    if (inquiry && search_query.get('first_load') && !search_query.get('search_rate')) {
      setInboundEmailModalProps({
        isOpen: true,
        email: `${inquiry?.uuid}@inbound.shipmnts.com`,
        recordName: 'Inquiry',
      });
      getQueryParamsAndRemove('first_load');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  // handle loading
  if (loading) {
    return (
      <div className="centered fullscreen">
        <Spinner tip="Fetching RFQ details..." />
      </div>
    );
  }
  if (error) {
    // Todo: error components
    return <div>error occured</div>;
  }

  // Constants and Methods
  const inquiryDetails = Inquiry.create(get(data, 'get_inquiry_from_global_id'));
  const items = [
    {
      key: 'inbox',
      label: 'Inbox',
      children: <InboxTab id={id} />,
    },
    {
      key: 'quotation',
      label: 'Quotation',
      children: (
        <TrackerContextProvider referenceType="SalesHub::InquiryOption">
          <Suspense fallback={<FallbackSkeleton />}>
            <InquiryOptionTable
              disableResource={[
                INQUIRY_CANCELED_STATUS,
                INQUIRY_LOST_STATUS,
                INQUIRY_WON_STATUS,
              ]?.includes(inquiryDetails?.last_action_status || '')}
              refetchInquiry={refetch}
              inquiry={inquiryDetails}
            />
          </Suspense>
        </TrackerContextProvider>
      ),
    },
    {
      key: 'documents',
      label: 'Documents',
      children: (
        <Suspense fallback={<FallbackSkeleton />}>
          <DocumentTab />
        </Suspense>
      ),
    },
  ];
  const { Content } = Layout;
  return (
    <div style={{ height: '94vh', overflowY: 'scroll' }}>
      <InquiryDetailContext.Provider
        value={{
          inquiry: inquiryDetails,
          refetchInquiry: refetch,
          inquiry_global_id: id,
        }}
      >
        <AppHelmet
          titleTemplate={`#${inquiryDetails?.inquiry_number || ''} | RFQ | Shipmnts [Sales Hub]`}
        />
        <Layout style={{ paddingBottom: '24px' }}>
          <Suspense fallback={<FallbackSkeleton />}>
            <InquiryDetailHeader
              setShowFormDrawer={setShowFormDrawer}
              externalLink={props.externalLink}
              onClose={props.onClose}
            />
          </Suspense>

          <Content style={{ padding: '16px 0px 0px 24px' }}>
            <div ref={summaryRef}>
              <Suspense fallback={<FallbackSkeleton />}>
                <InquirySummary />
              </Suspense>
              <Divider style={{ marginBottom: '0px' }} />
              <Tabs
                defaultActiveKey={defaultActiveKey}
                items={items}
                tabBarStyle={{
                  position: 'sticky',
                  top: '58px',
                  zIndex: 2,
                  backgroundColor: '#fff',
                }}
              />
            </div>
          </Content>
        </Layout>
        {showFormDrawer && (
          <Suspense fallback={<FallbackSkeleton />}>
            <EditInquiryDrawer
              isVisible={showFormDrawer}
              inquiry={inquiryDetails}
              setIsVisible={setShowFormDrawer}
              onSuccess={refetch}
            />
          </Suspense>
        )}
      </InquiryDetailContext.Provider>
    </div>
  );
};

export default InquiryDetailLayout;
