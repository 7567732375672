import { Col, Row } from '@shipmnts/pixel-hub';
import React from 'react';
import ShipmentParties from './Overview/Cards/ShipmentParties';
import BookingDetails from './Overview/Cards/BookingDetails';
import RoutingDetails from './Overview/Cards/RoutingDetails';
import CreationDetails from './Overview/Cards/CreationDetails';
import { useShipmentDetail } from './ShipmentDetailLayout';
import TripDetails from './Overview/Cards/TripDetails';
import { FREIGHT_TYPE_ROAD } from '../../constants';
import LinkShipmentDetails from './Overview/Cards/LinkShipmentDetails';

type Props = {
  onClose?: () => void;
  externalLink?: boolean;
};

const ShipmentSummary = (props: Props) => {
  const { shipment } = useShipmentDetail();
  if (!shipment) return <></>;
  const isRoad = shipment.freight_type === FREIGHT_TYPE_ROAD;

  if (isRoad) {
    return (
      <Row gutter={16} className="shipment-summary" justify="space-between" align="top">
        {/* Party Details */}
        <Col sm={24} md={12} lg={5}>
          <ShipmentParties />
        </Col>
        {
          <Col sm={24} md={12} lg={4}>
            <TripDetails />
          </Col>
        }
        {/* Linked Shipment Details */}
        {
          <Col sm={24} md={12} lg={5}>
            <LinkShipmentDetails />
          </Col>
        }
        {/* Routing Details */}
        {shipment.shipment_type !== 'general' && shipment.shipment_type !== 'warehouse' && (
          <Col sm={24} md={12} lg={5}>
            <RoutingDetails />
          </Col>
        )}
        {/* Creation Details */}
        <Col sm={24} md={12} lg={5}>
          <CreationDetails />
        </Col>
      </Row>
    );
  }
  return (
    <Row gutter={16} className="shipment-summary">
      {/* Party Details */}
      <Col sm={24} md={12} lg={6}>
        <ShipmentParties />
      </Col>
      {/* Booking Details */}
      {shipment.shipment_type !== 'general' && shipment.shipment_type !== 'warehouse' && (
        <Col sm={24} md={12} lg={6}>
          <BookingDetails />
        </Col>
      )}

      {/* Routing Details */}
      {shipment.shipment_type !== 'general' && shipment.shipment_type !== 'warehouse' && (
        <Col sm={24} md={12} lg={6}>
          <RoutingDetails />
        </Col>
      )}
      {(shipment.shipment_type === 'general' || shipment.shipment_type === 'warehouse') &&
        !!shipment?.vehicle_license_plate_number && (
          <Col sm={24} md={12} lg={6}>
            <TripDetails />
          </Col>
        )}

      {/* Creation Details */}
      <Col sm={24} md={12} lg={6}>
        <CreationDetails />
      </Col>
    </Row>
  );
};

export default ShipmentSummary;
