import { ICellRendererParams } from '@ag-grid-community/core';
import {
  Button,
  Dropdown,
  fetchDocument,
  MenuProps,
  message,
  Skeleton,
  useDocumentPayloadHelper,
} from '@shipmnts/pixel-hub';
import { MoreOutlined, DownOutlined, ViewTableContext } from '@shipmnts/pixel-hub';
import React, { useContext, useEffect, useState } from 'react';
import { Action, PerformAction } from 'operations/modules/actionHelper/models';
import { groupBy } from 'lodash';
import { useApolloClient, useLazyQuery } from '@apollo/client';
import { useApplicationContentContext, useEmailDrawerContext, useSession } from 'common';
import { useLocation } from 'wouter';

import OceanTransportOrder, {
  OceanTransportOrderValue,
} from 'operations/models/OceanTransportOrder';
import { getOTOActions } from 'operations/modules/actionHelper/OTOActions/otoActionHelper';
import { getShipmentActions } from 'operations/modules/actionHelper/ShipmentActions/shipmentActionHelper';
import { GET_OCEAN_TRANSPORT_ORDER } from 'operations/modules/booking/graphql/oceanTransportOrder';
import { FETCH_CONTAINER } from 'operations/modules/booking/graphql/shipmentContainer';
import { FETCH_CARGO, SHIPMENT } from 'operations/graphql/shipment';
import { SHIPMENT_CUSTOM_DETAIL } from 'operations/graphql/shipmentCustomDetail';
import { FETCH_SHIPMENT_DOCUMENT_WITH_SHIPMENT } from 'operations/graphql/shipmentDocument';
import { FETCH_STOCK_ORDER_ITEMS } from 'operations/graphql/StockOrder';
import { getContainerActions } from 'operations/modules/actionHelper/ContainerActions/containerActionHelper';
import { getShipmentDocumentActions } from 'operations/modules/actionHelper/ShipmentDocumentActions/shipmentDocumentActionHelper';
import { getStockOrderActions } from 'operations/modules/actionHelper/StockOrder/stockOrderActionHelper';
import {
  NEGATIVE_TYPE,
  PRIMARY_TYPE,
  SECONDARY_TYPE,
  TERTIARY_TYPE,
  getActionClassName,
} from 'operations/modules/actionHelper/constants';
import { FETCH_MESSAGE } from 'operations/modules/message/graphql/message';
import { getMessageAction } from 'operations/modules/actionHelper/MessageActions/messageActionHelper';
import { getVoyageScheduleAction } from 'operations/modules/actionHelper/VoyageScheduleActions/voyageScheduleActionHelper';
import { FETCH_VOYAGE_SCHEDULE_BY_ID } from 'operations/modules/booking/graphql/voyageSchedule';
import VoyageSchedule from 'operations/models/VoyageSchedule';
import { FETCH_FREIGHT_CONTRACT } from 'operations/modules/freightContract/graphql/freightContract';
import { getFreightContractActions } from 'operations/modules/actionHelper/FreightContractActions/freightContractActionHelper';
import Shipment from 'operations/models/Shipment';
import { getGeneratedDocumentActions } from 'operations/modules/actionHelper/GeneratedDocuments/generatedDocumentActionHelper';
import { getShipmentCustomsActions } from 'operations/modules/actionHelper/shipmentCustomDetailActions/shipmentCustomDetailActionHelper';
import { getAccountsActions } from 'operations/modules/actionHelper/AccountsActions/AccountsActionsHelper';
import { isStateTreeNode } from 'mobx-state-tree';
import { getInquiryActions } from './InquiryActions/inquiryActionHelper';
import { GET_INQUIRY } from 'operations/graphql/inquiries';
import Inquiry from 'operations/models/Inquiry';
import { GET_INQUIRY_OPTION } from '../booking/graphql/inquiryOption';
import { getInquiryOptionActions } from './InquiryOptionActions/inquiryOptionActionHelper';
import { GET_VIEW_SUBSCRIPTION } from 'operations/graphql/report';
import { getViewSubscriptionActions } from './ViewSubscriptionActions/viewSubscriptionActionHelper';
import { getProductOrderActions } from '../orderManagement/OMSActions/OMSActionHelper';
import {
  FETCH_COMMERCIAL_INVOICE,
  FETCH_PRODUCT_ORDER,
} from '../orderManagement/graphql/productOrder';
import { getShipmentManifestActions } from './ShipmentManifestActions/shipmentManifestActionRenderer';
import { GET_SHIPMENT_MANIFEST } from 'operations/graphql/shipmentManifest';
import { GET_VEHICLE } from '../vehicle/graphql';
import { getVehicleActions } from 'operations/modules/actionHelper/VehicleActions/vehicleActionHelper';
import { GET_DRIVER } from '../driver/graphql';
import { getDriverActions } from './DriverActions/driverActionHelper';
import { SizeType } from '@shipmnts/pixel-hub';
import { getTemplateAction } from './TemplateActions/templateActionHelper';
import { GET_TEMPLATE } from 'network';
import { getCommercialInvoiceActions } from '../orderManagement/OMSActions/CommercialInvoiceActionHelper';
import ProductOrder from 'operations/models/ProductOrder';
import { FETCH_COMPANY } from './Companies/queries';
import { getConnectionRequestActions } from './Companies/companyActionHelper';

import { GET_DATA_IMPORT, GET_FIELDS } from 'common/utils/graphql';

import { FETCH_SALES_PERSON } from 'operations/graphql/salesPerson';
import { getSalesPersonActions } from './SalesPersonActions/salesPersonActionHelper';
import { FETCH_USER_CONTACT } from 'operations/graphql/user';
import { getUserContactActions } from './UserContactActions/userContactActionHelper';
import { getCompanyActions } from './CompanyActions/companyActionHelper';
import Company from 'operations/models/Company';
import {
  getWmsProductActions,
  getWmsTransactionActions,
  getWmsWarehouseUnitActions,
} from '../warehouseManagement/WMSActionHelper';
import { FETCH_WMS_TRANSACTION } from '../warehouseManagement/graphql/warehouseTransaction';
import { getTaskActions } from './TaskActions/taskActionHelper';
import { GET_TASK } from 'network/graphql/task';
import { GET_PRODUCT } from 'operations/modules/warehouseManagement/graphql/product';
import { getTicketActions } from 'common';
import { FETCH_TICKET } from './Tickets/queries';
import { FETCH_WAREHOUSE_UNIT } from '../warehouseManagement/graphql/warehouseUnit';
import { getCargoActions } from './CargoActions/cargoActionHelper';
import { getShipmentRouteActions } from './ShipmentRouteActions/shipmentRouteActionsHelper';
import { FETCH_SHIPMENT_ROUTE } from 'operations/graphql/shipmentRoute';
import { getDataImportActions } from './DataImportActions/dataImportActionHelper';
import { FETCH_CONTRACT } from 'src/packages/rate_management/graphql/query';
import getContractActions from './ContractActions/contractActionHelper';

type MenuItem = Required<MenuProps>['items'][number];

interface ActionRendererType extends ICellRendererParams {
  doc_type_id: string;
  refetchData?: () => void;
  selectedActions?: any[];
}
export interface ActionRendererProps {
  id: string;
  doc_type_id: string;
  isDetailScreen?: boolean;
  children?: JSX.Element;
  selectedActions?: any[];
  data?: any;
  refetchData?: () => void;
  buttonSize?: SizeType;
}

export default function ActionRendererReport(props: ActionRendererType) {
  if (!props.node.data) return <></>;
  if (props.node.rowPinned) return <span></span>;
  return (
    <ActionRenderer
      id={props.node.data?.id}
      doc_type_id={props.doc_type_id}
      refetchData={props.refetchData}
      selectedActions={props.selectedActions}
    />
  );
}

export function ActionRendererDetailReport(props: ActionRendererType) {
  if (!props.node.data) return <></>;
  if (props.node.rowPinned) return <span></span>;
  return (
    <ActionRenderer
      id={props.node.data.id}
      data={props.node.data}
      doc_type_id={props.doc_type_id}
      refetchData={props.refetchData}
      selectedActions={props.selectedActions}
    />
  );
}

export function ActionRenderer(props: ActionRendererProps) {
  const { id, doc_type_id, data, refetchData, selectedActions, buttonSize = 'middle' } = props;
  const { setVisible, setEmailProps } = useEmailDrawerContext();
  const [TaskComponent, setTaskComponent] = useState<React.JSXElementConstructor<any>>();
  const [actionParams, setActionParams] = useState<any>({});
  const [menuItems, setMenuItem] = useState<Action[]>(selectedActions || []);
  const [actionsWithType, setActionWithtype] = useState(groupBy(menuItems, 'type'));
  const [record, setRecord] = useState(data);
  const client = useApolloClient();
  const { config_data } = useApplicationContentContext();
  const { getFetchDocumentFunction } = useDocumentPayloadHelper();
  const user = useSession();
  const { permissions } = user;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { refreshReportRows } = useContext(ViewTableContext);
  const { 1: navigate } = useLocation();

  useEffect(() => {
    if (data) {
      setRecord(data);
    }
  }, [data]);

  useEffect(() => {
    if (selectedActions) {
      setMenuItem(selectedActions);
    }
  }, [selectedActions]);

  const refetch = () => {
    if (refreshReportRows) {
      refreshReportRows([record.id]);
    }
    setRecord(data);
    if (refetchData) {
      refetchData();
    }
  };
  const [fetchOto, { data: oto }] = useLazyQuery<
    { ocean_transport_order: OceanTransportOrderValue },
    { id: string }
  >(GET_OCEAN_TRANSPORT_ORDER, {
    fetchPolicy: 'no-cache',
  });
  const [fetchContainers, { data: container }] = useLazyQuery(FETCH_CONTAINER, {
    fetchPolicy: 'no-cache',
  });
  const [fetchShipment, { data: shipment }] = useLazyQuery(SHIPMENT, {
    fetchPolicy: 'no-cache',
  });
  const [fetchCompanyData, { data: companyData }] = useLazyQuery(FETCH_COMPANY, {
    fetchPolicy: 'no-cache',
  });
  const [fetchShipmentDocument, { data: document }] = useLazyQuery(
    FETCH_SHIPMENT_DOCUMENT_WITH_SHIPMENT,
    {
      fetchPolicy: 'no-cache',
    }
  );
  const [fetchStockOrderItem, { data: stockOrderItem }] = useLazyQuery(FETCH_STOCK_ORDER_ITEMS, {
    fetchPolicy: 'no-cache',
  });
  const [fetchMessage, { data: messageData }] = useLazyQuery(FETCH_MESSAGE, {
    fetchPolicy: 'no-cache',
  });
  const [fetchVoyageSchedule, { data: voyageSchedule }] = useLazyQuery(
    FETCH_VOYAGE_SCHEDULE_BY_ID,
    {
      fetchPolicy: 'no-cache',
    }
  );
  const [fetchFreightContract, { data: freightContract }] = useLazyQuery(FETCH_FREIGHT_CONTRACT, {
    fetchPolicy: 'no-cache',
  });

  const [fetchShipmentCustomDetail, { data: shipmentCustomDetail }] = useLazyQuery(
    SHIPMENT_CUSTOM_DETAIL,
    {
      fetchPolicy: 'no-cache',
    }
  );

  const [fetchInquiry, { data: inquiryDetail }] = useLazyQuery(GET_INQUIRY, {
    fetchPolicy: 'no-cache',
  });

  const [fetchInquiryOption, { data: inquiryOptionDetail }] = useLazyQuery(GET_INQUIRY_OPTION, {
    fetchPolicy: 'no-cache',
  });

  const [fetchViewSubscription, { data: viewSubscriptionData }] = useLazyQuery(
    GET_VIEW_SUBSCRIPTION,
    {
      fetchPolicy: 'no-cache',
    }
  );

  const [fetchProductOrder, { data: productData }] = useLazyQuery(FETCH_PRODUCT_ORDER, {
    fetchPolicy: 'no-cache',
  });
  const [fetchShipmentManifests, { data: shipmentManifestsData }] = useLazyQuery(
    GET_SHIPMENT_MANIFEST,
    {
      fetchPolicy: 'no-cache',
    }
  );

  const [fetchVehicle, { data: vehicle }] = useLazyQuery(GET_VEHICLE, {
    fetchPolicy: 'no-cache',
  });

  const [fetchDriver, { data: driver }] = useLazyQuery(GET_DRIVER, {
    fetchPolicy: 'no-cache',
  });

  const [fetchTemplate, { data: template }] = useLazyQuery(GET_TEMPLATE, {
    fetchPolicy: 'no-cache',
  });

  const [fetchCommercialInvoice, { data: commercialInvoice }] = useLazyQuery(
    FETCH_COMMERCIAL_INVOICE,
    {
      fetchPolicy: 'no-cache',
    }
  );

  const [fetchCustomFields, { data: customField }] = useLazyQuery(GET_FIELDS, {
    fetchPolicy: 'no-cache',
  });

  const [fetchUserContacts, { data: userContact }] = useLazyQuery(FETCH_USER_CONTACT, {
    fetchPolicy: 'no-cache',
  });

  const [fetchSalesPerson, { data: salesPerson }] = useLazyQuery(FETCH_SALES_PERSON, {
    fetchPolicy: 'no-cache',
  });

  const [fetchWMSTransaction, { data: wmsTransactionData }] = useLazyQuery(FETCH_WMS_TRANSACTION, {
    fetchPolicy: 'no-cache',
  });
  const [fetchTask, { data: taskData }] = useLazyQuery(GET_TASK, {
    fetchPolicy: 'no-cache',
  });

  const [fetchWmsProduct, { data: WmsProductData }] = useLazyQuery(GET_PRODUCT, {
    fetchPolicy: 'no-cache',
  });

  const [fetchTicket, { data: ticket }] = useLazyQuery(FETCH_TICKET, {
    fetchPolicy: 'no-cache',
  });

  const [fetchWMSWarehouseUnit, { data: wmsWarehouseUnitData }] = useLazyQuery(
    FETCH_WAREHOUSE_UNIT,
    {
      fetchPolicy: 'no-cache',
    }
  );

  const [fetchCargo, { data: cargo }] = useLazyQuery(FETCH_CARGO, {
    fetchPolicy: 'no-cache',
  });

  const [fetchShipmentRoute, { data: shipmentRoute }] = useLazyQuery(FETCH_SHIPMENT_ROUTE, {
    fetchPolicy: 'no-cache',
  });

  const [fetchDataImport, { data: dataImport }] = useLazyQuery(GET_DATA_IMPORT, {
    fetchPolicy: 'no-cache',
  });

  const [fetchContract, { data: contract }] = useLazyQuery(FETCH_CONTRACT, {
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    if (oto?.ocean_transport_order) {
      setRecord(oto.ocean_transport_order);
    } else if (container?.fetch_container) {
      setRecord(container.fetch_container);
    } else if (shipment?.shipment) {
      setRecord(shipment.shipment);
    } else if (document?.fetch_document) {
      setRecord(document.fetch_document);
    } else if (stockOrderItem?.fetch_stock_order_item) {
      setRecord(stockOrderItem.fetch_stock_order_item);
    } else if (messageData?.message) {
      setRecord(messageData.message);
    } else if (voyageSchedule?.voyage_schedule) {
      if (!isStateTreeNode(voyageSchedule?.voyage_schedule)) {
        setRecord(VoyageSchedule.create(voyageSchedule?.voyage_schedule));
        return;
      }
      setRecord(voyageSchedule?.voyage_schedule);
    } else if (freightContract?.freight_contract) {
      setRecord(freightContract.freight_contract);
    } else if (shipmentCustomDetail?.shipment_custom_detail) {
      setRecord(shipmentCustomDetail?.shipment_custom_detail);
    } else if (inquiryDetail?.get_inquiry) {
      setRecord(inquiryDetail?.get_inquiry);
    } else if (inquiryOptionDetail?.get_inquiry_option) {
      setRecord(inquiryOptionDetail?.get_inquiry_option);
    } else if (viewSubscriptionData?.view_subscription) {
      setRecord(viewSubscriptionData?.view_subscription);
    } else if (productData?.get_product_order) {
      setRecord(productData?.get_product_order);
    } else if (shipmentManifestsData?.shipment_manifest) {
      setRecord(shipmentManifestsData?.shipment_manifest);
    } else if (vehicle?.get_vehicle) {
      setRecord(vehicle?.get_vehicle);
    } else if (driver?.get_driver) {
      setRecord(driver?.get_driver);
    } else if (template?.template) {
      setRecord(template?.template);
    } else if (commercialInvoice?.get_commercial_invoice) {
      setRecord(commercialInvoice?.get_commercial_invoice);
    } else if (customField?.field) {
      setRecord(customField?.field);
    } else if (companyData?.company) {
      setRecord(companyData?.company);
    } else if (salesPerson?.fetch_sales_person) {
      setRecord(salesPerson?.fetch_sales_person);
    } else if (userContact?.fetch_user_contact) {
      setRecord(userContact?.fetch_user_contact);
    } else if (wmsTransactionData?.get_wms_transaction) {
      setRecord(wmsTransactionData?.get_wms_transaction);
    } else if (taskData?.task) {
      setRecord(taskData?.task);
    } else if (WmsProductData?.get_product) {
      setRecord(WmsProductData?.get_product);
    } else if (ticket?.service_management_ticket) {
      setRecord(ticket?.service_management_ticket);
    } else if (wmsWarehouseUnitData?.get_warehouse_unit) {
      setRecord(wmsWarehouseUnitData?.get_warehouse_unit);
    } else if (cargo?.fetch_cargo) {
      setRecord(cargo?.fetch_cargo);
    } else if (shipmentRoute?.get_shipment_route) {
      setRecord(shipmentRoute?.get_shipment_route);
    } else if (dataImport?.get_data_import) {
      setRecord(dataImport?.get_data_import);
    } else if (contract?.get_contract) {
      setRecord(contract?.get_contract);
    }
  }, [
    oto,
    container,
    shipment,
    document,
    stockOrderItem,
    messageData,
    voyageSchedule,
    freightContract,
    shipmentCustomDetail,
    inquiryDetail,
    inquiryOptionDetail,
    viewSubscriptionData,
    productData,
    shipmentManifestsData,
    vehicle,
    driver,
    template,
    commercialInvoice,
    customField,
    salesPerson,
    userContact,
    wmsTransactionData,
    taskData,
    WmsProductData,
    ticket,
    companyData?.company,
    wmsWarehouseUnitData,
    cargo,
    shipmentRoute,
    dataImport,
    contract,
  ]);

  useEffect(() => {
    if (!selectedActions && record) {
      if (doc_type_id === 'Network::Company') {
        setMenuItem([
          ...// (!record?.connection_request || record.connection_request?.status === 'accepted' // if company is in connection stage, we don't show company specific actions
          getCompanyActions(record, user, props.isDetailScreen || false),
          // : []),
          ...getConnectionRequestActions(record, user),
        ]);
      } else if (doc_type_id === 'Shipment::OceanTransportOrder') {
        setMenuItem(getOTOActions(record, navigate));
      } else if (doc_type_id === 'Shipment::Shipment') {
        let shipment = record;
        if (!isStateTreeNode(shipment)) {
          shipment = Shipment.create(record);
        }
        setMenuItem(
          getShipmentActions(
            shipment,
            permissions,
            setEmailProps,
            setVisible,
            user,
            client,
            navigate,
            getFetchDocumentFunction,
            config_data,
            props.isDetailScreen
          )
        );
      } else if (doc_type_id === 'Shipment::ShipmentDocument') {
        setMenuItem(
          getShipmentDocumentActions(
            record,
            record.shipment,
            client,
            user,
            setEmailProps,
            setVisible,
            navigate
          )
        );
      } else if (doc_type_id === 'Shipment::StockOrderItem') {
        setMenuItem(getStockOrderActions(record.status));
      } else if (doc_type_id === 'Shipment::ShipmentContainer') {
        setMenuItem(getContainerActions(record, setEmailProps, setVisible, user));
      } else if (doc_type_id === 'Email::Message') {
        setMenuItem(getMessageAction(record, user));
      } else if (doc_type_id === 'Network::VoyageSchedule') {
        setMenuItem(getVoyageScheduleAction(record, navigate));
      } else if (doc_type_id === 'NewAccounting::FreightContract') {
        setMenuItem(getFreightContractActions(record));
      } else if (doc_type_id === 'Shipment::ShipmentCustomDetail') {
        setMenuItem(getShipmentCustomsActions(record));
      } else if (doc_type_id === 'Docgen::GeneratedDocuments') {
        setMenuItem(getGeneratedDocumentActions());
      } else if (doc_type_id === 'NewAccounting::Invoices') {
        setMenuItem(getAccountsActions(record, user));
      } else if (doc_type_id === 'SalesHub::Inquiry') {
        setMenuItem(getInquiryActions(record, setEmailProps, setVisible, user));
      } else if (doc_type_id === 'SalesHub::InquiryOption') {
        setMenuItem(getInquiryOptionActions(record, setEmailProps, setVisible, user));
      } else if (doc_type_id === 'ReportManager::ViewSubscription') {
        setMenuItem(getViewSubscriptionActions(record, user));
      } else if (doc_type_id === 'OrderManagement::ProductOrder') {
        setMenuItem(getProductOrderActions(record, user));
      } else if (doc_type_id === 'Shipment::ShipmentManifest') {
        setMenuItem(getShipmentManifestActions(record, user));
      } else if (doc_type_id === 'Shipment::Vehicle') {
        setMenuItem(getVehicleActions(record));
      } else if (doc_type_id === 'Shipment::Driver') {
        setMenuItem(getDriverActions(record));
      } else if (doc_type_id === 'Network::Template') {
        setMenuItem(getTemplateAction(record, navigate));
      } else if (doc_type_id === 'Shipment::ShipmentInvoice') {
        setMenuItem(getCommercialInvoiceActions(record, user));
      } else if (doc_type_id === 'Network::SalesPerson') {
        setMenuItem(getSalesPersonActions(record, client, user));
      } else if (doc_type_id === 'Network::UserContact') {
        setMenuItem(getUserContactActions(record, client, permissions));
      } else if (
        doc_type_id === 'Wms::WarehouseTransaction' ||
        doc_type_id === 'Wms::IncomingShipment' ||
        doc_type_id === 'Wms::Receipt' ||
        doc_type_id === 'Wms::OutgoingShipment' ||
        doc_type_id === 'Wms::Delivery' ||
        doc_type_id === 'Wms::GoodsTransfer'
      ) {
        setMenuItem(getWmsTransactionActions(record, user));
      } else if (doc_type_id === 'ServiceManagement::Ticket::Ticket') {
        setMenuItem(getTicketActions(record));
      } else if (doc_type_id === 'Wms::WarehouseUnit') {
        setMenuItem(getWmsWarehouseUnitActions(record, user));
      } else if (doc_type_id === 'Email::Task') {
        setMenuItem(getTaskActions(record));
      } else if (doc_type_id === 'Wms::Product') {
        setMenuItem(getWmsProductActions(record, user));
      } else if (doc_type_id === 'ServiceManagement::Ticket::Ticket') {
        setMenuItem(getTicketActions(record));
      } else if (doc_type_id === 'Shipment::Cargo') {
        setMenuItem(getCargoActions(record));
      } else if (doc_type_id === 'Shipment::Route') {
        setMenuItem(getShipmentRouteActions(record, client));
      } else if (doc_type_id === 'Blueprint::DataImport') {
        setMenuItem(getDataImportActions(record, permissions));
      } else if (
        [
          'RateManagement::RoadContract',
          'RateManagement::AirContract',
          'RateManagement::OceanContract',
          'RateManagement::OceanLocalsContract',
          'RateManagement::AirDueAgentContract',
        ].includes(doc_type_id)
      ) {
        setMenuItem(getContractActions(record, navigate, user, client, !!props?.isDetailScreen));
      } else if (doc_type_id === 'RateManagement::Contract') {
        setMenuItem(getContractActions(record, navigate, user, client, !!props?.isDetailScreen));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [record]);

  useEffect(() => {
    setActionWithtype(groupBy(menuItems, 'type'));
  }, [menuItems]);

  const handleActions = async (action: Action) => {
    let result: PerformAction | undefined;
    if (doc_type_id === 'Network::Company') {
      if (action.performAction && record) {
        let company = record;
        if (!isStateTreeNode(company)) {
          company = Company.create(record);
        }
        result = await action.performAction({
          company,
          sessionData: user,
          extraProps: action.extraProps,
          navigate,
          setEmailProps,
          setVisible,
        });
      }
    } else if (doc_type_id === 'Shipment::OceanTransportOrder') {
      if (action.performAction && record) {
        let oceanTransportOrder = record;
        if (!isStateTreeNode(record)) {
          oceanTransportOrder = OceanTransportOrder.create(record);
        }
        result = await action.performAction({
          oceanTransportOrder,
          setEmailProps,
          setVisible,
          sessionData: user,
          extraProps: action.extraProps,
          navigate,
          config_data,
          client,
        });
      }
    } else if (doc_type_id === 'Shipment::Shipment') {
      if (action.performAction && record) {
        let shipment = record;
        if (!isStateTreeNode(record)) {
          shipment = Shipment.create(record);
        }
        result = await action.performAction({
          shipment,
          setEmailProps,
          setVisible,
          sessionData: user,
          extraProps: action.extraProps,
          navigate,
          config_data,
          client,
          getFetchDocumentFunction,
        });
      }
    } else if (doc_type_id === 'Shipment::ShipmentDocument') {
      if (action.performAction && record) {
        let shipment = record.shipment;
        if (!isStateTreeNode(record)) {
          shipment = Shipment.create(record.shipment);
        }
        result = await action.performAction({
          shipment_document: record,
          shipment,
          setEmailProps,
          setVisible,
          sessionData: user,
          extraProps: action.extraProps,
          navigate,
          config_data,
          client,
          getFetchDocumentFunction,
        });
      }
    } else if (doc_type_id === 'Shipment::StockOrderItem') {
      if (action.performAction && record) {
        result = await action.performAction({
          stock_order: record.stock_order,
          stock_order_item_id: record.id,
          awb_number: record.awb_number,
          setEmailProps,
          setVisible,
          sessionData: user,
          extraProps: action.extraProps,
          navigate,
        });
      }
    } else if (doc_type_id === 'Shipment::ShipmentContainer') {
      if (action.performAction && record) {
        result = await action.performAction({
          container: record,
          setEmailProps,
          setVisible,
          sessionData: user,
          extraProps: action.extraProps,
          navigate,
        });
      }
    } else if (doc_type_id === 'Email::Message') {
      if (action.performAction && record) {
        result = await action.performAction({
          message: record,
          setEmailProps,
          setVisible,
          sessionData: user,
          extraProps: action.extraProps,
          navigate,
        });
      }
    } else if (doc_type_id === 'NewAccounting::FreightContract') {
      if (action.performAction && record) {
        result = await action.performAction({
          freight_contract: record,
          setEmailProps,
          setVisible,
          sessionData: user,
          extraProps: action.extraProps,
          navigate,
        });
      }
    } else if (doc_type_id === 'Shipment::ShipmentCustomDetail') {
      if (action.performAction && record) {
        result = await action.performAction({
          shipment_custom_detail: record,
          setEmailProps,
          setVisible,
          sessionData: user,
          extraProps: action.extraProps,
          navigate,
        });
      }
    } else if (doc_type_id === 'Docgen::GeneratedDocuments') {
      if (action.performAction && record) {
        result = await action.performAction({
          document: record,
          setEmailProps,
          setVisible,
          sessionData: user,
          extraProps: action.extraProps,
          navigate,
        });
      }
    } else if (doc_type_id === 'NewAccounting::Invoices') {
      if (action.performAction && record) {
        result = await action.performAction({ voucher: record, sessionData: user });
      }
    } else if (doc_type_id === 'SalesHub::Inquiry') {
      if (action.performAction && record) {
        let inquiry = record;
        if (!isStateTreeNode(inquiry)) {
          inquiry = Inquiry.create(record);
        }
        result = await action.performAction({
          inquiry,
          setEmailProps,
          setVisible,
          sessionData: user,
          extraProps: action.extraProps,
          navigate,
        });
      }
    } else if (doc_type_id === 'SalesHub::InquiryOption') {
      if (action.performAction && record) {
        const inquiryOption = record;
        if (inquiryOption?.inquiry && !isStateTreeNode(inquiryOption.inquiry)) {
          inquiryOption.inquiry = Inquiry.create(inquiryOption.inquiry);
        }
        result = await action.performAction({
          inquiryOption,
          setEmailProps,
          setVisible,
          sessionData: user,
          extraProps: action.extraProps,
          navigate,
        });
      }
    } else if (doc_type_id === 'ReportManager::ViewSubscription') {
      if (action.performAction && record) {
        result = await action.performAction({
          viewSubscription: record,
        });
      }
    } else if (doc_type_id === 'OrderManagement::ProductOrder') {
      if (action.performAction && record) {
        let productOrder = record;
        if (!isStateTreeNode(productOrder)) {
          productOrder = ProductOrder.create(record);
        }
        result = await action.performAction({
          productOrder,
          setEmailProps,
          setVisible,
          sessionData: user,
          extraProps: action.extraProps,
          navigate,
        });
      }
    } else if (doc_type_id === 'Shipment::ShipmentManifest') {
      if (action.performAction && record) {
        result = await action.performAction({
          shipmentManifest: record,
        });
      }
    } else if (doc_type_id === 'Shipment::Vehicle') {
      if (action.performAction && record) {
        result = await action.performAction({
          vehicle: record,
        });
      }
    } else if (doc_type_id === 'Shipment::Driver') {
      if (action.performAction && record) {
        result = await action.performAction({
          driver: record,
        });
      }
    } else if (doc_type_id === 'Network::Template') {
      if (action.performAction && record) {
        result = await action.performAction({
          template: record,
        });
      }
    } else if (doc_type_id === 'Shipment::ShipmentInvoice') {
      if (action.performAction && record) {
        result = await action.performAction({
          commercialInvoice: record,
        });
      }
    } else if (doc_type_id === 'Network::Company') {
      if (action.performAction && record) {
        let company = record;
        if (!isStateTreeNode(company)) {
          company = Company.create(record);
        }
        result = await action.performAction({
          company,
          sessionData: user,
          extraProps: action.extraProps,
          navigate,
          setEmailProps,
          setVisible,
        });
      }
    } else if (doc_type_id === 'Network::SalesPerson') {
      if (action.performAction && record) {
        result = await action.performAction({
          salesPerson: record,
        });
      }
    } else if (doc_type_id === 'Shipment::Cargo') {
      if (action.performAction && record) {
        result = await action.performAction({
          cargo: record,
          selectedIds: [id],
          setEmailProps,
          setVisible,
          sessionData: user,
        });
      }
    } else if (doc_type_id === 'Network::UserContact') {
      if (action.performAction && record) {
        result = await action.performAction({
          userContact: record,
        });
      }
    } else if (
      doc_type_id === 'Wms::WarehouseTransaction' ||
      doc_type_id === 'Wms::IncomingShipment' ||
      doc_type_id === 'Wms::Receipt' ||
      doc_type_id === 'Wms::OutgoingShipment' ||
      doc_type_id === 'Wms::Delivery'
    ) {
      if (action.performAction && record) {
        const transaction = record;

        result = await action.performAction({
          transaction,
          setEmailProps,
          setVisible,
          sessionData: user,
          extraProps: action.extraProps,
          navigate,
        });
      }
    } else if (doc_type_id === 'Email::Task') {
      if (action.performAction && record) {
        result = await action.performAction({ task: record });
      }
    } else if (doc_type_id === 'ServiceManagement::Ticket::Ticket') {
      if (action.performAction && record) {
        result = await action.performAction({
          ticket: record,
        });
      }
    } else if (doc_type_id === 'Wms::WarehouseUnit') {
      if (action.performAction && record) {
        const transaction = record;

        result = await action.performAction({
          transaction,
          setEmailProps,
          setVisible,
          sessionData: user,
          extraProps: action.extraProps,
          navigate,
        });
      }
    } else if (doc_type_id === 'Email::Task') {
      if (action.performAction && record) {
        result = await action.performAction({ task: record });
      }
    } else if (doc_type_id === 'Wms::Product') {
      if (action.performAction && record) {
        result = await action.performAction({ wmsProduct: record });
      }
    } else if (doc_type_id === 'ServiceManagement::Ticket::Ticket') {
      if (action.performAction && record) {
        result = await action.performAction({
          ticket: record,
        });
      }
    } else if (doc_type_id === 'Blueprint::DataImport') {
      if (action.performAction && record) {
        result = await action.performAction({
          dataImport: record,
        });
      }
    } else if (doc_type_id === 'RateManagement::Contract') {
      if (action.performAction && record) {
        result = await action.performAction({ contract: record, extraProps: action.extraProps });
      }
    }

    if (result) {
      setActionParams(result?.actionParams);
      if (result) {
        setTaskComponent(() => result?.component);
      }
    }
  };

  const onSuccess = (...args: any[]) => {
    if (actionParams?.onSuccess) {
      actionParams.onSuccess(...args);
    }
    refetch();
    if (onCloseModal) onCloseModal();
  };

  const onCloseModal = () => {
    setTaskComponent(undefined);
    setActionParams({});
  };

  const fetchData = async () => {
    if (!record) {
      if (doc_type_id === 'Network::Company') {
        fetchCompanyData({ variables: { id: id } });
      } else if (doc_type_id === 'Shipment::OceanTransportOrder') {
        fetchOto({ variables: { id: id } });
      } else if (doc_type_id === 'Shipment::Shipment') {
        fetchShipment({ variables: { id: id } });
      } else if (doc_type_id === 'Shipment::ShipmentDocument') {
        fetchShipmentDocument({ variables: { id: id } });
      } else if (doc_type_id === 'Shipment::StockOrderItem') {
        fetchStockOrderItem({ variables: { id: id } });
      } else if (doc_type_id === 'Shipment::ShipmentContainer') {
        fetchContainers({ variables: { id: id } });
      } else if (doc_type_id === 'Email::Message') {
        fetchMessage({ variables: { id: id } });
      } else if (doc_type_id === 'Network::VoyageSchedule') {
        fetchVoyageSchedule({ variables: { id: id } });
      } else if (doc_type_id === 'NewAccounting::FreightContract') {
        fetchFreightContract({ variables: { id: id } });
      } else if (doc_type_id === 'Shipment::ShipmentCustomDetail') {
        fetchShipmentCustomDetail({ variables: { id: id } });
      } else if (doc_type_id === 'Docgen::GeneratedDocuments') {
        const { response, error } = await fetchDocument(id, process.env.DOCGEN_URL || '');
        if (!!error) {
          message.error('Unable to fetch Document');
        }
        if (response) {
          setRecord(response?.data?.generate_document);
        }
      } else if (doc_type_id === 'SalesHub::Inquiry') {
        fetchInquiry({ variables: { id: id } });
      } else if (doc_type_id === 'SalesHub::InquiryOption') {
        fetchInquiryOption({ variables: { id: id } });
      } else if (doc_type_id === 'ReportManager::ViewSubscription') {
        fetchViewSubscription({ variables: { id: id } });
      } else if (doc_type_id === 'OrderManagement::ProductOrder') {
        fetchProductOrder({ variables: { id: id } });
      } else if (doc_type_id === 'Shipment::ShipmentManifest') {
        fetchShipmentManifests({ variables: { id: id } });
      } else if (doc_type_id === 'Shipment::Vehicle') {
        fetchVehicle({ variables: { id: id } });
      } else if (doc_type_id === 'Shipment::Driver') {
        fetchDriver({ variables: { id: id } });
      } else if (doc_type_id === 'Network::Template') {
        fetchTemplate({ variables: { id: id } });
      } else if (doc_type_id === 'Shipment::ShipmentInvoice') {
        fetchCommercialInvoice({ variables: { id: id } });
      } else if (doc_type_id === 'Blueprint::CustomField') {
        fetchCustomFields({ variables: { id: id } });
      } else if (doc_type_id === 'Network::SalesPerson') {
        fetchSalesPerson({ variables: { id: id } });
      } else if (doc_type_id === 'Network::UserContact') {
        fetchUserContacts({ variables: { id: id } });
      } else if (
        doc_type_id === 'Wms::WarehouseTransaction' ||
        doc_type_id === 'Wms::IncomingShipment' ||
        doc_type_id === 'Wms::Receipt' ||
        doc_type_id === 'Wms::OutgoingShipment' ||
        doc_type_id === 'Wms::Delivery'
      ) {
        fetchWMSTransaction({ variables: { id: id } });
      } else if (doc_type_id === 'ServiceManagement::Ticket::Ticket') {
        fetchTicket({ variables: { id: id } });
      } else if (doc_type_id === 'Wms::WarehouseUnit') {
        fetchWMSWarehouseUnit({ variables: { id: id } });
      } else if (doc_type_id === 'Email::Task') {
        // setRecord(data);
        fetchTask({ variables: { id: id } });
      } else if (doc_type_id === 'Wms::Product') {
        fetchWmsProduct({ variables: { id: id } });
      } else if (doc_type_id === 'ServiceManagement::Ticket::Ticket') {
        fetchTicket({ variables: { id: id } });
      } else if (doc_type_id === 'Shipment::Cargo') {
        fetchCargo({ variables: { id: id } });
      } else if (doc_type_id === 'Shipment::Route') {
        fetchShipmentRoute({ variables: { id: id } });
      } else if (doc_type_id === 'Blueprint::DataImport') {
        fetchDataImport({ variables: { id: id } });
      } else if (
        [
          'RateManagement::RoadContract',
          'RateManagement::AirContract',
          'RateManagement::OceanContract',
        ].includes(doc_type_id)
      ) {
      } else if (doc_type_id === 'RateManagement::Contract') {
        fetchContract({ variables: { id: id } });
      }
    }
  };

  const renderActions: Action[][] = [];
  [PRIMARY_TYPE, SECONDARY_TYPE, TERTIARY_TYPE, NEGATIVE_TYPE].forEach((type: string) => {
    if (actionsWithType && actionsWithType[type]) {
      const currentAction = actionsWithType[type].filter((action) => action.isEnable);
      if (currentAction.length > 0) {
        renderActions.push(currentAction);
      }
    }
  });
  const menuItemsList: MenuItem[] = [];
  const renderActionByMenuItems = () => {
    if (data && menuItems.length === 1) {
      if (menuItems[0].childComponents) {
        const childComponentsLength = menuItems[0].childComponents.filter(
          (c: any) => c.isEnable
        ).length;

        const getItem = menuItems[0].childComponents.map((child: any) =>
          child.isEnable
            ? {
                key: child.key,
                onClick: () => {
                  handleActions(child);
                  if (child.onClick) {
                    child.onClick(onSuccess);
                  }
                },
                className: getActionClassName(menuItems[0].type, !!menuItems[0].isAdmin),
                icon: child.icon,
                label: child.displayComponent,
              }
            : null
        );
        return (
          <>
            {childComponentsLength > 0 && (
              <Dropdown
                menu={{ items: getItem }}
                key={menuItems[0].key}
                trigger={['click']}
                placement="bottomLeft"
              >
                <Button type={menuItems[0].type === 'primary' ? 'primary' : undefined}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <span style={{ marginRight: '8px' }} className="action-icon">
                      {menuItems[0].icon}
                    </span>
                    {menuItems[0].displayComponent}
                  </div>
                </Button>
              </Dropdown>
            )}
          </>
        );
      } else {
        const buttonType = menuItems[0].type === 'primary' ? 'primary' : undefined;
        return (
          <>
            {menuItems[0].isEnable && (
              <Button
                type={buttonType}
                onClick={() => {
                  handleActions(menuItems[0]);
                  if (menuItems[0].onClick) {
                    menuItems[0].onClick(onSuccess);
                  }
                }}
                size={buttonSize}
              >
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <span style={{ marginRight: '8px' }} className="action-icon">
                    {menuItems[0].icon}
                  </span>
                  {menuItems[0].displayComponent}
                </div>
              </Button>
            )}
          </>
        );
      }
    } else {
      const getDropDownItems = () => {
        if (record && renderActions.length === 0)
          menuItemsList.push({
            disabled: true,
            label: 'No Actions Available',
            key: '0',
          });
        if (!record && renderActions.length === 0) {
          menuItemsList.push({
            key: '0',
            label: <Skeleton active paragraph={{ width: '150px' }} />,
          });
        }
        renderActions.map((actions: Action[], actionsId: number) => {
          return actions.map((item: Action, index: number) => {
            let description = item.displayComponent;
            if (typeof item.displayComponent === 'function') {
              const ComponentWrapper = item.displayComponent;
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              description = <ComponentWrapper key={item.key} {...item.componentProps} />;
            }
            if (!item.isEnable) {
              return null;
            }
            if (item.childComponents && item.childComponents.length === 0) return null;
            if (item.childComponents && item.childComponents.length > 0) {
              const childComponentsLength = item.childComponents.filter(
                (c: any) => c.isEnable
              ).length;
              if (childComponentsLength > 0) {
                menuItemsList.push({
                  key: item.key,
                  icon: item.icon,
                  label: description + ` (${childComponentsLength})`,
                  children: item.childComponents.map((child: any) => {
                    if (child.isEnable) {
                      return {
                        key: child.key,
                        onClick: () => {
                          handleActions(child);
                          if (child.onClick) {
                            child.onClick(onSuccess);
                          }
                        },
                        className: getActionClassName(item.type, !!item.isAdmin),
                        icon: child.icon,
                        label: child.displayComponent,
                        disabled: false,
                      };
                    }
                    return null;
                  }),
                });
              }
              if (index === actions.length - 1 && actionsId !== renderActions.length - 1) {
                menuItemsList.push({
                  type: 'divider',
                });
              }
            }
            if (!item.childComponents) {
              menuItemsList.push({
                key: item.key,
                className: getActionClassName(item.type, !!item.isAdmin),
                onClick: () => {
                  handleActions(item);
                  if (item.onClick) {
                    item.onClick(onSuccess);
                  }
                },
                icon: item.icon,
                label: description,
              });
              if (index === actions.length - 1 && actionsId !== renderActions.length - 1)
                menuItemsList.push({
                  type: 'divider',
                });
            }

            return null;
          });
        });
        return menuItemsList;
      };

      return (
        <Dropdown
          placement="bottomLeft"
          disabled={!!TaskComponent}
          menu={{
            style: { minWidth: '200px' },
            className: 'actions-menu',
            items: getDropDownItems(),
          }}
          trigger={['click']}
        >
          {props?.children ? (
            <div style={{ cursor: 'pointer' }} onClick={fetchData}>
              {props?.children}
            </div>
          ) : !props.isDetailScreen ? (
            <Button size="small" onClick={fetchData}>
              <MoreOutlined rotate={90} />
            </Button>
          ) : (
            <Button size="small" onClick={fetchData}>
              Actions <DownOutlined />
            </Button>
          )}
        </Dropdown>
      );
    }
  };

  // TODO: Remove any on onclose or oncancel
  return (
    <>
      {renderActionByMenuItems()}
      {TaskComponent && (
        <TaskComponent
          {...actionParams}
          visible={true}
          onClose={onCloseModal} // make sure that you are handling onClose and onSuccess
          onCancel={onCloseModal} // if not handled, it will not reopen modal
          onSuccess={onSuccess} // onSuccess it will refetch report
        />
      )}
    </>
  );
}
