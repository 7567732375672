import React, { forwardRef, useEffect, useState } from 'react';
import { ApplicationPropsType } from '../../../types';
import { Tag } from '@shipmnts/pixel-hub';

const SeaRatesTracker = forwardRef<HTMLDivElement, ApplicationPropsType>(
  ({ container, isSubscribed, app }, ref) => {
    // States
    const [errorOccures, setErrorOccures] = useState<boolean>(false);

    // Effects
    useEffect(() => {
      fetch(
        `https://sirius.searates.com/tracking?nocontroll=1&container=${container?.container_number}&sealine=0`,
        { referrerPolicy: 'no-referrer' }
      )
        .then((res) => setErrorOccures(false))
        .catch((err) => {
          setErrorOccures(true);
        });
    }, [container]);

    return (
      <div className="activity-box carrier-tracking" ref={ref}>
        <div className="title">{app?.label}</div>
        <div style={{ marginTop: '24px' }}>
          <iframe
            height="500"
            id="container-tracking"
            scrolling="auto"
            width="100%"
            title="searates-container-tracking"
            src={`https://sirius.searates.com/tracking?nocontroll=1&container=${container?.container_number}&sealine=0`}
            referrerPolicy="no-referrer"
          ></iframe>
          {errorOccures && (
            <Tag type="critical">
              Some error occured in loading map. Please disable Ad Blocker and try again
            </Tag>
          )}
        </div>
      </div>
    );
  }
);

SeaRatesTracker.displayName = 'SeaRatesTracker';

export default SeaRatesTracker;
