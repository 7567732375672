import React, { useState } from 'react';
import { useCompanyView } from '../CompanyView';
import { EmailPreferenceCard } from '../Cards/EmailPreferenceCard';
import { Button, Card } from '@shipmnts/pixel-hub';
import {
  AutoEmailSubscriptionListByResource,
  AutoEmailFinanceSubscriptionForm,
  AutoEmailFinanceSubscriptionList,
  AutoEmailSubscriptionForm,
} from '@shipmnts/pixel-hub';
import { useSession, ADMINISTRATION_ACCESS_LEVELS } from 'common';
// import AutoEmailFinanceSubscriptionForm from 'common/report_manager/components/ViewExecution/AutoEmailFinanceSubscriptionForm';
// const AutoEmailFinanceSubscriptionList = lazy(
//   () => import('common/report_manager/components/ViewExecution/AutoEmailFinanceSubscriptionList')
// );
// const AutoEmailSubscriptionListByResource = lazy(
//   () => import('common/report_manager/components/ViewExecution/AutoEmailSubscriptionList')
// );

export const NotificationTab = () => {
  const { company } = useCompanyView();
  const [visibleAutoEmail, setVisibleAutoEmail] = useState(false);
  const [visibleFinAutoEmail, setVisibleFinAutoEmail] = useState(false);
  const session = useSession();

  return (
    <div
      style={{
        width: '80%',
        marginInline: 'auto',
        marginBlock: '1em 2em',
      }}
    >
      <Card
        title="Operation Auto Email"
        key="auto_email"
        size="small"
        headStyle={{ border: 'none' }}
        extra={[
          <Button
            // loading={}
            key="save"
            onClick={() => setVisibleAutoEmail(true)}
            type="primary"
            style={{ marginLeft: '12px' }}
            disabled={
              company?.is_disabled ||
              (company?.company_group === 'Self' &&
                !ADMINISTRATION_ACCESS_LEVELS.includes(session?.user_level || ''))
            }
            size="small"
          >
            Create New Auto Email
          </Button>,
        ]}
      >
        <AutoEmailSubscriptionListByResource
          resourceId={company?.id}
          key={visibleAutoEmail ? 0 : 1}
          resourceType={'Network::Company'}
          showDrawer={false}
        />
      </Card>
      <br />

      <Card
        title="Finance Auto Email"
        key="auto_email_template"
        size="small"
        headStyle={{ border: 'none' }}
        extra={[
          <Button
            // loading={}
            key="save"
            onClick={() => setVisibleFinAutoEmail(true)}
            type="primary"
            style={{ marginLeft: '12px' }}
            disabled={
              company?.is_disabled ||
              (company?.company_group === 'Self' &&
                !ADMINISTRATION_ACCESS_LEVELS.includes(session?.user_level || ''))
            }
            size="small"
          >
            Create New Auto Email
          </Button>,
        ]}
      >
        <AutoEmailFinanceSubscriptionList
          key={visibleFinAutoEmail ? 0 : 1}
          companyName={company?.registered_name}
        />
      </Card>
      <br />
      {company && <EmailPreferenceCard />}
      <AutoEmailSubscriptionForm
        isVisible={visibleAutoEmail}
        setIsVisible={setVisibleAutoEmail}
        defaultRecurring={true}
        resource_type="Network::Company"
        resource={company}
      />
      <AutoEmailFinanceSubscriptionForm
        isVisible={visibleFinAutoEmail}
        setIsVisible={setVisibleFinAutoEmail}
        resource={company}
      />
    </div>
  );
};
