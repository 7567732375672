import React, { useCallback } from 'react';
import { BaseTable, RenderLinkByDocType, SearchDocTypeEditor, dayjs } from '@shipmnts/pixel-hub';
import { compact as _compact } from 'lodash';
import { Column } from 'operations/models/Report';
import { useBillingReportContext } from '../BillingReportProvider';
import { HANDLING_REPORT } from '../BillingFilterHelpers';

const HandlingReportTable = () => {
  const { reportRows } = useBillingReportContext();

  const getColumns = useCallback(() => {
    const columnDefs: Column[] = _compact([
      {
        headerName: 'Transaction Date',
        field: 'wms_warehouse_transactions_transaction_date',
        colId: 'wms_warehouse_transactions_transaction_date',
        columnType: 'Date',
        valueGetter: (params: any) => {
          const date = params?.data?.wms_warehouse_transactions_transaction_date;
          return date ? dayjs(date).format('ddd, DD MMM YYYY') : '';
        },
      },
      {
        headerName: 'Transaction #',
        field: 'wms_warehouse_transactions_transaction_number',
        colId: 'wms_warehouse_transactions_transaction_number',
        columnType: 'String',
        cellRenderer: (params: any) => {
          if (params.node.isRowPinned()) return <></>;
          return (
            <RenderLinkByDocType
              key={params?.data?.wms_warehouse_transactions_transaction_number?.extra_fields?.id}
              doc_type_id={'Wms::WarehouseTransaction'}
              id={params?.data?.wms_warehouse_transactions_transaction_number?.extra_fields?.id}
              value={params?.data?.wms_warehouse_transactions_transaction_number?.value}
              extra_fields={{}}
              colDef={undefined}
            />
          );
        },
        valueGetter: (params: any) => {
          return params?.data?.wms_warehouse_transactions_transaction_number?.value;
        },
      },
      {
        headerName: 'Item',
        field: 'shipment_estimates_item',
        colId: 'shipment_estimates_item',
        columnType: 'String',
      },
      {
        headerName: 'Qty',
        field: 'shipment_estimates_quantity',
        colId: 'shipment_estimates_quantity',
        columnType: 'Float',
        editable: false,
      },
      {
        headerName: 'UOM',
        field: 'shipment_estimates_uom',
        colId: 'shipment_estimates_uom',
        columnType: 'String',
      },
      {
        headerName: 'Sell Currency',
        field: 'shipment_estimates_sell_currency',
        colId: 'shipment_estimates_sell_currency',
        columnType: 'String',
      },
      {
        headerName: 'Sell Rate',
        field: 'shipment_estimates_sell_rate',
        colId: 'shipment_estimates_sell_rate',
        columnType: 'Float',
      },
      {
        headerName: 'Sell Exchange Rate',
        field: 'shipment_estimates_sell_exchange_rate',
        colId: 'shipment_estimates_sell_exchange_rate',
        columnType: 'String',
      },
      {
        headerName: 'Total Sell Amount',
        field: 'shipment_estimates_total_sell_amount',
        colId: 'shipment_estimates_total_sell_amount',
        columnType: 'Float',
      },
      {
        headerName: 'Sell Remarks',
        field: 'shipment_estimates_sell_remarks',
        colId: 'shipment_estimates_sell_remarks',
        columnType: 'String',
      },
    ]);
    return columnDefs;
    // eslint-disable-next-line
  }, []);
  const allColumnsDefs = getColumns() || [];
  const components = {
    SearchDocTypeEditor,
  };
  return (
    <div style={{ margin: '10px', padding: '0 10px' }}>
      <BaseTable
        reportName={HANDLING_REPORT}
        rowData={reportRows?.data || []}
        height={'70vh'}
        columns={allColumnsDefs}
        reportConfig={{
          components: components,
        }}
      />
    </div>
  );
};

export default HandlingReportTable;
