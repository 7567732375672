import React from 'react';
import { Drawer } from '@shipmnts/pixel-hub';
import CopilotChat from './CopilotChat';
import Logo from './CopilotLogo';

const CopilotChatDrawer = ({
  isOpen,
  setIsOpen,
}: {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}) => {
  return (
    <>
      <Drawer
        destroyOnClose
        width={'70%'}
        open={isOpen}
        onClose={() => setIsOpen(false)}
        title={
          <>
            <span style={{ height: '10px' }}>
              <Logo />{' '}
            </span>
            Chat With Alex Copilot
          </>
        }
      >
        <CopilotChat />
      </Drawer>
    </>
  );
};

export default CopilotChatDrawer;
