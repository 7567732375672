import { gql } from '@apollo/client';

export const routingLocationObjectType = gql`
  fragment routingLocationObjectType on RoutingLocationObjectType {
    id
    name
    type
    unlocode
    city_code
    state_code
    country_code
    is_customs_location
  }
`;

export const routingNodeObjectType = gql`
  fragment routingNodeObjectType on RoutingNodeObjectType {
    id
    location_id
    location {
      ...routingLocationObjectType
    }
    terminal {
      ...routingLocationObjectType
    }
    terminal_id
    active_port_call
    doc_cutoff_date
    form13_cutoff_date
    gate_close_date
    gate_open_date
    load_list_cutoff_date
    reefer_gate_close_date
    reefer_gate_open_date
    si_cutoff_date
    vgm_cutoff_date
    icd_cutoff_date
    ams_cutoff_date
  }
  ${routingLocationObjectType}
`;

export const voyageScheduleObjectType = gql`
  fragment voyageScheduleObjectType on VoyageScheduleObjectType {
    id
    service_name
    preferable_for_perishable
    voyage_number
    vessel_id
    carrier_ids
    carriers {
      id
      name
    }
    vessel {
      imo
      name
      mmsi
      flag_id
    }
    routing_legs {
      id
      sequence_number
      routing_type
      mode_of_transit
      estimated_time_of_arrival
      estimated_time_of_departure
      actual_time_of_departure
      origin_id
      destination_id
      origin {
        ...routingNodeObjectType
      }
      destination {
        ...routingNodeObjectType
      }
    }
  }
  ${routingNodeObjectType}
`;

export const CREATE_VOYAGE_SCHEDULE = gql`
  mutation createVoyageSchedule($voyage_schedule: VoyageScheduleInputType!) {
    create_voyage_schedule(voyage_schedule: $voyage_schedule) {
      ...voyageScheduleObjectType
    }
  }
  ${voyageScheduleObjectType}
`;

export const UPDATE_VOYAGE_SCHEDULE = gql`
  mutation updateVoyageSchedule(
    $id: ID!
    $voyage_schedule: VoyageScheduleInputType!
    $send_email: Boolean
  ) {
    update_voyage_schedule(id: $id, voyage_schedule: $voyage_schedule, send_email: $send_email) {
      ...voyageScheduleObjectType
    }
  }
  ${voyageScheduleObjectType}
`;

export const FETCH_VOYAGE_SCHEDULE_BY_ID = gql`
  query voyageSchedule($id: ID!) {
    voyage_schedule(id: $id) {
      ...voyageScheduleObjectType
    }
  }
  ${voyageScheduleObjectType}
`;

// Need just carrier ids, don't want to make it heavy
export const FETCH_VOYAGE_SCHEDULE_BY_ID_DUPLICATE = gql`
  query voyageSchedule($id: ID!) {
    voyage_schedule(id: $id) {
      id
      carriers {
        id
        name
      }
    }
  }
`;
