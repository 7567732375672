import { Col, Row, Tag, hasPermission } from '@shipmnts/pixel-hub';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { PERMISSION_ESTIMATES_TOTAL } from 'common/constants/constant';
import { useSession, decimalPointFormater } from 'common';

const TotalsHeadingStyles = { fontSize: '1rem' };
const TotalsValueStyles = {
  fontSize: '1rem',
  fontWeight: 'bold',
  paddingLeft: '0px',
};
const ColStyles = {
  display: 'flex',
  justifyContent: 'center', // Horizontally center content in each Col
};
interface ShipmentEstimatesTotalProps {
  useStore: () => any;
}

const ShipmentEstimatesTotal = observer(function ShipmentEstimatesTotal(
  props: ShipmentEstimatesTotalProps
) {
  const { permissions } = useSession();
  if (!props.useStore) return null;
  const { store } = props.useStore();
  const estimateTotalsAllowed = hasPermission(permissions, {
    name: PERMISSION_ESTIMATES_TOTAL,
    docType: 'NewAccounting::ShipmentEstimate',
  });
  const totalMargin = store.totalProvisionalSellAmount - store.totalProvisionalBuyAmount;

  if (!estimateTotalsAllowed) return null;

  return (
    <div
      style={{
        display: 'flex',
        width: '100%',
        height: 'auto',
        justifyContent: 'center',
        borderRadius: '4px',
        padding: '10px 0',
      }}
    >
      <Row
        gutter={[20, 5]}
        justify="center"
        style={{
          width: '100%',
        }}
      >
        <Col xs={24} md={8} style={ColStyles}>
          <Col style={TotalsHeadingStyles}>Prov. Income</Col>
          <Col style={TotalsValueStyles}>
            {decimalPointFormater(store.totalProvisionalSellAmount)}
          </Col>
        </Col>
        <Col xs={24} md={8} style={ColStyles}>
          <Col style={TotalsHeadingStyles}>Prov. Expense</Col>
          <Col style={TotalsValueStyles}>
            {decimalPointFormater(store.totalProvisionalBuyAmount)}
          </Col>
        </Col>
        <Col xs={24} md={8} style={ColStyles}>
          <Col style={TotalsHeadingStyles}>Prov. Margin</Col>
          <Col
            style={{
              ...TotalsValueStyles,
              color: totalMargin > 0 ? '#237804' : '#f00',
              display: 'flex',
              gap: '0.5rem',
              alignItems: 'center',
            }}
          >
            {decimalPointFormater(totalMargin)}
            {store.totalProvisionalSellAmount ? (
              <Tag type={totalMargin > 0 ? 'success' : 'critical'}>
                {decimalPointFormater((totalMargin / store.totalProvisionalSellAmount) * 100)}%
              </Tag>
            ) : (
              <></>
            )}
          </Col>
        </Col>
      </Row>
    </div>
  );
});

export default ShipmentEstimatesTotal;
