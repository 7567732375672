import React, { useState, useEffect, useMemo } from 'react';
import { debounce as _debounce } from 'lodash';
import { Select, Spin, Row, Col, Typography } from '@shipmnts/pixel-hub';
import { SearchOutlined } from '@ant-design/icons';
import { useLazyQuery } from '@apollo/client';
import { ProductOrderValue } from 'operations/models/ProductOrder';
import { PRODUCT_ORDERS_SEARCH } from './graphql/productOrder';

const { Option, OptGroup } = Select;
const { Text } = Typography;

const getSelectValue = (
  value: ProductOrderValue | Array<ProductOrderValue> | undefined | null,
  type: string
) => {
  if (Array.isArray(value)) {
    return value.map(
      (val) =>
        val && {
          value: val.id,
          label: type === 'sales' ? val.sales_order_number : val.purchase_order_number,
          key: val.id,
        }
    );
  }
  return value
    ? {
        value: value.id,
        label: value.sales_order_number,
        key: value.id,
      }
    : undefined;
};

const ProductOrderSearch = React.memo(function ProductOrderSearch(props: {
  value?: any;
  onSelectProductOrder: (
    value: ProductOrderValue | Array<ProductOrderValue> | undefined | null | null[]
  ) => void;
  productOrder: ProductOrderValue;
}): JSX.Element {
  const { value, onSelectProductOrder, productOrder } = props;
  const [errorMessage, setErrorMessage] = React.useState('');
  const hasSearchedOnce = React.useRef(false);
  const [fetching, setFetching] = useState(false);
  const [productOrders, setProductOrders] = useState<Array<ProductOrderValue>>([]);
  const [searchProductOrders, { data, error, loading }] = useLazyQuery(PRODUCT_ORDERS_SEARCH);

  useEffect(() => {
    if (data && data.product_orders_search) setProductOrders(data.product_orders_search);
  }, [data]);

  const buyerId = productOrder?.type === 'sales' ? productOrder.buyer?.id : null;
  const sellerId = productOrder?.type === 'purchase' ? productOrder.seller?.id : null,
    consigneeId = productOrder.ship_to_company?.id,
    shipperId = productOrder.ship_from_company?.id;

  const fetchData = useMemo(
    () =>
      _debounce(async (searchString: string) => {
        if (searchString) {
          hasSearchedOnce.current = true;
          setFetching(true);
          try {
            searchProductOrders({
              variables: {
                search_text: searchString,
                buyer_id: buyerId,
                seller_id: sellerId,
                ship_to_company_id: consigneeId,
                ship_from_company_id: shipperId,
                port_of_loading_id: productOrder?.port_of_loading?.id,
                port_of_discharge_id: productOrder?.port_of_discharge?.id,
                type: productOrder?.type,
              },
            });
          } catch (error) {
            if (error instanceof Error) setErrorMessage(error?.message);
            console.error(error);
          }
          setFetching(false);
        }
      }, 1000),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [productOrder, searchProductOrders]
  );

  let notFoundContent: any = undefined;
  if (!hasSearchedOnce.current) {
    notFoundContent = 'Start typing to search product orders';
  } else if (fetching) {
    notFoundContent = <Spin size="small" />;
  } else if (errorMessage) {
    notFoundContent = (
      <div className="text-color-secondary">
        Unable to fetch product orders. Please Search Again
      </div>
    );
  }

  const onProductOrderChange = (changeValue: any, data: any): void => {
    let br: ProductOrderValue | undefined | Array<ProductOrderValue> | null[] =
      data?.product_order || undefined;

    if (Array.isArray(changeValue)) {
      br = changeValue.map((val) => {
        let return_val = null;
        return_val = data.find(
          (product_order: { value: string; product_order?: ProductOrderValue }) =>
            product_order.value === val.value
        )?.product_order;
        if (!return_val) {
          return_val = (value || []).find((po: any) => po.id === val.value);
        }
        return return_val;
      });
    }
    if (onSelectProductOrder) onSelectProductOrder(br);
  };

  const getLabelValueItem = (label: string, value: string | undefined) => {
    return (
      <small>
        <span style={{ color: 'var(--text-primary-gray-1)', marginRight: '5px' }}>{label}:</span>
        <span style={{ fontWeight: 500, color: 'var(--text-primary-gray-1)', marginRight: '10px' }}>
          {value}
        </span>
      </small>
    );
  };
  const getSelectOption = (po: ProductOrderValue) => {
    const buyer = po?.buyer?.registered_name;
    const seller = po?.seller?.registered_name;

    return (
      <Option
        title={productOrder?.type === 'sales' ? po.sales_order_number : po.purchase_order_number}
        key={po.id}
        value={po.id}
        product_order={po}
      >
        <Row className="flex">
          <Col span={24}>
            <Text>
              <div>
                <b>
                  {productOrder?.type === 'sales'
                    ? po.sales_order_number
                    : po.purchase_order_number}
                </b>
              </div>
            </Text>
            <Text>
              <div>
                {productOrder?.type === 'sales'
                  ? getLabelValueItem('Buyer', buyer)
                  : getLabelValueItem('Seller', seller)}
              </div>
            </Text>
          </Col>
        </Row>
      </Option>
    );
  };

  return (
    <Select
      mode="multiple"
      value={getSelectValue(value, productOrder?.type || 'sales')}
      onChange={onProductOrderChange}
      placeholder="Eg: SO0000031"
      notFoundContent={notFoundContent}
      filterOption={false}
      showSearch
      onSearch={fetchData}
      allowClear
      optionLabelProp="title"
      labelInValue
      dropdownMatchSelectWidth={400}
      style={{ width: '100%' }}
      suffixIcon={<SearchOutlined />}
    >
      {!fetching && !loading && !error && productOrders.length > 0 && (
        <OptGroup label="ORDERS">
          {productOrders
            .filter(
              (po) =>
                (productOrder?.type === 'sales'
                  ? po.sales_order_number !== productOrder?.sales_order_number
                  : po.purchase_order_number !== productOrder?.purchase_order_number) &&
                po.freight_type === productOrder?.freight_type &&
                po.incoterm === productOrder?.incoterm
            )
            .map((po) => {
              return getSelectOption(po);
            })}
        </OptGroup>
      )}
    </Select>
  );
});

export default ProductOrderSearch;
