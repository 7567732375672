import { axiosInstance as axios } from 'common';

export interface ParamsObj {
  user_id: string;
  allowed_cost_centers: BranchObj[];
  business_verticals: BusinessVerticalObj[];
  allowed_doctypes: string[];
  tenant_id: string;
  superset_dashboard_id: string | undefined | null;
  fin_database: string;
  company?: string;
  user_contact_id: string;
  is_collaborator?: boolean;
}

interface UrlParams {
  fin_database: string;
  allowed_cost_centers: string[];
  allowed_doctypes: string[];
  business_verticals: string[];
  user_id: string;
  tenant_id: string;
  company: string;
  user_contact_id: string;
  is_collaborator?: boolean;
}

interface BranchObj {
  erp_cost_center_id: string;
}
interface BusinessVerticalObj {
  id: string;
}

export const get_dashboard_token = async (dashboardParams: ParamsObj) => {
  const payload = {
    resources: [
      {
        type: 'dashboard',
        id: dashboardParams?.superset_dashboard_id,
      },
    ],
    rls: [],
  };
  try {
    const response = await axios.post(
      `${process.env.ALEX_URL}${process.env.DASHBOARD_TOKEN_ENDPOINT}`,
      payload
    );
    return response.data.token;
  } catch (error) {
    console.log(
      'DASHBOARD TOKEN ERROR -> ',
      error,
      `${process.env.ALEX_URL}${process.env.DASHBOARD_TOKEN_ENDPOINT}`
    );
  }
  return '';
};
export const generate_url_params = (dashboardParams: ParamsObj) => {
  const allowed_cost_centers: string[] = (dashboardParams.allowed_cost_centers || []).map(
    (branch_account: BranchObj) => branch_account.erp_cost_center_id
  );
  const business_verticals: string[] = (dashboardParams.business_verticals || []).map(
    (business_vertical: BusinessVerticalObj) => business_vertical.id
  );
  const url_params: UrlParams = {
    fin_database: dashboardParams.fin_database,
    allowed_cost_centers,
    business_verticals,
    allowed_doctypes: dashboardParams.allowed_doctypes,
    user_id: dashboardParams.user_id,
    tenant_id: dashboardParams.tenant_id,
    company: dashboardParams.company || '',
    user_contact_id: dashboardParams.user_contact_id,
    is_collaborator: dashboardParams.is_collaborator,
  };
  return url_params;
};
