import { CustomIcon, Tag } from '@shipmnts/pixel-hub';
import React, { useState } from 'react';
import WarehouseTransactionTimeline from './WarehouseTransactionTimeline';
import { CANCELLED, STATUS_SUBMITTED } from '../models/WarehouseTransaction';
import { upperCase as _upperCase } from 'lodash';

const WarehouseTransactionTimlinedTag = (props: any) => {
  const { transaction } = props;
  const [open, setOpen] = useState<boolean>(false);
  const getTagsColor = (status: string | undefined) => {
    if (!status) return 'info';
    else if ([STATUS_SUBMITTED].includes(status)) return 'success';
    else if ([CANCELLED].includes(status)) return 'critical';
    return 'info';
  };
  return (
    <div>
      <div style={{ display: 'flex' }}>
        <div style={{ fontSize: '12px', color: '#cc933e' }}>
          <Tag type={getTagsColor(transaction?.status)}>
            {_upperCase(transaction?.status || '')}
          </Tag>
        </div>{' '}
        <span style={{ marginLeft: '2px' }}>
          <CustomIcon icon="TrackerIcon" onClick={() => setOpen(!open)} />
        </span>
      </div>

      {open && (
        <WarehouseTransactionTimeline
          transaction_id={transaction.id}
          onClose={() => setOpen(!open)}
        />
      )}
    </div>
  );
};

export default WarehouseTransactionTimlinedTag;
